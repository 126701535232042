import Axios from "../utils/axios";

const DarshanamService = {
  all: async () => {
    const resp = await Axios.get(`darshanam/all`);
    return resp.data;
  },

  allIdAndNames: async () => {
    const resp = await Axios.get(`darshanam/all/id-and-names`);
    return resp.data;
  },

  darshanamById: async (id) => {
    const resp = await Axios.get(`darshanam/by-id?darshanamId=${id}`);
    return resp.data;
  },

  delete: async (id) => {
    const resp = await Axios.delete(`darshanam/delete/${id}`);
    return resp.data;
  },

  newDarshanam: async (
    name,
    description,
    imageUrl,
    maxBookingPerDay,
    cutOffTimeHours,
    disabled,
    price,
    bookWithinDays
  ) => {
    const body = {
      name,
      description,
      imageUrl,
      maxBookingPerDay,
      cutOffTimeHours,
      disabled,
      price,
      bookWithinDays,
    };
    Object.keys(body).forEach((key) => {
      if (body[key] === undefined || !body[key]) {
        delete body[key];
      }
    });
    const resp = await Axios.post(`darshanam/new`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  editDarshanam: async (
    id,
    name,
    description,
    imageUrl,
    maxBookingPerDay,
    cutOffTimeHours,
    disabled,
    price,
    bookWithinDays
  ) => {
    const body = {
      id,
      name,
      description,
      imageUrl,
      maxBookingPerDay,
      cutOffTimeHours,
      disabled,
      price,
      bookWithinDays,
    };
    console.log(body);
    Object.keys(body).forEach((key) => {
      if (body[key] === undefined || !body[key]) {
        delete body[key];
      }
    });
    const resp = await Axios.put(`darshanam/edit`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  // not passing paymentStatus so gets back only success
  bookings: async (
    darshanamId,
    bookingFromDate,
    bookingToDate,
    dateType,
    page,
    limit
  ) => {
    let query = `paymentStatus=success&page=${page}&limit=${limit}&dateType=${dateType}&`;

    if (darshanamId) {
      query += `darshanamId=${darshanamId}&`;
    }

    if (bookingFromDate) {
      query += `bookingFromDate=${bookingFromDate}&`;
    }

    if (bookingToDate) {
      query += `bookingToDate=${bookingToDate}&`;
    }

    const resp = await Axios.get(`darshanam-report/bookings?${query}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  bookingsExcel: async (
    darshanamId,
    bookingFromDate,
    bookingToDate,
    dateType
  ) => {
    const resp = await Axios.get(
      `darshanam-report/bookings/excel?darshanamId=${darshanamId}&bookingFromDate=${bookingFromDate}&bookingToDate=${bookingToDate}&dateType=${dateType}&paymentStatus=success`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        responseType: "blob",
      }
    );
    return resp.data;
  },

  disableDates: async (darshanamIds, fromDate, toDate, description) => {
    const requestObj = {
      darshanamIds: darshanamIds,
      start_date: fromDate,
      end_date: toDate,
      description: description,
    };
    if (!description) {
      delete requestObj.description;
    }
    const resp = await Axios.post(`/darshanam/disable/date`, requestObj, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  getDisabledDates: async (page, limit) => {
    const resp = await Axios.get(
      `/darshanam/disable/date?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  deleteDisabledDate: async (id) => {
    const resp = await Axios.delete(`/darshanam/disable/date?id=${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  getCollectionByDarshanam: async (fromDate, toDate, dateType, txType) => {
    const resp = await Axios.get(
      `/darshanam-report/collection?fromDate=${fromDate}&toDate=${toDate}&dateType=${dateType}&paymentStatus=success&txType=${txType}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  getAllSlots: async (id, page) => {
    const resp = await Axios.get(`/darshanam/slots/byDarshanamId/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return resp.data;
  },
  addSlot: async (body) => {
    const resp = await Axios.post(`/darshanam/slots/new`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });

    return resp.data;
  },
  editSlot: async (body) => {
    const resp = await Axios.put(`/darshanam/slots/edit`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });

    return resp.data;
  },
  deleteSlotById: async (id) => {
    const resp = await Axios.delete(`darshanam/slots/delete/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return resp.data;
  },

  getAllDarshanamCounters: async (id) => {
    const resp = await Axios.get(
      `/darshanam-counter/counters/BySlotId?darshanamSlotId=${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );
    return resp.data;
  },
  getCounterByDarshanamId: async (id) => {
    const resp = await Axios.get(
      `/darshanam-counter/counters/darshanamSlotCounterId/${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );
    return resp.data;
  },
  deleteCounterbyDarshanamId: async (id) => {
    const resp = await Axios.delete(
      `/darshanam-counter/counters/darshanamSlotCounterId/${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );
    return resp.data;
  },
  addCounterbyDarshanamId: async (body) => {
    const resp = await Axios.post(`/darshanam-counter/counters/new`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });

    return resp.data;
  },
  editCounterbyDarshanamId: async (body) => {
    const resp = await Axios.put(`/darshanam-counter/counters/edit`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return resp.data;
  },
};

export default DarshanamService;
