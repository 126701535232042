import React, {useEffect} from "react";
import Quagga from "quagga";

const QuaggaClass = ({closeFlag, setCloseFlag, onDetected}) => {
  useEffect(() => {
    Quagga.init(
      {
        inputStream: {
          type: "LiveStream",
          constraints: {
            width: 640,
            height: 480,
            facingMode: "environment", // or user
          },
        },
        locator: {
          patchSize: "medium",
          halfSample: true,
        },
        numOfWorkers: 4,
        decoder: {
          readers: ["code_128_reader"],
        },
        locate: true,
      },
      function (err) {
        if (err) {
          return console.log(err);
        }
        Quagga.start();
      }
    );
    Quagga.onDetected(_onDetected);

    return () => {
      Quagga.offDetected(_onDetected);
      Quagga.stop();
    };
  }, []);

  useEffect(() => {
    console.log("change");
    if (closeFlag) {
      Quagga.stop();
      setCloseFlag(false);
    }
  }, [closeFlag]);

  const _onDetected = (result) => {
    // console.log(result);
    onDetected(result);
  };

  return <div id="interactive" className="viewport" />;
};

export default QuaggaClass;
