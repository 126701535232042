/* eslint-disable react/jsx-pascal-case */
import {Button, Col, notification, Row, Select} from "antd";
import Modal from "antd/lib/modal/Modal";
import {useContext, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {routeMapMini} from "../../../constants/routeMap";
import {UserContext} from "../../../context/user.context";
// import RoomService from "../../../Services/rooms.service";
import PrasadamCounterTable from "./PrasadamCounterTable";
import CounterService from "../../../Services/Counter.service";
import PrasadamService from "../../../Services/Prasadam.service";
const {Option} = Select;
const PrasadamCounterMain = ({history, location}) => {
  const [loading, setLoading] = useState();
  const [showModal, setShowModal] = useState(false);
  const [prasadamID, setPrasadamID] = useState("");
  const {userDetails} = useContext(UserContext);
  const [list, setList] = useState([]);
  const [counterList, setCounterList] = useState([]);
  const [updateTable, setUpdateTable] = useState("none");
  const [selectedCounter, setSelectedCounter] = useState({});
  const [edit, setEdit] = useState(false);
  const [temp, setTemp] = useState("");

  useEffect(() => {
    getTableData();
  }, []);

  useEffect(() => {
    loadCounters();
  }, [updateTable]);

  useEffect(() => {
    if (edit === true) {
      setShowModal(true);
      let temps = counterList?.find((item) => item.id === temp.sd_counter_id);
      setSelectedCounter(temps);
    }
  }, [edit]);

  const loadCounters = async () => {
    try {
      setLoading(true);
      const counters = await CounterService.getAllCounter();
      setCounterList(counters);
      setLoading(false);
    } catch (error) {
      if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
      setLoading(false);
    }
  };

  const getTableData = async () => {
    setLoading(true);
    try {
      const publication = new URLSearchParams(location.search).get("id");
      setPrasadamID(publication);
      const resp = await PrasadamService.getAllPrasadamCounterList(publication);
      setList(resp);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
  };

  const handleOk = async () => {
    if (selectedCounter === {}) {
      notification.error({message: `Select a Shift`});
      return;
    }
    setLoading(true);
    try {
      console.log(edit);
      if (edit) {
        let body = {
          donation_id: temp.id,
          prasadamId: prasadamID,
          counterId: selectedCounter.id,
        };
        console.log(body);

        // await DonationService.editCounterbyDarshanamId(body);
        notification.success({
          message: `Counter has been succesfully Edited`,
        });
        setEdit(false);
      } else {
        let body = {
          prasadamId: Number(prasadamID),
          counterId: selectedCounter.id,
        };
        await PrasadamService.addCounterbyprasadamId(body);
        notification.success({
          message: `Counter has been succesfully created`,
        });
      }

      getTableData();
      setShowModal(false);
      clearFields();
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setSelectedCounter({});
    // setblockID(undefined);
  };

  const handleCancel = () => {
    clearFields();
    setShowModal(false);
    setEdit(false);
  };

  return (
    <div style={{width: "100%"}}>
      <Row style={{width: "100%", marginBottom: "15px"}} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setUpdateTable(Math.random(100));
              setShowModal(true);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Add New Counter
          </Button>
        </Col>
      </Row>
      <Row style={{width: "100%"}}>
        <PrasadamCounterTable
          list={list}
          blockID={prasadamID}
          loading={loading}
          setLoading={setLoading}
          setUpdateTable={setUpdateTable}
          getCountersByBlock={getTableData}
          setTemp={setTemp}
          setEdit={setEdit}
        />
      </Row>

      <Modal
        visible={showModal}
        title={
          edit
            ? `Edit Counter to Prasadam Id: ${prasadamID}`
            : `Add New Counter to Prasadam Id: ${prasadamID}`
        }
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify="center" style={{width: "100%", paddingTop: "10px"}}>
          Prasadam Id : {prasadamID}
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Select
            width="100%"
            style={{width: "100%"}}
            value={selectedCounter?.name}
            placeholder="Please select a Counter"
            onChange={(e) => {
              let temp = counterList?.find((item) => item.name === e);
              setSelectedCounter(temp);
            }}
          >
            {counterList?.map((item, i) => {
              return (
                <Option key={item.name + "prasadam-list" + i} value={item.name}>
                  {item.name}{" "}
                </Option>
              );
            })}
          </Select>
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(PrasadamCounterMain);
