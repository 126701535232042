import "../CounterDonation/styles.css";
import "../../Ticket/PrintTicket.scss";

import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  Menu,
  // Modal,
  Row,
  Switch,
  notification,
  InputNumber,
} from "antd";
import { LoadingOutlined, RightOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import { FormatNumberINRCurrency } from "../../../utils/currencyFormat";
import ThulabharamService from "../../../Services/Thulabharam.service";
// import { useReactToPrint } from "react-to-print";
import PrintTicket from "../../Ticket/PrintTicket";
import StringUtils from "../../../utils/string.utils";
import moment from "moment";
import { routeMapMini } from "../../../constants/routeMap";
import { withRouter } from "react-router";

const { Meta } = Card;

const Sd_Counter_Thulabharam = ({ history }) => {
  // let refContainer = useRef(null);
  const [ThulabharamList, setThulabharamList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bookingData, setBookingData] = useState([]);
  const [ThulabharamCount, setThulabharamCount] = useState([]);
  const [amountpaid, setAmountPaid] = useState(0);
  const labelStyles = { float: "left", fontSize: "15px", marginBottom: "5px" };
  const inputBoxes = {
    width: "48%",
    justifyContent: "start",
    marginBottom: "5px",
  };
  const [buttonLoading, setButtonLoading] = useState(false);
  const [externalPrint, setExternalPrint] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [selectedThulabharamIds, setSelectedThulabharamIds] = useState([]);
  const [name, setName] = useState("");
  const [weight, setWeight] = useState(0);
  const [behalfOfName, setBehalfOfName] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    const localStorageSelectedThulabharamIds = localStorage.getItem(
      "selectedCounterThulabharamIds"
    );

    if (localStorageSelectedThulabharamIds) {
      const ids = localStorageSelectedThulabharamIds.split(",").map(Number);
      setSelectedThulabharamIds(ids);
    } else {
      setSelectedThulabharamIds([]);
    }
  }, []);

  const getThulabharamList = async () => {
    setLoading(true);
    try {
      let res1 = await ThulabharamService.getAllThulabharamList();
      setThulabharamList(res1);
      setThulabharamCount(
        res1.map((item) => {
          return {
            id: item?.sd_thulabharam?.id,
            price: item?.sd_thulabharam?.price,
            count: 0,
          };
        })
      );
      // let res2 = await kalyanaKattaService.allByType("cover");
      // setCoversList(res2);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        // console.log(error);

        notification.error({ message: error.response?.data?.message });
      } else {
        // console.log(error);
        notification.error({ message: error.message });
      }
    }
  };

  useEffect(() => {
    getThulabharamList();
  }, []);

  const handleSubmit = async () => {
    setButtonLoading(true);
    setLoading(true);
    let a = false;
    if (name === "" || behalfOfName === "" || weight === 0) {
      let string = [];
      name === "" && string.push("Name");
      behalfOfName === "" && string.push("on Behalf of Name");
      weight === 0 && string.push("Weight");
      notification.error({
        message: string.join("") + "  cannot be empty",
      });
      return;
    }
    if (phone.length !== 10) {
      notification.error({ message: "Phone number must be 10 digits" });
      setLoading(false);
      setButtonLoading(false);
      return;
    }
    ThulabharamCount?.forEach((e) => {
      if (e.count !== 0 && e.count > 0) {
        a = true;
      }
    });
    if (!a) {
      notification.error({ message: "Please select atleast one Thulabharam" });
      setLoading(false);
      setButtonLoading(false);
      return;
    }
    try {
      let body = {
        thulabharamItems: [],
        cashType: "CASH",
        name: name,
        onBehalfOf: behalfOfName,
        weight: weight,
        phone: `+91${phone}`,
        date: moment(new Date()).format("YYYY-MM-DD"),
      };
      ThulabharamCount.forEach((item) => {
        if (item.count > 0 && item?.count !== 0) {
          body.thulabharamItems.push({
            thulabharamId: item?.id,
            quantity: item?.count,
          });
        }
      });
      let res = await ThulabharamService.bookThulabharam(body);
      notification.success({ message: "Success" });
      setBookingData(res);
      clearFields();
      setLoading(false);
      setIsModalVisible(true);
      setExternalPrint(true);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({ message: error.response?.data?.message });
      } else {
        notification.error({ message: error.message });
      }
      setLoading(false);
    }
    setButtonLoading(false);
  };

  const getAmount = (type) => {
    let amount = 0;
    ThulabharamCount.forEach((e) => {
      if (e?.count === 0) {
        return;
      } else {
        amount += e?.count * e?.price;
      }
    });
    return amount;
  };

  const clearFields = () => {
    setStartDate("");
    setThulabharamList([]);
  };

  const toggleSwitch = (id) => {
    let newIds = [];
    if (selectedThulabharamIds?.includes(id)) {
      newIds = selectedThulabharamIds.filter((e) => e !== id);
    } else {
      newIds = [...selectedThulabharamIds, id];
    }
    setSelectedThulabharamIds(newIds);
    localStorage.setItem("selectedCounterThulabharamIds", newIds);
  };

  const menu = (
    <Menu onClick={() => setMenuVisible(true)}>
      {ThulabharamList.map((item, i) => {
        return (
          <Menu.Item
            key={item?.sd_thulabharam?.id}
            onClick={() => {
              toggleSwitch(item?.sd_thulabharam?.id);
            }}
          >
            {item?.sd_thulabharam.name}
            <Switch
              size="small"
              style={{ float: "right" }}
              checked={selectedThulabharamIds?.includes(
                item?.sd_thulabharam?.id
              )}
            />
          </Menu.Item>
        );
      })}
    </Menu>
  );
  // const handlePrint = useReactToPrint({
  //   content: () => refContainer.current,
  //   copyStyles: true,
  //   bodyClass: "sd-ticket-container",
  //   onAfterPrint: () => {
  //     setBookingData(undefined);
  //     window.location.reload();
  //   },
  // });
  // const handleFunction = () => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 200);
  //   setTimeout(() => {
  //     handlePrint();
  //   }, 1000);
  // };
  const handleVisibleChange = () => {
    if (menuVisible) {
      setMenuVisible(false);
    } else {
      setMenuVisible(true);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div
          style={{
            maxWidth: "1200px",
            width: "100%",
            background: "white",
            padding: "30px 50px",
          }}
        >
          <Row
            justify="center"
            style={{ fontSize: "22px", marginBottom: "10px" }}
          >
            Thulabharam
          </Row>
          <Divider style={{ marginTop: "0px", marginBottom: "10px" }} />
          <Row justify="space-between">
            <Col style={inputBoxes}>
              <label style={labelStyles}>
                Booking Date{" "}
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
              </label>
              <br />{" "}
              <DatePicker
                disabled={true}
                value={startDate}
                style={{ width: "100%" }}
                format="DD-MM-YYYY"
                disabledDate={(d) =>
                  !d || d.isBefore(StringUtils.addDays(new Date(), -1))
                }
                onChange={(date) => {
                  setStartDate(date);
                }}
                size="small"
              />
            </Col>

            <Col style={inputBoxes}>
              <label style={labelStyles}>
                Name
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
              </label>
              <br />
              <Input
                size="small"
                type="text"
                placeholder="Enter a Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Col>

            <Col style={inputBoxes}>
              <label style={labelStyles}>
                On behalf of
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
              </label>
              <br />
              <Input
                size="small"
                type="text"
                placeholder="Enter a Name"
                value={behalfOfName}
                onChange={(e) => {
                  setBehalfOfName(e.target.value);
                }}
              />
            </Col>

            <Col style={inputBoxes}>
              <label style={labelStyles}>
                Weight
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
              </label>
              <br />
              <InputNumber
                size="small"
                defaultValue={weight}
                value={weight}
                min={0}
                max={101}
                style={{ width: "100%" }}
                placeholder="Enter weight of the person"
                onChange={(e) => {
                  setWeight(e);
                }}
              ></InputNumber>
            </Col>
            <Col style={{ width: "48%" }}>
              <label style={labelStyles}>
                Mobile Number
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
              </label>
              <br />
              <Input
                size="small"
                type="number"
                onWheel={(event) => event.currentTarget.blur()}
                maxLength={10}
                addonBefore={"+91"}
                placeholder="Please Enter Mobile Number"
                style={{ width: "100%" }}
                value={phone}
                onChange={(e) => {
                  if (e.target.value.length > 10) {
                    return;
                  }
                  setPhone(`${e.target.value}`);
                }}
              />
            </Col>

            <Col style={inputBoxes}>
              <label style={labelStyles}>Thulabharam Items </label>
              <br />{" "}
              <Dropdown
                overlay={menu}
                placement="bottomLeft"
                onVisibleChange={() => handleVisibleChange()}
                visible={menuVisible}
              >
                <Button style={{ width: "100%" }} size="small">
                  Thulabharam Items
                </Button>
              </Dropdown>
            </Col>
          </Row>
          <br />
          <Row
            justify="space-between"
            className="sd-display-cards"
            style={{ overflow: "hidden" }}
          >
            {ThulabharamList.filter((item) =>
              selectedThulabharamIds?.includes(item?.sd_thulabharam?.id)
            ).map((item, i) => {
              return (
                <Card
                  hoverable
                  className="sd-display-card"
                  style={{
                    width: "100%",
                    height: "50px",
                    display: "flex",
                  }}
                  actions={[
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        textAlign: "left",
                      }}
                    >
                      {/* <MinusOutlined
                        key="minus"
                        onClick={() => {
                          let data = [...kalyanakattaCount];
                          data.forEach((e) => {
                            if (e?.id === item?.sd_kalyanakatta?.id) {
                              if (e?.count === 0) {
                                return;
                              }
                              e.count--;
                            }
                          });
                          setKalyanakattaCount(data);
                        }}
                      /> */}
                      <Input
                        style={{ width: "50px", textAlign: "center" }}
                        type="number"
                        min="0"
                        max="99"
                        maxLength="2"
                        key="edit"
                        placeholder="0"
                        autoFocus={i === 0 ? true : false}
                        value={
                          ThulabharamCount?.find(
                            (e) => e.id === item?.sd_thulabharam?.id
                          )?.count || undefined
                        }
                        onInput={(object) => {
                          if (
                            object.target.value.length > object.target.maxLength
                          ) {
                            object.target.value = object.target.value.slice(
                              0,
                              object.target.maxLength
                            );
                          }
                        }}
                        onChange={(event) => {
                          let data = [...ThulabharamCount];
                          data.forEach((e) => {
                            if (e?.id === item?.sd_thulabharam?.id) {
                              e.count = event.target.value;
                            }
                          });
                          setThulabharamCount(data);
                        }}
                      />
                      {/* <PlusOutlined
                        key="plus"
                        onClick={() => {
                          let data = [...kalyanakattaCount];
                          data.forEach((e) => {
                            if (e?.id === item?.sd_kalyanakatta?.id) {
                              e.count++;
                            }
                          });
                          setKalyanakattaCount(data);
                        }}
                      /> */}
                    </div>,
                    <div>
                      Final Amout:{" "}
                      <span style={{ color: "red", fontWeight: "600" }}>
                        {FormatNumberINRCurrency(
                          ThulabharamCount?.find(
                            (e) => e.id === item?.sd_thulabharam?.id
                          )?.price *
                            ThulabharamCount?.find(
                              (e) => e.id === item?.sd_thulabharam?.id
                            )?.count
                        )}
                      </span>
                    </div>,
                  ]}
                >
                  <Meta
                    // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                    title={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-beetween",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            textTransform: "capitalize",
                            width: "200px",
                            fontWeight: "400",
                            textAlign: "left",
                          }}
                        >
                          {item?.sd_thulabharam.name}
                        </span>
                        <Divider type="vertical" />
                        <span
                          style={{
                            marginLeft: "30px",
                            fontWeight: "400",
                          }}
                        >
                          Price:
                          <span
                            style={{
                              color: "red",
                              fontWeight: "600",
                              marginLeft: "5px",
                            }}
                          >
                            {FormatNumberINRCurrency(
                              item?.sd_thulabharam?.price
                            )}
                          </span>
                        </span>
                      </div>
                    }
                  />
                </Card>
              );
            })}
          </Row>
          <Row style={{ height: "50px", marginTop: "10px" }}>
            <Col
              style={{
                width: "25%",
                fontWeight: "bold",
                fontSize: "23px",
                textAlign: "left",
              }}
            >
              Total Amount :
            </Col>
            <Col
              style={{
                width: "75%",
                textAlign: "right",
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              {FormatNumberINRCurrency(getAmount())}
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col
              style={{
                width: "25%",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              Amount Paid:
            </Col>
            <Col
              style={{
                width: "75%",
                textAlign: "right",
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              <Input
                style={{ width: "200px", textAlign: "right" }}
                type="number"
                onChange={(e) => {
                  setAmountPaid(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col
              style={{
                width: "25%",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              Amount to be Returned:
            </Col>
            <Col
              style={{
                width: "75%",
                textAlign: "right",
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              {FormatNumberINRCurrency(
                amountpaid - getAmount() > 0 ? amountpaid - getAmount() : 0
              )}
            </Col>
          </Row>
          <br />
          <Row>
            <Col
              style={{
                textAlign: "right",
              }}
            >
              <Button
                type="primary"
                style={{ background: "#f68121", border: "none" }}
                onClick={() => {
                  handleSubmit();
                }}
                loading={buttonLoading}
              >
                Submit <RightOutlined />
              </Button>
            </Col>
          </Row>
        </div>
      )}
      <Row style={{ display: "none" }}>
        <PrintTicket
          type="thulabharam"
          from="Counter"
          bookingDate={moment(bookingData?.booking?.booking?.created_at).format(
            "DD-MM-YYYY"
          )}
          ticketId={bookingData?.ticket}
          name={bookingData?.booking?.booking?.name}
          phone={phone}
          onBehalfOf={bookingData?.booking?.booking?.on_behalf_of}
          weight={bookingData?.booking?.booking?.weight}
          thulabharamItems={bookingData?.booking?.thulabharamItems}
          setPrintTicketModal={setIsModalVisible}
          setData={setBookingData}
          categoryTransactionId={
            bookingData?.counterTransactions
              ? bookingData?.counterTransactions[0]?.category_transaction_id
              : ""
          }
          totalAmount={bookingData?.booking?.booking?.total_price ?? 0}
          serialNumber={
            bookingData?.counterTransactions
              ? bookingData?.counterTransactions[0]
                  ?.daily_category_transaction_id
              : ""
          }
          externalPrint={externalPrint}
          setExternalPrint={setExternalPrint}
        />
      </Row>
      {/* <Modal
        visible={isModalVisible}
        width={400}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <PrintTicket
          type="prasadam"
          from="Counter"
          bookingDate={moment(bookingData?.booking?.booking?.created_at).format(
            "DD-MM-YYYY"
          )}
          ticketId={bookingData?.ticket}
          prasadamItems={bookingData?.booking?.prasadamItems}
          setPrintTicketModal={setIsModalVisible}
          setData={setBookingData}
          categoryTransactionId={
            bookingData?.counterTransactions
              ? bookingData?.counterTransactions[0]?.category_transaction_id
              : ""
          }
          totalAmount={bookingData?.booking?.booking?.total_price}
          serialNumber={
            bookingData?.counterTransactions
              ? bookingData?.counterTransactions[0]
                  ?.daily_category_transaction_id
              : ""
          }
        />
      </Modal> */}
    </div>
  );
};
export default withRouter(Sd_Counter_Thulabharam);
