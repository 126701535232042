import axios from 'axios';

export const baseURL =
  process.env.REACT_APP_API_URL ?? 'https://api-ws.srisailadevasthanam.org/';

const Axios = axios.create({
  baseURL: baseURL,
});

Axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Prevent infinite loops
    if (
      error.response.status === 401 &&
      (originalRequest.url === '/user/refresh' ||
        originalRequest.url === '/user/login' ||
        originalRequest.url === '/user/logout')
    ) {
      if (originalRequest.url === '/user/logout') {
        localStorage.clear();
        return Promise.resolve();
      }
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken');

      if (refreshToken) {
        const resp = await Axios.post(
          '/user/refresh',
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('refreshToken')}`,
            },
          }
        );
        console.log(resp.data);
        localStorage.setItem('accessToken', resp.data.accessToken);

        originalRequest.headers[
          'Authorization'
        ] = `Bearer ${localStorage.getItem('accessToken')}`;

        return Axios(originalRequest);
      } else {
        return Promise.reject(error);
      }
    }
    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default Axios;
