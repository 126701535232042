import React from "react";
import {Row, Col} from "antd";
import numberToWords from "../../utils/amount-to-words/number-to-words";
import "./PrintTicket.scss";
import srisailam_logo from "../../assets/srisailam_logo.png";
import Barcode from "react-barcode";
import moment from "moment";

const SevaTicket = ({
  serialNumber,
  type,
  sevaName,
  abishekam,
  bookingDate,
  timeSlot,
  startTime,
  endTime,
  persons,
  name,
  amountPaid,
  actualAmount,
  phone,
  proof,
  ticketId,
  ticketcopy,
  from,
  performanceDate,
  categoryTransactionId,
}) => {
  return (
    <div
      style={{
        textAlign: "center",
        // paddingLeft: "20px",
      }}
    >
      {/* <img className="background right" src={srisailam_logo} alt="logo" /> */}
      <>
        {categoryTransactionId && (
          <Row justify="space-between" style={{fontWeight: "bold"}}>
            <Col>
              {" "}
              {`TI No : ${categoryTransactionId?.split("-")[2]} -
              ${categoryTransactionId?.split("-")?.pop()}`}
            </Col>
            <Col style={{paddingRight: "35px"}}>
              {/* {`Sl No : ${serialNumber?.split("-")[3]}-${
                serialNumber?.split("-")[4]
              }`}{" "} */}
              {`Sl No : 
            ${serialNumber?.split("-")[3]}`}{" "}
            </Col>
          </Row>
        )}
        <Row className={`${from === "Counter" ? "hideLogo" : "displayLogo"}`}>
          <img
            src={srisailam_logo}
            alt="logo"
            style={{
              width: "20%",
              justifyContent: "center",
              margin: "auto",
              filter: "grayscale(1)",
            }}
          />
        </Row>
        <div className={`${from === "Counter" ? "displayL" : "hideL"}`}>.</div>
        <div className={`${from === "Counter" ? "hideLogo" : "displayLogo"}`}>
          <Row className="tickettitle">
            <p>
              Sri Bhramaramba Mallikarjuna Swamy Varla Devasthanam - Srisailam
            </p>
          </Row>
        </div>
        <br />
        <Row
          className={`${
            from === "Counter"
              ? "hideLogo tickettitle sevaTicketTitle"
              : "displayLogo tickettitle"
          }`}
        >
          <p style={{textTransform: "uppercase"}}>
            {from !== "Counter" ? "Seva Online" : "Seva Counter"} - {ticketcopy}
          </p>
        </Row>
      </>
      {/* )} */}
      <div style={{textAlign: "left", marginTop: "13px"}}>
        <Row>
          <Col className="ticket_label">
            <p>{sevaName ? `Seva Name` : `Abishekam Type`}</p>
          </Col>
          <Col
            className="ticket_data seva_ticket_col"
            style={{fontWeight: "bold"}}
          >
            <p className="ticket_colon">:</p>
            <p>{sevaName ? sevaName : abishekam}</p>
          </Col>
        </Row>
        <Row>
          <Col className="ticket_label">
            <p>Ticket Id</p>
          </Col>
          <Col
            className="ticket_data seva_ticket_col"
            style={{fontWeight: "bold"}}
          >
            <p className="ticket_colon">:</p>
            <p>{ticketId}</p>
          </Col>
        </Row>
        <Row>
          <Col className="ticket_label">
            <p>Booking Date</p>
          </Col>
          <Col className="ticket_data seva_ticket_col">
            <p className="ticket_colon">:</p>
            <p>{bookingDate}</p>
          </Col>
        </Row>

        <Row>
          <Col className="ticket_label">
            <p>{`Performance Date`}</p>
          </Col>
          <Col className="ticket_data seva_ticket_col">
            <p className="ticket_colon">:</p>
            <p>{performanceDate}</p>
          </Col>
        </Row>

        <Row>
          <Col className="ticket_label">
            <p>Time Slot</p>
          </Col>
          <Col className="ticket_data seva_ticket_col">
            <p className="ticket_colon">:</p>
            {timeSlot ? (
              <p>{timeSlot}</p>
            ) : (
              <p>
                {moment(startTime, "HH:mm:ss").format("hh:mm A")} -{" "}
                {moment(endTime, "HH:mm:ss").format("hh:mm A")}
              </p>
            )}
          </Col>
        </Row>

        {persons && (
          <Row>
            <Col className="ticket_label">
              <p>No of Devotees</p>
            </Col>
            <Col className="ticket_label seva_ticket_col">
              <p className="ticket_colon">:</p>
              <p>{persons}</p>
            </Col>
          </Row>
        )}
        {name && (
          <Row>
            <Col className="ticket_label">
              <p>Devotee Names</p>
            </Col>
            <Col className="ticket_data seva_ticket_col">
              <p className="ticket_colon">:</p>
              <p>{name?.split(",")[0].trim()}</p>
            </Col>
          </Row>
        )}
        {sevaName.toLowerCase() !== "vip break darshanam" && (
          <Row>
            <Col className="ticket_label">
              <p>Allowed</p>
            </Col>
            <Col className="ticket_data seva_ticket_col">
              <p className="ticket_colon">:</p>
              <p>Couple / Single</p>
            </Col>
          </Row>
        )}
        {amountPaid && actualAmount && (
          <>
            <Row>
              <Col className="ticket_label amount-label">{`Seva Amount`}</Col>
              <Col className="ticket_label amount-label seva_ticket_col">
                <p className="ticket_colon">:</p>
                <p> Rs. {actualAmount}.00</p>
              </Col>
            </Row>
            <Row>
              <Col className=" ticket_label amount-label">
                <p>Handling Charges</p>
              </Col>
              <Col className="ticket_label amount-label seva_ticket_col">
                <p className="ticket_colon">:</p>
                <p>Rs. {amountPaid - actualAmount}.00</p>
              </Col>
            </Row>
          </>
        )}
        {amountPaid && (
          <Row>
            <Col className="ticket_label">
              <p>Total Amount</p>
            </Col>
            <Col className="ticket_data seva_ticket_col">
              <p className="ticket_colon">:</p>
              <p>
                Rs. {amountPaid}.00
                <>
                  <br />
                  <div style={{fontSize: "13px"}}>
                    ({numberToWords(amountPaid)} Rupees)
                  </div>
                </>
              </p>
            </Col>
          </Row>
        )}
        {phone && (
          <Row>
            <Col className="ticket_label">
              <p>Contact No</p>
            </Col>
            <Col className="ticket_data seva_ticket_col">
              <p className="ticket_colon">:</p>
              <p>{phone}</p>
            </Col>
          </Row>
        )}
        {proof && (
          <Row>
            <Col className="ticket_label">
              <p>ID Proof No.</p>
            </Col>
            <Col className="ticket_data seva_ticket_col">
              <p className="ticket_colon">:</p>
              <p>{proof}</p>
            </Col>
          </Row>
        )}
      </div>

      <Barcode
        height="35"
        width="1"
        fontSize="15px"
        value={ticketId ? ticketId : "transactionId=127"}
        style={{
          justifyContent: "center",
          margin: "auto",
        }}
      />
    </div>
  );
};

export default SevaTicket;
