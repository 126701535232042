import {Button, Card, Carousel, Input, notification, Row} from "antd";
import {useContext, useState} from "react";
import {withRouter} from "react-router-dom";
import {routeMapMini} from "../../constants/routeMap";
import AuthService from "../../Services/Auth.service";
import {UserContext} from "../../context/user.context";
import {
  sd_center_logo,
  sd_pic_one,
  sd_pic_three,
  sd_pic_two,
} from "../../assets/images";
import "./login.scss";
import {RightOutlined} from "@ant-design/icons";
const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const {setUserDetails} = useContext(UserContext);

  const loginClick = async () => {
    try {
      setLoading(true);
      const tokens = await AuthService.login(username, password);
      if (!tokens.user?.role || tokens.user?.role === "user") {
        setLoading(false);
        notification.error({message: "Forbidden Account"});
      } else {
        localStorage.setItem("accessToken", tokens.accessToken);
        localStorage.setItem("refreshToken", tokens.refreshToken);
        setLoading(false);
        setUserDetails(tokens.user);

        if (tokens.user?.role !== "admin") {
          setUsername("");
          setPassword("");
          setLoading(false);
          localStorage.clear();
          notification.error({
            message:
              "This account does not has admin permissions, Please login with another account!",
          });
          return;
        } else {
          props?.history?.push(routeMapMini.Dashboard);
          setUsername("");
          setPassword("");
          setLoading(false);
        }

        // if (
        //   tokens.user?.role === "seva_read" ||
        //   tokens.user?.role === "seva_read_and_performance_scan"
        // ) {
        // } else if (tokens.user?.role === "customer_support") {
        //   props?.history?.push(routeMapMini.contactUs);
        // } else if (tokens.user?.role === "donation_counter") {
        //   props?.history?.push(routeMapMini.Counter);
        // } else if (tokens.user?.role === "donation_read") {
        //   props?.history?.push(routeMapMini.Donation);
        // } else if (tokens.user?.role === "donation_write") {
        //   props?.history?.push(routeMapMini.Donation);
        // } else if (tokens.user?.role === "publication_read") {
        //   props?.history?.push(routeMapMini.Publications);
        // } else if (tokens.user?.role === "accommodation_counter") {
        //   props?.history?.push(routeMapMini.AccommodationDashboard);
        // } else if (tokens.user?.role === "accommodation_read") {
        //   props?.history?.push(routeMapMini.AccommodationDashboard);
        // } else if (tokens.user?.role === "counter") {
        //   props?.history?.push(routeMapMini.ticket);
        // } else {
        //   props?.history?.push(routeMapMini.Dashboard);
        // }
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        notification.error({message: "username or password wrong"});
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      loginClick();
    }
  };
  const contentStyle = {
    height: "200px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
    width: "100%",
  };
  return (
    <Row
      align="middle"
      justify="center"
      style={{height: "100%", display: "flex", flexDirection: "column"}}
    >
      <Card className="login">
        <Carousel autoplay className="autoplay" easing effect="fade">
          <div>
            <img src={sd_pic_one} style={contentStyle} alt="" />
          </div>
          <div>
            <img src={sd_pic_two} style={contentStyle} alt="" />
          </div>
          <div>
            <img src={sd_pic_three} style={contentStyle} alt="" />
          </div>
        </Carousel>
        <img src={sd_center_logo} alt="" className="center-logo" />
        <div className="outer">
          <Row className="flex">
            <label>Mobile Number</label>
            <Input
              className="mobile-field"
              placeholder="Enter your Phone Number"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              type="number"
              addonBefore={<p style={{margin: "0"}}>+91</p>}
              onKeyDown={onKeyDown}
              style={{margin: "5px 0px 10px 0px"}}
            />
          </Row>
          <Row className="flex">
            <label>Password</label>
            <Input
              className="mobile-field"
              placeholder="Password"
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onKeyDown={onKeyDown}
              style={{margin: "5px 0px 10px 0px"}}
            />
          </Row>
          <Row
            className="flex"
            justify="start"
            style={{margin: "20px 0px 5px 0px"}}
          >
            <Button
              type="primary"
              onClick={loginClick}
              loading={loading}
              style={{width: "fit-content"}}
            >
              Login{" "}
              {!loading && <RightOutlined style={{marginRight: "-5px"}} />}
            </Button>
          </Row>
        </div>
      </Card>

      <Row style={{fontSize: "14px", marginTop: "15px"}}>
        © {new Date().getFullYear()} Srisaila Devasthanam Account Manager
      </Row>
    </Row>
  );
};

export default withRouter(Login);
