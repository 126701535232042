import { Card, Col, Row } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { PieChart, Pie, Sector, Cell } from "recharts";

const UserOrderPieGraph = ({
  data,
  fromDate,
  toDate,
  history,
  title,
  graphsmall,
  graphlarge,
}) => {

  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };
  const [smallScreen, setSmallScreen] = useState(false);

  const screens = useBreakpoint();

  useEffect(() => {
    if (screens?.xl || screens?.xxl || screens?.lg) {
      setSmallScreen(false);
    } else {
      setSmallScreen(true);
    }
  }, [screens]);

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    if (smallScreen) {
      return (
        <g>
          <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
            {payload.status}
          </text>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          />
        </g>
      );
    }

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.status}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill={ "#000000"}
        >{`${value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const [formatData, setFormatData] = useState([
    {
      status: "created",
      count: 0,
      amount: 0,
    },
    {
      status: "success",
      count: 0,
      amount: 0,
    },
    {
      status: "created",
      amount: 0,
      count: 0,
    },
    {
      status: "canceled",
      amount: 0,
      count: 0,
    },
  ]);

  useEffect(() => {
    if (data && data?.length > 0) {
      setMainData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const setMainData = () => {
    setFormatData(
      data?.map((item) => {
        return {
          status: item.status,
          count: Number(item.count),
          amount: item.amount,
        };
      })
    );
  };

  let totalOrders = 0;

  const getColor = (status) => {
    switch (status) {
      case "created":
        return "#5ebfff";
      case "success":
        return "#b8e99e";
      case "failed":
        return "#f7a1c9";
      case "canceled":
        return "#fcbc70";
      default:
        return "#fcbc70";
    }
  };

  return (
    <Card title={title}>
      <Row justify="center">
        <Col
          xs={graphsmall}
          sm={graphsmall}
          md={graphlarge}
          lg={graphlarge}
          xl={graphlarge}
          xsl={graphlarge}
        >
          <PieChart width={450} height={smallScreen ? 250 : 250}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={formatData}
              cx={smallScreen ? "25%" : "50%"}
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="count"
              onMouseEnter={onPieEnter}
              paddingAngle={5}
            >
              {formatData?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={getColor(entry.status)} />
              ))}
            </Pie>
          </PieChart>
        </Col>
        <Col
          xs={graphsmall}
          sm={graphsmall}
          md={graphlarge}
          lg={graphlarge}
          xl={graphlarge}
          xsl={graphlarge}
          style={{ margin: "auto" }}
        >
          {formatData.map((item, index) => {
            totalOrders += Number(item.count);
            return (
              <Row justify="space-between">
                <Col
                  key={index}
                  style={{
                    textAlign: "left",
                    textTransform: "capitalize",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: getColor(item.status),
                      height: "10px",
                      width: "10px",
                      marginTop: "7px",
                      marginRight: "10px",
                    }}
                  ></div>
                  {item.status}
                </Col>
                <Col>{item.count}</Col>
              </Row>
            );
          })}
          <Row justify="space-between">
            <Col
              style={{
                textAlign: "left",
                textTransform: "capitalize",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  backgroundColor: "#FF6347",
                  height: "10px",
                  width: "10px",
                  marginTop: "7px",
                  marginRight: "10px",
                }}
              ></div>
              <b>Total Orders</b>
            </Col>
            <Col>{totalOrders}</Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default withRouter(UserOrderPieGraph);
