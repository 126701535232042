import Axios from "../utils/axios";

const CartService = {
  allPurchases: async (
    view,
    fromDate,
    toDate,
    paymentId,
    ticketId,
    paymentType,
    paymentStatus,
    cartId,
    userId,
    page,
    pageSize,
    sortBy,
    sortType
  ) => {
    const query = {
      fromDate,
      toDate,
      paymentId,
      ticketId,
      paymentType,
      paymentStatus,
      cartId,
      userId,
      page,
      limit: pageSize,
      sortBy,
      sortType,
    };
    Object.keys(query).forEach((key) => {
      if (query[key] === undefined || !query[key]) {
        delete query[key];
      }
    });
    const resp = await Axios.get(`cart/all-purchases/${view}`, {
      params: query,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      responseType: view === "download" ? "blob" : "",
    });
    return resp.data;
  },

  getAdminTicketDetails: async (ticketId) => {
    const resp = await Axios.get(`cart/ticket/admin/${ticketId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  scanTicket: async (ticketId) => {
    const resp = await Axios.post(
      `cart/ticket/scan/${ticketId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  getUserBookingHistory: async (userId, paymentStatus, page, pageSize) => {
    const resp = await Axios.get(
      `cart/admin/user/booking-history?userId=${userId}&paymentStatus=${paymentStatus}&page=${page}&limit=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

};

export default CartService;
