import Axios from '../utils/axios';

const AuditService = {
  getSevaUpdates: async (updateStatus, mapUserReviews, page, limit) => {
    updateStatus = updateStatus ?? 'all';
    const resp = await Axios.get(
      `seva/stage-updates/open?updateStatus=${updateStatus}&mapUserReviews=${mapUserReviews}&page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    return resp.data;
  },

  getSevaUpdateReviews: async (itemId, page, limit) => {
    const resp = await Axios.get(
      `seva/stage-updates/reviews?itemId=${itemId}&page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    return resp.data;
  },

  postSevaUpdateReview: async (itemId, review_description, accepted) => {
    const resp = await Axios.post(
      `seva/stage-updates/update-review`,
      {
        itemId,
        review_description,
        accepted,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    return resp.data;
  },
};

export default AuditService;
