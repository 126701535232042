/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Row, Space, Table } from "antd";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "./accomadation.css";
const CheckoutTable = ({
  list,
  loading,
  setLoading,
  tableCurrentPage,
  tablePageSize,
  setCheckOutData,
  checkOutSubmit,
  handleTableChange,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [data, setData] = useState([]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  // const getColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //   }) => (
  //     <div style={{ padding: 8 }}>
  //       <Input
  //         // ref={node => {
  //         //   this.searchInput = node;
  //         // }}
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) =>
  //           setSelectedKeys(e.target.value ? [e.target.value] : [])
  //         }
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{ width: 188, marginBottom: 8, display: "block" }}
  //       />
  //       <Button
  //         type="primary"
  //         onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         size="small"
  //         style={{ width: 90, marginRight: 8 }}
  //       >
  //         Search
  //       </Button>
  //       <Button
  //         onClick={() => handleReset(clearFilters)}
  //         size="small"
  //         style={{ width: 90 }}
  //       >
  //         Reset
  //       </Button>
  //     </div>
  //   ),
  //   filterIcon: (filtered) => (
  //     <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
  //   onFilterDropdownVisibleChange: (visible) => {
  //     if (visible) {
  //       // setTimeout(() => this.searchInput.select());
  //     }
  //   },
  //   render: (text) =>
  //     searchedColumn === dataIndex ? (
  //       <Highlighter
  //         highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
  //         searchWords={[searchText]}
  //         autoEscape
  //         textToHighlight={text.toString()}
  //       />
  //     ) : (
  //       text
  //     ),
  // });

  const columns = [
    {
      title: "Room Number",
      dataIndex: "sd_rooms",
      render: (item) => {
        return item.room_number;
      },
    },
    {
      title: "Block Name",
      dataIndex: "sd_rooms",
      render: (item) => {
        return (
          <>
            {item.sd_block_category_details.sd_block_details.block_name}-
            {item.sd_block_category_details.room_type}
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "sd_block_category_booking",
      render: (item) => {
        return item?.first_name + " " + item?.last_name;
      },
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      // ellipsis: true,
      // ...getColumnSearchProps("block_name"),
      render: (text) => {
        return (
          text && (
            <div
              style={{
                // width: "200px",
                whiteSpace: "initial",
                textTransform: "capitalize",
              }}
            >
              {text}
            </div>
          )
        );
      },
    },

    {
      title: "Check In Time",
      dataIndex: "check_in_time",
      render: (text) => {
        return moment(new Date(text)).format("DD-MM-YYYY - ( hh:mm A )");
        // <>
        //   {text ? (
        //     <>
        //       {" "}
        //       {moment(new Date(text)).format("DD-MM-YYYY ")} <br /> (
        //       {moment(new Date(text)).format("hh:mm A")})
        //     </>
        //   ) : (
        //     "None"
        //   )}
        // </>
      },
    },
    {
      title: "Checkout Time",
      dataIndex: "actual_check_out_time",
      render: (text) => {
        return moment(new Date(text)).format("DD-MM-YYYY - ( hh:mm A )");

        // <>
        //   {text ? (
        //     <>
        //       {" "}
        //       {moment(new Date(text)).format("DD-MM-YYYY ")} <br /> (
        //       {moment(new Date(text)).format("hh:mm A")})
        //     </>
        //   ) : (
        //     "None"
        //   )}
        // </>
      },
    },
    {
      title: "Extra Hours",
      dataIndex: "extraHoursStayed",
      key: "extraHoursStayed",
    },
    {
      title: "Options",
      dataIndex: "check_out_time",
      key: "check_out_time",
      render: (text, record) => {
        if (text) {
          return moment(text)
            .utcOffset("+5:30")
            .format(`DD-MM-YYYY - hh:mm A `);
        } else {
          return (
            <Space size="middle">
              <a className="optioncss" onClick={() => checkOutSubmit(record)}>
                Check Out
              </a>
            </Space>
          );
        }
      },
    },
  ];
  return (
    <Table
      tableLayout="fixed"
      scroll={{ x: true }}
      loading={loading}
      style={{ width: "100%", textTransform: "capitalize" }}
      size="medium"
      rowClassName={(record, index) =>
        record.extraHoursStayed > 0 && record.extraHoursStayed <= 1
          ? "sd-light-orange"
          : record.extraHoursStayed > 1 && record.extraHoursStayed <= 2
          ? "sd-color-orange"
          : record.extraHoursStayed > 2 && record.extraHoursStayed < 24
          ? "sd-color-red"
          : record.extraHoursStayed >= 24
          ? "sd-dark-red"
          : ""
      }
      columns={columns}
      dataSource={list}
      pagination={{
        current: tableCurrentPage,
        pageSize: 10,
      }}
      onChange={handleTableChange}
      expandable={{
        expandedRowRender: (record) => (
          <>
            <Row style={{ paddingLeft: "60px" }}>
              <Col span={6}>
                Booked on :
                <p style={{ fontWeight: "bolder", marginRight: "20px" }}>
                  {moment(new Date(record?.booking_date)).format("DD-MM-YYYY")}
                </p>
              </Col>
              <Col span={6}>
                {" "}
                Payment :
                <p style={{ fontWeight: "bolder", marginRight: "20px" }}>
                  ID-{" "}
                  {
                    record.sd_block_category_booking?.sd_cart_item?.sd_cart
                      ?.sd_payment?.id
                  }
                  -
                  <span style={{ textTransform: "capitalize" }}>
                    (
                    {
                      record.sd_block_category_booking?.sd_cart_item?.sd_cart
                        ?.sd_payment?.type
                    }
                    )
                  </span>
                </p>
              </Col>
              <Col span={6}>
                Check Out :
                <p style={{ fontWeight: "bolder", marginRight: "20px" }}>
                  {record?.check_out_time
                    ? moment(new Date(record?.check_out_time)).format(
                        "DD-MM-YYYY - hh:mm A"
                      )
                    : "Not Yet"}
                </p>
              </Col>

              <Col span={6}>
                No of Persons:
                <p style={{ fontWeight: "bolder", marginRight: "20px" }}>
                  {record?.sd_block_category_booking?.no_of_persons}
                </p>
              </Col>
            </Row>
          </>
        ),
      }}
    />
  );
};

export default withRouter(CheckoutTable);
