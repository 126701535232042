/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Col, DatePicker, notification, Row, Table } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { routeMapMini } from '../../../constants/routeMap';
import DarshanamService from '../../../Services/Darshanam.service';

const DisableDarshanam = ({ history }) => {

  const [darshanamList, setDarshanamList] = useState([]);

  const [loading, setLoading] = useState(false);

  const [selectedDarshanams, setSelectedDarshanams] = useState([]);
  const [fromDate, setFromDate] = useState(moment().format(`YYYY-MM-DD`));
  const [toDate, setToDate] = useState(moment().format(`YYYY-MM-DD`));
  const [description, setDescription] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);

  const loadDarshanamList = async () => {
    setLoading(true);
    try {
      const respData = await DarshanamService.all();
      setDarshanamList(
        respData?.map((item) => {
          return {
            key: item.id,
            name: item.name,
          };
        })
      );
    } catch (error) {
      if (error.response?.data?.message) {
        notification.error({ message: error.response?.data?.message });
      } else {
        notification.error({ message: error.message });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadDarshanamList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Darshanam Id',
      dataIndex: 'key',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
  ];

  const toggleSelectAll = () => {
    setSelectedDarshanams((keys) =>
      keys.length === darshanamList.length
        ? []
        : darshanamList.map((r) => r.key)
    );
  };

  const clearFields = () => {
    setDescription('');
    setSelectedDarshanams([]);
  };

  const submit = async () => {
    setSubmitLoading(true);
    try {
      await DarshanamService.disableDates(
        selectedDarshanams,
        fromDate,
        toDate,
        description
      );
      notification.success({
        message: 'Blocked selected Darshanams on selected dates.',
      });
      clearFields();
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: 'Token Expired, redirecting to login page in 5 seconds',
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: 'something is wrong' });
      }
    }
    setSubmitLoading(false);
  };

  return (
    <>
      <Row gutter={[20, 0]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xsl={12}>
          <Table
            scroll={{ x: true }}
            rowSelection={{
              selectedRowKeys: selectedDarshanams,
              onChange: (e) => {
                setSelectedDarshanams(e);
              },
              type: 'checkbox',
              fixed: true,
              onSelect: (record, selected) => {
                if (selected) {
                  setSelectedDarshanams((keys) => [...keys, record.key]);
                } else {
                  setSelectedDarshanams((keys) => {
                    const index = keys.indexOf(record.key);
                    return [...keys.slice(0, index), ...keys.slice(index + 1)];
                  });
                }
              },
              columnTitle: (
                <Checkbox
                  checked={selectedDarshanams.length}
                  indeterminate={
                    selectedDarshanams.length > 0 &&
                    selectedDarshanams.length < darshanamList.length
                  }
                  onChange={toggleSelectAll}
                />
              ),
            }}
            loading={loading}
            columns={columns}
            dataSource={darshanamList}
            // pagination={false}
            size='small'
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xsl={12}
          style={{
            backgroundColor: 'white',
          }}
        >
          <Row justify='center' style={{ padding: '10px', fontWeight: 'bold' }}>
            <a>{selectedDarshanams?.length}</a>{' '}
            <span style={{ marginLeft: '3px' }}>Darshanams selected.</span>
          </Row>
          <Row justify='center'>
            <span
              style={{
                marginRight: '5px',
                marginTop: '4px',
                fontWeight: 'bold',
              }}
            >
              Date Period:
            </span>
            <DatePicker.RangePicker
              onChange={(e) => {
                if (e?.[0] && e?.[1]) {
                  setFromDate(moment(e[0]).format(`YYYY-MM-DD`));
                  setToDate(moment(e[1]).format(`YYYY-MM-DD`));
                } else {
                  setFromDate(undefined);
                  setToDate(undefined);
                }
              }}
              value={[moment(fromDate), moment(toDate)]}
            />
          </Row>
          <Row>
            <div style={{ fontWeight: 'bold' }}>Description: </div>
            <TextArea
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Row>
        </Col>
      </Row>
      <Row justify='end' style={{ marginTop: '5px' }}>
        <Button type='primary' loading={submitLoading} onClick={submit}>
          Submit
        </Button>
      </Row>
    </>
  );
};

export default withRouter(DisableDarshanam);
