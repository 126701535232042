import {Col, Row} from "antd";
import moment from "moment";
import React from "react";
import Barcode from "react-barcode";
import {FormatNumberINRCurrency} from "../../utils/currencyFormat";
import numberToWords from "../../utils/amount-to-words/number-to-words";

const AccomodationTicket = ({
  booking,
  name,
  persons,
  blockName,
  roomType,
  roomNo,
  bookingDate,
  checkInTime,
  checkOutTime,
  roomPrice,
  depositAmount,
  couponDisc,
  extraAmount,
  unPaidDays,
  outstandingAmount,
  totalAmount,
  contact,
  ticketId,
}) => {
  return (
    <>
      <Row className="tickettitle" style={{fontSize: "12px"}}>
        <p>ACCOMMODATION RENT RECEIPT</p>
      </Row>
      <Row className="tickettitle" style={{fontSize: "12px"}}>
        <p style={{textTransform: "uppercase"}}>{booking} Ticket</p>
      </Row>
      <div style={{margin: "0px 20px"}}>
        <Row>
          <Col className="ticket_label">Devotee Name :</Col>
          <Col className="ticket_data">{name}</Col>
        </Row>
        {persons && (
          <Row>
            <Col className="ticket_label">No. of Persons :</Col>
            <Col className="ticket_data">{persons}</Col>
          </Row>
        )}
        <Row>
          <Col className="ticket_label">Room Details :</Col>
          <Col className="ticket_data">
            {blockName} | {roomType} | {roomNo}
          </Col>
        </Row>
        {bookingDate && (
          <Row>
            <Col className="ticket_label">Booking Date :</Col>
            <Col className="ticket_data">
              {moment(bookingDate)
                .utcOffset("+5:30")
                .format(`dddd, DD MMMM YYYY`)}
            </Col>
          </Row>
        )}

        <Row>
          <Col className="ticket_label">Checkin Time :</Col>
          <Col className="ticket_data">
            {moment(checkInTime)
              .utcOffset("+5:30")
              .format(`DD MMMM YYYY - hh:mm A `)}
          </Col>
        </Row>
        <Row>
          <Col className="ticket_label">Checkout Time :</Col>
          <Col className="ticket_data">
            {moment(checkOutTime)
              .utcOffset("+5:30")
              .format(`DD MMMM YYYY - hh:mm A `)}
          </Col>
        </Row>
        <Row>
          <Col style={{width: "40%"}}>Room Price :</Col>
          <Col>{FormatNumberINRCurrency(roomPrice)}</Col>
        </Row>
        {depositAmount > 0 && (
          <Row>
            <Col style={{width: "40%"}}>Deposit Amount :</Col>
            <Col>{FormatNumberINRCurrency(depositAmount)}</Col>
          </Row>
        )}
        {roomPrice - couponDisc > 0 && extraAmount !== 0 && (
          <Row>
            <Col style={{width: "40%"}}>{"GST Amount :"}</Col>
            <Col>{FormatNumberINRCurrency(extraAmount)}</Col>
          </Row>
        )}

        {couponDisc !== 0 && (
          <Row>
            <Col style={{width: "40%"}}>Coupon Discount :</Col>
            <Col>{FormatNumberINRCurrency(couponDisc)}</Col>
          </Row>
        )}
        {booking === "Checkout" ? (
          <>
            {unPaidDays > 0 && (
              <Row>
                <Col className="ticket_label">Un-Paid Days:</Col>
                <Col className="ticket_data">{unPaidDays}</Col>
              </Row>
            )}
            <Row>
              <Col className="ticket_label">
                {outstandingAmount > 0 ? "Amount Paid :" : "Amount Received"}
              </Col>
              <Col className="ticket_data">
                {outstandingAmount > 0
                  ? FormatNumberINRCurrency(outstandingAmount)
                  : FormatNumberINRCurrency(Number(outstandingAmount * -1))}
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col className="ticket_label">Total Amount :</Col>
            <Col className="ticket_data">
              {totalAmount > 0
                ? FormatNumberINRCurrency(totalAmount)
                : FormatNumberINRCurrency(
                    roomPrice + depositAmount + extraAmount - couponDisc
                  )}{" "}
              {unPaidDays > 1 && `(${unPaidDays} days)`}
              <br />(
              {numberToWords(
                totalAmount > 0
                  ? Number(totalAmount)
                  : Number(roomPrice + depositAmount + extraAmount - couponDisc)
              )}{" "}
              Rupees)
            </Col>
          </Row>
        )}
        {contact && (
          <Row>
            <Col className="ticket_label">Phone No. :</Col>
            <Col className="ticket_data">{contact}</Col>
          </Row>
        )}
      </div>
      {ticketId && (
        <Row
          style={{
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <Barcode
            height="35"
            width="2"
            fontSize="15px"
            value={ticketId ? ticketId : "transactionId=127"}
            style={{
              justifyContent: "center",
              margin: "auto",
            }}
          />
        </Row>
      )}
    </>
  );
};

export default AccomodationTicket;
