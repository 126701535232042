import {Button, Col, Input, notification, Row, Select} from "antd";

import Modal from "antd/lib/modal/Modal";
import {useContext, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {routeMapMini} from "../../../constants/routeMap";
import DonationService from "../../../Services/Donation.service";
import DonorRegistrationTable from "./DonorRegistrationTable";
import {UserContext} from "../../../context/user.context";
import RoomsService from "../../../Services/rooms.service";

const DonorRegistration = ({history}) => {
  const [showNewModal, setShowNewModal] = useState(false);
  const [loading, setLoading] = useState();
  const [donorName, setDonorName] = useState("");
  const [CouponAmount, setCouponAmount] = useState();
  const [noOfCoupons, setNoOfCoupons] = useState();
  const [selectedBlockId, setSelectedBlockId] = useState("");
  const [selectedRoomId, setSelectedRoomId] = useState("");
  const [blockNamesAndIds, setBlockNamesAndIds] = useState([]);
  const [roomNameAndIds, setRoomNamesAndIds] = useState([]);
  const [phone, setPhone] = useState("");
  const [updateTable, setUpdateTable] = useState();

  const {userDetails} = useContext(UserContext);

  const getBlockNamesAndIds = async () => {
    try {
      const blockNamesAndIdData = await RoomsService.getAllBlocks();
      setBlockNamesAndIds(blockNamesAndIdData);
    } catch (error) {
      notification.error({message: error.message});
    }
  };

  useEffect(() => {
    getBlockNamesAndIds();
  }, []);

  useEffect(() => {
    if (selectedBlockId) {
      loadRoomList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBlockId]);

  const loadRoomList = async () => {
    if (selectedBlockId === "") {
      notification.error({message: "please select a block."});
      return;
    }
    try {
      const roomsData = await RoomsService.getRoomsByBlockID(selectedBlockId);
      setRoomNamesAndIds(roomsData);
    } catch (error) {
      if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
  };

  //   useEffect(() => {
  //     (async () => {
  //       if (updateDonationId) {
  //         setShowNewModal(true);
  //         try {
  //           const resp = await DonationService.donationById(updateDonationId);
  //           setDonorName(resp?.name);
  //           setTargetDate(moment(resp?.target_date).format(`YYYY-MM-DD`));
  //           setDescription(resp?.description);
  //           setImageUrl(resp?.image_url);
  //           setCouponAmount(resp?.target_amount);
  //           setDisabled(resp?.disabled);
  //           setMinPrice(resp?.min_amount);
  //           setShowNewModal(true);
  //         } catch (error) {
  //           if (error?.response?.status === 401) {
  //             notification.error({
  //               message: "Token Expired, redirecting to login page in 5 seconds",
  //             });
  //             history?.push(routeMapMini.Login);
  //           } else if (error.response?.data?.message) {
  //             notification.error({message: error.response?.data?.message});
  //           } else {
  //             notification.error({message: error.message});
  //           }
  //         }
  //       }
  //     })();
  //   }, [history, updateDonationId]);

  const handleOk = async () => {
    setLoading(true);

    if (!donorName) {
      setLoading(false);
      return notification.error({message: `Donor name is required`});
    } else if (!noOfCoupons || CouponAmount <= 0) {
      setLoading(false);
      return notification.error({
        message: `No of coupons and coupon amount are mandatory`,
      });
    } else if (phone.length !== 10) {
      notification.error({message: "Phone number must be 10 digits"});
      setLoading(false);
      return;
    }
    if (selectedBlockId === "" || selectedRoomId === "") {
      notification.error({message: "Select a block and a room"});
      setLoading(false);
      return;
    }
    try {
      //   if (updateDonationId) {
      //     await DonationService.editDonation(
      //       updateDonationId,
      //       donorName,
      //       description,
      //       CouponAmount,
      //       minPrice,
      //       targetDate,
      //       imageUrl,
      //       disabled
      //     );
      //     notification.success({
      //       message: `Donation ${updateDonationId} updated`,
      //     });
      //     clearFields();
      //     setUpdateTable(updateDonationId + Math.random());
      //   } else {
      const doantion = await DonationService.newDonorRegistartion(
        donorName,
        selectedRoomId,
        selectedBlockId,
        CouponAmount,
        noOfCoupons,
        phone
      );
      notification.success({message: `Donor ${doantion.id} created`});
      setUpdateTable(doantion.id + Math.random());
      //   }
      setShowNewModal(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setDonorName("");
    setNoOfCoupons("");
    setCouponAmount("");
    setSelectedBlockId("");
    setSelectedRoomId("");
    setPhone("");
    // setUpdateDonationId(undefined);
  };

  const handleCancel = () => {
    clearFields();
    setShowNewModal(false);
  };

  return (
    <div style={{width: "100%"}}>
      <Row style={{width: "100%", marginBottom: "15px"}} gutter={[20, 20]}>
        {userDetails.role === "admin" && (
          <Col>
            <Button
              type="primary"
              onClick={() => {
                setShowNewModal(true);
              }}
              disabled={!["admin"].includes(userDetails.role)}
            >
              Add New Donor
            </Button>
          </Col>
        )}
      </Row>
      <Row style={{width: "100%"}}>
        <DonorRegistrationTable
          updateTable={updateTable}
          blockNamesAndIds={blockNamesAndIds}
          //   setUpdateDonationId={setUpdateDonationId}
        />
      </Row>
      <Modal
        visible={showNewModal}
        title="Add New Donation"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Donor Name"
            value={donorName}
            onChange={(e) => {
              setDonorName(e.target.value);
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            size="medium"
            type="number"
            onWheel={(event) => event.currentTarget.blur()}
            maxLength={10}
            addonBefore={"+91"}
            placeholder="Please Enter Mobile Number"
            style={{width: "400px"}}
            value={phone}
            onChange={(e) => {
              if (e.target.value.length > 10) {
                return;
              }
              setPhone(`${e.target.value}`);
            }}
          />
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="No of Coupons"
            type="number"
            value={noOfCoupons}
            onChange={(e) => {
              setNoOfCoupons(e.target.value);
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Coupon Value in Rs"
            type="number"
            value={CouponAmount}
            onChange={(e) => {
              setCouponAmount(e.target.value);
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Col>
            <Row justify="start">Block Name</Row>
            <Row justify="start">
              <Select
                style={{width: "400px"}}
                // loading={blockNamesLoading}
                onChange={setSelectedBlockId}
                defaultValue=""
                value={selectedBlockId}
              >
                {blockNamesAndIds.map((item) => {
                  return (
                    <Select.Option value={item.id}>
                      {item.block_name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Row>
          </Col>
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Col>
            <Row justify="start">Room Number</Row>
            <Row justify="start">
              <Select
                style={{width: "400px"}}
                // loading={blockNamesLoading}
                onChange={setSelectedRoomId}
                defaultValue=""
                value={selectedRoomId}
              >
                {roomNameAndIds.map((item) => {
                  return (
                    <Select.Option value={item.id} key={item.id}>
                      {item.room_number}
                    </Select.Option>
                  );
                })}
              </Select>
            </Row>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(DonorRegistration);
