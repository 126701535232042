import React, {useState} from "react";

export const ShiftContext = React.createContext();

const ShiftContextProvider = (props) => {
  const [showData, setShowData] = useState(false);
  const [shiftContextCounterName, setShiftContextCounterName] = useState();
  const [shiftData, setshiftData] = useState(false);
  const [shiftShowData, setShiftShowData] = useState(null);

  return (
    <ShiftContext.Provider
      value={{
        showData,
        setShowData,
        shiftContextCounterName,
        setShiftContextCounterName,
        shiftData,
        setshiftData,
        shiftShowData,
        setShiftShowData,
      }}
    >
      {props.children}
    </ShiftContext.Provider>
  );
};

export default ShiftContextProvider;
