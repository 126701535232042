import React, { useState, useEffect } from "react";
import { Row, Col, Button, notification, Tag, Space } from "antd";
import RoomsService from "../../Services/rooms.service";
import { routeMapMini } from "../../constants/routeMap";
import { FormatNumberINRCurrency } from "../../utils/currencyFormat";
import moment from "moment";
import { withRouter } from "react-router-dom";
import Modal from "antd/lib/modal/Modal";
import PrintTicket from "./PrintTicket";

const CheckOut = ({
  history,
  roomId,
  setModalVisible,
  from,
  setCheckOutData,
  getCheckoutTable,
  accDashboard,
}) => {
  const [depositeReturned, setDepositeReturned] = useState(0);
  const [outstandingBalance, setOutstandingBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [stats, setStats] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [printConfirm, setPrintConfirm] = useState(false);
  const [printTicketModal, setPrintTicketModal] = useState(false);

  console.log(data);
  const checkoutRoom = async () => {
    setLoading(true);
    try {
      const data = await RoomsService.checkOut(
        roomId,
        depositeReturned,
        outstandingBalance,
        stats
      );
      setData(data);
      if (stats === false) {
        if (from === "allocationTable") {
          setModalVisible(false);
          setIsModalVisible(false);
          setPrintConfirm(true);
          setCheckOutData(data);
          notification.success({
            message: "Checked Out Successfully",
          });
          if (accDashboard) {
            getCheckoutTable();
          }
        } else {
          setIsModalVisible(false);
          setPrintConfirm(true);
          notification.success({
            message: `Checked Out Successfully`,
          });
        }
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
    setLoading(false);
    if (stats === false) {
      setOutstandingBalance(0);
      setDepositeReturned(0);
    }
  };

  useEffect(() => {
    if (stats) {
      checkoutRoom();
    } else {
      setStats(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  useEffect(() => {
    checkoutRoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stats]);

  // const onKeyDown = (e) => {
  //   if (e.key === "Enter") {
  //     checkoutRoom();
  //   }
  // };

  const cancelPrint = () => {
    if (from === "ScanTicket") {
      setPrintConfirm(false);
      window.location.reload();
    } else {
      setPrintConfirm(false);
    }
  };

  const submit = () => {
    data?.outstandingAmount > 0
      ? setOutstandingBalance(data?.outstandingAmount)
      : setDepositeReturned(data?.outstandingAmount * -1);
    setStats(false);
  };
  return (
    <>
      <Col style={{ justifyContent: "center", margin: "auto" }}>
        <Row>
          <Col style={{ width: "50%", textAlign: "left" }}>Unpaid Days :</Col>
          <Col>{data?.unPaidDays}</Col>
        </Row>
        {/* <Row>
        <Col style={{ width: "50%", textAlign: "left" }}>
          {data?.outstandingAmount > 0
            ? "Outstanding Amount :"
            : "Deposite Amount"}
        </Col>
        <Col>
          {data?.outstandingAmount > 0
            ? FormatNumberINRCurrency(data?.outstandingAmount)
            : FormatNumberINRCurrency(data?.depositAmount)}
        </Col>
      </Row> */}
        <Row>
          <Col style={{ width: "50%", textAlign: "left" }}>Deposite Amount</Col>
          <Col>{FormatNumberINRCurrency(data?.depositAmount)}</Col>
        </Row>
        <Row>
          <Col style={{ width: "50%", textAlign: "left" }}>
            Outstanding Amount
          </Col>
          <Col>
            <Tag color={data?.outstandingAmount > 0 ? "green" : "red"}>
              {FormatNumberINRCurrency(data?.outstandingAmount)}
            </Tag>
          </Col>
        </Row>
        <Row>
          <Col style={{ width: "50%", textAlign: "left" }}>Check In Time :</Col>
          <Col>
            {moment(data?.checkInTime).format("ddd, MMM Do YYYY, h:mm:ss a")}
          </Col>
        </Row>
        <Row>
          <Col style={{ width: "50%", textAlign: "left" }}>
            Actual Check Out Time :
          </Col>
          <Col>
            {moment(data?.currentCheckOutTime).format(
              "ddd, MMM Do YYYY, h:mm:ss a"
            )}
          </Col>
        </Row>
        <Row>
          <Col style={{ width: "50%", textAlign: "left" }}>
            Current Check Out Time :
          </Col>
          <Col>{moment().format("ddd, MMM Do YYYY, h:mm:ss a")}</Col>
        </Row>
        {/* <Row>
        <Col style={{ width: "50%", textAlign: "left" }}>Room :</Col>
        <Col>
          {roomNo} | {blockName}
        </Col>
      </Row> */}
        {data?.outstandingAmount !== 0 && (
          <Row style={{ marginTop: "10px" }}>
            <Col style={{ width: "50%", textAlign: "left" }}>
              {" "}
              {data?.outstandingAmount > 0
                ? "Amount To Be Paid :"
                : "Amount To Be Returned"}{" "}
            </Col>
            <Col>
              {/* <Input
              type="number"
              onChange={(e) => {
                data?.outstandingAmount > 0
                  ? setOutstandingBalance(e.target.value)
                  : setDepositeReturned(e.target.value);
              }}
              onKeyDown={onKeyDown}
            /> */}
              {data?.outstandingAmount > 0
                ? FormatNumberINRCurrency(data?.outstandingAmount)
                : FormatNumberINRCurrency(data?.outstandingAmount * -1)}
            </Col>
          </Row>
        )}
        <Row style={{ marginTop: "20px" }}>
          <Button
            type="primary"
            onClick={() => {
              if (data?.outstandingAmount === 0) {
                submit();
              } else {
                setIsModalVisible(true);
              }
            }}
            loading={loading}
          >
            Check Out
          </Button>
        </Row>
      </Col>
      <Modal
        visible={isModalVisible}
        width={850}
        footer={null}
        closable={false}
        centered
      >
        <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
          {data?.outstandingAmount > 0
            ? "Oustanding Amount Received ?"
            : "Deposit Amount Returned ?"}
        </Row>
        <Row style={{ justifyContent: "center", margin: "auto" }}>
          <Space>
            <Button type="primary" onClick={submit}>
              Yes
            </Button>
            <Button onClick={() => setIsModalVisible(false)}>No</Button>
          </Space>
        </Row>
      </Modal>
      <Modal
        visible={printConfirm}
        width={850}
        footer={null}
        closable={false}
        centered
      >
        <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
          Do You want to Print Ticket ?
        </Row>
        <Row style={{ justifyContent: "center", margin: "auto" }}>
          <Space>
            <Button
              type="primary"
              onClick={() => {
                setPrintConfirm(false);
                setPrintTicketModal(true);
              }}
            >
              Yes
            </Button>
            <Button onClick={cancelPrint}>No</Button>
          </Space>
        </Row>
      </Modal>
      <Modal
        visible={printTicketModal}
        width={850}
        footer={null}
        closable={false}
        centered
      >
        <PrintTicket
          type="accomodation"
          booking="Checkout"
          from={from}
          checkInTime={data?.checkInTime}
          checkOutTime={moment()}
          roomPrice={data?.roomPrice}
          depositAmount={data?.depositAmount}
          extraAmount={data?.GST}
          couponDisc={0}
          unPaidDays={data?.unPaidDays}
          outstandingAmount={data?.outstandingAmount}
          setData={setData}
          accDashboard={accDashboard}
          setModalVisible={setModalVisible}
          blockName={data?.blockName}
          roomNo={data?.roomNumber}
          name={`${data?.firstName} ${data?.lastName}`}
          getCheckoutTable={getCheckoutTable}
          setPrintTicketModal={setPrintTicketModal}
        />
      </Modal>
    </>
  );
};

export default withRouter(CheckOut);
