import {
  Button,
  Col,
  DatePicker,
  notification,
  Row,
  Select,
  Statistic,
  Divider,
} from "antd";
import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import {withRouter} from "react-router";
import {routeMapMini} from "../../constants/routeMap";
import {UserContext} from "../../context/user.context";
import DashStatsService from "../../Services/DashStats.service";
import DarshanamService from "../../Services/Darshanam.service";
import DonationService from "../../Services/Donation.service";
import SevaService from "../../Services/Seva.service";
import srisailaPrabhaService from "../../Services/SrisailaPrabha.service";
import "./dt.scss";
import {UserAddOutlined} from "@ant-design/icons";
import ThulabharamService from "../../Services/Thulabharam.service";

import {
  sd_accommodation,
  sd_book,
  sd_cart,
  sd_darshanam,
  sd_donation,
  sd_hands,
  sd_pooja,
  sd_registered_users,
} from "../../assets/images";
import CustomChart from "./Graphs/Dt-chart";
import PrasadamService from "../../Services/Prasadam.service";
import TollGateServices from "../../Services/TollGate.service";
import KailasaKankanamService from "../../Services/KailasaKankanam.service";
import {FormatNumberINRCurrencyDecimalSeperator as FormatNumberINRCurrency} from "../../utils/currencyFormat";
const Dashboard = ({history}) => {
  const {userDetails} = useContext(UserContext);
  const [total, setTotal] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const getCollections = async () => {
    try {
      const data = await DashStatsService.getAllCollections(
        aggregationType,
        fromDate,
        toDate,
        txType,
        dateType
      );
      setData(data);

      let total = 0;
      let totalC = 0;
      data?.totalcollectionResult?.filter((e) => {
        total += Number(e?.collection);
        totalC += Number(e?.count);
      });
      setTotal(total);
      setTotalCount(totalC);
      setFormatData(
        data?.totalcollectionResult?.map((item) => {
          // console.log(item);
          return {
            bookingType: item?.bookingType,
            collection: Number(item?.collection),
            total: item?.count,
          };
        })
      );
      setHeading("Total Collection");
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page.",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
  };
  const [aggregationType, setAggregationType] = useState("day");
  const [fromDate, setFromDate] = useState(
    moment().subtract(15, aggregationType).format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [formatData, setFormatData] = useState([
    {
      bookingType: "seva",
      collection: 0,
      total: 0,
    },
    {
      bookingType: "donation",
      collection: 0,
    },
    {
      bookingType: "darshanam",
      collection: 0,
    },
    {
      bookingType: "srisailaPrabha",
      collection: 0,
    },
    {
      bookingType: "accomodation",
      collection: 0,
    },
  ]);
  const [txType, setTxType] = useState("counter");
  const [dateType, setDateType] = useState("orderDate");
  const [Heading, setHeading] = useState("Total Collection");
  const [data, setData] = useState({
    totalcollectionResult: [],
    datecollectionResult: [],
    userData: {
      usersByDate: [],
      totalUsers: 0,
    },
  });

  useEffect(() => {
    if (["admin"].includes(userDetails.role)) {
      getCollections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails.role, fromDate, toDate, aggregationType]);

  const getSevaTypeCollection = async (type, seva) => {
    try {
      let body = {
        type,
        fromDate,
        toDate,
        dateType,
        sevaType: seva,
        txType,
      };
      // console.log(body);
      const resp = await SevaService.getCollectionBySeva(body);
      const temp = resp.map((item) => {
        return {
          bookingType: type === "sevaType" ? item.seva_type : item.seva_name,
          collection: Number(item.collection),
          total: item?.total,
        };
      });
      setHeading(
        seva === "parokshaSeva"
          ? "Paroksha Seva"
          : seva === "pratyakshaSeva"
          ? "Pratyaksha Seva"
          : ""
      );
      setFormatData(temp);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page.",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
  };

  const getDonationTypeCollection = async () => {
    try {
      const resp = await DonationService.getCollectionByDonation(
        fromDate,
        toDate,
        txType
      );
      const temp = resp.map((item) => {
        return {
          bookingType: item.donation_name,
          collection: Number(item.collection),
          total: item?.total,
          type: "donation",
        };
      });
      setHeading("Donations");
      setFormatData(temp);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page.",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
  };

  const getDarshanamTypeCollection = async () => {
    try {
      const resp = await DarshanamService.getCollectionByDarshanam(
        fromDate,
        toDate,
        dateType,
        txType
      );
      const temp = resp.map((item) => {
        return {
          bookingType: item.darshanam_name,
          collection: Number(item.collection),
          total: item?.total,
          type: "darshanam",
        };
      });
      setHeading("Darshanam");
      setFormatData(temp);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page.",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
  };

  const getPublicationsCollection = async () => {
    try {
      const resp = await srisailaPrabhaService.getCollectionByType(
        fromDate,
        toDate,
        txType
      );
      const temp = resp.map((item) => {
        return {
          bookingType: item.publication_name,
          collection: Number(item.collection),
          total: item?.total,
          type: "publications",
        };
      });
      setFormatData(temp);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page.",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
  };
  const getPrasadamsCollection = async () => {
    try {
      const resp = await PrasadamService.getPrasadamReportCollection(
        fromDate,
        toDate,
        txType
      );
      const temp = resp.map((item) => {
        return {
          bookingType: item.name,
          collection: Number(item.collection),
          total: item?.total,
          type: "prasadam",
        };
      });
      setFormatData(temp);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page.",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
  };
  const getKailasaKankanamCollection = async () => {
    try {
      const resp =
        await KailasaKankanamService.getKailasaKankanamReportCollection(
          fromDate,
          toDate,
          txType
        );
      const temp = resp.map((item) => {
        return {
          bookingType: item.name,
          collection: Number(item.collection),
          total: item?.total,
          type: "prasadam",
        };
      });
      setFormatData(temp);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page.",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
  };
  const getTollGateCollection = async () => {
    try {
      let resp = await TollGateServices.getTollGateReportCollection(
        fromDate,
        toDate,
        txType
      );
      if (!resp) {
        resp = [];
      }
      const temp = resp.map((item) => {
        return {
          bookingType: item.name,
          collection: Number(item.collection),
          total: item?.total,
          type: "tollgate",
        };
      });
      setFormatData(temp);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page.",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
  };
  const getThulabharamCollection = async () => {
    try {
      let resp = await ThulabharamService.getThulabharamReportCollection(
        fromDate,
        toDate,
        txType
      );
      if (!resp) {
        resp = [];
      }
      const temp = resp.map((item) => {
        return {
          bookingType: item.name,
          collection: Number(item.collection),
          total: item?.total,
          type: "thulabharam",
        };
      });
      setHeading("Thulabharam");
      setFormatData(temp);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page.",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
  };

  return (
    <>
      <Row gutter={[20, 20]} justify="start">
        <Col xs={24} sm={12} md={12} lg={7} xl={7} xsl={7} className="flex-d">
          <Row justify="start" className="whitespace">
            Date Period
          </Row>
          <Row justify="start" style={{width: "80%"}}>
            <DatePicker.RangePicker
              style={{minWidth: "100%"}}
              onChange={(e) => {
                if (e?.[0] && e?.[1]) {
                  setFromDate(moment(e[0]).format(`YYYY-MM-DD`));
                  setToDate(moment(e[1]).format(`YYYY-MM-DD`));
                } else {
                  setFromDate(undefined);
                  setToDate(undefined);
                }
              }}
              format="DD-MM-YYYY"
              ranges={{
                Today: [moment(), moment()],
                "Last 7 Days": [moment().subtract(7, "d"), moment()],
                "Last 15 Days": [moment().subtract(15, "d"), moment()],
              }}
              value={[moment(fromDate), moment(toDate)]}
            />
          </Row>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xsl={6} className="flex-d">
          <Row justify="start" className="whitespace">
            Transaction Type
          </Row>
          <Row justify="start" style={{width: "80%"}}>
            <Select
              style={{width: "100%"}}
              onChange={(e) => {
                setTxType(e);
              }}
              value={txType}
            >
              <Select.Option value="counter">Counter</Select.Option>
              <Select.Option value="online">Online</Select.Option>
            </Select>
          </Row>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xsl={6} className="flex-d ">
          <Row justify="start" className="whitespace">
            Date Type
          </Row>
          <Row justify="start" style={{width: "80%"}}>
            <Select
              style={{width: "100%"}}
              onChange={(e) => {
                setDateType(e);
              }}
              value={dateType}
            >
              <Select.Option value="orderDate">Order Date</Select.Option>
              <Select.Option value="performanceDate">
                Performance Date
              </Select.Option>
            </Select>
          </Row>
        </Col>
        <Col xs={24} sm={12} md={12} lg={5} xl={5} xsl={5} className="flex-d">
          <Row justify="start" className="whitespace">
            Update
          </Row>
          <Row justify="start">
            <Button
              style={{width: "100px"}}
              type="primary"
              onClick={() => {
                getCollections();
              }}
            >
              Fetch
            </Button>
          </Row>
        </Col>
      </Row>

      <Divider style={{margin: "8px 0px 16px 0px"}} />
      <Row justify="space-between">
        <div
          className="statistic-box color-1"
          onClick={() => {
            getCollections();
          }}
        >
          <div className="top-i">
            <img src={sd_cart} alt="" />
            <Statistic
              formatter={FormatNumberINRCurrency}
              title="Total Collection"
              value={total !== 0 ? total : 0}
            />
          </div>
          <Divider />
          <div className="tick">
            <UserAddOutlined />
            No of Bookings: <span className="pl-5">{totalCount}</span>
          </div>
        </div>
        <div
          className="statistic-box color-2"
          onClick={(e) => {
            let seva = "pratyakshaSeva";
            getSevaTypeCollection("sevaName", seva);
            setHeading("Pratyaksha Seva");
          }}
        >
          <div className="top-i">
            <img src={sd_pooja} alt="" />
            <Statistic
              formatter={FormatNumberINRCurrency}
              title="Pratyaksha Seva"
              value={
                data?.totalcollectionResult?.find(
                  (e) => e.bookingType === "pratyakshaSeva"
                )?.collection !== 0
                  ? data?.totalcollectionResult?.find(
                      (e) => e.bookingType === "pratyakshaSeva"
                    )?.collection
                  : 0
              }
            />
          </div>

          <Divider />
          <div className="tick">
            <UserAddOutlined />
            No of Bookings:{" "}
            <span className="pl-5">
              {" "}
              {
                data?.totalcollectionResult?.find(
                  (e) => e.bookingType === "pratyakshaSeva"
                )?.count
              }
            </span>
          </div>
        </div>

        <div
          className="statistic-box color-3"
          onClick={(e) => {
            let seva = "parokshaSeva";
            getSevaTypeCollection("sevaName", seva);
            setHeading("Paroksha Seva");
          }}
        >
          <div className="top-i">
            <img src={sd_hands} alt="" />
            <Statistic
              formatter={FormatNumberINRCurrency}
              title="Paroksha Seva"
              value={
                data?.totalcollectionResult?.find(
                  (e) => e.bookingType === "parokshaSeva"
                )?.collection
                  ? data?.totalcollectionResult?.find(
                      (e) => e.bookingType === "parokshaSeva"
                    )?.collection
                  : 0
              }
            />
          </div>
          <Divider />
          <div style={{color: "white"}}>
            <UserAddOutlined /> No of Bookings:{" "}
            <span className="pl-5">
              {" "}
              {
                data?.totalcollectionResult?.find(
                  (e) => e.bookingType === "parokshaSeva"
                )?.count
              }
            </span>
          </div>
        </div>

        <div
          className="statistic-box responsive-box4-largescreen color-4"
          onClick={(e) => {
            getDarshanamTypeCollection();
            setHeading("Darshanam");
          }}
        >
          <div className="top-i">
            <img src={sd_darshanam} alt="" />
            <Statistic
              formatter={FormatNumberINRCurrency}
              title="Darshanam"
              value={
                data?.totalcollectionResult?.find(
                  (e) => e.bookingType === "darshanam"
                )?.collection
                  ? data?.totalcollectionResult?.find(
                      (e) => e.bookingType === "darshanam"
                    )?.collection
                  : 0
              }
            />
          </div>
          <Divider />
          <div className="tick">
            <UserAddOutlined />
            No of Bookings:{" "}
            <span className="pl-5">
              {" "}
              {
                data?.totalcollectionResult?.find(
                  (e) => e.bookingType === "darshanam"
                )?.count
              }
            </span>
          </div>
        </div>

        <div
          className="statistic-box responsive-box5-largescreen color-5"
          onClick={(e) => {
            getDonationTypeCollection();
          }}
        >
          <div className="top-i">
            <img src={sd_donation} alt="" />
            <Statistic
              formatter={FormatNumberINRCurrency}
              title="Donations"
              value={
                data?.totalcollectionResult?.find(
                  (e) => e.bookingType === "donation"
                )?.collection
                  ? data?.totalcollectionResult?.find(
                      (e) => e.bookingType === "donation"
                    )?.collection
                  : 0
              }
            />
          </div>
          <Divider />
          <div className="tick">
            <UserAddOutlined />
            No of Bookings:{" "}
            <span className="pl-5">
              {" "}
              {
                data?.totalcollectionResult?.find(
                  (e) => e.bookingType === "donation"
                )?.count
              }
            </span>
          </div>
        </div>
      </Row>
      <Row className="sd-graph-row" justify="space-between">
        {/*middle cards and graph*/}
        <div className="sd-graph-side-right">
          {" "}
          {/*---Prasadam/TollGate/Thulabharam---*/}
          {/*---Prasadam---*/}
          <div
            className="statistic-box-side color-6"
            onClick={(e) => {
              getPrasadamsCollection();
              setHeading("Prasadam");
            }}
          >
            <div className="top-i">
              <img src={sd_accommodation} alt="" />
              <Statistic
                formatter={FormatNumberINRCurrency}
                title="Prasadam"
                value={
                  data?.totalcollectionResult?.find(
                    (e) => e.bookingType === "prasadam"
                  )?.collection
                    ? data?.totalcollectionResult?.find(
                        (e) => e.bookingType === "prasadam"
                      )?.collection
                    : 0
                }
              />
            </div>
            <Divider />
            <div className="tick">
              <UserAddOutlined />
              No of Bookings:{" "}
              <span className="pl-5">
                {" "}
                {
                  data?.totalcollectionResult?.find(
                    (e) => e.bookingType === "prasadam"
                  )?.count
                }
              </span>
            </div>
          </div>
          {/*---Toll Gate---*/}
          <div
            className="statistic-box-side color-7"
            style={{marginTop: "10px"}}
            // onClick={() => {
            //   getTollGateCollection();
            //   setHeading("Toll Gate");
            // }}
          >
            <div className="top-i">
              <img src={sd_accommodation} alt="" />
              <Statistic
                formatter={FormatNumberINRCurrency}
                title="Accommodation"
                value={
                  data?.totalcollectionResult?.find(
                    (e) => e.bookingType === "accomodation"
                  )?.collection
                    ? data?.totalcollectionResult?.find(
                        (e) => e.bookingType === "accomodation"
                      )?.collection
                    : 0
                }
              />
            </div>
            <Divider />
            <div className="tick">
              <UserAddOutlined />
              No of Bookings:{" "}
              <span className="pl-5">
                {" "}
                {
                  data?.totalcollectionResult?.find(
                    (e) => e.bookingType === "accomodation"
                  )?.count
                }
              </span>
            </div>
          </div>
          {/*---Thulabharam---*/}
          <div
            className="statistic-box-side color-2"
            style={{marginTop: "10px"}}
            onClick={() => {
              getThulabharamCollection();
              setHeading("Thulabharam");
            }}
          >
            <div className="top-i">
              <img src={sd_registered_users} alt="" />
              <Statistic
                formatter={FormatNumberINRCurrency}
                title="Thulabharam"
                value={
                  data?.totalcollectionResult?.find(
                    (e) => e.bookingType === "thulabharam"
                  )?.collection
                    ? data?.totalcollectionResult?.find(
                        (e) => e.bookingType === "thulabharam"
                      )?.collection
                    : 0
                }
              />
            </div>
            <Divider />
            <div className="tick">
              <UserAddOutlined />
              No of Bookings: <span className="pl-5">{"0"}</span>
              {
                data?.totalcollectionResult?.find(
                  (e) => e.bookingType === "thulabharam"
                )?.count
              }
            </div>
          </div>
        </div>

        <div className="sd-graph">
          {/*---Graph chart---*/}
          <div className="sd-graph-heading">{Heading}</div>
          <Divider />
          <CustomChart
            update={data?.updatedAt}
            dataCollection={formatData}
            fromDate={fromDate}
            toDate={toDate}
            dateType={dateType}
            getSevaTypeCollection={getSevaTypeCollection}
            getDonationTypeCollection={getDonationTypeCollection}
            getDarshanamTypeCollection={getDarshanamTypeCollection}
            getPublicationsCollection={getPublicationsCollection}
            getPrasadamsCollection={getPrasadamsCollection}
            getTollGateCollection={getTollGateCollection}
            getThulabharamCollection={getThulabharamCollection}
          />
        </div>
      </Row>
      <Row>
        {" "}
        {/* Bottom Cards- Accomadation,Publications,registered users,kalyana katta,shop rents*/}
        <div className="sd-graph-side">
          {/*---Darshanam res---*/}
          <div
            className="statistic-box-side responsive-box4-smallscreen color-4"
            onClick={(e) => {
              getDarshanamTypeCollection();
              setHeading("Darshanam");
            }}
          >
            <div className="top-i">
              <img src={sd_darshanam} alt="" />
              <Statistic
                formatter={FormatNumberINRCurrency}
                title="Darshanam"
                value={
                  data?.totalcollectionResult?.find(
                    (e) => e.bookingType === "darshanam"
                  )?.collection
                    ? data?.totalcollectionResult?.find(
                        (e) => e.bookingType === "darshanam"
                      )?.collection
                    : 0
                }
              />
            </div>
            <Divider />
            <div className="tick">
              <UserAddOutlined />
              No of Bookings:{" "}
              <span className="pl-5">
                {
                  data?.totalcollectionResult?.find(
                    (e) => e.bookingType === "darshanam"
                  )?.count
                }
              </span>
            </div>
          </div>
          {/*---Donation  res---*/}
          <div
            className="statistic-box-side responsive-box5-smallscreen color-5"
            onClick={(e) => {
              getDonationTypeCollection();
            }}
          >
            <div className="top-i">
              <img src={sd_donation} alt="" />
              <Statistic
                formatter={FormatNumberINRCurrency}
                title="Donations"
                value={
                  data?.totalcollectionResult?.find(
                    (e) => e.bookingType === "donation"
                  )?.collection
                    ? data?.totalcollectionResult?.find(
                        (e) => e.bookingType === "donation"
                      )?.collection
                    : 0
                }
              />
            </div>
            <Divider />
            <div className="tick">
              <UserAddOutlined />
              No of Bookings:{" "}
              <span className="pl-5">
                {" "}
                {
                  data?.totalcollectionResult?.find(
                    (e) => e.bookingType === "donation"
                  )?.count
                }
              </span>
            </div>
          </div>

          {/*---Registered Users---*/}
          <div className="statistic-box color-6 ">
            <div className="top-i">
              <img src={sd_registered_users} alt="" />
              <Statistic
                title="Registered Users"
                value={data?.userData?.totalUsers}
              />
            </div>
            <Divider />
            <div className="tick">Users Count</div>
          </div>

          {/*---Publication---*/}
          <div
            className="statistic-box color-7 "
            onClick={() => {
              //
            }}
          >
            <div
              className="top-i"
              onClick={() => {
                getPublicationsCollection();
                setHeading("Publications");
              }}
            >
              <img className="s-40" src={sd_book} alt="" />
              <Statistic
                formatter={FormatNumberINRCurrency}
                title="Publications"
                value={
                  data?.totalcollectionResult?.find(
                    (e) => e.bookingType === "publication"
                  )?.collection
                    ? data?.totalcollectionResult?.find(
                        (e) => e.bookingType === "publication"
                      )?.collection
                    : 0
                }
              />
            </div>
            <Divider />
            <div className="tick">
              <UserAddOutlined />
              No of Bookings:{" "}
              <span className="pl-5">
                {
                  data?.totalcollectionResult?.find(
                    (e) => e.bookingType === "publication"
                  )?.count
                }
              </span>
            </div>
          </div>

          <div
            className="statistic-box color-2 "
            onClick={() => {
              getTollGateCollection();
              setHeading("Toll Gate");
            }}
          >
            <div
              className="top-i"
              onClick={() => {
                // getTollGateCollection();
              }}
            >
              <img className="s-40" src={sd_pooja} alt="" />
              <Statistic
                formatter={FormatNumberINRCurrency}
                title="Toll Gate"
                value={
                  data?.totalcollectionResult?.find(
                    (e) => e.bookingType === "tollgate"
                  )?.collection
                    ? data?.totalcollectionResult?.find(
                        (e) => e.bookingType === "tollgate"
                      )?.collection
                    : 0
                }
              />
            </div>
            <Divider />
            <div className="tick">
              <UserAddOutlined />
              No of Bookings:{" "}
              <span className="pl-5">
                {
                  data?.totalcollectionResult?.find(
                    (e) => e.bookingType === "tollgate"
                  )?.count
                }
              </span>
            </div>
          </div>

          {/*---Kalayana Katta---*/}
          <div className="statistic-box  color-6">
            <div className="top-i">
              <img src={sd_accommodation} alt="" />
              <Statistic
                formatter={FormatNumberINRCurrency}
                title="Kalayana Katta"
                value={
                  data?.totalcollectionResult?.find(
                    (e) => e.bookingType === "kalaynakatta"
                  )?.collection
                    ? data?.totalcollectionResult?.find(
                        (e) => e.bookingType === "kalaynakatta"
                      )?.collection
                    : 0
                }
              />
            </div>
            <Divider />
            <div className="tick">
              <UserAddOutlined />
              No of Bookings:{" "}
              <span className="pl-5">
                {
                  data?.totalcollectionResult?.find(
                    (e) => e.bookingType === "kalaynakatta"
                  )?.count
                }
              </span>
            </div>
          </div>

          {/*---Shop rents---*/}
          <div
            className="statistic-box  color-7"
            onClick={() => {
              //
            }}
          >
            <div
              className="top-i"
              onClick={() => {
                // getPublicationsCollection();
              }}
            >
              <img className="s-40" src={sd_book} alt="" />
              <Statistic
                formatter={FormatNumberINRCurrency}
                title="Shop Rents"
                value={
                  data?.totalcollectionResult?.find(
                    (e) => e.bookingType === "shopRents"
                  )?.collection
                    ? data?.totalcollectionResult?.find(
                        (e) => e.bookingType === "shopRents"
                      )?.collection
                    : 0
                }
              />
            </div>
            {/* <div
              className="top-i"
              onClick={() => {
                getKailasaKankanamCollection();
              }}
            >
              <img className="s-40" src={sd_book} alt="" />
              <Statistic
                formatter={FormatNumberINRCurrency}
                title="Kailasa Kankanam"
                value={
                  data?.totalcollectionResult?.find(
                    (e) => e.bookingType === "kailasakanaknam"
                  )?.collection
                    ? data?.totalcollectionResult?.find(
                        (e) => e.bookingType === "kailasakanaknam"
                      )?.collection
                    : 0
                }
              />
            </div> */}
            <Divider />
            <div className="tick">
              <UserAddOutlined />
              No of Bookings:{" "}
              <span className="pl-5">
                {" "}
                {
                  data?.totalcollectionResult?.find(
                    (e) => e.bookingType === "shopRents"
                  )?.count
                }
              </span>
            </div>
          </div>
        </div>
      </Row>
    </>
  );
};

export default withRouter(Dashboard);
