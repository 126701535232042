import React, { useState } from "react";

export const UserContext = React.createContext();

const UserContextProvider = (props) => {
  const [userDetails, setUserDetails] = useState(false);

  return (
    <UserContext.Provider value={{ setUserDetails, userDetails }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
