import React, {useState} from "react";
import SevaService from "../Services/Seva.service";

export const SADContext = React.createContext();

const SADContextProvider = (props) => {
  const [selectedPratyakshaSeva, setSelectedPratyakshaSeva] = useState("");
  const [selectedDonation, setSelectedDonation] = useState(null);

  const getSevaList = async (seva, setLoading, setSevaList, catchFunction) => {
    try {
      setLoading(true);
      let res = await SevaService.getAll(seva);
      setSevaList(res);
      setLoading(false);
    } catch (error) {
      console.log(error);
      catchFunction(error);
    }
  };
  const verifyIDProof = (item, callback) => {
    if (item === "pan") {
      let txt = item["idProofNumber"].toUpperCase();
      let regex = /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
      let pan = {
        C: "Company",
        P: "Personal",
        H: "Hindu Undivided Family (HUF)",
        F: "Firm",
        A: "Association of Persons (AOP)",
        T: "AOP (Trust)",
        B: "Body of Individuals (BOI)",
        L: "Local Authority",
        J: "Artificial Juridical Person",
        G: "Govt",
      };
      pan = pan[txt[3]];
      if (regex.test(txt)) {
        if (pan !== "Personal") {
          callback("Invalid Pan Card Number.");
        }
      } else callback("Invalid Pan Card Number.");
    } else if (item === "driving") {
      let regex =
        /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
      if (!regex.test(item["idProofNumber"].toUpperCase())) {
        // data.push({
        //   type: `${item["id"]}-idProofNumber`,
        //   msg: "Invalid driving license number",
        // });
      }
    } else if (item === "voter") {
      let regex = /^([a-zA-Z]){3}([0-9]){7}?$/;
      if (!regex.test(item["idProofNumber"].toUpperCase())) {
        callback("Invalid Voter Card Number.");
      }
    } else if (item === "aadhar") {
      let regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
      if (!regex.test(item["idProofNumber"].toUpperCase())) {
        callback("Invalid Aadhar Card Number.");
      }
    }
  };

  return (
    <SADContext.Provider
      value={{
        selectedPratyakshaSeva,
        setSelectedPratyakshaSeva,
        getSevaList,
        verifyIDProof,
        selectedDonation,
        setSelectedDonation,
      }}
    >
      {props.children}
    </SADContext.Provider>
  );
};

export default SADContextProvider;
