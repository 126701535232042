/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Input,
  notification,
  Popconfirm,
  Space,
  Table,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import UserService from "../../Services/User.service";
import { routeMapMini } from "../../constants/routeMap";

const UsersTable = ({
  history,
  updateTable,
  setRole,
  setPhone,
  setShowManageUserModal,
  handleOk,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const getUsers = async () => {
    setLoading(true);
    try {
      const data = await UserService.managementUsers();
      setAllUsers(data);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTable]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "User",
      dataIndex: "display_name",
      key: "display_name",
      ...getColumnSearchProps("display_name"),
      render: (text, record) => {
        return (
          <a
            onClick={() => {
              history?.push(`${routeMapMini.userDetails}?userId=${record.id}`);
            }}
            style={{ textTransform: "capitalize" }}
          >
            {text}
          </a>
        );
      },
    },
    {
      title: "Added By",
      dataIndex: "role_changed_by",
      key: "addedrole_changed_byBy",
      sorter: (a, b) => a.addedBy > b.addedBy,
    },
    {
      title: "Role",
      key: "role",
      dataIndex: "role",
      filters: [
        {
          text: "admin",
          value: "admin",
        },
        {
          text: "manager",
          value: "manager",
        },
        {
          text: "operator",
          value: "operator",
        },
      ],
      onFilter: (value, record) => {
        return record.role === value;
      },
      render: (text) => {
        if (text === "admin") {
          return <Tag color="green">Admin</Tag>;
        } else if (text === "operator") {
          return <Tag color="orange">Operator</Tag>;
        } else if (text === "manager") {
          return <Tag color="blue">Manager</Tag>;
        } else {
          return <Tag color="red">{text}</Tag>;
        }
      },
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={() => {
                if (record.phone?.substring(3)) {
                  setPhone(record.phone?.substring(3));
                  setRole("user");
                  handleOk("user", record.phone?.substring(3));
                }
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm>
            <a
              onClick={() => {
                setShowManageUserModal(true);
                setPhone(record.phone?.substring(3));
                setRole(record.role);
              }}
            >
              Modify
            </a>
          </Space>
        );
      },
    },
  ];
  return (
    <Table
      scroll={{ x: true }}
      style={{ width: "100%", textTransform: "capitalize" }}
      columns={columns}
      dataSource={allUsers}
      loading={loading}
      size="small"
    ></Table>
  );
};

export default withRouter(UsersTable);
