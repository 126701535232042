import React, {useContext, useEffect, useState} from "react";
import {Layout, Menu} from "antd";
import {routeMap} from "../../constants/routeMap";
import {withRouter} from "react-router-dom";
import {UserContext} from "../../context/user.context";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import srisailamLogo from "../../assets/srisailam_logo.png";
import "./Slider.css";

const {Sider} = Layout;

const HomeSider = ({history, siderCollapsed, location, toggleSider}) => {
  const [selectedNavItem, setSelectedNavItem] = useState(["/"]);

  const [routeMapState, setRouteMapState] = useState();
  const {userDetails} = useContext(UserContext);

  const [collapsedWidth, setCollapsedWidth] = useState(80);

  const screens = useBreakpoint();

  useEffect(() => {
    if (screens?.xl || screens?.xxl || screens?.lg) {
      setCollapsedWidth(80);
    } else {
      setCollapsedWidth(0);
    }
  }, [screens]);

  const onMenuClick = (event) => {
    const {key} = event;
    if (collapsedWidth === 0) {
      toggleSider();
    }
    history.push(key);
  };

  useEffect(() => {
    setRouteMapState(
      routeMap.map((item, index) => {
        return item.inLayout && item.accessedBy.includes(userDetails.role) ? (
          <Menu.Item key={item.route} icon={<item.icon />}>
            {item.name}
          </Menu.Item>
        ) : null;
      })
    );

    setSelectedNavItem(
      routeMap.find((_item) => location.pathname.startsWith(_item.route))?.route
    );
  }, [location.pathname, userDetails]);

  return (
    <Sider
      className="menu_sider"
      collapsible
      onCollapse={toggleSider}
      collapsed={siderCollapsed}
      collapsedWidth={collapsedWidth}
      style={{
        position: "fixed",
        left: 0,
        zIndex: 1000,
      }}
      defaultCollapsed={true}
    >
      <div
        className="logo"
        style={{
          height: "54px",
          backgroundImage: srisailamLogo,
          marginTop: "10px",
        }}
      >
        <img src={srisailamLogo} alt="srisaila_logo" height="40px" />
      </div>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={selectedNavItem}
        onClick={onMenuClick}
      >
        {routeMapState}
      </Menu>
    </Sider>
  );
};

export default withRouter(HomeSider);
