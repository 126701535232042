/* eslint-disable jsx-a11y/anchor-is-valid */
import {Button, Col, Input, notification, Row, Space, Upload} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import TextArea from "antd/lib/input/TextArea";
import Modal from "antd/lib/modal/Modal";
import React, {useContext, useState} from "react";
import {useEffect} from "react";
import {withRouter} from "react-router";
import {UploadOutlined} from "@ant-design/icons";
import {routeMapMini} from "../../constants/routeMap";
import {UserContext} from "../../context/user.context";
import PublicationService from "../../Services/Publication.service";
import PublicationTable from "./PublicationTable";
import {baseURL} from "../../utils/axios";

const Publications = ({history}) => {
  const {userDetails} = useContext(UserContext);

  const [showNewModal, setShowNewModal] = useState(false);

  const [updateTable, setUpdateTable] = useState();

  const [updatePublicationId, setUpdatePublicationId] = useState();

  const [loading, setLoading] = useState();

  const [publicationName, setPublicationName] = useState();
  const [description, setDescription] = useState();
  const [coverUrl, setCoverUrl] = useState();
  const [disabled, setDisabled] = useState();

  useEffect(() => {
    (async () => {
      if (updatePublicationId) {
        setShowNewModal(true);
        try {
          const resp = await PublicationService.getPublicationById(
            updatePublicationId
          );
          setPublicationName(resp.name);
          setDescription(resp.description);
          setCoverUrl(resp.cover_url);
          setShowNewModal(true);
          setDisabled(resp.disabled);
        } catch (error) {
          if (error?.response?.status === 401) {
            notification.error({
              message: "Token Expired, redirecting to login page in 5 seconds",
            });
            history?.push(routeMapMini.Login);
          } else if (error.response?.data?.message) {
            notification.error({message: error.response?.data?.message});
          } else {
            notification.error({message: error.message});
          }
        }
      }
    })();
  }, [history, updatePublicationId]);

  const clearFields = () => {
    setUpdatePublicationId();
    setPublicationName();
    setDescription();
    setCoverUrl();
    setDisabled();
    setUpdateTable();
    setShowNewModal(false);
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      if (updatePublicationId) {
        await PublicationService.editPublication(
          updatePublicationId,
          publicationName,
          description,
          coverUrl,
          disabled
        );
      } else {
        await PublicationService.createNewPublication(
          publicationName,
          description,
          coverUrl,
          disabled
        );
      }
      clearFields();
      setUpdateTable(Math.random());
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page.",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
    setLoading(false);
  };

  const handleCancel = () => {
    clearFields();
  };

  return (
    <div style={{width: "100%"}}>
      <Row style={{width: "100%", marginBottom: "15px"}} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setShowNewModal(true);
            }}
            disabled={!["admin"].includes(userDetails.role)}
          >
            Add New Publication
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              history?.push(routeMapMini.PublicationBookings);
            }}
            disabled={
              !["admin", "publication_read", "publication_counter"].includes(
                userDetails.role
              )
            }
          >
            Publication Booking Details
          </Button>
        </Col>
        {/* <Col>
          <Button
            type="primary"
            onClick={() => {
              // history?.push(routeMapMini.SevaReports);
            }}
            disabled={
              !["admin", "publication_read", "publication_counter"].includes(
                userDetails.role
              )
            }
          >
            Publication Reports
          </Button>
        </Col> */}
      </Row>
      <Row style={{width: "100%"}}>
        <PublicationTable
          updateTable={updateTable}
          setUpdatePublicationId={setUpdatePublicationId}
        />
      </Row>
      <Modal
        visible={showNewModal}
        title={
          updatePublicationId
            ? `Modify ${updatePublicationId}`
            : `Add New Publication`
        }
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify="center" style={{width: "100%", paddingTop: "10px"}}>
          Name
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            value={publicationName}
            style={{width: "150px"}}
            onChange={(e) => {
              setPublicationName(e.target.value);
            }}
          />
        </Row>

        <Row justify="center" style={{width: "100%", paddingTop: "10px"}}>
          Description
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <TextArea
            value={description}
            style={{width: "250px"}}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </Row>
        <Row justify="center" style={{width: "100%", paddingTop: "10px"}}>
          <a onClick={() => window.open(coverUrl, "_blank")}>Cover Pic</a>
        </Row>
        <Row justify="center">
          <Upload
            name="file"
            listType="picture"
            action={`${baseURL}fileupload/upload/public-file?fileFor=publication_cover_pic`}
            headers={{
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }}
            accept="image/png, image/gif, image/jpeg"
            maxCount={1}
            onChange={(info) => {
              if (info.file.status !== "uploading") {
                setCoverUrl(info.file?.response?.path);
              }
              if (info.file.status === "done") {
                notification.success({
                  message: `${info.file.name} file uploaded successfully`,
                });
              } else if (info.file.status === "error") {
                notification.error({
                  message: `${info.file.name} file upload failed.`,
                });
              }
            }}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Space size="middle">
            Disabled
            <Checkbox
              checked={disabled}
              onChange={(e) => {
                setDisabled(e.target.checked);
              }}
            />
            {console.log(disabled)}
          </Space>
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(Publications);
