import {
  HomeOutlined,
  PieChartOutlined,
  UserSwitchOutlined,
  AimOutlined,
  RiseOutlined,
  BookOutlined,
  FundProjectionScreenOutlined,
  CompressOutlined,
  BarcodeOutlined,
  MailOutlined,
  PhoneOutlined,
  AuditOutlined,
  SnippetsOutlined,
  DesktopOutlined,
  ContactsOutlined,
  AlertOutlined,
  CarOutlined,
  ControlOutlined,
  ScissorOutlined,
  GoldOutlined,
  GatewayOutlined,
} from "@ant-design/icons";

const routeMap = [
  {
    route: "/Dashboard",
    accessedBy: ["admin", "manager", "operator", "dashboardView"],
    icon: PieChartOutlined,
    inLayout: true,
    name: "Dashboard",
  },
  {
    route: "/Login",
    accessedBy: ["admin", "manager", "operator"],
    icon: HomeOutlined,
    inLayout: false,
    name: "Login",
  },
  {
    route: "/Acc-Dashboard",
    accessedBy: ["accommodation_counter"],
    icon: PieChartOutlined,
    inLayout: true,
    name: "Dashboard",
  },
  {
    route: "/Accommodation",
    accessedBy: ["admin", "manager", "operator", "accommodation_counter"],
    icon: HomeOutlined,
    inLayout: true,
    name: "Accommodation",
  },
  {
    route: "/Accommodation/Block-Category",
    accessedBy: ["admin", "manager", "operator"],
    icon: HomeOutlined,
    inLayout: false,
    name: "Accommodation Block Category",
  },
  {
    route: "/Accommodation/Block-Booking-Details",
    accessedBy: ["admin", "manager", "operator"],
    icon: HomeOutlined,
    inLayout: false,
    name: "Accommodation Block Bookings",
  },
  {
    route: "/Accommodation/Manage-Counters",
    accessedBy: ["admin", "manager", "operator"],
    icon: HomeOutlined,
    inLayout: false,
    name: "Accommodation Manage Counters",
  },
  {
    route: "/Accommodation/Block-Category/Rooms",
    accessedBy: ["admin", "manager", "operator"],
    icon: HomeOutlined,
    inLayout: false,
    name: "Accommodation rooms",
  },
  {
    route: "/Accommodation/Block-Category/Rooms/Bookings",
    accessedBy: ["admin", "manager", "operator"],
    icon: HomeOutlined,
    inLayout: false,
    name: "Accommodation rooms bookings",
  },
  {
    route: "/Accommodation/Room-Allocation",
    accessedBy: ["admin", "manager", "operator"],
    icon: HomeOutlined,
    inLayout: false,
    name: "Room Allocation Report",
  },
  {
    route: "/Accommodation/Disabled-Dates",
    accessedBy: ["admin", "manager", "operator"],
    icon: AimOutlined,
    inLayout: false,
    name: "Accommodation Block Disabled Dates",
  },
  {
    route: "/Accommodation/Disable",
    accessedBy: ["admin", "manager", "operator"],
    icon: AimOutlined,
    inLayout: false,
    name: "Disable Accommodation Block",
  },
  {
    route: "/Seva",
    accessedBy: [
      "admin",
      "manager",
      "operator",
      "seva_read",
      "seva_read_and_performance_scan",
    ],
    icon: AimOutlined,
    inLayout: true,
    name: "Sevas",
  },

  {
    route: "/Darshanam",
    accessedBy: ["admin", "manager", "operator"],
    icon: CompressOutlined,
    inLayout: true,
    name: "Darshanam",
  },
  {
    route: "/Prasadam",
    accessedBy: ["admin", "manager", "operator"],
    icon: AlertOutlined,
    inLayout: true,
    name: "Prasadam",
  },
  {
    route: "/Kailasa-Kankanam",
    accessedBy: ["admin", "manager", "operator"],
    icon: GatewayOutlined,
    inLayout: true,
    name: "Kailasa Kankanam",
  },
  {
    route: "/Kalyanakatta",
    accessedBy: ["admin", "manager", "operator", "kalyanakatta_counter"],
    icon: ScissorOutlined,
    inLayout: true,
    name: "Kalyana Katta",
  },
  {
    route: "/Thulabharam",
    accessedBy: ["admin", "manager", "operator"],
    icon: GoldOutlined,
    inLayout: true,
    name: "Thulabharam",
  },
  {
    route: "/Darshanam/Booking-Details",
    accessedBy: ["admin", "manager", "operator"],
    icon: CompressOutlined,
    inLayout: false,
    name: "Darshanam",
  },
  {
    route: "/Darshanam/Slots",
    accessedBy: ["admin", "manager", "operator"],
    icon: CompressOutlined,
    inLayout: false,
    name: "Darshanam Slots",
  },
  {
    route: "/Darshanam/Reports",
    accessedBy: ["admin", "manager", "operator"],
    icon: CompressOutlined,
    inLayout: false,
    name: "Darshanam",
  },
  {
    route: "/Darshanam/Disabled-Dates",
    accessedBy: ["admin", "manager", "operator"],
    icon: CompressOutlined,
    inLayout: false,
    name: "Darshanam",
  },
  {
    route: "/Darshanam/Disable",
    accessedBy: ["admin", "manager", "operator"],
    icon: CompressOutlined,
    inLayout: false,
    name: "Darshanam",
  },
  {
    route: "/Donation",
    accessedBy: [
      "admin",
      "manager",
      "operator",
      "donation_read",
      "donation_write",
    ],
    icon: RiseOutlined,
    inLayout: true,
    name: "Donation",
  },
  {
    route: "/Donation/Booking-Details",
    accessedBy: [
      "admin",
      "manager",
      "operator",
      "donation_read",
      "donation_write",
    ],
    icon: RiseOutlined,
    inLayout: false,
    name: "Donation",
  },
  {
    route: "/Donation/Reports",
    accessedBy: [
      "admin",
      "manager",
      "operator",
      "donation_read",
      "donation_write",
    ],
    icon: RiseOutlined,
    inLayout: false,
    name: "Donation",
  },
  {
    route: "/Seva/Booking-Details",
    accessedBy: ["admin", "manager", "operator"],
    icon: AimOutlined,
    inLayout: false,
    name: "Seva Booking Details",
  },
  {
    route: "/Seva/Reports",
    accessedBy: ["admin", "manager", "operator"],
    icon: AimOutlined,
    inLayout: false,
    name: "Seva Reports",
  },
  {
    route: "/Seva/Disabled-Dates",
    accessedBy: ["admin", "manager", "operator"],
    icon: AimOutlined,
    inLayout: false,
    name: "Seva Disabled Dates",
  },
  {
    route: "/Seva/Disable",
    accessedBy: ["admin", "manager", "operator"],
    icon: AimOutlined,
    inLayout: false,
    name: "Disable Seva",
  },
  {
    route: "/Publications",
    accessedBy: [
      "admin",
      "manager",
      "operator",
      "publication_read",
      "publication_counter",
    ],
    icon: BookOutlined,
    inLayout: true,
    name: "Publications",
  },
  {
    route: "/Publications/Bookings",
    accessedBy: [
      "admin",
      "manager",
      "operator",
      "publication_read",
      "publication_counter",
    ],
    icon: BookOutlined,
    inLayout: false,
    name: "Publications",
  },
  {
    route: "/Toll-Gate",
    accessedBy: ["admin"],
    icon: CarOutlined,
    inLayout: true,
    name: "Toll Gate",
  },
  {
    route: "/Manage-Users",
    accessedBy: ["admin", "manager", "operator"],
    icon: UserSwitchOutlined,
    inLayout: true,
    name: "Manage Users",
  },
  // {
  //   route: "/Manage-Roles",
  //   accessedBy: ["admin", "manager", "operator"],
  //   icon: ControlOutlined,
  //   inLayout: true,
  //   name: "Manage Roles",
  // },
  {
    route: "/Manage-Users/User-Details",
    accessedBy: ["admin", "manager", "operator"],
    icon: UserSwitchOutlined,
    inLayout: false,
    name: "User Details",
  },
  {
    route: "/All-Purchases",
    accessedBy: ["admin", "manager", "operator", "customer_support"],
    icon: FundProjectionScreenOutlined,
    inLayout: true,
    name: "All Purchases",
  },
  {
    route: "/Reports",
    accessedBy: [
      "admin",
      "accommodation_counter",
      "accommodation_read",
      "counter",
      "seva_and_darshanam_counter",
      "darshanam_counter",
      "seva_counter",
      "performance_scan",
      "prasadam_counter",
      "kailasakankanam_counter",
      "seva_read_and_performance_scan",
      "toll_gate_counter",
      "publication_counter",
      "thulabharam_counter",
      "seva_read",
      "kalyanakatta_counter",


    ],
    icon: SnippetsOutlined,
    inLayout: true,
    name: "Reports",
  },
  {
    route: "/Reports/Reconciliation-Report",
    accessedBy: ["admin"],
    icon: SnippetsOutlined,
    inLayout: false,
    name: "Reconciliation Report",
  },
  {
    route: "/Reports/Transactions-Report",
    accessedBy: ["admin"],
    icon: SnippetsOutlined,
    inLayout: false,
    name: "Transactions Report",
  },
  {
    route: "/Ticket",
    accessedBy: [
      "admin",
      "manager",
      "operator",
      "counter",
      "accommodation_counter",
    ],
    icon: BarcodeOutlined,
    inLayout: true,
    name: "Scan Ticket",
  },
  {
    route: "/Ticket/Details",
    accessedBy: [
      "admin",
      "manager",
      "operator",
      "counter",
      "performance_scan",
      "seva_read_and_performance_scan",
    ],
    icon: BarcodeOutlined,
    inLayout: false,
    name: "Ticket Details",
  },
  {
    route: "/Audits",
    accessedBy: ["admin"],
    icon: AuditOutlined,
    inLayout: true,
    name: "Audits",
  },
  {
    route: "/Newsletter",
    accessedBy: ["admin", "manager", "operator"],
    icon: MailOutlined,
    inLayout: true,
    name: "News Letter",
  },
  {
    route: "/Counter",
    accessedBy: [
      "admin",
      "accommodation_counter",
      "counter",
      "seva_and_darshanam_counter",
      "darshanam_counter",
      "seva_counter",
      "performance_scan",
      "prasadam_counter",
      "kailasakankanam_counter",
      "publication_counter",
      "seva_read_and_performance_scan",
      "toll_gate_counter",
      "thulabharam_counter",
      "kalyanakatta_counter",
      "donation_counter",
    ],
    icon: DesktopOutlined,
    inLayout: true,
    name: "Counter",
  },
  {
    route: "/Counter/Accommodation",
    accessedBy: ["admin"],
    icon: DesktopOutlined,
    inLayout: false,
    name: "Offline Accommodation",
  },
  {
    route: "/Counter/Accommodation/User-Details",
    accessedBy: ["admin"],
    icon: DesktopOutlined,
    inLayout: false,
    name: "User Form",
  },
  {
    route: "/Counter/Accommodation/User-Details/Block-Rooms",
    accessedBy: ["admin"],
    icon: DesktopOutlined,
    inLayout: false,
    name: "Rooms List",
  },
  {
    route: "/Counter/Coupon-Management",
    accessedBy: ["admin", "manager", "operator"],
    icon: DesktopOutlined,
    inLayout: false,
    name: "Coupon Management",
  },
  {
    route: "/Counter/Management",
    accessedBy: ["admin"],
    icon: DesktopOutlined,
    inLayout: false,
    name: "Counter",
  },
  {
    route: "/Contact-Us",
    accessedBy: ["admin", "manager", "operator", "customer_support"],
    icon: PhoneOutlined,
    inLayout: true,
    name: "Contact Us",
  },
  {
    route: "/Counter/Shift-Management",
    accessedBy: ["admin", "manager", "operator"],
    icon: DesktopOutlined,
    inLayout: false,
    name: "Shift Management",
  },
  {
    route: "/Temple-Dairy",
    accessedBy: ["admin", "manager", "operator", "customer_support"],
    icon: ContactsOutlined,
    inLayout: true,
    name: "Temple Dairy",
  },
  {
    route: "/Counter/Prasadam Scanner",
    accessedBy: ["admin", "prasadam_counter"],
    icon: ContactsOutlined,
    inLayout: false,
    name: "Prasadam Scanner",
  },
  {
    route: "/Counter/Kailasa Kankanam Scanner",
    accessedBy: ["admin", "kailasakankanam_counter",
    ],
    icon: ContactsOutlined,
    inLayout: false,
    name: "Kailasa Kankanam Scanner",
  },
];

const routeMapMini = {
  ShiftTransactions: "/Reports/Shift-Report-Table/Shift-Transactions",
  SevaCounterReport: "/Reports/Seva-Counter-Report",
  PrasadamCounterReport: "/Reports/Prasadam-Counter-Report",
  KailasaKankanamCounterReport: "/Reports/Kailasa-Kankanam-Counter-Report",
  KalayanakattaCounterReport: "/Reports/kalayanakatta-Counter-Report",

  DonationReports: "/Reports/Donation-Counter-Report",
  ThulabharamReports: "/Reports/Thulabharam-Counter-Report",
  AccommodationReports: "/Reports/Accommodation-Counter-Report",
  AccommodationOcupancyReports: "/Reports/Accommodation-Occupancy-Report",
  PublicationReports: "/Reports/Publication-Counter-Report",
  TollGateReports: "/Reports/Tollgate-Counter-Report",


  ConsolidatedReport: "/Reports/Consolidated-Report",
  DarshanamCounterReport: "/Reports/Darshanam-Counter-Report",
  shiftManagement: "/Counter/Shift-Management",
  DarshanamSlots: "/Darshanam/Slots",
  CouponManagement: "/Counter/Coupon-Management",
  Accommodation: "/Accommodation/",
  Prasadam: "/Prasadam",
  KailasaKankanam: "/Kailasa-Kankanam",

  PrasadamCounterMain: "/Prasadam/Counter-Management",
  KailasaKankanamCounterMain: "/Kailasa-Kankanam/Counter-Management",

  KalyanaKatta: "/Kalyanakatta",
  KalyanaKattaCounterMain: "/Kalyanakatta/Counter-Management",
  Thulabharam: "/Thulabharam",
  ThulabharamCounterMain: "/Thulabharam/Counter-Management",
  PrasadamScanner: "/Counter/Prasadam Scanner",
  KailasaKankanamScanner: "/Counter/Kailasa Kankanam Scanner",

  AccommodationDashboard: "/Acc-Dashboard",
  AccommodationBlockCategory: "/Accommodation/Block-Category",
  AccommodationBlockBookingDetails: "/Accommodation/Block-Booking-Details",
  AccommodationManageCounters: "/Accommodation/Manage-Counters",
  AccommodationDisabledDates: "/Accommodation/Disabled-Dates",
  DisableAccommodation: "/Accommodation/Disabled-Dates/Disable",
  AccommodationRooms: "/Accommodation/Block-Category/Rooms",
  AccommodationRoomsBookings: "/Accommodation/Block-Category/Rooms/Bookings",
  CounterPrasadam: "/Counter/Prasadam",
  CounterKailasaKankanam: "/Counter/KailasaKankanam",

  CounterKalyanakatta: "/Counter/Kalyanakatta",
  CounterThulabharam: "/Counter/Thulabharam",
  RoomAllocation: "/Accommodation/Room-Allocation",
  Login: "/Login",
  Dashboard: "/Dashboard",
  ManageUsers: "/Manage-Users",
  ManagePermissions: "/Manage-Users/Manage-Permissions",
  userDetails: "/Manage-Users/User-Details",
  Seva: "/Seva",
  Donation: "/Donation",
  DonationBookingDetails: "/Donation/Booking-Details",
  DonorRegistration: "/Donation/Donar-Registration",

  DonationCounterManagement: "/Donation/Manage-Counters",
  SevaBookingDetails: "/Seva/Booking-Details",
  SevaReports: "/Seva/Reports",
  SevaSlotManagement: "/Seva/Seva-Slot-Management",
  SevaDisabledDates: "/Seva/Disabled-Dates",
  DisableSeva: "/Seva/Disabled-Dates/Disable",
  AllPurchases: "/All-Purchases",
  Reports: "/Reports",
  ReconciliationReport: "/Reports/Overview-Report",
  Alltransactions: "/Reports/Transactions-Report",
  gstReport: "/Reports/GST-Report",
  Darshanam: "/Darshanam",
  DarshanamBookingDetails: "/Darshanam/Booking-Details",
  DarshanamReports: "/Darshanam/Reports",
  DarshanamDisabledDates: "/Darshanam/Disabled-Dates",
  DarshanamSlotCounterManagement: "/Darshanam/Slot-Counter-Management",
  SevaSlotCounterManagement: "/Seva/Slot-Counter-Management",
  Counterdonation: "/Counter/Donation",
  Counter_Publications: "/Counter/Publications",
  DisableDarshanam: "/Darshanam/Disabled-Dates/Disable",
  Publications: "/Publications",
  PublicationFiles: "/Publications/Files",
  PublicationBookings: "/Publications/Bookings",
  PublicationsManageCounters: "/Publications/Manage-Counters",
  Counter_SAD_pratyakshaSeva_One: "/Counter/Pratyaksha-seva",
  ticket: "/Ticket",
  ticketDetails: "/Counter/Ticket-Details",
  PerformanceScan: "/Counter/Performance-Scan",
  CheckInScan: "/Counter/Check-In-Scan",
  audits: "/Audits",
  newsletter: "/Newsletter",
  Counter: "/Counter",
  OfflineAccommodation: "/Counter/Accommodation",
  OfflineDarshanam: "/Counter/Darshanam-Booking",
  userForm: "/Counter/Accommodation/User-Details",
  RoomList: "/Counter/Accommodation/User-Details/Block-Rooms",
  CounterManagement: "/Counter/Management",
  CounterManagementRole: "/Counter/Management/Role",
  CounterManagementShift: "/Counter/Management/Shift",
  TempleDairyMain: "/Temple-Dairy",
  contactUs: "/Contact-Us",
  shiftReportTable: "/Reports/Shift-Report-Table",
  ShiftScanLogs: "/Reports/Scan-Logs",
  ScanTicketLogReport: "/Reports/Scan-Ticket-Log-Report",
  TollGate: "/Toll-Gate",
  TollGateCounterMain: "/Toll-Gate/Counters",
  CounterTollGate: "/Counter/Toll-Gate",
};

export { routeMap, routeMapMini };
