import { Button, Input, notification, Row, Space, Divider } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Modal from 'antd/lib/modal/Modal';
import { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { routeMapMini } from '../../../constants/routeMap';
import { UserContext } from '../../../context/user.context';
import RoomService from '../../../Services/rooms.service';
import RoomTable from './RoomTable';

const Rooms = ({ history, location }) => {
  const [showNewModal, setShowNewModal] = useState(false);
  const [loading, setLoading] = useState();

  const [roomNumber, setRoomNumber] = useState();
  const [description, setDescription] = useState();
  const [openForBookings, setOpenForBookings] = useState();

  const [updateTable, setUpdateTable] = useState();

  const [updateRoomId, setUpdateRoomId] = useState();

  const [blockCategoryId, setBlockCategoryId] = useState();
  const [blockId, setBlockId] = useState();
  const [blockName, setBlockName] = useState();
  const [roomType, setRoomType] = useState();

  const { userDetails } = useContext(UserContext);

  useEffect(() => {
    const id = new URLSearchParams(location.search).get('blockCategoryId');
    const t_blockId = new URLSearchParams(location.search).get('blockId');
    setBlockName(new URLSearchParams(location.search).get('blockName'));
    setRoomType(new URLSearchParams(location.search).get('roomType'));
    if (!id) {
      if (t_blockId) {
        history?.push(
          `${routeMapMini.AccommodationBlockCategory}?blockId=${t_blockId}`
        );
      } else {
        history?.push(routeMapMini.Accommodation);
      }
    }
    if (!t_blockId) {
      history?.push(routeMapMini.Accommodation);
    } else {
      setBlockCategoryId(id);
      setBlockId(t_blockId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, location.search]);

  useEffect(() => {
    (async () => {
      if (updateRoomId) {
        setShowNewModal(true);
        try {
          const resp = await RoomService.getRoomById(updateRoomId);
          setDescription(resp?.description);
          setOpenForBookings(resp?.open_for_bookings);
          setRoomNumber(resp?.room_number);
          setShowNewModal(true);
        } catch (error) {
          if (error?.response?.status === 401) {
            notification.error({
              message: 'Token Expired, redirecting to login page in 5 seconds',
            });
            history?.push(routeMapMini.Login);
          } else if (error.response?.data?.message) {
            notification.error({ message: error.response?.data?.message });
          } else {
            notification.error({ message: error.message });
          }
        }
      }
    })();
  }, [history, updateRoomId]);

  const handleOk = async () => {
    if (description < 0) {
      return notification.error({
        message: `price should be positive`,
      });
    }
    setLoading(true);
    try {
      if (!blockCategoryId) {
        return history?.push(routeMapMini.Accommodation);
      }
      if (updateRoomId) {
        await RoomService.editRoom(
          updateRoomId,
          roomNumber,
          description,
          openForBookings,
          blockCategoryId
        );
        notification.success({
          message: `block ${updateRoomId} updated`,
        });
        clearFields();
        setUpdateTable(updateRoomId + Math.random());
      } else {
        const block = await RoomService.createRoom(
          roomNumber,
          description,
          openForBookings,
          blockCategoryId
        );
        notification.success({ message: `block ${block.id} created` });
        setUpdateTable(block.id + Math.random());
      }
      setShowNewModal(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: 'Token Expired, redirecting to login page in 5 seconds',
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({ message: error.response?.data?.message });
      } else {
        notification.error({ message: error.message });
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setRoomNumber(undefined);
    setDescription(undefined);
    setOpenForBookings(undefined);
    setUpdateRoomId(undefined);
  };

  const handleCancel = () => {
    clearFields();
    setShowNewModal(false);
  };

  return (
    <div style={{ width: '100%' }}>
      <Row>
        <h1
          style={{
            textAlign: 'left',
            fontWeight: 'bold',
            marginBottom: '0px',
            fontSize: '24px',
          }}
        >
          {blockName} ( {roomType} )
        </h1>
        <Divider />
      </Row>
      <Row style={{ width: '100%', marginBottom: '15px' }}>
        <Space size='middle'>
          <Button
            onClick={() => {
              history?.push(
                `${routeMapMini.AccommodationBlockCategory}?blockId=${blockId}&blockName=${blockName}`
              );
            }}
          >
            Go Back
          </Button>
          <Button
            type='primary'
            onClick={() => {
              setShowNewModal(true);
            }}
            disabled={userDetails.role !== 'admin'}
          >
            Add New Room
          </Button>
        </Space>
      </Row>
      <Row style={{ width: '100%' }}>
        <RoomTable
          updateTable={updateTable}
          setUpdateRoomId={setUpdateRoomId}
          blockCategoryId={blockCategoryId}
        />
      </Row>
      <Modal
        visible={showNewModal}
        title={`Add New Room`}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify='center' style={{ width: '100%', paddingTop: '10px' }}>
          Room Number
        </Row>
        <Row justify='center' style={{ width: '100%', padding: '10px 0px' }}>
          <Input
            placeholder='Room Number'
            value={roomNumber}
            onChange={(e) => {
              setRoomNumber(e.target.value);
            }}
            style={{ width: '400px' }}
          />
        </Row>

        <Row justify='center' style={{ width: '100%', paddingTop: '10px' }}>
          Description
        </Row>
        <Row justify='center' style={{ width: '100%', padding: '10px 0px' }}>
          <Input
            placeholder='Description'
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            style={{ width: '400px' }}
          />
        </Row>
        <Row justify='center' style={{ width: '100%', padding: '10px 0px' }}>
          <Space size='middle'>
            Open For bookings
            <Checkbox
              checked={openForBookings}
              onChange={(e) => {
                setOpenForBookings(e.target.checked);
              }}
            />
          </Space>
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(Rooms);
