import crypto from 'crypto';

const IV_LENGTH = 16;

const PHONEENCRYPTSECRET = 'sdkfrdamsnd#fjevnjdsfc1egB8q6M1f';

export const EncryptPhone = (phone) => {
  const iv = crypto.randomBytes(IV_LENGTH);
  const cipher = crypto.createCipheriv(
    'aes-256-cbc',
    Buffer.from(PHONEENCRYPTSECRET),
    iv
  );
  let encrypted = cipher.update(phone);

  encrypted = Buffer.concat([encrypted, cipher.final()]);

  return iv.toString('hex') + ':' + encrypted.toString('hex');
};
