/* eslint-disable jsx-a11y/anchor-is-valid */
import { notification, Popconfirm, Row, Space, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { routeMapMini } from "../../../constants/routeMap";

import RoomService from "../../../Services/rooms.service";
import { FormatNumberINRCurrency } from "../../../utils/currencyFormat";

const BlockCategoryTable = ({
  updateTable,
  setUpdateBlockId,
  blockId,
  history,
  blockName,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (blockId) {
      loadBlockCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTable, blockId]);

  const loadBlockCategories = async () => {
    try {
      setLoading(true);
      const blockCategories = await RoomService.getBlockCategoryiesByBlock(
        blockId
      );
      setData(
        blockCategories?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      if (error.response?.data?.message) {
        notification.error({ message: error.response?.data?.message });
      } else {
        notification.error({ message: error.message });
      }
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Room Type",
      dataIndex: "room_type",
      key: "room_type",
      render: (text) => {
        return text === "SUITE" ? (
          <>
            <Tag color="GOLD">{text}</Tag>
          </>
        ) : text === "AC" ? (
          <>
            <Tag color="green">{text}</Tag>
          </>
        ) : text === "DORMITORY" ? (
          <>
            <Tag color="brown">{text}</Tag>
          </>
        ) : text === "COTTAGE" ? (
          <>
            <Tag color="red">{text}</Tag>
          </>
        ) : (
          <>
            <Tag color="orange">{text}</Tag>
          </>
        );
      },
    },
    {
      title: "Online Available Rooms",
      dataIndex: "online_available_rooms",
      key: "online_available_rooms",
    },
    {
      title: "Deposit Amount",
      dataIndex: "deposit_amount",
      key: "deposit_amount",
      render: FormatNumberINRCurrency,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: FormatNumberINRCurrency,
    },
    {
      title: "Persons",
      dataIndex: "persons",
      key: "persons",
    },
    {
      title: "Action",
      fixed: "right",
      responsive: ["md", "lg", "xl", "xsl"],
      render: (record) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                history?.push(
                  `${routeMapMini.AccommodationRooms}?blockCategoryId=${record.key}&blockId=${blockId}&blockName=${blockName}&roomType=${record.room_type}`
                );
              }}
            >
              Manage Room
            </a>
            <a
              onClick={() => {
                setUpdateBlockId(record.key);
              }}
            >
              Modify
            </a>
            <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={async () => {
                setLoading(true);
                try {
                  await RoomService.deleteBlockCategory(record.key);
                  notification.success({
                    message: `block ${record.key} has been deleted`,
                  });
                } catch (error) {
                  if (error.response?.data?.message) {
                    notification.error({
                      message: error.response?.data?.message,
                    });
                  } else {
                    notification.error({ message: error.message });
                  }
                }
                loadBlockCategories();
                setLoading(false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <Table
      tableLayout="fixed"
      scroll={{ x: true }}
      loading={loading}
      style={{ width: "100%", textTransform: "capitalize" }}
      columns={columns}
      dataSource={data}
      expandable={{
        expandedRowRender: (record) => (
          <>
            <Row>
              <p style={{ fontWeight: "bolder", marginRight: "20px" }}>
                Image:
              </p>
              <a onClick={() => window.open(record.image_url, "_blank")}>
                image
              </a>
            </Row>
          </>
        ),
      }}
    />
  );
};

export default withRouter(BlockCategoryTable);
