/* eslint-disable react/jsx-no-target-blank */
import { Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { FormatNumberINRCurrency } from "../../../utils/currencyFormat";

const COLORS = ["#0088FE", "#00C49F", "#ffab00", "#FF8042", "#eb42e5"];

const UserOrderCards = ({
  data,
  fromDate,
  toDate,
  history,
  title,
  cardsmall,
  cardmid,
  cardlarge,
  cardheight,
}) => {
  const [formatData, setFormatData] = useState([
    {
      bookingType: "seva",
      collection: 0,
    },
    {
      bookingType: "donation",
      collection: 0,
    },
    {
      bookingType: "darshanam",
      collection: 0,
    },
    {
      bookingType: "srisailaPrabha",
      collection: 0,
    },
    {
      bookingType: "accomodation",
      collection: 0,
    },
  ]);
  useEffect(() => {
    if (data && data?.length > 0) {
      setMainData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const setMainData = () => {
    setFormatData(
      data?.map((item) => {
        return {
          bookingType: item.bookingType,
          count: Number(item.count),
          amount: item.amount,
        };
      })
    );
  };

  let totalSpent = 0;
  let totalOrders = 0;

  return (
    <>
      <Row gutter={[20, 20]}>
        {formatData?.map((item, index) => {
          totalSpent += Number(item.amount);
          totalOrders += Number(item.count);
          return (
            <Col
              xs={cardsmall}
              sm={cardsmall}
              md={cardmid}
              lg={cardlarge}
              xl={cardlarge}
              xsl={cardlarge}
              key={index}
            >
              <Card
                hoverable
                style={{
                  backgroundColor: COLORS[index % COLORS.length],
                  height: `${cardheight}`,
                }}
              >
                <div
                  style={{
                    textTransform: "capitalize",
                    float: "left",
                    top: 0,
                    position: "absolute",
                    fontSize: "24px",
                    padding: "10px",
                  }}
                >
                  {item.count} {item.bookingType}
                </div>
                <div
                  style={{
                    textTransform: "capitalize",
                    float: "right",
                    right: 0,
                    bottom: 0,
                    position: "absolute",
                    fontSize: "16px",
                    padding: "10px",
                  }}
                >
                  {FormatNumberINRCurrency(item.amount)}
                </div>
              </Card>
            </Col>
          );
        })}
        <Col
          xs={cardsmall}
          sm={cardsmall}
          md={cardmid}
          lg={cardlarge}
          xl={cardlarge}
          xsl={cardlarge}
        >
          <Card
            hoverable
            style={{
              height: `${cardheight}`,
            }}
          >
            <div
              style={{
                textTransform: "capitalize",
                float: "left",
                top: 0,
                position: "absolute",
                fontSize: "24px",
                padding: "10px",
              }}
            >
              {totalOrders} Total Orders
            </div>
            <div
              style={{
                textTransform: "capitalize",
                float: "right",
                right: 0,
                bottom: 0,
                position: "absolute",
                fontSize: "16px",
                padding: "10px",
              }}
            >
              {FormatNumberINRCurrency(totalSpent)}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(UserOrderCards);
