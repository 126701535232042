/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Col,
  DatePicker,
  notification,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Drawer,
} from "antd";
import {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import moment from "moment";
import fileDownload from "js-file-download";
import RoomService from "../../../Services/rooms.service";
import {routeMapMini} from "../../../constants/routeMap";
import {FormatNumberINRCurrency} from "../../../utils/currencyFormat";
import UserDetailsCard from "../../ManageUsers/UserDetailsCard";

const BlockBookingDetails = ({history}) => {
  const [blockNamesAndIds, setBlockNamesAndIds] = useState([]);
  const [blockNamesLoading, setBlockNamesLoading] = useState(false);

  const [selectedBlockId, setSelectedBlockId] = useState(0);
  const [bookingFromDate, setBookingFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [bookingToDate, setBookingToDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [counterType, setCounterType] = useState("all");
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [userDetails, setUserDetails] = useState();

  const [dateType, setDateType] = useState("orderDate");

  const getBlockNamesAndIds = async () => {
    setBlockNamesLoading(true);
    try {
      const blockNamesAndIdData = await RoomService.getAllBlocks();
      setBlockNamesAndIds(blockNamesAndIdData);
    } catch (error) {
      notification.error({message: error.message});
    }
    setBlockNamesLoading(false);
  };

  const fetchBookings = async () => {
    setDataLoading(true);
    try {
      const respData = await RoomService.bookings(
        selectedBlockId,
        bookingFromDate,
        bookingToDate,
        dateType,
        counterType,
        tableCurrentPage,
        tablePageSize
      );
      setData(respData?.items);
      setTabelTotalSize(respData?.meta?.totalItems);
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setDataLoading(false);
  };

  const downloadExcel = async () => {
    if (!bookingFromDate || !bookingToDate) {
      return notification.error({
        message: "Date is not selected",
      });
    }

    notification.info({message: "Downloading file! Please wait."});
    RoomService.bookingsExcel(
      selectedBlockId,
      bookingFromDate,
      bookingToDate,
      dateType,
      counterType
    )
      .then((data) => {
        fileDownload(
          data,
          `${bookingFromDate}-RoomsBooked-${moment().format(
            "DD-MM-YYYY HH:MM"
          )}.xlsx`
        );
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          history?.push(routeMapMini.Login);
        } else if (error?.response?.data?.message) {
          notification.error({message: error?.response?.data?.message});
        } else {
          console.error(error);
          notification.error({message: "something is wrong"});
        }
      });
  };

  useEffect(() => {
    getBlockNamesAndIds();
    fetchBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableCurrentPage,
    tablePageSize,
    selectedBlockId,
    bookingFromDate,
    bookingToDate,
    counterType,
  ]);

  const columns = [
    {
      title: "user",
      dataIndex: "user_display_name",
      key: "user_display_name",
      render: (text, record) => {
        return (
          <div>
            <a
              onClick={() => {
                // history?.push(
                //   `${routeMapMini.userDetails}?userId=${record.user_id}`
                // );
                setUserDetails(record?.user_id);
                setShowUserDetails(true);
              }}
              style={{textTransform: "capitalize"}}
            >
              {record.user_display_name}
            </a>
          </div>
        );
      },
    },
    {
      title: "Room Type",
      dataIndex: "room_type",
      key: "room_type",
      render: (text) => {
        return text === "SUITE" ? (
          <>
            <Tag color="GOLD">{text}</Tag>
          </>
        ) : text === "AC" ? (
          <>
            <Tag color="green">{text}</Tag>
          </>
        ) : text === "DORMITORY" ? (
          <>
            <Tag color="brown">{text}</Tag>
          </>
        ) : text === "COTTAGE" ? (
          <>
            <Tag color="red">{text}</Tag>
          </>
        ) : (
          <>
            <Tag color="orange">{text}</Tag>
          </>
        );
      },
    },
    {
      title: "Block Name",
      dataIndex: "block_name",
      key: "block_name",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "contact",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Date of birth",
      dataIndex: "dob",
      key: "dob",
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "PinCode",
      dataIndex: "pin_code",
      key: "pin_code",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "No of persons",
      dataIndex: "no_of_persons",
      key: "no_of_persons",
    },
    {
      title: "price",
      dataIndex: "price",
      key: "price",
      render: FormatNumberINRCurrency,
    },

    {
      title: "Order Date",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Booking Date",
      dataIndex: "booking_date",
      key: "booking_date",
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const handleUserDetails = () => {
    setUserDetails(undefined);
    setShowUserDetails(false);
  };

  return (
    <>
      <Row style={{marginBottom: "20px"}} gutter={[20, 20]}>
        <Col>
          <Row justify="start">Block Name</Row>
          <Row justify="start">
            <Select
              style={{width: "200px"}}
              loading={blockNamesLoading}
              onChange={setSelectedBlockId}
              defaultValue=""
            >
              {blockNamesAndIds.map((item) => {
                return (
                  <Select.Option value={item.id}>
                    {item.block_name}
                  </Select.Option>
                );
              })}
              <Select.Option value="">All</Select.Option>
            </Select>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Booking Date</Row>
          <Row justify="start">
            <DatePicker.RangePicker
              format="DD-MM-YYYY"
              onChange={(e) => {
                if (e?.[0] && e?.[1]) {
                  setBookingFromDate(moment(e[0]).format(`YYYY-MM-DD`));
                  setBookingToDate(moment(e[1]).format(`YYYY-MM-DD`));
                } else {
                  setBookingFromDate(undefined);
                  setBookingToDate(undefined);
                }
              }}
              ranges={{
                Today: [moment(), moment()],
                "Last 7 Days": [moment().subtract(7, "d"), moment()],
                "Last 15 Days": [moment().subtract(15, "d"), moment()],
              }}
              defaultValue={[moment(bookingFromDate), moment(bookingToDate)]}
              defaultPickerValue={[
                moment(bookingFromDate),
                moment(bookingToDate),
              ]}
            />
          </Row>
        </Col>
        <Col>
          <Row justify="start">Date Type</Row>
          <Row justify="start">
            <Select
              style={{width: "180px"}}
              onChange={(e) => {
                setDateType(e);
              }}
              value={dateType}
            >
              <Select.Option value="orderDate">Order Date</Select.Option>
              <Select.Option value="performanceDate">
                Performance Date
              </Select.Option>
            </Select>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Booking Type</Row>
          <Row justify="start">
            <Select
              style={{width: "180px"}}
              onChange={(e) => {
                setCounterType(e);
              }}
              value={counterType}
            >
              <Select.Option value="online">Online</Select.Option>
              <Select.Option value="counter">Counter</Select.Option>
              <Select.Option value="all">All</Select.Option>
            </Select>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Update</Row>
          <Row justify="start">
            <Button
              type="primary"
              style={{minWidth: "100px"}}
              onClick={() => fetchBookings()}
            >
              Fetch
            </Button>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Download</Row>
          <Row justify="start">
            <Button
              type="primary"
              style={{minWidth: "100px"}}
              onClick={() => downloadExcel()}
            >
              Excel
            </Button>
          </Row>
        </Col>
      </Row>

      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Total Bookings</span>
        </Space>
      </Row>

      <Table
        style={{textTransform: "capitalize"}}
        columns={columns}
        dataSource={data}
        loading={dataLoading}
        size="small"
        scroll={{x: true}}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        onChange={handleTableChange}
      ></Table>
      <Drawer
        title={`User`}
        placement="right"
        closable={false}
        onClose={handleUserDetails}
        visible={showUserDetails}
        width={500}
        destroyOnClose={true}
      >
        <UserDetailsCard userid={userDetails} />
      </Drawer>
    </>
  );
};

export default withRouter(BlockBookingDetails);
