/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Col,
  Input,
  notification,
  Row,
  Select,
  Space,
  Table,
  Drawer,
} from "antd";
import {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";

import {routeMapMini} from "../../../constants/routeMap";
import ReportService from "../../../Services/Report.service";
import RoomsService from "../../../Services/rooms.service";

const OccupancyReport = ({history}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [showUserDetails, setShowUserDetails] = useState(false);

  const [selectedBlockId, setSelectedBlockId] = useState("");
  const [selectedRoomId, setSelectedRoomId] = useState("");
  const [blockNamesAndIds, setBlockNamesAndIds] = useState([]);
  const [roomNameAndIds, setRoomNamesAndIds] = useState([]);

  const getBlockNamesAndIds = async () => {
    try {
      const blockNamesAndIdData = await RoomsService.getAllBlocks();
      setBlockNamesAndIds(blockNamesAndIdData);
    } catch (error) {
      notification.error({message: error.message});
    }
  };

  useEffect(() => {
    getBlockNamesAndIds();
  }, []);

  useEffect(() => {
    if (selectedBlockId) {
      loadRoomList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBlockId]);

  const loadRoomList = async () => {
    if (selectedBlockId === "") {
      notification.error({message: "please select a block."});
      return;
    }
    try {
      const roomsData = await RoomsService.getRooms(selectedBlockId);
      setRoomNamesAndIds(roomsData);
    } catch (error) {
      if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
  };

  const fetchBookings = async () => {
    setDataLoading(true);

    try {
      const respData = await ReportService.getOccupancyCounterReport(
        selectedBlockId,
        selectedRoomId
      );
      setData(
        respData.map((item, i) => {
          return {
            key: i,
            ...item,
          };
        })
      );
      setTabelTotalSize(respData?.length);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }

    setDataLoading(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleUserDetails = () => {
    // setUserDetails(undefined);
    setShowUserDetails(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{padding: 8}}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: "block"}}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{width: 90, marginRight: 8}}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{width: 90}}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    // if (sorter.field === "created_at") {
    //   if (sorter.order === "ascend") {
    //     setOrderBy("ASC");
    //   } else {
    //     setOrderBy("DESC");
    //   }
    // }
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Block Name",
      dataIndex: "blockName",
      key: "blockName",
      ...getColumnSearchProps("blockName"),
    },
    {
      title: "Room Number",
      dataIndex: "roomNumber",
      key: "roomNumber",
      ...getColumnSearchProps("roomNumber"),
    },

    {
      title: "Room ID",
      dataIndex: "roomId",
      key: "roomId",
    },
    {
      title: "Occupied Days Name",
      dataIndex: "occupiedDays",
      key: "occupiedDays",
    },
  ];

  return (
    <>
      <Row style={{marginBottom: "20px"}} justify={"start"} gutter={[20, 20]}>
        <Col>
          <Row justify="start">Block Name</Row>
          <Row justify="start">
            <Select
              style={{width: "250px"}}
              // loading={blockNamesLoading}
              onChange={setSelectedBlockId}
              defaultValue=""
              value={selectedBlockId}
            >
              {blockNamesAndIds.map((item) => {
                return (
                  <Select.Option value={item.id}>
                    {item.block_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Room Number</Row>
          <Row justify="start">
            <Select
              style={{width: "250px"}}
              // loading={blockNamesLoading}
              onChange={setSelectedRoomId}
              defaultValue=""
              value={selectedRoomId}
            >
              {roomNameAndIds.map((item) => {
                return (
                  <Select.Option value={item.id} key={item.id}>
                    {item.room_number}
                  </Select.Option>
                );
              })}
            </Select>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Update</Row>
          <Row justify="start">
            <Button
              loading={dataLoading}
              type="primary"
              style={{minWidth: "100px"}}
              onClick={fetchBookings}
            >
              Fetch
            </Button>
          </Row>
        </Col>
      </Row>

      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Total Bookings</span>
        </Space>
      </Row>

      <Table
        style={{width: "100%", textTransform: "capitalize"}}
        columns={columns}
        dataSource={data}
        loading={dataLoading}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        scroll={{x: true}}
        size="small"
        onChange={handleTableChange}
      ></Table>
      <Drawer
        title={`User`}
        placement="right"
        closable={false}
        onClose={handleUserDetails}
        visible={showUserDetails}
        width={500}
        destroyOnClose={true}
      >
        {/* <SevaCounterTable userid={userDetails} /> */}
      </Drawer>
    </>
  );
};

export default withRouter(OccupancyReport);
