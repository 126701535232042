/* eslint-disable react/jsx-pascal-case */
import {
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
} from "antd";
import moment from "moment";

import Modal from "antd/lib/modal/Modal";
import { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { routeMapMini } from "../../../constants/routeMap";
import { UserContext } from "../../../context/user.context";
import CounterService from "../../../Services/Counter.service";
import CoupounTable from "./CoupounTable";

const { Option } = Select;
const CoupounMainPage = ({ history, location }) => {
  const [loading, setLoading] = useState();
  const [showModal, setShowModal] = useState(false);
  const { userDetails } = useContext(UserContext);
  const [list, setList] = useState([]);
  const [coupounType, setCouponType] = useState("once_a_user");
  const [from, setFrom] = useState(moment(new Date()).format(`YYYY-MM-DD`));
  const [to, setTo] = useState(moment(new Date()).format(`YYYY-MM-DD`));
  const [percentage, setPercntage] = useState(20);
  const [coupounCode, setCouponCode] = useState("");
  const [coupounMaxAmount, setCoupounMaxAmount] = useState("");

  const [edit, setEdit] = useState(false);
  const [temp, setTemp] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  const [tableTotalSize, setTabelTotalSize] = useState(0);

  useEffect(() => {
    getCoupouns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePageSize, tableCurrentPage]);

  useEffect(() => {
    if (edit === true) {
      setShowModal(true);
      setCouponCode(temp?.coupon_code);
      setCouponType(temp?.type);
      setFrom(temp?.valid_from);
      setTo(temp?.valid_to);
      setPercntage(temp?.max_offer_percentage);
      setCoupounMaxAmount(temp?.max_offer_amount);
    }
  }, [edit]);
  const handleTableChange = (pagination, filters, sorter) => {
    console.log(pagination);
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const getCoupouns = async (data) => {
    setLoading(true);
    try {
      const resp = await CounterService.getCoupons(
        data ? data : tableCurrentPage,
        tablePageSize
      );
      setList(resp.items);
      setMeta(resp.meta);
      setTabelTotalSize(resp?.meta?.totalItems);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({ message: error.response?.data?.message });
      } else {
        notification.error({ message: error.message });
      }
    }
  };

  const handleOk = async () => {
    if (coupounType === "") {
      notification.error({ message: "Please select a Type" });
      return;
    }
    if (coupounCode === "") {
      notification.error({ message: "Please enter Coupon code" });
      return;
    }
    setLoading(true);
    try {
      let body = {
        type: coupounType,
        coupon_code: coupounCode,
        max_offer_percentage: percentage,
        valid_from: from,
        valid_to: to,
        max_offer_amount: coupounMaxAmount,
      };

      if (edit) {
        body.coupon_id = temp?.id;
        await CounterService.editCoupon(body);
        notification.success({
          message: `Coupoun has been succesfully Edited`,
        });
        setEdit(false);
      } else {
        await CounterService.addCoupon(body);
        notification.success({
          message: `Coupoun has been succesfully generated`,
        });
      }

      getCoupouns();
      setShowModal(false);
      clearFields();
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({ message: error.response?.data?.message });
      } else {
        notification.error({ message: error.message });
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setCouponCode("");
    setCouponType("");
    setPercntage(20);
    setFrom(moment(new Date()).format(`YYYY-MM-DD`));
    setTo(moment(new Date()).format(`YYYY-MM-DD`)); // setblockID(undefined);
  };

  const handleCancel = () => {
    clearFields();
    setShowModal(false);
    setEdit(false);
  };

  return (
    <div style={{ width: "100%" }}>
      {/* <Row style={{ width: "100%", marginBottom: "15px" }} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setShowModal(true);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Add New Coupon
          </Button>
        </Col>
      </Row> */}
      <Row style={{ width: "100%" }}>
        <CoupounTable
          list={list}
          loading={loading}
          setLoading={setLoading}
          getCoupons={getCoupouns}
          edit={edit}
          setEdit={setEdit}
          setTemp={setTemp}
          meta={meta}
          handleTableChange={handleTableChange}
          tableTotalSize={tableTotalSize}
          tableCurrentPage={tableCurrentPage}
          tablePageSize={tablePageSize}
        />
      </Row>

      <Modal
        visible={showModal}
        title={edit ? `Edit a Coupoun` : `Add a New Coupoun`}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row>
          Coupon Code :{" "}
          <Input
            value={coupounCode}
            onChange={(e) => setCouponCode(e.target.value?.toUpperCase())}
            placeholder="Enter Coupon code"
          ></Input>
        </Row>
        <Row style={{ width: "100%", padding: "10px 0px" }}>
          Coupon Type :{" "}
          <Select
            width="100%"
            style={{ width: "100%" }}
            value={coupounType}
            defaultValue={coupounType}
            placeholder="Please select a Counter"
            onChange={(e) => {
              setCouponType(e);
            }}
          >
            <Option key={"donor_accommodation"} value={"donor_accommodation"}>
              Donor Accommodation
            </Option>
            <Option key={"once_a_user"} value={"once_a_user"}>
              Once a User
            </Option>
          </Select>
        </Row>
        <Row width="100%">
          Discount in % :{" "}
          <InputNumber
            defaultValue={percentage}
            value={percentage}
            min={0}
            max={101}
            style={{ width: "100%" }}
            placeholder="Enter Discount Percentage"
            onChange={(e) => {
              console.log(e);
              if (e < 101) {
                setPercntage(e);
              }
            }}
          ></InputNumber>
        </Row>
        <Row style={{ width: "100%", padding: "10px 0px" }}>
          Max Discount in Rs.:{" "}
          <InputNumber
            defaultValue={coupounMaxAmount}
            value={coupounMaxAmount}
            min={0}
            style={{ width: "100%" }}
            placeholder="Enter max Discount on Coupon"
            onChange={(e) => {
              setCoupounMaxAmount(e);
            }}
          ></InputNumber>
        </Row>

        <Row style={{ width: "100%", padding: "25px 0px 10px 0px" }}>
          From-To :{" "}
          <DatePicker.RangePicker
            style={{ marginLeft: "5px" }}
            onChange={(e) => {
              if (e?.[0] && e?.[1]) {
                setFrom(moment(e[0]).format(`YYYY-MM-DD`));
                setTo(moment(e[1]).format(`YYYY-MM-DD`));
              } else {
                setFrom(undefined);
                setTo(undefined);
              }
            }}
            ranges={{
              Today: [moment(), moment()],
              "For 7 Days": [moment(), moment().add(7, "d")],
              "For 15 Days": [moment(), moment().add(15, "d")],
              "For Month": [moment(), moment().add(30, "d")],
              "For Year": [moment(), moment().add(365, "d")],
            }}
            defaultValue={[moment(from), moment(to)]}
            defaultPickerValue={[moment(from), moment(to)]}
          />
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(CoupounMainPage);
