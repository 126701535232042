/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
// import ReportService from "../../../Services/Report.service";
import { routeMapMini } from "../../../constants/routeMap";
import {
  notification,
  Row,
  Col,
  Table,
  Space,
  Button,
  InputNumber,
  Select,
  Statistic,
} from "antd";
// import {FormatNumberINRCurrency} from "../../../utils/currencyFormat";
import moment from "moment";
import fileDownload from "js-file-download";
import ShiftService from "../../../Services/Shift.service";
const { Option } = Select;
const ShiftScanLogs = ({ history, location }) => {
  const [dataLoading, setDataLoading] = useState(false);

  const [reportData, setReportData] = useState("");
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [ShiftID, setShiftID] = useState("");
  const [shiftData, setShiftData] = useState("");
  const [outcome, setOutcome] = useState("all");

  const fetchAllTransactions = async (id) => {
    if (ShiftID === "") {
      if (!id) {
        notification.error({ message: "Please enter a shift ID" });
        return;
      }
    }
    setDataLoading(true);
    try {
      setReportData("");
      setShiftData("");
      const data = await ShiftService.getScanTicketLogs(
        id ? Number(id) : Number(ShiftID),
        outcome
      );
      console.log(data);
      console.log(data.transactions);
      setReportData(data?.transactions);
      setShiftData(data?.shiftLog);
      setTabelTotalSize(data?.transactions?.length);
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
    setDataLoading(false);
  };

  useEffect(() => {
    const id = new URLSearchParams(location.search).get("shiftID");
    setShiftID(id ? Number(id) : "");
    if (!id) {
      return;
    } else {
      fetchAllTransactions(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const columns = [
    { title: "id", dataIndex: "id", key: "id" },
    {
      title: "Seva type",
      dataIndex: "seva_type",
      key: "seva_type",
    },
    { title: "Seva name", dataIndex: "seva_name", key: "seva_name" },

    {
      title: "Devotee Name",
      dataIndex: "devotee_name",
      key: "devotee_name",
    },
    { title: "Ticket Id", dataIndex: "ticket_id", key: "ticket_id" },

    { title: "Payment Id", dataIndex: "payment_id", key: "payment_id" },
    {
      title: "Order Date - Time",
      dataIndex: "order_date",
      render: (record) => {
        return moment(record, "DD-MM-YYYY hh:mm A").format(
          "DD-MM-YYYY hh:mm A"
        );
      },
    },
    {
      title: "Check In Date - Time",
      dataIndex: "scanned_date",
      render: (record) => {
        return moment(record, "DD-MM-YYYY hh:mm A").format(
          "DD-MM-YYYY hh:mm A"
        );
      },
    },

    {
      title: "Performance Date",
      dataIndex: "performance_date",
      render: (record) => {
        return moment(record, "YYYY-MM-DD hh:mm A").format("DD-MM-YYYY");
      },
    },

    { title: "outcome", dataIndex: "outcome", key: "outcome" },
  ];
  const downloadReport = async () => {
    notification.info({ message: "Downloading file! Please wait." });
    ShiftService.scanReportDownload(ShiftID, outcome)
      .then((data) => {
        fileDownload(
          data,
          `Scan-Logs-${ShiftID}-${moment().format("DD-MM-YYYY")}.pdf`
        );
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          history?.push(routeMapMini.Login);
        } else if (error?.response?.data?.message) {
          notification.error({ message: error?.response?.data?.message });
        } else {
          console.error(error);
          notification.error({ message: "something is wrong" });
        }
      });
  };

  return (
    <>
      <Row style={{ marginBottom: "20px", display: "flex" }} gutter={[20, 20]}>
        <Col>
          <Row justify="start"> Shift ID</Row>
          <Row justify="start" style={{ flexDirection: "row" }}>
            <InputNumber
              style={{ width: "300px", maxWidth: "300px" }}
              value={ShiftID}
              defaultValue={ShiftID}
              placeholder="Please Enter a Shift ID"
              onChange={(e) => {
                setShiftID(e);
              }}
            />
          </Row>
        </Col>
        <Col>
          <Row style={{ flexDirection: "column" }}>
            <Row>Outcome Status</Row>
            <Select
              placeholder="Select"
              value={outcome}
              onChange={(e) => {
                setOutcome(e);
              }}
            >
              <Option value="all" key="all">
                All
              </Option>
              <Option value="success">Success</Option>

              <Option value="failure">Failure</Option>
            </Select>
          </Row>
        </Col>
        <Col>
          <Row style={{ color: "#F0F2F5" }}>.</Row>
          <Row>
            <Button
              type="primary"
              style={{ maxWidth: "100px", marginLeft: "20px" }}
              onClick={() => fetchAllTransactions()}
            >
              Fetch
            </Button>
            {reportData === "" || reportData?.length === 0 || !reportData ? (
              ""
            ) : (
              <Button
                type="primary"
                style={{ maxWidth: "100px", marginLeft: "20px" }}
                onClick={() => downloadReport()}
              >
                Download
              </Button>
            )}{" "}
          </Row>
        </Col>
      </Row>
      {shiftData !== "" && reportData !== "" && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            textTransform: "capitalize",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              backgroundColor: "white",
              padding: "20px",
              width: "100%",
            }}
          >
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="User Name"
                value={shiftData?.sd_user?.display_name}
              />
            </Col>
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="Counter Name"
                value={shiftData?.sd_counter?.name}
              />
            </Col>{" "}
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                className="sd-stat"
                title="Log In "
                value={
                  shiftData?.login
                    ? moment(new Date(shiftData?.login)).format(
                        `DD-MM-YYYY  (hh:mm A)`
                      )
                    : "None"
                }
              />
            </Col>
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                className="sd-stat"
                title="Log Out "
                value={
                  shiftData?.logout
                    ? moment(new Date(shiftData?.logout)).format(
                        "DD-MM-YYYY  (hh:mm A)"
                      )
                    : "None"
                }
              />
            </Col>
          </div>
        </div>
      )}
      <br />
      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Scan Logs</span>
        </Space>
      </Row>
      <Table
        style={{ width: "100%", textTransform: "capitalize" }}
        columns={columns}
        dataSource={reportData}
        loading={dataLoading}
        size="small"
        scroll={{ x: true }}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        onChange={handleTableChange}
      ></Table>
    </>
  );
};

export default withRouter(ShiftScanLogs);
