import {
  Button,
  Col,
  DatePicker,
  Input,
  notification,
  Row,
  Divider,
  Modal,
} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {withRouter} from "react-router";
import {SADContext} from "../../../../context/SAD.context";
import {routeMapMini} from "../../../../constants/routeMap";
import {LoadingOutlined, RightOutlined} from "@ant-design/icons";
import {Select} from "antd";
import moment from "moment";
import SevaService from "../../../../Services/Seva.service";
import {FormatNumberINRCurrency} from "../../../../utils/currencyFormat";
import "./styles.css";
import StringUtils from "../../../../utils/string.utils";
import PrintTicket from "../../../Ticket/PrintTicket";

const {Option} = Select;
const Sd_CounterManagement_PratyakshaSeva_One = ({history}) => {
  const {selectedPratyakshaSeva, setSelectedPratyakshaSeva, getSevaList} =
    useContext(SADContext);
  const [cashRemarks, setCashRemarks] = useState("");
  const [noPersons, setNoperons] = useState(1);
  const [sevaList, setSevaList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [slot, setSlot] = useState("");
  const [slotLoading, setSlotLoading] = useState(false);
  const [slotsData, setSlotsData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [phone, setPhone] = useState("");
  const [gothram, setGothram] = useState("");
  const [age, setAge] = useState("");
  const [address, setAddress] = useState("");
  const [payment, setPayment] = useState("CASH");
  const [idProofType, setIdProofType] = useState("");
  const [idProofNumber, setIdProofNumber] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bookingData, setBookingData] = useState();
  const [cashReference, setCashReference] = useState("");
  const [err, setErr] = useState([]);
  const labelStyles = {float: "left", fontSize: "17px", marginBottom: "5px"};
  const [devoteeName, setDevoteeName] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    setStartDate("");
    getPratyakshaList();
  }, []);

  const getPratyakshaList = () => {
    setLoading(true);
    getSevaList("pratyakshaSeva", setLoading, setSevaList, (error) => {
      console.log(error);
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        console.log(error);

        notification.error({message: error.response?.data?.message});
      } else {
        console.log(error);
        notification.error({message: error.message});
      }
    });
  };

  const getSlots = async (sevaId, date) => {
    setSlotLoading(true);
    try {
      setSlot("");
      setSlotsData([]);
      let res = await SevaService.getAvailableSevaSlots(date, sevaId);
      // console.log(res);
      setSlotsData(res);
      setSlotLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
      setSlotLoading(false);
      setSlot("");
      setSlotsData([]);
    }
  };

  const getSlotsDetails = () => {
    if (slotsData?.length === 0 || !slotsData) {
      return <p>No Slots available</p>;
    }
    return slotsData.map((item, i) => {
      if (noPersons > item?.available_slots) {
        return;
      }
      return (
        <div
          key={i}
          onClick={() => {
            if (1 > item?.available_slots) {
              return;
            }
            setSlot(item);
            if (err?.find((e) => e.type === `slot`)) {
              let data = err.filter((e) => e.type !== "slot");
              setErr(data);
            }
          }}
        >
          <div
            className="sd-darshanam-box text-center"
            style={{
              display: "flex",
              alignItems: "center",
              border:
                err.find((e) => e.type === "slot") ||
                1 > item?.sd_seva_slots.available_booking_count
                  ? "1px solid red"
                  : "",
            }}
            onClick={() => {
              if (1 > item?.available_booking_count) {
                return;
              }
              setSlot(item);
              if (err?.find((e) => e.type === `slot`)) {
                let data = err.filter((e) => e.type !== "slot");
                setErr(data);
              }
            }}
          >
            <p
              className="sd-darshanam-time"
              style={{marginBottom: "0px", maxWidth: "85px"}}
            >
              <span style={{marginRight: "3px", fontWeight: "600"}}>
                {item.available_booking_count}
              </span>
              <span style={{fontWeight: "400"}}> Available </span>
            </p>
            <div className="sd-side-heading-2 sd-acc">
              {window.innerWidth < 650 ? (
                <span
                  style={{
                    fontWeight: "600",
                    whiteSpace: "nowrap",
                  }}
                >
                  Slot <br /> (
                  {moment(item?.sd_seva_slots?.start_time, "HH:mm:ss").format(
                    "hh:mm A"
                  )}
                  to
                  <span style={{marginLeft: "5px"}}>
                    {moment(item?.sd_seva_slots?.end_time, "HH:mm:ss").format(
                      "hh:mm A"
                    )}
                    )
                  </span>
                </span>
              ) : (
                <span
                  style={{
                    fontWeight: "600",
                    whiteSpace: "nowrap",
                    marginRight: "5px",
                  }}
                >
                  Slot - (
                  {moment(item?.sd_seva_slots?.start_time, "HH:mm:ss").format(
                    "hh:mm A"
                  )}
                  -
                  {moment(item?.sd_seva_slots?.end_time, "HH:mm:ss").format(
                    "hh:mm A"
                  )}
                  )
                </span>
              )}
            </div>
            <input
              className="sd-darshanam-radio"
              type="radio"
              id={"slot" + 11}
              name=""
              value={slot}
              onClick={() => {
                if (1 > item?.available_slots) {
                  return;
                }
                setSlot(item);
                if (err?.find((e) => e.type === `slot`)) {
                  let data = err.filter((e) => e.type !== "slot");
                  setErr(data);
                }
              }}
              checked={slot.id === item?.id}
            />
          </div>
        </div>
      );
    });
  };

  const handleSubmit = async () => {
    setButtonLoading(true);
    setLoading(true);
    if (slot === "" || !slot) {
      notification.error({message: "Select a Slot"});
      setLoading(false);
      setButtonLoading(false);
      return;
    }
    if (phone === "" || devoteeName === "") {
      notification.error({message: "Please fill all mandatory fields"});
      setLoading(false);
      setButtonLoading(false);
      return;
    }

    if (phone.length !== 10) {
      notification.error({message: "Phone number must be 10 digits"});
      setLoading(false);
      setButtonLoading(false);
      return;
    }
    if (idProofType === "") {
      notification.error({message: "Select an ID proof"});
      setLoading(false);
      setButtonLoading(false);
      return;
    } else {
      if (idProofType === "pan") {
        let txt = idProofNumber.toUpperCase();
        let regex = /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
        let pan = {
          C: "Company",
          P: "Personal",
          H: "Hindu Undivided Family (HUF)",
          F: "Firm",
          A: "Association of Persons (AOP)",
          T: "AOP (Trust)",
          B: "Body of Individuals (BOI)",
          L: "Local Authority",
          J: "Artificial Juridical Person",
          G: "Govt",
        };
        pan = pan[txt[3]];
        if (regex.test(txt)) {
          if (pan !== "Personal") {
            notification.error({message: "Invalid Pan Card Number."});
            setLoading(false);
            setButtonLoading(false);
            return;
          }
        } else {
          notification.error({message: "Invalid Pan Card Number."});
          setLoading(false);
          setButtonLoading(false);
          return;
        }
      } else if (idProofType === "voter") {
        let regex = /^([a-zA-Z]){3}([0-9]){7}?$/;
        if (!regex.test(idProofNumber.toUpperCase())) {
          notification.error({message: "Invalid Voter Card Number."});
          setLoading(false);
          setButtonLoading(false);
          return;
        }
      } else if (idProofType === "aadhar") {
        let regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
        if (!regex.test(idProofNumber.toUpperCase())) {
          notification.error({message: "Invalid Aadhar Card Number."});
          setLoading(false);
          setButtonLoading(false);
          return;
        }
      }
    }
    try {
      let body = {
        name: devoteeName,
        contact: `+91${phone}`,
        idProofType: idProofType,
        idProofNumber: idProofNumber,
        bookingDate: moment(startDate).format("YYYY-MM-DD"),
        sevaSlotId: slot?.sd_seva_slots?.id,
      };

      if (idProofType !== "") {
        if (idProofNumber !== "") body.idProofNumber = idProofNumber;
        body.idProofType = idProofType;
      }

      if (address !== "") {
        body.address = address;
      }
      if (age !== "") {
        body.dob = moment().subtract(age, "years").format("YYYY-MM-DD");
      }
      if (gothram !== "") {
        body.gothram = gothram;
      }
      if (cashReference !== "") {
        body.cashReference = cashReference;
      }
      if (cashRemarks !== "") {
        body.cashRemarks = cashRemarks;
      }
      let res = await SevaService.bookSevaTicket(body);
      notification.success({message: "Success"});
      setBookingData(res);
      // clearFields();
      setLoading(false);
      setIsModalVisible(true);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
      setLoading(false);
    }
    setButtonLoading(false);
  };

  return (
    <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div
          style={{
            maxWidth: "1200px",
            width: "100%",
            background: "white",
            padding: "30px 50px",
          }}
        >
          <Row
            justify="center"
            style={{fontSize: "22px", marginBottom: "20px"}}
          >
            Pratyaksha Seva
          </Row>
          <Divider />
          <Row justify="space-between">
            <Col style={{width: "48%", justifyContent: "start"}}>
              <label style={labelStyles}>
                Select a Seva
                <span style={{color: "red", marginLeft: "3px"}}>*</span>
              </label>
              <br />
              <Select
                size="medium"
                style={{width: "100%"}}
                showSearch
                placeholder="Select a Seva"
                optionFilterProp="children"
                value={selectedPratyakshaSeva?.name}
                onChange={(e) => {
                  let temp = sevaList?.find((a) => a.name === e);
                  setSelectedPratyakshaSeva(temp);
                  setStartDate("");
                  setSlotsData([]);
                  setSlot("");
                  setStartDate(moment(new Date()));
                  getSlots(temp?.id, moment(new Date()).format("YYYY-MM-DD"));
                }}
              >
                {sevaList?.map((item, i) => {
                  return (
                    <Option key={item.name + "seva-list" + i} value={item.name}>
                      {item.name} - Rs.
                      {item?.price}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col
              style={{
                width: "48%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Col style={{width: "100%", justifyContent: "start"}}>
                <label style={labelStyles}>
                  Booking Date
                  <span style={{color: "red", marginLeft: "3px"}}>*</span>
                </label>
                <br />
                <DatePicker
                  value={startDate}
                  style={{width: "100%"}}
                  format="DD-MM-YYYY"
                  disabledDate={(d) =>
                    !d || d.isBefore(StringUtils.addDays(new Date(), -1))
                  }
                  onChange={(date) => {
                    if (selectedPratyakshaSeva === "") {
                      notification.error({
                        message: "Please select a Seva before selecting a date",
                      });
                      return;
                    }
                    getSlots(
                      selectedPratyakshaSeva?.id,
                      moment(date).format("YYYY-MM-DD")
                    );
                    setStartDate(date);
                  }}
                />
              </Col>
            </Col>
          </Row>
          <br />
          <Divider />

          <Row style={{display: "flex", flexDirection: "column"}}>
            {slotsData?.length !== 0 ? (
              <>
                <label style={labelStyles}>
                  Select a Slot
                  <span style={{color: "red", marginLeft: "3px"}}>*</span>
                </label>
                <br />
                <div
                  className="clearfix sd-darshanam-content"
                  style={{position: "relative"}}
                >
                  {getSlotsDetails()}
                  <p
                    style={{
                      position: "absolute",
                      top: "-12px",
                      left: "0px",
                      textAlign: "right",
                      color: "red",
                    }}
                  >
                    {err?.find((e) => e.type === `slot`)?.msg}
                  </p>
                </div>
                {slot !== "" && (
                  <>
                    <br />
                    <Row justify="space-between">
                      <Col style={{width: "48%", justifyContent: "start"}}>
                        <label style={labelStyles}>
                          Select ID Proof{" "}
                          <span style={{color: "red", marginLeft: "3px"}}>
                            *
                          </span>
                        </label>
                        <br />
                        <Select
                          size="medium"
                          style={{width: "100%"}}
                          placeholder="Select a ID"
                          optionFilterProp="children"
                          value={idProofType}
                          onChange={(e) => {
                            setIdProofType(e);
                            setIdProofNumber("");
                          }}
                        >
                          <Option key="pan" value="pan">
                            PAN
                          </Option>
                          <Option key="aadhar" value="aadhar">
                            AADHAR
                          </Option>

                          <Option key="voter" value="voter">
                            Voter ID
                          </Option>
                        </Select>
                      </Col>
                      <Col style={{width: "48%"}}>
                        <label style={labelStyles}>
                          Enter ID Proof{" "}
                          <span style={{color: "red", marginLeft: "3px"}}>
                            *
                          </span>
                        </label>
                        <br />
                        <Input
                          size="medium"
                          type="text"
                          placeholder="Enter ID Proof"
                          style={{width: "100%"}}
                          value={idProofNumber}
                          onChange={(e) => {
                            setIdProofNumber(
                              `${e.target.value?.toUpperCase()}`
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row justify="space-between">
                      <Col style={{width: "48%", justifyContent: "start"}}>
                        <label style={labelStyles}>
                          Name of Piligrim
                          <span style={{color: "red", marginLeft: "3px"}}>
                            *
                          </span>
                        </label>
                        <br />
                        <Input
                          type="text"
                          placeholder="Enter a Name"
                          value={devoteeName}
                          onChange={(e) => {
                            setDevoteeName(e.target.value);
                          }}
                        />
                      </Col>
                      <Col style={{width: "48%"}}>
                        <label style={labelStyles}>
                          Mobile Number
                          <span style={{color: "red", marginLeft: "3px"}}>
                            *
                          </span>
                        </label>
                        <br />
                        <Input
                          size="medium"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          maxLength={10}
                          addonBefore={"+91"}
                          placeholder="Please Enter Mobile Number"
                          style={{width: "100%"}}
                          value={phone}
                          onChange={(e) => {
                            if (e.target.value.length > 10) {
                              return;
                            }
                            setPhone(`${e.target.value}`);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row justify="space-between">
                      <Col style={{width: "48%", justifyContent: "start"}}>
                        <label style={labelStyles}>Age </label>
                        <br />
                        <Input
                          size="medium"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          maxLength={3}
                          placeholder="Please Enter Age"
                          style={{width: "100%"}}
                          value={age}
                          onChange={(e) => {
                            setAge(`${e.target.value}`);
                          }}
                        />
                      </Col>
                      <Col style={{width: "48%"}}>
                        <label style={labelStyles}>Gothram</label>
                        <br />
                        <Input
                          type="text"
                          placeholder="Enter a Gothram"
                          value={gothram}
                          onChange={(e) => {
                            setGothram(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row justify="space-between">
                      <Col style={{width: "48%", justifyContent: "start"}}>
                        <label style={labelStyles}>Address </label>
                        <br />
                        <Input
                          size="medium"
                          type="text"
                          placeholder="Please Enter Address"
                          style={{width: "100%"}}
                          value={address}
                          onChange={(e) => {
                            setAddress(`${e.target.value}`);
                          }}
                        />
                      </Col>
                      <Col style={{width: "48%", justifyContent: "start"}}>
                        <label style={labelStyles}>
                          Select Payment Type
                          <span style={{color: "red", marginLeft: "3px"}}>
                            *
                          </span>
                        </label>
                        <br />
                        <Select
                          size="medium"
                          style={{width: "100%"}}
                          placeholder="Select payment type"
                          optionFilterProp="children"
                          value={payment}
                          onChange={(e) => {
                            setPayment(e);
                          }}
                        >
                          <Option key="CASH" value="CASH">
                            Cash
                          </Option>
                          <Option key="UPI" value="UPI">
                            UPI
                          </Option>
                          <Option key="CARD" value="CARD">
                            Card
                          </Option>
                          <Option key="CHEQUE" value="CHEQUE">
                            Cheque
                          </Option>
                          <Option key="DD" value="DD">
                            DD
                          </Option>
                        </Select>
                      </Col>
                      <Col style={{width: "48%", marginTop: "20px"}}>
                        <label style={labelStyles}>
                          Enter Reference (UPI Payment ID, Cheque ID, DD ID)
                        </label>
                        <br />
                        <Input
                          size="medium"
                          type="text"
                          placeholder="Enter Reference ID"
                          style={{width: "100%"}}
                          value={cashReference}
                          onChange={(e) => {
                            setCashReference(`${e.target.value}`);
                          }}
                        />
                      </Col>
                      <Col style={{width: "48%", marginTop: "20px"}}>
                        <label style={labelStyles}>Enter Payment Remarks</label>
                        <br />
                        <Input
                          size="medium"
                          type="text"
                          placeholder="Enter Payment Remarks"
                          style={{width: "100%"}}
                          value={cashRemarks}
                          onChange={(e) => {
                            setCashRemarks(`${e.target.value}`);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                  </>
                )}
                {slot !== "" && (
                  <>
                    <Row style={{height: "50px"}}>
                      <Col
                        style={{
                          width: "25%",
                          fontWeight: "bold",
                          fontSize: "23px",
                          textAlign: "left",
                        }}
                      >
                        Total Amount :
                      </Col>
                      <Col
                        style={{
                          width: "75%",
                          textAlign: "right",
                          fontSize: "22px",
                          fontWeight: "600",
                        }}
                      >
                        {FormatNumberINRCurrency(selectedPratyakshaSeva?.price)}
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <Button
                          type="primary"
                          onClick={() => {
                            handleSubmit();
                          }}
                          loading={buttonLoading}
                        >
                          Book Now <RightOutlined />
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            ) : (
              <>
                {startDate && !slotLoading ? (
                  <>
                    <hr className="clearfix"></hr>
                    <div
                      className="clearfix sd-darshanam-content"
                      style={{position: "relative", color: "red"}}
                    >
                      No Slots available on
                      {moment(startDate).format("DD-MM-YYYY")}
                    </div>
                  </>
                ) : (
                  startDate && (
                    <>
                      <hr className="clearfix"></hr>
                      <div
                        className="clearfix sd-darshanam-content"
                        style={{position: "relative"}}
                      >
                        Fetching slots...Please wait
                      </div>
                    </>
                  )
                )}
              </>
            )}
          </Row>
        </div>
      )}
      <Modal
        visible={isModalVisible}
        width={850}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <PrintTicket
          type="pratyakshaSeva"
          from="Counter"
          bookingDate={moment(
            bookingData?.darshanamBooking?.booking?.created_at
          ).format("DD-MM-YYYY")}
          performanceDate={moment(
            bookingData?.darshanamBooking?.booking?.booking_date
          ).format("DD-MM-YYYY")}
          name={bookingData?.darshanamBooking?.booking?.name}
          amountPaid={bookingData?.darshanamBooking?.booking?.price}
          phone={bookingData?.darshanamBooking?.booking?.contact}
          proof={bookingData?.darshanamBooking?.booking?.id_proof_number}
          ticketId={bookingData?.ticket}
          setPrintTicketModal={setIsModalVisible}
          sevaName={bookingData?.darshanamBooking?.sevaName}
          timeSlot={bookingData?.darshanamBooking?.timeSlot}
          categoryTransactionId={bookingData?.category_transaction_id}
          serialNumber={bookingData?.daily_category_transaction_id}
          setData={setBookingData}
        />
      </Modal>
    </div>
  );
};
export default withRouter(Sd_CounterManagement_PratyakshaSeva_One);
