/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  // Badge,
  // Button,
  // Input,
  notification,
  Popconfirm,
  Space,
  Table,
  Tooltip,
} from "antd";
import {useState} from "react";
import {withRouter} from "react-router-dom";
// import { SearchOutlined } from "@ant-design/icons";
// import Highlighter from "react-highlight-words";
// import CounterService from "../../../Services/Counter.service";
import {routeMapMini} from "../../../constants/routeMap";
import moment from "moment";
import ShiftService from "../../../Services/Shift.service";
import CounterService from "../../../Services/Counter.service";

const CounterShiftManagemnetTable = ({
  list,
  blockID,
  loading,
  setLoading,
  setUpdateTable,
  getCountersShifts,
  setEdit,
  setTemp,
  history,
}) => {
  // const [searchText, setSearchText] = useState("");
  // const [searchedColumn, setSearchedColumn] = useState("");
  // const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //   confirm();
  //   setSearchText(selectedKeys[0]);
  //   setSearchedColumn(dataIndex);
  // };

  // const handleReset = (clearFilters) => {
  //   clearFilters();
  //   setSearchText("");
  // };

  // const getColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //   }) => (
  //     <div style={{padding: 8}}>
  //       <Input
  //         // ref={node => {
  //         //   this.searchInput = node;
  //         // }}
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) =>
  //           setSelectedKeys(e.target.value ? [e.target.value] : [])
  //         }
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{width: 188, marginBottom: 8, display: "block"}}
  //       />
  //       <Button
  //         type="primary"
  //         onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         size="small"
  //         style={{width: 90, marginRight: 8}}
  //       >
  //         Search
  //       </Button>
  //       <Button
  //         onClick={() => handleReset(clearFilters)}
  //         size="small"
  //         style={{width: 90}}
  //       >
  //         Reset
  //       </Button>
  //     </div>
  //   ),
  //   filterIcon: (filtered) => (
  //     <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}} />
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
  //   onFilterDropdownVisibleChange: (visible) => {
  //     if (visible) {
  //       // setTimeout(() => this.searchInput.select());
  //     }
  //   },
  //   render: (text) =>
  //     searchedColumn === dataIndex ? (
  //       <Highlighter
  //         highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
  //         searchWords={[searchText]}
  //         autoEscape
  //         textToHighlight={text.toString()}
  //       />
  //     ) : (
  //       text
  //     ),
  // });

  const columns = [
    {title: "id", dataIndex: "id", key: "id"},
    {
      title: "Assigned to User",
      dataIndex: "assigned_to_user",
      render: (record) => {
        return <Space size="middle">{record.display_name}</Space>;
      },
    },
    {
      title: "Shift Name",
      dataIndex: "sd_shift",
      render: (record) => {
        return (
          <Tooltip
            placement="top"
            title={`${moment(record?.start, "HH:mm:ss").format(
              "hh:mm A"
            )} - ${moment(record?.end, "HH:mm:ss").format("hh:mm A")}`}
          >
            <Space size="middle">{record.name}</Space>
          </Tooltip>
        );
      },
    },

    {
      title: "Start Date",
      dataIndex: "start_date",
      render: (record) => {
        return (
          <Space size="middle">{moment(record).format("DD-MM-YYYY")}</Space>
        );
      },
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      render: (record) => {
        return (
          <Space size="middle">{moment(record).format("DD-MM-YYYY")}</Space>
        );
      },
    },

    {
      title: "Action",
      fixed: "right",
      responsive: ["md", "lg", "xl", "xsl"],
      render: (record) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Are you sure remove this Shift?"
              onConfirm={async () => {
                setLoading(true);
                try {
                  await CounterService.deleteCounterPreShiftById(record.id);
                  notification.success({
                    message: `Shift with id:${record.id} has been deleted`,
                  });
                  getCountersShifts();
                } catch (error) {
                  if (error?.response?.status === 401) {
                    notification.error({
                      message:
                        "Token Expired, redirecting to login page in 5 seconds",
                    });
                    setTimeout(() => {
                      history?.push(routeMapMini.Login);
                    }, 5000);
                  }
                  if (error.response?.data?.message) {
                    notification.error({
                      message: error.response?.data?.message,
                    });
                  } else {
                    notification.error({message: error.message});
                  }
                }
                setLoading(false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm>
            <Popconfirm
              title={
                record.inLoggedIn
                  ? "Are you sure to close the current shift"
                  : "Are you sure to reopen the shift"
              }
              onConfirm={async () => {
                try {
                  if (record.inLoggedIn) {
                    await ShiftService.adminCloseShift(
                      record.sd_shift_id,
                      blockID,
                      record.assigned_to_user_id
                    );
                  } else {
                    await ShiftService.adminReopenShift(
                      record.sd_shift_id,
                      blockID,
                      record.assigned_to_user_id
                    );
                  }
                  setUpdateTable(Math.random());
                  notification.success({
                    message: `Success`,
                  });
                } catch (error) {
                  if (error?.response?.status === 401) {
                    notification.error({
                      message: "Token Expired, redirecting to login page",
                    });
                    history?.push(routeMapMini.Login);
                  }
                  if (error.response?.data?.message) {
                    notification.error({
                      message: error.response?.data?.message,
                    });
                  } else {
                    notification.error({message: error.message});
                  }
                }
              }}
            >
              <a>{record.inLoggedIn ? "Close Shift" : "Reopen Last Shift"}</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      tableLayout="fixed"
      scroll={{x: true}}
      loading={loading}
      style={{width: "100%", textTransform: "capitalize"}}
      columns={columns}
      dataSource={list}
      tableTotalSize
    ></Table>
  );
};

export default withRouter(CounterShiftManagemnetTable);
