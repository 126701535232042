import Axios from "../utils/axios";

const ShiftService = {
  shiftLoggedIn: async () => {
    const resp = await Axios.get(`/shift-log/isLoggedIn`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  shiftLogIn: async (counterId, shiftId) => {
    const resp = await Axios.post(
      `/shift-log/log-in?preAssignedShiftId=${counterId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  shiftLogOut: async () => {
    const resp = await Axios.post(
      `/shift-log/log-out`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  ShiftReport: async (shiftId, header) => {
    let url = header
      ? `/accommodation-report/shift-report-challan?shiftId=${shiftId}&includeTables=true`
      : `/accommodation-report/shift-report-challan?shiftId=${shiftId}`;
    const resp = await Axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  GetCounterForRole: async () => {
    const resp = await Axios.get(`/counter/for-role`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  GetCounterForUserShift: async () => {
    const resp = await Axios.get(`/counter/for-user-shift`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  GetCounterForUserPreShift: async () => {
    const resp = await Axios.get(`/pre-assign-shifts/user-shifts-to-login`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  getCurrentShiftDetails: async () => {
    const resp = await Axios.get(`/shift-log/current-shift-report`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  allShiftReports: async (userId, counterId, fromDate, toDate, page, limit) => {
    const params = {
      userId,
      counterId,
      fromDate,
      toDate,
      page,
      limit,
    };

    Object.keys(params).forEach((key) => {
      if (params[key] === undefined || !params[key]) {
        delete params[key];
      }
    });

    const resp = await Axios.get(`/shift-log-report/total/report`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      params: params,
    });
    return resp.data;
  },

  adminReopenShift: async (shiftId, counterId, userId) => {
    const resp = await Axios.post(
      `/shift-log/admin/reopen-previous-shift?shiftId=${shiftId}&counterId=${counterId}&userId=${userId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  adminCloseShift: async (shiftId, counterId, userId) => {
    const resp = await Axios.post(
      `/shift-log/admin/close-current-shift?shiftId=${shiftId}&counterId=${counterId}&userId=${userId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  getScanTicketLogs: async (shiftLogID, outcome) => {
    let resp = await Axios.get(
      `scan-ticket-log/transactions/${shiftLogID}?outcome=${outcome}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  scanReportDownload: async (id, outcome) => {
    const resp = await Axios.get(
      `scan-ticket-log/transactions/pdf/${id}?outcome=${outcome}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        responseType: "blob",
      }
    );
    return resp.data;
  },
};

export default ShiftService;
