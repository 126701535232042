import React from "react";
import numberToWords from "../../utils/amount-to-words/number-to-words";
import srisailam_logo from "../../assets/srisailam_logo.png";
import QRCode from "qrcode.react";
import moment from "moment";

const DarshanamTicket = ({
  serialNumber,
  sevaName,
  abishekam,
  bookingDate,
  timeSlot,
  startTime,
  endTime,
  persons,
  name,
  amountPaid,
  actualAmount,
  phone,
  proof,
  ticketId,
  ticketcopy,
  from,
  performanceDate,
  categoryTransactionId,
  data,
}) => {
  return (
    <div style={{textAlign: "center"}}>
      <>
        <div>
          <img
            src={srisailam_logo}
            alt="logo"
            style={{
              width: "20%",
              justifyContent: "center",
              margin: "auto",
              filter: "grayscale(1)",
            }}
          />
        </div>

        <>
          <div
            style={{
              justifyContent: "center",
              margin: "0",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            <p>
              Sri Bhramaramba Mallikarjuna Swamy Varla Devasthanam - Srisailam
            </p>
          </div>
        </>

        <div
          style={{
            justifyContent: "center",
            margin: "0",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          <p style={{margin: "0"}}>
            {from !== "Counter"
              ? `Darshanam Online - ${ticketcopy}`
              : "Darshanam Counter"}
          </p>
        </div>
      </>

      <div style={{textAlign: "left", marginBottom: "10px"}}>
        {ticketId && (
          <div style={{display: "flex"}}>
            <div
              style={{
                width: "40%",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              <p style={{margin: "0"}}>Ticket Id</p>
            </div>
            <div
              style={{
                width: "60%",
                overflowWrap: "break-word",
                textTransform: "capitalize",
                display: "inline-flex",
              }}
            >
              <p style={{paddingRight: "10px", margin: "0"}}>:</p>
              <p style={{margin: "0"}}>
                {ticketId ? ticketId : "transactionId=127"}
              </p>
            </div>
          </div>
        )}
        <div style={{display: "flex"}}>
          <div
            style={{
              width: "40%",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            <p style={{margin: "0"}}>Darshanam</p>
          </div>
          <div
            style={{
              width: "60%",
              overflowWrap: "break-word",
              textTransform: "capitalize",
              display: "inline-flex",
              fontWeight: "bold",
            }}
          >
            <p style={{paddingRight: "10px", margin: "0"}}>:</p>
            <p style={{margin: "0"}}>{sevaName ? sevaName : abishekam}</p>
          </div>
        </div>
        {from === "Counter" && (
          <div style={{display: "flex"}}>
            <div
              style={{
                width: "40%",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              <p style={{margin: "0"}}>TI No</p>
            </div>
            <div
              style={{
                width: "60%",
                overflowWrap: "break-word",
                textTransform: "capitalize",
                display: "inline-flex",
              }}
            >
              <p style={{paddingRight: "10px", margin: "0"}}>:</p>
              <p style={{margin: "0"}}>
                {/* {categoryTransactionId?.substring(
                categoryTransactionId?.indexOf("-") + 1
              )} */}
                {categoryTransactionId?.split("-")[2]} -{" "}
                {categoryTransactionId?.split("-")?.pop()}
              </p>
            </div>
          </div>
        )}
        <div style={{display: "flex"}}>
          <div
            style={{
              width: "40%",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            <p style={{margin: "0"}}>{`Date`}</p>
          </div>
          <div
            style={{
              width: "60%",
              overflowWrap: "break-word",
              textTransform: "capitalize",
              display: "inline-flex",
            }}
          >
            <p style={{paddingRight: "10px", margin: "0"}}>:</p>
            <p style={{margin: "0"}}>{performanceDate}</p>
          </div>
        </div>

        <div style={{display: "flex"}}>
          <div
            style={{
              width: "40%",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            <p style={{margin: "0"}}>Time Slot</p>
          </div>
          <div
            style={{
              width: "60%",
              overflowWrap: "break-word",
              textTransform: "capitalize",
              display: "inline-flex",
            }}
          >
            <p style={{paddingRight: "10px", margin: "0"}}>:</p>
            {timeSlot ? (
              <p style={{margin: "0"}}>{timeSlot}</p>
            ) : (
              <p style={{margin: "0"}}>
                {moment(startTime, "HH:mm:ss").format("hh:mm A")} -{" "}
                {moment(endTime, "HH:mm:ss").format("hh:mm A")}
              </p>
            )}
          </div>
        </div>

        {persons && (
          <div style={{display: "flex"}}>
            <div
              style={{
                width: "40%",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              <p style={{margin: "0"}}>No of Devotees</p>
            </div>
            <div
              style={{
                display: "inline-flex",
                width: "40%",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              <p style={{paddingRight: "10px", margin: "0"}}>:</p>
              <p style={{margin: "0"}}>{persons}</p>
            </div>
          </div>
        )}
        {name && (
          <div style={{display: "flex"}}>
            <div
              style={{
                width: "40%",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              <p style={{margin: "0"}}>Devotee Names</p>
            </div>
            <div
              style={{
                width: "60%",
                overflowWrap: "break-word",
                textTransform: "capitalize",
                display: "inline-flex",
              }}
            >
              <p style={{paddingRight: "10px", margin: "0"}}>:</p>
              <p style={{margin: "0"}}>{name?.split(",")[0].trim()}</p>
            </div>
          </div>
        )}

        <div style={{display: "flex"}}>
          <div
            style={{
              width: "40%",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            <p style={{margin: "0"}}>Ticket Price</p>
          </div>
          <div
            style={{
              width: "60%",
              overflowWrap: "break-word",
              textTransform: "capitalize",
              display: "inline-flex",
            }}
          >
            <p style={{paddingRight: "10px", margin: "0"}}>:</p>
            <p style={{margin: "0"}}>Rs. {amountPaid / persons}.00</p>
          </div>
        </div>

        {amountPaid && actualAmount && (
          <>
            <div style={{display: "flex"}}>
              <div
                style={{
                  width: "40%",
                  textTransform: "capitalize",
                }}
              >
                {`Darshanam Amount`}
              </div>
              <div
                style={{
                  width: "40%",
                  textTransform: "capitalize",
                  display: "inline-flex",
                }}
              >
                <p style={{paddingRight: "10px", margin: "0"}}>:</p>
                <p style={{margin: "0"}}> Rs. {actualAmount}.00</p>
              </div>
            </div>
            <div style={{display: "flex"}}>
              <div
                style={{
                  width: "40%",
                  textTransform: "capitalize",
                }}
              >
                <p style={{margin: "0"}}>Handling Charges</p>
              </div>
              <div
                style={{
                  width: "40%",
                  textTransform: "capitalize",
                  display: "inline-flex",
                }}
              >
                <p style={{paddingRight: "10px", margin: "0"}}>:</p>
                <p style={{margin: "0"}}>Rs. {amountPaid - actualAmount}.00</p>
              </div>
            </div>
          </>
        )}
        {amountPaid && (
          <div style={{display: "flex"}}>
            <div
              style={{
                width: "40%",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              <p style={{margin: "0"}}>Total Amount</p>
            </div>
            <div
              style={{
                width: "60%",
                overflowWrap: "break-word",
                textTransform: "capitalize",
                display: "inline-flex",
              }}
            >
              <p style={{paddingRight: "10px", margin: "0"}}>:</p>
              <p style={{margin: "0", fontSize: "22px", fontWeight: "800"}}>
                Rs. {amountPaid}.00
                <>
                  <br />
                  <div style={{fontSize: "13px"}}>
                    ({numberToWords(amountPaid)} Rupees)
                  </div>
                </>
              </p>
            </div>
          </div>
        )}
        {phone && (
          <div style={{display: "flex"}}>
            <div
              style={{
                width: "40%",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              <p style={{margin: "0"}}>Contact No</p>
            </div>
            <div
              style={{
                width: "60%",
                overflowWrap: "break-word",
                textTransform: "capitalize",
                display: "inline-flex",
              }}
            >
              <p style={{paddingRight: "10px", margin: "0"}}>:</p>
              <p style={{margin: "0"}}>{phone}</p>
            </div>
          </div>
        )}
        {proof && (
          <div style={{display: "flex"}}>
            <div
              style={{
                width: "40%",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              <p style={{margin: "0"}}>ID Proof No.</p>
            </div>
            <div
              style={{
                width: "60%",
                overflowWrap: "break-word",
                textTransform: "capitalize",
                display: "inline-flex",
              }}
            >
              <p style={{paddingRight: "10px", margin: "0"}}>:</p>
              <p style={{margin: "0"}}>{proof}</p>
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          justifyContent: "center",
          margin: "auto",
        }}
      >
        {" "}
        <QRCode
          size="75"
          fontSize="15px"
          value={ticketId ? ticketId : "transactionId=127"}
        />
      </div>
      {categoryTransactionId && (
        <div justify="space-between" style={{fontWeight: "bold"}}>
          <div>
            {`Sl No : 
            ${serialNumber?.split("-")[3]}`}{" "}
          </div>
        </div>
      )}
    </div>
  );
};

export default DarshanamTicket;
