import {Col, DatePicker} from "antd";
import React from "react";
import {withRouter} from "react-router";
const TempleTimePickerOne = ({name, time, setTime}) => {
  return (
    <>
      <Col span={9}>
        <h3>{name}</h3>
      </Col>{" "}
      <Col span={15}>
        <DatePicker
          allowClear
          picker="time"
          defaultValue={time}
          value={time}
          onChange={(e) => {
            setTime(e);
          }}
        />{" "}
      </Col>
    </>
  );
};
export default withRouter(TempleTimePickerOne);
