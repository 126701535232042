/* eslint-disable jsx-a11y/anchor-is-valid */
import { notification, Popconfirm, Row, Space, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PublicationService from "../../../Services/Publication.service";

const PublicationFilesTable = ({
  updateTable,
  setUpdatePublicationFileId,
  publicationId,
  history,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (publicationId) {
      loadPublications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTable, publicationId]);

  const loadPublications = async () => {
    try {
      setLoading(true);
      const pubs = await PublicationService.getFilesForPublication(
        publicationId
      );
      setData(
        pubs?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      if (error.response?.data?.message) {
        notification.error({ message: error.response?.data?.message });
      } else {
        notification.error({ message: error.message });
      }
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        if (text.toLowerCase() === "purchase") {
          return <Tag color="red">{text}</Tag>;
        } else if (text.toLowerCase() === "download") {
          return <Tag color="green">{text}</Tag>;
        } else if (text.toLowerCase() === "subscription") {
          return <Tag color="blue">{text}</Tag>;
        } else {
          return <Tag color="orange">{text}</Tag>;
        }
      },
    },
    {
      title: "Language Options",
      dataIndex: "language_options",
      key: "language_options",
      render: (text) => {
        return text?.split(",")?.map((item) => {
          if (item?.trim().toLowerCase() === "english") {
            return <Tag color="red">{item}</Tag>;
          } else if (item?.trim().toLowerCase() === "telugu") {
            return <Tag color="green">{item}</Tag>;
          } else if (item?.trim().toLowerCase() === "kannada") {
            return <Tag color="blue">{item}</Tag>;
          } else {
            return <Tag color="orange">{item}</Tag>;
          }
        });
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Delivery Price",
      dataIndex: "delivery_price",
      key: "delivery_price",
    },
    {
      title: "Action",
      fixed: "right",
      responsive: ["md", "lg", "xl", "xsl"],
      render: (record) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                setUpdatePublicationFileId(record.key);
              }}
            >
              Modify
            </a>
            <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={async () => {
                setLoading(true);
                try {
                  await PublicationService.deletePublicationFile(record.key);
                  notification.success({
                    message: `Publication ${record.key} has been deleted`,
                  });
                } catch (error) {
                  if (error.response?.data?.message) {
                    notification.error({
                      message: error.response?.data?.message,
                    });
                  } else {
                    notification.error({ message: error.message });
                  }
                }
                loadPublications();
                setLoading(false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <Table
      tableLayout="fixed"
      scroll={{ x: true }}
      loading={loading}
      style={{ width: "100%", textTransform: "capitalize" }}
      columns={columns}
      dataSource={data}
      expandable={{
        expandedRowRender: (record) => (
          <>
            <Row>
              <p style={{ fontWeight: "bolder", marginRight: "20px" }}>File:</p>
              <a onClick={() => window.open(record.file_url, "_blank")}>File</a>
            </Row>
          </>
        ),
      }}
    ></Table>
  );
};

export default withRouter(PublicationFilesTable);
