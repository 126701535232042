import {Button, Col, Input, notification, Row, Space} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import TextArea from "antd/lib/input/TextArea";
import Modal from "antd/lib/modal/Modal";
import {useContext, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {routeMapMini} from "../../constants/routeMap";
import {UserContext} from "../../context/user.context";
import RoomService from "../../Services/rooms.service";
import BlockTable from "./blockTable";

const Blocks = ({history}) => {
  const [showNewModal, setShowNewModal] = useState(false);
  const [loading, setLoading] = useState();
  const [blockName, setBlockName] = useState("");
  const [description, setDescription] = useState("");
  const [minDays, setMinDays] = useState();
  const [maxDays, setMaxDays] = useState();
  const [openForBooking, setOpenForBooking] = useState(false);

  const {userDetails} = useContext(UserContext);

  const [updateTable, setUpdateTable] = useState();

  const [updateBlockId, setUpdateBlockId] = useState();

  useEffect(() => {
    (async () => {
      if (updateBlockId) {
        setShowNewModal(true);
        try {
          const resp = await RoomService.getBlockById(updateBlockId);
          setBlockName(resp?.block_name);
          setDescription(resp?.description);
          setMinDays(resp?.min_days);
          setMaxDays(resp?.max_days);
          setOpenForBooking(resp?.open_for_bookings);
          setShowNewModal(true);
        } catch (error) {
          if (error?.response?.status === 401) {
            notification.error({
              message: "Token Expired, redirecting to login page in 5 seconds",
            });
            history?.push(routeMapMini.Login);
          } else if (error.response?.data?.message) {
            notification.error({message: error.response?.data?.message});
          } else {
            notification.error({message: error.message});
          }
        }
      }
    })();
  }, [history, updateBlockId]);

  const handleOk = async () => {
    if (!blockName) {
      return notification.error({message: `name is required`});
    } else if (minDays < 0) {
      return notification.error({
        message: `minDays should be positive`,
      });
    } else if (maxDays < 0) {
      return notification.error({
        message: `maxDays should be positive`,
      });
    }
    setLoading(true);
    try {
      if (updateBlockId) {
        await RoomService.editBlock(
          updateBlockId,
          blockName,
          description,
          minDays,
          maxDays,
          openForBooking
        );
        notification.success({
          message: `block ${updateBlockId} updated`,
        });
        clearFields();
        setUpdateTable(updateBlockId + Math.random());
      } else {
        const block = await RoomService.createNewBlock(
          blockName,
          description,
          minDays,
          maxDays,
          openForBooking
        );
        notification.success({message: `block ${block.id} created`});
        setUpdateTable(block.id + Math.random());
      }
      setShowNewModal(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setBlockName(undefined);
    setDescription(undefined);
    setMinDays(undefined);
    setMaxDays(undefined);
    setOpenForBooking(undefined);
    setUpdateBlockId(undefined);
  };

  const handleCancel = () => {
    clearFields();
    setShowNewModal(false);
  };

  return (
    <div style={{width: "100%"}}>
      <Row style={{width: "100%", marginBottom: "15px"}} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setShowNewModal(true);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Add New Block
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              history?.push(routeMapMini.AccommodationBlockBookingDetails);
            }}
            disabled={
              !["accommodation_counter", "admin"].includes(userDetails.role)
            }
          >
            Block Booking Details
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            // onClick={() => {
            //   history?.push(routeMapMini.SevaReports);
            // }}
            disabled={
              !["accommodation_counter", "admin"].includes(userDetails.role)
            }
          >
            Accommodation Reports
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              history?.push(routeMapMini.RoomAllocation);
            }}
            disabled={
              !["accommodation_counter", "admin"].includes(userDetails.role)
            }
          >
            Room Allocation Report
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              history?.push(routeMapMini.AccommodationDisabledDates);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Disable Block on Date
          </Button>
        </Col>
      </Row>
      <Row style={{width: "100%"}}>
        <BlockTable
          updateTable={updateTable}
          setUpdateBlockId={setUpdateBlockId}
        />
      </Row>
      <Modal
        visible={showNewModal}
        title={`Add New Block`}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify="center" style={{width: "100%", paddingTop: "10px"}}>
          Block Name
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Block Name"
            value={blockName}
            onChange={(e) => {
              setBlockName(e.target.value);
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", paddingTop: "10px"}}>
          Description
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <TextArea
            placeholder="Description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", paddingTop: "10px"}}>
          Min Days
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="min days"
            value={minDays}
            onChange={(e) => {
              setMinDays(e.target.value);
            }}
            type="number"
            onWheel={(event) => event.currentTarget.blur()}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", paddingTop: "10px"}}>
          Max Days
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="max days"
            type="number"
            onWheel={(event) => event.currentTarget.blur()}
            value={maxDays}
            onChange={(e) => {
              setMaxDays(e.target.value);
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Space size="middle">
            Open For bookings
            <Checkbox
              checked={openForBooking}
              onChange={(e) => {
                setOpenForBooking(e.target.checked);
              }}
            />
          </Space>
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(Blocks);
