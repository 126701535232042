import Axios from "../utils/axios";

const PrasadamService = {
  all: async () => {
    const resp = await Axios.get(`prasadam/all`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      }
    });
    return resp.data;
  },
  allByType: async (type) => {
    const resp = await Axios.get(`prasadam-counter/counter-prasadam/list`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  allIdAndNames: async () => {
    const resp = await Axios.get(`darshanam/all/id-and-names`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  PrasadamById: async (id) => {
    const resp = await Axios.get(`/prasadam/byId/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  delete: async (id) => {
    const resp = await Axios.delete(`prasadam/disableById/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  newPrasadam: async (prasadamName, type, price) => {
    const body = {
      name: prasadamName,
      type: type,
      price,
    };
    Object.keys(body).forEach((key) => {
      if (body[key] === undefined || !body[key]) {
        delete body[key];
      }
    });
    const resp = await Axios.post(`prasadam`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  editPrasadam: async (updatePrasadamId, prasadamName, type, price) => {
    const body = {
      prasadam_id: updatePrasadamId,
      name: prasadamName,
      type,
      price,
    };
    console.log(body);
    Object.keys(body).forEach((key) => {
      if (body[key] === undefined || !body[key]) {
        delete body[key];
      }
    });
    const resp = await Axios.put(`/prasadam/edit`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  bookPrasadam: async (body) => {
    let resp = await Axios.post(`/cart-counter/prasadam`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  getAllPrasadamCounterList: async (id) => {
    let resp = await Axios.get(`prasadam-counter/counter/byPrasadamId/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  addCounterbyprasadamId: async (body) => {
    const resp = await Axios.post(`/prasadam-counter/new`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });

    return resp.data;
  },

  deleteCounterbyprasadamId: async (id) => {
    let resp = await Axios.delete(
      `prasadam-counter/ByPrasadamCounterId/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  getPrasadamReportCollection: async (fromDate, toDate, txType) => {
    const resp = await Axios.get(
      `/prasadam-report/collection?fromDate=${fromDate}&aggregateBy=publicationName&toDate=${toDate}&paymentStatus=success&txType=${txType}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
};
export default PrasadamService;
