import React, {useContext} from "react";
import {withRouter} from "react-router";
import {Card, Row, Col} from "antd";
import {routeMapMini} from "../../constants/routeMap";
import "./Report.css";
import {UserContext} from "../../context/user.context";
const Reports = ({history}) => {
  const {userDetails} = useContext(UserContext);

  const cardDetails = [
    {
      title: "Overview Report",
      route: routeMapMini.ReconciliationReport,
      roles: ["admin"],
    },
    {
      title: "All Transactions",
      route: routeMapMini.Alltransactions,
      roles: ["admin"],
    },
    {
      title: "Shift Report",
      route: routeMapMini.shiftReportTable,
      roles: [
        "admin",
        "accommodation_counter",
        "counter",
        "seva_and_darshanam_counter",
        "darshanam_counter",
        "seva_counter",
        "performance_scan",
        "prasadam_counter",
        "kailasakankanam_counter",
        "seva_read_and_performance_scan",
        "toll_gate_counter",
        "publication_counter",
        "thulabharam_counter",
        "kalyanakatta_counter",
      ], //add role
    },
    {
      title: "Accommodation Dashboard",
      route: routeMapMini.AccommodationDashboard,
      roles: ["admin", "accommodation_read"],
    },
    // {
    //   title: "Accommodation Counter Report",
    //   route: routeMapMini.AccommodationReports,
    //   roles: ["admin", "seva_read"],
    // },
    {
      title: "Accommodation Ocupancy Report",
      route: routeMapMini.AccommodationOcupancyReports,
      roles: ["admin", "seva_read"],
    },
    {
      title: "Donation Counter Report",
      route: routeMapMini.DonationReports,
      roles: ["admin", "seva_read"],
    },

    {
      title: "Publication Counter Report",
      route: routeMapMini.PublicationReports,
      roles: ["admin"],
    },
    {
      title: "Thulabharam Counter Report",
      route: routeMapMini.ThulabharamReports,
      roles: ["admin", "seva_read"],
    },
    {
      title: "Seva Counter Report",
      route: routeMapMini.SevaCounterReport,
      roles: ["admin", "seva_read"],
    },
    {
      title: "Darshanam Counter Report",
      route: routeMapMini.DarshanamCounterReport,
      roles: ["admin"],
    },
    {
      title: "Prasadam Counter Report",
      route: routeMapMini.PrasadamCounterReport,
      roles: ["admin"],
    },

    {
      title: "Kalayana Katta Counter Report",
      route: routeMapMini.KalayanakattaCounterReport,
      roles: ["admin"],
    },
    {
      title: "Kailasa Counter Report",
      route: routeMapMini.KailasaKankanamCounterReport,
      roles: ["admin"],
    },
    {
      title: "Toll Gate Report",
      route: routeMapMini.TollGateReports,
      roles: ["admin"],
    },
    {
      title: "Consolidated Report",
      route: routeMapMini.ConsolidatedReport,
      roles: ["admin"],
    },
    {
      title: "Shift Transactions",
      route: routeMapMini.ShiftTransactions,
      roles: [
        "admin",
        "accommodation_counter",
        "seva_and_darshanam_counter",
        "darshanam_counter",
        "seva_counter",
        "prasadam_counter",
        "toll_gate_counter",
        "thulabharam_counter",
        "publication_counter",
        "kalyanakatta_counter",
      ], //add role
    },
    {
      title: "Scan Ticket Log Report",
      route: routeMapMini.ScanTicketLogReport,
      roles: ["admin", "counter"],
    },
    {
      title: "Scan Logs",
      route: routeMapMini.ShiftScanLogs,
      roles: [
        "admin",
        "counter",
        "performance_scan",
        "seva_read_and_performance_scan",
      ],
    },
    {
      title: "GST Report",
      route: routeMapMini.gstReport,
      roles: ["admin"],
    },
  ];
  return (
    <Row gutter={[20, 20]}>
      {cardDetails
        .filter((card) => card.roles.includes(userDetails?.role))
        .map((card) => (
          <Col
            style={{
              float: "left",
              width: "25%",
            }}
          >
            <Card
              hoverable
              className="antd-custom"
              onClick={() => history?.push(card.route)}
            >
              <Row style={{fontSize: "20px"}}>{card.title}</Row>
            </Card>
          </Col>
        ))}
    </Row>
  );
};

export default withRouter(Reports);
