import Axios from '../utils/axios';

const PaymentService = {
  razorpayOrderPayments: async (orderId) => {
    const resp = await Axios.get(
      `payment/razorpay/payments/by_order/${orderId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    return resp.data;
  },
};

export default PaymentService;
