import Axios from "../utils/axios";

const accommodationReportService = {
  getShiftBlocks: async () => {
    const resp = await Axios.get(
      "accommodation-report/block-dashboard/block-names",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    return resp.data;
  },
  getBlockAccommodationReport: async (blockId) => {
    const resp = await Axios.get(
      `accommodation-report/block-dashboard-report/${blockId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    return resp.data;
  },
  getCheckoutTableData: async (blockId) => {
    const resp = await Axios.get(
      `accommodation-report/check-out-ready-rooms/${blockId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    return resp.data;
  },
  getCheckoutTableDataAll: async () => {
    const resp = await Axios.get(
      `accommodation-report/check-out-ready-rooms-all`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    return resp.data;
  },
  getBlockAccommodationReportAll: async () => {
    const resp = await Axios.get(
      `accommodation-report/block-dashboard-report-all`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    return resp.data;
  },
  getLiveAccommodationData: async (blockID, roomType, status) => {
    const resp = await Axios.get(
      `accommodation-report/block-dashboard-report-details/${blockID}?roomType=${roomType}&roomStatus=${status}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    return resp.data;
  },
};


export default accommodationReportService;
