import Axios from "../utils/axios";

const CounterDarshanamService = {
  getDarshanamSlots: async (bookingDate, persons) => {
    const resp = await Axios.get(
      `darshanam-counter/counter-query?bookingDate=${bookingDate}&tickets=${persons}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  darshanamBooking: async (persons, darshanamSlotId, bookingDate) => {
    const resp = await Axios.post(
      `/cart-counter/darshanam`,
      {
        no_of_persons: persons,
        darshanamSlotId: darshanamSlotId,
        bookingDate: bookingDate,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
};

export default CounterDarshanamService;
