import React, {useContext} from "react";
import {withRouter} from "react-router";
import {Card, Row, Col} from "antd";
// import {} from "../../constants/routeMap";
import "./AccommodationTicket/OfflineAccommodation.css";
import {UserContext} from "../../context/user.context";
import {routeMapMini} from "../../constants/routeMap";
const OfflineTicket = ({history}) => {
  const {userDetails} = useContext(UserContext);

  const cardDetails = [
    {
      title: "Check In Scan",
      route: routeMapMini.CheckInScan,
      roles: ["admin", "counter", "accommodation_counter", "check_in_scan"],
    },
    {
      title: "Performance Scan",
      route: routeMapMini.PerformanceScan,
      roles: [
        "admin",
        "counter",
        "performance_scan",
        "seva_read_and_performance_scan",
      ],
    },
    {
      title: "Accommodation",
      route: routeMapMini.OfflineAccommodation,
      roles: ["admin", "accommodation_counter"],
    },

    {
      title: "Pratyaksha Seva",
      route: routeMapMini.Counter_SAD_pratyakshaSeva_One,
      roles: ["admin", "seva_counter", "seva_and_darshanam_counter"],
      active: true,
    },
    {
      title: "Darshanam",
      route: routeMapMini.OfflineDarshanam,
      roles: ["admin", "darshanam_counter", "seva_and_darshanam_counter"],
      active: true,
    },
    {
      title: "Donation",
      route: routeMapMini.Counterdonation,
      roles: ["admin", "donation_counter"],
    },
    {
      title: "Publications",
      route: routeMapMini.Counter_Publications,
      roles: ["admin", "publication_counter"],
    },
    {
      title: "Toll Gate",
      route: routeMapMini.CounterTollGate,
      roles: ["admin", "toll_gate_counter"],
    },
    {
      title: "Prasadam",
      route: routeMapMini.CounterPrasadam,
      roles: ["admin", "prasadam_counter"],
    },
    {
      title: "Kailasa Kankanam",
      route: routeMapMini.CounterKailasaKankanam,
      roles: ["admin", "kailasakankanam_counter"],
    },
    {
      title: "Kalyanakatta",
      route: routeMapMini.CounterKalyanakatta,
      roles: ["admin", "kalyanakatta_counter"],
    },
    {
      title: "Thulabharam",
      route: routeMapMini.CounterThulabharam,
      roles: ["admin", "thulabharam_counter"],
    },
    {
      title: "Prasadam Scanner",
      route: routeMapMini.PrasadamScanner,
      roles: ["admin", "prasadam_counter"],
    },

    {
      title: "Counter Management",
      route: routeMapMini.CounterManagement,
      roles: ["admin"],
    },
    {
      title: "Coupon Management",
      route: routeMapMini.CouponManagement,
      roles: ["admin"],
    },
    {
      title: "Ticket Details",
      route: routeMapMini.ticketDetails,
      roles: ["admin", "accommodation_counter", "counter"],
    },
    {
      title: "Shift Management",
      route: routeMapMini.shiftManagement,
      roles: ["admin"],
    },
  ];

  return (
    <Row gutter={[20, 20]}>
      {cardDetails
        .filter((card) => card.roles.includes(userDetails?.role))
        .map((card) => (
          <Col
            style={{
              float: "left",
              width:
                window.innerWidth < 650
                  ? "100%"
                  : window.innerWidth < 1000 && window.innerWidth > 650
                  ? "50%"
                  : "25%",
            }}
          >
            <Card
              hoverable
              className="antd-custom"
              onClick={() => history?.push(card.route)}
            >
              <Row style={{fontSize: "20px"}}>{card.title}</Row>
            </Card>
          </Col>
        ))}
    </Row>
  );
};

export default withRouter(OfflineTicket);
