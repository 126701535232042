/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Button, Modal, notification, Card, Row, Col, Divider } from "antd";
import RoomsService from "../../../Services/rooms.service";
import moment from "moment";
import { routeMapMini } from "../../../constants/routeMap";
import { AccContext } from "../../../context/accommodation.context";
import PrintTicket from "../../Ticket/PrintTicket";
import CartCounterService from "../../../Services/CartCounter.service";
import { withRouter } from "react-router-dom";

const RoomList = ({ history }) => {
  const [tableLoading, setTableLoading] = useState(false);
  const [blockData, setBlockData] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userData, setUserData] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);

  const {
    firstName,
    lastName,
    address,
    address2,
    couponDisc,
    gender,
    persons,
    phone,
    zip,
    state,
    email,
    city,
    gothram,
    country,
    docType,
    docNumber,
    deposite,
    blockCategoryId,
    age,
    price,
    extraAmount,
    setExtraAmount,
    donorType,
    coupon,
    blockName,
  } = useContext(AccContext);

  const [bookLoading, setBookLoading] = useState(false);

  useEffect(() => {
    if (!firstName) {
      history?.push(routeMapMini.OfflineAccommodation);
    }
  }, [firstName]);

  const fetchBlockRooms = async () => {
    setTableLoading(true);
    try {
      const respData = await RoomsService.counterBlockAvailableRooms(
        blockCategoryId,
        moment().format(`YYYY-MM-DD`)
      );
      setBlockData(
        respData.map((item) => {
          return { ...item, key: item.id };
        })
      );
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
    setTableLoading(false);
  };

  const CounterAccomBook = async (roomId) => {
    setButtonLoading(true);
    setBookLoading(true);
    try {
      const userData = await CartCounterService.accomodation(
        country,
        docNumber,
        firstName,
        lastName,
        gender,
        gothram,
        phone,
        email,
        address,
        address2,
        city,
        state,
        zip,
        docType,
        persons,
        deposite,
        roomId,
        age,
        donorType,
        coupon,
        couponDisc
      );
      setIsModalVisible(true);
      setUserData(userData);
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
    setBookLoading(false);
    setButtonLoading(false);
  };

  useEffect(() => {
    if (blockCategoryId) {
      fetchBlockRooms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockCategoryId]);

  return (
    <>
      <Row>
        <h1
          style={{
            textAlign: "left",
            fontWeight: "bold",
            marginBottom: "0px",
            marginLeft: "10px",
            fontSize: "24px",
          }}
        >
          {blockName}
        </h1>
        <Divider />
      </Row>
      <Row gutter={[20, 20]}>
        {blockData?.map((item, index) => {
          return (
            <Col
              style={{
                float: "left",
                width: "20%",
              }}
            >
              <Card style={{ borderRadius: "20px" }}>
                <Col style={{ fontSize: "20px", width: "50%", float: "left" }}>
                  {item?.room_number}
                </Col>
                <Col style={{ width: "50%", float: "right" }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      CounterAccomBook(item?.id);
                    }}
                    loading={buttonLoading}
                  >
                    Book Room
                  </Button>
                </Col>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Modal
        visible={isModalVisible}
        width={850}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
        // onCancel={() => {
        //   setIsModalVisible(false);
        // }}
      >
        <PrintTicket
          ticketId={userData?.ticket}
          name={firstName}
          persons={persons}
          blockName={userData?.roomBooking?.blockName}
          roomNo={userData?.roomBooking?.roomNo}
          roomType={userData?.roomBooking?.roomType}
          bookingDate={userData?.roomBooking?.bookingDate}
          checkInTime={userData?.roomBooking?.checkInTime}
          checkOutTime={moment(userData?.roomBooking?.checkInTime).add(1, "d")}
          roomPrice={price}
          depositAmount={deposite}
          extraAmount={extraAmount > 0 ? extraAmount : 0}
          type="accomodation"
          couponDisc={couponDisc > 0 ? couponDisc : 0}
          coupon={coupon}
          donorType={donorType}
          contact={`+91${phone}`}
          booking="Counter"
          from="Counter"
          setPrintTicketModal={setIsModalVisible}
          setExtraAmount={setExtraAmount}
        />
      </Modal>
    </>
  );
};

export default withRouter(RoomList);
