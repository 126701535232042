/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import ReportService from "../../Services/Report.service";
import { routeMapMini } from "../../constants/routeMap";
import {
  notification,
  Row,
  Col,
  Table,
  Space,
  Button,
  InputNumber,
} from "antd";
import { FormatNumberINRCurrency } from "../../utils/currencyFormat";
import ShiftDetailsCard from "./ShiftDetailsCard";
import moment from "moment";
import fileDownload from "js-file-download";
const ShiftTransactionsMain = ({ history, location }) => {
  const [dataLoading, setDataLoading] = useState(false);

  const [reportData, setReportData] = useState("");
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [sortType, setSortType] = useState("DESC");
  const [ShiftID, setShiftID] = useState("");
  const [shiftData, setShiftData] = useState("");
  const fetchAllTransactions = async (id) => {
    if (ShiftID === "") {
      if (!id) {
        notification.error({ message: "Please enter a shift ID" });
      }
    }
    setDataLoading(true);
    try {
      setReportData("");
      setShiftData("");
      const data = await ReportService.allShiftTransactions(
        id ? Number(id) : Number(ShiftID),
        tableCurrentPage,
        tablePageSize
      );
      setReportData(data.transactions);
      setShiftData(data.shiftLog);
      setTabelTotalSize(data?.transactions?.length);
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
    setDataLoading(false);
  };

  useEffect(() => {
    const id = new URLSearchParams(location.search).get("shiftID");
    setShiftID(id ? Number(id) : "");
    if (!id) {
      return;
    } else {
      fetchAllTransactions(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const columns = [
    { title: "Id", dataIndex: "id", key: "id" },
    {
      title: "Service Name",
      dataIndex: "serviceName",
      key: "serviceName",
    },
    {
      title: "TI No.",
      dataIndex: "category_transaction_id",
      key: "category_transaction_id",
      render: (item) => {
        return item?.split("-")[3];
      },
    },
    { title: "Service", dataIndex: "tx_method", key: "tx_method" },
    {
      title: "Transaction type",
      dataIndex: "cash_type",
      key: "cash_type",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      render: (item) => {
        return moment(new Date(item)).format("DD-MM-YYYY - hh:mm A");
      },
    },
    {
      title: "Deposit Amount",
      dataIndex: "deposit_amount",
      render: (deposit_amount) => FormatNumberINRCurrency(deposit_amount),
    },
    {
      title: "GST Amount",
      dataIndex: "gst_amount",
      render: (gst_amount) => FormatNumberINRCurrency(gst_amount),
    },
    {
      title: "Service Amount",
      dataIndex: "service_amount",
      render: (service_amount) => FormatNumberINRCurrency(service_amount),
    },
  ];
  const downloadReport = async () => {
    notification.info({ message: "Downloading file! Please wait." });
    ReportService.shiftTransactionsDownload(ShiftID)
      .then((data) => {
        fileDownload(
          data,
          `Shift-Transactions-${ShiftID}-${moment().format("DD-MM-YYYY")}.pdf`
        );
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          history?.push(routeMapMini.Login);
        } else if (error?.response?.data?.message) {
          notification.error({ message: error?.response?.data?.message });
        } else {
          console.error(error);
          notification.error({ message: "something is wrong" });
        }
      });
  };

  return (
    <>
      <Row style={{ marginBottom: "20px", display: "flex" }} gutter={[20, 20]}>
        <Col>
          <Row justify="start"> Shift ID</Row>
          <Row justify="start" style={{ flexDirection: "row" }}>
            <InputNumber
              style={{ width: "300px", maxWidth: "300px" }}
              value={ShiftID}
              defaultValue={ShiftID}
              placeholder="Please Enter a Shift ID"
              onChange={(e) => {
                setShiftID(e);
              }}
            />
            <Button
              type="primary"
              style={{ maxWidth: "100px", marginLeft: "20px" }}
              onClick={() => fetchAllTransactions()}
            >
              Fetch
            </Button>
            {reportData === "" || reportData.length === 0 || !reportData ? (
              ""
            ) : (
              <Button
                type="primary"
                style={{ maxWidth: "100px", marginLeft: "20px" }}
                onClick={() => downloadReport()}
              >
                Download
              </Button>
            )}
          </Row>
        </Col>
        {/* <Col>
          <Row justify="start">Sort Type</Row>
          <Row justify="start">
            <Select
              style={{width: "150px"}}
              onChange={(e) => {
                setSortType(e);
              }}
              value={sortType}
            >
              <Select.Option value="DESC">Descending</Select.Option>
              <Select.Option value="ASC">Ascending</Select.Option>
            </Select>
          </Row>
        </Col>
       */}
      </Row>
      {shiftData !== "" && reportData !== "" && (
        <ShiftDetailsCard shiftData={shiftData} />
      )}
      <br />
      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Shift Transactions</span>
        </Space>
      </Row>
      <Table
        style={{ width: "100%", textTransform: "capitalize" }}
        columns={columns}
        dataSource={reportData}
        loading={dataLoading}
        size="small"
        scroll={{ x: true }}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        onChange={handleTableChange}
      ></Table>
    </>
  );
};

export default withRouter(ShiftTransactionsMain);
