import Axios from '../utils/axios';

const NewsLetterService = {
  allSubscription: async (page, limit) => {
    const resp = await Axios.get(
      `sub-newsletter/all?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    return resp.data;
  },
};

export default NewsLetterService;
