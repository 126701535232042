/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Input, notification, Table } from "antd";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import DonationService from "../../../Services/Donation.service";
import { FormatNumberINRCurrency } from "../../../utils/currencyFormat";

const DonorRegistrationTable = ({ updateTable, blockNamesAndIds }) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  // const [tableTotalSize, setTableTotalSize] = useState("");

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  useEffect(() => {
    if (!loading) {
      loadDonorData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTable, tableCurrentPage]);

  const loadDonorData = async () => {
    try {
      setLoading(true);
      const donorData = await DonationService.getAllDonorList(
        tableCurrentPage,
        tablePageSize
      );
      setData(
        donorData?.items.map((item) => {
          return {
            key: item.id,
            name: item.donar_name,
            added_by_user_id: item.added_by_user_id,
            created_at: item.created_at,
            coupon_amount: item.coupon_value,
            room_id: item.sd_rooms_id,
            user_id: item.sd_user_id,
            no_of_coupons: item.no_of_coupons,
            sd_block_details_id: item.sd_block_details_id,
          };
        })
      );
      // setTableTotalSize(donorData.meta.totalItems);
      setLoading(false);
    } catch (error) {
      if (error.response?.data?.message) {
        notification.error({ message: error.response?.data?.message });
      } else {
        notification.error({ message: error.message });
      }
      setLoading(false);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Donor Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      ...getColumnSearchProps("name"),
      render: (text) => {
        return (
          text && (
            <div style={{ width: "200px", whiteSpace: "initial" }}>{text}</div>
          )
        );
      },
    },
    {
      title: "Block ID",
      dataIndex: "sd_block_details_id",
      key: "sd_block_details_id",
      render: (text) => {
        return blockNamesAndIds.find((e) => e.id === text)?.block_name;
      },
    },
    {
      title: "Room ID",
      dataIndex: "room_id",
      key: "room_id",
    },
    {
      title: "No of Coupons",
      dataIndex: "no_of_coupons",
      key: "no_of_coupons",
    },
    {
      title: "Coupon Value",
      dataIndex: "coupon_amount",
      key: "coupon_amount",
      render: FormatNumberINRCurrency,
    },
    {
      title: "Added by User",
      dataIndex: "added_by_user_id",
      key: "added_by_user_id",
    },
    {
      title: "Created Date",
      key: "created_at",
      dataIndex: "created_at",
      render: (text, record) => {
        return moment(text).format("DD-MM-YY ( HH:mm:ss )");
      },
    },
  ];

  return (
    <Table
      tableLayout="fixed"
      //   scroll={{x: true}}
      loading={loading}
      style={{ width: "100%", textTransform: "capitalize" }}
      onChange={handleTableChange}
      columns={columns}
      dataSource={data}
      pagination={{
        current: tableCurrentPage,
        pageSize: tablePageSize,
        // total: tableTotalSize,
      }}
    ></Table>
  );
};

export default withRouter(DonorRegistrationTable);
