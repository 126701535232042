import {Button, Col, Input, notification, Row, Select} from "antd";
import Modal from "antd/lib/modal/Modal";
import {useContext, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {routeMapMini} from "../../constants/routeMap";
import {UserContext} from "../../context/user.context";
import PrasadamService from "../../Services/Prasadam.service";
import PrasadamTable from "./PrasadamTable";
const {Option} = Select;
const Prasadam = ({history}) => {
  const [showNewModal, setShowNewModal] = useState(false);
  const [loading, setLoading] = useState();
  const [prasadamName, setPrasadamName] = useState("");
  const [price, setPrice] = useState();
  const [edit, setEdit] = useState(false);
  const [updateTable, setUpdateTable] = useState();
  const [updatePrasadamId, setPrasadamId] = useState();
  const {userDetails} = useContext(UserContext);
  const [type, setType] = useState("prasadam");
  useEffect(() => {
    (async () => {
      if (updatePrasadamId) {
        setShowNewModal(true);
        try {
          const resp = await PrasadamService.PrasadamById(updatePrasadamId);
          setPrasadamName(resp?.name);
          setPrice(resp?.price);
          setType(resp.type);
          setEdit(true);
          setShowNewModal(true);
        } catch (error) {
          if (error?.response?.status === 401) {
            notification.error({
              message: "Token Expired, redirecting to login page in 5 seconds",
            });
            history?.push(routeMapMini.Login);
          } else if (error.response?.data?.message) {
            notification.error({message: error.response?.data?.message});
          } else {
            notification.error({message: error.message});
          }
        }
      }
    })();
  }, [history, updatePrasadamId]);

  const handleOk = async () => {
    if (!prasadamName) {
      return notification.error({message: `name is required`});
    } else if (!price || price <= 0) {
      return notification.error({
        message: `price is required and should be positive`,
      });
    }
    setLoading(true);
    try {
      if (updatePrasadamId) {
        await PrasadamService.editPrasadam(
          updatePrasadamId,
          prasadamName,
          type,
          price
        );
        notification.success({
          message: `Prasadam ${updatePrasadamId} updated`,
        });
        clearFields();
        setUpdateTable(updatePrasadamId + Math.random());
        setEdit(false);
      } else {
        const prasadam = await PrasadamService.newPrasadam(
          prasadamName,
          type,
          price
        );
        notification.success({message: `Prasadam ${prasadam.id} created`});
        setUpdateTable(prasadam.id + Math.random());
        clearFields();
        setEdit(false);
      }
      setShowNewModal(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setPrasadamName(undefined);
    setPrice(undefined);
    setPrasadamId(undefined);
    setType("");
    setEdit(false);
  };

  const handleCancel = () => {
    clearFields();
    setShowNewModal(false);
    setEdit(false);
  };

  return (
    <div style={{width: "100%"}}>
      <Row style={{width: "100%", marginBottom: "15px"}} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setShowNewModal(true);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Add New Prasadam
          </Button>
        </Col>
        {/* <Col>
          <Button
            type="primary"
            onClick={() => {
              history?.push(routeMapMini.DarshanamBookingDetails);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Darshanam Booking Details
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            // onClick={() => {
            //   history?.push(routeMapMini.DonationReports);
            // }}
            disabled={userDetails.role !== "admin"}
          >
            Darshanam Reports
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              history?.push(routeMapMini.DarshanamDisabledDates);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Disable Darshanam on Date
          </Button>
        </Col>
      */}
      </Row>
      <Row style={{width: "100%"}}>
        <PrasadamTable
          updateTable={updateTable}
          setPrasadamId={setPrasadamId}
        />
      </Row>
      <Modal
        visible={showNewModal}
        title={`${
          edit ? `Edit Prasadam id: ${updatePrasadamId}` : `Add New Prasadam`
        }`}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{alignContent: "center"}}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify="start" style={{width: "100%", padding: "10px 0px"}}>
          <label>Prasadam Name</label>
          <Input
            placeholder="Prasadam Name"
            value={prasadamName}
            onChange={(e) => {
              setPrasadamName(e.target.value);
            }}
            style={{width: "450px"}}
          />
        </Row>

        <Row justify="start" style={{width: "100%", padding: "10px 0px"}}>
          <label>Prasadam type</label>

          <Select
            placeholder="Select a Type"
            style={{width: "450px"}}
            value={type}
            onChange={(e) => {
              setType(e);
            }}
          >
            <Option value="prasadam" key="prasadam">
              Prasadam
            </Option>
            <Option value="cover" key="cover">
              Cover
            </Option>
          </Select>
        </Row>

        <Row
          justify="start"
          style={{width: "100%", padding: "10px 0px", flexDirection: "column"}}
        >
          <label>Price </label>
          <Input
            placeholder="Price"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
            type="number"
            style={{width: "450px"}}
          />
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(Prasadam);
