/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Col,
  DatePicker,
  Input,
  notification,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import fileDownload from "js-file-download";
import {routeMapMini} from "../../../constants/routeMap";
import DonationService from "../../../Services/Donation.service";
import {FormatNumberINRCurrency} from "../../../utils/currencyFormat";
import moment from "moment";
import ReportService from "../../../Services/Report.service";

const DonationCounterReport = ({history}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [DonationNamesAndIds, setDonationNamesAndIds] = useState([]);
  const [donationNamesLoading, setDonationNamesLoading] = useState(false);

  const [selectedDonationId, setSelectedDonationId] = useState(0);
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getDonationNamesAndIds = async () => {
    setDonationNamesLoading(true);
    try {
      const donationNamesAndIdData = await DonationService.getAll();
      setDonationNamesAndIds(donationNamesAndIdData);
    } catch (error) {
      notification.error({message: error.message});
    }
    setDonationNamesLoading(false);
  };

  const fetchBookings = async () => {
    setLoading(true);
    try {
      const respData = await ReportService.getDonationCounterReport(
        selectedDonationId,
        fromDate,
        toDate,
        tableCurrentPage,
        tablePageSize,
        paymentStatus
      );
      setData(
        respData?.items.map((item) => {
          return {
            key: item.ticket_id,
            ...item,
          };
        })
      );
      setTabelTotalSize(respData?.meta?.totalItems);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setLoading(false);
  };

  const downloadExcel = async () => {
    if (!selectedDonationId) {
      return notification.error({
        message: "Donation is not selected",
      });
    }

    notification.info({message: "Downloading file! Please wait."});
    ReportService.getDonationExcelCounterReport(
      selectedDonationId,
      fromDate,
      toDate,
      paymentStatus
    )
      .then((data) => {
        fileDownload(
          data,
          `Donations-${moment().format("DD-MM-YYYY HH:MM")}.xlsx`
        );
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          notification.error({
            message: "Token Expired, redirecting to login page in 5 seconds",
          });
          history?.push(routeMapMini.Login);
        } else if (error?.response?.data?.message) {
          notification.error({message: error?.response?.data?.message});
        } else {
          console.error(error);
          notification.error({message: "something is wrong"});
        }
      });
  };

  useEffect(() => {
    getDonationNamesAndIds();
  }, []);

  useEffect(() => {
    fetchBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDonationId, tableCurrentPage, tablePageSize, fromDate, toDate]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{padding: 8}}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: "block"}}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{width: 90, marginRight: 8}}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{width: 90}}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Ticket ID",
      dataIndex: "ticket_id",
      key: "ticket_id",
    },
    {
      title: "Donation Booking ID",
      dataIndex: "donationBookingId",
      key: "donationBookingId",
    },
    {
      title: "Donation Name",
      dataIndex: "donationName",
      key: "donationName",
      ...getColumnSearchProps("donationName"),
    },
    {
      title: "In the name of",
      dataIndex: "inTheNameOf",
      key: "inTheNameOf",
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: FormatNumberINRCurrency,
    },
    {
      title: "Order Date",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "User Name",
      dataIndex: "user_display_name",
      key: "user_display_name",
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  return (
    <>
      <Row style={{marginBottom: "20px"}} gutter={[20, 20]}>
        <Col>
          <Row justify="start">Donation Name</Row>
          <Row justify="start">
            <Select
              className="sd-select-seva"
              dropdownClassName="sd-select-dropdown-seva"
              loading={donationNamesLoading}
              onChange={setSelectedDonationId}
              defaultValue=""
            >
              {DonationNamesAndIds.map((item) => {
                return (
                  <Select.Option value={item.id}> {item.name} </Select.Option>
                );
              })}
              <Select.Option value="">All</Select.Option>
            </Select>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Order Date</Row>
          <Row justify="start">
            <DatePicker.RangePicker
              format="DD-MM-YYYY"
              onChange={(e) => {
                if (e?.[0] && e?.[1]) {
                  setFromDate(moment(e[0]).format(`YYYY-MM-DD`));
                  setToDate(moment(e[1]).format(`YYYY-MM-DD`));
                } else {
                  setFromDate(undefined);
                  setToDate(undefined);
                }
              }}
              ranges={{
                Today: [moment(), moment()],
                "Last 7 Days": [moment().subtract(7, "d"), moment()],
                "Last 15 Days": [moment().subtract(15, "d"), moment()],
              }}
              defaultValue={[moment(fromDate), moment(toDate)]}
              defaultPickerValue={[moment(fromDate), moment(toDate)]}
            />
          </Row>
        </Col>
        <Col>
          <Row justify="start">Update</Row>
          <Row justify="start">
            <Button type="primary" onClick={fetchBookings}>
              Fetch
            </Button>
          </Row>
        </Col>
        <Col>
          <Row justify="start"> Download </Row>
          <Row justify="start">
            <Button type="primary" onClick={downloadExcel}>
              Excel
            </Button>
          </Row>
        </Col>
      </Row>

      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Total Bookings</span>
        </Space>
      </Row>

      <Table
        scroll={{x: true}}
        style={{width: "100%", textTransform: "capitalize"}}
        columns={columns}
        dataSource={data}
        size="small"
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        loading={loading}
        onChange={handleTableChange}
      ></Table>
    </>
  );
};

export default withRouter(DonationCounterReport);
