/* eslint-disable jsx-a11y/anchor-is-valid */
import {notification, Popconfirm, Space, Table} from "antd";
import moment from "moment";
import {withRouter} from "react-router-dom";
import {routeMapMini} from "../../../constants/routeMap";
import SevaService from "../../../Services/Seva.service";

const SevaSlotTable = ({
  list,
  loading,
  setLoading,
  getAllSlots,
  edit,
  setEdit,
  setTemp,
  sevaId,
  history,
}) => {
  const columns = [
    {title: "id", dataIndex: "id", key: "id"},
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
      render: (record) => {
        return moment(record, "HH:mm:ss").format("HH:mm A");
      },
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "end_time",
      render: (record) => {
        return moment(record, "HH:mm:ss").format("HH:mm A");
      },
    },

    {
      title: "Online Max Bookings per Day",
      dataIndex: "max_booking_per_day",
      key: "max_booking_per_day",
    },

    {
      title: "Open before hours",
      dataIndex: "open_before_hours",
      key: "open_before_hours",
    },

    {
      title: "Action",
      fixed: "right",
      responsive: ["md", "lg", "xl", "xsl"],
      render: (record) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                history?.push(
                  `${routeMapMini.SevaSlotCounterManagement}?sevaId=${sevaId}&slotId=${record.id}`
                );
              }}
            >
              Manage Counters
            </a>
            <a
              onClick={() => {
                setTemp(record);
                setEdit(true);
              }}
            >
              Edit
            </a>
            <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={async () => {
                setLoading(true);
                try {
                  await SevaService.deleteSevaSlotById(record.id);
                  notification.success({
                    message: `Slot has been deleted`,
                  });
                  getAllSlots();
                } catch (error) {
                  if (error?.response?.status === 401) {
                    notification.error({
                      message:
                        "Token Expired, redirecting to login page in 5 seconds",
                    });
                    history?.push(routeMapMini.Login);
                  }
                  if (error.response?.data?.message) {
                    notification.error({
                      message: error.response?.data?.message,
                    });
                  } else {
                    notification.error({message: error.message});
                  }
                }

                setLoading(false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm>{" "}
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      tableLayout="fixed"
      scroll={{x: true}}
      loading={loading}
      style={{width: "100%", textTransform: "capitalize"}}
      columns={columns}
      dataSource={list}
    ></Table>
  );
};

export default withRouter(SevaSlotTable);
