/* eslint-disable react/jsx-pascal-case */
import {Button, Col, Input, InputNumber, notification, Row, Select} from "antd";
import Modal from "antd/lib/modal/Modal";
import {useContext, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {routeMapMini} from "../../../../constants/routeMap";
import {UserContext} from "../../../../context/user.context";
// import RoomService from "../../../Services/rooms.service";
import SevaSlotCounterTable from "./SevaSlotCounterTable";
import SevaService from "../../../../Services/Seva.service";
import CounterService from "../../../../Services/Counter.service";

const {Option} = Select;
const SevaSlotCounterMain = ({history, location}) => {
  const [loading, setLoading] = useState();
  const [showModal, setShowModal] = useState(false);

  const [sevaID, setSevaID] = useState("");
  const {userDetails} = useContext(UserContext);
  const [list, setList] = useState([]);
  const [counterList, setCounterList] = useState([]);
  const [updateTable, setUpdateTable] = useState("none");
  const [selectedCounter, setSelectedCounter] = useState({});
  const [edit, setEdit] = useState(false);
  const [temp, setTemp] = useState("");
  const [slotId, setSlotId] = useState("");
  const [maxBookings, setMaxBookings] = useState("");

  useEffect(() => {
    getTableData();
  }, []);

  useEffect(() => {
    loadCounters();
  }, [updateTable]);
  useEffect(() => {
    if (edit === true) {
      setShowModal(true);
      let temps = counterList?.find((item) => item.id === temp.sd_counter_id);
      setSelectedCounter(temps);
      setMaxBookings(temp?.max_booking_per_day);
    }
  }, [edit]);

  const loadCounters = async () => {
    try {
      setLoading(true);
      const counters = await CounterService.getAllCounter();
      setCounterList(counters);
      setLoading(false);
    } catch (error) {
      if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
      setLoading(false);
    }
  };

  const getTableData = async () => {
    setLoading(true);
    try {
      setSevaID(new URLSearchParams(location.search).get("sevaId"));
      const slotId = new URLSearchParams(location.search).get("slotId");
      setSlotId(slotId);
      const resp = await SevaService.getAllSevaCounters(slotId);
      setList(resp);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
  };

  const handleOk = async () => {
    if (maxBookings === "") {
      notification.error({message: "Please select max bookings"});
      return;
    }
    if (selectedCounter === {}) {
      notification.error({message: `Select a Shift`});
      return;
    }
    setLoading(true);
    try {
      if (edit) {
        let body = {
          seva_slot_counter_id: temp.id,
          sevaSlotId: slotId,
          counterId: selectedCounter.id,
          max_booking_per_day: maxBookings,
        };
        await SevaService.editCounterbySevaId(body);
        notification.success({
          message: `Counter has been succesfully Edited`,
        });
        setEdit(false);
      } else {
        let body = {
          sevaSlotId: slotId,
          counterId: selectedCounter.id,
          max_booking_per_day: maxBookings,
        };
        await SevaService.addCounterbySevaId(body);
        notification.success({
          message: `Counter has been succesfully created`,
        });
      }

      getTableData();
      setShowModal(false);
      clearFields();
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setSelectedCounter({});
    setMaxBookings("");
    // setblockID(undefined);
  };

  const handleCancel = () => {
    clearFields();
    setShowModal(false);
    setEdit(false);
  };

  return (
    <div style={{width: "100%"}}>
      <Row style={{width: "100%", marginBottom: "15px"}} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setUpdateTable(Math.random(100));
              setShowModal(true);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Add New Counter
          </Button>
        </Col>
      </Row>
      <Row style={{width: "100%"}}>
        <SevaSlotCounterTable
          list={list}
          blockID={sevaID}
          loading={loading}
          setLoading={setLoading}
          setUpdateTable={setUpdateTable}
          getCountersByBlock={getTableData}
          setTemp={setTemp}
          setEdit={setEdit}
          slotId={slotId}
        />
      </Row>

      <Modal
        visible={showModal}
        title={
          edit
            ? `Edit New Counter to Seva Id: ${sevaID}`
            : `Add a New Counter to Seva Id: ${sevaID}`
        }
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify="center" style={{width: "100%", paddingTop: "10px"}}>
          Seva Id : {sevaID}
          <span style={{marginLeft: "5px"}}>Slot Id: {slotId}</span>
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Select
            width="100%"
            style={{width: "100%"}}
            value={selectedCounter?.name}
            placeholder="Please select a Counter"
            onChange={(e) => {
              let temp = counterList?.find((item) => item.name === e);
              setSelectedCounter(temp);
            }}
          >
            {counterList?.map((item, i) => {
              return (
                <Option key={item.name + "counter-list" + i} value={item.name}>
                  {item.name}{" "}
                </Option>
              );
            })}
          </Select>
        </Row>
        <Row width="100%">
          Max Bookings :{" "}
          <InputNumber
            defaultValue={maxBookings}
            value={maxBookings}
            min={0}
            style={{width: "100%", marginTop: "6px"}}
            placeholder="Enter Maximum Bookings allowed"
            onChange={(e) => {
              setMaxBookings(e);
            }}
          ></InputNumber>
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(SevaSlotCounterMain);
