import { Tabs } from 'antd';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
} from 'recharts';

const data = [
  {
    name: '10th Aug',
    presentYear: 4000,
    previousYear: 2400,
    amt: 2400,
  },
  {
    name: '11th Aug',
    presentYear: 3000,
    previousYear: 1398,
    amt: 2210,
  },
  {
    name: '12th Aug',
    presentYear: 2000,
    previousYear: 9800,
    amt: 2290,
  },
  {
    name: '13th Aug',
    presentYear: 2780,
    previousYear: 3908,
    amt: 2000,
  },
  {
    name: '14th Aug',
    presentYear: 1890,
    previousYear: 4800,
    amt: 2181,
  },
];

const ReportBarChart = () => {
  return (
    <BarChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis dataKey='name' />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey='presentYear' fill='#8884d8' />
      <Bar dataKey='previousYear' fill='#82ca9d' />
    </BarChart>
  );
};

const ReportLineChart = () => {
  return (
    <LineChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis dataKey='name' />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line
        type='monotone'
        dataKey='presentYear'
        stroke='#8884d8'
        activeDot={{ r: 8 }}
      />
      <Line type='monotone' dataKey='previousYear' stroke='#82ca9d' />
    </LineChart>
  );
};

const SevaReportGraphs = () => {
  return (
    <Tabs defaultActiveKey='1'>
      <Tabs.TabPane tab='Line Chart' key='1'>
        <ReportLineChart />
      </Tabs.TabPane>
      <Tabs.TabPane tab='Revenue by seva' key='2'>
        <ReportBarChart />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default SevaReportGraphs;
