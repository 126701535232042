import { LoadingOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { routeMapMini } from "../../../constants/routeMap";
import PublicationService from "../../../Services/Publication.service";
import moment from "moment";
import { Select, Divider } from "antd";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { FormatNumberINRCurrency } from "../../../utils/currencyFormat";
import PrintTicket from "../../Ticket/PrintTicket";
import { withRouter } from "react-router";
const { Option } = Select;
const Sd_Counter_Publications = ({ history }) => {
  const [selectedPublication, setSelectedPublication] = useState("");
  const [selectedPublicationFile, setSelectedPublicationFile] = useState("");

  const [sevaList, setSevaList] = useState([]);
  const [sevaFiles, setSevaFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [payment, setPayment] = useState("CASH");
  const [buttonLoading, setButtonLoading] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bookingData, setBookingData] = useState();
  const [languageList, setLanuguageList] = useState([]);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [cashReference, setCashReference] = useState("");
  const [district, setDistrict] = useState("");
  const [language, setLanguage] = useState("");
  const [email, setEmail] = useState("");
  const labelStyles = { float: "left", fontSize: "17px", marginBottom: "5px" };
  const [devoteeName, setDevoteeName] = useState("");
  useEffect(() => {
    getListData();
  }, []);

  const getListData = async () => {
    try {
      const res = await PublicationService.getOfflineCounterPublicationList();
      setSevaList(res);
      // if (res?.length > 0) {
      //   getFileData(res[0]?.id);
      // }
    } catch (error) {
      setLoading(false);

      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        console.log(error);

        notification.error({ message: error.response?.data?.message });
      } else {
        console.log(error);
        notification.error({ message: error.message });
      }
    }
  };

  const getFileData = async (id) => {
    try {
      const res = await PublicationService.getOfflineCounterPublicationFileList(
        id
      );
      setSevaFiles(res);
      setSelectedPublicationFile("");
      setLanguage("");
      setLanuguageList([]);
    } catch (error) {
      setLoading(false);

      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        console.log(error);

        notification.error({ message: error.response?.data?.message });
      } else {
        console.log(error);
        notification.error({ message: error.message });
      }
    }
  };
  const setLanguages = (item) => {
    setLanuguageList(item.language_options.split(","));
  };

  const handleSubmit = async () => {
    setLoading(true);
    setButtonLoading(true);
    if (
      phone === "" ||
      devoteeName === "" ||
      language === "" ||
      payment === "" ||
      selectedPublicationFile === "" ||
      selectedPublication === ""
    ) {
      notification.error({ message: "Please fill all mandatory fields" });
      setLoading(false);
      setButtonLoading(false);
      return;
    }
    if (selectedPublicationFile.type === "subscription") {
      if (zip === "" || address === "" || city === "" || state === "") {
        notification.error({
          message: "Please fill all mandatory fields related to address",
        });
        setLoading(false);
        setButtonLoading(false);
        return;
      }
      // if (email === "") {
      //   notification.error({
      //     message: "Please enter email",
      //   });
      //   setLoading(false);
      //   setButtonLoading(false);
      //   return;
      // }
    }

    if (phone.length !== 10) {
      notification.error({ message: "Phone number must be 10 digits" });
      setLoading(false);
      setButtonLoading(false);
      return;
    }

    if (email !== "") {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(email).toLowerCase())) {
        notification.error({ message: "Invalid Email." });
        setLoading(false);
        setButtonLoading(false);
        return;
      }
    }

    try {
      let body = {
        publicationFileId: selectedPublicationFile?.id,
        full_name: devoteeName,
        language: language,
        contact: `+91${phone}`,
        cashType: payment,
      };
      if (selectedPublicationFile.type === "subscription") {
        body.address = address;
        body.pin_code = zip;
        body.state = state;
        body.city = city;
        body.country = country;
      }
      if (email !== "") body.email = email;
      if (cashReference !== "") body.cashReference = cashReference;

      let res = await PublicationService.PulicationCounterBooking(body);
      notification.success({ message: "Success" });
      setBookingData(res);
      clearFields();
      setLoading(false);
      setIsModalVisible(true);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({ message: error.response?.data?.message });
      } else {
        notification.error({ message: error.message });
      }
      setLoading(false);
    }
    setButtonLoading(false);
  };
  const clearFields = () => {
    setDevoteeName("");
    setPhone("");
    setSelectedPublication("");
    setSelectedPublicationFile("");
    setLanuguageList([]);
    setLanguage("");
    setDistrict("");
    setAddress("");
    setCity("");
    setZip("");
    setEmail("");

    setCashReference("");
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div
          style={{
            maxWidth: "1200px",
            width: "100%",
            background: "white",
            padding: "30px 50px",
          }}
        >
          <Row
            justify="center"
            style={{ fontSize: "22px", marginBottom: "20px" }}
          >
            Publication
          </Row>
          <Divider />
          <Row justify="space-between">
            <Col style={{ width: "48%", justifyContent: "start" }}>
              <label style={labelStyles}>
                Select Publication
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
              </label>
              <br />
              <Select
                size="medium"
                style={{ width: "100%" }}
                showSearch
                placeholder="Select Publication Type"
                optionFilterProp="children"
                value={selectedPublication?.name}
                onChange={(e) => {
                  let temp = sevaList?.find((a) => a?.name === e);
                  setSelectedPublication(temp);
                  getFileData(temp?.id);
                }}
              >
                {sevaList?.map((item, i) => {
                  return (
                    <Option
                      key={item?.name + "seva-list" + i}
                      value={item?.name}
                    >
                      {item?.name}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col style={{ width: "48%", justifyContent: "start" }}>
              <label style={labelStyles}>
                Select Publication Type
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
              </label>
              <br />
              <Select
                size="medium"
                style={{ width: "100%" }}
                showSearch
                placeholder="Select Publication Type"
                optionFilterProp="children"
                value={selectedPublicationFile?.name}
                onChange={(e) => {
                  let temp = sevaFiles?.find((a) => a?.name === e);
                  setSelectedPublicationFile(temp);
                  setLanguages(temp);
                }}
              >
                {sevaFiles?.map((item) => {
                  return (
                    <Option key={item?.name + "seva-files"} value={item?.name}>
                      {item?.name} - ({item?.type})- Rs.
                      {item?.price}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            {selectedPublicationFile !== "" && (
              <Col
                style={{
                  width: "48%",
                  justifyContent: "start",
                  marginTop: "25px",
                }}
              >
                <label style={labelStyles}>
                  Select Language
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
                <br />
                <Select
                  size="medium"
                  style={{ width: "100%" }}
                  showSearch
                  placeholder="Select Language"
                  optionFilterProp="children"
                  value={language}
                  onChange={(e) => {
                    setLanguage(e);
                  }}
                >
                  {languageList?.map((item) => {
                    return (
                      <Option key={item + "language-list"} value={item}>
                        {item}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            )}{" "}
          </Row>
          <br />

          <Divider />

          <Row style={{ display: "flex", flexDirection: "column" }}>
            <label style={{ ...labelStyles, fontSize: "24px" }}>
              Devotee Details
            </label>
            <br />
            <Row justify="space-between">
              <Col style={{ width: "48%", justifyContent: "start" }}>
                <label style={labelStyles}>
                  Devotee Name
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
                <br />
                <Input
                  size="medium"
                  type="text"
                  placeholder="Enter a Name"
                  value={devoteeName}
                  onChange={(e) => {
                    setDevoteeName(e.target.value);
                  }}
                />
              </Col>
              <Col style={{ width: "48%" }}>
                <label style={labelStyles}>
                  Mobile Number
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
                <br />
                <Input
                  size="medium"
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  maxLength={10}
                  addonBefore={"+91"}
                  placeholder="Please Enter Mobile Number"
                  style={{ width: "100%" }}
                  value={phone}
                  onChange={(e) => {
                    if (e.target.value.length > 10) {
                      return;
                    }
                    setPhone(`${e.target.value}`);
                  }}
                />
              </Col>
            </Row>
            <br />
            {selectedPublicationFile.type === "subscription" && (
              <>
                {" "}
                <Row justify="space-between">
                  <Col style={{ width: "48%" }}>
                    <label style={labelStyles}>
                      Enter Address{" "}
                      <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                    </label>
                    <br />
                    <Input
                      type="text"
                      placeholder="Enter address"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                  </Col>

                  <Col style={{ width: "48%" }}>
                    <label style={labelStyles}>
                      Enter City{" "}
                      <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                    </label>
                    <br />
                    <Input
                      type="text"
                      placeholder="Enter address"
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <br />
                <Row justify="space-between">
                  <Col style={{ width: "48%" }}>
                    <label style={labelStyles}>
                      District{" "}
                      <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                    </label>
                    <br />
                    <Input
                      type="text"
                      placeholder="Enter District"
                      value={district}
                      onChange={(e) => {
                        setDistrict(e.target.value);
                      }}
                    />
                  </Col>
                  <Col style={{ width: "48%" }}>
                    <label style={labelStyles}>
                      Enter Pincode{" "}
                      <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                    </label>{" "}
                    <br />
                    <Input
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Enter Pincode"
                      value={zip}
                      onChange={(e) => {
                        if (e.target.value.length > 6) {
                          return;
                        }
                        setZip(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <br />
                <Row justify="space-between">
                  <Col style={{ width: "48%" }}>
                    <label style={labelStyles}>
                      {" "}
                      Select State{" "}
                      <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                    </label>
                    <RegionDropdown
                      className="CountryRegionDropDown"
                      style={{
                        border: "1px solid #d9d9d9",
                        marginBottom: "20px",
                        height: "32px",
                        fontSize: "14px",
                        padding: "0px 8px",
                      }}
                      country={country}
                      value={state}
                      showDefaultOption={true}
                      blankOptionLabel="Select a State"
                      onChange={(val) => {
                        setState(val);
                      }}
                    />
                  </Col>
                  <Col style={{ width: "48%" }}>
                    <label style={labelStyles}>
                      {" "}
                      Select Country{" "}
                      <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                    </label>
                    <CountryDropdown
                      className="CountryRegionDropDown"
                      style={{
                        border: "1px solid #d9d9d9",
                        marginBottom: "20px",
                        height: "32px",
                        fontSize: "14px",
                        padding: "0px 8px",
                      }}
                      value={country}
                      onChange={(val) => {
                        setCountry(val);
                      }}
                    />
                  </Col>
                </Row>
                <Divider />
                <br />{" "}
              </>
            )}
            <Row justify="space-between">
              <Col style={{ width: "48%", justifyContent: "start" }}>
                <label style={labelStyles}>
                  Select Payment Type
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
                <br />
                <Select
                  size="medium"
                  style={{ width: "100%" }}
                  placeholder="Select payment type"
                  optionFilterProp="children"
                  value={payment}
                  onChange={(e) => {
                    setPayment(e);
                  }}
                >
                  <Option key="CASH" value="CASH">
                    Cash
                  </Option>
                  <Option key="UPI" value="UPI">
                    UPI
                  </Option>
                  <Option key="CARD" value="CARD">
                    Card
                  </Option>
                  <Option key="CHEQUE" value="CHEQUE">
                    Cheque
                  </Option>
                  <Option key="DD" value="DD">
                    DD
                  </Option>
                </Select>
              </Col>
              <Col style={{ width: "48%" }}>
                <label style={labelStyles}>
                  Enter Reference (UPI Payment ID, Cheque ID, DD ID)
                </label>
                <br />
                <Input
                  size="medium"
                  type="text"
                  placeholder="Enter Reference ID"
                  style={{ width: "100%" }}
                  value={cashReference}
                  onChange={(e) => {
                    setCashReference(`${e.target.value}`);
                  }}
                />
              </Col>
            </Row>
            <Row justify="space-between">
              <Col style={{ width: "48%" }}>
                <label style={labelStyles}>Enter Email </label>
                <br />
                <Input
                  size="medium"
                  type="email"
                  placeholder="Enter Email"
                  style={{ width: "100%" }}
                  value={email}
                  onChange={(e) => {
                    setEmail(`${e.target.value}`);
                  }}
                />
              </Col>
            </Row>
            <br /> <br />
            <Row style={{ height: "50px" }}>
              <Col
                style={{
                  width: "25%",
                  fontWeight: "bold",
                  fontSize: "23px",
                  textAlign: "left",
                }}
              >
                Total Amount :
              </Col>
              <Col
                style={{
                  width: "75%",
                  textAlign: "right",
                  fontSize: "22px",
                  fontWeight: "600",
                }}
              >
                {FormatNumberINRCurrency(selectedPublicationFile?.price ?? 0)}
              </Col>
            </Row>
            <Row>
              <Col
                style={{
                  textAlign: "right",
                }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    handleSubmit();
                  }}
                  loading={buttonLoading}
                >
                  Submit <RightOutlined />
                </Button>
              </Col>
            </Row>
          </Row>
        </div>
      )}
      <Modal
        visible={isModalVisible}
        width={500}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <PrintTicket
          type="publication"
          from="Counter"
          bookingDate={moment(
            bookingData?.publicationBooking?.booking?.created_at
          )
            .utcOffset("+5:30")
            .format(`dddd, DD MMMM YYYY`)}
          name={bookingData?.publicationBooking?.booking?.full_name}
          amountPaid={bookingData?.publicationBooking?.booking?.price}
          actualAmount={bookingData?.publicationBooking?.booking?.actual_price}
          state={bookingData?.publicationBooking?.booking?.state}
          city={bookingData?.publicationBooking?.booking?.city}
          pincode={bookingData?.publicationBooking?.booking?.pin_code}
          address={bookingData?.publicationBooking?.booking?.address}
          ticketId={bookingData?.ticket}
          setPrintTicketModal={setIsModalVisible}
          publicationName={
            bookingData?.publicationBooking?.publicationFile?.sd_publication
              ?.name
          }
          language={bookingData?.publicationBooking?.booking?.language}
          subscription={bookingData?.publicationBooking?.publicationFile?.name}
          categoryTransactionId={bookingData?.category_transaction_id}
          serialNumber={bookingData?.daily_category_transaction_id}
          setData={setBookingData}
        />
      </Modal>
    </div>
  );
};
export default withRouter(Sd_Counter_Publications);
