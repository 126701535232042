import Axios from "../utils/axios";

const DonationService = {
  newDonation: async (
    name,
    description,
    targetAmount,
    minAmount,
    targetDate,
    imageUrl,
    disabled
  ) => {
    const requestObj = {
      name,
      description,
      target_amount: targetAmount,
      min_amount: minAmount,
      target_date: targetDate,
      image_url: imageUrl,
      disabled,
    };
    if (!description) delete requestObj.description;
    if (!imageUrl) delete requestObj.imageUrl;
    if (!disabled) delete requestObj.disabled;
    if (!targetAmount) delete requestObj.target_amount;
    if (!minAmount) delete requestObj.min_amount;
    if (!targetAmount) delete requestObj.target_date;
    if (!imageUrl) delete requestObj.image_url;
    const resp = await Axios.post(`/donation/new`, requestObj, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  editDonation: async (
    donationId,
    name,
    description,
    targetAmount,
    minAmount,
    targetDate,
    imageUrl,
    disabled
  ) => {
    const requestObj = {
      donationId,
      name,
      description,
      target_amount: targetAmount,
      min_amount: minAmount,
      target_date: targetDate,
      imageUrl: imageUrl,
      disabled,
    };
    if (!description) delete requestObj.description;
    if (!imageUrl) delete requestObj.imageUrl;
    if (!disabled) delete requestObj.disabled;
    if (!targetAmount) delete requestObj.target_amount;
    if (!minAmount) delete requestObj.min_amount;
    if (!targetDate || targetDate === "Invalid date") {
      delete requestObj.target_date;
    }
    if (!imageUrl) delete requestObj.imageUrl;
    console.log(requestObj);
    const resp = await Axios.post(`/donation/edit`, requestObj, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    return resp.data;
  },

  getAll: async () => {
    const resp = await Axios.get("/donation/all");
    return resp.data;
  },

  getAllDonorList: async (page, limit) => {
    const resp = await Axios.get(`/coupon/accommodation-donar-registry?page=${page}&limit=${limit}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  newDonorRegistartion: async (
    donorName,
    RoomId,
    Blockid,
    CouponAmount,
    noOfCoupons,
    phone,
  ) => {
    const body = {
      "donar_name": donorName,
      "phone": `+91${phone}`,
      "block_id": Blockid,
      "room_id": RoomId,
      "coupon_value": CouponAmount,
      "no_of_coupons": noOfCoupons
    };

    const resp = await Axios.post(`/coupon/add-accommodation-donar-registry`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      }
    });
    return resp.data;
  },
  donationById: async (donationId) => {
    const resp = await Axios.get(`/donation/by-id?donationId=${donationId}`);
    return resp.data;
  },

  deleteDonation: async (donationId) => {
    const resp = await Axios.delete(`/donation?donationId=${donationId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  bookings: async (donationId, fromDate, toDate, page, limit) => {
    let query = `paymentStatus=success&page=${page}&limit=${limit}&`;

    if (fromDate) {
      query += `fromDate=${fromDate}&`;
    }
    if (toDate) {
      query += `toDate=${toDate}&`;
    }

    if (donationId) {
      query += `donataionId=${donationId}&`;
    }

    const resp = await Axios.get(`donation-report/bookings?${query}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  bookingsExcel: async (donationId, fromDate, toDate) => {
    let query = `paymentStatus=success&`;

    if (fromDate) {
      query += `fromDate=${fromDate}&`;
    }
    if (toDate) {
      query += `toDate=${toDate}&`;
    }

    if (donationId) {
      query += `donataionId=${donationId}&`;
    }

    const resp = await Axios.get(`donation-report/bookings/excel?${query}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      responseType: "blob",
    });
    return resp.data;
  },

  getCollectionByDonation: async (fromDate, toDate, txType) => {
    const resp = await Axios.get(
      `/donation-report/collection?fromDate=${fromDate}&toDate=${toDate}&paymentStatus=success&txType=${txType}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  //for offline counter list--------------------------------------
  getAllOfflineCounterDonationList: async (id) => {
    const resp = await Axios.get("donation-counter/counter-donations/list", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  //---------------------------------------------------------------
  getAllDonationCounterList: async (id) => {
    const resp = await Axios.get(
      `/donation-counter/counter/byDonationId/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  addCounterbyDonationId: async (body) => {
    const resp = await Axios.post(`/donation-counter/new`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });

    return resp.data;
  },
  deleteCounterbyDonationId: async (id) => {
    const resp = await Axios.delete(
      `/donation-counter/ByDonationCounterId/${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );
    return resp.data;
  },
  bookDonationTicket: async (body) => {
    let resp = await Axios.post(`/cart-counter/donation`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  editDonationBookingDetails: async (body) => {
    const resp = await Axios.patch(`donation/edit/donation-booking`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });

    return resp.data;
  }
};

export default DonationService;
