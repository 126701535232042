import { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import ThulabharamService from "../../Services/Thulabharam.service";
import { UserContext } from "../../context/user.context";
import { routeMapMini } from "../../constants/routeMap";
import { Button, Col, notification, Row, Modal, Input, Space } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import ThulabharamTable from "./ThulabharamTable";

const Thulabharam = ({ history }) => {
  const [showNewModal, setShowNewModal] = useState(false);
  const [loading, setLoading] = useState();
  const [ThulabharamName, setThulabharamName] = useState("");
  const [price, setPrice] = useState();
  const [edit, setEdit] = useState(false);
  const [updateTable, setUpdateTable] = useState();
  const [updateThulabharamID, setupdateThulabharamID] = useState();
  const { userDetails } = useContext(UserContext);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    (async () => {
      if (updateThulabharamID) {
        setShowNewModal(true);
        try {
          const resp = await ThulabharamService.ThulabharambyId(
            updateThulabharamID
          );
          setThulabharamName(resp?.name);
          setPrice(resp?.price);
          setDisabled(resp?.disabled);
          setEdit(true);
          setShowNewModal(true);
        } catch (error) {
          if (error?.response?.status === 401) {
            notification.error({
              message: "Token Expired, redirecting to login page in 5 seconds",
            });
            history?.push(routeMapMini.Login);
          } else if (error.response?.data?.message) {
            notification.error({ message: error.response?.data?.message });
          } else {
            notification.error({ message: error.message });
          }
        }
      }
    })();
  }, [history, updateThulabharamID]);

  const handleOk = async () => {
    if (!ThulabharamName) {
      return notification.error({ message: `name is required` });
    } else if (!price || price <= 0) {
      return notification.error({
        message: `price is required and should be positive`,
      });
    }
    setLoading(true);
    try {
      if (updateThulabharamID) {
        await ThulabharamService.editThulabharam(
          updateThulabharamID,
          ThulabharamName,
          disabled,
          price
        );
        notification.success({
          message: `Thulabharam ${updateThulabharamID} updated`,
        });
        clearFields();
        setUpdateTable(updateThulabharamID + Math.random());
        setEdit(false);
      } else {
        const data = await ThulabharamService.newThulabharam(
          ThulabharamName,
          disabled,
          price
        );
        notification.success({
          message: `Thulabharam ${data.id} created`,
        });
        clearFields();
        setUpdateTable(updateThulabharamID + Math.random());
        setEdit(false);
      }
      setShowNewModal(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({ message: error.response?.data?.message });
      } else {
        notification.error({ message: error.message });
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setThulabharamName(undefined);
    setPrice(undefined);
    setupdateThulabharamID(undefined);
    setDisabled(undefined);
    setEdit(false);
  };

  const handleCancel = () => {
    clearFields();
    setShowNewModal(false);
    setEdit(false);
  };

  return (
    <div style={{ width: "100%" }}>
      <Row style={{ width: "100%", marginBottom: "15px" }} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setShowNewModal(true);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Add New Thulabharam Item
          </Button>
        </Col>
      </Row>
      <Row style={{ width: "100%" }}>
        <ThulabharamTable
          updateTable={updateTable}
          setThulabharamId={setupdateThulabharamID}
        />
      </Row>
      <Modal
        visible={showNewModal}
        title={`${
          edit
            ? `Edit Thulabharam id: ${updateThulabharamID}`
            : `Add New Thulabharam Item`
        }`}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ alignContent: "center" }}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify="start" style={{ width: "100%", padding: "10px 0px" }}>
          <label>Thulabharam Item</label>
          <Input
            placeholder="Thulabharam Item"
            value={ThulabharamName}
            onChange={(e) => {
              setThulabharamName(e.target.value);
            }}
            style={{ width: "450px" }}
          />
        </Row>
        <Row
          justify="start"
          style={{
            width: "100%",
            padding: "10px 0px",
            flexDirection: "column",
          }}
        >
          <label>Price </label>
          <Input
            placeholder="Price"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
            type="number"
            style={{ width: "450px" }}
          />
        </Row>
        <Row justify="center" style={{ width: "100%", padding: "10px 0px" }}>
          <Space size="middle">
            Disable Seva
            <Checkbox
              checked={disabled}
              onChange={(e) => {
                setDisabled(e.target.checked);
              }}
            />
          </Space>
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(Thulabharam);
