/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  DatePicker,
  Input,
  notification,
  Row,
  Select,
  Space,
  Upload,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { routeMapMini } from '../../../constants/routeMap';
import { UserContext } from '../../../context/user.context';
import PublicationService from '../../../Services/Publication.service';
import { baseURL } from '../../../utils/axios';
import PublicationFilesTable from './PublicationFilesTable';
import { UploadOutlined } from '@ant-design/icons';

const PublicationFiles = ({ history, location }) => {
  const [showNewModal, setShowNewModal] = useState(false);

  const [updateTable, setUpdateTable] = useState();
  const [loading, setLoading] = useState();

  const [publicationId, setPublicationId] = useState();
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [file_url, set_file_url] = useState();
  const [type, setType] = useState();
  const [price, setPrice] = useState();
  const [deliveryPrice, setDeliveryPrice] = useState();
  const [languageOptions, setLanguageOptions] = useState(['telugu']);
  const [name, setName] = useState();

  const { userDetails } = useContext(UserContext);

  const [updatePublicationFileId, setUpdatePublicationFileId] = useState();

  useEffect(() => {
    const id = new URLSearchParams(location.search).get('publicationId');
    if (!id) {
      history?.push(routeMapMini.Publications);
    }
    setPublicationId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, location.search]);

  useEffect(() => {
    (async () => {
      if (updatePublicationFileId) {
        setShowNewModal(true);
        try {
          const resp = await PublicationService.getPublicationFileById(
            updatePublicationFileId
          );
          if (resp?.year && resp?.month) {
            setMonth(moment().set('month', resp?.month - 1));
            setYear(moment().set('year', resp?.year));
          }
          setPrice(resp?.price);
          set_file_url(resp?.file_url);
          setType(resp?.type);
          setLanguageOptions(resp?.language_options?.split(','));
          setName(resp?.name);
          setDeliveryPrice(resp?.delivery_price);
          setShowNewModal(true);
        } catch (error) {
          if (error?.response?.status === 401) {
            notification.error({
              message: 'Token Expired, redirecting to login page in 5 seconds',
            });
            history?.push(routeMapMini.Login);
          } else if (error.response?.data?.message) {
            notification.error({ message: error.response?.data?.message });
          } else {
            notification.error({ message: error.message });
          }
        }
      }
    })();
  }, [history, updatePublicationFileId]);

  const clearFields = () => {
    setUpdatePublicationFileId();
    setYear();
    setMonth();
    setPrice();
    setType();
    setLanguageOptions();
    setName();
    setDeliveryPrice();
    setShowNewModal(false);
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      if (updatePublicationFileId) {
        await PublicationService.editPublicationFile(
          updatePublicationFileId,
          publicationId,
          year?.format('YYYY'),
          month?.format('MM'),
          type,
          languageOptions,
          name,
          file_url,
          price,
          deliveryPrice
        );
      } else {
        await PublicationService.createPublicationFile(
          publicationId,
          year?.format('YYYY'),
          month?.format('MM'),
          type,
          languageOptions,
          name,
          file_url,
          price,
          deliveryPrice
        );
      }
      clearFields();
      setUpdateTable(Math.random());
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: 'Token Expired, redirecting to login page.',
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({ message: error.response?.data?.message });
      } else {
        notification.error({ message: error.message });
      }
    }
    setLoading(false);
  };

  const handleCancel = () => {
    clearFields();
  };

  return (
    <div style={{ width: '100%' }}>
      <Row style={{ width: '100%', marginBottom: '15px' }}>
        <Space size='middle'>
          <Button
            type='primary'
            onClick={() => {
              setShowNewModal(true);
            }}
            disabled={userDetails.role !== 'admin'}
          >
            Add New File
          </Button>
        </Space>
      </Row>
      <Row style={{ width: '100%' }}>
        <PublicationFilesTable
          updateTable={updateTable}
          setUpdatePublicationFileId={setUpdatePublicationFileId}
          publicationId={publicationId}
        />
      </Row>
      <Modal
        visible={showNewModal}
        title={
          updatePublicationFileId
            ? `Modify ${updatePublicationFileId}`
            : `Add New File`
        }
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify='center' style={{ width: '100%', paddingTop: '10px' }}>
          Type
        </Row>
        <Row justify='center' style={{ width: '100%', padding: '10px 0px' }}>
          <Select
            value={type}
            onChange={(e) => {
              setType(e);
            }}
            style={{ width: '150px' }}
          >
            <Select.Option value='download'>Download</Select.Option>
            <Select.Option value='subscription'>Subscription</Select.Option>
            <Select.Option value='purchase'>Purchase</Select.Option>
          </Select>
        </Row>
        <Row justify='center' style={{ width: '100%', paddingTop: '10px' }}>
          Languages
        </Row>
        <Row justify='center' style={{ width: '100%', padding: '10px 0px' }}>
          <Select
            mode={type === 'download' ? ' ' : 'multiple'}
            allowClear
            defaultValue={'telugu'}
            value={languageOptions}
            style={{ width: '150px' }}
            onChange={(e) => {
              setLanguageOptions(type === 'download' ? [e] : e);
            }}
          >
            <Select.Option value='telugu'>Telugu</Select.Option>
            <Select.Option value='kannada'>Kannada</Select.Option>
            <Select.Option value='english'>English</Select.Option>
            <Select.Option value='sanskrit'>Sanskrit</Select.Option>
          </Select>
        </Row>
        <div style={{ display: type === 'purchase' ? 'block' : 'none' }}>
          <Row justify='center' style={{ width: '100%', paddingTop: '10px' }}>
            Delivery Price
          </Row>
          <Row justify='center' style={{ width: '100%', padding: '10px 0px' }}>
            <Input
              type='number'
              value={deliveryPrice}
              onChange={(e) => {
                setDeliveryPrice(e.target.value);
              }}
              style={{ width: '150px' }}
            />
          </Row>
        </div>
        <Row justify='center' style={{ width: '100%', paddingTop: '10px' }}>
          Price
        </Row>
        <Row justify='center' style={{ width: '100%', padding: '10px 0px' }}>
          <Input
            type='number'
            min='1'
            placeholder='Price'
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
            style={{ width: '150px' }}
          />
        </Row>
        <div style={{ display: type === 'subscription' ? 'block' : 'none' }}>
          <Row justify='center' style={{ width: '100%', paddingTop: '10px' }}>
            Name
          </Row>
          <Row justify='center' style={{ width: '100%', padding: '10px 0px' }}>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Row>
        </div>
        <div style={{ display: type === 'download' ? 'block' : 'none' }}>
          <Row justify='center' style={{ width: '100%', paddingTop: '10px' }}>
            Year
          </Row>
          <Row justify='center' style={{ width: '100%', padding: '10px 0px' }}>
            <DatePicker
              value={year}
              onChange={(e) => {
                setYear(e);
              }}
              picker='year'
            />
          </Row>
        </div>
        <div style={{ display: type === 'download' ? 'block' : 'none' }}>
          <Row justify='center' style={{ width: '100%', paddingTop: '10px' }}>
            Month
          </Row>
          <Row justify='center' style={{ width: '100%', padding: '10px 0px' }}>
            <DatePicker
              onChange={(e) => setMonth(e)}
              value={month}
              picker='month'
              format='MM'
            />
          </Row>
        </div>
        <div style={{ display: type === 'download' ? 'block' : 'none' }}>
          <Row justify='center' style={{ width: '100%', paddingTop: '10px' }}>
            <a onClick={() => window.open(file_url, '_blank')}>File</a>
          </Row>
          <Row justify='center'>
            <Upload
              name='file'
              listType='picture'
              action={`${baseURL}fileupload/upload/purchase-file?fileFor=publication_file`}
              headers={{
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              }}
              accept='image/png, image/gif, image/jpeg, application/pdf'
              maxCount={1}
              onChange={(info) => {
                if (info.file.status !== 'uploading') {
                  set_file_url(info.file?.response?.key);
                }
                if (info.file.status === 'done') {
                  notification.success({
                    message: `${info.file.name} file uploaded successfully`,
                  });
                } else if (info.file.status === 'error') {
                  notification.error({
                    message: `${info.file.name} file upload failed.`,
                  });
                }
              }}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(PublicationFiles);
