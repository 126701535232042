/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Col,
  notification,
  Space,
  Table,
  Tag,
  Tabs,
  Drawer,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import React, { useContext, useState } from "react";
import ReactJson from "react-json-view";
import { withRouter } from "react-router";
import { routeMapMini } from "../../constants/routeMap";
import { UserContext } from "../../context/user.context";
import AuditService from "../../Services/Audit.service";
import OldUpdateDetails from "./OldUpdateDetails";
import NewUpdateDetails from "./NewUpdateDetails";

const SevaAudit = ({ history }) => {
  const { userDetails } = useContext(UserContext);
  const [data, setData] = useState([]);

  const [reviewData, setReviewData] = useState();

  const [reviewsLoading, setReviewsLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);

  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(0);

  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState(false);
  const [approvalItemId, setApprovalItemId] = useState(0);
  const [approvalDescription, setApprovalDescription] = useState();

  const [viewDetailsData, setViewDetailsData] = useState();
  const [viewDetailsData2, setViewDetailsData2] = useState();
  const [showUpdateDetailsModal, setShowUpdateDetailsModal] = useState(false);

  const { TabPane } = Tabs;

  const getUpdateData = async () => {
    setDataLoading(true);
    try {
      const respData = await AuditService.getSevaUpdates(
        "all",
        true,
        tableCurrentPage,
        tablePageSize
      );
      console.log(tablePageSize);
      const temp = respData?.items?.map((item) => {
        return {
          key: item.id,
          update_reason: item.update_reason,
          userName: item?.sd_user?.display_name,
          userId: item?.sd_user_id,
          ...item,
        };
      });
      setData(temp);
      setTabelTotalSize(respData?.meta?.totalItems);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page.",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
    setDataLoading(false);
  };

  const getSevaUpdateReviews = async (itemId) => {
    setReviewsLoading(true);
    try {
      const respData = await AuditService.getSevaUpdateReviews(itemId, 0, 0);
      const temp = respData?.items?.map((item) => {
        return {
          key: item.id,
          accepted: item.accepted,
          description: item.review_description,
          userName: item?.sd_user?.display_name,
          userId: item?.sd_user_id,
          userRole: item?.sd_user?.role,
          created_at: item.created_at,
        };
      });
      setReviewData(temp);
      setTabelTotalSize(respData?.meta?.totalItems);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page.",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
    setReviewsLoading(false);
  };

  useState(() => {
    getUpdateData();
  }, [tableCurrentPage, tablePageSize]);

  const columns = [
    {
      title: "Update Type",
      dataIndex: "update_type",
      key: "update_type",
      render: (text) => {
        if (text === "CREATE") {
          return <span style={{ color: "green" }}>{text}</span>;
        } else if (text === "DELETE") {
          return <span style={{ color: "red" }}>{text}</span>;
        } else if (text === "UPDATE") {
          return <span style={{ color: "orange" }}>{text}</span>;
        } else {
          return <span style={{ color: "blue" }}>{text}</span>;
        }
      },
    },
    {
      title: "Proposal By",
      dataIndex: "user",
      key: "user",
      render: (text, record) => {
        return (
          <div>
            <a
              onClick={() => {
                history?.push(
                  `${routeMapMini.userDetails}?userId=${record.userId}`
                );
              }}
              style={{ textTransform: "capitalize" }}
            >
              {record.userName || record.userPhone}
            </a>
          </div>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "update_reason",
      key: "update_reason",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => a.created_at > b.created_at,
      render: (text) =>
        moment(text).utcOffset("+05:30").format("YYYY-MM-DD hh:mm A"),
    },
    {
      title: "Review Status",
      dataIndex: "update_used",
      key: "update_used",
      render: (text) => {
        if (text === true) {
          return <Tag color="green">Completed</Tag>;
        } else if (text === false) {
          return <Tag color="red">In Process</Tag>;
        }
      },
    },
    {
      title: "Action",
      fixed: "right",
      responsive: ["md", "lg", "xl", "xsl"],
      render: (record) => {
        return ["admin"].includes(userDetails.role) ? (
          <Space size="middle">
            <a
              onClick={() => {
                if (record?.seva) {
                  const temp = { ...record };
                  delete temp.seva;
                  setViewDetailsData(temp);
                  setViewDetailsData2(record?.seva);
                } else {
                  setViewDetailsData(record);
                  setViewDetailsData2(null);
                }
                setShowUpdateDetailsModal(true);
              }}
            >
              View Changes
            </a>
            {!record.no_of_user_reviews > 0 && !record.update_used && (
              <>
                <a
                  onClick={() => {
                    setApprovalItemId(record.key);
                    setApprovalStatus(true);
                    setShowApprovalModal(true);
                  }}
                >
                  Accept
                </a>
                <a
                  onClick={() => {
                    setApprovalItemId(record.key);
                    setApprovalStatus(false);
                    setShowApprovalModal(true);
                  }}
                >
                  Reject
                </a>
              </>
            )}
          </Space>
        ) : null;
      },
    },
  ];

  const expandableTableColumns = [
    {
      title: "Status",
      dataIndex: "accepted",
      key: "accepted",
      render: (text) => {
        if (text === true) {
          return <Tag color="green">Accepted</Tag>;
        } else if (text === false) {
          return <Tag color="red">Rejected</Tag>;
        }
      },
    },
    {
      title: "Review By",
      dataIndex: "user",
      key: "user",
      render: (text, record) => {
        return (
          <div>
            <a
              onClick={() => {
                history?.push(
                  `${routeMapMini.userDetails}?userId=${record.userId}`
                );
              }}
              style={{ textTransform: "capitalize" }}
            >
              {record.userName || record.userPhone}
            </a>
          </div>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "User Role",
      dataIndex: "userRole",
      key: "userRole",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => a.created_at > b.created_at,
      render: (text) =>
        moment(text).utcOffset("+05:30").format("YYYY-MM-DD hh:mm A"),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const handleApprovalStatus = async () => {
    if (!approvalItemId) {
      return notification.error({ message: "Problem selecting approval" });
    }
    if (!approvalDescription) {
      return notification.error({
        message: "Approval Description is required",
      });
    }
    setApprovalLoading(true);
    try {
      await AuditService.postSevaUpdateReview(
        approvalItemId,
        approvalDescription,
        approvalStatus
      );
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page.",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
    setApprovalLoading(false);
    setShowApprovalModal(false);
    setApprovalDescription(undefined);
    getUpdateData();
  };

  return (
    <div>
      <Table
        scroll={{ x: true }}
        style={{ width: "100%", textTransform: "capitalize" }}
        columns={columns}
        dataSource={data}
        loading={dataLoading}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: 15,
        }}
        onChange={handleTableChange}
        expandable={{
          onExpand: async (expanded, record) => {
            console.log(expanded);
            console.log(record);
            if (expanded) {
              setReviewData([]);
              await getSevaUpdateReviews(record.key);
            }
          },
          expandedRowRender: (record) => (
            <>
              <Table
                columns={expandableTableColumns}
                dataSource={reviewData}
                loading={reviewsLoading}
                pagination={false}
                style={{ textTransform: "capitalize" }}
              />
            </>
          ),
        }}
      />
      <Modal
        visible={showApprovalModal}
        title={`${
          approvalStatus ? "Approve Modification" : "Reject Modification"
        }`}
        onCancel={() => {
          setShowApprovalModal(false);
          setApprovalStatus(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setShowApprovalModal(false);
              setApprovalStatus(false);
            }}
          >
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={approvalLoading}
            onClick={handleApprovalStatus}
          >
            Submit
          </Button>,
        ]}
      >
        <TextArea
          onChange={(e) => {
            setApprovalDescription(e.target.value);
          }}
          value={approvalDescription}
        />
      </Modal>
      <Drawer
        title={`View Changes`}
        placement="right"
        closable={false}
        onClose={() => {
          setViewDetailsData(undefined);
          setShowUpdateDetailsModal(false);
        }}
        visible={showUpdateDetailsModal}
        width={800}
        destroyOnClose={true}
      >
        {viewDetailsData2 ? (
          <Tabs defaultActiveKey="1">
            <TabPane tab="CARD" key="1">
              <OldUpdateDetails details={viewDetailsData2} />
              <NewUpdateDetails details={viewDetailsData} />
            </TabPane>
            <TabPane tab="JSON" key="2">
              <Col>
                Old
                <ReactJson
                  src={viewDetailsData2}
                  theme="bright:inverted"
                  displayDataTypes={false}
                  displayObjectSize={false}
                  enableClipboard={false}
                />
              </Col>
              <Col>
                New
                <ReactJson
                  src={viewDetailsData}
                  theme="bright:inverted"
                  displayDataTypes={false}
                  displayObjectSize={false}
                  enableClipboard={false}
                />
              </Col>
            </TabPane>
          </Tabs>
        ) : (
          <Tabs>
            <TabPane tab="CARD" key="1">
              <NewUpdateDetails details={viewDetailsData} />
            </TabPane>
            <TabPane tab="JSON" key="2">
              <ReactJson
                src={viewDetailsData}
                theme="bright:inverted"
                displayDataTypes={false}
                displayObjectSize={false}
                enableClipboard={false}
              />
            </TabPane>
          </Tabs>
        )}
      </Drawer>
    </div>
  );
};

export default withRouter(SevaAudit);
