import {Button, Col, Divider, Row, Select} from "antd";
import React, {useState} from "react";
import {withRouter} from "react-router";
import moment from "moment";
import TempleTimePickerTwo from "./TempleTimePickerTwo";
import TempleTimePickerOne from "./TempleTimePickerOne";
import {PlusSquareOutlined} from "@ant-design/icons";
import {Option} from "antd/lib/mentions";
const Panchangam = ({
  selectedDate,
  setSelectedDate,
  selectedTimePeriod,
  setSelectedTimePeriod,
}) => {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  return (
    <div className="temple-calender">
      <div className="tc-top">
        <div>
          <Button
            className={selectedTimePeriod === "day" ? "active-button" : ""}
            onClick={(e) => {
              e.preventDefault();
              setSelectedTimePeriod("D");
            }}
          >
            Day
          </Button>
          <Button
            className={selectedTimePeriod === "week" ? "active-button" : ""}
            onClick={(e) => {
              e.preventDefault();
              setSelectedTimePeriod("W");
            }}
          >
            Week
          </Button>
          <Button
            className={selectedTimePeriod === "month" ? "active-button" : ""}
            onClick={(e) => {
              e.preventDefault();
              setSelectedTimePeriod("M");
            }}
          >
            Month
          </Button>
        </div>
      </div>
      <div className="tc-bottom ">
        <div className="tc-p-left w-50 border">
          <div className="sd-header">
            Date: {moment(selectedDate).format("DD-MM-YYYY")}
            <Select
              placeholder="Languages"
              style={{marginLeft: "10px"}}
              default="Languages"
            >
              <Option key="Languages" value="Languages">
                Languages
              </Option>
            </Select>
          </div>
          <Row style={{textAlign: "left"}}>
            <TempleTimePickerOne name="Tithi" time={to} setTime={setTo} />
          </Row>
          <Row style={{textAlign: "left"}}>
            <TempleTimePickerOne name="Nakshatram" time={to} setTime={setTo} />
          </Row>
          <Row style={{textAlign: "left"}}>
            <TempleTimePickerTwo
              name="Amrutha Kaalam"
              to={to}
              setTo={setTo}
              from={from}
              setFrom={setFrom}
            />
          </Row>
          <Row style={{textAlign: "left"}}>
            <TempleTimePickerTwo
              name="Varjyam"
              to={to}
              setTo={setTo}
              from={from}
              setFrom={setFrom}
            />
          </Row>
          <Row style={{textAlign: "left"}}>
            <TempleTimePickerOne
              name="Dur Muhurtham"
              time={to}
              setTime={setTo}
            />
          </Row>
          <Row style={{textAlign: "left"}}>
            <TempleTimePickerTwo
              name="Rahu Kaalam"
              to={to}
              setTo={setTo}
              from={from}
              setFrom={setFrom}
            />
          </Row>
          <Row style={{textAlign: "left"}}>
            <TempleTimePickerTwo
              name="Yamagandham"
              to={to}
              setTo={setTo}
              from={from}
              setFrom={setFrom}
            />
          </Row>
          <Row style={{textAlign: "left"}}>
            <TempleTimePickerOne name="Suryodhayam" time={to} setTime={setTo} />
          </Row>
          <Row style={{textAlign: "left"}}>
            <TempleTimePickerOne
              name="Suryasthamayam"
              time={to}
              setTime={setTo}
            />
          </Row>
          <br />
          <Row justify="center">
            <Button
              style={{
                float: "right",
                backgroundColor: "#f38e00",
                color: "white",
              }}
            >
              Edit
            </Button>
          </Row>
          <Divider />
          <Row justify="start" style={{padding: "10px 0px"}}>
            <Col span={6}>Available in :</Col>
            <Col span={18} style={{display: "flex", flexWrap: "wrap"}}>
              {" "}
              <Button>Telugu</Button>
              <Button>Kannada</Button>
              <Button>Marathi</Button>
              <Button>Tamil</Button>
            </Col>
          </Row>
        </div>
        <div className="tc-p-right w-50 border">
          <div className="sd-header">
            Add New <PlusSquareOutlined />
          </div>
          <Row style={{textAlign: "left"}}>
            <TempleTimePickerOne name="Tithi" time={to} setTime={setTo} />
          </Row>
          <Row style={{textAlign: "left"}}>
            <TempleTimePickerOne name="Nakshatram" time={to} setTime={setTo} />
          </Row>
          <Row style={{textAlign: "left"}}>
            <TempleTimePickerTwo
              name="Amrutha Kaalam"
              to={to}
              setTo={setTo}
              from={from}
              setFrom={setFrom}
            />
          </Row>
          <Row style={{textAlign: "left"}}>
            <TempleTimePickerTwo
              name="Varjyam"
              to={to}
              setTo={setTo}
              from={from}
              setFrom={setFrom}
            />
          </Row>
          <Row style={{textAlign: "left"}}>
            <TempleTimePickerOne
              name="Dur Muhurtham"
              time={to}
              setTime={setTo}
            />
          </Row>
          <Row style={{textAlign: "left"}}>
            <TempleTimePickerTwo
              name="Rahu Kaalam"
              to={to}
              setTo={setTo}
              from={from}
              setFrom={setFrom}
            />
          </Row>
          <Row style={{textAlign: "left"}}>
            <TempleTimePickerTwo
              name="Yamagandham"
              to={to}
              setTo={setTo}
              from={from}
              setFrom={setFrom}
            />
          </Row>
          <Row style={{textAlign: "left"}}>
            <TempleTimePickerOne name="Suryodhayam" time={to} setTime={setTo} />
          </Row>
          <Row style={{textAlign: "left"}}>
            <TempleTimePickerOne
              name="Suryasthamayam"
              time={to}
              setTime={setTo}
            />
          </Row>
          <br />
          <Row justify="center">
            <Button
              style={{
                float: "right",
                backgroundColor: "#f38e00",
                color: "white",
              }}
            >
              Save
            </Button>
            <Button
              style={{
                float: "right",
                marginLeft: "10px",
                backgroundColor: "white",
                color: "#f38e00",
              }}
            >
              Cancel
            </Button>{" "}
          </Row>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Panchangam);
