/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Col,
  DatePicker,
  Input,
  notification,
  Row,
  Select,
  Space,
  Table,
  Drawer,
} from "antd";
import {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import fileDownload from "js-file-download";
import moment from "moment";
import DarshanamService from "../../Services/Darshanam.service";
import {routeMapMini} from "../../constants/routeMap";
import UserDetailsCard from "../ManageUsers/UserDetailsCard";

const DarshanamBookingDetails = ({history}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [darshanamNamesAndIds, setDarshanmNamesAndIds] = useState([]);
  const [darshanmNamesLoading, setDarshanamNamesLoading] = useState(false);

  const [selectedDarshanamId, setSelectedDarshanamId] = useState(0);
  const [bookingFromDate, setBookingFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [bookingToDate, setBookingToDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const [dateType, setDateType] = useState("orderDate");

  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);

  const [showUserDetails, setShowUserDetails] = useState(false);
  const [userDetails, setUserDetails] = useState();

  const getDonationNamesAndIds = async () => {
    setDarshanamNamesLoading(true);
    try {
      const doantionNamesAndIdData = await DarshanamService.allIdAndNames();
      setDarshanmNamesAndIds(doantionNamesAndIdData);
    } catch (error) {
      notification.error({message: error.message});
    }
    setDarshanamNamesLoading(false);
  };

  useEffect(() => {
    getDonationNamesAndIds();
  }, []);

  useEffect(() => {
    fetchBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bookingFromDate,
    bookingToDate,
    selectedDarshanamId,
    tableCurrentPage,
    tablePageSize,
  ]);

  const fetchBookings = async () => {
    setDataLoading(true);
    try {
      const respData = await DarshanamService.bookings(
        selectedDarshanamId,
        bookingFromDate,
        bookingToDate,
        dateType,
        tableCurrentPage,
        tablePageSize
      );
      setData(respData?.items);
      setTabelTotalSize(respData?.meta?.totalItems);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setDataLoading(false);
  };

  const downloadExcel = async () => {
    if (!selectedDarshanamId) {
      return notification.error({
        message: "Donation is not selected",
      });
    }
    if (!bookingFromDate) {
      return notification.error({
        message: "Date is not selected",
      });
    }

    notification.info({message: "Downloading file! Please wait."});
    DarshanamService.bookingsExcel(
      selectedDarshanamId,
      bookingFromDate,
      bookingToDate,
      dateType
    )
      .then((data) => {
        fileDownload(
          data,
          `${bookingFromDate}-DarshanamBooked-${moment().format(
            "DD-MM-YYYY HH:MM"
          )}.xlsx`
        );
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          notification.error({
            message: "Token Expired, redirecting to login page in 5 seconds",
          });
          history?.push(routeMapMini.Login);
        } else if (error?.response?.data?.message) {
          notification.error({message: error?.response?.data?.message});
        } else {
          console.error(error);
          notification.error({message: "something is wrong"});
        }
      });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{padding: 8}}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: "block"}}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{width: 90, marginRight: 8}}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{width: 90}}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const columns = [
    {
      title: "user",
      dataIndex: "user_display_name",
      key: "user_display_name",
      render: (text, record) => {
        return (
          <div>
            <a
              onClick={() => {
                // history?.push(
                //   `${routeMapMini.userDetails}?userId=${record.user_id}`
                // );
                setUserDetails(record?.user_id);
                setShowUserDetails(true);
              }}
              style={{textTransform: "capitalize"}}
            >
              {record.user_display_name}
            </a>
          </div>
        );
      },
    },
    {
      title: "Darshanam Name",
      dataIndex: "darshanamName",
      key: "darshanamName",
      ...getColumnSearchProps("darshanamName"),
    },
    {
      title: "Devotee Names",
      dataIndex: "devoteeNames",
      key: "devoteeNames",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Person Count",
      dataIndex: "personCount",
      key: "personCount",
    },
    {
      title: "Order Date",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Darshanam Date",
      dataIndex: "bookingDate",
      render: (record) => {
        return moment(record).format("DD-MM-YYYY");
      },
    },
  ];

  const bookingPersonDetails = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Date of Birth",
      dataIndex: "dob",
      key: "dob",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
  ];

  const handleUserDetails = () => {
    setUserDetails(undefined);
    setShowUserDetails(false);
  };

  return (
    <>
      <Row style={{marginBottom: "20px"}} gutter={[20, 20]}>
        <Col>
          <Row justify="start">Darshanam Type</Row>
          <Row justify="start">
            <Select
              style={{width: "200px"}}
              loading={darshanmNamesLoading}
              onChange={setSelectedDarshanamId}
              defaultValue=""
            >
              {darshanamNamesAndIds.map((item) => {
                return (
                  <Select.Option value={item.id}> {item.name} </Select.Option>
                );
              })}
              <Select.Option value="">All</Select.Option>
            </Select>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Date</Row>
          <Row justify="start">
            <DatePicker.RangePicker
              format="DD-MM-YYYY"
              onChange={(e) => {
                if (e?.[0] && e?.[1]) {
                  setBookingFromDate(moment(e[0]).format(`YYYY-MM-DD`));
                  setBookingToDate(moment(e[1]).format(`YYYY-MM-DD`));
                } else {
                  setBookingFromDate(undefined);
                  setBookingToDate(undefined);
                }
              }}
              ranges={{
                Today: [moment(), moment()],
                "Last 7 Days": [moment().subtract(7, "d"), moment()],
                "Last 15 Days": [moment().subtract(15, "d"), moment()],
              }}
              defaultValue={[moment(bookingFromDate), moment(bookingToDate)]}
              defaultPickerValue={[
                moment(bookingFromDate),
                moment(bookingToDate),
              ]}
            />
          </Row>
        </Col>
        <Col>
          <Row justify="start">Date Type</Row>
          <Row justify="start">
            <Select
              style={{width: "180px"}}
              onChange={(e) => {
                setDateType(e);
              }}
              value={dateType}
            >
              <Select.Option value="orderDate">Order Date</Select.Option>
              <Select.Option value="performanceDate">
                Performance Date
              </Select.Option>
            </Select>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Update</Row>
          <Row justify="start">
            <Button
              type="primary"
              style={{minWidth: "100px"}}
              onClick={fetchBookings}
            >
              Fetch
            </Button>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Download</Row>
          <Row justify="start">
            <Button
              type="primary"
              style={{minWidth: "100px"}}
              onClick={() => downloadExcel()}
            >
              Excel
            </Button>
          </Row>
        </Col>
      </Row>

      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Total Bookings</span>
        </Space>
      </Row>

      <Table
        scroll={{x: true}}
        style={{width: "100%", textTransform: "capitalize"}}
        columns={columns}
        dataSource={data}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        size="small"
        onChange={handleTableChange}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              scroll={{x: true}}
              columns={bookingPersonDetails}
              dataSource={record.bookingPersonDetails?.map((item) => {
                return item;
              })}
              pagination={false}
              size="small"
              style={{textTransform: "capitalize"}}
            />
          ),
        }}
        loading={dataLoading}
      ></Table>
      <Drawer
        title={`User`}
        placement="right"
        closable={false}
        onClose={handleUserDetails}
        visible={showUserDetails}
        width={500}
        destroyOnClose={true}
      >
        <UserDetailsCard userid={userDetails} />
      </Drawer>
    </>
  );
};

export default withRouter(DarshanamBookingDetails);
