import Axios from "../utils/axios";

const ReportService = {
  reconciliationReport: async (
    fromDate,
    toDate,
    bookingType,
    counterId,
    shiftId
  ) => {
    const resp = await Axios.get(
      `reports/reconciliationReport/?fromDate=${fromDate}&toDate=${toDate}&type=${bookingType}&counterId=${counterId}&shiftId=${shiftId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  reconciliationReportExcel: async (
    fromDate,
    toDate,
    bookingType,
    counterId,
    shiftId
  ) => {
    const resp = await Axios.get(
      `reports/reconciliationReport/excel?fromDate=${fromDate}&toDate=${toDate}&type=${bookingType}&counterId=${counterId}&shiftId=${shiftId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        responseType: "blob",
      }
    );
    return resp.data;
  },

  getConsolidatedReport: async (fromDate, toDate, counterIds, shiftIds) => {
    let url = `reports/consolidated-report?fromDate=${fromDate}&toDate=${toDate}${counterIds !== "" ? `&counters=${counterIds}` : ""
      }${shiftIds !== "" ? `&shiftIds=${shiftIds}` : ""
      } `;

    const resp = await Axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  getConsolidatedReportExcel: async (fromDate, toDate, counterIds, shiftIds) => {
    let url = `reports/consolidated-report/excel?fromDate=${fromDate}&toDate=${toDate}${counterIds !== "" ? `&counters=${counterIds}` : ""
      }${shiftIds !== "" ? `&shiftIds=${shiftIds}` : ""} `;

    const resp = await Axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      responseType: "blob",
    });
    return resp.data;
  },

  allTransactions: async (fromDate, toDate, sortType, page, limit) => {
    const resp = await Axios.get(
      `reports/transactions?fromDate=${fromDate}&toDate=${toDate}&sortType=${sortType}&page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  allTransactionsExcel: async (fromDate, toDate, sortType, page, limit) => {
    const resp = await Axios.get(
      `reports/transactions/excel?fromDate=${fromDate}&toDate=${toDate}&sortType=${sortType}&page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        responseType: "blob",
      }
    );
    return resp.data;
  },
  allShiftTransactions: async (logID, page, limit) => {
    const resp = await Axios.get(`shift-log-report/transactions/${logID}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  shiftTransactionsDownload: async (id) => {
    const resp = await Axios.get(`shift-log-report/transactions/pdf/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      responseType: "blob",
    });
    return resp.data;
  },

  gstReport: async (fromDate, toDate) => {
    const resp = await Axios.get(
      `gst-shift-report/accommodation/?fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  gstReportExcel: async (fromDate, toDate, sortType, page, limit) => {
    const resp = await Axios.get(
      `gst-shift-report/accommodation/excel?fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        responseType: "blob",
      }
    );
    return resp.data;
  },
  getSevaCounterReport: async (
    view,
    sevaId,
    bookingFromDate,
    bookingToDate,
    sevaType,
    dateType,
    orderBy,
    page,
    limit,
    counterId,
    shiftId
  ) => {
    let query = `page=${page}&limit=${limit}&paymentStatus=success&orderBy=${orderBy}&dateType=${dateType}&`;
    if (bookingFromDate) {
      query += `bookingFromDate=${bookingFromDate}&`;
    }
    if (bookingToDate) {
      query += `bookingToDate=${bookingToDate}&`;
    }
    if (sevaId) {
      query += `sevaId=${sevaId}&`;
    }
    if (sevaType) {
      query += `sevaType=${sevaType}&`;
    }
    if (counterId) {
      query += `counterId=${counterId}&`;
    }
    if (shiftId) {
      query += `shiftId=${shiftId}&`;
    }

    const resp = await Axios.get(
      `seva-report/bookings/counter/${view}?${query}&`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        responseType: view === "download" ? "blob" : "",
      }
    );
    return resp.data;
  },
  getPrasadamCounterReport: async (
    view,
    selectedPrasadamId,
    bookingFromDate,
    bookingToDate,
    dateType,
    page,
    limit,
    counterId,
    shiftId
  ) => {
    let query = `page=${page}&limit=${limit}&paymentStatus=success&dateType=${dateType}&`;
    if (bookingFromDate) {
      query += `bookingFromDate=${bookingFromDate}&`;
    }
    if (bookingToDate) {
      query += `bookingToDate=${bookingToDate}&`;
    }
    if (selectedPrasadamId) {
      query += `prasadamId=${selectedPrasadamId}&`;
    }

    if (counterId) {
      query += `counterId=${counterId}&`;
    }
    if (shiftId) {
      query += `shiftId=${shiftId}&`;
    }

    const resp = await Axios.get(`prasadam-report/counter/${view}?${query}&`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      responseType: view === "download" ? "blob" : "",
    });
    return resp.data;
  },
  getKalayanakattaCounterReport: async (
    view,
    selectedPrasadamId,
    bookingFromDate,
    bookingToDate,
    dateType,
    page,
    limit,
    counterId,
    shiftId
  ) => {
    let query = `page=${page}&limit=${limit}&paymentStatus=success&dateType=${dateType}&`;
    if (bookingFromDate) {
      query += `bookingFromDate=${bookingFromDate}&`;
    }
    if (bookingToDate) {
      query += `bookingToDate=${bookingToDate}&`;
    }
    if (selectedPrasadamId) {
      query += `kalyanakattaId=${selectedPrasadamId}&`;
    }

    if (counterId) {
      query += `counterId=${counterId}&`;
    }
    if (shiftId) {
      query += `shiftId=${shiftId}&`;
    }

    const resp = await Axios.get(`kalyanakatta-report/counter/${view}?${query}&`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      responseType: view === "download" ? "blob" : "",
    });
    return resp.data;
  },
  getKailasaKankanamCounterReport: async (
    view,
    selectedKankanamId,
    bookingFromDate,
    bookingToDate,
    dateType,
    page,
    limit,
    counterId,
    shiftId
  ) => {
    let query = `page=${page}&limit=${limit}&paymentStatus=success&dateType=${dateType}&`;
    if (bookingFromDate) {
      query += `bookingFromDate=${bookingFromDate}&`;
    }
    if (bookingToDate) {
      query += `bookingToDate=${bookingToDate}&`;
    }
    if (selectedKankanamId) {
      query += `prasadamId=${selectedKankanamId}&`;
    }

    if (counterId) {
      query += `counterId=${counterId}&`;
    }
    if (shiftId) {
      query += `shiftId=${shiftId}&`;
    }

    const resp = await Axios.get(`kailasa-kankanam-report/counter/${view}?${query}&`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      responseType: view === "download" ? "blob" : "",
    });
    return resp.data;
  },
  getDarshanamCounterReport: async (
    view,
    selectedDarshanamId,
    bookingFromDate,
    bookingToDate,
    dateType,
    page,
    limit,
    counterId,
    shiftId
  ) => {
    let query = `page=${page}&limit=${limit}&paymentStatus=success&dateType=${dateType}&`;
    if (bookingFromDate) {
      query += `bookingFromDate=${bookingFromDate}&`;
    }
    if (bookingToDate) {
      query += `bookingToDate=${bookingToDate}&`;
    }
    if (selectedDarshanamId) {
      query += `darshanamId=${selectedDarshanamId}&`;
    }

    if (counterId) {
      query += `counterId=${counterId}&`;
    }
    if (shiftId) {
      query += `shiftId=${shiftId}&`;
    }

    const resp = await Axios.get(
      `darshanam-report/bookings/counter/${view}?${query}&`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        responseType: view === "download" ? "blob" : "",
      }
    );
    return resp.data;
  },
  getDonationCounterReport: async (
    selectedDonationId,
    bookingFromDate,
    bookingToDate,
    page,
    limit,
    paymentStatus
  ) => {
    let query = `onlyCounter=true&page=${page}&limit=${limit}&paymentStatus=success&`;
    if (bookingFromDate) {
      query += `fromDate=${bookingFromDate}&`;
    }
    if (bookingToDate) {
      query += `toDate=${bookingToDate}&`;
    }
    if (selectedDonationId) {
      query += `donationId=${selectedDonationId}`;
    }

    // if (paymentStatus) {
    //   query += `paymentStatus=${paymentStatus}&`;
    // }


    const resp = await Axios.get(
      `donation-report/bookings?${query}&`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  getDonationExcelCounterReport: async (
    selectedDonationId,
    bookingFromDate,
    bookingToDate,
    page,
    limit,
  ) => {
    let query = `onlyCounter=true&page=${page}&limit=${limit}&paymentStatus=success&`;
    if (bookingFromDate) {
      query += `fromDate=${bookingFromDate}&`;
    }
    if (bookingToDate) {
      query += `toDate=${bookingToDate}&`;
    }
    if (selectedDonationId) {
      query += `donationId=${selectedDonationId}&`;
    }




    const resp = await Axios.get(
      `donation-report/bookings/excel?${query}&`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        responseType: "blob",
      }
    );
    return resp.data;
  },
  getScanTicketLogReport: async (
    bookingFromDate,
    bookingToDate,
    page,
    limit,
    scanType,
    outcome
  ) => {
    let query = `page=${page}&limit=${limit}&`;
    if (bookingFromDate) {
      query += `bookingFromDate=${bookingFromDate}&`;
    }
    if (bookingToDate) {
      query += `bookingToDate=${bookingToDate}&`;
    }
    if (scanType) {
      query += `scan_type=${scanType}&`;
    }
    if (outcome) {
      query += `outcome=${outcome}&`;
    }
    const resp = await Axios.get(`scan-ticket-log/all-transactions?${query}&`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      // responseType: view === "download" ? "blob" : "",
    });
    return resp.data;
  },
  getPulicationReport: async (
    publicationId,
    fromDate,
    toDate,
    publicationType,
    page,
    limit
  ) => {
    let query = `onlyCounter=true&paymentStatus=success&page=${page}&limit=${limit}&`;

    if (publicationId) {
      query += `publicationId=${publicationId}&`;
    }
    if (fromDate) {
      query += `fromDate=${fromDate}&`;
    }
    if (toDate) {
      query += `toDate=${toDate}&`;
    }
    if (publicationType) {
      query += `publicationType=${publicationType}&`;
    }

    const resp = await Axios.get(`publication-report/bookings?${query}&`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  getPublicationReportExcel: async (publicationId, fromDate, toDate, publicationType) => {
    let query = `onlyCounter=true&paymentStatus=success&`;

    if (publicationId) {
      query += `publicationId=${publicationId}&`;
    }
    if (fromDate) {
      query += `fromDate=${fromDate}&`;
    }
    if (toDate) {
      query += `toDate=${toDate}&`;
    }
    if (publicationType) {
      query += `publicationType=${publicationType}&`;
    }
    const resp = await Axios.get(
      `/publication-report/bookings/excel?${query}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        responseType: "blob",
      }
    );
    return resp.data;
  },
  getThulabharanCounterReport: async (
    view,
    thulabharamId,
    bookingFromDate,
    bookingToDate,
    dateType,
    page,
    limit,
    counterId,
  ) => {
    let query = `page=${page}&limit=${limit}&paymentStatus=success&dateType=${dateType}&`;
    if (bookingFromDate) {
      query += `bookingFromDate=${bookingFromDate}&`;
    }
    if (bookingToDate) {
      query += `bookingToDate=${bookingToDate}&`;
    }
    if (thulabharamId) {
      query += `darshanamId=${thulabharamId}&`;
    }

    if (counterId) {
      query += `counterId=${counterId}&`;
    }


    const resp = await Axios.get(
      `thulabharam-report/counter/${view}?${query}&`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        responseType: view === "download" ? "blob" : "",
      }
    );
    return resp.data;
  },
  getTollGateCounterReport: async (
    view,
    tollGateId,
    bookingFromDate,
    bookingToDate,
    dateType,
    page,
    limit,
    counterId,
  ) => {
    let query = `page=${page}&limit=${limit}&paymentStatus=success&dateType=${dateType}&`;
    if (bookingFromDate) {
      query += `bookingFromDate=${bookingFromDate}&`;
    }
    if (bookingToDate) {
      query += `bookingToDate=${bookingToDate}&`;
    }
    if (tollGateId) {
      query += `darshanamId=${tollGateId}&`;
    }

    if (counterId) {
      query += `counterId=${counterId}&`;
    }


    const resp = await Axios.get(
      `toll-gate-report/counter/${view}?${query}&`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        responseType: view === "download" ? "blob" : "",
      }
    );
    return resp.data;
  },
  getOccupancyCounterReport: async (
    blockId,
    roomId,
  ) => {
    let query = ``;

    if (blockId) {
      query += `blockId=${blockId}&`;
    }
    if (roomId) {
      query += `roomId=${roomId}&`;
    }

    const resp = await Axios.get(
      `accommodation-report/room-occupancy-report?${query}&`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
};

export default ReportService;
