/* eslint-disable jsx-a11y/anchor-is-valid */
import { notification, Row, Space, Table, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { routeMapMini } from "../../../constants/routeMap";
import CartService from "../../../Services/Cart.service";
import { FormatNumberINRCurrency } from "../../../utils/currencyFormat";

const UserBookingHistoryTable = ({ userId, history }) => {
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("success");

  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);

  const fetchBookings = async () => {
    if (!userId) {
      return;
    }
    setDataLoading(true);
    try {
      const respData = await CartService.getUserBookingHistory(
        userId,
        paymentStatus,
        tableCurrentPage,
        tablePageSize
      );
      setData(respData?.items);
      setTabelTotalSize(respData?.meta?.totalItems);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page.",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }

    setDataLoading(false);
  };

  useEffect(() => {
    fetchBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, paymentStatus, tableCurrentPage, tablePageSize]);

  const columns = [
    {
      title: "Service Category",
      dataIndex: "serviceCategory",
      key: "serviceCategory",
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      filters: [
        { text: "success", value: "success" },
        { text: "failed", value: "failed" },
        { text: "created", value: "created" },
        { text: "canceled", value: "canceled" },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.paymentStatus?.indexOf(value) === 0,
      render: (text) => {
        if (text === "success") {
          return <Tag color="green">Success</Tag>;
        } else if (text === "failed") {
          return <Tag color="red">Failed</Tag>;
        } else if (text === "created") {
          return <Tag color="blue">Created</Tag>;
        } else {
          return <Tag color="orange">{text}</Tag>;
        }
      },
    },
    {
      title: "Service Name",
      dataIndex: "serviceName",
      key: "serviceName",
    },
    {
      title: "Booking Date",
      dataIndex: "bookingDate",
      key: "bookingDate",
    },
    {
      title: "Amount",
      dataIndex: "price",
      key: "price",
      render: FormatNumberINRCurrency,
    },
    {
      title: "Order Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) =>
        moment(text).utcOffset("+05:30").format("YYYY-MM-DD hh:mm A"),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setPaymentStatus(filters?.paymentStatus?.[0] ?? "success");
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  return (
    <>
      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Total Bookings</span>
        </Space>
      </Row>
      <Table
        loading={dataLoading}
        columns={columns}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        dataSource={data}
        scroll={{ x: true }}
        size="small"
        onChange={handleTableChange}
        style={{ textTransform: "capitalize" }}
      />
    </>
  );
};

export default withRouter(UserBookingHistoryTable);
