/* eslint-disable jsx-a11y/anchor-is-valid */
import { notification, Row, Space, Table } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { routeMapMini } from "../../constants/routeMap";
import NewsLetterService from "../../Services/NewsLetter.service";

const NewsLetter = ({ history }) => {
  const [loading, setLoading] = useState();
  const [data, setData] = useState();

  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);

  const getSubscriptions = async () => {
    setLoading(true);
    try {
      const respData = await NewsLetterService.allSubscription(
        tableCurrentPage,
        tablePageSize
      );
      setData(respData?.items);
      setTabelTotalSize(respData?.meta?.totalItems);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableCurrentPage, tablePageSize]);

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => a.createdAt > b.createdAt,
      render: (text) =>
        moment(text).utcOffset("+05:30").format("YYYY-MM-DD hh:mm A"),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  return (
    <div>
      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          Total Subscriptions
        </Space>
      </Row>
      <Table
        scroll={{ x: true }}
        style={{ width: "100%", textTransform: "capitalize" }}
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        onChange={handleTableChange}
        size="small"
      ></Table>
    </div>
  );
};

export default NewsLetter;
