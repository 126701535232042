import {
  Button,
  Col,
  DatePicker,
  Input,
  notification,
  Row,
  Space,
  Upload,
} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import TextArea from "antd/lib/input/TextArea";
import Modal from "antd/lib/modal/Modal";
import {useContext, useEffect, useState} from "react";
import * as moment from "moment";

import {withRouter} from "react-router-dom";
import {UploadOutlined} from "@ant-design/icons";
import {routeMapMini} from "../../constants/routeMap";
import DonationService from "../../Services/Donation.service";
import DonationTable from "./DonationTable";
import {UserContext} from "../../context/user.context";
import {baseURL} from "../../utils/axios";

const Donation = ({history}) => {
  const [showNewModal, setShowNewModal] = useState(false);
  const [loading, setLoading] = useState();
  const [donationName, setDonationName] = useState("");
  const [targetDate, setTargetDate] = useState(null);
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [targetAmount, setTargetAmount] = useState();
  const [disabled, setDisabled] = useState(false);
  const [minPrice, setMinPrice] = useState();
  const [updateTable, setUpdateTable] = useState();
  const [updateDonationId, setUpdateDonationId] = useState();
  const {userDetails} = useContext(UserContext);

  useEffect(() => {
    (async () => {
      if (updateDonationId) {
        setShowNewModal(true);
        try {
          const resp = await DonationService.donationById(updateDonationId);
          setDonationName(resp?.name);
          setTargetDate(moment(resp?.target_date).format(`YYYY-MM-DD`));
          setDescription(resp?.description);
          setImageUrl(resp?.image_url);
          setTargetAmount(resp?.target_amount);
          setDisabled(resp?.disabled);
          setMinPrice(resp?.min_amount);
          setShowNewModal(true);
        } catch (error) {
          if (error?.response?.status === 401) {
            notification.error({
              message: "Token Expired, redirecting to login page in 5 seconds",
            });
            history?.push(routeMapMini.Login);
          } else if (error.response?.data?.message) {
            notification.error({message: error.response?.data?.message});
          } else {
            notification.error({message: error.message});
          }
        }
      }
    })();
  }, [history, updateDonationId]);

  const handleOk = async () => {
    if (!donationName) {
      return notification.error({message: `name is required`});
    } else if (!minPrice || minPrice <= 0) {
      return notification.error({
        message: `price is required and should be positive`,
      });
    }
    setLoading(true);
    try {
      if (updateDonationId) {
        await DonationService.editDonation(
          updateDonationId,
          donationName,
          description,
          targetAmount,
          minPrice,
          targetDate,
          imageUrl,
          disabled
        );
        notification.success({
          message: `Donation ${updateDonationId} updated`,
        });
        clearFields();
        setUpdateTable(updateDonationId + Math.random());
      } else {
        const doantion = await DonationService.newDonation(
          donationName,
          description,
          targetAmount,
          minPrice,
          targetDate,
          imageUrl,
          disabled
        );
        notification.success({message: `doantion ${doantion.id} created`});
        setUpdateTable(doantion.id + Math.random());
      }
      setShowNewModal(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setDonationName(undefined);
    setTargetDate(undefined);
    setDescription(undefined);
    setImageUrl(undefined);
    setTargetAmount(undefined);
    setDisabled(undefined);
    setMinPrice(undefined);
    setUpdateDonationId(undefined);
  };

  const handleCancel = () => {
    clearFields();
    setShowNewModal(false);
  };

  return (
    <div style={{width: "100%"}}>
      <Row style={{width: "100%", marginBottom: "15px"}} gutter={[20, 20]}>
        {userDetails.role === "admin" && (
          <Col>
            <Button
              type="primary"
              onClick={() => {
                setShowNewModal(true);
              }}
              disabled={!["admin"].includes(userDetails.role)}
            >
              Add New Donation
            </Button>
          </Col>
        )}
        <Col>
          <Button
            type="primary"
            onClick={() => {
              history?.push(routeMapMini.DonationBookingDetails);
            }}
            disabled={
              !["admin", "donation_read", "donation_write"].includes(
                userDetails.role
              )
            }
          >
            Donation Booking Details
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              history?.push(routeMapMini.DonorRegistration);
            }}
            disabled={!["admin"].includes(userDetails.role)}
          >
            Donor Registartion
          </Button>
        </Col>
      </Row>
      <Row style={{width: "100%"}}>
        <DonationTable
          updateTable={updateTable}
          setUpdateDonationId={setUpdateDonationId}
        />
      </Row>
      <Modal
        visible={showNewModal}
        title="Add New Donation"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Donation Name"
            value={donationName}
            onChange={(e) => {
              setDonationName(e.target.value);
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Upload
            name="file"
            listType="picture"
            action={`${baseURL}fileupload/upload/public-file?fileFor=publication_cover_pic`}
            headers={{
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }}
            accept="image/png, image/gif, image/jpeg"
            maxCount={1}
            onChange={(info) => {
              if (info.file.status !== "uploading") {
                setImageUrl(info.file?.response?.path);
              }
              if (info.file.status === "done") {
                notification.success({
                  message: `${info.file.name} file uploaded successfully`,
                });
              } else if (info.file.status === "error") {
                notification.error({
                  message: `${info.file.name} file upload failed.`,
                });
              }
            }}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <TextArea
            placeholder="Description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Target Amount"
            type="number"
            value={targetAmount}
            onChange={(e) => {
              setTargetAmount(e.target.value);
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Min Price"
            value={minPrice}
            onChange={(e) => {
              setMinPrice(e.target.value);
            }}
            type="number"
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Space size="middle">
            Disabled Donation
            <Checkbox
              checked={disabled}
              onChange={(e) => {
                setDisabled(e.target.checked);
              }}
            />
          </Space>
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <DatePicker
            use12Hours
            onChange={(e) => {
              setTargetDate(e?.format?.("YYYY-MM-DD"));
            }}
            style={{width: "400px"}}
          />
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(Donation);
