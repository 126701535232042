import {useState, useEffect, useRef} from "react";
import {withRouter} from "react-router";
import {
  DatePicker,
  Row,
  notification,
  Divider,
  Col,
  Card,
  Button,
  Radio,
  Input,
} from "antd";
import moment from "moment";
import CounterDarshanamService from "../../../../Services/CounterDarshanam.service";
import {routeMapMini} from "../../../../constants/routeMap";
import {FormatNumberINRCurrency} from "../../../../utils/currencyFormat";
import PrintTicket from "../../../Ticket/PrintTicket";
import StringUtils from "../../../../utils/string.utils";
import "./Sd_Darshanam.scss";
import {RightOutlined} from "@ant-design/icons";

const Sd_Offline_Darshanam_Ticket = ({history}) => {
  const [fetchDarshanamLoading, setFetchDarshanamLoading] = useState(false);
  const [bookingDate, setBookingDate] = useState(moment());
  const [slots, setSlots] = useState();
  const [persons, setPersons] = useState(1);
  const [price, setPrice] = useState();
  const [ticketPrice, setTicketPrice] = useState();
  const [darshanamId, setDarshanamId] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [darshanamData, setDarshanamData] = useState();
  const [amountpaid, setAmountPaid] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [externalPrint, setExternalPrint] = useState(false);
  // const ref = useRef();
  const fetchDarshanam = async () => {
    document.getElementById("searchable")?.focus();

    if (persons) {
      setFetchDarshanamLoading(true);
      try {
        setSlots([]);
        const slots = await CounterDarshanamService.getDarshanamSlots(
          moment(bookingDate).format("YYYY-MM-DD"),
          persons
        );
        setSlots(slots);
        selectSlot(
          slots[0]?.sd_darshanam_slots.sd_darshanam.price,
          slots[0]?.sd_darshanam_slots?.id
        );

        // ref?.current?.focus();
      } catch (error) {
        if (error?.response?.status === 401) {
          history?.push(routeMapMini.Login);
        } else if (error?.response?.data?.message) {
          notification.error({message: error?.response?.data?.message});
        } else {
          console.error(error);
          notification.error({message: "something is wrong"});
        }
      }
      setFetchDarshanamLoading(false);
    }
  };

  const darshanamBooking = async () => {
    setButtonLoading(true);
    try {
      const data = await CounterDarshanamService.darshanamBooking(
        persons,
        darshanamId,
        moment(bookingDate).format("YYYY-MM-DD")
      );
      setDarshanamData(data);
      setIsModalVisible(true);
      setExternalPrint(true);
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    fetchDarshanam();
    if (persons && ticketPrice) {
      setPrice();
    }
  }, [bookingDate, persons]);

  const selectSlot = (ticketPrice, darshanamSlotId) => {
    setDarshanamId(darshanamSlotId);
    setTicketPrice(ticketPrice);
    setPrice(ticketPrice * persons);
  };
  const onKeyDown = (e) => {
    console.log(e.key);
    if (e.key === "Enter") {
      darshanamBooking();
    }
  };

  return (
    <div className="darshanamBody" onKeyDown={onKeyDown} tabindex="0">
      <Card className="darshanamBodyCard">
        {" "}
        <Row justify="center" className="darshanamTitle">
          Darshanam
        </Row>
        <Divider />
        <Row gutter={(20, 20)} style={{justifyContent: "center"}}>
          <Col style={{width: "30%"}}>
            <Row className="darshanamBookingDate">Booking Date</Row>
            <Row style={{marginBottom: "20px"}}>
              <DatePicker
                disabled={true}
                size="large"
                allowClear
                defaultPickerValue={moment()}
                format="DD-MM-YYYY"
                value={bookingDate}
                onChange={(e) => setBookingDate(e)}
                disabledDate={(d) =>
                  !d || d.isBefore(StringUtils.addDays(new Date(), -1))
                }
                style={{width: "100%"}}
              />
            </Row>
          </Col>
          <Col style={{width: "30%"}}>
            <Row className="darshanamTickets">No. of Tickets</Row>
            <Row style={{marginBottom: "20px"}}>
              <Input
                autoFocus
                placeholder="Enter No. of Persons"
                value={persons}
                onChange={(e) => setPersons(e.target.value)}
                style={{width: "100%", textAlign: "left"}}
                size="large"
                type="number"
                readOnly
                // min="0"
                // max="4"
                // maxLength="1"
                // onInput={(object) => {
                //   if (object.target.value.length > object.target.maxLength) {
                //     object.target.value = object.target.value.slice(
                //       0,
                //       object.target.maxLength
                //     );
                //   }
                // }}
              />
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col xs={24} sm={24} md={12} lg={16} xl={16} xsl={16}>
            {slots?.length !== 0 && persons ? (
              <div style={{marginBottom: "20px"}}>
                <Row>
                  <h1 className="resultsTitle">Results</h1>
                </Row>
                <Row gutter={[20, 20]} className="resultsRow">
                  {slots?.map((item) => {
                    if (1 > item?.available_booking_count) {
                      // eslint-disable-next-line array-callback-return
                      return;
                    } else {
                    }
                    return (
                      <Col
                        style={{
                          float: "left",
                        }}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xsl={12}
                      >
                        <Card
                          className="darshanamCard"
                          title={
                            <Row>
                              <Col style={{textAlign: "left", width: "80%"}}>
                                {item.sd_darshanam_slots.sd_darshanam.name} |{" "}
                                {FormatNumberINRCurrency(
                                  item?.sd_darshanam_slots.sd_darshanam.price
                                )}
                              </Col>
                              <Col style={{textAlign: "right", width: "20%"}}>
                                <Radio
                                  defaultChecked={false}
                                  value={item?.sd_darshanam_slots?.id}
                                  checked={
                                    item?.sd_darshanam_slots?.id === darshanamId
                                      ? true
                                      : false
                                  }
                                  id={item?.sd_darshanam_slots?.id}
                                  onClick={() => {
                                    if (1 > item?.available_booking_count) {
                                      return;
                                    }
                                    setDarshanamId(item);
                                  }}
                                />
                              </Col>
                            </Row>
                          }
                          hoverable
                          onClick={() =>
                            selectSlot(
                              item?.sd_darshanam_slots.sd_darshanam.price,
                              item?.sd_darshanam_slots?.id
                            )
                          }
                        >
                          {/* {moment(item.sd_darshanam_slots.start_time)
                  .utcOffset("+5:30")
                  .format(`hh:mm A`)} */}
                          <Row>
                            <Col
                              style={{
                                width: "70%",
                                borderRight: "2px solid #f0f2f5",
                              }}
                            >
                              <Row
                                style={{
                                  fontWeight: "bold",
                                  color: "#ff844c",
                                  fontSize: "16px",
                                  marginBottom: "0px",
                                }}
                              >
                                {moment(
                                  item?.sd_darshanam_slots.start_time,
                                  "HH:mm:ss"
                                ).format("hh:mm A")}{" "}
                                -{" "}
                                {moment(
                                  item?.sd_darshanam_slots.end_time,
                                  "HH:mm:ss"
                                ).format("hh:mm A")}
                              </Row>
                              {/* <Row
                            style={{
                              fontWeight: "bold",
                              fontSize: "12px",
                              marginBottom: "5px",
                            }}
                          >
                            {FormatNumberINRCurrency(
                              item?.sd_darshanam_slots.sd_darshanam.price
                            )}
                          </Row> */}
                            </Col>
                            <Col className="ticketCount">
                              {item?.available_booking_count ?? 0}
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            ) : (
              <>
                {bookingDate && !fetchDarshanamLoading ? (
                  <>
                    <div
                      style={{
                        position: "relative",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {persons
                        ? `No Slots available on ${moment(bookingDate).format(
                            "DD-MM-YYYY"
                          )}`
                        : "Enter No. of Tickets"}
                    </div>
                  </>
                ) : (
                  bookingDate && (
                    <>
                      <div style={{position: "relative", textAlign: "left"}}>
                        Fetching slots...Please wait{" "}
                      </div>
                    </>
                  )
                )}
              </>
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8} xsl={8}>
            <Divider type="vertical" />
            {price >= 0 && persons > 0 && (
              <>
                <Row>
                  <h1 className="paymentTitle">Payment Details</h1>
                </Row>
                <Row>
                  <Col
                    style={{
                      width: "25%",
                      textAlign: "left",
                    }}
                  >
                    Price :
                  </Col>
                  <Col style={{width: "75%", textAlign: "right"}}>
                    {FormatNumberINRCurrency(ticketPrice)}
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      width: "25%",
                      textAlign: "left",
                    }}
                  >
                    No. of Tickets :
                  </Col>
                  <Col style={{width: "75%", textAlign: "right"}}>
                    {persons}
                  </Col>
                </Row>
                <Row>
                  <Col className="totalAmountTitle">Total Amount :</Col>
                  <Col className="totalAmount">
                    {FormatNumberINRCurrency(price)}
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col
                    style={{
                      width: "25%",
                      textAlign: "left",
                    }}
                  >
                    Amount Paid:
                  </Col>
                  <Col
                    style={{
                      width: "75%",
                      textAlign: "right",
                      fontWeight: "600",
                    }}
                  >
                    <Input
                      style={{width: "150px", textAlign: "right"}}
                      type="number"
                      onChange={(e) => {
                        setAmountPaid(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{marginTop: "10px", marginBottom: "50px"}}>
                  <Col
                    style={{
                      width: "25%",
                      textAlign: "left",
                    }}
                  >
                    Amount to be Returned:
                  </Col>
                  <Col
                    style={{
                      width: "75%",
                      textAlign: "right",
                      fontSize: "22px",
                      fontWeight: "600",
                    }}
                  >
                    {FormatNumberINRCurrency(
                      amountpaid - price > 0 ? amountpaid - price : 0
                    )}
                  </Col>
                </Row>
                <br />
                <Row gutter={[10, 10]} className="paymentDetailsButton">
                  <Col>
                    <Button
                      // ref={ref}
                      type="primary"
                      onClick={darshanamBooking}
                      loading={buttonLoading}
                    >
                      Book Now <RightOutlined />
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
        <Row style={{display: "none"}}>
          <PrintTicket
            sevaName={darshanamData?.darshanamBooking?.darshanamName}
            bookingDate={moment(
              darshanamData?.darshanamBooking?.booking?.created_at
            ).format("DD-MM-YYYY")}
            performanceDate={moment(
              darshanamData?.darshanamBooking?.booking?.booking_date
            ).format("DD-MM-YYYY")}
            timeSlot={darshanamData?.darshanamBooking?.timeSlot}
            persons={darshanamData?.darshanamBooking?.booking?.persons}
            amountPaid={darshanamData?.darshanamBooking?.booking?.price ?? 0}
            ticketId={darshanamData?.ticket}
            setData={setDarshanamData}
            setPrintTicketModal={setIsModalVisible}
            categoryTransactionId={darshanamData?.category_transaction_id}
            serialNumber={darshanamData?.daily_category_transaction_id}
            type="darshanam"
            from="Counter"
            externalPrint={externalPrint}
            setExternalPrint={setExternalPrint}
          />
        </Row>
        {/* <Modal
          visible={isModalVisible}
          width={450}
          footer={null}
          closable={false}
          centered
          maskClosable={false}
        >
          <PrintTicket
            sevaName={darshanamData?.darshanamBooking?.darshanamName}
            bookingDate={moment(
              darshanamData?.darshanamBooking?.booking?.created_at
            ).format("DD-MM-YYYY")}
            performanceDate={moment(
              darshanamData?.darshanamBooking?.booking?.booking_date
            ).format("DD-MM-YYYY")}
            timeSlot={darshanamData?.darshanamBooking?.timeSlot}
            persons={darshanamData?.darshanamBooking?.booking?.persons}
            amountPaid={darshanamData?.darshanamBooking?.booking?.price}
            ticketId={darshanamData?.ticket}
            setData={setDarshanamData}
            setPrintTicketModal={setIsModalVisible}
            categoryTransactionId={darshanamData?.category_transaction_id}
            serialNumber={darshanamData?.daily_category_transaction_id}
            type="darshanam"
            from="Counter"
            externalPrint={externalPrint}
            setExternalPrint={setExternalPrint}
          />
        </Modal> */}
      </Card>
    </div>
  );
};

export default withRouter(Sd_Offline_Darshanam_Ticket);
