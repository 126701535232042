import {
  Button,
  Col,
  Input,
  notification,
  Row,
  Space,
  Typography,
  Card,
  Tag,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import ReactJson from "react-json-view";
import { withRouter } from "react-router";
import { routeMapMini } from "../../constants/routeMap";
import CartService from "../../Services/Cart.service";
import ScannedTicket from "./ScannedTicket";

const TicketDetails = ({ history }) => {
  const inputEl = useRef();

  const [ticketId, setTicketId] = useState("");
  const [data, setData] = useState("");

  const setInputFocus = () => {
    inputEl?.current?.input?.focus();
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      submit();
    }
  };

  const submit = async () => {
    setData(undefined);
    if (!ticketId) {
      notification.error({ message: "Enter Ticket Number!" });
    }
    try {
      const data = await CartService.getAdminTicketDetails(ticketId);
      if (data) {
        setData(data);
        setTicketId("");
      }
      setInputFocus();
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
  };

  useEffect(() => {
    setInputFocus();
  }, []);

  return (
    <>
      <Row justify="center">
        <Typography.Title> Ticket Details </Typography.Title>
      </Row>
      <Row justify="center">
        <Space size="middle">
          <Col> Ticket No: </Col>
          <Col>
            <Input
              ref={inputEl}
              value={ticketId}
              onChange={(e) => {
                setTicketId(e.target.value);
              }}
              onKeyDown={onKeyDown}
            />
          </Col>
          <Col>
            <Button type="primary" onClick={submit}>
              GO!
            </Button>
          </Col>
        </Space>
      </Row>
      <Row justify="center">
        <Col
          style={{ marginTop: "20px" }}
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={12}
          xsl={8}
        >
          {data && <ScannedTicket data={data} />}
        </Col>
      </Row>
    </>
  );
};

export default withRouter(TicketDetails);
