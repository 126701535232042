import Axios from "../utils/axios";
import moment from "moment";

const CartCounterService = {
  accomodation: async (
    country,
    docNumber,
    firstName,
    lastName,
    gender,
    gothram,
    phone,
    email,
    address,
    address2,
    city,
    state,
    zip,
    docType,
    persons,
    deposite,
    roomId,
    age,
    donorType,
    coupon
  ) => {
    let dob;

    if (age) {
      dob = moment().subtract(age, "years").format("YYYY-MM-DD");
    }

    const body = {
      first_name: firstName,
      last_name: lastName,
      address: address,
      address2: address2,
      gender: gender,
      no_of_persons: persons,
      contact: phone,
      pin_code: zip,
      state: state,
      email: email,
      city: city,
      gothram: gothram,
      country: country,
      idProofType: docType,
      idProofNumber: docNumber,
      roomId: roomId,
      depositAmount: deposite,
      dob,
    };

    Object.keys(body).forEach((key) => {
      if (body[key] === undefined || !body[key]) {
        delete body[key];
      }
    });
    console.log(coupon);
    if (coupon !== "") {
      body.coupon_code = coupon;
      body.is_direct_donor = donorType === "owner" ? true : false;
    }
    const resp = await Axios.post(`/cart-counter/accomodation`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
};

export default CartCounterService;
