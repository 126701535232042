import {PlusSquareOutlined} from "@ant-design/icons";
import {Button, Col, DatePicker, Divider, Input, Row} from "antd";
import React, {useEffect} from "react";
import {withRouter} from "react-router";
import moment from "moment";
import StringUtils from "../../../utils/string.utils";
import {useState} from "react";
import Modal from "antd/lib/modal/Modal";
import Checkbox from "antd/lib/checkbox/Checkbox";
import TempleNewEventModalOne from "./TempleNewEventModalOne";
const TempleEvents = ({
  selectedDate,
  setSelectedDate,
  selectedTimePeriod,
  setSelectedTimePeriod,
}) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [date, setDate] = useState("");
  const [fullDay, setFullDay] = useState(false);
  const [announcement, setAnnouncemnet] = useState(false);
  const [block, setBlock] = useState(false);
  const [blockType, setBlocktype] = useState("");
  const [blockTimeTo, setBlocktimeTo] = useState("");
  const [blockTimeFrom, setBlocktimeFrom] = useState("");

  const [step, setStep] = useState("1");
  useEffect(() => {
    if (fullDay) {
      setFrom(moment("00:00:00", "HH:mm:ss"));
      setTo(moment("23:59:00", "HH:mm:ss"));
    } else {
      setFrom("");
      setTo("");
    }
  }, [fullDay]);
  return (
    <div className="temple-calender">
      <div className="tc-top">
        <div>
          <Button
            className={selectedTimePeriod === "D" ? "active-button" : ""}
            onClick={(e) => {
              e.preventDefault();
              setSelectedTimePeriod("D");
            }}
          >
            Day
          </Button>
          <Button
            className={selectedTimePeriod === "W" ? "active-button" : ""}
            onClick={(e) => {
              e.preventDefault();
              setSelectedTimePeriod("W");
            }}
          >
            Week
          </Button>
          <Button
            className={selectedTimePeriod === "M" ? "active-button" : ""}
            onClick={(e) => {
              e.preventDefault();
              setSelectedTimePeriod("M");
            }}
          >
            Month
          </Button>
        </div>

        <Button
          onClick={() => {
            setVisible(true);
          }}
        >
          New <PlusSquareOutlined />
        </Button>
      </div>
      <div className="tc-bottom">
        <div className="tc-e-bottom w-100">
          <p style={{marginTop: "10px"}}>
            Events from
            <span className="fw600">
              {moment(selectedDate).format("DD-MM-YYYY")}
            </span>
            -
            <span className="fw600">
              {(() => {
                if (selectedTimePeriod === "D") {
                  return moment(selectedDate).format("DD-MM-YYYY");
                } else if (selectedTimePeriod === "W") {
                  let date = StringUtils.addDays(selectedDate, 7);
                  return moment(date).format("DD-MM-YYYY");
                }
              })()}
            </span>
          </p>
          <Divider style={{marginTop: "0px"}} />
          <div></div>
        </div>
      </div>
      <TempleNewEventModalOne
        setVisible={setVisible}
        visible={visible}
        loading={loading}
        date={date}
        setDate={setDate}
        to={to}
        setTo={setTo}
        from={from}
        setFrom={setFrom}
        setFullDay={setFullDay}
        fullDay={fullDay}
        announcement={announcement}
        setAnnouncemnet={setAnnouncemnet}
        step={step}
        setStep={setStep}
        block={block}
        setBlock={setBlock}
        blockType={blockType}
        setBlocktype={setBlocktype}
        blockTimeTo={blockTimeTo}
        setBlocktimeTo={setBlocktimeTo}
        blockTimeFrom={blockTimeFrom}
        setBlocktimeFrom={setBlocktimeFrom}
      />
    </div>
  );
};
export default withRouter(TempleEvents);
