import React, { useRef, useContext, useState, useEffect } from "react";
import { Button, Col, Divider, Row, Space, Modal } from "antd";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import "./PrintTicket.scss";
import srisailam_logo from "../../assets/srisailam_logo.png";
import { UserContext } from "../../context/user.context";
import { ShiftContext } from "../../context/shift.context";
import DarshanamTicket from "./DarshanamTicket";
import { AccContext } from "../../context/accommodation.context";
import { routeMapMini } from "../../constants/routeMap";
import { withRouter } from "react-router-dom";
import AccomodationTicket from "./AccomodationTicket";
import DonationTicket from "./DonationTicket";
import PublicationTicket from "./PublicationTicket";
import PrasadamTicket from "./PrasadamTicket";
import BondTicket from "./BondTicket";
import TollGateTicket from "./TollGateTicket";
import SevaTicket from "./SevaTicket";
import KalyanaKattaTicket from "./KalyanaKattaTicket";
import ThulabharamTicket from "./ThulabharamTicket";
import KailasaKankanamTicket from "./KailasaKankanamTicket";

const PrintTicket = ({
  externalPrint,
  setExternalPrint,
  ticketId,
  setData,
  type,
  history,
  name,
  blockName,
  roomNo,
  roomType,
  bookingDate,
  performanceDate,
  checkInTime,
  checkOutTime,
  roomPrice,
  depositAmount,
  totalAmount,
  extraAmount,
  persons,
  contact,
  from,
  setPrintTicketModal,
  couponDisc,
  unPaidDays,
  getCheckoutTable,
  accDashboard,
  booking,
  sevaName,
  abishekam,
  timeSlot,
  startTime,
  endTime,
  amountPaid,
  actualAmount,
  phone,
  proof,
  outstandingAmount,
  setModalVisible,
  state,
  city,
  pincode,
  address,
  calendarType,
  masam,
  paksham,
  tithi,
  setOnlineTicketId,
  publicationName,
  language,
  subscription,
  prasadamItems,
  kankanamItems,
  categoryTransactionId,
  inTheNameOf,
  gothram,
  serialNumber,
  tollGateName,
  numberPlate,
  kalyanakattaItems,
  thulabharamItems,
  onBehalfOf,
  weight,
  setExtraAmount,
}) => {
  const { userDetails } = useContext(UserContext);
  const { shiftContextCounterName } = useContext(ShiftContext);
  const [loading, setLoading] = useState(false);
  const [reBookModal, SetReBookModal] = useState(false);
  const [bondPrintConfirmation, setBondPrintConfirmation] = useState(false);
  const [bondPrintTicket, setBondPrintTicket] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const filterArray = [
    "darshanam",
    "prasadam",
    "tollGate",
    "thulabharam",
    "kalyanakatta",
    "publication",
    "kailasakankanam",
  ];
  let refContainer = useRef(null);

  const { clearAccContext, clearAmount } = useContext(AccContext);

  const handlePrint = useReactToPrint({
    content: () => refContainer.current,
    copyStyles: true,
    bodyClass: "sd-ticket-container",
    // type === "darshanam"
    //   ? "sd-darshanam-ticket-container"
    //   : "sd-ticket-container",
    onAfterPrint: () => {
      if (from === "allocationTable") {
        if (accDashboard) {
          setModalVisible(false);
          getCheckoutTable();
          setPrintTicketModal(false);
        } else {
          // setRenewModal(false);
          setPrintTicketModal(false);
          setData(undefined);
        }
      } else if (from === "ScanTicket") {
        if (booking === "Renew" || booking === "Checkout") {
          setPrintTicketModal(false);
          setData(undefined);
          if (booking === "Renew") {
            setOnlineTicketId(undefined);
          }
          window.location.reload();
        } else {
          setData(undefined);
          if (booking === "Online") {
            setPrintTicketModal(false);
          }
          window.location.reload();
        }
      } else if (from === "Counter") {
        setPrintTicketModal(false);
        if (type === "pratyakshaSeva") {
          setData(undefined);
          setPrintTicketModal(false);
          SetReBookModal(true);
        } else if (type === "darshanam") {
          setData(undefined);
          setExternalPrint(false);
          window.location.reload();
        } else if (type === "publication") {
          setData(undefined);
          history?.push(routeMapMini.Counter);
        } else if (type === "donation") {
          setBondPrintConfirmation(true);
          // window.location.reload();
        } else if (type === "accomodation") {
          clearAmount();
          setExtraAmount(0);
          SetReBookModal(true);
          setPrintTicketModal(false);
        } else if (type === "prasadam") {
          setData(undefined);
          setPrintTicketModal(false);
          window.location.reload();
        } else if (type === "kailasakankanam") {
          setData(undefined);
          setPrintTicketModal(false);
          window.location.reload();
        } else if (type === "tollGate") {
          setData(undefined);
          setPrintTicketModal(false);
          window.location.reload();
        } else if (type === "kalyanakatta") {
          setData(undefined);
          setExternalPrint(false);
          window.location.reload();
        } else if (type === "thulabharam") {
          setData(undefined);
          setExternalPrint(false);
          window.location.reload();
        }
      }
      setButtonLoading(false);
    },
  });

  useEffect(() => {
    console.log(externalPrint);
    if (externalPrint === true) {
      handlePrint();
    }
  }, [externalPrint]);

  const handleFunction = () => {
    setButtonLoading(true);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 200);
    setTimeout(() => {
      handlePrint();
    }, 1000);
  };

  const reBookingAccomodation = () => {
    clearAccContext();
    history?.push(`${routeMapMini.OfflineAccommodation}?clear=true`);
  };

  return (
    <>
      {/* {type !== "prasadam" ? ( */}
      <div style={{ textAlign: "center" }}>
        <Button
          type="primary"
          style={{ margin: "20px" }}
          onClick={() => {
            handleFunction();
          }}
          loading={buttonLoading}
        >
          Print
        </Button>
        {loading ? (
          "Loading"
        ) : (
          <>
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <div
                ref={refContainer}
                // className={
                //   type === "darshanam"
                //     ? "sd-darshanam-ticket-container"
                //     : "sd-ticket-container"
                // }
                className="sd-ticket-container"
                style={{ marginTop: "0", width: "100%", textAlign: "center" }}
              >
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    color: "black",
                    textAlign: "left",
                    padding: "24px 44px",
                    border: "1px solid #f2f2f2",
                  }}
                  className="sd-ticket-card"
                >
                  {(() => {
                    if (type === "accomodation" || type === "donation") {
                      return (
                        <>
                          {categoryTransactionId && type !== "accomodation" && (
                            // <Row
                            //   style={{
                            //     justifyContent: "end",
                            //     fontWeight: "bold",
                            //   }}
                            // >
                            //   {`Sl No : ${categoryTransactionId?.substring(
                            //     categoryTransactionId?.indexOf("-") + 1
                            //   )}`}
                            // </Row>
                            <Row
                              justify="space-between"
                              style={{ fontWeight: "bold" }}
                            >
                              <Col>
                                {" "}
                                {`TI No : ${
                                  categoryTransactionId?.split("-")[2]
                                } -
              ${categoryTransactionId?.split("-")?.pop()}`}
                              </Col>
                              <Col>
                                {`Sl No : 
                            ${serialNumber?.split("-")[3]}`}{" "}
                              </Col>
                            </Row>
                          )}

                          {/* <img
                              className="background right"
                              src={srisailam_logo}
                              alt="logo"
                            /> */}
                          <Row>
                            <img
                              src={srisailam_logo}
                              alt="logo"
                              style={{
                                width: "8%",
                                justifyContent: "center",
                                margin: "auto",
                                filter: "grayscale(1)",
                              }}
                            />
                          </Row>
                          <Row className="tickettitle">
                            <p>
                              Sri Bhramaramba Mallikarjuna Swamy Varla
                              Devasthanam - Srisailam
                            </p>
                          </Row>
                          {/* <Row className="tickettitle">
                              <p>Srisailam</p>
                            </Row> */}
                          {type === "accomodation" && (
                            <Row className="tickettitle">
                              <p>SBMSD GST No. 37AAAJS0198M1ZK</p>
                            </Row>
                          )}
                        </>
                      );
                    }
                  })()}
                  {(() => {
                    if (type === "accomodation") {
                      return (
                        <AccomodationTicket
                          booking={booking}
                          name={name}
                          persons={persons}
                          blockName={blockName}
                          roomType={roomType}
                          roomNo={roomNo}
                          bookingDate={bookingDate}
                          checkInTime={checkInTime}
                          checkOutTime={checkOutTime}
                          roomPrice={roomPrice}
                          depositAmount={depositAmount}
                          couponDisc={couponDisc}
                          extraAmount={extraAmount}
                          unPaidDays={unPaidDays}
                          outstandingAmount={outstandingAmount}
                          totalAmount={totalAmount}
                          contact={contact}
                          ticketId={ticketId}
                        />
                      );
                    } else if (type === "donation") {
                      return (
                        <DonationTicket
                          name={name}
                          sevaName={sevaName}
                          bookingDate={bookingDate}
                          calendarType={calendarType}
                          performanceDate={performanceDate}
                          masam={masam}
                          paksham={paksham}
                          tithi={tithi}
                          amountPaid={amountPaid}
                          actualAmount={actualAmount}
                          address={address}
                          city={city}
                          state={state}
                          pincode={pincode}
                          ticketId={ticketId}
                          inTheNameOf={inTheNameOf}
                        />
                      );
                    } else if (type === "publication") {
                      return (
                        <PublicationTicket
                          publicationName={publicationName}
                          name={name}
                          bookingDate={bookingDate}
                          language={language}
                          subscription={subscription}
                          amountPaid={amountPaid}
                          actualAmount={actualAmount}
                          address={address}
                          city={city}
                          state={state}
                          pincode={pincode}
                          ticketId={ticketId}
                          serialNumber={serialNumber}
                          categoryTransactionId={categoryTransactionId}
                        />
                      );
                    } else if (type === "darshanam") {
                      return (
                        <>
                          <div
                            className={`${
                              from === "Counter"
                                ? "ticket-card-col"
                                : "ticket-card-col ticket-one"
                            }`}
                            style={{
                              paddingRight: `${
                                from === "Counter" ? "0px" : "10px"
                              }`,
                              float: `${from === "Counter" ? "none" : "left"}`,
                              width: `${from === "Counter" ? "100%" : "50%"}`,
                              borderRightStyle: `${
                                from === "Counter" ? "none" : "dashed"
                              }`,
                            }}
                          >
                            <DarshanamTicket
                              from={from}
                              type={type}
                              sevaName={sevaName}
                              abishekam={abishekam}
                              bookingDate={bookingDate}
                              performanceDate={performanceDate}
                              timeSlot={timeSlot}
                              startTime={startTime}
                              endTime={endTime}
                              persons={persons}
                              name={name}
                              amountPaid={amountPaid}
                              actualAmount={actualAmount}
                              phone={phone}
                              proof={proof}
                              ticketId={ticketId}
                              categoryTransactionId={categoryTransactionId}
                              ticketcopy="TEMPLE COPY"
                              serialNumber={serialNumber}
                            />
                          </div>
                          {from !== "Counter" && (
                            <div
                              style={{
                                paddingLeft: "10px",
                                float: "right",
                                width: "50%",
                              }}
                            >
                              <DarshanamTicket
                                from={from}
                                type={type}
                                sevaName={sevaName}
                                abishekam={abishekam}
                                bookingDate={bookingDate}
                                performanceDate={performanceDate}
                                timeSlot={timeSlot}
                                startTime={startTime}
                                endTime={endTime}
                                persons={persons}
                                name={name}
                                amountPaid={amountPaid}
                                actualAmount={actualAmount}
                                phone={phone}
                                proof={proof}
                                ticketId={ticketId}
                                categoryTransactionId={categoryTransactionId}
                                ticketcopy="DEVOTEE COPY"
                                serialNumber={serialNumber}
                              />
                            </div>
                          )}
                        </>
                      );
                    } else if (type === "pratyakshaSeva") {
                      return (
                        <>
                          <Col className="ticket-card-col ticket-one">
                            <SevaTicket
                              from={from}
                              type={type}
                              sevaName={sevaName}
                              abishekam={abishekam}
                              bookingDate={bookingDate}
                              performanceDate={performanceDate}
                              timeSlot={timeSlot}
                              startTime={startTime}
                              endTime={endTime}
                              persons={persons}
                              name={name}
                              amountPaid={amountPaid}
                              actualAmount={actualAmount}
                              phone={phone}
                              proof={proof}
                              ticketId={ticketId}
                              categoryTransactionId={categoryTransactionId}
                              ticketcopy="TEMPLE COPY"
                              serialNumber={serialNumber}
                            />
                          </Col>
                          <Col className="ticket-card-col ticket-two">
                            <SevaTicket
                              from={from}
                              type={type}
                              sevaName={sevaName}
                              abishekam={abishekam}
                              bookingDate={bookingDate}
                              performanceDate={performanceDate}
                              timeSlot={timeSlot}
                              startTime={startTime}
                              endTime={endTime}
                              persons={persons}
                              name={name}
                              amountPaid={amountPaid}
                              actualAmount={actualAmount}
                              phone={phone}
                              proof={proof}
                              ticketId={ticketId}
                              categoryTransactionId={categoryTransactionId}
                              ticketcopy="DEVOTEE COPY"
                              serialNumber={serialNumber}
                            />
                          </Col>
                        </>
                      );
                    } else if (type === "prasadam") {
                      return (
                        <PrasadamTicket
                          bookingDate={bookingDate}
                          ticketId={ticketId}
                          prasadamItems={prasadamItems}
                          totalAmount={totalAmount}
                          categoryTransactionId={categoryTransactionId}
                          serialNumber={serialNumber}
                        />
                      );
                    } else if (type === "kailasakankanam") {
                      return (
                        <KailasaKankanamTicket
                          bookingDate={bookingDate}
                          ticketId={ticketId}
                          kankanamItems={kankanamItems}
                          totalAmount={totalAmount}
                          categoryTransactionId={categoryTransactionId}
                          serialNumber={serialNumber}
                        />
                      );
                    } else if (type === "tollGate") {
                      return (
                        <TollGateTicket
                          categoryTransactionId={categoryTransactionId}
                          serialNumber={serialNumber}
                          tollGateName={tollGateName}
                          numberPlate={numberPlate}
                          amountPaid={amountPaid}
                          ticketId={ticketId}
                        />
                      );
                    } else if (type === "kalyanakatta") {
                      return (
                        <KalyanaKattaTicket
                          bookingDate={bookingDate}
                          ticketId={ticketId}
                          kalyanakattaItems={kalyanakattaItems}
                          totalAmount={totalAmount}
                          categoryTransactionId={categoryTransactionId}
                          serialNumber={serialNumber}
                        />
                      );
                    } else if (type === "thulabharam") {
                      return (
                        <ThulabharamTicket
                          bookingDate={bookingDate}
                          ticketId={ticketId}
                          thulabharamItems={thulabharamItems}
                          totalAmount={totalAmount}
                          categoryTransactionId={categoryTransactionId}
                          name={name}
                          phone={phone}
                          onBehalfOf={onBehalfOf}
                          weight={weight}
                          serialNumber={serialNumber}
                        />
                      );
                    }
                  })()}
                  <Divider dashed />
                  {type === "pratyakshaSeva" && (
                    <Row
                      style={{
                        fontSize: "13px",
                        paddingBottom: "10px",
                      }}
                    >
                      <Col style={{ width: "9%", fontWeight: "bold" }}>
                        Note :{" "}
                      </Col>
                      <Col style={{ width: "91%" }}>
                        <p>
                          1. Sevadaris must wear Traditional Clothes. Pancha &
                          Kanduva for Male and Saree for Female. No other wear
                          is allowed.
                          <br />
                          2. Kindly carry your Aadhar card Xerox or Original in
                          the Darshanam Queue.
                          <br />
                          {sevaName.toLowerCase() !== "vip break darshanam" && (
                            <>3. Couple - (Husband & Wife)</>
                          )}
                        </p>
                      </Col>
                    </Row>
                  )}
                  {type === "darshanam" && (
                    <div
                      style={{
                        fontSize: "13px",
                        paddingBottom: "10px",
                      }}
                    >
                      <div style={{ width: "12%", fontWeight: "bold" }}>
                        Note :{" "}
                      </div>
                      <div style={{ width: "88%" }}>
                        <p>
                          1. Kindly carry your Aadhar card Xerox or Original in
                          the Darshanam Queue.
                        </p>
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: "grid",
                      fontSize: "10px",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ width: "100%", display: "inline-flex" }}>
                      <p style={{ marginRight: "10px", marginBottom: "0" }}>
                        User :
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          textTransform: "capitalize",
                          marginBottom: "0",
                          marginRight: "10px",
                        }}
                      >
                        {userDetails.display_name ?? userDetails.phone}
                      </p>
                      <p style={{ marginRight: "10px", marginBottom: "0" }}>
                        Counter :
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          textTransform: "capitalize",
                          marginBottom: "0",
                        }}
                      >
                        {shiftContextCounterName}
                      </p>
                      {!filterArray?.includes(type) && (
                        <>
                          <p
                            style={{
                              marginLeft: "10px",
                              marginRight: "10px",
                            }}
                          >
                            Time :
                          </p>
                          <p style={{ fontWeight: "bold" }}>
                            {moment()
                              .utcOffset("+05:30")
                              .format("DD-MM-YYYY hh:mm A")}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  {(() => {
                    if (filterArray?.includes(type)) {
                      return (
                        <div
                          style={{
                            justifyContent: "center",
                            margin: "auto",
                            width: "100%",
                            display: "flex",
                            fontSize: "10px",
                          }}
                        >
                          <p style={{ marginRight: "10px" }}>Time :</p>
                          <p style={{ fontWeight: "bold" }}>
                            {moment()
                              .utcOffset("+05:30")
                              .format("DD-MM-YYYY hh:mm:ss A")}
                          </p>
                        </div>
                      );
                    }
                  })()}
                </div>
              </div>
            </div>
          </>
        )}
        <Modal
          visible={reBookModal}
          width={850}
          footer={null}
          closable={false}
          centered
        >
          <>
            <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
              {type === "pratyakshaSeva"
                ? `Do You Want To Book Another Seva ?`
                : `Do You Want To Book Another Room ?`}
            </Row>
            <Row style={{ justifyContent: "center", margin: "auto" }}>
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    type === "pratyakshaSeva"
                      ? SetReBookModal(false)
                      : history.push(routeMapMini.OfflineAccommodation);
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    type === "pratyakshaSeva"
                      ? window.location.reload()
                      : reBookingAccomodation();
                  }}
                >
                  No
                </Button>
              </Space>
            </Row>
          </>
        </Modal>
        <Modal
          visible={bondPrintConfirmation}
          width={850}
          footer={null}
          closable={false}
          centered
        >
          <>
            <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
              {`Do You Want To Print Bond ?`}
            </Row>
            <Row style={{ justifyContent: "center", margin: "auto" }}>
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    setBondPrintConfirmation(false);
                    setBondPrintTicket(true);
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    setData(undefined);
                    history?.push(routeMapMini.Counter);
                  }}
                >
                  No
                </Button>
              </Space>
            </Row>
          </>
        </Modal>
        <Modal
          visible={bondPrintTicket}
          width={850}
          footer={null}
          closable={false}
          centered
        >
          <BondTicket
            name={name}
            sevaName={sevaName}
            bookingDate={bookingDate}
            calendarType={calendarType}
            performanceDate={performanceDate}
            masam={masam}
            paksham={paksham}
            tithi={tithi}
            amountPaid={amountPaid}
            actualAmount={actualAmount}
            address={address}
            city={city}
            state={state}
            pincode={pincode}
            categoryTransactionId={`${
              categoryTransactionId?.split("-")[2]
            }-${categoryTransactionId?.split("-")?.pop()}`}
            serialNumber={`${serialNumber?.split("-")[2]}-${serialNumber
              ?.split("-")
              ?.pop()}`}
            setBondPrintTicket={setBondPrintTicket}
            inTheNameOf={inTheNameOf}
            gothram={gothram}
            ticketId={ticketId}
            contact={contact}
          />
        </Modal>
      </div>
    </>
  );
};

export default withRouter(PrintTicket);
