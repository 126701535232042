/* eslint-disable react/jsx-pascal-case */
import {Button, Col, DatePicker, notification, Row, Select} from "antd";
import Modal from "antd/lib/modal/Modal";
import {useContext, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {routeMapMini} from "../../../constants/routeMap";
import {UserContext} from "../../../context/user.context";
import CounterService from "../../../Services/Counter.service";
import UserService from "../../../Services/User.service";
import CounterShiftManagemnetTable from "../CounterShiftManagement/CounterShiftManagemnetTable";
import moment from "moment";
// import {Label} from "recharts";
// import RoomService from "../../../Services/rooms.service";
const {Option} = Select;
const CounterShiftManagementPage = ({history, location}) => {
  const [loading, setLoading] = useState();
  const [showModal, setShowModal] = useState(false);
  const [blockID, setBlockID] = useState("");
  const {userDetails} = useContext(UserContext);
  const [list, setList] = useState([]);
  const [counterList, setCounterList] = useState([]);
  const [updateTable, setUpdateTable] = useState("none");
  const [selectedShift, setSelectedShift] = useState({});
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [edit, setEdit] = useState(false);
  const [temp, setTemp] = useState(false);
  const [from, setFrom] = useState(moment().format("YYYY-MM-DD"));
  const [to, setTo] = useState(moment().add(1, "d"), moment());
  useEffect(() => {
    getCountersShifts();
  }, []);

  useEffect(() => {
    loadShifts();
  }, [updateTable]);

  useEffect(() => {
    if (edit) {
      setShowModal(true);
      let temps = counterList?.find((item) => item.id === temp.sd_shift_id);
      setSelectedShift(temps);
      let e = users.find((e) => e.id === temp.assigned_to_user_id);
      setSelectedUser(e);
    }
  }, [edit]);

  const loadShifts = async () => {
    try {
      setLoading(true);
      const counters = await CounterService.getAllShifts();
      const users = await UserService.managementUsers();
      setCounterList(counters);
      setUsers(users);
      setLoading(false);
    } catch (error) {
      if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
      setLoading(false);
    }
  };

  const getCountersShifts = async () => {
    setLoading(true);
    try {
      const id = new URLSearchParams(location.search).get("CounterId");
      setBlockID(id);
      const resp = await CounterService.getCounterPreShifts(id);
      setList(resp);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
  };

  const handleOk = async () => {
    if (selectedShift === {}) {
      return notification.error({message: `Select a Shift`});
    }
    if (selectedUser === {}) {
      return notification.error({message: `Select a Shift`});
    }
    if (to === "" || from === "") {
      return notification.error({message: `Select a valid Start and End date`});
    }
    setLoading(true);
    try {
      // if (edit) {
      //   await CounterService.editCounterShift(
      //     temp.id,
      //     blockID,
      //     selectedShift.id,
      //     selectedUser.id
      //   );
      //   notification.success({
      //     message: `Shift ${selectedShift.name} has been assigned to ${selectedUser.display_name} to Counter ID: ${blockID}`,
      //   });
      // } else {
      let body = {
        assignToUserId: selectedUser.id,
        counterId: blockID,
        shiftId: selectedShift.id,
        start_date: moment(from).format("YYYY-MM-DD"),
        end_date: moment(to).format("YYYY-MM-DD"),
      };
      await CounterService.addCounterPreShift(body);
      notification.success({
        message: `Pre-Shift ${selectedShift.name} has been assigned to ${selectedUser.display_name} to Counter ID: ${blockID}`,
      });
      // }
      setUpdateTable(blockID.id + Math.random(1000));
      setSelectedShift({});
      setSelectedUser({});
      setTemp({});
      getCountersShifts();
      setShowModal(false);
      setEdit(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setSelectedShift({});
    setSelectedUser({});

    // setblockID(undefined);
  };

  const handleCancel = () => {
    clearFields();
    setEdit(false);
    setShowModal(false);
  };

  return (
    <div style={{width: "100%"}}>
      <Row style={{width: "100%", marginBottom: "15px"}} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setUpdateTable(Math.random(100));
              setShowModal(true);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Add New Pre-Shift
          </Button>
        </Col>
      </Row>
      <Row style={{width: "100%"}}>
        <CounterShiftManagemnetTable
          list={list}
          blockID={blockID}
          loading={loading}
          setLoading={setLoading}
          setUpdateTable={setUpdateTable}
          getCountersShifts={getCountersShifts}
          setEdit={setEdit}
          setTemp={setTemp}
          history={history}
        />
      </Row>

      <Modal
        visible={showModal}
        title={`Add New Shift to Counter ${new URLSearchParams(
          location.search
        ).get("counterName")}`}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify="center" style={{width: "100%", paddingTop: "10px"}}>
          Counter Name :{" "}
          {new URLSearchParams(location.search).get("counterName")}
        </Row>
        <Row
          justify="center"
          style={{
            width: "100%",
            padding: "10px 0px 0px 0px",
            flexDirection: "column",
          }}
        >
          <div style={{marginBottom: "5px"}}>Select a Shift</div>

          <Select
            width="100%"
            style={{width: "100%"}}
            value={selectedShift?.id}
            placeholder="Please select a Shift"
            onChange={(e) => {
              let temp = counterList?.find((item) => item.id === e);
              setSelectedShift(temp);
            }}
          >
            {counterList?.map((item, i) => {
              return (
                <Option key={`${item.name}-${i}-counter-list`} value={item.id}>
                  {item.name}-(
                  {moment(item?.start, "HH:mm:sss").format("hh:mm A")} -
                  {moment(item?.end, "HH:mm:sss").format("hh:mm A")})
                </Option>
              );
            })}
          </Select>
        </Row>

        <Row
          justify="center"
          style={{width: "100%", padding: "10px 0px", flexDirection: "column"}}
        >
          <div style={{marginBottom: "5px"}}>Select a user to assign shift</div>

          <Select
            width="100%"
            style={{width: "100%"}}
            value={selectedUser?.display_name}
            placeholder="Please select a User to assign "
            onChange={(e) => {
              let temp = users?.find((item) => item.id === e);
              setSelectedUser(temp);
            }}
          >
            {users?.map((item,i) => {
              if (!item?.id || !item?.display_name) {
                return;
              }
              return (
                <Option key={item.display_name + `${i}-display_name`} value={item.id}>
                  {item.display_name}
                </Option>
              );
            })}
          </Select>
        </Row>
        <Row justify="start" style={{flexDirection: "column"}}>
          <div style={{marginBottom: "5px"}}>Select Duration of Shift</div>

          <DatePicker.RangePicker
            format="DD-MM-YYYY"
            onChange={(e) => {
              if (e?.[0] && e?.[1]) {
                setFrom(moment(e[0]).format(`YYYY-MM-DD`));
                setTo(moment(e[1]).format(`YYYY-MM-DD`));
              } else {
                setFrom(undefined);
                setTo(undefined);
              }
            }}
            ranges={{
              Today: [moment(), moment()],
              "Last 7 Days": [moment().add(7, "d"), moment()],
              "Last 15 Days": [moment().add(15, "d"), moment()],
            }}
            defaultValue={[moment(from), moment(to)]}
            defaultPickerValue={[moment(from), moment(to)]}
          />
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(CounterShiftManagementPage);
