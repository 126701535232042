import React from "react";
import numberToWords from "../../utils/amount-to-words/number-to-words";
import "./PrintTicket.scss";
import srisailam_logo from "../../assets/srisailam_logo.png";
import QRCode from "qrcode.react";
import moment from "moment";

const TollGateTicket = ({
  categoryTransactionId,
  serialNumber,
  tollGateName,
  numberPlate,
  amountPaid,
  ticketId,
}) => {
  return (
    <div>
      <div
        style={{
          fontWeight: "bold",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          {" "}
          {`TI No : ${categoryTransactionId?.split("-")[2]} -
        ${categoryTransactionId?.split("-")?.pop()}`}
        </div>
        <div>{`Sl No : ${serialNumber?.split("-")[1]}`} </div>
      </div>
      <div style={{display: "flex"}}>
        <img
          src={srisailam_logo}
          alt="logo"
          style={{
            width: "20%",
            justifyContent: "center",
            margin: "auto",
            filter: "grayscale(1)",
          }}
        />
      </div>
      <div
        style={{
          justifyContent: "center",
          margin: "0",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        <p style={{margin: "0"}}>
          Sri Bhramaramba Mallikarjuna Swamy Varla Devasthanam - Srisailam
        </p>
      </div>
      <div
        style={{
          justifyContent: "center",
          margin: "0",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        <p style={{margin: "0"}}>TOLL GATE TICKET</p>
      </div>
      <div style={{marginBottom: "10px"}}>
        {ticketId && (
          <div style={{display: "flex"}}>
            <div
              style={{
                width: "40%",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              <p style={{margin: "0"}}>Ticket Id</p>
            </div>
            <div
              style={{
                width: "60%",
                overflowWrap: "break-word",
                textTransform: "capitalize",
                display: "inline-flex",
              }}
            >
              <p style={{paddingRight: "10px", margin: "0"}}>:</p>
              <p style={{margin: "0"}}>
                {ticketId ? ticketId : "transactionId=127"}
              </p>
            </div>
          </div>
        )}
        <div style={{display: "flex"}}>
          <div
            style={{
              width: "40%",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            <p style={{margin: "0"}}>Booking Date</p>
          </div>
          <div
            style={{
              width: "60%",
              overflowWrap: "break-word",
              textTransform: "capitalize",
              display: "inline-flex",
            }}
          >
            <p style={{paddingRight: "10px", margin: "0"}}>:</p>
            <p style={{margin: "0"}}>{moment().format("DD-MM-YYYY HH:MM A")}</p>
          </div>
        </div>
        <div style={{display: "flex"}}>
          <div
            style={{
              width: "40%",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            <p style={{margin: "0"}}>Vehicle Type</p>
          </div>
          <div
            style={{
              width: "60%",
              overflowWrap: "break-word",
              textTransform: "capitalize",
              display: "inline-flex",
            }}
          >
            <p style={{paddingRight: "10px", margin: "0"}}>:</p>
            <p style={{margin: "0"}}>{tollGateName}</p>
          </div>
        </div>
        <div style={{display: "flex"}}>
          <div
            style={{
              width: "40%",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            <p style={{margin: "0"}}>Vehicle Number</p>
          </div>
          <div
            style={{
              width: "60%",
              overflowWrap: "break-word",
              textTransform: "capitalize",
              display: "inline-flex",
            }}
          >
            <p style={{paddingRight: "10px", margin: "0"}}>:</p>
            <p style={{textTransform: "uppercase", margin: "0"}}>
              {numberPlate}
            </p>
          </div>
        </div>
        <div style={{display: "flex"}}>
          <div
            style={{
              width: "40%",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            <p style={{margin: "0"}}>Validity </p>
          </div>
          <div
            style={{
              width: "60%",
              overflowWrap: "break-word",
              textTransform: "capitalize",
              display: "inline-flex",
            }}
          >
            <p style={{paddingRight: "10px", margin: "0"}}>:</p>
            <p style={{textTransform: "uppercase", margin: "0"}}>24 Hrs.</p>
          </div>
        </div>
        {amountPaid && (
          <div style={{display: "flex"}}>
            <div
              style={{
                width: "40%",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              <p style={{margin: "0"}}>Amount</p>
            </div>
            <div
              style={{
                width: "60%",
                overflowWrap: "break-word",
                textTransform: "capitalize",
                display: "inline-flex",
              }}
            >
              <p style={{paddingRight: "10px", margin: "0"}}>:</p>
              <p style={{margin: "0", fontWeight: "800", fontSize: "22px"}}>
                Rs. {amountPaid}.00
                <>
                  <br />
                  <div style={{fontSize: "13px"}}>
                    ({numberToWords(amountPaid)} Rupees)
                  </div>
                </>
              </p>
            </div>
          </div>
        )}
      </div>
      {ticketId && (
        <div
          style={{
            justifyContent: "center",
            margin: "auto",
            display: "flex",
          }}
        >
          {" "}
          <QRCode
            size="75"
            fontSize="15px"
            value={ticketId ? ticketId : "transactionId=127"}
          />
        </div>
      )}
    </div>
  );
};

export default TollGateTicket;
