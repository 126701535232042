/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect, useContext} from "react";
import {withRouter} from "react-router";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Divider,
  Button,
  notification,
  Modal,
  Space,
} from "antd";
import {routeMapMini} from "../../../constants/routeMap";
import "./OfflineAccommodation.css";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import {AccContext} from "../../../context/accommodation.context";
import {FormatNumberINRCurrency} from "../../../utils/currencyFormat";
import CounterService from "../../../Services/Counter.service";

const UserForm = ({history}) => {
  const {
    country,
    setCountry,
    docNumber,
    setDocNumber,
    firstName,
    setFirstName,
    gender,
    setGender,
    phone,
    setPhone,
    email,
    setEmail,
    address,
    setAddress,
    city,
    setCity,
    state,
    setState,
    zip,
    setZip,
    docType,
    setDockType,
    price,
    deposite,
    extraAmount,
    setExtraAmount,
    clearAccContext,
    age,
    setAge,
    couponData,
    couponDisc,
    setCouponDisc,
    coupon,
    setCoupon,
    donorType,
    setDonorType,
    setCouponData,
  } = useContext(AccContext);

  const [form] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <p style={{margin: "0"}}>+91</p>
    </Form.Item>
  );

  const [err, setErr] = useState([]);
  const onReset = () => {
    clearAccContext();
    form.resetFields();
  };

  useEffect(() => {
    if (!price) {
      history.push(routeMapMini.OfflineAccommodation);
    }
  });

  useEffect(() => {
    if (price > 0 && price <= 7500) {
      if (couponDisc !== 0 && couponData) {
        setExtraAmount(Math.round(Number(price - couponDisc) * 0.12));
      } else {
        setExtraAmount(Math.round(Number(price) * 0.12));
      }
    } else if (price > 7500) {
      if (couponDisc !== 0 && couponData) {
        setExtraAmount(Math.round(Number(price - couponDisc) * 0.18));
      } else {
        setExtraAmount(Math.round(Number(price) * 0.18));
      }
    }
  }, [couponDisc]);

  const getCouponAmount = (e) => {
    if (e === "owner") {
      let discount = couponData.max_offer_percentage;
      console.log(discount);
      let disc = price / 100;
      console.log(disc);
      setCouponDisc((price / 100) * discount);
    } else if (e === "relative") {
      let discount = couponData.max_offer_percentage / 2;
      setCouponDisc((price / 100) * discount);
    }
  };

  const verifyCoupon = async () => {
    if (coupon === "") {
      notification?.error({message: "Please enter coupon"});
      return;
    }

    try {
      setCouponData("");
      setCouponDisc(0);
      setDonorType("");

      const respData = await CounterService.verifyCoupon(coupon);
      setCouponData(respData);
      notification.success({message: "Coupon is valid"});
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        setCoupon("");

        notification.error({message: error.response?.data?.message});
      } else {
        setCoupon("");
        notification.error({message: error.message});
      }
    }
  };

  const verifyButton = (
    <Form.Item noStyle>
      <p
        style={{
          margin: "0",
          cursor: "pointer",
          color: couponData === "" ? "black" : "green",
        }}
        onClick={() => {
          if (couponData === "") {
            verifyCoupon();
          }
        }}
      >
        {couponData === "" ? "Verify" : "Verified"}
      </p>
    </Form.Item>
  );

  return (
    <>
      <Form
        colon={false}
        form={form}
        onFinish={() => setIsModalVisible(true)}
        style={{
          justifyContent: "center",
          margin: "auto",
          width: "100%",
          textAlign: "left",
          maxWidth: "1000px",
        }}
        scrollToFirstError
        initialValues={{
          firstName: firstName,
          gender: gender,
          age: age,
          mobileNumber: phone,
          emailAddress: email,
          address1: address,
          country: country,
          state: state,
          city: city,
          zipcode: zip,
          photoIDProof: docType,
          photoIDNumber: docNumber,
        }}
      >
        <Row>
          <h1
            style={{
              textAlign: "left",
              fontWeight: "bold",
              marginBottom: "0px",
            }}
          >
            Devotee Details
          </h1>
          <Divider />
        </Row>
        <Row gutter={[10, 10]}>
          <Col style={{width: "50%"}}>
            <Form.Item
              name="firstName"
              label="Devotee Name"
              rules={[{required: true, message: "Please Enter Devotee Name!"}]}
            >
              <Input
                size="large"
                type="text"
                placeholder="Please Enter Devotee Name"
                value={firstName}
                maxLength="30"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col style={{width: "50%"}}>
            <Form.Item
              name="age"
              label="Age"
              rules={[
                {
                  required: true,
                  message: "Please Enter Age",
                },
                {
                  validator(_, value) {
                    if (value && value < 17) {
                      return Promise.reject("Minimum age of 18 is mandatory!");
                    } else return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                size="large"
                type="number"
                onWheel={(event) => event.currentTarget.blur()}
                value={age}
                placeholder="Please Enter Age"
                maxLength="30"
                onChange={(e) => {
                  setAge(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col style={{width: "50%"}}>
            <Form.Item
              name="gender"
              label="Gender"
              rules={[{required: true, message: "Please Select Gender"}]}
            >
              <Select
                placeholder="Please Select Gender"
                size="large"
                value={gender}
                allowClear
                onChange={setGender}
              >
                <Select.Option key="male" value="male">
                  Male
                </Select.Option>
                <Select.Option key="female" value="female">
                  Female
                </Select.Option>
                <Select.Option key="other" value="other">
                  Other
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col style={{width: "50%"}}>
            <Form.Item
              name="mobileNumber"
              label="Mobile Number"
              rules={[
                {required: true, message: "Please Enter Mobile Number!"},
                {
                  validator(_, value) {
                    if (!value || value.length === 10) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "Please enter 10 digit Mobile Number!"
                    );
                  },
                },
              ]}
            >
              <Input
                size="large"
                type="number"
                onWheel={(event) => event.currentTarget.blur()}
                maxLength={10}
                addonBefore={prefixSelector}
                placeholder="Please Enter Mobile Number"
                style={{width: "100%"}}
                value={phone}
                onChange={(e) => {
                  setPhone(`${e.target.value}`);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col style={{width: "50%"}}>
            <Form.Item name="emailAddress" label="Email Address">
              <Input
                size="large"
                type="email"
                maxLength="30"
                style={{textTransform: "none"}}
                placeholder="Please Enter a Valid Email Id"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col style={{width: "50%"}}>
            <Form.Item
              name="address1"
              label="Address 1"
              rules={[
                {
                  required: true,
                  message: "Please Enter Address 1",
                },
              ]}
            >
              <Input
                size="large"
                maxLength="50"
                type="text"
                placeholder="House/building number, street number"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col style={{width: "50%", color: "black"}}>
            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: "Please Select Country",
                },
              ]}
            >
              <CountryDropdown
                className="CountryRegionDropDown"
                value={country}
                onChange={(val) => {
                  if (
                    err.find((e) => e.type === "country" || e.type === "common")
                  ) {
                    // eslint-disable-next-line array-callback-return
                    let data = err.filter((e) => {
                      if (e.type !== "country" && e.type !== "common") {
                        return e;
                      }
                    });
                    setErr(data);
                  }
                  setCountry(val);
                }}
              />
            </Form.Item>
          </Col>
          <Col style={{width: "50%", color: "black"}}>
            <Form.Item
              name="state"
              label="State"
              rules={[
                {
                  required: true,
                  message: "Please Select State",
                },
              ]}
            >
              <RegionDropdown
                className="CountryRegionDropDown"
                country={country}
                value={state}
                showDefaultOption={true}
                blankOptionLabel="Select a State"
                onChange={(val) => {
                  if (
                    err.find((e) => e.type === "state" || e.type === "common")
                  ) {
                    // eslint-disable-next-line array-callback-return
                    let data = err.filter((e) => {
                      if (e.type !== "state" && e.type !== "common") {
                        return e;
                      }
                    });
                    setErr(data);
                  }
                  setState(val);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col style={{width: "50%"}}>
            <Form.Item
              name="city"
              label="City"
              rules={[
                {
                  required: true,
                  message: "Please Enter City",
                },
              ]}
            >
              <Input
                size="large"
                type="text"
                value={city}
                placeholder="Please Enter City"
                maxLength="30"
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col style={{width: "50%"}}>
            <Form.Item
              name="zipcode"
              label="Zipcode"
              rules={[
                {
                  required: true,
                  message: "Please Enter Zipcode",
                },
                {
                  validator(_, value) {
                    if (!value || value.length === 6) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Please enter 6 digit Zipcode!");
                  },
                },
              ]}
            >
              <Input
                size="large"
                type="number"
                onWheel={(event) => event.currentTarget.blur()}
                placeholder="Please Enter Zip/Pincode"
                value={zip}
                maxLength={12}
                onChange={(e) => {
                  if (e.target.value.length < 12) {
                    setZip(e.target.value);
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <h1
            style={{
              textAlign: "left",
              fontWeight: "bold",
              marginBottom: "0px",
            }}
          >
            ID Proof Details
          </h1>
          <Divider />
        </Row>
        <Row gutter={[10, 10]}>
          <Col style={{width: "50%"}}>
            <Form.Item
              name="photoIDProof"
              label="Photo ID Proof"
              rules={[{required: true, message: "Select Photo ID Proof"}]}
            >
              <Select
                placeholder="Select Photo ID Proof"
                size="large"
                allowClear
                onChange={setDockType}
              >
                <Select.Option key="pan" value="pan">
                  PAN
                </Select.Option>
                <Select.Option key="aadhar" value="aadhar">
                  AADHAR
                </Select.Option>
                <Select.Option key="driving" value="driving">
                  Driving License
                </Select.Option>

                <Select.Option key="voter" value="voter">
                  Voter ID
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col style={{width: "50%"}}>
            <Form.Item
              name="photoIDNumber"
              label="Photo ID Number"
              rules={[
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (getFieldValue("photoIDProof") === "pan") {
                      let txt = value.toUpperCase();
                      let regex =
                        /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
                      let pan = {
                        C: "Company",
                        P: "Personal",
                        H: "Hindu Undivided Family (HUF)",
                        F: "Firm",
                        A: "Association of Persons (AOP)",
                        T: "AOP (Trust)",
                        B: "Body of Individuals (BOI)",
                        L: "Local Authority",
                        J: "Artificial Juridical Person",
                        G: "Govt",
                      };
                      pan = pan[txt[3]];
                      if (!regex.test(value.toUpperCase())) {
                        return Promise.reject(new Error("Invalid PAN Number!"));
                      } else {
                        if (pan !== "Personal") {
                          return Promise.reject(
                            new Error("It is not a Personal PAN Number!")
                          );
                        } else {
                          return Promise.resolve();
                        }
                      }
                    }
                    if (getFieldValue("photoIDProof") === "aadhar") {
                      let regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
                      if (!regex.test(value.toUpperCase())) {
                        return Promise.reject(
                          new Error("Not a valid Aadhar Card!")
                        );
                      } else return Promise.resolve();
                    }
                    if (getFieldValue("photoIDProof") === "driving") {
                      let regex =
                        /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
                      if (!regex.test(value?.toUpperCase())) {
                        return Promise.reject(
                          new Error("Not a valid Driving License!")
                        );
                      } else return Promise.resolve();
                    }

                    if (getFieldValue("photoIDProof") === "voter") {
                      let regex = /^([a-zA-Z]){3}([0-9]){7}?$/;
                      if (!regex.test(value.toUpperCase())) {
                        return Promise.reject(
                          new Error("Not a valid Voter ID!")
                        );
                      } else return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Please enter a Photo ID Number!")
                    );
                  },
                }),
              ]}
            >
              <Input
                size="large"
                type="text"
                maxLength="30"
                placeholder="Please Enter Valid Photo ID Number"
                style={{paddingLeft: "15px", textTransform: "none"}}
                value={docNumber}
                onChange={(e) => {
                  setDocNumber(
                    e.target.value?.toUpperCase()?.replaceAll?.(" ", "")
                  );
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <h1
            style={{
              textAlign: "left",
              fontWeight: "bold",
              marginBottom: "0px",
            }}
          >
            Coupon
          </h1>
          <Divider />
        </Row>
        <Row gutter={[10, 10]}>
          <Col style={{width: "50%"}}>
            <div style={{marginBottom: "5px"}}>Coupon</div>
            <div name="Coupon" label="Coupon">
              <Input
                size="large"
                type="text"
                placeholder="Please Enter Coupon"
                addonAfter={verifyButton}
                value={coupon}
                defaultValue={coupon}
                maxLength="30"
                allowClear
                onChange={(e) => {
                  setCoupon(e.target.value);
                  setCouponData("");
                  setCouponDisc("");
                  setDonorType("");
                }}
              />
            </div>
          </Col>
          {couponData !== "" ? (
            <Col style={{width: "50%"}}>
              <div style={{marginBottom: "5px"}}>Donor</div>
              <div name="Donor" label="Donor">
                <Select
                  style={{width: "100%"}}
                  placeholder="Select Coupon Type"
                  size="large"
                  allowClear
                  value={donorType}
                  defaultValue={donorType}
                  onChange={(e) => {
                    setDonorType(e);
                    getCouponAmount(e);
                  }}
                >
                  <Select.Option key="owner" value="owner">
                    Owner
                  </Select.Option>
                  <Select.Option key="relative" value="relative">
                    Relative
                  </Select.Option>
                </Select>
              </div>
            </Col>
          ) : (
            ""
          )}
          {donorType !== "" && couponDisc && couponData !== "" ? (
            <div style={{marginLeft: "5px", zIndex: "2"}}>
              Coupon Code{" "}
              <span
                style={{
                  fontWeight: "600",
                  marginLeft: "3px",
                  marginRight: "3px",
                }}
              >
                {coupon} has been applied for
                {FormatNumberINRCurrency(couponDisc)}{" "}
              </span>{" "}
              <button
                style={{
                  fontWeight: "600",
                  marginLeft: "3px",
                  marginRight: "3px",
                  color: "red",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  console.log("click");
                  setCouponData("");
                  setDonorType("");
                  setCouponDisc(0);
                  setCoupon("");
                }}
              >
                Remove
              </button>
            </div>
          ) : (
            ""
          )}
        </Row>
        <Divider />

        {price && (
          <>
            <Row>
              <h1
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  marginBottom: "0px",
                }}
              >
                Payment Details
              </h1>

              <Divider />
            </Row>
            <Row>
              <Col style={{width: "25%"}}>Room Price :</Col>
              <Col style={{width: "75%", textAlign: "right"}}>
                {FormatNumberINRCurrency(price)}
              </Col>
            </Row>
            <Row>
              <Col style={{width: "25%"}}>Deposit Amount :</Col>
              <Col style={{width: "75%", textAlign: "right"}}>
                {FormatNumberINRCurrency(deposite)}
              </Col>
            </Row>
            {couponData && donorType !== "" ? (
              <Row>
                <Col style={{width: "25%"}}>Coupon Discount :</Col>
                <Col style={{width: "75%", textAlign: "right"}}>
                  {FormatNumberINRCurrency(couponDisc)}
                </Col>
              </Row>
            ) : (
              ""
            )}
            {price > 0 && (
              <Row>
                <Col style={{width: "25%"}}>GST Amount :</Col>
                <Col style={{width: "75%", textAlign: "right"}}>
                  {FormatNumberINRCurrency(extraAmount)}
                </Col>
              </Row>
            )}
            <Row>
              <Col style={{width: "25%", fontWeight: "bold", fontSize: "25px"}}>
                Total Amount :
              </Col>
              <Col style={{width: "75%", textAlign: "right", fontSize: "25px"}}>
                {console.log(price)}
                {console.log(deposite)}
                {console.log(extraAmount)}
                {console.log(couponDisc)}
                {price > 0
                  ? FormatNumberINRCurrency(
                      price + deposite + extraAmount - couponDisc
                    )
                  : FormatNumberINRCurrency(price + deposite - couponDisc)}
              </Col>
            </Row>
          </>
        )}
        <Row gutter={[10, 10]} style={{marginTop: "20px"}}>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        visible={isModalVisible}
        width={850}
        footer={null}
        closable={false}
        centered
      >
        <>
          <Row style={{justifyContent: "center", marginBottom: "10px"}}>
            Deposit Amount Received ?
          </Row>
          <Row style={{justifyContent: "center", margin: "auto"}}>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  history.push(routeMapMini.RoomList);
                }}
              >
                Yes
              </Button>
              <Button onClick={() => setIsModalVisible(false)}>No</Button>
            </Space>
          </Row>
        </>
      </Modal>
    </>
  );
};

export default withRouter(UserForm);
