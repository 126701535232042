import {
  Button,
  Card,
  Col,
  DatePicker,
  Row,
  Space,
  Statistic,
  Table,
  Modal,
  Tabs,
} from "antd";
import { useState, useEffect } from "react";
import { withRouter } from "react-router";
import moment from "moment";
import ReportService from "../../../Services/Report.service";
import { routeMapMini } from "../../../constants/routeMap";
import ReactJson from "react-json-view";

const ScanTicketLogReport = ({ history, notification }) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [reportData, setReportData] = useState("");
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [reportMetaData, setReportMetaData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [scanType, setScanType] = useState("all");
  const [outcome, SetOutcome] = useState("all");

  const { TabPane } = Tabs;

  const fetchScanTicketLogReport = async () => {
    setDataLoading(true);
    try {
      const data = await ReportService.getScanTicketLogReport(
        fromDate,
        toDate,
        tableCurrentPage,
        tablePageSize,
        scanType,
        outcome
      );
      console.log(data);
      setReportData(
        data?.items?.map((item) => {
          item.key = item.id;
          return item;
        })
      );
      setReportMetaData(data?.meta);
      setTabelTotalSize(data?.meta?.totalItems);
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
    setDataLoading(false);
  };

  console.log(reportData);

  useEffect(() => {
    fetchScanTicketLogReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableCurrentPage, tablePageSize, fromDate, toDate, scanType, outcome]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    setScanType(filters?.scan_type);
    SetOutcome(filters?.outcome);
  };

  // const downloadExcel = async () => {
  //   notification.info({message: "Downloading file! Please wait."});
  //   ReportService.allTransactionsExcel(
  //     fromDate,
  //     toDate,
  //     sortType,
  //     tableCurrentPage,
  //     tablePageSize
  //   )
  //     .then((data) => {
  //       fileDownload(
  //         data,
  //         `${fromDate}-TransactionReport-${moment().format("DD-MM-YYYY")}.xlsx`
  //       );
  //     })
  //     .catch((error) => {
  //       if (error?.response?.status === 401) {
  //         history?.push(routeMapMini.Login);
  //       } else if (error?.response?.data?.message) {
  //         notification.error({message: error?.response?.data?.message});
  //       } else {
  //         console.error(error);
  //         notification.error({message: "something is wrong"});
  //       }
  //     });
  // };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Ticket Id",
      dataIndex: "ticket_id",
      key: "ticket_id",
    },
    { title: "Product", dataIndex: "seva_name", key: "seva_name" },
    { title: "Type", dataIndex: "seva_type", key: "seva_type" },
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
    },
    {
      title: "Performance Date",
      dataIndex: "performance_date",
      key: "performance_date",
      render: (text) => {
        return moment(text).format(`DD-MM-YYYY`);
      },
    },
    {
      title: "Performance Scan Date",
      dataIndex: "performance_scan_date",
      key: "performance_scan_date",
    },
    {
      title: "Scanned Date",
      dataIndex: "scanned_date",
      key: "scanned_date",
    },
    {
      title: "Scan Type",
      dataIndex: "scan_type",
      key: "scan_type",

      filters: [
        {
          text: "Performance",
          value: "performance",
        },
        {
          text: "Verify",
          value: "verify",
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.scan_type.indexOf(value) === 0,
    },

    {
      title: "Outcome",
      dataIndex: "outcome",
      key: "outcome",
      filters: [
        {
          text: "Failure",
          value: "failure",
        },
        {
          text: "Success",
          value: "success",
        },
      ],

      filterMultiple: false,
      onFilter: (value, record) => record.outcome.indexOf(value) === 0,
    },
  ];

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <>
      <Row style={{ marginBottom: "20px" }} gutter={[20, 20]}>
        <Col>
          <Row justify="start">Booking Date</Row>
          <Row justify="start">
            <DatePicker.RangePicker
              format="DD-MM-YYYY"
              onChange={(e) => {
                if (e?.[0] && e?.[1]) {
                  setFromDate(moment(e[0]).format(`YYYY-MM-DD`));
                  setToDate(moment(e[1]).format(`YYYY-MM-DD`));
                } else {
                  setFromDate(undefined);
                  setToDate(undefined);
                }
              }}
              ranges={{
                Today: [moment(), moment()],
                "Last 7 Days": [moment().subtract(7, "d"), moment()],
                "Last 15 Days": [moment().subtract(15, "d"), moment()],
              }}
              defaultValue={[moment(fromDate), moment(toDate)]}
              defaultPickerValue={[moment(fromDate), moment(toDate)]}
            />
          </Row>
        </Col>
        <Col>
          <Row justify="start">Update</Row>
          <Row justify="start">
            <Button
              type="primary"
              style={{ minWidth: "100px" }}
              onClick={() => fetchScanTicketLogReport()}
            >
              Fetch
            </Button>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Download</Row>
          <Row justify="start">
            <Button
              type="primary"
              style={{ minWidth: "100px" }}
              // onClick={() => downloadExcel()}
            >
              Excel
            </Button>
          </Row>
        </Col>
        <Col
          style={{
            width: "20%",
            textAlign: "right",
            margin: "auto",
            marginRight: "0",
          }}
        >
          <Card
            hoverable
            size="small"
            style={{ fontWeight: "bold" }}
            onClick={() => setShowModal(true)}
          >
            <Row>
              <Col span={12}>
                <Statistic
                  title="Verify"
                  value={
                    reportMetaData?.scanTypeCount?.verify === null ||
                    reportMetaData?.scanTypeCount?.verify === 0
                      ? 0
                      : reportMetaData?.scanTypeCount?.verify
                  }
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Performance"
                  value={
                    reportMetaData?.scanTypeCount?.performance === null ||
                    reportMetaData?.scanTypeCount?.performance === 0
                      ? 0
                      : reportMetaData?.scanTypeCount?.performance
                  }
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Total Bookings</span>
        </Space>
      </Row>

      <Table
        style={{ width: "100%", textTransform: "capitalize" }}
        columns={columns}
        dataSource={reportData}
        loading={dataLoading}
        size="small"
        scroll={{ x: true }}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        onChange={handleTableChange}
        expandable={{
          expandedRowRender: (record) => (
            <div style={{ paddingLeft: "50px" }}>
              <Row>
                <b style={{ paddingRight: "10px" }}>Name :</b>
                <p>
                  {record?.devotee_name}/{record?.devotee_phone}
                </p>
              </Row>
              <Row>
                <b style={{ paddingRight: "10px" }}>Failure Message :</b>
                <p>{record?.failure_message ?? "NA"}</p>
              </Row>
            </div>
          ),
        }}
      ></Table>
      <Modal
        visible={showModal}
        width="30%"
        title={`Statistics`}
        onCancel={handleCancel}
        centered
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
        ]}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Name Count" key="1">
            <ReactJson
              src={reportMetaData?.sevaNameCount}
              theme="bright:inverted"
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              quotesOnKeys={false}
              name={false}
            />
          </TabPane>
          <TabPane tab="Type Count" key="2">
            <ReactJson
              src={reportMetaData?.sevaTypeCount}
              theme="bright:inverted"
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              quotesOnKeys={false}
              name={false}
            />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default withRouter(ScanTicketLogReport);
