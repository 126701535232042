import React from "react";
import QRCode from "qrcode.react";
import numberToWords from "../../utils/amount-to-words/number-to-words";
import {FormatNumberINRCurrency} from "../../utils/currencyFormat";
import srisailam_logo from "../../assets/srisailam_logo.png";
import {withRouter} from "react-router-dom";

const KalyanaKattaTicket = ({
  kalyanakattaItems,
  bookingDate,
  ticketId,
  totalAmount,
  categoryTransactionId,
  serialNumber,
}) => {
  return (
    <>
      <div
        style={{
          fontWeight: "bold",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          {" "}
          {`TI No : ${categoryTransactionId?.split("-")[2]} -
              ${categoryTransactionId?.split("-")?.pop()}`}
        </div>
        <div>
          {`Sl No : 
            ${serialNumber?.split("-")[3]}`}{" "}
        </div>
      </div>
      <div style={{display: "flex"}}>
        <img
          src={srisailam_logo}
          alt="logo"
          style={{
            width: "20%",
            justifyContent: "center",
            margin: "auto",
            filter: "grayscale(1)",
          }}
        />
      </div>
      <div
        style={{
          justifyContent: "center",
          margin: "0",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        <p style={{margin: "0"}}>
          Sri Bhramaramba Mallikarjuna Swamy Varla Devasthanam - Srisailam
        </p>
      </div>
      <div
        style={{
          justifyContent: "center",
          margin: "0",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        <p style={{margin: "0"}}>KALYANAKATTA TICKET</p>
      </div>
      <div
        style={{
          justifyContent: "center",
          margin: "0",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        <p style={{margin: "0"}}>Booking Date. : {bookingDate}</p>
      </div>
      <div style={{fontSize: "12px", marginBottom: "10px"}}>
        <div>
          <div
            style={{
              width: "40%",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            Details :
          </div>
        </div>
        <div style={{display: "flex"}}>
          <div
            style={{
              width: "40%",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
            span="12"
          >
            <p style={{margin: "0"}}>Item</p>
          </div>
          <div
            style={{
              width: "40%",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
            span="6"
          >
            <p style={{margin: "0"}}>Qty</p>
          </div>
          <div
            style={{
              width: "40%",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
            span="6"
          >
            <p style={{margin: "0"}}>Amount</p>
          </div>
        </div>
        {kalyanakattaItems?.map((items) => (
          <div style={{display: "flex"}}>
            <div
              style={{
                width: "60%",
                overflowWrap: "break-word",
                textTransform: "capitalize",
              }}
              span="12"
            >
              <p style={{margin: "0"}}>{items?.kalyanakattaName}</p>
            </div>
            <div
              style={{
                width: "60%",
                overflowWrap: "break-word",
                textTransform: "capitalize",
              }}
              span="6"
            >
              <p style={{margin: "0"}}>
                ₹{items?.unitPrice} x {items?.quantity}
              </p>
            </div>
            <div
              style={{
                width: "60%",
                overflowWrap: "break-word",
                textTransform: "capitalize",
              }}
              span="6"
            >
              <p style={{margin: "0"}}>₹{items?.price}</p>
            </div>
          </div>
        ))}

        <div style={{display: "flex"}}>
          <div
            style={{
              width: "40%",
              overflowWrap: "break-word",
              textTransform: "capitalize",
              fontWeight: "bold",
            }}
          >
            <p style={{margin: "0"}}>Total Amount</p>
          </div>
          <div
            style={{
              width: "60%",
              overflowWrap: "break-word",
              textTransform: "capitalize",
              display: "inline-flex",
              // fontWeight: "bold",
            }}
          >
            <p style={{paddingRight: "10px", margin: "0"}}>:</p>
            {FormatNumberINRCurrency(totalAmount)}
            <br />({numberToWords(Number(totalAmount))} Rupees)
          </div>
        </div>
        {ticketId && (
          <div style={{display: "flex"}}>
            <div
              style={{
                width: "40%",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              <p style={{margin: "0"}}>Ticket Id</p>
            </div>
            <div
              style={{
                width: "60%",
                overflowWrap: "break-word",
                textTransform: "capitalize",
                display: "inline-flex",
                fontWeight: "bold",
              }}
            >
              <p style={{paddingRight: "10px", margin: "0"}}>:</p>
              <p style={{margin: "0"}}>
                {ticketId ? ticketId : "transactionId=127"}
              </p>
            </div>
          </div>
        )}
      </div>
      {ticketId && (
        <div
          style={{
            justifyContent: "center",
            margin: "auto",
            display: "flex",
          }}
        >
          {" "}
          <QRCode
            size="75"
            fontSize="15px"
            value={ticketId ? ticketId : "transactionId=127"}
          />
        </div>
      )}
    </>
  );
};

export default withRouter(KalyanaKattaTicket);
