import {Button, Col, Input, notification, Row, Space, Checkbox} from "antd";
import Modal from "antd/lib/modal/Modal";
import {useContext, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {routeMapMini} from "../../constants/routeMap";
import {UserContext} from "../../context/user.context";
import kalyanaKattaService from "../../Services/KalyanakattaService";
import KalyanaKattaTable from "./KalayanaKattaTable";

const KalyanaKatta = ({history}) => {
  const [showNewModal, setShowNewModal] = useState(false);
  const [loading, setLoading] = useState();
  const [kalyanaKattaName, setkalyanaKattaName] = useState("");
  const [price, setPrice] = useState();
  const [edit, setEdit] = useState(false);
  const [updateTable, setUpdateTable] = useState();
  const [updatekalyanaKattaID, setKalyanaKattaID] = useState();
  const {userDetails} = useContext(UserContext);
  const [disabled, setDisabled] = useState(false);
  // const [description, setDescription] = useState("");

  useEffect(() => {
    (async () => {
      if (updatekalyanaKattaID) {
        setShowNewModal(true);
        try {
          const resp = await kalyanaKattaService.kalyanakattabyId(
            updatekalyanaKattaID
          );
          setkalyanaKattaName(resp?.name);
          setPrice(resp?.price);
          setDisabled(resp?.disabled);
          setEdit(true);
          setShowNewModal(true);
        } catch (error) {
          if (error?.response?.status === 401) {
            notification.error({
              message: "Token Expired, redirecting to login page in 5 seconds",
            });
            history?.push(routeMapMini.Login);
          } else if (error.response?.data?.message) {
            notification.error({message: error.response?.data?.message});
          } else {
            notification.error({message: error.message});
          }
        }
      }
    })();
  }, [history, updatekalyanaKattaID]);

  const handleOk = async () => {
    if (!kalyanaKattaName) {
      return notification.error({message: `name is required`});
    } else if (!price || price <= 0) {
      return notification.error({
        message: `price is required and should be positive`,
      });
    }
    setLoading(true);
    try {
      if (updatekalyanaKattaID) {
        await kalyanaKattaService.editKalayanaKatta(
          updatekalyanaKattaID,
          kalyanaKattaName,
          disabled,
          price
        );
        notification.success({
          message: `Kalyanakatta ${updatekalyanaKattaID} updated`,
        });
        clearFields();
        setUpdateTable(updatekalyanaKattaID + Math.random());
        setEdit(false);
      } else {
        const kalyanakatta = await kalyanaKattaService.newKalyanaKatta(
          kalyanaKattaName,
          disabled,
          price
        );
        notification.success({
          message: `Kalyanakatta ${kalyanakatta.id} created`,
        });
        clearFields();
        setUpdateTable(updatekalyanaKattaID + Math.random());
        setEdit(false);
      }
      setShowNewModal(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setkalyanaKattaName(undefined);
    setPrice(undefined);
    setKalyanaKattaID(undefined);
    setDisabled(undefined);
    setEdit(false);
  };

  const handleCancel = () => {
    clearFields();
    setShowNewModal(false);
    setEdit(false);
  };

  return (
    <div style={{width: "100%"}}>
      <Row style={{width: "100%", marginBottom: "15px"}} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setShowNewModal(true);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Add New Kalyanakatta Seva
          </Button>
        </Col>
      </Row>
      <Row style={{width: "100%"}}>
        <KalyanaKattaTable
          updateTable={updateTable}
          setKalyanakattaId={setKalyanaKattaID}
        />
      </Row>
      <Modal
        visible={showNewModal}
        title={`${
          edit
            ? `Edit Kalyanakatta id: ${updatekalyanaKattaID}`
            : `Add New Kalyanakatta`
        }`}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{alignContent: "center"}}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify="start" style={{width: "100%", padding: "10px 0px"}}>
          <label>Kalyanakatta Name</label>
          <Input
            placeholder="Kalyanakatta Name"
            value={kalyanaKattaName}
            onChange={(e) => {
              setkalyanaKattaName(e.target.value);
            }}
            style={{width: "450px"}}
          />
        </Row>

        <Row
          justify="start"
          style={{width: "100%", padding: "10px 0px", flexDirection: "column"}}
        >
          <label>Price </label>
          <Input
            placeholder="Price"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
            type="number"
            style={{width: "450px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Space size="middle">
            Disable Seva
            <Checkbox
              checked={disabled}
              onChange={(e) => {
                setDisabled(e.target.checked);
              }}
            />
          </Space>
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(KalyanaKatta);
