import {Col, Row} from "antd";
import React from "react";
import Barcode from "react-barcode";
import numberToWords from "../../utils/amount-to-words/number-to-words";

const DonationTicket = ({
  name,
  sevaName,
  bookingDate,
  calendarType,
  performanceDate,
  masam,
  paksham,
  tithi,
  amountPaid,
  actualAmount,
  address,
  city,
  state,
  pincode,
  ticketId,
  inTheNameOf,
}) => {
  return (
    <>
      <Row className="tickettitle">
        <p>DONATION RECEIPT</p>
      </Row>
      <div style={{margin: "20px"}}>
        <Row>
          <Col className="ticket_label">Devotee Name :</Col>
          <Col className="ticket_data">{name}</Col>
        </Row>
        <Row>
          <Col className="ticket_label">in the Name of :</Col>
          <Col className="ticket_data">{inTheNameOf}</Col>
        </Row>
        <Row>
          <Col className="ticket_label">Seva Name :</Col>
          <Col className="ticket_data">{sevaName}</Col>
        </Row>
        <Row>
          <Col className="ticket_label">Booking Date :</Col>
          <Col className="ticket_data">{bookingDate}</Col>
        </Row>
        <Row>
          <Col className="ticket_label">Donation Date :</Col>
          <Col className="ticket_data">
            {calendarType === "english"
              ? performanceDate
              : `${masam} Masam - ${paksham} Paksham - ${tithi} Tithi`}
          </Col>
        </Row>
        {amountPaid && (
          <Row>
            <Col className="ticket_label">
              {actualAmount && (
                <>
                  <p className="amount-label">Donation Amount :</p>
                  {amountPaid - actualAmount > 0 && (
                    <p className="amount-label">Handling Charges :</p>
                  )}
                </>
              )}
              <p>Total Amount :</p>
            </Col>
            <Col className="ticket_data">
              {actualAmount && (
                <>
                  <p className="amount-data">Rs. {actualAmount}.00</p>
                  {amountPaid - actualAmount > 0 && (
                    <p className="amount-data">
                      Rs. {amountPaid - actualAmount}.00
                    </p>
                  )}
                </>
              )}
              <p>
                Rs. {amountPaid}.00 <br />({numberToWords(amountPaid)} Rupees)
                <br />
              </p>
            </Col>
          </Row>
        )}
        <Row>
          <Col className="ticket_label">Address :</Col>
          <Col className="ticket_data">{address}</Col>
        </Row>
        <Row>
          <Col className="ticket_label">City :</Col>
          <Col className="ticket_data">{city}</Col>
        </Row>
        <Row>
          <Col className="ticket_label">State :</Col>
          <Col className="ticket_data">{state}</Col>
        </Row>
        <Row>
          <Col className="ticket_label">Pin Code :</Col>
          <Col className="ticket_data">{pincode}</Col>
        </Row>
      </div>
      {ticketId && (
        <Row
          style={{
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <Barcode
            height="35"
            width="2"
            fontSize="15px"
            value={ticketId ? ticketId : "transactionId=127"}
            style={{
              justifyContent: "center",
              margin: "auto",
            }}
          />
        </Row>
      )}
    </>
  );
};

export default DonationTicket;
