import {Button, Col, DatePicker, Input, Row, Select} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Modal from "antd/lib/modal/Modal";
import React, {useState} from "react";
import {withRouter} from "react-router";
const {Option} = Select;

const TempleNewEventModalOne = ({
  setVisible,
  visible,
  loading,
  date,
  setDate,
  to,
  setTo,
  from,
  setFrom,
  setFullDay,
  fullDay,
  announcement,
  setAnnouncemnet,
  step,
  setStep,
  block,
  setBlock,
  blockType,
  setBlocktype,
  setBlocktimeFrom,
  blockTimeFrom,
  setBlocktimeTo,
  blockTimeTo,
}) => {
  const [sevablock, setSevablock] = useState([]);
  const [darshanamBlock, setDarshanamBlock] = useState([]);

  const [accomadationBlock, setAccomadationblock] = useState([]);

  const renderDisplay = () => {
    switch (step) {
      case "1":
        return (
          <>
            {" "}
            <Row>
              <Col span={8}> Date:</Col>{" "}
              <Col span={16}>
                <DatePicker
                  defaultValue={date}
                  placeholder="DD-MM-YYYY"
                  style={{marginLeft: "5px", width: "100%"}}
                  onChange={(e) => {
                    setDate(e?.format?.("YYYY-MM-DD"));
                  }}
                />
              </Col>
            </Row>
            <Row style={{width: "100%", padding: "10px 0px 10px 0px"}}>
              <Col span={12}>
                Start Time:
                <DatePicker
                  allowClear
                  picker="time"
                  style={{marginRight: "5px", width: "100%"}}
                  defaultValue={from}
                  value={from}
                  onChange={(e) => {
                    setFrom(e);
                  }}
                />
              </Col>
              <Col span={12}>
                End Time:
                <DatePicker
                  allowClear
                  style={{marginLeft: "5px", width: "100%"}}
                  picker="time"
                  defaultValue={to}
                  placeholder="Select"
                  value={to}
                  onChange={(e) => {
                    setTo(e);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Checkbox
                checked={fullDay ? true : false}
                onChange={() => {
                  setFullDay(!fullDay);
                }}
              >
                Entire Day
              </Checkbox>
            </Row>
            <Row style={{display: "flex", marginTop: "10px"}}>
              <Col span={8}> Title:</Col>
              <Col span={16}>
                <Input />
              </Col>
            </Row>
            <Row style={{marginTop: "10px"}}>
              <Checkbox
                checked={announcement ? true : false}
                onChange={() => {
                  if (announcement) {
                    setAnnouncemnet(false);
                  } else {
                    setAnnouncemnet(true);
                  }
                }}
              >
                Special Announcement
              </Checkbox>
            </Row>
            {announcement === true && (
              <>
                <Row
                  style={{display: "flex", marginTop: "10px", width: "100%"}}
                >
                  <Col span={8}> Announcement :</Col>
                  <Col span={16}>
                    <Input />
                  </Col>
                </Row>
                <Row
                  style={{display: "flex", marginTop: "10px", width: "100%"}}
                >
                  <Col span={8}> Description / Comment :</Col>
                  <Col span={16}>
                    <Input width="100%" style={{width: "100%"}} />
                  </Col>
                </Row>
              </>
            )}
          </>
        );
      case "2":
        return (
          <>
            <Row>
              <Checkbox
                onChange={() => {
                  if (block) {
                    setBlock(false);
                  } else {
                    setBlock(true);
                  }
                }}
              >
                Do you want to Block any during that time or day?{" "}
              </Checkbox>
            </Row>
            <br />
            {blockType && (
              <>
                <Row>
                  <Col span={6}>Block for:</Col>
                  <Col span={18}>
                    <Checkbox
                      style={{margin: "0px 5px"}}
                      checked={blockType === "seva" ? true : false}
                      onChange={() => {
                        setBlocktype("seva");
                      }}
                    >
                      Seva
                    </Checkbox>
                    <Checkbox
                      style={{margin: "0px 5px"}}
                      checked={blockType === "darshanam" ? true : false}
                      onChange={() => {
                        setBlocktype("darshanam");
                      }}
                    >
                      Darshanam
                    </Checkbox>
                    <Checkbox
                      style={{margin: "0px 5px"}}
                      checked={blockType === "accomadation" ? true : false}
                      onChange={() => {
                        setBlocktype("accomadation");
                      }}
                    >
                      Accommodation{" "}
                    </Checkbox>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={12}>
                    Block Start :{" "}
                    <DatePicker
                      allowClear
                      style={{marginTop: "5px"}}
                      picker="time"
                      defaultValue={blockTimeFrom}
                      value={blockTimeFrom}
                      onChange={(e) => {
                        setBlocktimeFrom(e);
                      }}
                    />{" "}
                  </Col>
                  <Col span={12}>
                    Block End :
                    <DatePicker
                      allowClear
                      picker="time"
                      style={{margin: "5px 0px 5px 7px"}}
                      defaultValue={blockTimeTo}
                      value={blockTimeTo}
                      onChange={(e) => {
                        setBlocktimeTo(e);
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            <br />
            {blockType === "seva" && (
              <Row>
                <Col span={6}>Block Seva's: </Col>
                <Col span={18}>
                  {" "}
                  <Select
                    mode="multiple"
                    style={{width: "100%"}}
                    placeholder="select seva"
                    defaultValue={sevablock}
                    onChange={(value) => {
                      setSevablock(value);
                    }}
                    optionLabelProp="label"
                  >
                    <Option value="china" label="China">
                      <div className="demo-option-label-item">
                        <span role="img" aria-label="China">
                          🇨🇳
                        </span>
                        China (中国)
                      </div>
                    </Option>
                    <Option value="usa" label="USA">
                      <div className="demo-option-label-item">
                        <span role="img" aria-label="USA">
                          🇺🇸
                        </span>
                        USA
                      </div>
                    </Option>
                    <Option value="japan" label="Japan">
                      <div className="demo-option-label-item">
                        <span role="img" aria-label="Japan">
                          🇯🇵
                        </span>
                        Japan
                      </div>
                    </Option>
                    <Option value="korea" label="Korea">
                      <div className="demo-option-label-item">
                        <span role="img" aria-label="Korea">
                          🇰🇷
                        </span>
                        Korea
                      </div>
                    </Option>
                  </Select>
                </Col>
              </Row>
            )}
            {blockType === "darshanam" && (
              <Row>
                <Col span={6}>Block Darshanam: </Col>
                <Col span={18}>
                  {" "}
                  <Select
                    mode="multiple"
                    style={{width: "100%"}}
                    placeholder="select darshanam"
                    defaultValue={darshanamBlock}
                    onChange={(value) => {
                      setDarshanamBlock(value);
                    }}
                    optionLabelProp="label"
                  >
                    <Option value="china" label="China">
                      <div className="demo-option-label-item">
                        <span role="img" aria-label="China">
                          🇨🇳
                        </span>
                        China
                      </div>
                    </Option>
                    <Option value="usa" label="USA">
                      <div className="demo-option-label-item">
                        <span role="img" aria-label="USA">
                          🇺🇸
                        </span>
                        USA
                      </div>
                    </Option>
                    <Option value="japan" label="Japan">
                      <div className="demo-option-label-item">
                        <span role="img" aria-label="Japan">
                          🇯🇵
                        </span>
                        Japan
                      </div>
                    </Option>
                    <Option value="korea" label="Korea">
                      <div className="demo-option-label-item">
                        <span role="img" aria-label="Korea">
                          🇰🇷
                        </span>
                        Korea
                      </div>
                    </Option>
                  </Select>
                </Col>
              </Row>
            )}
            {blockType === "accomadation" && (
              <Row>
                <Col span={8}>Block Accommodation: </Col>
                <Col span={16}>
                  {" "}
                  <Select
                    mode="multiple"
                    style={{width: "100%"}}
                    placeholder="select Accommodation"
                    defaultValue={accomadationBlock}
                    onChange={(value) => {
                      setAccomadationblock(value);
                    }}
                    optionLabelProp="label"
                  >
                    <Option value="china" label="China">
                      <div className="demo-option-label-item">
                        <span role="img" aria-label="China">
                          🇨🇳
                        </span>
                        China
                      </div>
                    </Option>
                    <Option value="usa" label="USA">
                      <div className="demo-option-label-item">
                        <span role="img" aria-label="USA">
                          🇺🇸
                        </span>
                        USA
                      </div>
                    </Option>
                    <Option value="japan" label="Japan">
                      <div className="demo-option-label-item">
                        <span role="img" aria-label="Japan">
                          🇯🇵
                        </span>
                        Japan
                      </div>
                    </Option>
                    <Option value="korea" label="Korea">
                      <div className="demo-option-label-item">
                        <span role="img" aria-label="Korea">
                          🇰🇷
                        </span>
                        Korea
                      </div>
                    </Option>
                  </Select>
                </Col>
              </Row>
            )}
          </>
        );
      default:
        break;
    }
  };
  const clearFields = () => {
    setBlock(false);
    setBlocktype("");
    setDarshanamBlock([]);
    setAccomadationblock([]);
    setSevablock([]);
    setBlocktimeFrom("");
    setBlocktimeTo("");
    setDate("");
    setTo("");
    setFrom("");
    setFullDay(false);
    setAnnouncemnet(false);
  };
  return (
    <Modal
      visible={visible}
      title={`Add New Event`}
      onOk={() => {
        setVisible(false);
      }}
      onCancel={() => {
        setVisible(false);
        clearFields();
      }}
      footer={[
        <Button
          key="draft"
          type="info"
          onClick={() => {
            if (step === "2") {
              setStep("1");
            } else {
              setVisible(false);
              clearFields();
            }
          }}
        >
          {step === "1" ? "Draft" : "Back"}
        </Button>,
        <Button
          key="back"
          onClick={() => {
            setVisible(false);
            clearFields();
          }}
        >
          Cancel
        </Button>,

        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => {
            if (step === "1") {
              setStep("2");
            } else {
              setVisible(false);
              clearFields();
            }
          }}
        >
          {step === "1" ? "Next" : "Submit"}
        </Button>,
      ]}
    >
      {renderDisplay()}
    </Modal>
  );
};
export default withRouter(TempleNewEventModalOne);
