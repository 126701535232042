import Axios from "../utils/axios";

const CounterService = {
  newCounter: async (
    name,
    category,
    location,
    contactNumber,
    email,
    disabled
  ) => {
    const body = {
      name,
      category,
      location,
      contactNumber,
      email,
      disabled,
    };

    Object.keys(body).forEach((key) => {
      if (body[key] === undefined || !body[key]) {
        delete body[key];
      }
    });

    if (!disabled) {
      body.disabled = false;
    }

    const resp = await Axios.post("/counter/new", body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });

    return resp.data;
  },

  editCounter: async (
    counter_id,
    name,
    category,
    location,
    contactNumber,
    email,
    disabled
  ) => {
    const body = {
      counter_id,
      name,
      category,
      location,
      contactNumber,
      email,
      disabled,
    };

    Object.keys(body).forEach((key) => {
      if (body[key] === undefined || !body[key]) {
        delete body[key];
      }
    });

    if (!disabled) {
      body.disabled = false;
    }

    const resp = await Axios.put("/counter/edit", body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });

    return resp.data;
  },

  deleteCounter: async (id) => {
    const resp = await Axios.delete(`/counter/delete/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return resp.data;
  },

  getAllCounter: async () => {
    const resp = await Axios.get("/counter", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return resp.data;
  },

  getCounterById: async (id) => {
    const resp = await Axios.get(`/counter?counterId=${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return resp.data?.[0];
  },

  getCounterRoles: async (id) => {
    const resp = await Axios.get(`/counter/role?counterId=${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return resp.data;
  },

  addRoleToCounter: async (id, role) => {
    const resp = await Axios.post(
      `counter/add/role?counterId=${id}&role=${role}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );
    return resp.data;
  },

  deleteRoleFromCounter: async (id, role) => {
    const resp = await Axios.delete(
      `counter/remove/role?counterId=${id}&role=${role}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );
    return resp.data;
  },
  getCounterByblockId: async (id) => {
    const resp = await Axios.get(`/block-counter/counter/byBlockId/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return resp.data;
  },
  deleteCounterbyBlockId: async (blockID, counterId) => {
    const resp = await Axios.delete(
      `block-counter/byCounterId/BlockId?blockId=${blockID}&counterId=${counterId}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );
    return resp.data;
  },
  addCounterbyBlockId: async (blockID, counterID) => {
    const resp = await Axios.post(
      `/block-counter?blockId=${blockID}&counterId=${counterID}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );

    return resp.data;
  },

  getCoupons: async (page, limit) => {
    const resp = await Axios.get(`/coupon/all?page=${page}&limit=${limit}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return resp.data;
  },
  addCoupon: async (body) => {
    const resp = await Axios.post(`/coupon/new`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });

    return resp.data;
  },
  editCoupon: async (body) => {
    const resp = await Axios.put(`/coupon/edit`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });

    return resp.data;
  },
  deleteCouponById: async (id) => {
    const resp = await Axios.delete(`coupon/byId/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return resp.data;
  },
  deleteCouponByCode: async (code) => {
    const resp = await Axios.delete(`coupon/byCouponCode/${code}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return resp.data;
  },
  verifyCoupon: async (code) => {
    const resp = await Axios.get(
      `coupon/verify/coupon?coupon_code=${code}&type=donor_accommodation`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );
    return resp.data;
  },
  getAllShifts: async () => {
    const resp = await Axios.get(`/counter/shift`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return resp.data;
  },
  addShift: async (body) => {
    const resp = await Axios.post(`/counter/shift/new`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });

    return resp.data;
  },
  deleteShiftById: async (id) => {
    const resp = await Axios.delete(`counter/shift/delete/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return resp.data;
  },

  getCounterShifts: async (id) => {
    const resp = await Axios.get(`/counter/c-shift/status?counterId=${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return resp.data;
  },
  getCounterPreShifts: async (id) => {
    const resp = await Axios.get(`/pre-assign-shifts/counter/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return resp.data;
  },

  addCounterPreShift: async (data) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    console.log(headers);
    const resp = await Axios.post(`/pre-assign-shifts/new`, data, {
      headers: headers,
    });

    return resp.data;
  },

  // addCounterPreShift: async (body) => {
  //   const resp = await Axios.post(`/pre-assign-shifts/new`, body, {
  //     headers: {
  //       Authorization: "Bearer " + localStorage.getItem("accessToken"),
  //     },
  //   });

  //   return resp.data;
  // },

  deleteCounterPreShiftById: async (id) => {
    const resp = await Axios.delete(`/pre-assign-shifts/by-id/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return resp.data;
  },
};

export default CounterService;
