import Axios from '../utils/axios';

const UserService = {
  managementUsers: async () => {
    const resp = await Axios.get(`/user/managment/users`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return resp.data;
  },

  manageUserRole: async (role, phone) => {
    phone = '+91' + phone;
    const resp = await Axios.post(
      `user/managment/user/role`,
      {
        role,
        phone,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    return resp.data;
  },

  userDetails: async (queryBy, value) => {
    const params = {};
    params[queryBy] = value;
    const resp = await Axios.get(`/user/admin/user/details?`, {
      params: params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return resp.data;
  },

  adminCreateUser: async (phone, display_name, user_role, password) => {
    phone = '+91' + phone;
    const resp = await Axios.post(
      `user/admin/create`,
      {
        user_role,
        phone,
        display_name,
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    return resp.data;
  },

  userOrderStats: async (userId) => {
    const resp = await Axios.get(`user-report/order/stats?userId=${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return resp.data;
  },

  userPaymentStats: async (userId) => {
    const resp = await Axios.get(`user-report/payments?userId=${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return resp.data;
  },

  userAuthLogs: async (phone, page, pageSize) => {
    const resp = await Axios.get(`user-report/logs/auth`, {
      params: {
        phone,
        page,
        limit: pageSize,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return resp.data;
  },

  userActionLogs: async (userId, page, pageSize) => {
    const resp = await Axios.get(
      `user-report/logs/actions?userId=${userId}&page=${page}&limit=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    return resp.data;
  },
};

export default UserService;
