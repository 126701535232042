import {
  Alert,
  Button,
  Col,
  Input,
  notification,
  Row,
  Typography,
  Card,
} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router";
import {routeMapMini} from "../../constants/routeMap";
import CartService from "../../Services/Cart.service";
import ScannedTicket from "./ScannedTicket";
import TicketTable from "./TicketTable";
import CheckOut from "./CheckOut";
import Renew from "./Renew";
import PrintTicket from "./PrintTicket";
import "./PrintTicket.scss";
// import moment from "moment";
import {QrReader} from "react-qr-reader";

const Ticket = ({history}) => {
  const inputEl = useRef();

  const [ticketId, setTicketId] = useState("");
  const [valid, setValid] = useState(false);
  const [errMessage, setErrMessage] = useState(undefined);
  const [data, setData] = useState(null);
  const [scanning, setScanning] = useState("");

  const [loading, setLoading] = useState(false);

  const setInputFocus = () => {
    inputEl?.current?.input?.focus();
  };

  const selectInput = () => {
    inputEl?.current?.input?.select?.();
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      submit();
    }
  };
  const Scan = () => {
    setScanning(!scanning);
    // if (scanning === false) {
    //   setCloseFlag(true);
    // }
  };

  const OnDetected = (result) => {
    setScanning(false);
    setTicketId(result.text);
    setValid(false);
    setErrMessage(undefined);
    submit(result.text);
  };

  const submit = async (value) => {
    setValid(false);
    setErrMessage(undefined);
    setLoading(true);
    if (!ticketId && !value) {
      notification.error({message: "Enter Ticket Number!"});
    }
    try {
      const data = await CartService.scanTicket(value ?? ticketId);
      if (data) {
        setData(data);
      }
      setValid(true);
      selectInput();
      setInputFocus();
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        setValid(false);
        setErrMessage(error?.response?.data?.message);
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setLoading(false);
  };

  const onChangeTicketId = (e) => {
    setTicketId(e.target.value);
    setValid(false);
    setErrMessage(undefined);
  };

  useEffect(() => {
    setInputFocus();
  }, [data]);

  return (
    <>
      <Row justify="center">
        <Typography.Title> Scan Ticket </Typography.Title>
      </Row>
      {!valid && (
        <Row justify="center">
          {!scanning ? (
            <>
              <Col
                className="mobile-responsive-width"
                style={{margin: "8px", textAlign: "left"}}
              >
                {" "}
                Ticket No:{" "}
              </Col>
              <Col className="mobile-responsive-width" style={{margin: "8px"}}>
                <Input
                  ref={inputEl}
                  value={ticketId}
                  onChange={onChangeTicketId}
                  onKeyDown={onKeyDown}
                  className="mobile-responsive-width"
                />
              </Col>
              <Col className="mobile-responsive-width" style={{margin: "8px"}}>
                <Button
                  type="primary"
                  onClick={submit}
                  loading={loading}
                  className="mobile-responsive-width"
                >
                  GO!
                </Button>
              </Col>
            </>
          ) : (
            ""
          )}
          <Col className="mobile-responsive-width">
            <Button
              className="mobile-button-width"
              type="primary"
              onClick={() => {
                Scan();
              }}
              loading={loading}
              style={{margin: "8px"}}
            >
              {scanning ? "Close Scan" : " Barcode Scan"}
            </Button>
            {scanning ? (
              <QrReader
                onError={(e) => {
                  console.log(e);
                }}
                onResult={(result, error) => {
                  if (!!result) {
                    OnDetected(result);
                  }
                  if (!!error && error?.message) {
                    notification.error({message: error?.message});
                  }
                }}
                // chooseDeviceId={()=>selected}
                videoContainerStyle={{width: "25vw", heigth: "20vw"}}
              />
            ) : null}
            {/* <br />
              <ul className="results">
                <p>{results?.codeResult?.code}</p>
              </ul> */}
          </Col>
        </Row>
      )}

      <Row justify="center" style={{margin: "20px"}}>
        {valid && data && (
          <Alert
            message={`Ticket Scanned ${ticketId}`}
            description={`Ticket ${ticketId} has been scanned successfully.`}
            type="success"
            showIcon
          />
        )}
        {!valid && errMessage && (
          <Alert
            message={`Error Scanning Ticket ${ticketId}`}
            description={errMessage}
            type="error"
            showIcon
          />
        )}
      </Row>
      {!data?.ticket_used && valid && data?.type === "accomodation" && (
        <Row style={{marginTop: "24px"}} gutter={[10, 10]}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
            <TicketTable data={data} />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
            xxl={8}
            style={{justifyContent: "center", margin: "auto"}}
          >
            <ScannedTicket data={data} />
          </Col>
        </Row>
      )}
      {data && !data?.ticket_used && valid && data?.type !== "accomodation" && (
        <Row style={{justifyContent: "center", margin: "auto"}}>
          <PrintTicket
            setData={setData}
            type={(() => {
              if (data?.type === "darshanam_counter") {
                return "darshanam";
              } else if (data?.type === "pratyakshaSeva_counter") {
                return "pratyakshaSeva";
              } else {
                return data?.type;
              }
            })()}
            sevaName={data?.seva_name}
            abishekam={data?.abishekam_type}
            performanceDate={data?.booking_date}
            bookingDate={data?.ordered_date}
            timeSlot={data?.time_slot}
            startTime={data?.slot_start_start}
            endTime={data?.slot_end_start}
            persons={data?.no_of_tickets}
            name={data?.devotee_names}
            amountPaid={data?.amount_paid}
            actualAmount={data?.actual_amount}
            phone={data?.mobile_no}
            proof={data?.primary_id_proof_number}
            ticketId={data?.ticketId}
            categoryTransactionId={data?.category_transaction_id}
            from="ScanTicket"
          />
        </Row>
      )}
      {data?.ticket_used && valid && (
        <Row
          gutter={[20, 20]}
          style={{justifyContent: "center", margin: "auto"}}
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xsl={8}>
            <Card style={{height: "100%"}} title="Check Out">
              <CheckOut
                roomId={data?.room_booking_id}
                roomNo={data?.room_booking?.sd_rooms?.room_number}
                blockName={data?.seva_name}
                from="ScanTicket"
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xsl={8}>
            <Card style={{height: "100%"}} title="Renew">
              <Renew
                ticketId={data?.ticketId}
                roomNo={data?.room_booking?.sd_rooms?.room_number}
                blockName={data?.seva_name}
                devoteeName={data?.devotee_names}
                bookingDate={data?.room_booking?.booking_date}
                checkInTime={data?.room_booking?.check_in_time}
                depositAmount={data?.room_booking?.deposit_amount}
                persons={data?.no_of_tickets}
                phone={data?.room_booking?.contact}
                // roomType={data?.}
                from="ScanTicket"
              />
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default withRouter(Ticket);
