import {
  Button,
  Col,
  Input,
  notification,
  Row,
  Select,
  Space,
  TimePicker,
  Upload,
} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import TextArea from "antd/lib/input/TextArea";
import Modal from "antd/lib/modal/Modal";
import {useContext, useEffect, useState} from "react";
import moment from "moment";
import {UploadOutlined} from "@ant-design/icons";

import {withRouter} from "react-router-dom";
import {routeMapMini} from "../../constants/routeMap";
import SevaTable from "./SevaTable";
import SevaService from "../../Services/Seva.service";
import {UserContext} from "../../context/user.context";
import {baseURL} from "../../utils/axios";

const Seva = ({history}) => {
  const [showNewModal, setShowNewModal] = useState(false);
  const [loading, setLoading] = useState();
  const [sevaName, setSevaName] = useState("");
  const [sevaLocation, setSevaLoation] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [maxBookingPerDay, setMaxBookingPerDay] = useState();
  const [minDays, setMinDays] = useState();
  const [maxDays, setMaxDays] = useState();
  const [isLive, setIsLive] = useState(false);
  const [liveTelecast, setLiveTelecast] = useState(false);
  const [price, setPrice] = useState();
  const [sevaType, setSevaType] = useState("");
  const [updateReason, setUpdateReason] = useState("");
  const [sevaShortId, setSevaShortId] = useState("");
  const [offlineCounterCutOff, setOfflineCounterCutOff] = useState();

  const [updateTable, setUpdateTable] = useState();

  const [updateSevaId, setUpdateSevaId] = useState();

  const {userDetails} = useContext(UserContext);

  useEffect(() => {
    (async () => {
      if (updateSevaId) {
        setShowNewModal(true);
        try {
          const resp = await SevaService.getSevaById(updateSevaId);
          setSevaName(resp?.name);
          setSevaLoation(resp?.location);
          setStartDate(resp?.start_date ?? undefined);
          setEndDate(resp?.end_date ?? undefined);
          setDescription(resp?.description);
          setImageUrl(resp?.image_url);
          setMaxBookingPerDay(resp?.max_booking_per_day);
          setMinDays(resp?.min_days);
          setMaxDays(resp?.max_days);
          setIsLive(!resp?.disabled);
          setPrice(resp?.price);
          setLiveTelecast(resp?.live_telecast);
          setSevaShortId(resp?.short_id);
          setShowNewModal(true);
          setOfflineCounterCutOff(resp?.offline_counter_cut_off);
        } catch (error) {
          if (error?.response?.status === 401) {
            notification.error({
              message: "Token Expired, redirecting to login page in 5 seconds",
            });
            history?.push(routeMapMini.Login);
          } else if (error.response?.data?.message) {
            notification.error({message: error.response?.data?.message});
          } else {
            notification.error({message: error.message});
          }
        }
      }
    })();
  }, [history, updateSevaId]);

  const handleOk = async () => {
    console.log(maxDays);
    console.log(minDays);
    if (!sevaName) {
      return notification.error({message: `name is required`});
    } else if (!price || price <= 0) {
      return notification.error({
        message: `price is required and should be positive`,
      });
    } else if (maxBookingPerDay < 0) {
      return notification.error({
        message: `maxBookingPerDay and should be positive`,
      });
    } else if (minDays < 0) {
      return notification.error({
        message: `minDays and should be positive`,
      });
    }
    //  else if (maxDays < minDays) {
    //   return notification.error({
    //     message: `maxDays should be greater than minDays`,
    //   });
    // }
    setLoading(true);
    try {
      if (updateSevaId) {
        await SevaService.editSeva(
          updateSevaId,
          sevaName,
          sevaLocation,
          startDate,
          endDate,
          description,
          imageUrl,
          maxBookingPerDay,
          minDays,
          maxDays,
          !isLive,
          liveTelecast,
          price,
          updateReason,
          sevaShortId,
          offlineCounterCutOff
        );
        notification.success({
          message: `Seva Proposal Sent`,
        });
        clearFields();
        setUpdateTable(updateSevaId + Math.random());
      } else {
        const seva = await SevaService.newSeva(
          sevaType,
          sevaName,
          sevaLocation,
          startDate,
          endDate,
          description,
          imageUrl,
          maxBookingPerDay,
          minDays,
          maxDays,
          !isLive,
          liveTelecast,
          price,
          updateReason,
          sevaShortId,
          offlineCounterCutOff
        );
        notification.success({message: `Seva Proposal Sent`});
        setUpdateTable(seva.id + Math.random());
      }
      setShowNewModal(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setSevaName(undefined);
    setSevaLoation(undefined);
    setStartDate(undefined);
    setEndDate(undefined);
    setDescription(undefined);
    setImageUrl(undefined);
    setMaxBookingPerDay(undefined);
    setMinDays(undefined);
    setMaxDays(undefined);
    setIsLive(undefined);
    setPrice(undefined);
    setLiveTelecast(undefined);
    setUpdateSevaId(undefined);
    setUpdateReason(undefined);
    setSevaShortId(undefined);
    setOfflineCounterCutOff(undefined);
  };

  const handleCancel = () => {
    clearFields();
    setShowNewModal(false);
  };

  return (
    <div style={{width: "100%"}}>
      <Row gutter={[20, 20]} style={{width: "100%", margin: "0px 0px 15px"}}>
        <Select
          style={{
            minWidth: "150px",
            margin: "10px 10px 0px 0px",
            maxHeight: "32px",
          }}
          onChange={(e) => {
            setSevaType(e);
          }}
          defaultValue=""
        >
          <Select.Option value="parokshaSeva">Paroksha Seva</Select.Option>
          <Select.Option value="pratyakshaSeva">Pratyaksha Seva</Select.Option>
          <Select.Option value="">All</Select.Option>
        </Select>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              console.log(sevaType);
              if (
                sevaType !== "parokshaSeva" &&
                sevaType !== "pratyakshaSeva"
              ) {
                notification.error({message: `please select seva type`});
              } else {
                setShowNewModal(true);
              }
            }}
            disabled={!["admin"].includes(userDetails.role)}
          >
            Add New Seva
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              history?.push(routeMapMini.SevaBookingDetails);
            }}
            disabled={
              ![
                "admin",
                "seva_read",
                "seva_read_and_performance_scan",
              ].includes(userDetails.role)
            }
          >
            Seva Online Booking Details
          </Button>
        </Col>
        {/* <Col>
          <Button
            type="primary"
            onClick={() => {
              history?.push(routeMapMini.SevaReports);
            }}
            disabled={!["admin", "seva_read"].includes(userDetails.role)}
          >
            Seva Reports
          </Button>
        </Col> */}
        <Col>
          <Button
            type="primary"
            onClick={() => {
              history?.push(routeMapMini.SevaDisabledDates);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Disable Sevas On Date
          </Button>
        </Col>
      </Row>
      <Row style={{width: "100%"}}>
        {userDetails?.role !== "seva_read" && (
          <SevaTable
            updateTable={updateTable}
            setUpdateSevaId={setUpdateSevaId}
            sevaType={sevaType}
          />
        )}
      </Row>
      <Modal
        visible={showNewModal}
        title={`Add New ${sevaType}`}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify="center">Seva Name</Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Seva Name"
            value={sevaName}
            // disabled={updateSevaId}
            onChange={(e) => {
              setSevaName(e.target.value);
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center">Seva Short Id</Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Short Id"
            value={sevaShortId}
            onChange={(e) => {
              setSevaShortId(e.target.value);
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center">Location</Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Location"
            value={sevaLocation}
            onChange={(e) => {
              setSevaLoation(e.target.value);
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center">Image</Row>
        <Row justify="center">
          <Upload
            name="file"
            listType="picture"
            action={`${baseURL}fileupload/upload/public-file?fileFor=publication_cover_pic`}
            headers={{
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }}
            accept="image/png, image/gif, image/jpeg"
            maxCount={1}
            onChange={(info) => {
              if (info.file.status !== "uploading") {
                setImageUrl(info.file?.response?.path);
              }
              if (info.file.status === "done") {
                notification.success({
                  message: `${info.file.name} file uploaded successfully`,
                });
              } else if (info.file.status === "error") {
                notification.error({
                  message: `${info.file.name} file upload failed.`,
                });
              }
            }}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Row>
        <Row justify="center">Description</Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <TextArea
            placeholder="Description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center">Max Bookings Per Day</Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Max Bookings per day"
            value={maxBookingPerDay}
            onChange={(e) => {
              setMaxBookingPerDay(e.target.value);
            }}
            type="number"
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center">Min Days</Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Min Days"
            type="number"
            value={minDays}
            onChange={(e) => {
              setMinDays(e.target.value);
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center">Max Days</Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Max Days"
            type="number"
            value={maxDays}
            onChange={(e) => {
              setMaxDays(e.target.value);
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center">Price</Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Price"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
            type="number"
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Space size="middle">
            Live Telecast Support
            <Checkbox
              checked={liveTelecast}
              onChange={(e) => {
                setLiveTelecast(e.target.checked);
              }}
            />
          </Space>
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Space size="middle">
            Seva is live
            <Checkbox
              checked={isLive}
              onChange={(e) => {
                setIsLive(e.target.checked);
              }}
            />
          </Space>
        </Row>
        <Row justify="center">Time</Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <TimePicker.RangePicker
            use12Hours
            value={[
              startDate ? moment(startDate) : null,
              endDate ? moment(endDate) : null,
            ]}
            // format='h:mm a'
            onChange={(e) => {
              setStartDate(e[0].toDate());
              setEndDate(e[1].toDate());
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center">Offline Cut Off Time</Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Offline Cut Off Time hours"
            value={offlineCounterCutOff}
            onChange={(e) => {
              setOfflineCounterCutOff(e.target.value);
            }}
            type="number"
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center">Update Reason</Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <TextArea
            value={updateReason}
            onChange={(e) => {
              setUpdateReason(e.target.value);
            }}
          />
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(Seva);
