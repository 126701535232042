import React, { useState } from "react";

export const AccContext = React.createContext();

const AccContextProvider = (props) => {
  const [country, setCountry] = useState("India");
  const [docNumber, setDocNumber] = useState();
  const [roomBlockList, setRoomBlockList] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [gender, setGender] = useState();
  const [gothram, setGothram] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [address, setAddress] = useState();
  const [address2, setAddress2] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zip, setZip] = useState();
  const [docType, setDockType] = useState();
  const [roomtype, setRoomtype] = useState();
  const [blockId, setBlockId] = useState();
  const [persons, setPersons] = useState();
  const [blockCategoryId, setBlockCategoryId] = useState();
  const [blockName, setBlockName] = useState();
  const [deposite, setDeposite] = useState(0);
  const [roomId, setRoomId] = useState();
  const [price, setPrice] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [extraAmount, setExtraAmount] = useState(0);
  const [age, setAge] = useState();
  const [coupon, setCoupon] = useState();
  const [donorType, setDonorType] = useState();
  const [couponData, setCouponData] = useState("");
  const [couponDisc, setCouponDisc] = useState(0);

  const clearAccContext = () => {
    setCountry("");
    setDocNumber("");
    setRoomBlockList([]);
    setFirstName("");
    setLastName("");
    setGender("");
    setGothram("");
    setPhone("");
    setEmail("");
    setAddress("");
    setAddress2("");
    setCity("");
    setState("");
    setZip("");
    setDockType("");
    setRoomtype("");
    setBlockId("");
    setPersons("");
    setBlockCategoryId("");
    setDeposite("");
    setRoomId("");
    setPrice("");
    setAge("");
    setCoupon("");
    setDonorType("");
    setCoupon("");
    setCouponDisc("");
    setBlockName("");
    setCouponData("");
    setExtraAmount("");
  };

  const clearAmount = () => {
    setPrice(0);
    setExtraAmount(0);
    setCoupon("");
    setCouponDisc(0);
    setDeposite(0);
  };

  return (
    <AccContext.Provider
      value={{
        couponDisc,
        setCouponDisc,
        couponData,
        setCouponData,
        country,
        setCountry,
        docNumber,
        setDocNumber,
        roomBlockList,
        setRoomBlockList,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        gender,
        setGender,
        gothram,
        setGothram,
        phone,
        setPhone,
        email,
        setEmail,
        address,
        setAddress,
        address2,
        setAddress2,
        city,
        setCity,
        state,
        setState,
        zip,
        setZip,
        docType,
        setDockType,
        roomtype,
        setRoomtype,
        blockId,
        setBlockId,
        persons,
        setPersons,
        blockCategoryId,
        setBlockCategoryId,
        deposite,
        setDeposite,
        roomId,
        setRoomId,
        price,
        setPrice,
        totalAmount,
        setTotalAmount,
        extraAmount,
        setExtraAmount,
        clearAccContext,
        clearAmount,
        age,
        setAge,
        coupon,
        setCoupon,
        donorType,
        setDonorType,
        blockName,
        setBlockName,
      }}
    >
      {props.children}
    </AccContext.Provider>
  );
};

export default AccContextProvider;
