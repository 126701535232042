import { Breadcrumb } from 'antd';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

const BreadCrumb = ({ location }) => {
  const [pathItems, setPathItems] = useState([]);

  useEffect(() => {
    const items = location?.pathname?.split('/');
    items?.shift();
    setPathItems(items);
  }, [location?.pathname]);

  let path = '';

  return (
    <Breadcrumb>
      {pathItems.map((item, i) => {
        if (i === 0) {
          path = '';
        }
        path += '/' + item;
        return (
          <Breadcrumb.Item key={i}>
            <Link to={path} style={{ textTransform: 'capitalize' }}>
              {item}
            </Link>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default withRouter(BreadCrumb);
