/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Col,
  DatePicker,
  Input,
  notification,
  Row,
  Select,
  Space,
  Table,
  Upload,
  Drawer,
} from "antd";
import {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router-dom";
import {SearchOutlined, UploadOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import SevaService from "../../Services/Seva.service";
import {routeMapMini} from "../../constants/routeMap";
import fileDownload from "js-file-download";
import {baseURL} from "../../utils/axios";
import UserDetailsCard from "../ManageUsers/UserDetailsCard";
import Modal from "antd/lib/modal/Modal";
import {useReactToPrint} from "react-to-print";
import "./sevaform.scss";
import {sd_center_logo} from "../../assets/images";
const {Option} = Select;
const SevaBookingDetails = ({history}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [sevaNamesAndIds, setSevaNamesAndIds] = useState([]);
  const [sevaNamesLoading, setSevaNamesLoading] = useState(false);
  const [youtubeLinkModal, setYoutubeLinkModal] = useState();
  const [selectedYoutubeSevaId, setSelectedYoutubeSevaId] = useState(0);
  const [youtubeLink, setYoutubeLink] = useState("");
  const [youtubeLoading, setYoutubeLoading] = useState(false);
  const [downloadData, setDownloadData] = useState(null);
  const [downloadFlag, setDownloadFlag] = useState(null);
  const [sevaType, setSevaType] = useState();
  const [selectedSevaId, setSelectedSevaId] = useState("");
  const [bookingFromDate, setBookingFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [bookingToDate, setBookingToDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [orderBy, setOrderBy] = useState("ASC");
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [dateType, setDateType] = useState("performanceDate");

  const udayaasthamaList = [
    "Gopooja - 03:15 a.m.",
    "Suprabhatham - 03:30 a.m.",
    "Maha Mangala Harathi - 04:30 a.m.",
    "Panchamrutha Abhishekam -05:00 am",
    "Sri Vruddha Mallikarjuna Swamy vari Abhishekam - 05:30am",
    "Kumkumarchana",
    "Vedaseervachanam",
    "Rudra Homam - 08:00 am to 09:00 am.",
    "Sri Uma Maheswara Swamy Vratham - 09:30 am.",
    " Sri Valli Devasena Subrahmanya Swamy vari Kalyanam -10:00 am to 10:45 am",
    "Chandi Homam - 11:00 am.",
    "Sarpa Dosha Nivarana Pooja - 05:30 pm.",
    "Leela Kalyanotsavam - 07:30 pm.",
    "Ekantha Seva - 10:30 pm",
  ];
  const pradokshakalaList = [
    "Pradoshakala Maha Mangala Harathi - 05:30 p.m",
    "Panchamrutha Abhishekam - 06:00 p.m",
    "Kumkumarchana - 06:30 p.m.",
    "Vedaseervachanam",
  ];

  const getSevaNamesAndIds = async () => {
    setSevaNamesLoading(true);
    try {
      const sevaNamesAndIdData = await SevaService.getAllIdAndNames(sevaType);
      setSevaNamesAndIds(sevaNamesAndIdData);
    } catch (error) {
      notification.error({message: error.message});
    }
    setSevaNamesLoading(false);
  };

  const fetchBookings = async () => {
    setDataLoading(true);
    try {
      const respData = await SevaService.bookings(
        selectedSevaId,
        bookingFromDate,
        bookingToDate,
        sevaType,
        dateType,
        orderBy,
        tableCurrentPage,
        tablePageSize
      );
      setData(respData?.items);
      setTabelTotalSize(respData?.meta?.totalItems);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }

    setDataLoading(false);
  };

  const downloadFile = async (type, format = "Bookings") => {
    if (!bookingFromDate) {
      return notification.error({
        message: "From Date is not selected",
      });
    }
    if (!bookingToDate) {
      return notification.error({
        message: "To Date is not selected",
      });
    }

    notification.info({message: "Downloading file! Please wait."});
    if (type === "pdf") {
      SevaService.bookingsPdf(
        selectedSevaId,
        bookingFromDate,
        sevaType,
        orderBy
      )
        .then((data) => {
          fileDownload(
            data,
            `${bookingFromDate}-sevasBooked-${moment().format(
              "DD-MM-YYYY HH:MM"
            )}.pdf`
          );
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            notification.error({
              message: "Token Expired, redirecting to login page in 5 seconds",
            });
            history?.push(routeMapMini.Login);
          } else if (error?.response?.data?.message) {
            notification.error({message: error?.response?.data?.message});
          } else {
            console.error(error);
            notification.error({message: "something is wrong"});
          }
        });
    } else {
      SevaService.bookingsExcel(
        selectedSevaId,
        bookingFromDate,
        bookingToDate,
        sevaType,
        dateType,
        orderBy,
        format
      )
        .then((data) => {
          fileDownload(
            data,
            `${bookingFromDate}-sevasBooked-${moment().format(
              "DD-MM-YYYY HH:MM"
            )}.xlsx`
          );
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            notification.error({
              message: "Token Expired, redirecting to login page in 5 seconds",
            });
            history?.push(routeMapMini.Login);
          } else if (error?.response?.data?.message) {
            notification.error({message: error?.response?.data?.message});
          } else {
            console.error(error);
            notification.error({message: "something is wrong"});
          }
        });
    }
  };

  useEffect(() => {
    if (sevaType) getSevaNamesAndIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sevaType]);

  useEffect(() => {
    fetchBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sevaType,
    selectedSevaId,
    bookingFromDate,
    bookingToDate,
    orderBy,
    tableCurrentPage,
    tablePageSize,
  ]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleUserDetails = () => {
    setUserDetails(undefined);
    setShowUserDetails(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{padding: 8}}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: "block"}}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{width: 90, marginRight: 8}}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{width: 90}}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "created_at") {
      if (sorter.order === "ascend") {
        setOrderBy("ASC");
      } else {
        setOrderBy("DESC");
      }
    }
  };
  let refContainer = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => refContainer.current,
    copyStyles: true,
    bodyClass: "sevaform",

    onAfterPrint: () => {
      setDownloadData(null);
      setDownloadFlag(false);
    },
  });
  const columns = [
    {
      title: "user",
      dataIndex: "user_display_name",
      key: "user_display_name",
      render: (text, record) => {
        return (
          <div>
            <a
              onClick={() => {
                // history?.push(
                //   `${routeMapMini.userDetails}?userId=${record.user_id}`
                // );
                setUserDetails(record?.user_id);
                setShowUserDetails(true);
              }}
              style={{textTransform: "capitalize"}}
            >
              {record.user_display_name}
            </a>
          </div>
        );
      },
    },
    {
      title: "Seva Name",
      dataIndex: "sevaName",
      key: "id",
      ...getColumnSearchProps("sevaName"),
    },
    {
      title: "Devotee Names",
      dataIndex: "devoteeNames",
      key: "id",
    },

    {
      title: "Ticket ID",
      dataIndex: "ticket_id",
      key: "ticket_id",
    },

    {
      title: "Contact",
      dataIndex: "contact",
      key: "id",
    },
    {
      title: "Order Date",
      dataIndex: "created_at",
      key: "id",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.created_at - b.created_at,
    },
    {
      title: "Seva Date",
      dataIndex: "sevaDate",
      key: "id",
      render: (text) => {
        return moment(text).format(`YYYY-MM-DD`);
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (record) => {
        return record.sevaName === "Udayasthamana Seva" ||
          record.sevaName === "Pradoshakala Seva" ? (
          <span
            style={{cursor: "pointer", fontWeight: "600"}}
            onClick={() => {
              setDownloadFlag(true);
              setDownloadData(record);
            }}
          >
            Download Form
          </span>
        ) : (
          ""
        );
      },
    },
  ];

  const handleYoutubeOk = async () => {
    setYoutubeLoading(true);
    try {
      const resp = await SevaService.sendYoutubeLink(
        youtubeLink,
        selectedYoutubeSevaId
      );
      notification.success({message: resp});
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setYoutubeLink();
    setYoutubeLoading(false);
    setYoutubeLinkModal(false);
  };

  const handleYoutubeCancel = () => {
    setYoutubeLink();
    setYoutubeLinkModal(false);
  };

  return (
    <>
      <Row style={{marginBottom: "20px"}} justify={"start"} gutter={[20, 20]}>
        <Col style={{textAlign: "center"}}>
          <Row justify="start">Seva Type</Row>
          <Row justify="start">
            <Select
              style={{width: "200px", marginRight: "10px"}}
              onChange={(e) => {
                setSevaType(e);
                setSelectedSevaId("");
              }}
              value={sevaType}
              defaultValue=""
            >
              <Select.Option value="parokshaSeva">Paroksha Seva</Select.Option>
              <Select.Option value="pratyakshaSeva">
                Pratyaksha Seva
              </Select.Option>
              <Select.Option value="">All</Select.Option>
            </Select>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Seva Name</Row>
          <Row justify="start">
            <Select
              style={{width: "200px"}}
              loading={sevaNamesLoading}
              className="sd-select-seva"
              dropdownClassName="sd-select-dropdown-seva"
              onChange={setSelectedSevaId}
              value={selectedSevaId}
              defaultValue=""
            >
              {sevaNamesAndIds.map((item) => {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                );
              })}
              <Select.Option value={""} key={""}>
                All
              </Select.Option>
            </Select>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Seva Date</Row>
          <Row justify="start">
            <DatePicker.RangePicker
              onChange={(e) => {
                if (e?.[0] && e?.[1]) {
                  setBookingFromDate(moment(e[0]).format(`YYYY-MM-DD`));
                  setBookingToDate(moment(e[1]).format(`YYYY-MM-DD`));
                } else {
                  setBookingFromDate(undefined);
                  setBookingToDate(undefined);
                }
              }}
              format="DD-MM-YYYY"
              ranges={{
                Today: [moment(), moment()],
                "Last 7 Days": [moment().subtract(7, "d"), moment()],
                "Last 15 Days": [moment().subtract(15, "d"), moment()],
              }}
              defaultValue={[moment(bookingFromDate), moment(bookingToDate)]}
              defaultPickerValue={[
                moment(bookingFromDate),
                moment(bookingToDate),
              ]}
            />
          </Row>
        </Col>
        <Col>
          <Row justify="start">Date Type</Row>
          <Row justify="start">
            <Select
              style={{width: "180px"}}
              onChange={(e) => {
                setDateType(e);
              }}
              value={dateType}
            >
              <Select.Option value="orderDate">Order Date</Select.Option>
              <Select.Option value="performanceDate">
                Performance Date
              </Select.Option>
            </Select>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Update Tracking Id's</Row>
          <Row justify="start">
            <Upload
              name="file"
              action={`${baseURL}seva-report/upload/delivery-track-id`}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              }}
              fileList={[]}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={(info) => {
                if (info.file.status !== "uploading") {
                  console.log(info.file, info.fileList);
                }
                if (info.file.status === "done") {
                  notification.success({
                    message: `Success: ${info.file.response?.success}, Failure: ${info.file.response?.failure}`,
                  });
                } else if (info.file.status === "error") {
                  notification.error({
                    message: `${info.file.name} file upload failed.`,
                  });
                }
              }}
            >
              <Button icon={<UploadOutlined />}>Upload Tracking Files</Button>
            </Upload>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Update</Row>
          <Row justify="start">
            <Button
              type="primary"
              style={{minWidth: "100px"}}
              onClick={fetchBookings}
            >
              Fetch
            </Button>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Download</Row>
          <Row justify="start">
            <Button
              type="primary"
              style={{minWidth: "100px"}}
              onClick={() => downloadFile("excel")}
            >
              Excel
            </Button>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Download</Row>
          <Row justify="start">
            <Button
              type="primary"
              style={{minWidth: "100px"}}
              onClick={() => downloadFile("printFormat")}
            >
              Label Excel
            </Button>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Download</Row>
          <Row justify="start">
            <Button
              type="primary"
              style={{minWidth: "100px"}}
              onClick={() => downloadFile("excel", "portalFormat")}
            >
              Portal Excel
            </Button>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Download</Row>
          <Row justify="start">
            <Button
              type="primary"
              style={{minWidth: "100px"}}
              onClick={() => downloadFile("excel", "accountFormat")}
            >
              Account Excel
            </Button>
          </Row>
        </Col>

        <Col>
          <Row justify="start">Send Youtube Link</Row>
          <Row justify="start">
            <Button
              type="primary"
              style={{minWidth: "100px"}}
              onClick={() => {
                setSevaType("parokshaSeva");
                setYoutubeLinkModal(true);
              }}
            >
              Youtube Link
            </Button>
          </Row>
        </Col>
      </Row>

      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Total Bookings</span>
        </Space>
      </Row>

      <Table
        style={{width: "100%", textTransform: "capitalize"}}
        columns={columns}
        dataSource={data}
        loading={dataLoading}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        scroll={{x: true}}
        size="small"
        onChange={handleTableChange}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <Row>
                <p style={{fontWeight: "bolder"}}>Address:</p>
                {record.address}
              </Row>
            </>
          ),
        }}
      ></Table>
      <Drawer
        title={`User`}
        placement="right"
        closable={false}
        onClose={handleUserDetails}
        visible={showUserDetails}
        width={500}
        destroyOnClose={true}
      >
        <UserDetailsCard userid={userDetails} />
      </Drawer>

      <Modal
        title="Send Youtube Link"
        visible={youtubeLinkModal}
        onOk={handleYoutubeOk}
        onCancel={handleYoutubeCancel}
        okButtonProps={{loading: youtubeLoading}}
      >
        <Col>
          <Row justify="start">Seva Name</Row>
          <Row justify="start">
            <Select
              onChange={setSelectedYoutubeSevaId}
              style={{width: "200px"}}
            >
              {sevaNamesAndIds.map((item) => {
                return (
                  <Select.Option value={item.id}> {item.name} </Select.Option>
                );
              })}
            </Select>
          </Row>
          <Row justify="center">Youtube Link</Row>
          <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
            <Input
              placeholder="Short Youtube Link"
              value={youtubeLink}
              onChange={(e) => {
                setYoutubeLink(e.target.value);
              }}
              style={{width: "400px"}}
            />
          </Row>
        </Col>
      </Modal>

      <Modal
        title="Devotee Data Form"
        visible={downloadFlag}
        onOk={() => {
          console.log(downloadData);
          handlePrint();
        }}
        onCancel={() => {
          setDownloadData(null);
          setDownloadFlag(false);
        }}
      >
        <div ref={refContainer} style={{padding: "5px "}} className="sevaform">
          <Row justify="center">
            <img src={sd_center_logo} alt="" height={"60px"} />
          </Row>{" "}
          <Row justify="center">
            <h2 style={{textAlign: "center"}}>
              Sri Bhramaramba Mallikarjuna Swamy Varla Devasthanam - Srisailam{" "}
            </h2>
          </Row>{" "}
          <Row justify="center">
            {" "}
            <h3>Devotee Seva Form</h3>
          </Row>
          <br />
          <Row justify="space-between" style={{textAlign: "left"}}>
            <Col span={8} className="t-l">
              <b>Seva name</b>
            </Col>{" "}
            <Col span={16} className="t-l">
              : <b>{downloadData?.sevaName}</b>
            </Col>
          </Row>
          <Row justify="space-between" style={{textAlign: "left"}}>
            <Col span={8} className="t-l">
              <b>Ticket ID</b>
            </Col>{" "}
            <Col span={16} className="t-l">
              : {downloadData?.ticket_id}
            </Col>
          </Row>
          <Row justify="space-between" style={{textAlign: "left"}}>
            <Col span={8} className="t-l">
              <b>Devotee Names</b>
            </Col>
            <Col span={16} className="t-l">
              : {downloadData?.devoteeNames}
            </Col>
          </Row>
          <Row justify="space-between" style={{textAlign: "left"}}>
            <Col span={8} className="t-l">
              <b>Seva Date</b>{" "}
            </Col>
            <Col span={16} className="t-l">
              {" "}
              : {moment(downloadData?.sevaDate).format("DD-MM-YYYY")}
            </Col>
          </Row>
          <Row justify="space-between" style={{textAlign: "left"}}>
            <Col span={8} className="t-l">
              {" "}
              <b>Gothram</b>
            </Col>{" "}
            <Col span={16} className="t-l">
              : {downloadData?.gotram ?? "-"}
            </Col>
          </Row>
          <Row justify="space-between" style={{textAlign: "left"}}>
            <Col span={8} className="t-l">
              {" "}
              <b>Address</b>{" "}
            </Col>
            <Col span={16} className="t-l">
              : {downloadData?.address ?? "-"}
            </Col>
          </Row>
          <Row justify="space-between" style={{textAlign: "left"}}>
            <Col span={8} className="t-l">
              {" "}
              <b>Attender Name</b>{" "}
            </Col>
            <Col span={16} className="t-l">
              : {downloadData?.address ?? "-"}
            </Col>
          </Row>
          <Row justify="space-between" style={{textAlign: "left"}}>
            <Col span={8} className="t-l">
              {" "}
              <b>Accommodation (Building)</b>{" "}
            </Col>
            <Col span={16} className="t-l">
              : {downloadData?.address ?? "-"}
            </Col>
          </Row>
          <br />
          <Row
            justify="space-between"
            style={{
              border: "1px solid black",
              borderCollapse: "collapse",
              borderBottom: "none",
            }}
          >
            {" "}
            <Col span={16} style={{padding: "3px"}}>
              Seva Name
            </Col>
            <Col
              span={4}
              style={{
                textAlign: "center",
                borderLeft: "1px solid black",
                padding: "3px",
              }}
            >
              Clerk
            </Col>
            <Col
              span={4}
              style={{
                textAlign: "center",
                borderLeft: "1px solid black",
                padding: "3px",
              }}
            >
              SUPT
            </Col>
          </Row>
          {downloadData?.sevaName === "Udayasthamana Seva"
            ? udayaasthamaList.map((item, i) => {
                console.log(udayaasthamaList?.length);

                return (
                  <Row
                    justify="space-between"
                    style={{
                      textAlign: "left",
                      border: "1px solid black",
                      borderCollapse: "collapse",
                      borderBottom:
                        i + 1 === udayaasthamaList?.length
                          ? "1px solid black"
                          : "none",
                    }}
                  >
                    <Col span={16} style={{padding: "3px"}}>
                      {i + 1}. {item}
                    </Col>
                    <Col
                      span={4}
                      style={{
                        textAlign: "end",
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    ></Col>
                    <Col
                      span={4}
                      style={{
                        textAlign: "end",
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    ></Col>
                  </Row>
                );
              })
            : pradokshakalaList.map((item, i) => {
                return (
                  <Row
                    justify="space-between"
                    style={{
                      border: "1px solid black",
                      borderCollapse: "collapse",
                      borderBottom:
                        i + 1 === pradokshakalaList?.length
                          ? "1px solid black"
                          : "none",
                      textAlign: "left",
                    }}
                  >
                    <Col span={16} style={{padding: "3px"}}>
                      {i + 1}. {item}
                    </Col>
                    <Col
                      span={4}
                      style={{
                        textAlign: "end",
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    ></Col>
                    <Col
                      span={4}
                      style={{
                        textAlign: "end",
                        borderLeft: "1px solid black",
                        padding: "3px",
                      }}
                    ></Col>
                  </Row>
                );
              })}
          <br />
          <Row gutter={[10, 10]}>
            {" "}
            <Col>
              <Space>
                <p className="boldText">AEO :</p>
                <p className="boldText">__________</p>
              </Space>
            </Col>{" "}
            <Col>
              <Space>
                <p className="boldText">Devotee :</p>
                <p className="boldText">__________</p>
              </Space>
            </Col>
          </Row>
          <Row>Devotee Feedback: </Row>
        </div>
      </Modal>
    </>
  );
};

export default withRouter(SevaBookingDetails);
