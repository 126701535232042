import {Breadcrumb, Col, Row} from "antd";
import React, {useContext} from "react";
import {withRouter} from "react-router";
import {ShiftContext} from "../../context/shift.context";
import moment from "moment";
import {FormatNumberINRCurrency} from "../../utils/currencyFormat";

const ShiftDetailsBreadCrumb = ({location}) => {
  const {shiftData, shiftShowData} = useContext(ShiftContext);

  return shiftData !== "" || shiftData ? (
    <Breadcrumb
      style={{
        padding: "5px 10px",
        // borderTop: "1px solid grey",
        // backgroundColor: "#dedede",,
        boxShadow: "0px 7px 9px -8px",
      }}
    >
      {shiftData?.id ? (
        <Row
          style={{color: "black", textAlign: "left"}}
          justify="space-between"
        >
          <Col>
            {" "}
            User Name: {shiftShowData?.shiftReport?.sd_user?.display_name}
          </Col>{" "}
          |{" "}
          <Col>
            Login Counter Name: {shiftShowData?.shiftReport?.sd_counter?.name}
          </Col>{" "}
          |
          <Col>
            Login Time :{" "}
            {moment(shiftShowData?.shift?.login).format("hh:mm A  DD-MM-YYYY")}
          </Col>{" "}
          |<Col>Shift Name : {shiftShowData?.shiftReport?.sd_shift?.name}</Col>{" "}
          |{" "}
          <Col>
            Total Amount:
            {FormatNumberINRCurrency(
              shiftShowData?.shiftReport?.amount_deposited +
                shiftShowData?.shiftReport?.accommodation_services_amount +
                shiftShowData?.shiftReport?.darshanam_services_amount +
                shiftShowData?.shiftReport?.pratyaksha_seva_services_amount +
                shiftShowData?.shiftReport?.gst_amount +
                shiftShowData?.shiftReport?.prasadam_services_amount +
                shiftShowData?.shiftReport?.donation_services_amount +
                shiftShowData?.shiftReport?.publication_services_amount +
                shiftShowData?.shiftReport?.kalyanakatta_services_amount +
                shiftShowData?.shiftReport?.thulabharam_services_amount +
                shiftShowData?.shiftReport?.toll_gate_services_amount
            )}
          </Col>
          |
          <Col style={{textAlign: "left"}}>
            Total Tickets:
            {shiftShowData?.shiftReport?.publication_count +
              shiftShowData?.shiftReport?.pratyaksha_seva_tickets_count +
              shiftShowData?.shiftReport?.prasadam_count +
              shiftShowData?.shiftReport?.donation_count +
              shiftShowData?.shiftReport?.toll_gate_count +
              shiftShowData?.shiftReport?.kalyanakatta_count +
              shiftShowData?.shiftReport?.thulabharam_count +
              shiftShowData?.shiftReport?.darshanam_tickets_count}
          </Col>
        </Row>
      ) : (
        ""
      )}
    </Breadcrumb>
  ) : (
    ""
  );
};

export default withRouter(ShiftDetailsBreadCrumb);
