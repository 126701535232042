import Axios from "../utils/axios";

const ThulabharamService = {
  all: async () => {
    const resp = await Axios.get(`thulabharam/all`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  //counter item list
  // allByType: async () => {
  //     const resp = await Axios.get(`kalyanakatta-counter/counter-kalyanakatta/list`, {
  //         headers: {
  //             Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         }
  //     });
  //     return resp.data;
  // },

  // allIdAndNames: async () => {
  //     const resp = await Axios.get(`darshanam/all/id-and-names`, {
  //         headers: {
  //             Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         }
  //     });
  //     return resp.data;
  // },

  ThulabharambyId: async (id) => {
    const resp = await Axios.get(`/thulabharam/byId/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  delete: async (id) => {
    const resp = await Axios.delete(`thulabharam/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  newThulabharam: async (ThulabharamName, disabled, price) => {
    const body = {
      name: ThulabharamName,
      price: price,
      disabled: disabled,
    };
    Object.keys(body).forEach((key) => {
      if (body[key] === undefined || !body[key]) {
        delete body[key];
      }
    });
    const resp = await Axios.post(`thulabharam`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  editThulabharam: async (
    updateThulabharamId,
    ThulabharamName,
    disabled,
    price
  ) => {
    const body = {
      id: updateThulabharamId,
      name: ThulabharamName,
      price: price,
      disabled: disabled,
    };
    console.log(body);
    Object.keys(body).forEach((key) => {
      if (body[key] === undefined || !body[key]) {
        delete body[key];
      }
    });
    const resp = await Axios.put(`/thulabharam/edit`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  bookThulabharam: async (body) => {
    let resp = await Axios.post(`/cart-counter/thulabharam`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  //manage counter component API's
  getAllThulabharamList: async () => {
    let resp = await Axios.get(
      `/thulabharam-counter/counter-thulabharam/list`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  getAllThulabharamCounterListbyId: async (id) => {
    let resp = await Axios.get(
      `/thulabharam-counter/counter/byThulabharamId/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  addCounterThulabharambyId: async (thulabharamId, counterId) => {
    const body = {
      thulabharamId: thulabharamId,
      counterId: counterId,
    };
    Object.keys(body).forEach((key) => {
      if (body[key] === undefined || !body[key]) {
        delete body[key];
      }
    });
    const resp = await Axios.post(`/thulabharam-counter/new`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    return resp.data;
  },

  deleteCounterbyThulabharamId: async (id) => {
    let resp = await Axios.delete(
      `/thulabharam-counter/ByThulabharamCounterId/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  getThulabharamReportCollection: async (fromDate, toDate, txType) => {
    const resp = await Axios.get(
      `/thulabharam-report/collection?fromDate=${fromDate}&toDate=${toDate}&paymentStatus=success&txType=${txType}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        }
      }
    );
    return resp.data;
  },
};
export default ThulabharamService;
