/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Badge,
  Button,
  Input,
  notification,
  Popconfirm,
  Space,
  Table,
} from "antd";
import {useState} from "react";
import {withRouter} from "react-router-dom";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {routeMapMini} from "../../../../constants/routeMap";
import SevaService from "../../../../Services/Seva.service";

const SevaSlotCounterTable = ({
  list,
  blockID,
  loading,
  setLoading,
  setUpdateTable,
  getCountersByBlock,
  slotId,
  setTemp,
  setEdit,
  history,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{padding: 8}}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: "block"}}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{width: 90, marginRight: 8}}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{width: 90}}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Counter Name",
      dataIndex: "sd_counter",
      key: "sd_counter",
      ellipsis: true,
      ...getColumnSearchProps("name"),
      render: (text) => {
        return (
          text && (
            <div
              style={{
                width: "200px",
                whiteSpace: "initial",
                textTransform: "capitalize",
              }}
            >
              {text.name}
            </div>
          )
        );
      },
    },

    {
      title: "Max bookings for Counter",
      dataIndex: "max_booking_per_day",
      key: "max_booking_per_day",
    },

    {
      title: "Action",
      fixed: "right",
      responsive: ["md", "lg", "xl", "xsl"],
      render: (record) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                setTemp(record);
                setEdit(true);
              }}
            >
              Edit
            </a>
            <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={async () => {
                setLoading(true);
                try {
                  await SevaService.deleteCounterbySevaId(record.id);
                  setUpdateTable(Math.random(100));

                  notification.success({
                    message: `Counter ${record.id} has been deleted`,
                  });
                  getCountersByBlock();
                } catch (error) {
                  if (error?.response?.status === 401) {
                    notification.error({
                      message:
                        "Token Expired, redirecting to login page in 5 seconds",
                    });
                    history?.push(routeMapMini.Login);
                  }
                  if (error.response?.data?.message) {
                    notification.error({
                      message: error.response?.data?.message,
                    });
                  } else {
                    notification.error({message: error.message});
                  }
                }

                setLoading(false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      tableLayout="fixed"
      scroll={{x: true}}
      loading={loading}
      style={{width: "100%", textTransform: "capitalize"}}
      columns={columns}
      dataSource={list}
    ></Table>
  );
};

export default withRouter(SevaSlotCounterTable);
