import { Card, Col, Divider, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import ProfileImage from "../../../assets/profile_pic_holder.png";
import {
  MailOutlined,
  PhoneOutlined,
  CalendarOutlined,
  HomeOutlined,
  LoginOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import moment from "moment";

const UserCard = ({ userLoading, userDetails, cardwidth }) => {
  const [userAddress, setUserAddress] = useState("");

  useEffect(() => {
    const addressArr = [];
    if (userDetails?.address1) {
      addressArr.push(userDetails?.address1);
    }
    if (userDetails?.address2) {
      addressArr.push(userDetails?.address2);
    }
    if (userDetails?.city) {
      addressArr.push(userDetails?.city);
    }
    if (userDetails?.state) {
      addressArr.push(userDetails?.state);
    }
    if (userDetails?.country) {
      addressArr.push(userDetails?.country);
    }
    if (userDetails?.pincode) {
      addressArr.push(userDetails?.pincode);
    }
    setUserAddress(addressArr.join(", "));
  }, [userDetails]);
  console.log(cardwidth);
  return (
    <Row>
      <Col span={24}>
        <Card
          style={{ marginBottom: 24, width: `${cardwidth}` }}
          loading={userLoading}
        >
          {userDetails && Object.keys(userDetails).length ? (
            <div>
              <div style={{ width: "50%", float: "left" }}>
                <img
                  src={ProfileImage}
                  alt="profile"
                  style={{
                    height: "180px",
                    paddingBottom: "30px",
                    float: "left",
                  }}
                ></img>
              </div>
              <div
                style={{
                  width: "50%",
                  textAlign: "left",
                  float: "right",
                  margin: "24px 0px",
                }}
              >
                <div
                  style={{
                    textTransform: "capitalize",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {userDetails.display_name}{" "}
                  {userDetails?.registered ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                  ) : (
                    <CloseCircleTwoTone twoToneColor="#eb2f96" />
                  )}
                </div>
                ({userDetails.role})
                <div
                  style={{
                    textTransform: "capitalize",
                    fontSize: "16px",
                    fontWeight: "lighter",
                  }}
                >
                  {userDetails.first_name} {userDetails.last_name}
                </div>
                <div
                  style={{
                    textTransform: "capitalize",
                    fontSize: "16px",
                  }}
                >
                  {userDetails.gothram}
                </div>
              </div>
              <Divider dashed />
              <div style={{ textAlign: "left" }}>
                <Row gutter={[10, 0]}>
                  <Col>
                    <PhoneOutlined />
                  </Col>
                  <Col>
                    <Typography.Paragraph copyable>
                      {userDetails.phone}
                    </Typography.Paragraph>
                  </Col>
                </Row>
                <Row gutter={[10, 0]}>
                  <Col>
                    <MailOutlined />
                  </Col>
                  <Col>
                    <Typography.Paragraph copyable>
                      {userDetails.email}
                    </Typography.Paragraph>
                  </Col>
                </Row>
                <Row gutter={[10, 10]}>
                  <Col>
                    <CalendarOutlined />
                  </Col>
                  <Col>{userDetails.date_of_birth}</Col>
                </Row>
                <Row gutter={[10, 10]}>
                  <Col>
                    <HomeOutlined />
                  </Col>
                  <Col>{userAddress}</Col>
                </Row>
                <Row gutter={[10, 10]}>
                  <Col>
                    <LoginOutlined />
                  </Col>
                  <Col>
                    {userDetails?.logout_at &&
                      moment(userDetails?.logout_at)
                        .utcOffset("+5:30")
                        .format("YYYY-MM-DD hh:mm A")}
                  </Col>
                </Row>
                <Row gutter={[10, 10]}>
                  <Col>Created On:</Col>
                  <Col>
                    {userDetails?.created_at &&
                      moment(userDetails?.created_at)
                        .utcOffset("+5:30")
                        .format("YYYY-MM-DD hh:mm A")}
                  </Col>
                </Row>
                <Row gutter={[10, 10]}>
                  <Col>Updated On:</Col>
                  <Col>
                    {userDetails?.updated_at &&
                      moment(userDetails?.updated_at)
                        .utcOffset("+5:30")
                        .format("YYYY-MM-DD hh:mm A")}
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            "loading..."
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default UserCard;
