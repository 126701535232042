import Axios from "../utils/axios";

const SevaService = {
  getAll: async (sevaType) => {
    const resp = await Axios.get(`/seva/all?sevaType=${sevaType}`);
    return resp.data;
  },
  bookSevaTicket: async (body) => {
    let resp = await Axios.post(`/cart-counter/seva`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  getAvailableSeva: async (seva) => {
    const resp = await Axios.get(`/seva/all?sevaType=${seva}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  getAllIdAndNames: async (sevaType) => {
    const resp = await Axios.get(`/seva/all/id-and-names?sevaType=${sevaType}`);
    return resp.data;
  },
  getAvailableSevaSlots: async (date, sevaId) => {
    const resp = await Axios.get(
      `/seva-counter/counter-query?bookingDate=${date}&sevaId=${sevaId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  newSeva: async (
    sevaType,
    name,
    location,
    startDate,
    endDate,
    description,
    imageUrl,
    maxBookingPerDay,
    minDays,
    maxDays,
    disabled,
    liveTelecast,
    price,
    update_reason,
    short_id,
    offline_counter_cut_off
  ) => {
    const requestObj = {
      type: sevaType,
      name,
      location: location,
      startDate,
      endDate,
      description: description,
      imageUrl: imageUrl,
      maxBookingPerDay: maxBookingPerDay ?? 108,
      min_days: minDays ?? 2,
      max_days: maxDays ?? 30,
      disabled,
      liveTelecast,
      price,
      update_reason,
      short_id,
      offline_counter_cut_off,
    };
    if (!location) {
      delete requestObj.location;
    }
    if (!description) {
      delete requestObj.description;
    }
    if (!imageUrl) {
      delete requestObj.imageUrl;
    }
    if (!short_id) {
      delete requestObj.short_id;
    }
    const resp = await Axios.post(`/seva/new`, requestObj, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  editSeva: async (
    updateId,
    name,
    location,
    startDate,
    endDate,
    description,
    imageUrl,
    maxBookingPerDay,
    minDays,
    maxDays,
    disabled,
    liveTelecast,
    price,
    update_reason,
    short_id,
    offline_counter_cut_off
  ) => {
    const requestObj = {
      sevaId: updateId,
      name,
      location: location,
      startDate,
      endDate,
      description: description,
      imageUrl: imageUrl,
      maxBookingPerDay: maxBookingPerDay ?? 108,
      min_days: minDays ?? 2,
      max_days: maxDays ?? 30,
      disabled,
      liveTelecast,
      price,
      update_reason,
      short_id,
      offline_counter_cut_off,
    };
    if (!location) {
      delete requestObj.location;
    }
    if (!description) {
      delete requestObj.description;
    }
    if (!imageUrl) {
      delete requestObj.imageUrl;
    }
    if (!short_id) {
      delete requestObj.short_id;
    }
    const resp = await Axios.post(`/seva/edit`, requestObj, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  deleteSeva: async (id) => {
    const resp = await Axios.delete(`seva/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  getSevaById: async (id) => {
    const resp = await Axios.get(`seva/by-id?sevaId=${id}`);
    return resp.data;
  },

  // not passing paymentStatus so gets back only success
  bookings: async (
    sevaId,
    bookingFromDate,
    bookingToDate,
    sevaType,
    dateType,
    orderBy,
    page,
    limit
  ) => {
    let query = `page=${page}&limit=${limit}&paymentStatus=success&orderBy=${orderBy}&dateType=${dateType}&`;
    if (bookingFromDate) {
      query += `bookingFromDate=${bookingFromDate}&`;
    }
    if (bookingToDate) {
      query += `bookingToDate=${bookingToDate}&`;
    }
    if (sevaId) {
      query += `sevaId=${sevaId}&`;
    }
    if (sevaType) {
      query += `sevaType=${sevaType}&`;
    }
    const resp = await Axios.get(`seva-report/bookings?${query}&`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  bookingsExcel: async (
    sevaId,
    bookingFromDate,
    bookingToDate,
    sevaType,
    dateType,
    orderBy,
    format = "Bookings"
  ) => {
    let query = `paymentStatus=success&orderBy=${orderBy}&format=${format}&dateType=${dateType}&`;
    if (bookingFromDate) {
      query += `bookingFromDate=${bookingFromDate}&`;
    }
    if (bookingToDate) {
      query += `bookingToDate=${bookingToDate}&`;
    }
    if (sevaId) {
      query += `sevaId=${sevaId}&`;
    }
    if (sevaType) {
      query += `sevaType=${sevaType}&`;
    }
    const resp = await Axios.get(`/seva-report/bookings/excel?${query}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      responseType: "blob",
    });
    return resp.data;
  },

  bookingsPdf: async (sevaId, bookingDate, sevaType, orderBy) => {
    let query = `paymentStatus=success&orderBy=${orderBy}&`;
    if (bookingDate) {
      query += `bookingDate=${bookingDate}&`;
    }
    if (sevaId) {
      query += `sevaId=${sevaId}&`;
    }
    if (sevaType) {
      query += `sevaType=${sevaType}&`;
    }
    const resp = await Axios.get(`/seva-report/bookings/pdf?${query}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      responseType: "blob",
    });
    return resp.data;
  },

  disableDates: async (sevaIds, fromDate, toDate, description) => {
    const requestObj = {
      sevaIds: sevaIds,
      start_date: fromDate,
      end_date: toDate,
      description: description,
    };
    if (!description) {
      delete requestObj.description;
    }
    const resp = await Axios.post(`/seva/disable/date`, requestObj, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  getDisabledDates: async (page, limit) => {
    const resp = await Axios.get(
      `/seva/disable/date?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  deleteDisabledDate: async (id) => {
    const resp = await Axios.delete(`/seva/disable/date?id=${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  getCollectionBySeva: async (body) => {
    console.log(body);
    const resp = await Axios.get(
      `/seva-report/collection?aggregateBy=${body?.type}&fromDate=${
        body?.fromDate
      }&toDate=${body?.toDate}&dateType=${
        body?.dateType
      }&paymentStatus=success&sevaType=${
        body?.sevaType ? body?.sevaType : "all"
      }&txType=${body?.txType}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  sendYoutubeLink: async (youtubeLink, seva_id) => {
    const resp = await Axios.post(
      `/seva-report/send-youtube-link`,
      {
        youtube_link: youtubeLink,
        sd_seva_id: seva_id,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  getSevaAllSlots: async (id) => {
    const resp = await Axios.get(`/seva/slots/bySevaId/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return resp.data;
  },
  addSevaSlot: async (body) => {
    const resp = await Axios.post(`/seva/slots/new`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });

    return resp.data;
  },
  editSevaSlot: async (body) => {
    const resp = await Axios.put(`/seva/slots/edit`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });

    return resp.data;
  },
  deleteSevaSlotById: async (id) => {
    const resp = await Axios.delete(`seva/slots/delete/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return resp.data;
  },

  getAllSevaCounters: async (id) => {
    const resp = await Axios.get(
      `/seva-counter/counters/BySlotId?sevaSlotId=${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );
    return resp.data;
  },
  getCounterBySevaId: async (id) => {
    const resp = await Axios.get(
      `/seva-counter/counters/sevaSlotCounterId/${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );
    return resp.data;
  },
  deleteCounterbySevaId: async (id) => {
    const resp = await Axios.delete(
      `/seva-counter/counters/sevaSlotCounterId/${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );
    return resp.data;
  },
  addCounterbySevaId: async (body) => {
    const resp = await Axios.post(`/seva-counter/counters/new`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });

    return resp.data;
  },
  editCounterbySevaId: async (body) => {
    const resp = await Axios.put(`/seva-counter/counters/edit`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return resp.data;
  },

  scanPerformanceTicket: async (ticketId) => {
    const resp = await Axios.post(
      `/cart/ticket/performance-scan/${ticketId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
};

export default SevaService;
