import Axios from "../utils/axios";

const srisailaPrabhaService = {
  // not passing paymentStatus so gets back only success
  bookings: async (fromDate, toDate, type, page, limit) => {
    let query = `paymentStatus=success&page=${page}&limit=${limit}&`;

    if (fromDate) {
      query += `fromDate=${fromDate}&`;
    }
    if (toDate) {
      query += `toDate=${toDate}&`;
    }
    if (type) {
      query += `type=${type}&`;
    }

    const resp = await Axios.get(`srisaila-prabha-report/bookings?${query}&`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  bookingsExcel: async (fromDate, type) => {
    const resp = await Axios.get(
      `/srisaila-prabha-report/bookings/excel?fromDate=${fromDate}&type=${type}&paymentStatus=success`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        responseType: "blob",
      }
    );
    return resp.data;
  },

  getCollectionByType: async (fromDate, toDate, txType) => {
    const resp = await Axios.get(
      `/publication-report/collection?fromDate=${fromDate}&aggregateBy=publicationName&toDate=${toDate}&paymentStatus=success&txType=${txType}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
};

export default srisailaPrabhaService;
