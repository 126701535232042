import Axios from "../utils/axios";

const KailasaKankanamService = {
    //used
    all: async () => {
        const resp = await Axios.get(`kailasa-kankanam/all`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
        });
        return resp.data;
    },

    //used
    KailasaKankanamById: async (id) => {
        const resp = await Axios.get(`/kailasa-kankanam/byId/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        });
        return resp.data;
    },

    //used
    delete: async (id) => {
        const resp = await Axios.delete(`kailasa-kankanam/disableById/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        });
        return resp.data;
    },

    //used
    newKailasaKankanam: async (kankanamName, price) => {
        const body = {
            name: kankanamName,
            type: "kailasa-kankanam",
            price,
        };
        Object.keys(body).forEach((key) => {
            if (body[key] === undefined || !body[key]) {
                delete body[key];
            }
        });
        const resp = await Axios.post(`kailasa-kankanam`, body, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        });
        return resp.data;
    },

    //used
    editKailasaKankanam: async (updateKailasaKankanamId, kankanamName, price) => {
        const body = {
            kailasa_kankanam_id: updateKailasaKankanamId,
            name: kankanamName,
            type: "kailasa-kankanam",
            price,
        };
        Object.keys(body).forEach((key) => {
            if (body[key] === undefined || !body[key]) {
                delete body[key];
            }
        });
        const resp = await Axios.put(`/kailasa-kankanam/edit`, body, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        });
        return resp.data;
    },

    //used
    bookKailasaKankanam: async (body) => {
        let resp = await Axios.post(`/cart-counter/kailasa-kankanam`, body, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        });
        return resp.data;
    },
    //used for fetching counter available list
    allByType: async () => {
        const resp = await Axios.get(`kailasa-kankanam-counter/counter-kailasaKankanam/list`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        });
        return resp.data;
    },

    //used
    getAllKailasaKankanamCounterList: async (id) => {
        let resp = await Axios.get(`kailasa-kankanam-counter/counter/byKailasaKankanamId/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        });
        return resp.data;
    },

    //used
    addCounterbykankanamId: async (body) => {
        const resp = await Axios.post(`/kailasa-kankanam-counter/new`, body, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
        });

        return resp.data;
    },

    //used
    deleteCounterbykankanamId: async (id) => {
        let resp = await Axios.delete(
            `kailasa-kankanam-counter/ByKailasaKankanamCounterId/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            }
        );
        return resp.data;
    },

    //used
    getKailasaKankanamReportCollection: async (fromDate, toDate, txType) => {
        const resp = await Axios.get(
            `/kailasa-kankanam-report/collection?fromDate=${fromDate}&aggregateBy=publicationName&toDate=${toDate}&paymentStatus=success&txType=${txType}`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            }
        );
        return resp.data;
    },
};
export default KailasaKankanamService;
