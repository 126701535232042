/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Col,
  DatePicker,
  Input,
  notification,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
  Card,
  Tabs,
  Drawer,
  Empty,
} from "antd";

import {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import {routeMapMini} from "../../constants/routeMap";
import CartService from "../../Services/Cart.service";
// import Modal from "antd/lib/modal/Modal";
import ReactJson from "react-json-view";
import {FormatNumberINRCurrency} from "../../utils/currencyFormat";
import PaymentService from "../../Services/Payment.service";
import UserDetailsCard from "../ManageUsers/UserDetailsCard";
import fileDownload from "js-file-download";

const AllPurchases = ({history}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableSortBy, setTableSortBy] = useState("date");
  const [tableSortType, setTableSortType] = useState("DESC");

  const [cartId, setCardId] = useState();
  const [paymentId, setPaymentId] = useState();
  const [ticketId, setTicketId] = useState();
  const [userId, setUserId] = useState();
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [paymentStatus, setPaymentStatus] = useState();
  const [paymentType, setPaymentType] = useState("all");
  const [data, setData] = useState([]);

  const [tableLoading, setTableLoading] = useState(false);

  const [showCartDetails, setShowCartDetails] = useState(false);
  const [cartDetails, setCartDetails] = useState();
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [selectedOrderPayments, setSelectedOrderPayments] = useState();
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);

  const {TabPane} = Tabs;

  const fetchPurchases = async (view) => {
    setTableLoading(true);
    try {
      const respData = await CartService.allPurchases(
        view,
        fromDate,
        toDate,
        paymentId,
        ticketId,
        paymentType,
        paymentStatus,
        cartId,
        userId,
        tableCurrentPage,
        tablePageSize,
        tableSortBy,
        tableSortType
      );
      setData(respData?.items);
      setTabelTotalSize(respData?.meta?.totalItems);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setTableLoading(false);
  };
  const downloadPurchases = async () => {
    notification.info({message: "Downloading file! Please wait."});
    CartService.allPurchases(
      "download",
      fromDate,
      toDate,
      paymentId,
      ticketId,
      paymentType,
      paymentStatus,
      cartId,
      userId,
      tableCurrentPage,
      tablePageSize,
      tableSortBy,
      tableSortType
    )
      .then((data) => {
        fileDownload(
          data,
          `all-purchases-${moment().format("DD-MM-YYYY HH:MM")}.xlsx`
        );
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          notification.error({
            message: "Token Expired, redirecting to login page in 5 seconds",
          });
          history?.push(routeMapMini.Login);
        } else if (error?.response?.data?.message) {
          notification.error({message: error?.response?.data?.message});
        } else {
          console.error(error);
          notification.error({message: "something is wrong"});
        }
      });
  };

  useEffect(() => {
    fetchPurchases("view");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableCurrentPage, tablePageSize, tableSortBy, tableSortType]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{padding: 8}}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: "block"}}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{width: 90, marginRight: 8}}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{width: 90}}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "paymentAmount") {
      setTableSortBy("amount");
    } else if (sorter.field === "createdAt") {
      setTableSortBy("date");
    }
    if (sorter.order === "ascend") {
      setTableSortType("ASC");
    } else if (sorter.order === "descend") {
      setTableSortType("DESC");
    }
  };

  const columns = [
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      ...getColumnSearchProps("user"),
      render: (text, record) => {
        return (
          <div>
            <a
              onClick={() => {
                // history?.push(
                //   `${routeMapMini.userDetails}?userId=${record.userId}`
                // );
                setUserDetails(record?.userId);
                setShowUserDetails(true);
              }}
              style={{textTransform: "capitalize"}}
            >
              {record.userName || record.userPhone}
            </a>
          </div>
        );
      },
    },
    {
      title: "Payment Id",
      dataIndex: "paymentId",
      key: "paymentId",
    },
    {
      title: "Cart Id",
      dataIndex: "cartId",
      key: "cartId",
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (text) => {
        if (text === "success") {
          return <Tag color="green">Success</Tag>;
        } else if (text === "failed") {
          return <Tag color="red">Failed</Tag>;
        } else if (text === "created") {
          return <Tag color="blue">Created</Tag>;
        } else {
          return <Tag color="orange">{text}</Tag>;
        }
      },
    },
    {
      title: "Amount",
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      sorter: (a, b) => a.amount > b.amount,
      render: FormatNumberINRCurrency,
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: "Cart Contains",
      dataIndex: "cartContains",
      key: "cartContains",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt > b.createdAt,
      render: (text) =>
        moment(text).utcOffset("+05:30").format("YYYY-MM-DD hh:mm A"),
    },
    {
      title: "Operation",
      dataIndex: "operation",
      key: "operation",
      render: (text, record) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                setCartDetails(record?.cartItems);
                setShowCartDetails(true);
              }}
            >
              Cart Details
            </a>
            {record.razorpay_order_id && (
              <a
                onClick={async () => {
                  const data = await PaymentService.razorpayOrderPayments(
                    record?.razorpay_order_id
                  );
                  setSelectedOrderPayments(data);
                  setShowPaymentDetails(true);
                }}
              >
                Payment Details
              </a>
            )}
          </Space>
        );
      },
    },
  ];

  const handleCartDetails = () => {
    setCartDetails(undefined);
    setShowCartDetails(false);
  };

  const handleUserDetails = () => {
    setUserDetails(undefined);
    setShowUserDetails(false);
  };
  return (
    <>
      <Row style={{marginBottom: "20px"}} gutter={[20, 10]}>
        {/* <Space size='middle'> */}
        <Col span={4}>
          <Row style={{minWidth: "100px"}}>
            <Typography.Text style={{marginRight: "10px"}}>
              Cart Id:
            </Typography.Text>
            <Input
              placeholder="Enter Cart ID"
              type="number"
              onWheel={(event) => event.currentTarget.blur()}
              value={cartId}
              onChange={(e) => {
                setCardId(e.target.value);
              }}
            />
          </Row>
        </Col>
        <Col span={4}>
          <Row style={{minWidth: "100px"}}>
            <Typography.Text style={{marginRight: "10px"}}>
              Payment Id:
            </Typography.Text>
            <Input
              placeholder="Enter Payment ID"
              type="number"
              onWheel={(event) => event.currentTarget.blur()}
              value={paymentId}
              onChange={(e) => {
                setPaymentId(e.target.value);
              }}
            />
          </Row>
        </Col>
        <Col span={4}>
          <Row style={{minWidth: "100px"}}>
            <Typography.Text style={{marginRight: "10px"}}>
              User Id:
            </Typography.Text>
            <Input
              placeholder="Enter User ID"
              type="number"
              onWheel={(event) => event.currentTarget.blur()}
              value={userId}
              onChange={(e) => {
                setUserId(e.target.value);
              }}
            />
          </Row>
        </Col>
        <Col span={4}>
          <Row style={{minWidth: "100px"}}>
            <Typography.Text style={{marginRight: "10px"}}>
              Ticket Id:
            </Typography.Text>
            <Input
              placeholder="Enter Ticket ID"
              value={ticketId}
              onChange={(e) => {
                setTicketId(e.target.value);
              }}
            />
          </Row>
        </Col>
        <Col span={4}>
          <Row style={{minWidth: "100px"}}>
            <Typography.Text style={{marginRight: "10px"}}>
              Payment Type:
            </Typography.Text>
            <Select
              style={{width: "100%"}}
              value={paymentType}
              onChange={(e) => {
                setPaymentType(e);
              }}
            >
              <Select.Option value={"all"} key={"all"}>
                All
              </Select.Option>
              <Select.Option value={"razorpay"} key={"razorpay"}>
                Razorpay
              </Select.Option>
              <Select.Option value={"cash"} key={"cash"}>
                Cash
              </Select.Option>
              <Select.Option value={"online-free"} key={"online-free"}>
                Online(free)
              </Select.Option>
            </Select>
          </Row>
        </Col>
        <Col span={4}>
          <Row style={{minWidth: "100px"}}>
            <Typography.Text style={{marginRight: "10px"}}>
              Payment Status:
            </Typography.Text>
            <Select
              type="number"
              onWheel={(event) => event.currentTarget.blur()}
              value={paymentStatus}
              onChange={(e) => {
                setPaymentStatus(e);
              }}
              style={{minWidth: "100px", width: "100%"}}
            >
              <Select.Option value={undefined}>None</Select.Option>
              <Select.Option value="created">created</Select.Option>
              <Select.Option value="success">success</Select.Option>
              <Select.Option value="failed">failed</Select.Option>
              <Select.Option value="canceled">canceled</Select.Option>
            </Select>
          </Row>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col>
          <Row style={{minWidth: "100px"}}>
            <Typography.Text style={{marginRight: "10px"}}>
              Date Range:
            </Typography.Text>
            <DatePicker.RangePicker
              onChange={(e) => {
                if (e?.[0] && e?.[1]) {
                  setFromDate(moment(e[0]).format(`YYYY-MM-DD`));
                  setToDate(moment(e[1]).format(`YYYY-MM-DD`));
                } else {
                  setFromDate(undefined);
                  setToDate(undefined);
                }
              }}
              ranges={{
                Today: [moment(), moment()],
                "Last 7 Days": [moment().subtract(7, "d"), moment()],
                "Last 15 Days": [moment().subtract(15, "d"), moment()],
              }}
              defaultValue={[moment(fromDate), moment(toDate)]}
              defaultPickerValue={[moment(fromDate), moment(toDate)]}
            />
          </Row>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              fetchPurchases("view");
            }}
          >
            Fetch
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              downloadPurchases();
            }}
          >
            Download Excel
          </Button>
        </Col>
      </Row>

      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Total Bookings</span>
        </Space>
      </Row>

      <Table
        scroll={{x: true}}
        style={{width: "100%", textTransform: "capitalize"}}
        columns={columns}
        dataSource={data}
        loading={tableLoading}
        size="small"
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        onChange={handleTableChange}
      ></Table>
      <Drawer
        title={`User`}
        placement="right"
        closable={false}
        onClose={handleUserDetails}
        visible={showUserDetails}
        width={500}
        destroyOnClose={true}
      >
        <UserDetailsCard userid={userDetails} />
      </Drawer>
      <Drawer
        title={`Cart Items`}
        placement="right"
        closable={false}
        onClose={handleCartDetails}
        visible={showCartDetails}
        width={550}
        destroyOnClose={true}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="CARD" key="1">
            <Row>
              {cartDetails?.map((items) => (
                <Card
                  hoverable={"true"}
                  style={{width: "100%", margin: "10px"}}
                  title={(() => {
                    if (items?.DonationBooking != null) {
                      return items.DonationBooking.sd_donation.name;
                    } else if (items?.SevaBookings != null) {
                      return items.SevaBookings.sd_seva.type;
                    } else if (items?.darshanamBooking != null) {
                      return items.darshanamBooking.sd_darshanam.name;
                    } else if (items?.publicationBooking != null) {
                      return items.publicationBooking.sd_publication_file
                        .sd_publication.name;
                    }
                  })()}
                >
                  <Space>
                    <p>
                      {(() => {
                        if (items?.DonationBooking != null) {
                          return (
                            <Col>
                              <Row>
                                <Space>
                                  <p>Donor :</p>
                                  <p style={{textTransform: "capitalize"}}>
                                    {items.DonationBooking.in_the_name_of}
                                  </p>
                                </Space>
                              </Row>
                              <Row>
                                <Space>
                                  <p>Donation Type :</p>
                                  <p>
                                    {items.DonationBooking.sd_donation.name}
                                  </p>
                                </Space>
                              </Row>
                            </Col>
                          );
                        } else if (items?.SevaBookings != null) {
                          return (
                            <Col>
                              <Row>
                                <Space>
                                  <p>Seva Name :</p>
                                  <p>{items.SevaBookings.sd_seva.name}</p>
                                </Space>
                              </Row>
                              <Row>
                                <Space>
                                  <p>Seva Type :</p>
                                  <p>{items.SevaBookings.sd_seva.type}</p>
                                </Space>
                              </Row>
                              <Row>
                                <Space>
                                  <p>Ocassion :</p>
                                  <p>{items.SevaBookings.occassion}</p>
                                </Space>
                              </Row>
                              <Row>
                                {items.SevaBookings.sd_seva_booking_person_details.map(
                                  (details) => {
                                    return (
                                      <Row>
                                        <Space>
                                          <p>Gothram :</p>
                                          <p>{details.gothram}</p>
                                        </Space>
                                      </Row>
                                    );
                                  }
                                )}
                              </Row>
                              <Row>
                                <Space>
                                  <p>Date :</p>
                                  <p>{items.SevaBookings.booking_date}</p>
                                </Space>
                              </Row>
                            </Col>
                          );
                        } else if (items?.darshanamBooking != null) {
                          return (
                            <Col>
                              <Row>
                                <Space>
                                  <p>Booking Date :</p>
                                  <p>{items.darshanamBooking.booking_date}</p>
                                </Space>
                              </Row>
                              <Row>
                                <Space>
                                  <p>Darshanam :</p>
                                  <p style={{textTransform: "capitalize"}}>
                                    {items.darshanamBooking.sd_darshanam.name}
                                  </p>
                                </Space>
                              </Row>
                              <Row>
                                <Space>
                                  <p>
                                    {items.darshanamBooking.sd_darshanam_booking_person_details?.map(
                                      (details) => {
                                        return (
                                          <Col>
                                            <Row>
                                              <Space>
                                                <p>Name :</p>
                                                <p
                                                  style={{
                                                    textTransform: "capitalize",
                                                  }}
                                                >
                                                  {details.name}
                                                </p>
                                              </Space>
                                            </Row>
                                            <Row>
                                              <Space>
                                                <p>Date of Birth :</p>
                                                <p>{details.dob}</p>
                                              </Space>
                                            </Row>
                                            <Row>
                                              <Space>
                                                <p>Gender :</p>
                                                <p
                                                  style={{
                                                    textTransform: "capitalize",
                                                  }}
                                                >
                                                  {details.gender}
                                                </p>
                                              </Space>
                                            </Row>
                                          </Col>
                                        );
                                      }
                                    )}
                                  </p>
                                </Space>
                              </Row>
                            </Col>
                          );
                        } else if (items?.accomodationBooking != null) {
                          return (
                            <Col>
                              <Row>
                                <Space>
                                  <p>Booking Date :</p>
                                  <p>
                                    {items.accomodationBooking.booking_date}
                                  </p>
                                </Space>
                              </Row>
                              <Row>
                                <Space>
                                  <p>Room Type :</p>
                                  <p>
                                    {(() => {
                                      if (
                                        items.accomodationBooking
                                          .sd_block_category_details
                                          .room_type === "NONAC"
                                      ) {
                                        return (
                                          <Tag color="red">
                                            <p style={{margin: "0"}}>NON-AC</p>
                                          </Tag>
                                        );
                                      } else if (
                                        items.accomodationBooking
                                          .sd_block_category_details
                                          .room_type === "AC"
                                      ) {
                                        return (
                                          <Tag color="green">
                                            <p style={{margin: "0"}}>AC</p>
                                          </Tag>
                                        );
                                      } else {
                                        return (
                                          <Tag color="orange">
                                            <p style={{margin: "0"}}>
                                              {
                                                items.accomodationBooking
                                                  .sd_block_category_details
                                                  .room_type
                                              }
                                            </p>
                                          </Tag>
                                        );
                                      }
                                    })()}
                                  </p>
                                </Space>
                              </Row>
                              <Row>
                                <Space>
                                  <p>Block Name :</p>
                                  <p>
                                    {
                                      items.accomodationBooking
                                        .sd_block_category_details
                                        .sd_block_details.block_name
                                    }
                                  </p>
                                </Space>
                              </Row>
                            </Col>
                          );
                        } else if (items?.publicationBooking != null) {
                          return (
                            <Col>
                              <Row>
                                <Space>
                                  <p>Publication Language :</p>
                                  <p style={{textTransform: "capitalize"}}>
                                    {items.publicationBooking.language}
                                  </p>
                                </Space>
                              </Row>
                              <Row>
                                <Space>
                                  <p>Publication Name : </p>
                                  <p style={{textTransform: "capitalize"}}>
                                    {
                                      items.publicationBooking
                                        .sd_publication_file.sd_publication.name
                                    }
                                  </p>
                                </Space>
                              </Row>
                            </Col>
                          );
                        }
                      })()}
                    </p>
                  </Space>
                </Card>
              ))}
            </Row>
          </TabPane>
          <TabPane tab="JSON" key="2">
            <ReactJson
              src={cartDetails}
              theme="bright:inverted"
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
            />
          </TabPane>
        </Tabs>
      </Drawer>
      <Drawer
        title={`Payments`}
        placement="right"
        closable={false}
        onClose={() => {
          setShowPaymentDetails(false);
          setSelectedOrderPayments(undefined);
        }}
        visible={showPaymentDetails}
        width={550}
        destroyOnClose={true}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="CARD" key="1">
            <Row>
              {(() => {
                if (selectedOrderPayments?.length === 0) {
                  return (
                    <Card
                      hoverable={"true"}
                      style={{width: "100%", margin: "10px"}}
                    >
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </Card>
                  );
                } else {
                  return selectedOrderPayments?.map((items) => (
                    <Card
                      hoverable={"true"}
                      style={{width: "100%", margin: "10px"}}
                    >
                      <Row>
                        <Space>
                          <p>Order ID :</p>
                          <p>{items.order_id}</p>
                        </Space>
                      </Row>
                      <Row>
                        <Space>
                          <p>Amount :</p>
                          <p>{FormatNumberINRCurrency(items.amount / 100)}</p>
                        </Space>
                      </Row>
                      {/* <Row>
                        <Space>
                          <p>Currency :</p>
                          <p>{items.currency}</p>
                        </Space>
                      </Row> */}
                      <Row>
                        <Space>
                          {(() => {
                            if (items.status === "failed") {
                              return (
                                <Col>
                                  <Row>
                                    <Space>
                                      <p>Status :</p>
                                      <Tag
                                        color="red"
                                        style={{
                                          textTransform: "capitalize",
                                          marginBottom: "12px",
                                        }}
                                      >
                                        {items.status}
                                      </Tag>
                                    </Space>
                                  </Row>
                                  <Row>
                                    <Space>
                                      <p>Error Description :</p>
                                      <p>{items.error_description}</p>
                                    </Space>
                                  </Row>
                                  <Row>
                                    <Space>
                                      <p>Error Reason :</p>
                                      <p>{items.error_reason}</p>
                                    </Space>
                                  </Row>
                                </Col>
                              );
                            } else {
                              return (
                                <Row>
                                  <Space>
                                    <p>Status :</p>
                                    <Tag
                                      color="green"
                                      style={{
                                        textTransform: "capitalize",
                                        marginBottom: "12px",
                                      }}
                                    >
                                      {items.status}
                                    </Tag>
                                  </Space>
                                </Row>
                              );
                            }
                          })()}
                        </Space>
                      </Row>
                      <Row>
                        <Space>
                          <p>Method :</p>
                          <p style={{textTransform: "uppercase"}}>
                            {items.method}
                          </p>
                        </Space>
                      </Row>
                      <Row>
                        <Space>
                          <p>Contact :</p>
                          <p>{items.contact}</p>
                        </Space>
                      </Row>
                      <Row>
                        <Space>
                          <p>Email :</p>
                          <p>{items.email}</p>
                        </Space>
                      </Row>
                    </Card>
                  ));
                }
              })()}
              {}
            </Row>
          </TabPane>
          <TabPane tab="JSON" key="2">
            <ReactJson
              src={selectedOrderPayments}
              theme="bright:inverted"
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
            />
          </TabPane>
        </Tabs>
      </Drawer>
    </>
  );
};

export default withRouter(AllPurchases);
