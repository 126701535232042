/* eslint-disable jsx-a11y/anchor-is-valid */
import { Badge, notification, Popconfirm, Row, Space, Table } from "antd";
import React from "react";
import { useState, useEffect } from "react";
import RoomsService from "../../../Services/rooms.service";

const RoomTable = ({
  updateTable,
  setUpdateRoomId,
  blockCategoryId,
  history,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (blockCategoryId) {
      loadBlockCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTable, blockCategoryId]);

  const loadBlockCategories = async () => {
    try {
      setLoading(true);
      const blockCategories = await RoomsService.getRooms(blockCategoryId);
      setData(
        blockCategories?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      if (error.response?.data?.message) {
        notification.error({ message: error.response?.data?.message });
      } else {
        notification.error({ message: error.message });
      }
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Room Number",
      dataIndex: "room_number",
      key: "room_number",
    },
    {
      title: "Open for bookings",
      dataIndex: "open_for_bookings",
      key: "open_for_bookings",
      render: (text, record) => {
        return text ? <Badge color="green" /> : <Badge color="red" />;
      },
    },
    {
      title: "Action",
      fixed: "right",
      render: (record) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                setUpdateRoomId(record.key);
              }}
            >
              Modify
            </a>
            <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={async () => {
                setLoading(true);
                try {
                  await RoomsService.deleteRoom(record.key);
                  notification.success({
                    message: `Room ${record.room_number} has been deleted`,
                  });
                } catch (error) {
                  if (error.response?.data?.message) {
                    notification.error({
                      message: error.response?.data?.message,
                    });
                  } else {
                    notification.error({ message: error.message });
                  }
                }
                loadBlockCategories();
                setLoading(false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <Table
      tableLayout="fixed"
      scroll={{ x: true }}
      loading={loading}
      style={{ width: "100%", textTransform: "capitalize" }}
      columns={columns}
      dataSource={data}
      expandable={{
        expandedRowRender: (record) => (
          <>
            <Row>
              <p style={{ fontWeight: "bolder", marginRight: "20px" }}>
                Description:
              </p>
              {record.description}
            </Row>
          </>
        ),
      }}
    ></Table>
  );
};

export default RoomTable;
