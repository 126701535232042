import React, {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import ReportService from "../../Services/Report.service";
import {routeMapMini} from "../../constants/routeMap";
import moment from "moment";
import {
  notification,
  Row,
  Col,
  Table,
  Space,
  DatePicker,
  Button,
  Select,
  Card,
  Statistic,
} from "antd";
import {FormatNumberINRCurrency} from "../../utils/currencyFormat";
import fileDownload from "js-file-download";
import CounterService from "../../Services/Counter.service";
import "./Report.css";
const {Option} = Select;

const ReconciliationReport = ({history}) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [reportData, setReportData] = useState("");
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [bookingType, setBookingType] = useState("online");
  const [totalCollection, setTotalCollection] = useState(0);
  const [loading, setLoading] = useState(false);
  //new
  const [countersList, setCounterList] = useState([]);
  const [selectedCounter, setSelectedCounter] = useState({});
  const [selectedShift, setSelectedShift] = useState({});
  const [shiftList, setShiftList] = useState([]);

  let totalCollectionAmount = 0;

  const fetchReconciliationReport = async () => {
    setDataLoading(true);
    try {
      const data = await ReportService.reconciliationReport(
        fromDate,
        toDate,
        bookingType,
        selectedCounter?.id,
        selectedShift?.sd_shift_id
      );
      data.forEach((items) => {
        totalCollectionAmount =
          totalCollectionAmount + Number(items?.collection);
      });
      setTotalCollection(totalCollectionAmount);
      setReportData(data);
      setTabelTotalSize(data?.length);
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setDataLoading(false);
  };

  useEffect(() => {
    fetchReconciliationReport();
  }, [
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    bookingType,
    selectedShift,
    selectedCounter,
  ]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const downloadExcel = async () => {
    notification.info({message: "Downloading file! Please wait."});
    ReportService.reconciliationReportExcel(fromDate, toDate, bookingType)
      .then((data) => {
        fileDownload(
          data,
          `${fromDate}-ReconciliationReport-${moment().format(
            "DD-MM-YYYY"
          )}.xlsx`
        );
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          history?.push(routeMapMini.Login);
        } else if (error?.response?.data?.message) {
          notification.error({message: error?.response?.data?.message});
        } else {
          console.error(error);
          notification.error({message: "something is wrong"});
        }
      });
  };

  useEffect(() => {
    getCounters();
  }, []);

  const getCounters = async () => {
    try {
      setLoading(true);
      const counters = await CounterService.getAllCounter();
      setCounterList(counters);
      setLoading(false);
    } catch (error) {
      if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
      setLoading(false);
    }
  };

  const getShifts = async (id) => {
    setLoading(true);
    try {
      const resp = await CounterService.getCounterShifts(id);
      setShiftList(resp);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
  };

  const columns = [
    {title: "Name", dataIndex: "name", key: "name"},
    {title: "Type", dataIndex: "type", key: "type"},
    {title: "Counter", dataIndex: "counter", key: "counter"},
    {title: "Shift", dataIndex: "shift", key: "shift"},
    {title: "Price", dataIndex: "price", key: "price"},
    {title: "Persons", dataIndex: "persons", key: "persons"},
    {title: "Tickets", dataIndex: "total", key: "total"},
    {
      title: "Total Price",
      dataIndex: "collection",
      key: "collection",
      render: FormatNumberINRCurrency,
    },
  ];

  return (
    <>
      <Row style={{marginBottom: "20px"}} gutter={[20, 20]}>
        <Col>
          <Row justify="start">Booking Date</Row>
          <Row justify="start">
            <DatePicker.RangePicker
              format="DD-MM-YYYY"
              onChange={(e) => {
                if (e?.[0] && e?.[1]) {
                  setFromDate(moment(e[0]).format(`YYYY-MM-DD`));
                  setToDate(moment(e[1]).format(`YYYY-MM-DD`));
                } else {
                  setFromDate(undefined);
                  setToDate(undefined);
                }
              }}
              ranges={{
                Today: [moment(), moment()],
                "Last 7 Days": [moment().subtract(7, "d"), moment()],
                "Last 15 Days": [moment().subtract(15, "d"), moment()],
              }}
              defaultValue={[moment(fromDate), moment(toDate)]}
              defaultPickerValue={[moment(fromDate), moment(toDate)]}
            />
          </Row>
        </Col>
        <Col>
          <Row justify="start">Booking Type</Row>
          <Row justify="start" style={{width: "100%"}}>
            <Select
              style={{width: "100px"}}
              defaultValue="online"
              onChange={(e) => {
                setBookingType(e);
              }}
              value={bookingType}
            >
              <Select.Option value={"online"}>Online</Select.Option>
              <Select.Option value={"counter"}>Counter</Select.Option>
            </Select>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Counter</Row>
          <Row justify="start">
            <Select
              className="sd-select-r"
              dropdownClassName="sd-select-dropdown-r"
              value={selectedCounter.name}
              placeholder="Please enter counter"
              onChange={(e) => {
                if (e === "") {
                  setSelectedCounter("");
                  setShiftList([]);
                  setSelectedShift("");
                } else {
                  let parsed = JSON.parse(e);
                  setSelectedCounter(parsed);
                  getShifts(parsed.id);
                }
              }}
            >
              {countersList.map((item, i) => {
                return (
                  <Option
                    key={item.name + "counter-list" + i}
                    value={JSON.stringify(item)}
                  >
                    {item.name}
                  </Option>
                );
              })}
              <Option value="" key="">
                All
              </Option>
            </Select>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Shift</Row>
          <Row justify="start">
            <Select
              value={selectedShift.sd_shift?.name}
              className="sd-select-r"
              dropdownClassName="sd-select-dropdown-r"
              placeholder="Please select Shift"
              onChange={(e) => {
                if (e === "") {
                  setSelectedShift("");
                } else {
                  let parsed = JSON.parse(e);
                  setSelectedShift(parsed);
                }
                //
              }}
            >
              {shiftList.map((item, i) => {
                return (
                  <Option
                    key={item.name + "shift-list" + i}
                    value={JSON.stringify(item)}
                  >
                    {item.sd_shift?.name}
                  </Option>
                );
              })}
            </Select>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Update</Row>
          <Row justify="start">
            <Button
              type="primary"
              style={{minWidth: "100px"}}
              onClick={() => fetchReconciliationReport()}
            >
              Fetch
            </Button>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Download</Row>
          <Row justify="start">
            <Button
              type="primary"
              style={{minWidth: "100px"}}
              onClick={() => downloadExcel()}
            >
              Excel
            </Button>
          </Row>
        </Col>
        <Col
          style={{
            width: "20%",
            textAlign: "right",
            margin: "auto",
            marginRight: "0",
          }}
        >
          <Card hoverable size="small" style={{fontWeight: "bold"}}>
            <Statistic
              prefix="₹"
              title="Total Collection"
              value={totalCollection !== 0 ? totalCollection : 0}
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Total Bookings</span>
        </Space>
      </Row>

      <Table
        style={{width: "100%", textTransform: "capitalize"}}
        columns={columns}
        dataSource={reportData}
        loading={dataLoading}
        size="small"
        scroll={{x: true}}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        onChange={handleTableChange}
      ></Table>
    </>
  );
};

export default withRouter(ReconciliationReport);
