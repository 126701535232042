import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { routeMapMini } from '../../../constants/routeMap';
import { UserContext } from '../../../context/user.context';
import AuthService from '../../../Services/Auth.service';
import StringUtils from '../../../utils/string.utils';
import HeaderDropdown from './HeaderDropDown';

const AvatarDropdown = ({ history }) => {
  const { userDetails } = useContext(UserContext);

  const menuHeaderDropdown = (
    <Menu selectedKeys={[]} style={{ maxWidth: '140px' }}>
      <Menu.Item
        key='user'
        style={{
          textOverflow: 'ellipsis',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        {StringUtils.CaptilizeWords(
          userDetails.display_name ?? userDetails.phone
        )}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key='settings'>
        <SettingOutlined />
        Settings
      </Menu.Item>
      <Menu.Divider />
      <Menu.Divider />
      <Menu.Item
        key='logout'
        onClick={async () => {
          await AuthService.logout();
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          history?.push(routeMapMini.Login);
        }}
      >
        <LogoutOutlined />
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <span>
        <UserOutlined />
      </span>
    </HeaderDropdown>
  );
};

export default withRouter(AvatarDropdown);
