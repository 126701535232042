import React from "react";
import QRCode from "qrcode.react";
import numberToWords from "../../utils/amount-to-words/number-to-words";
import {FormatNumberINRCurrency} from "../../utils/currencyFormat";
import srisailam_logo from "../../assets/srisailam_logo.png";

const KailasaKankanamTicket = ({
  kankanamItems,
  bookingDate,
  ticketId,
  totalAmount,
  categoryTransactionId,
  serialNumber,
}) => {
  return (
    <>
      {/* <Card
      style={{
        width: "100%",
        backgroundColor: "white",
        color: "black",
        textAlign: "left",
      }}
      className="sd-ticket-card"
    > */}
      <div
        style={{
          fontWeight: "bold",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          {" "}
          {`TI No : ${categoryTransactionId?.split("-")[2]} -
              ${categoryTransactionId?.split("-")?.pop()}`}
        </div>
        {/* <div>
          {`Sl No : ${serialNumber?.split("-")[3]}-${
                serialNumber?.split("-")[4]
              }`}{" "}
          {`Sl No : 
            ${serialNumber?.split("-")[3]}`}{" "}
        </div> */}
      </div>
      <div style={{display: "flex"}}>
        <img
          src={srisailam_logo}
          alt="logo"
          style={{
            width: "20%",
            justifyContent: "center",
            margin: "auto",
            filter: "grayscale(1)",
          }}
        />
      </div>
      <div
        style={{
          justifyContent: "center",
          margin: "0",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        <p style={{margin: "0"}}>
          Sri Bhramaramba Mallikarjuna Swamy Varla Devasthanam - Srisailam
        </p>
      </div>
      <div
        style={{
          justifyContent: "center",
          margin: "0",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        <p style={{margin: "0"}}>Kailasa Kankanam Ticket</p>
      </div>
      <div
        style={{
          justifyContent: "center",
          margin: "0",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        <p style={{margin: "0"}}>Booking Date. : {bookingDate}</p>
      </div>
      <div style={{fontSize: "12px", marginBottom: "10px"}}>
        <div>
          <div
            style={{
              width: "40%",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            Details :
          </div>
        </div>
        <div style={{display: "flex"}}>
          <div
            style={{
              width: "40%",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
            span="12"
          >
            <p style={{margin: "0"}}>Item</p>
          </div>
          <div
            style={{
              width: "40%",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
            span="6"
          >
            <p style={{margin: "0"}}>Qty</p>
          </div>
          <div
            style={{
              width: "40%",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
            span="6"
          >
            <p style={{margin: "0"}}>Amount</p>
          </div>
        </div>
        {kankanamItems?.map((items) => (
          <div style={{display: "flex"}}>
            <div
              style={{
                width: "60%",
                overflowWrap: "break-word",
                textTransform: "capitalize",
              }}
              span="12"
            >
              <p style={{margin: "0", fontSize: "15px", fontWeight: "800"}}>
                {items?.kailasaKankanamName}
              </p>
            </div>
            <div
              style={{
                width: "60%",
                overflowWrap: "break-word",
                textTransform: "capitalize",
              }}
              span="6"
            >
              <p style={{margin: "0", fontSize: "15px", fontWeight: "800"}}>
                ₹{items?.unitPrice} x {items?.quantity}
              </p>
            </div>
            <div
              style={{
                width: "60%",
                overflowWrap: "break-word",
                textTransform: "capitalize",
              }}
              span="6"
            >
              <p style={{margin: "0", fontSize: "15px", fontWeight: "700"}}>
                ₹{items?.price}
              </p>
            </div>
          </div>
        ))}

        <div style={{display: "flex"}}>
          <div
            style={{
              width: "40%",
              overflowWrap: "break-word",
              textTransform: "capitalize",
              fontWeight: "bold",
            }}
          >
            <p style={{margin: "0"}}>Total Amount</p>
          </div>
          <div
            style={{
              width: "60%",
              overflowWrap: "break-word",
              textTransform: "capitalize",
              display: "inline-flex",
              fontSize: "15px",
              // fontWeight: "bold",
            }}
          >
            <p
              style={{
                paddingRight: "10px",
                margin: "0",
              }}
            >
              :
            </p>
            <span style={{fontWeight: "800", fontSize: "22px"}}>
              {FormatNumberINRCurrency(totalAmount)}
            </span>{" "}
            <br />
            <span style={{fontWeight: "600"}}>
              ({numberToWords(Number(totalAmount))} Rupees)
            </span>
          </div>
        </div>
        {ticketId && (
          <div style={{display: "flex"}}>
            <div
              style={{
                width: "40%",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              <p style={{margin: "0"}}>Ticket Id</p>
            </div>
            <div
              style={{
                width: "60%",
                overflowWrap: "break-word",
                textTransform: "capitalize",
                display: "inline-flex",
                fontWeight: "bold",
              }}
            >
              <p style={{paddingRight: "10px", margin: "0"}}>:</p>
              <p style={{margin: "0"}}>
                {ticketId ? ticketId : "transactionId=127"}
              </p>
            </div>
          </div>
        )}
      </div>
      {/* <div>
        <p>
          Note :<br />
          1. Ticket is valid on the day of booking only.
          <br />
          2. Kindly maintain Queue at Counter.
          <br />
          3. Don't fold/crush/tear the ticket.
          <br />
          <br />
          In the service of God and Goddess
        </p>
      </div> */}

      {ticketId && (
        <div
          style={{
            justifyContent: "center",
            margin: "auto",
            display: "flex",
          }}
        >
          {" "}
          <QRCode
            size="75"
            fontSize="15px"
            value={ticketId ? ticketId : "transactionId=127"}
          />
        </div>
      )}
      {/* <Divider dashed />
      <div className="footerrow">
        <Space>
          <div>
            <Space>
              <p>User :</p>
              <p
                style={{
                  fontWeight: "bold",
                  textTransform: "capitalize",
                }}
              >
                {userDetails.display_name ?? userDetails.phone}
              </p>
            </Space>
          </div>
          <div>
            <Space>
              <p>Counter :</p>
              <p
                style={{
                  fontWeight: "bold",
                  textTransform: "capitalize",
                }}
              >
                {shiftContextCounterName}
              </p>
            </Space>
          </div>
        </Space>
        <div
          style={{
            justifyContent: "center",
            margin: "auto",
            width: "100%",
            textAlign: "center",
            marginTop: "5px",
          }}
        >
          <div>
            <Space>
              <p>Time :</p>
              <p style={{ fontWeight: "bold" }}>
                {moment().utcOffset("+05:30").format("DD-MM-YYYY hh:mm A")}
              </p>
            </Space>
          </div>
        </div>
      </div> */}
      {/* </Card> */}
    </>
  );
};

export default KailasaKankanamTicket;
