import Axios from '../utils/axios';

const ContactUsService = {
  allRequests: async (status, page, limit) => {
    let query = `page=${page}&limit=${limit}&`;

    if (status) {
      query += `status=${status}`;
    }

    const resp = await Axios.get(`contact-us/all?${query}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return resp.data;
  },

  updateContactUs: async (contact_id, status, description) => {
    const resp = await Axios.put(
      `contact-us/editStatus`,
      {
        contactUsId: contact_id,
        status: status,
        status_message: description,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    return resp.data;
  },
};

export default ContactUsService;
