import "../CounterDonation/styles.css";
import "../../Ticket/PrintTicket.scss";

import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  Row,
  notification,
  Radio,
} from "antd";
import {LoadingOutlined, RightOutlined} from "@ant-design/icons";
import React, {useEffect, useState, useRef} from "react";

import {FormatNumberINRCurrency} from "../../../utils/currencyFormat";
import kalyanaKattaService from "../../../Services/KalyanakattaService";
// import { useReactToPrint } from "react-to-print";
import PrintTicket from "../../Ticket/PrintTicket";
import StringUtils from "../../../utils/string.utils";
import moment from "moment";
import {routeMapMini} from "../../../constants/routeMap";
import {withRouter} from "react-router";

const {Meta} = Card;

const Sd_Counter_KalyanaKatta = ({history}) => {
  // let refContainer = useRef(null);
  const [kalyanakattaList, setkalyanakattaList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bookingData, setBookingData] = useState([]);
  const [kalyanakattaCount, setKalyanakattaCount] = useState([]);
  const [amountpaid, setAmountPaid] = useState(0);
  const labelStyles = {float: "left", fontSize: "17px", marginBottom: "5px"};
  const [buttonLoading, setButtonLoading] = useState(false);
  const [externalPrint, setExternalPrint] = useState(false);
  // const [menuVisible, setMenuVisible] = useState(false);
  // const [selectedKalayanaKattaIds, setSelectedKalayanaKattaIds] = useState([]);
  const [kalyanakattaId, setkalyanakattaId] = useState();
  const [price, setPrice] = useState(0);
  const ref = useRef(null);

  const getKalyanaKattaList = async () => {
    setLoading(true);
    try {
      let res1 = await kalyanaKattaService.allByType();
      // setkalyanakattaList(res1);
      setkalyanakattaList(
        res1.map((item) => {
          return {
            id: item?.sd_kalyanakatta?.id,
            price: item?.sd_kalyanakatta?.price,
            name: item?.sd_kalyanakatta?.name,
          };
        })
      );
      // let res2 = await kalyanaKattaService.allByType("cover");
      // setCoversList(res2);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        // console.log(error);

        notification.error({message: error.response?.data?.message});
      } else {
        // console.log(error);
        notification.error({message: error.message});
      }
    }
  };
  const selectSlot = (tollGatePrice, id) => {
    setkalyanakattaId(id);
    setPrice(tollGatePrice);
  };
  useEffect(() => {
    getKalyanaKattaList();
  }, []);

  const handleSubmit = async () => {
    setButtonLoading(true);
    setLoading(true);
    // let a = false;
    // kalyanakattaCount?.forEach((e) => {
    //   if (e.count !== 0 && e.count > 0) {
    //     a = true;
    //   }
    // });
    if (kalyanakattaId === "" || !kalyanakattaId) {
      notification.error({message: "Please select atleast one Kalyanakatta"});
      setLoading(false);
      setButtonLoading(false);
      return;
    }
    try {
      let body = {
        kalyanakattaItems: [{kalyanakattaId: kalyanakattaId, quantity: 1}],
        cashType: "CASH",
      };

      let res = await kalyanaKattaService.bookKalayanakatta(body);
      notification.success({message: "Success"});
      setBookingData(res);
      clearFields();
      setLoading(false);
      setIsModalVisible(true);
      setExternalPrint(true);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
      setLoading(false);
    }
    setButtonLoading(false);
  };

  // const getAmount = (type) => {
  //   let amount = 0;
  //   kalyanakattaCount.forEach((e) => {
  //     if (e?.count === 0) {
  //       return;
  //     } else {
  //       amount += e?.count * e?.price;
  //     }
  //   });
  //   return amount;
  // };

  const clearFields = () => {
    setStartDate("");
    setkalyanakattaList([]);
  };

  // const handlePrint = useReactToPrint({
  //   content: () => refContainer.current,
  //   copyStyles: true,
  //   bodyClass: "sd-ticket-container",
  //   onAfterPrint: () => {
  //     setBookingData(undefined);
  //     window.location.reload();
  //   },
  // });
  // const handleFunction = () => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 200);
  //   setTimeout(() => {
  //     handlePrint();
  //   }, 1000);
  // };
  // const handleVisibleChange = () => {
  //   if (menuVisible) {
  //     setMenuVisible(false);
  //   } else {
  //     setMenuVisible(true);
  //   }
  // };

  return (
    <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div
          style={{
            maxWidth: "1200px",
            width: "100%",
            background: "white",
            padding: "30px 50px",
          }}
        >
          <Row
            justify="center"
            style={{fontSize: "22px", marginBottom: "10px"}}
          >
            Kalyana katta
          </Row>
          <Divider style={{marginTop: "0px", marginBottom: "10px"}} />
          <Row justify="space-between">
            <Col style={{width: "48%", justifyContent: "start"}}>
              <label style={labelStyles}>
                Booking Date{" "}
                <span style={{color: "red", marginLeft: "3px"}}>*</span>
              </label>
              <br />{" "}
              <DatePicker
                disabled={true}
                value={startDate}
                style={{width: "100%"}}
                format="DD-MM-YYYY"
                disabledDate={(d) =>
                  !d || d.isBefore(StringUtils.addDays(new Date(), -1))
                }
                onChange={(date) => {
                  setStartDate(date);
                }}
                size="small"
              />
            </Col>
            {/* <Col style={{ width: "48%", justifyContent: "start" }}>
              <label style={labelStyles}>Kalayana Katta Seva Menu </label>
              <br />{" "}
              <Dropdown
                overlay={menu}
                placement="bottomLeft"
                onVisibleChange={() => handleVisibleChange()}
                visible={menuVisible}
              >
                <Button style={{ width: "100%" }} size="small">
                  Kalayana Katta Menu
                </Button>
              </Dropdown>
            </Col> */}
          </Row>

          <Divider />
          <br />
          <Row
            justify="space-between"
            className="sd-display-cards"
            style={{overflow: "hidden"}}
          >
            {kalyanakattaList?.map((item, i) => {
              return (
                <Col
                  style={{
                    float: "left",
                    width: "100%",
                  }}
                >
                  <Card
                    hoverable
                    className="toll-gate-card"
                    style={{
                      width: "100%",
                      border: "2px solid #f0f2f5",
                    }}
                    onClick={() => {
                      selectSlot(item?.price, item?.id);
                      ref.current.focus();
                    }}
                  >
                    <Meta
                      title={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-beetween",
                            alignItems: "center",
                          }}
                        >
                          <div style={{width: "40%", textAlign: "left"}}>
                            <span
                              style={{
                                textTransform: "capitalize",
                                fontWeight: "400",
                                textAlign: "left",
                              }}
                            >
                              Seva type: {item?.name}
                            </span>
                            <Divider type="vertical" />
                          </div>

                          <div style={{width: "40%"}}>
                            {" "}
                            <span
                              style={{
                                marginLeft: "30px",
                                fontWeight: "400",
                              }}
                            >
                              Price:
                              <span
                                style={{
                                  color: "red",
                                  fontWeight: "600",
                                  marginLeft: "5px",
                                }}
                              >
                                {FormatNumberINRCurrency(item?.price)}
                              </span>
                            </span>
                            <Divider type="vertical" />
                          </div>

                          <div
                            style={{
                              textAlign: "right",
                              width: "10%",
                              margin: "auto",
                              marginRight: "0",
                            }}
                          >
                            <Radio
                              defaultChecked={false}
                              value={item?.id}
                              checked={
                                item?.id === kalyanakattaId ? true : false
                              }
                              id={item?.id}
                              onClick={() => {
                                setkalyanakattaId(item?.id);
                                setPrice(item?.price);
                              }}
                            />
                          </div>
                        </div>
                      }
                    />
                  </Card>
                </Col>
              );
            })}
          </Row>
          <br />
          <Row style={{height: "50px", marginTop: "10px"}}>
            <Col
              style={{
                width: "25%",
                fontWeight: "bold",
                fontSize: "23px",
                textAlign: "left",
              }}
            >
              Total Amount :
            </Col>
            <Col
              style={{
                width: "75%",
                textAlign: "right",
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              {FormatNumberINRCurrency(price)}
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col
              style={{
                width: "25%",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              Amount Paid:
            </Col>
            <Col
              style={{
                width: "75%",
                textAlign: "right",
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              <Input
                ref={ref}
                style={{width: "200px", textAlign: "right"}}
                type="number"
                onChange={(e) => {
                  setAmountPaid(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row style={{marginTop: "10px"}}>
            <Col
              style={{
                width: "25%",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              Amount to be Returned:
            </Col>
            <Col
              style={{
                width: "75%",
                textAlign: "right",
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              {FormatNumberINRCurrency(
                amountpaid - price > 0 ? amountpaid - price : 0
              )}
            </Col>
          </Row>
          <br />
          <Row>
            <Col
              style={{
                textAlign: "right",
              }}
            >
              <Button
                type="primary"
                style={{background: "#f68121", border: "none"}}
                onClick={() => {
                  handleSubmit();
                }}
                loading={buttonLoading}
              >
                Submit <RightOutlined />
              </Button>
            </Col>
          </Row>
        </div>
      )}
      <Row style={{display: "none"}}>
        <PrintTicket
          type="kalyanakatta"
          from="Counter"
          bookingDate={moment(bookingData?.booking?.booking?.created_at).format(
            "DD-MM-YYYY"
          )}
          ticketId={bookingData?.ticket}
          kalyanakattaItems={bookingData?.booking?.kalyanakattaItems}
          setPrintTicketModal={setIsModalVisible}
          setData={setBookingData}
          categoryTransactionId={
            bookingData?.counterTransactions
              ? bookingData?.counterTransactions[0]?.category_transaction_id
              : ""
          }
          totalAmount={bookingData?.booking?.booking?.total_price ?? 0}
          serialNumber={
            bookingData?.counterTransactions
              ? bookingData?.counterTransactions[0]
                  ?.daily_category_transaction_id
              : ""
          }
          externalPrint={externalPrint}
          setExternalPrint={setExternalPrint}
        />
      </Row>
      {/* <Modal
        visible={isModalVisible}
        width={400}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <PrintTicket
          type="prasadam"
          from="Counter"
          bookingDate={moment(bookingData?.booking?.booking?.created_at).format(
            "DD-MM-YYYY"
          )}
          ticketId={bookingData?.ticket}
          prasadamItems={bookingData?.booking?.prasadamItems}
          setPrintTicketModal={setIsModalVisible}
          setData={setBookingData}
          categoryTransactionId={
            bookingData?.counterTransactions
              ? bookingData?.counterTransactions[0]?.category_transaction_id
              : ""
          }
          totalAmount={bookingData?.booking?.booking?.total_price}
          serialNumber={
            bookingData?.counterTransactions
              ? bookingData?.counterTransactions[0]
                  ?.daily_category_transaction_id
              : ""
          }
        />
      </Modal> */}
    </div>
  );
};
export default withRouter(Sd_Counter_KalyanaKatta);
