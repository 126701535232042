import {Card, Col, Row, Statistic} from "antd";
import React from "react";
// import {FormatNumberINRCurrency} from "../../../utils/currencyFormat";
import "./accomadation.css";
const AccommodationStats = ({shiftData, getLiveAvailableRoomsData}) => {
  return (
    <>
      <br />
      <Card
        className="roomCount"
        style={{
          width: "100%",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <Row justify="start">
          <Col
            style={{textAlign: "left", padding: "10px 20px", fontSize: "18px"}}
          >
            Total Rooms: {shiftData?.Rooms}
          </Col>
          <Col
            style={{textAlign: "left", padding: "10px 20px", fontSize: "18px"}}
          >
            Total Available Rooms: {shiftData?.Rooms - shiftData?.BookedRooms}
          </Col>
          <Col
            style={{textAlign: "left", padding: "10px 20px", fontSize: "18px"}}
          >
            Total Booked Rooms: {shiftData?.BookedRooms}
          </Col>
        </Row>
      </Card>
      <Card
        title={"A/C Rooms"}
        style={{
          width: "18.5%",
          marginBottom: "20px",
        }}
      >
        <Col
          span={24}
          style={{padding: "10px"}}
          onClick={() => {
            getLiveAvailableRoomsData("AC", "available");
          }}
        >
          <Statistic
            className="sd-large"
            title="Available Rooms"
            value={shiftData?.ACRooms - shiftData?.BookedACRooms}
          />
        </Col>
        <Row>
          <Col
            span={12}
            style={{padding: "10px"}}
            onClick={() => {
              getLiveAvailableRoomsData("AC", "booked");
            }}
          >
            <Statistic
              title="Booked Rooms"
              className="color-red"
              value={shiftData?.BookedACRooms}
            />
          </Col>
          <Col span={12} style={{padding: "10px"}}>
            <Statistic title="Total Rooms" value={shiftData?.ACRooms} />
          </Col>
        </Row>
      </Card>{" "}
      <Card
        title={"Non A/C Rooms"}
        style={{
          width: "18.5%",
          marginBottom: "20px",
        }}
      >
        <Col
          span={24}
          style={{padding: "10px"}}
          onClick={() => {
            getLiveAvailableRoomsData("NONAC", "available");
          }}
        >
          <Statistic
            className="sd-large"
            title="Available Rooms"
            value={shiftData?.NonACRooms - shiftData?.BookedNonACRooms}
          />
        </Col>
        <Row>
          <Col
            span={12}
            style={{padding: "10px"}}
            onClick={() => {
              getLiveAvailableRoomsData("NONAC", "booked");
            }}
          >
            <Statistic
              className="color-red"
              title="Booked Rooms"
              value={shiftData?.BookedNonACRooms}
            />
          </Col>
          <Col span={12} style={{padding: "10px"}}>
            <Statistic title="Total Rooms" value={shiftData?.NonACRooms} />
          </Col>
        </Row>
      </Card>{" "}
      <Card
        title={"Suite"}
        style={{
          width: "18.5%",
          marginBottom: "20px",
        }}
      >
        <Col
          span={24}
          style={{padding: "10px"}}
          onClick={() => {
            getLiveAvailableRoomsData("SUITE", "available");
          }}
        >
          <Statistic
            className="sd-large"
            title="Available Rooms"
            value={shiftData?.SuiteRooms - shiftData?.BookedSuiteRooms}
          />
        </Col>
        <Row>
          <Col
            span={12}
            style={{padding: "10px"}}
            onClick={() => {
              getLiveAvailableRoomsData("SUITE", "booked");
            }}
          >
            <Statistic
              className="color-red"
              title="Booked Rooms"
              value={shiftData?.BookedSuiteRooms}
            />
          </Col>
          <Col span={12} style={{padding: "10px"}}>
            <Statistic title="Total Rooms" value={shiftData?.SuiteRooms} />
          </Col>
        </Row>
      </Card>{" "}
      <Card
        title={"Cottages"}
        style={{
          width: "18.5%",
          marginBottom: "20px",
        }}
      >
        <Col
          span={24}
          style={{padding: "10px"}}
          onClick={() => {
            getLiveAvailableRoomsData("COTTAGE", "available");
          }}
        >
          <Statistic
            className="sd-large"
            title="Available Rooms"
            value={shiftData?.Cottages - shiftData?.BookedCottages}
          />
        </Col>
        <Row>
          <Col
            span={12}
            style={{padding: "10px"}}
            onClick={() => {
              getLiveAvailableRoomsData("COTTAGE", "booked");
            }}
          >
            <Statistic
              title="Booked Rooms"
              className="color-red"
              value={shiftData?.BookedCottages}
            />
          </Col>
          <Col span={12} style={{padding: "10px"}}>
            <Statistic title="Total Rooms" value={shiftData?.Cottages} />
          </Col>
        </Row>
      </Card>{" "}
      <Card
        title={"Dormitory"}
        style={{
          width: "18.5%",
          marginBottom: "20px",
        }}
      >
        <Col
          span={24}
          style={{padding: "10px"}}
          onClick={() => {
            getLiveAvailableRoomsData("DORMITORY", "available");
          }}
        >
          <Statistic
            className="sd-large"
            title="Available Rooms"
            value={shiftData?.Dormitories - shiftData?.BookedDormitories}
          />
        </Col>
        <Row>
          <Col
            span={12}
            style={{padding: "10px"}}
            onClick={() => {
              getLiveAvailableRoomsData("DORMITORY", "booked");
            }}
          >
            <Statistic
              title="Booked Rooms"
              className="color-red"
              value={shiftData?.BookedDormitories}
            />
          </Col>
          <Col span={12} style={{padding: "10px"}}>
            <Statistic title="Total Rooms" value={shiftData?.Dormitories} />
          </Col>
        </Row>
      </Card>{" "}
      <br />
    </>
  );
};
export default AccommodationStats;
