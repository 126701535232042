/* eslint-disable jsx-a11y/anchor-is-valid */
import {notification, Popconfirm, Space, Table} from "antd";
// import {useState} from "react";
import {withRouter} from "react-router-dom";
// import {SearchOutlined} from "@ant-design/icons";
// import Highlighter from "react-highlight-words";
import {routeMapMini} from "../../../constants/routeMap";
import PrasadamService from "../../../Services/Prasadam.service";

const PrasadamCounterTable = ({
  list,
  blockID,
  loading,
  setLoading,
  setUpdateTable,
  getCountersByBlock,
  setTemp,
  setEdit,
  history,
}) => {
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {title: "Counter ID", dataIndex: "sd_counter_id", key: "sd_counter_id"},

    {
      title: "Counter Name",
      dataIndex: "sd_counter.name",
      key: "sd_counter.name",
      render: (text, record) => {
        return <div>{record.sd_counter.name}</div>;
      },
    },
    {
      title: "Action",
      fixed: "right",
      responsive: ["md", "lg", "xl", "xsl"],
      render: (record) => {
        return (
          <Space size="middle">
            {/* <a
              onClick={() => {
                setTemp(record);
                setEdit(true);
              }}
            >
              Edit
            </a> */}
            <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={async () => {
                setLoading(true);
                try {
                  await PrasadamService.deleteCounterbyprasadamId(record.id);
                  setUpdateTable(Math.random(100));

                  notification.success({
                    message: `Counter ${record.id} has been deleted`,
                  });
                  getCountersByBlock();
                } catch (error) {
                  if (error?.response?.status === 401) {
                    notification.error({
                      message:
                        "Token Expired, redirecting to login page in 5 seconds",
                    });
                    history?.push(routeMapMini.Login);
                  }
                  if (error.response?.data?.message) {
                    notification.error({
                      message: error.response?.data?.message,
                    });
                  } else {
                    notification.error({message: error.message});
                  }
                }

                setLoading(false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      tableLayout="fixed"
      scroll={{x: true}}
      loading={loading}
      style={{width: "100%", textTransform: "capitalize"}}
      columns={columns}
      dataSource={list}
    ></Table>
  );
};

export default withRouter(PrasadamCounterTable);
