/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Input,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import { routeMapMini } from "../../../constants/routeMap";
import DarshanamService from "../../../Services/Darshanam.service";

const DarshanamDisabledDatesTable = ({ history }) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);

  useEffect(() => {
    loadDarshanams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDarshanams = async () => {
    try {
      setLoading(true);
      const darshanams = await DarshanamService.getDisabledDates(
        tableCurrentPage,
        tablePageSize
      );
      setData(
        darshanams?.items?.map((item) => {
          return {
            key: item.id,
            darshanamname: item.sd_darshanam?.name,
            start_date: item.start_date,
            end_date: item.end_date,
            description: item.description,
          };
        })
      );
      setTabelTotalSize(darshanams?.meta?.totalItems);
      setLoading(false);
    } catch (error) {
      if (error.response?.data?.message) {
        notification.error({ message: error.response?.data?.message });
      } else {
        notification.error({ message: error.message });
      }
      setLoading(false);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Darshanam Name",
      dataIndex: "darshanamname",
      key: "darshanamname",
      ellipsis: true,
      ...getColumnSearchProps("darshanamname"),
      render: (text) => {
        return (
          text && (
            <div style={{ width: "200px", whiteSpace: "initial" }}>{text}</div>
          )
        );
      },
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
      render: (text) => {
        return (
          text && (
            <div style={{ width: "300px", whiteSpace: "initial" }}>{text}</div>
          )
        );
      },
    },
    {
      title: "Action",
      fixed: "right",
      render: (record) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={async () => {
                setLoading(true);
                try {
                  await DarshanamService.deleteDisabledDate(record.key);
                } catch (error) {
                  if (error?.response?.status === 401) {
                    notification.error({
                      message:
                        "Token Expired, redirecting to login page in 5 seconds",
                    });
                    history?.push(routeMapMini.Login);
                  } else if (error.response?.data?.message) {
                    notification.error({
                      message: error.response?.data?.message,
                    });
                  } else {
                    notification.error({ message: error.message });
                  }
                }
                loadDarshanams();
                setLoading(false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  return (
    <>
      <Row style={{ marginBottom: "20px" }}>
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              history?.push(routeMapMini.DisableDarshanam);
            }}
          >
            {" "}
            Disable Date{" "}
          </Button>
        </Space>
      </Row>
      <Table
        tableLayout="fixed"
        scroll={{ x: true }}
        loading={loading}
        style={{ width: "100%", textTransform: "capitalize" }}
        columns={columns}
        dataSource={data}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        onChange={handleTableChange}
      ></Table>
    </>
  );
};

export default withRouter(DarshanamDisabledDatesTable);
