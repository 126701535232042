/* eslint-disable jsx-a11y/anchor-is-valid */
import {Button, Col, notification, Row, Select, Space, Table, Tag} from "antd";
import TextArea from "antd/lib/input/TextArea";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import React, {useEffect} from "react";
import {useState} from "react";
import {withRouter} from "react-router";
import {routeMapMini} from "../../constants/routeMap";
import ContactUsService from "../../Services/ContactUs.service";

const ContactUs = ({history}) => {
  const [loading, setLoading] = useState();
  const [data, setData] = useState();

  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);

  const [fetchStatus, setFetchStatus] = useState(undefined);

  const [updateStatus, setUpdateStatus] = useState(undefined);
  const [description, setDescription] = useState();
  const [updateContactId, setUpdateContactId] = useState();

  const [showModal, setShowModal] = useState(false);

  const getContacts = async () => {
    setLoading(true);
    try {
      const respData = await ContactUsService.allRequests(
        fetchStatus,
        tableCurrentPage,
        tablePageSize
      );
      setData(
        respData?.items?.map((item) => {
          item.key = item.id;
          return item;
        })
      );
      setTabelTotalSize(respData?.meta?.totalItems);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableCurrentPage, tablePageSize, fetchStatus]);

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        if (text === "created") {
          return <Tag color="blue">Created</Tag>;
        } else {
          return <Tag color="orange">{text}</Tag>;
        }
      },
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => a.createdAt > b.createdAt,
      render: (text) =>
        moment(text).utcOffset("+05:30").format("DD-MM-YYYY hh:mm A"),
    },
    {
      title: "Action",
      render: (text, record) => {
        return (
          <a
            onClick={() => {
              setUpdateContactId(record.id);
              setShowModal(true);
            }}
          >
            Update
          </a>
        );
      },
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const closeModal = () => {
    setDescription(undefined);
    setUpdateStatus(undefined);
    setShowModal(false);
    setUpdateContactId(undefined);
  };

  const handleOk = async () => {
    try {
      if (!updateContactId) {
        return notification.error({message: `select entity`});
      }
      if (!updateStatus) {
        return notification.error({message: `select status`});
      }
      if (!description) {
        return notification.error({message: "please enter description."});
      }
      await ContactUsService.updateContactUs(
        updateContactId,
        updateStatus,
        description
      );
      getContacts();
      closeModal();
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
  };

  return (
    <div>
      <Row gutter={[0, 10]}>
        <Space size="middle">
          <Col>Select Status</Col>
          <Col>
            <Select
              value={fetchStatus}
              onChange={(e) => {
                setFetchStatus(e);
              }}
              style={{minWidth: "100px"}}
            >
              <Select.Option value={undefined}>All</Select.Option>
              <Select.Option value="created">Created</Select.Option>
              <Select.Option value="contacted">Contacted</Select.Option>
              <Select.Option value="closed">Closed</Select.Option>
            </Select>
          </Col>
        </Space>
      </Row>
      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          Total Queries
        </Space>
      </Row>
      <Table
        scroll={{x: true}}
        style={{width: "100%", textTransform: "capitalize"}}
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <b> Description: </b>
              <p>{record.message}</p>
              <b> Response: </b>
              <p>{record.status_message}</p>
            </>
          ),
          rowExpandable: (record) => true,
        }}
        onChange={handleTableChange}
        size="small"
      ></Table>
      <Modal
        visible={showModal}
        title="Manage User"
        onOk={handleOk}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => {
              handleOk();
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify="center">Select Status</Row>
        <Row justify="center">
          <Select
            value={updateStatus}
            onChange={(e) => {
              setUpdateStatus(e);
            }}
            style={{minWidth: "100px"}}
          >
            <Select.Option value="created">Created</Select.Option>
            <Select.Option value="contacted">Contacted</Select.Option>
            <Select.Option value="closed">Closed</Select.Option>
          </Select>
        </Row>
        <Row justify="center">Description</Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <TextArea
            placeholder="Desction"
            style={{width: "500px"}}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(ContactUs);
