import React, {useState} from "react";
import {withRouter} from "react-router";
import SevaService from "../../../Services/Seva.service";
import {
  Alert,
  Button,
  Card,
  Col,
  Input,
  notification,
  Row,
  // Space,
  Typography,
} from "antd";
import {routeMapMini} from "../../../constants/routeMap";
// import DarshanamTicket from "../../Ticket/DarshanamTicket";
import ScannedTicket from "../../Ticket/ScannedTicket";
import QuaggaClass from "../../Ticket/BarcodeScanner/Quagga";
import "../TicketScan.scss";

const PerformanceScan = ({history}) => {
  const [loading, setLoading] = useState(false);
  const [ticketId, setTicketId] = useState();
  const [data, setData] = useState();
  const [valid, setValid] = useState(false);
  const [errMessage, setErrMessage] = useState();
  const [scanning, setScanning] = useState("");
  const [closeFlag, setCloseFlag] = useState(false);

  const ticketScan = async () => {
    setValid(false);
    setErrMessage(undefined);
    setLoading(true);
    if (!ticketId) {
      notification.error({message: "Enter Ticket Number!"});
    }
    try {
      const data = await SevaService.scanPerformanceTicket(ticketId);
      if (data) {
        setData(data);
        setTicketId("");
      }
      setValid(true);
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        setValid(false);
        setErrMessage(error?.response?.data?.message);
        setTicketId("");
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setLoading(false);
  };

  const onChangeTicketId = (e) => {
    setTicketId(e.target.value);
    setValid(false);
    setErrMessage(undefined);
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      ticketScan();
    }
  };
  const Scan = () => {
    setScanning(!scanning);
    if (scanning === false) {
      setCloseFlag(true);
    }
  };

  const OnDetected = (result) => {
    setScanning(false);
    setTicketId(result.codeResult.code);
    setValid(false);
    setErrMessage(undefined);
  };
  return (
    <>
      <Row justify="center">
        <Typography.Title> Performance Scan </Typography.Title>
      </Row>
      <Row justify="center">
        {!scanning ? (
          <>
            <Col
              className="mobile-responsive-width"
              style={{margin: "8px", textAlign: "left"}}
            >
              {" "}
              Ticket No:{" "}
            </Col>
            <Col className="mobile-responsive-width" style={{margin: "8px"}}>
              <Input
                value={ticketId}
                onChange={onChangeTicketId}
                onKeyDown={onKeyDown}
                className="mobile-responsive-width"
              />
            </Col>
            <Col className="mobile-responsive-width" style={{margin: "8px"}}>
              <Button
                className="mobile-responsive-width"
                type="primary"
                onClick={ticketScan}
                loading={loading}
              >
                GO!
              </Button>
            </Col>
          </>
        ) : (
          ""
        )}
        <Col className="mobile-responsive-width">
          <Button
            className="mobile-button-width"
            type="primary"
            onClick={() => {
              Scan();
            }}
            loading={loading}
            style={{margin: "8px"}}
          >
            {scanning ? "Close Scan" : " Barcode Scan"}
          </Button>
          {scanning ? (
            <QuaggaClass
              onDetected={OnDetected}
              closeFlag={closeFlag}
              setCloseFlag={setCloseFlag}
            />
          ) : null}
          {/* <br />
              <ul className="results">
                <p>{results?.codeResult?.code}</p>
              </ul> */}
        </Col>
      </Row>
      <Row justify="center" style={{margin: "20px"}}>
        {valid && data && (
          <Alert
            message={`Ticket Scanned: ${data?.ticketId}`}
            description={"Ticket Scanned succesfully"}
            type="success"
            showIcon
          />
        )}
        {!valid && errMessage && (
          <Alert
            style={{paddingRight: "30px"}}
            message={`Error Scanning Ticket ${data?.ticketId}`}
            description={errMessage}
            type="error"
            showIcon
          />
        )}
      </Row>
      {/* {valid && data && (
        // <Card
        //   style={{ width: "40%", justifyContent: "center", margin: "auto" }}
        // >
        //   <DarshanamTicket
        //     type={
        //       data?.type === "pratyakshaSeva_counter"
        //         ? "pratyakshaSeva"
        //         : data?.type
        //     }
        //     sevaName={data?.seva_name}
        //     abishekam={data?.abishekam_type}
        //     performanceDate={data?.booking_date}
        //     bookingDate={data?.ordered_date}
        //     timeSlot={data?.time_slot}
        //     startTime={data?.slot_start_start}
        //     endTime={data?.slot_end_start}
        //     persons={data?.no_of_tickets}
        //     name={data?.devotee_names}
        //     amountPaid={data?.amount_paid}
        //     actualAmount={data?.actual_amount}
        //     phone={data?.mobile_no}
        //     proof={data?.primary_id_proof_number}
        //     ticketId={data?.ticketId}
        //     categoryTransactionId={data?.category_transaction_id}
        //     ticketcopy="TEMPLE COPY"
        //   />
        // </Card>
       
      )} */}
      {valid && data && (
        <Row
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xsl={12}
          style={{
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <ScannedTicket data={data} />
        </Row>
      )}
    </>
  );
};

export default withRouter(PerformanceScan);
