import {Button, Col, Input, notification, Row} from "antd";
import Modal from "antd/lib/modal/Modal";
import {useContext, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {routeMapMini} from "../../constants/routeMap";
import {UserContext} from "../../context/user.context";
import KailasaKankanamService from "../../Services/KailasaKankanam.service";
import KailasaKankanamTable from "./KailasaKankanamTable";
const KailasaKankanam = ({history}) => {
  const [showNewModal, setShowNewModal] = useState(false);
  const [loading, setLoading] = useState();
  const [kankanamName, setKankanamName] = useState("");
  const [price, setPrice] = useState();
  const [edit, setEdit] = useState(false);
  const [updateTable, setUpdateTable] = useState();
  const [kankanamId, setKankanamId] = useState();
  const {userDetails} = useContext(UserContext);
  useEffect(() => {
    (async () => {
      if (kankanamId) {
        setShowNewModal(true);
        try {
          const resp = await KailasaKankanamService.KailasaKankanamById(
            kankanamId
          );
          setKankanamName(resp?.name);
          setPrice(resp?.price);
          setEdit(true);
          setShowNewModal(true);
        } catch (error) {
          if (error?.response?.status === 401) {
            notification.error({
              message: "Token Expired, redirecting to login page in 5 seconds",
            });
            history?.push(routeMapMini.Login);
          } else if (error.response?.data?.message) {
            notification.error({message: error.response?.data?.message});
          } else {
            notification.error({message: error.message});
          }
        }
      }
    })();
  }, [history, kankanamId]);

  const handleOk = async () => {
    if (!kankanamName) {
      return notification.error({message: `name is required`});
    } else if (!price || price <= 0) {
      return notification.error({
        message: `price is required and should be positive`,
      });
    }
    setLoading(true);
    try {
      if (kankanamId) {
        await KailasaKankanamService.editKailasaKankanam(
          kankanamId,
          kankanamName,
          price
        );
        notification.success({
          message: `Kanakanam ${kankanamId} updated`,
        });
        clearFields();
        setUpdateTable(kankanamId + Math.random());
        setEdit(false);
      } else {
        const kankanam = await KailasaKankanamService.newKailasaKankanam(
          kankanamName,
          price
        );
        notification.success({message: `Kanakanam ${kankanam.id} created`});
        setUpdateTable(kankanam.id + Math.random());
        clearFields();
        setEdit(false);
      }
      setShowNewModal(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setKankanamName(undefined);
    setPrice(undefined);
    setKankanamId(undefined);
    setEdit(false);
  };

  const handleCancel = () => {
    clearFields();
    setShowNewModal(false);
    setEdit(false);
  };

  return (
    <div style={{width: "100%"}}>
      <Row style={{width: "100%", marginBottom: "15px"}} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setShowNewModal(true);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Add New Kanakanam
          </Button>
        </Col>
      </Row>
      <Row style={{width: "100%"}}>
        <KailasaKankanamTable
          updateTable={updateTable}
          setKankanamId={setKankanamId}
        />
      </Row>
      <Modal
        visible={showNewModal}
        title={`${
          edit ? `Edit Kanakanam id: ${kankanamId}` : `Add New Kanakanam`
        }`}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{alignContent: "center"}}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify="start" style={{width: "100%", padding: "10px 0px"}}>
          <label>Kankanam Name</label>
          <Input
            placeholder="Kankanam Name"
            value={kankanamName}
            onChange={(e) => {
              setKankanamName(e.target.value);
            }}
            style={{width: "450px"}}
          />
        </Row>

        <Row
          justify="start"
          style={{width: "100%", padding: "10px 0px", flexDirection: "column"}}
        >
          <label>Price </label>
          <Input
            placeholder="Price"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
            type="number"
            style={{width: "450px"}}
          />
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(KailasaKankanam);
