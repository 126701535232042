import {Col, DatePicker} from "antd";
import React from "react";
import {withRouter} from "react-router";
const TempleTimePickerTwo = ({name, from, setFrom, to, setTo}) => {
  return (
    <>
      <Col span={9}>
        <h3>{name}</h3>
      </Col>{" "}
      <Col span={15}>
        Start time:{" "}
        <DatePicker
          allowClear
          style={{marginTop: "5px"}}
          picker="time"
          defaultValue={from}
          value={from}
          onChange={(e) => {
            setFrom(e);
          }}
        />
        <br />
        End Time:
        <DatePicker
          allowClear
          picker="time"
          style={{margin: "5px 0px 5px 7px"}}
          defaultValue={to}
          value={to}
          onChange={(e) => {
            setTo(e);
          }}
        />
      </Col>
    </>
  );
};
export default withRouter(TempleTimePickerTwo);
