import {Button, Checkbox, Col, Divider, Input, notification, Row} from "antd";
import Modal from "antd/lib/modal/Modal";
import {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {routeMapMini} from "../../constants/routeMap";
import RolesService from "../../Services/Roles.service";
import UsersRolesTable from "./UsersRolesTable";

const UserRoles = ({history}) => {
  const [showModifyUserModal, setShowModifyUserModal] = useState(false);
  const [cUserPhone, setCUserPhone] = useState("");
  const [userId, setUserId] = useState();
  const [loading, setLoading] = useState();
  const [rolesData, setRolesData] = useState([]);
  const [userRolesData, setUserRolesData] = useState([]);
  const [updateTable, setUpdateTable] = useState();
  const [createUserFlag, setCreateUserFlag] = useState(false);
  const [editRolesFlag, setEditRolesFlag] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    setButtonLoading(true);
    if (cUserPhone === "") {
      notification.error({message: "Please Enter Number"});
      setLoading(false);
      setButtonLoading(false);
      return;
    }
    if (cUserPhone.length !== 10) {
      notification.error({message: "Phone number must be 10 digits"});
      setLoading(false);
      setButtonLoading(false);
      return;
    }
    try {
      await RolesService.updateRoles(
        userRolesData,
        createUserFlag ? `+91${cUserPhone}` : userId,
        createUserFlag
      );
      setShowModifyUserModal(false);
      setCUserPhone();
      setUserId();
      setRolesData([]);
      setUserRolesData([]);
      setCreateUserFlag(false);
      setEditRolesFlag(false);
      setUpdateTable(Math.random());
    } catch (error) {
      console.log(error.response);
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setLoading(false);
    setButtonLoading(false);
  };

  const handleCancel = () => {
    setShowModifyUserModal(false);
    setCUserPhone();
    setUserId();
    setRolesData([]);
    setUserRolesData([]);
    setCreateUserFlag(false);
    setEditRolesFlag(false);
    setUpdateTable(Math.random());
  };

  const fetchRoles = async () => {
    setLoading(true);
    try {
      const rolesData = await RolesService.getRoles();
      setRolesData(rolesData);
    } catch (error) {
      console.log(error.response);
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setLoading(false);
  };

  const selectedRoles = (role) => {
    let newRoles = [];
    if (userRolesData?.includes(role)) {
      newRoles = userRolesData.filter((e) => e !== role);
    } else {
      newRoles = [...userRolesData, role];
    }
    setUserRolesData(newRoles);
    console.log(userRolesData);
  };

  const onClickCreateUser = () => {
    setCreateUserFlag(true);
    setShowModifyUserModal(true);
  };

  useEffect(() => {
    if (createUserFlag === true || editRolesFlag === true) {
      fetchRoles();
    }
  }, [createUserFlag, editRolesFlag]);

  return (
    <div style={{width: "100%"}}>
      <Row style={{marginBottom: "15px"}}>
        <Button
          type="primary"
          onClick={() => {
            onClickCreateUser();
          }}
        >
          Create User
        </Button>
      </Row>
      <Row style={{width: "100%"}}>
        <UsersRolesTable
          setShowModifyUserModal={setShowModifyUserModal}
          setUserRolesData={setUserRolesData}
          setCUserPhone={setCUserPhone}
          setUserId={setUserId}
          setEditRolesFlag={setEditRolesFlag}
          updateTable={updateTable}
        />
      </Row>
      <Modal
        visible={showModifyUserModal}
        title={createUserFlag ? "Create User" : "Edit Permissions"}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={buttonLoading}
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>,
        ]}
        width={1000}
      >
        <Row gutter={[20, 20]} style={{marginBottom: "10px"}}>
          <Col>
            <Row justify="start">Phone</Row>
            <Row justify="start" style={{width: "100%"}}>
              <Input
                type="number"
                onWheel={(event) => event.currentTarget.blur()}
                maxLength={10}
                addonBefore={"+91"}
                placeholder="Please Enter Mobile Number"
                style={{width: "100%"}}
                value={cUserPhone}
                onChange={(e) => {
                  if (e.target.value.length > 10) {
                    return;
                  }
                  setCUserPhone(`${e.target.value}`);
                }}
                disabled={createUserFlag ? false : true}
              />
            </Row>
          </Col>
        </Row>
        <Divider style={{margin: "10px 0px"}} />
        <Row justify="start">Role</Row>
        {rolesData ? (
          <Row
            gutter={[20, 20]}
            justify="start"
            style={{width: "100%", padding: "10px 0px"}}
          >
            {rolesData?.map((item) => {
              return (
                <Col style={{width: "25%", textTransform: "lowercase"}}>
                  <Checkbox
                    checked={userRolesData?.includes(item)}
                    onChange={() => {
                      selectedRoles(item);
                    }}
                  >
                    {item}
                  </Checkbox>
                </Col>
              );
            })}
          </Row>
        ) : (
          <Row>fetching Roles ....</Row>
        )}
      </Modal>
    </div>
  );
};

export default withRouter(UserRoles);
