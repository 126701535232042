/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { Route, Switch } from "react-router-dom";
import AllPurchases from "./Components/AllPurchases/AllPurchases";
import ContactUs from "./Components/ContactUs/ContactUs";
import Darshanam from "./Components/Darshanam/Darshanam";
import DarshanamBookingDetails from "./Components/Darshanam/DarshanamBookingDetails";
import DarshanamDisabledDates from "./Components/Darshanam/DisableDates/DarshanamDisabledDates";
import DisableDarshanam from "./Components/Darshanam/DisableDates/DisableDarshanam";
import Sd_Counter_Publications from "./Components/counter_managment/CounterPublications/Sd_Counter_Publicatons";
import Donation from "./Components/Donation/Donation";
import DonationBookingDetails from "./Components/Donation/DonationBookingDetails";
import ManageUsers from "./Components/ManageUsers/ManageUsers";
import UserDetails from "./Components/ManageUsers/UserDetails/UserDetails";
import NewsLetter from "./Components/NewsLetter/NewsLetter";
import BlockBookingDetails from "./Components/Accomodation/BlockCategories/BlockBookingDetails";
import BlockCategories from "./Components/Accomodation/BlockCategories/BlockCategories";
import DisableRoom from "./Components/Accomodation/DisableDates/DisableRoom";
import RoomDisabledDates from "./Components/Accomodation/DisableDates/RoomDisabledDates";
import Blocks from "./Components/Accomodation/block";
import DisableSeva from "./Components/Seva/DisableDates/DisableSeva";
import SevaDisabledDates from "./Components/Seva/DisableDates/SevaDisabledDates";
import Seva from "./Components/Seva/Seva";
import SevaBookingDetails from "./Components/Seva/SevaBookingDetails";
import SevaReports from "./Components/Seva/SevaReports/SevaReports";
import Ticket from "./Components/Ticket/ticket";
import TicketDetails from "./Components/Ticket/ticketDetails";
import { routeMapMini } from "./constants/routeMap";
import Rooms from "./Components/Accomodation/Rooms/Rooms";
import Audit from "./Components/Audits/Audit";
import Publications from "./Components/Publications/Publications";
import PublicationBookingDetails from "./Components/Publications/PublicationBookingDetails";
import PublicationFiles from "./Components/Publications/PublicationFiles/PublicationFiles";
import RoomAllocation from "./Components/Accomodation/Rooms/RoomAllocation";
import Reports from "./Components/Report/Reports";
import ReconciliationReport from "./Components/Report/ReconciliationReport";
import TransactionReport from "./Components/Report/TransactionReport";
import OfflineAccommodation from "./Components/counter_managment/AccommodationTicket/OfflineAccommodation";
import OfflineTicket from "./Components/counter_managment/Counter";
import RoomList from "./Components/counter_managment/AccommodationTicket/RoomList";
import CounterManagment from "./Components/counter_managment/CounterManagment";
import CounterRole from "./Components/counter_managment/CounterRole";
import ManageCounters from "./Components/Accomodation/Counters/ManageCounters";
import UserForm from "./Components/counter_managment/AccommodationTicket/UserForm";
import CoupounMainPage from "./Components/counter_managment/Coupon Management/CoupounMainPage";
import DarshanamSlotMainPage from "./Components/Darshanam/SlotManagement/DarshanamSlotMainPage";
import ShiftReportTable from "./Components/Report/ShiftReport/ShiftReportTable";
import ShiftMangementMainPage from "./Components/counter_managment/ShiftManagement/ShiftMangementMainPage";
import CounterShiftManagementPage from "./Components/counter_managment/CounterShiftManagement/CounterShiftManagementPage";
import TempleDairyMain from "./Components/TempleDairy/TempleDairyMain";
import AccommodationDashboard from "./Components/Accomodation/AccommodationDashboard/AccommodationDashboard";
import ShiftTransactionsMain from "./Components/Report/ShiftTransactionsMain";
import Sd_CounterManagement_PratyakshaSeva_One from "./Components/counter_managment/SevasAndDarshanam/Pratyaksha Seva/Sd_CounterManagement_PratyakshaSeva_One";
import SevaSlotMainPage from "./Components/Seva/SlotManagement/SevaSlotMainPage";
import DarshanamSlotCounterMain from "./Components/Darshanam/SlotManagement/DarshanamSlotCounterManagement/DarshanamSlotCounterMain";
import Sd_Offline_Darshanam_Ticket from "./Components/counter_managment/SevasAndDarshanam/Darshanam/Sd_Offline_Darshanam_Ticket";
import SevaSlotCounterMain from "./Components/Seva/SlotManagement/SlotCounterManagement/SevaSlotCounterMain";
import Sd_CounterManagement_Donation from "./Components/counter_managment/CounterDonation/Sd_CounterManagement_Donation";
import DonationCounterMain from "./Components/Donation/DonationCounterManagement/DonationCounterMain";
import Dashboard from "./Components/Dashboard/Dashboard";
import ShiftScanLogs from "./Components/Report/ShiftReport/ShiftScanLogs";
import PublicationCounterMain from "./Components/Publications/CounterManagement/PublicationCounterMain";
import Sd_Counter_Prasadam from "./Components/counter_managment/CounterPrasadam/Sd_Counter_Prasadam";
import GstReport from "./Components/Report/GstReport";
import Prasadam from "./Components/Prasadam/Prasadam";
import PrasadamCounterMain from "./Components/Prasadam/PrasadamCounterManagement/PrasadamCounterMain";
import KalyanaKatta from "./Components/KalayanaKatta/KalayanaKatta";
import KalyanaKattaCounterMain from "./Components/KalayanaKatta/KalayanakattaCounterManagement/KalyanaKattaCounterMain";

import PerformanceScan from "./Components/counter_managment/CounterPerformanceScan/PerformanceScan";
import CheckInScan from "./Components/counter_managment/CounterCheckInScan/CheckInScan";
import PrasadamScanner from "./Components/counter_managment/CounterPrasadam/PrasadamScanner";
import SevaCounterReport from "./Components/Report/SevaCounterReport/SevaCounterReport";
import PrasadamCounterReport from "./Components/Report/PrasadamCounterReport.jsx/PrasadamCounterReport";
import ConsolidatedReport from "./Components/Report/ConsolidatedReport/ConsolidatedReport";

import DarshanamCounterReport from "./Components/Report/DarshanamCounterReport/DarshanamCounterReport";
import Toll_Gate from "./Components/TollGate/Toll_Gate";
import TollGateCounterMain from "./Components/TollGate/TollGateCounterMain";
import Counter_Toll_Gate from "./Components/counter_managment/CounterTollGate/Counter_Toll_Gate";
import UserRoles from "./Components/Roles/UserRoles";
import Sd_Counter_KalyanaKatta from "./Components/counter_managment/CounterKalyanaKatta/Sd_Counter_KalyanaKatta";

import Thulabharam from "./Components/Thulabharam/Thulabharam";
import ThulabharamCounterMain from "./Components/Thulabharam/ThulabharamCounterManagement/ThulabharamCounterMain";
import Sd_Counter_Thulabharam from "./Components/counter_managment/CounterThulabharam/Sd_Counter_Thulabharam";

import ScanTicketLogReport from "./Components/Report/ScanTicketLogReport/ScanTicketLogReport";
import KailasaKankanamScanner from "./Components/counter_managment/CounterKailasaKankanam/KailasaKankanamScanner";
import KailasaKankanamCounterMain from "./Components/KailasaKankanam/KailasaKankanamCounterManagement/KailasaKankanamCounterMain";
import Sd_Counter_KailasaKankanam from "./Components/counter_managment/CounterKailasaKankanam/Sd_Counter_KailasaKankanam";
import KailasaKankanamCounterReport from "./Components/Report/KailasaKankanamReport/KailasaKankanamCounterReport"
import KailasaKankanam from "./Components/KailasaKankanam/KailasaKankanam";
import KalayanakattaCounterReport from "./Components/Report/KalyanakattaReport/KalayanakattaCounterReport";
import DonorRegistration from "./Components/Donation/DonorRegistartion/DonorRegistration";
import DonationCounterReport from "./Components/Report/DonationReport/DonationReport";
import PublicationReport from "./Components/Report/PublicationReport/PublicationReport";
import ThulabharamCounterReport from "./Components/Report/ThulabharamReport/ThulabharamReport";
import TollGateCounterReport from "./Components/Report/TollgateReport/TollgateReport";
import OccupancyReport from "./Components/Report/AccommodationReport/OccupancyReport";

const Routes = () => {
  return (
    <Switch>
      <Route exact path={routeMapMini.Dashboard} render={() => <Dashboard />} />

      <Route
        exact
        path={routeMapMini.Accommodation}
        render={() => <Blocks />}
      />
      <Route
        exact
        path={routeMapMini.AccommodationBlockCategory}
        render={() => <BlockCategories />}
      />
      <Route
        exact
        path={routeMapMini.AccommodationBlockBookingDetails}
        render={() => <BlockBookingDetails />}
      />
      <Route
        exact
        path={routeMapMini.AccommodationDisabledDates}
        render={() => <RoomDisabledDates />}
      />

      <Route
        exact
        path={routeMapMini.DisableAccommodation}
        render={() => <DisableRoom />}
      />
      <Route
        exact
        path={routeMapMini.AccommodationRooms}
        render={() => <Rooms />}
      />
      <Route
        exact
        path={routeMapMini.AccommodationOcupancyReports}
        render={() => <OccupancyReport />}
      />
      <Route exact path={routeMapMini.Prasadam} render={() => <Prasadam />} />
      <Route exact path={routeMapMini.KailasaKankanam} render={() => <KailasaKankanam />} />

      <Route
        exact
        path={routeMapMini.KalyanaKatta}
        render={() => <KalyanaKatta />}
      />
      <Route
        exact
        path={routeMapMini.Thulabharam}
        render={() => <Thulabharam />}
      />
      <Route
        exact
        path={routeMapMini.CouponManagement}
        render={() => <CoupounMainPage />}
      />
      <Route
        exact
        path={routeMapMini.CounterManagementShift}
        render={() => <CounterShiftManagementPage />}
      />
      <Route
        exact
        path={routeMapMini.AccommodationRoomsBookings}
        render={() => <DisableRoom />}
      />
      <Route
        exact
        path={routeMapMini.RoomAllocation}
        render={() => <RoomAllocation />}
      />
      <Route
        exact
        path={routeMapMini.ManageUsers}
        render={() => <ManageUsers />}
      />
      <Route
        exact
        path={routeMapMini.userDetails}
        render={() => <UserDetails />}
      />
      <Route exact path={routeMapMini.Seva} render={() => <Seva />} />
      <Route
        exact
        path={routeMapMini.SevaReports}
        render={() => <SevaReports />}
      />
      <Route
        exact
        path={routeMapMini.SevaCounterReport}
        render={() => <SevaCounterReport />}
      />
      <Route
        exact
        path={routeMapMini.PrasadamCounterReport}
        render={() => <PrasadamCounterReport />}
      />
      <Route
        exact
        path={routeMapMini.KalayanakattaCounterReport}
        render={() => <KalayanakattaCounterReport />}
      />
      <Route
        exact
        path={routeMapMini.KailasaKankanamCounterReport}
        render={() => <KailasaKankanamCounterReport />}
      />
      <Route
        exact
        path={routeMapMini.ConsolidatedReport}
        render={() => <ConsolidatedReport />}
      />
      <Route
        exact
        path={routeMapMini.DarshanamCounterReport}
        render={() => <DarshanamCounterReport />}
      />
      <Route
        exact
        path={routeMapMini.DonationReports}
        render={() => <DonationCounterReport />}
      />
      <Route
        exact
        path={routeMapMini.PublicationReports}
        render={() => <PublicationReport />}
      />
      <Route
        exact
        path={routeMapMini.TollGateReports}
        render={() => <TollGateCounterReport />}
      />

      <Route
        exact
        path={routeMapMini.ShiftTransactions}
        render={() => <ShiftTransactionsMain />}
      />
      <Route
        exact
        path={routeMapMini.TempleDairyMain}
        render={() => <TempleDairyMain />}
      />
      <Route
        exact
        path={routeMapMini.DarshanamSlotCounterManagement}
        render={() => <DarshanamSlotCounterMain />}
      />
      <Route
        exact
        path={routeMapMini.SevaSlotCounterManagement}
        render={() => <SevaSlotCounterMain />}
      />
      <Route
        exact
        path={routeMapMini.SevaSlotManagement}
        render={() => <SevaSlotMainPage />}
      />
      <Route
        exact
        path={routeMapMini.Counter_SAD_pratyakshaSeva_One}
        render={() => <Sd_CounterManagement_PratyakshaSeva_One />}
      />
      <Route
        exact
        path={routeMapMini.Counter_Publications}
        render={() => <Sd_Counter_Publications />}
      />
      <Route
        exact
        path={routeMapMini.CounterPrasadam}
        render={() => <Sd_Counter_Prasadam />}
      />
      <Route
        exact
        path={routeMapMini.CounterKailasaKankanam}
        render={() => <Sd_Counter_KailasaKankanam />}
      />
      <Route
        exact
        path={routeMapMini.CounterKalyanakatta}
        render={() => <Sd_Counter_KalyanaKatta />}
      />
      <Route
        exact
        path={routeMapMini.CounterThulabharam}
        render={() => <Sd_Counter_Thulabharam />}
      />
      <Route
        exact
        path={routeMapMini.ThulabharamReports}
        render={() => <ThulabharamCounterReport />}
      />
      <Route
        exact
        path={routeMapMini.PrasadamCounterMain}
        render={() => <PrasadamCounterMain />}
      />
      <Route
        exact
        path={routeMapMini.KailasaKankanamCounterMain}
        render={() => <KailasaKankanamCounterMain />}
      />
      <Route
        exact
        path={routeMapMini.KalyanaKattaCounterMain}
        render={() => <KalyanaKattaCounterMain />}
      />
      <Route
        exact
        path={routeMapMini.ThulabharamCounterMain}
        render={() => <ThulabharamCounterMain />}
      />
      <Route
        exact
        path={routeMapMini.PublicationsManageCounters}
        render={() => <PublicationCounterMain />}
      />
      <Route
        exact
        path={routeMapMini.SevaDisabledDates}
        render={() => <SevaDisabledDates />}
      />
      <Route
        exact
        path={routeMapMini.DisableSeva}
        render={() => <DisableSeva />}
      />
      <Route exact path={routeMapMini.Donation} render={() => <Donation />} />
      <Route
        exact
        path={routeMapMini.DonationBookingDetails}
        render={() => <DonationBookingDetails />}
      />
      <Route
        exact
        path={routeMapMini.DonorRegistration}
        render={() => <DonorRegistration />}
      />
      <Route
        exact
        path={routeMapMini.DonationCounterManagement}
        render={() => <DonationCounterMain />}
      />
      <Route
        exact
        path={routeMapMini.SevaBookingDetails}
        render={() => <SevaBookingDetails />}
      />
      <Route
        exact
        path={routeMapMini.DarshanamBookingDetails}
        render={() => <DarshanamBookingDetails />}
      />
      <Route
        exact
        path={routeMapMini.DarshanamDisabledDates}
        render={() => <DarshanamDisabledDates />}
      />
      <Route
        exact
        path={routeMapMini.DisableDarshanam}
        render={() => <DisableDarshanam />}
      />
      <Route
        exact
        path={routeMapMini.DarshanamSlots}
        render={() => <DarshanamSlotMainPage />}
      />

      <Route
        exact
        path={routeMapMini.AllPurchases}
        render={() => <AllPurchases />}
      />
      <Route exact path={routeMapMini.Darshanam} render={() => <Darshanam />} />
      <Route exact path={routeMapMini.Reports} render={() => <Reports />} />
      <Route
        exact
        path={routeMapMini.ReconciliationReport}
        render={() => <ReconciliationReport />}
      />
      <Route
        exact
        path={routeMapMini.Alltransactions}
        render={() => <TransactionReport />}
      />
      <Route
        exact
        path={routeMapMini.Publications}
        render={() => <Publications />}
      />
      <Route
        exact
        path={routeMapMini.PublicationFiles}
        render={() => <PublicationFiles />}
      />
      <Route
        exact
        path={routeMapMini.PublicationBookings}
        render={() => <PublicationBookingDetails />}
      />
      <Route exact path={routeMapMini.ticket} render={() => <Ticket />} />
      <Route
        exact
        path={routeMapMini.ticketDetails}
        render={() => <TicketDetails />}
      />
      <Route exact path={routeMapMini.audits} render={() => <Audit />} />
      <Route
        exact
        path={routeMapMini.newsletter}
        render={() => <NewsLetter />}
      />
      <Route
        exact
        path={routeMapMini.Counter}
        render={() => <OfflineTicket />}
      />
      <Route
        exact
        path={routeMapMini.OfflineAccommodation}
        render={() => <OfflineAccommodation />}
      />
      <Route exact path={routeMapMini.RoomList} render={() => <RoomList />} />
      <Route exact path={routeMapMini.contactUs} render={() => <ContactUs />} />
      <Route
        exact
        path={routeMapMini.CounterManagement}
        render={() => <CounterManagment />}
      />
      <Route
        exact
        path={routeMapMini.Counterdonation}
        render={() => <Sd_CounterManagement_Donation />}
      />
      <Route
        exact
        path={routeMapMini.shiftReportTable}
        render={() => <ShiftReportTable />}
      />
      <Route
        exact
        path={routeMapMini.ShiftScanLogs}
        render={() => <ShiftScanLogs />}
      />

      <Route
        exact
        path={routeMapMini.shiftManagement}
        render={() => <ShiftMangementMainPage />}
      />
      <Route
        exact
        path={routeMapMini.CounterManagementRole}
        render={() => <CounterRole />}
      />
      <Route
        path={routeMapMini.AccommodationManageCounters}
        render={() => <ManageCounters />}
      />
      <Route path={routeMapMini.userForm} render={() => <UserForm />} />
      <Route
        path={routeMapMini.AccommodationDashboard}
        render={() => <AccommodationDashboard />}
      />
      <Route
        exact
        path={routeMapMini.OfflineDarshanam}
        render={() => <Sd_Offline_Darshanam_Ticket />}
      />
      <Route exact path={routeMapMini.gstReport} render={() => <GstReport />} />
      <Route
        exact
        path={routeMapMini.PerformanceScan}
        render={() => <PerformanceScan />}
      />
      <Route
        exact
        path={routeMapMini.CheckInScan}
        render={() => <CheckInScan />}
      />
      <Route
        exact
        path={routeMapMini.PrasadamScanner}
        render={() => <PrasadamScanner />}
      />
      <Route
        exact
        path={routeMapMini.KailasaKankanamScanner}
        render={() => <KailasaKankanamScanner />}
      />
      <Route exact path={routeMapMini.TollGate} render={() => <Toll_Gate />} />
      <Route
        exact
        path={routeMapMini.TollGateCounterMain}
        render={() => <TollGateCounterMain />}
      />
      <Route
        exact
        path={routeMapMini.CounterTollGate}
        render={() => <Counter_Toll_Gate />}
      />
      <Route
        exact
        path={routeMapMini.ManagePermissions}
        render={() => <UserRoles />}
      />

      <Route
        exact
        path={routeMapMini.ScanTicketLogReport}
        render={() => <ScanTicketLogReport />}
      />
    </Switch>
  );
};

export default Routes;
