import Axios from "../utils/axios";


const kalyanaKattaService = {
    all: async () => {
        const resp = await Axios.get(`kalyanakatta/all`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
        });
        return resp.data;
    },
    //counter item list
    allByType: async () => {
        const resp = await Axios.get(`kalyanakatta-counter/counter-kalyanakatta/list`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
        });
        return resp.data;
    },

    allIdAndNames: async () => {
        const resp = await Axios.get(`darshanam/all/id-and-names`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
        });
        return resp.data;
    },

    kalyanakattabyId: async (id) => {
        const resp = await Axios.get(`/kalyanakatta/byId/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
        });
        return resp.data;
    },

    delete: async (id) => {
        const resp = await Axios.delete(`kalyanakatta/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
        });
        return resp.data;
    },

    newKalyanaKatta: async (kalyanaKattaName, disabled, price,) => {
        const body = {
            name: kalyanaKattaName,
            disabled: disabled,
            price,
        };
        Object.keys(body).forEach((key) => {
            if (body[key] === undefined || !body[key]) {
                delete body[key];
            }
        });
        const resp = await Axios.post(`kalyanakatta`, body, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
        });
        return resp.data;
    },

    editKalayanaKatta: async (updateKalaynaKattaId, kalyanaKattaName, description, disabled, price) => {
        const body = {
            id: updateKalaynaKattaId,
            name: kalyanaKattaName,
            description: description,
            disabled: disabled,
            price,
        };
        console.log(body);
        Object.keys(body).forEach((key) => {
            if (body[key] === undefined || !body[key]) {
                delete body[key];
            }
        });
        const resp = await Axios.put(`/kalyanakatta/edit`, body, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
        });
        return resp.data;
    },

    bookKalayanakatta: async (body) => {
        let resp = await Axios.post(`/cart-counter/kalyanakatta`, body, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
        });
        return resp.data;
    },


    //manage counter component API's
    getAllKalyanakattaCounterList: async (id) => {
        let resp = await Axios.get(`kalyanakatta-counter/counter/byKalyanakattaId/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
        });
        return resp.data;
    },

    addCounterKalayanakattabyId: async (body) => {
        const resp = await Axios.post(`/kalyanakatta-counter/new`, body, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
        });

        return resp.data;
    },

    deleteCounterbyKalyanakattaId: async (id) => {
        let resp = await Axios.delete(
            `/kalyanakatta-counter/ByKalyanakattaCounterId/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                }
            }
        );
        return resp.data;
    },

    getPrasadamReportCollection: async (fromDate, toDate, txType) => {
        const resp = await Axios.get(
            `/prasadam-report/collection?fromDate=${fromDate}&aggregateBy=publicationName&toDate=${toDate}&paymentStatus=success&txType=${txType}`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                }
            }
        );
        return resp.data;
    },
};
export default kalyanaKattaService;
