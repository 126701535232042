/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import RolesService from "../../Services/Roles.service";
import {
  Button,
  Input,
  notification,
  Popconfirm,
  Space,
  Table,
  Tag,
} from "antd";
import { routeMapMini } from "../../constants/routeMap";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { withRouter } from "react-router-dom";

const UsersRolesTable = ({
  history,
  setShowModifyUserModal,
  setUserRolesData,
  setCUserPhone,
  setUserId,
  updateTable,
  setEditRolesFlag,
}) => {
  const [allUsers, setAllUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const data = await RolesService.getUsers();
      setAllUsers(data);
    } catch (error) {
      console.log(error.response);
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
    setLoading(false);
  };

  const fetchUsersRoles = async (userId) => {
    setLoading(true);
    try {
      const userRolesData = await RolesService.getUsersRoles(userId);
      setUserRolesData(userRolesData?.permissions);
    } catch (error) {
      console.log(error.response);
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, [updateTable]);

  const onClickModifyFuction = (userId, phone) => {
    let cUserPhone = phone.substr(3, 10);
    setUserId(userId);
    setCUserPhone(cUserPhone);
    setShowModifyUserModal(true);
    fetchUsersRoles(userId);
    setEditRolesFlag(true);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "User",
      dataIndex: "display_name",
      key: "display_name",
      ...getColumnSearchProps("display_name"),
      render: (text, record) => {
        return (
          <a
            onClick={() => {
              history?.push(`${routeMapMini.userDetails}?userId=${record.id}`);
            }}
            style={{ textTransform: "capitalize" }}
          >
            {text}
          </a>
        );
      },
    },
    { title: "Phone No.", dataIndex: "phone", key: "phone" },
    {
      title: "Role",
      key: "role",
      dataIndex: "role",
      filters: [
        {
          text: "admin",
          value: "admin",
        },
        {
          text: "manager",
          value: "manager",
        },
        {
          text: "operator",
          value: "operator",
        },
      ],
      onFilter: (value, record) => {
        return record.role === value;
      },
      render: (text) => {
        if (text === "admin") {
          return <Tag color="green">Admin</Tag>;
        } else if (text === "operator") {
          return <Tag color="orange">Operator</Tag>;
        } else if (text === "manager") {
          return <Tag color="blue">Manager</Tag>;
        } else {
          return <Tag color="red">{text}</Tag>;
        }
      },
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                onClickModifyFuction(record?.id, record?.phone);
              }}
            >
              Modify
            </a>
          </Space>
        );
      },
    },
  ];
  return (
    <Table
      scroll={{ x: true }}
      style={{ width: "100%", textTransform: "capitalize" }}
      columns={columns}
      dataSource={allUsers}
      loading={loading}
      size="small"
    ></Table>
  );
};

export default withRouter(UsersRolesTable);
