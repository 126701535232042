// import {notification} from "antd";
import React from "react";
import moment from "moment";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // Legend,
  ResponsiveContainer,
} from "recharts";
import {FormatNumberINRCurrency} from "../../../utils/currencyFormat";
import "../dt.scss";
const CustomChart = ({
  update,
  dataCollection,
  getPublicationsCollection,
  getDarshanamTypeCollection,
  getDonationTypeCollection,
  getSevaTypeCollection,
  getPrasadamsCollection,
  getTollGateCollection,
  getThulabharamCollection,
}) => {
  const CustomTooltip = ({active, payload, label}) => {
    if (active && payload && payload.length) {
      // console.log(label, payload);
      return (
        <div
          className="custom-tooltip"
          onClick={async () => {
            switch (payload[0].payload.bookingType) {
              case "donation":
                await getDonationTypeCollection();
                break;

              case "parokshaseva":
                await getSevaTypeCollection("sevaName", "parokshaSeva");
                break;

              case "pratyakshaseva":
                await getSevaTypeCollection("sevaName", "pratyakshaSeva");
                break;

              case "darshanam":
                await getDarshanamTypeCollection();
                break;

              case "publication":
                await getPublicationsCollection();
                break;
              case "prasadam":
                await getPrasadamsCollection();
                break;
              case "tollgate":
                await getTollGateCollection();
                break;
              case "thulabharam":
                await getThulabharamCollection();
                break;
              default:
                break;
            }
          }}
        >
          <p className="label">{`${label}`}</p>
          <p className="Collection">
            {FormatNumberINRCurrency(payload[0].value)}
          </p>
          <p className="Collection">
            {payload[0].payload.type === "darshanam"
              ? "Devotee Count"
              : payload[0].payload.type === "prasadam"
              ? `${label} Count`
              : payload[0].payload.type === "tollgate"
              ? "Total Vehicles"
              : "Count"}
            : {payload[0].payload.total}
          </p>
        </div>
      );
    }

    return null;
  };
  return (
    <div style={{width: "100%", height: "320px", position: "relative"}}>
      {
        <>
          {update ? (
            <p
              style={{
                margin: "0px",
                textAlign: "left",
                position: "absolute",
                right: "30px",
                marginTop: "-6px",
              }}
            >
              Last Updated at
              {moment(update).format("HH:mm:ss DD-MM-YYYY")}
            </p>
          ) : (
            ""
          )}
        </>
      }
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={dataCollection}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barSize={40}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="bookingType" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="collection"
            stackId="a"
            fill="#82ca9d"
            onClick={async (props) => {
              switch (props.bookingType?.toLowerCase?.()) {
                case "donation":
                  await getDonationTypeCollection();
                  break;

                case "parokshaseva":
                  await getSevaTypeCollection("sevaName", "parokshaSeva");
                  break;

                case "pratyakshaseva":
                  await getSevaTypeCollection("sevaName", "pratyakshaSeva");
                  break;

                case "darshanam":
                  await getDarshanamTypeCollection();
                  break;

                case "publication":
                  await getPublicationsCollection();
                  break;
                case "prasadam":
                  await getPrasadamsCollection();
                  break;
                case "tollgate":
                  await getTollGateCollection();
                  break;
                case "thulabharam":
                  await getThulabharamCollection();
                  break;
                default:
                  break;
              }
            }}
            barSize={40}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
export default CustomChart;
