import { notification, Tabs, Button } from "antd";
import React, { useEffect, useState } from "react";
import UserService from "../../Services/User.service";
import UserCard from "./UserDetails/UserCard";
import UserOrderStats from "./UserDetails/UserOrderStats";
import { routeMapMini } from "../../constants/routeMap";
import { withRouter } from "react-router-dom";

const UserDetailsCard = ({ history, location, userid }) => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState();

  const { TabPane } = Tabs;

  const fetchUserDetails = async () => {
    setLoading(true);
    setData(undefined);
    try {
      const data = await UserService.userDetails("userId", userid);
      if (data) {
        setData(data);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid]);

  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="User Details" key="1">
          <UserCard
            userLoading={loading}
            userDetails={data}
            style={{ float: "right" }}
            cardwidth={"100%"}
          />
          <Button
            type="primary"
            onClick={() => {
              history?.push(`${routeMapMini.userDetails}?userId=${userid}`);
            }}
          >
            More info
          </Button>
        </TabPane>
        <TabPane tab="Order Stats" key="2">
          <UserOrderStats
            userId={userid}
            cardsmall={24}
            cardmid={24}
            cardlarge={24}
            graphsmall={24}
            graphlarge={24}
            cardheight={"100px"}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default withRouter(UserDetailsCard);
