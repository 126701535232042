import { Button, Col, Input, notification, Row, Space } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import TextArea from 'antd/lib/input/TextArea';
import Modal from 'antd/lib/modal/Modal';
import { useContext, useEffect, useState } from 'react';

import { withRouter } from 'react-router-dom';
import { routeMapMini } from '../../constants/routeMap';
import { UserContext } from '../../context/user.context';
import CounterService from '../../Services/Counter.service';
import CounterTable from './CounterTable';

const CounterManagment = ({ history }) => {
  const [showNewModal, setShowNewModal] = useState(false);
  const [loading, setLoading] = useState();
  const [counterName, setCounterName] = useState('');
  const [category, setCategory] = useState('');
  const [location, setLocation] = useState();
  const [disabled, setDisabled] = useState(false);
  const [contactNumber, setContactNumber] = useState('');
  const [email, setEmail] = useState();

  const [updateTable, setUpdateTable] = useState();

  const [updateCounterId, setUpdateCounterId] = useState();

  const { userDetails } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      if (updateCounterId) {
        setShowNewModal(true);
        try {
          const resp = await CounterService.getCounterById(updateCounterId);
          setCounterName(resp?.name);
          setCategory(resp?.category);
          setLocation(resp?.location);
          setDisabled(resp?.disabled);
          setContactNumber(resp?.contact_number);
          setEmail(resp?.email);
          setShowNewModal(true);
        } catch (error) {
          if (error?.response?.status === 401) {
            notification.error({
              message: 'Token Expired, redirecting to login page in 5 seconds',
            });
            history?.push(routeMapMini.Login);
          } else if (error.response?.data?.message) {
            notification.error({ message: error.response?.data?.message });
          } else {
            notification.error({ message: error.message });
          }
        }
      }
    })();
  }, [history, updateCounterId]);

  const handleOk = async () => {
    if (!counterName) {
      return notification.error({ message: `name is required` });
    }
    if (!category) {
      return notification.error({ message: `catogery is required` });
    }
    setLoading(true);
    try {
      if (updateCounterId) {
        await CounterService.editCounter(
          updateCounterId,
          counterName,
          category,
          location,
          contactNumber,
          email,
          disabled
        );
        notification.success({
          message: `Counter ${updateCounterId} updated`,
        });
        clearFields();
        setUpdateTable(updateCounterId + Math.random());
      } else {
        const doantion = await CounterService.newCounter(
          counterName,
          category,
          location,
          contactNumber,
          email,
          disabled
        );
        notification.success({ message: `Counter ${doantion.id} created` });
        setUpdateTable(doantion.id + Math.random());
      }
      setShowNewModal(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: 'Token Expired, redirecting to login page in 5 seconds',
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({ message: error.response?.data?.message });
      } else {
        notification.error({ message: error.message });
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setCounterName('');
    setCategory(undefined);
    setLocation(undefined);
    setDisabled(undefined);
    setContactNumber('');
    setUpdateCounterId(undefined);
  };

  const handleCancel = () => {
    clearFields();
    setShowNewModal(false);
  };

  return (
    <div style={{ width: '100%' }}>
      <Row style={{ width: '100%', marginBottom: '15px' }} gutter={[20, 20]}>
        <Col>
          <Button
            type='primary'
            onClick={() => {
              setShowNewModal(true);
            }}
            disabled={!['admin'].includes(userDetails.role)}
          >
            Add New Counter
          </Button>
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <CounterTable
          updateTable={updateTable}
          setUpdateCounterId={setUpdateCounterId}
        />
      </Row>
      <Modal
        visible={showNewModal}
        title='Add New Counter'
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify='center' style={{ width: '100%', padding: '10px 0px' }}>
          <Input
            placeholder='Counter Name'
            value={counterName}
            onChange={(e) => {
              setCounterName(e.target.value);
            }}
            style={{ width: '400px' }}
          />
        </Row>
        <Row justify='center' style={{ width: '100%', padding: '10px 0px' }}>
          <TextArea
            placeholder='Category'
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            style={{ width: '400px' }}
          />
        </Row>
        <Row justify='center' style={{ width: '100%', padding: '10px 0px' }}>
          <Input
            placeholder='location'
            value={location}
            onChange={(e) => {
              setLocation(e.target.value);
            }}
            style={{ width: '400px' }}
          />
        </Row>
        <Row justify='center' style={{ width: '100%', padding: '10px 0px' }}>
          <Input
            placeholder='Contact Number'
            value={contactNumber}
            onChange={(e) => {
              setContactNumber(e.target.value);
            }}
            style={{ width: '400px' }}
          />
        </Row>
        <Row justify='center' style={{ width: '100%', padding: '10px 0px' }}>
          <Input
            placeholder='Email'
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            style={{ width: '400px' }}
          />
        </Row>
        <Row justify='center' style={{ width: '100%', padding: '10px 0px' }}>
          <Space size='middle'>
            Disabled Counter
            <Checkbox
              checked={disabled}
              onChange={(e) => {
                setDisabled(e.target.checked);
              }}
            />
          </Space>
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(CounterManagment);
