import {RightOutlined} from "@ant-design/icons";
import {
  Card,
  Col,
  Row,
  Input,
  Select,
  Divider,
  Button,
  notification,
  Radio,
  Modal,
} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router";
import TollGateServices from "../../../Services/TollGate.service";
import {routeMapMini} from "../../../constants/routeMap";
import PrintTicket from "../../Ticket/PrintTicket";
import {FormatNumberINRCurrency} from "../../../utils/currencyFormat";
import "../TicketScan.scss";
const {Meta} = Card;
const Counter_Toll_Gate = ({history}) => {
  const [numPlate, setNumPlate] = useState("");
  // const [payment, setPayment] = useState("CASH");
  // const [cashReference, setCashReference] = useState("");
  // const [cashRemarks, setCashRemarks] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookTollGate, setBookTollGate] = useState();
  const [tollGates, setTollGates] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tollGateId, setTollGateId] = useState();
  const [price, setPrice] = useState(0);
  const [externalPrint, setExternalPrint] = useState(false);
  const ref = useRef(null);

  const fetchTollGate = async () => {
    setLoading(true);
    try {
      const tollgatesdata = await TollGateServices.fetchCounterTollGates();
      setTollGates(tollgatesdata);
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTollGate();
  }, []);

  const handleSubmit = async () => {
    setButtonLoading(true);
    setLoading(true);
    if (tollGateId === "") {
      notification.error({message: "Please select toll gate"});
      setLoading(false);
      setButtonLoading(false);
      return;
    }
    if (numPlate !== "") {
      if (numPlate.length !== 13 && numPlate.length !== 12) {
        notification.error({message: "Number Plate must be 10 digits"});
        setLoading(false);
        setButtonLoading(false);
        return;
      }
      let regex = /^[A-Z]{2}\s[0-9]{2}\s[A-Z]{1,2}\s[0-9]{4}$/;
      if (!regex.test(numPlate.toUpperCase())) {
        notification.error({message: "Invalid Number Plate."});
        setLoading(false);
        setButtonLoading(false);
        return;
      }
    }
    try {
      const data = await TollGateServices.bookTollGate(
        tollGateId,
        numPlate,
        "CASH",
        "",
        ""
      );
      setBookTollGate(data);
      setIsModalVisible(true);
      setExternalPrint(true);
      notification.success({message: "Success"});
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setButtonLoading(false);
    setLoading(false);
  };

  const selectSlot = (tollGatePrice, id) => {
    setTollGateId(id);
    setPrice(tollGatePrice);
  };

  return (
    <div className="darshanamBody">
      <Card className="darshanamBodyCard">
        <Row justify="center" className="darshanamTitle">
          Toll Gate
        </Row>
        <Divider />
        <Row justify="space-between" style={{marginBottom: "15px"}}>
          <Col style={{width: "30%", display: "flex", alignItems: "center"}}>
            <div
              style={{
                fontWeight: "600",
                whiteSpace: "nowrap",
                marginRight: "10px",
              }}
            >
              Number Plate :
              {/* <span style={{ color: "red", marginLeft: "3px" }}>*</span> */}
            </div>

            <Input
              size="medium"
              type="text"
              placeholder="Please Number Plate"
              style={{width: "100%"}}
              value={numPlate}
              onChange={(e) => {
                setNumPlate(`${e.target.value}`);
              }}
            />
          </Col>
        </Row>
        <Row justify="space-between" className="sd-toll-gate">
          {tollGates?.map((item, i) => {
            return (
              <Col
                style={{
                  float: "left",
                  width: "100%",
                }}
              >
                <Card
                  hoverable
                  className="toll-gate-card"
                  style={{
                    width: "100%",
                    border: "2px solid #f0f2f5",
                  }}
                  onClick={() => {
                    selectSlot(item?.price, item?.id);
                    ref.current.focus();
                  }}
                >
                  <Meta
                    title={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-beetween",
                          alignItems: "center",
                        }}
                      >
                        <div style={{width: "30%"}}>
                          <span
                            style={{
                              textTransform: "capitalize",
                              fontWeight: "400",
                              textAlign: "left",
                            }}
                          >
                            {item?.name}
                          </span>
                          <Divider type="vertical" />
                        </div>

                        <div style={{width: "30%"}}>
                          {" "}
                          <span
                            style={{
                              marginLeft: "30px",
                              fontWeight: "400",
                            }}
                          >
                            Price:
                            <span
                              style={{
                                color: "red",
                                fontWeight: "600",
                                marginLeft: "5px",
                              }}
                            >
                              {FormatNumberINRCurrency(item?.price)}
                            </span>
                          </span>
                          <Divider type="vertical" />
                        </div>
                        <div style={{width: "30%"}}>
                          {" "}
                          <span
                            style={{
                              marginLeft: "30px",
                              fontWeight: "400",
                            }}
                          >
                            Duration:
                            <span
                              style={{
                                color: "red",
                                fontWeight: "600",
                                marginLeft: "5px",
                              }}
                            >
                              {item?.duration_in_hours}
                            </span>
                          </span>
                        </div>
                        <div
                          style={{
                            textAlign: "right",
                            width: "10%",
                            margin: "auto",
                            marginRight: "0",
                          }}
                        >
                          <Radio
                            defaultChecked={false}
                            value={item?.id}
                            checked={item?.id === tollGateId ? true : false}
                            id={item?.id}
                            onClick={() => {
                              setTollGateId(item.id);
                              setPrice(item?.price);
                            }}
                          />
                        </div>
                      </div>
                    }
                  />
                </Card>
              </Col>
            );
          })}
        </Row>
        <Divider />
        <Row>
          <Col className="totalAmountTitle">Total Amount :</Col>
          <Col className="totalAmount">{FormatNumberINRCurrency(price)}</Col>
        </Row>
        <Row style={{marginTop: "30px", float: "left"}}>
          <Col
            style={{
              textAlign: "right",
            }}
          >
            <Button
              ref={ref}
              type="primary"
              onClick={() => {
                handleSubmit();
              }}
              loading={buttonLoading}
            >
              Submit <RightOutlined />
            </Button>
          </Col>
        </Row>
      </Card>
      <Row style={{display: "none"}}>
        <PrintTicket
          categoryTransactionId={bookTollGate?.category_transaction_id}
          serialNumber={bookTollGate?.counter_transaction_id}
          ticketId={bookTollGate?.ticket}
          tollGateName={bookTollGate?.tollGateBooking?.tollGateName}
          numberPlate={
            bookTollGate?.tollGateBooking?.booking?.plate_number ?? "NA"
          }
          amountPaid={bookTollGate?.tollGateBooking?.booking?.price ?? 0}
          setPrintTicketModal={setIsModalVisible}
          setData={setBookTollGate}
          type="tollGate"
          from="Counter"
          externalPrint={externalPrint}
          setExternalPrint={setExternalPrint}
        />
      </Row>
      {/* <Modal
        visible={isModalVisible}
        width={450}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <PrintTicket
          categoryTransactionId={bookTollGate?.category_transaction_id}
          serialNumber={bookTollGate?.daily_category_transaction_id}
          ticketId={bookTollGate?.ticket}
          tollGateName={bookTollGate?.tollGateBooking?.tollGateName}
          numberPlate={
            bookTollGate?.tollGateBooking?.booking?.plate_number ?? "NA"
          }
          amountPaid={bookTollGate?.tollGateBooking?.booking?.price}
          setPrintTicketModal={setIsModalVisible}
          setData={setBookTollGate}
          type="tollGate"
          from="Counter"
        />
      </Modal> */}
    </div>
  );
};

export default withRouter(Counter_Toll_Gate);
