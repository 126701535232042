import React, {useState} from "react";
import {withRouter} from "react-router";
import "./sd-temple-dairy.scss";
import {Alert, Badge, Calendar} from "antd";
import moment from "moment";
import {Tabs} from "antd";
import TempleEvents from "./TempleEvents/TempleEvents";
import Panchangam from "./Panchangam/Panchangam";
import TempleCalender from "./TempleCalender/TempleCalender";
const {TabPane} = Tabs;

const TempleDairyMain = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("D");
  const onPanelChange = (value, mode) => {
    console.log(value, mode);
  };
  const [activeTab, setActiveTab] = useState("1");
  const onSelect = (value) => {
    setSelectedDate(value);
  };
  return (
    <>
      <Alert
        message={`You selected date: ${
          selectedDate && moment(selectedDate).format("DD-MM-YYYY")
        }`}
      />
      <br />
      <div className="sd-temple">
        <div className="sd-temple-left">
          {" "}
          <Calendar
            fullscreen={false}
            onSelect={onSelect}
            onPanelChange={onPanelChange}
          />
          <div className="sd-temple-bottom">
            <p>
              {" "}
              <Badge color="green" />
              {activeTab === "2" ? "Green are filled" : "Entered"}
            </p>
            <p>
              {" "}
              <Badge color="red" />
              {activeTab === "2" ? "Red are not filled" : "Entered"}
            </p>
          </div>
        </div>
        <div className="sd-temple-right">
          <div className="sd-tabs">
            <Tabs
              defaultActiveKey={activeTab}
              onChange={(e) => {
                setActiveTab(e);
              }}
            >
              <TabPane tab="Calender" key="1">
                {activeTab === "1" && (
                  <TempleCalender
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    selectedTimePeriod={selectedTimePeriod}
                    setSelectedTimePeriod={setSelectedTimePeriod}
                  />
                )}
              </TabPane>
              <TabPane tab="Panchangam" key="2">
                {activeTab === "2" && (
                  <Panchangam
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    selectedTimePeriod={selectedTimePeriod}
                    setSelectedTimePeriod={setSelectedTimePeriod}
                  />
                )}
              </TabPane>
              <TabPane tab="Events" key="3">
                {activeTab === "3" && (
                  <TempleEvents
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    selectedTimePeriod={selectedTimePeriod}
                    setSelectedTimePeriod={setSelectedTimePeriod}
                  />
                )}
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(TempleDairyMain);
