/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Input,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import {useState} from "react";
import {withRouter} from "react-router-dom";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import ParoksnaSevaReportGraphs from "./SevaReportGraphs";

const SevaReports = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{padding: 8}}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: "block"}}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{width: 90, marginRight: 8}}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{width: 90}}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const dataSource = [];

  const columns = [
    {
      title: "Seva Name",
      dataIndex: "sevaname",
      key: "sevaname",
    },
    {
      title: "Party Name",
      dataIndex: "partyName",
      key: "partyName",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Devotee Names",
      dataIndex: "devoteeNames",
      key: "devoteeNames",
    },
    {
      title: "Auspicious Day",
      dataIndex: "auspiciousDay",
      key: "auspiciousDay",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Gotram",
      dataIndex: "gotram",
      key: "gotram",
    },
    {
      title: "Seva Date",
      dataIndex: "sevaDate",
      key: "sevaDate",
    },
    {
      title: "Transaction ID",
      dataIndex: "tranactionID",
      key: "sevaDate",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Bank Transaction ID",
      dataIndex: "bankTransactionID",
      key: "bankTransactionID",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];
  return (
    <>
      <Row style={{marginBottom: "20px"}}>
        <Space size="middle">
          <Col>
            <Typography.Text style={{marginRight: "10px"}}>
              Seva Name:
            </Typography.Text>
            <Select
              style={{width: "200px"}}
              defaultValue=""
              className="sd-select-seva"
              dropdownClassName="sd-select-dropdown-seva"
            >
              <Select.Option>Ganapati Homam</Select.Option>
              <Select.Option>Chandi Homam</Select.Option>
              <Select.Option value="">All</Select.Option>
            </Select>
          </Col>
          <Col>
            <Typography.Text style={{marginRight: "10px"}}>
              From Date:
            </Typography.Text>
            <DatePicker />
          </Col>
          <Col>
            <Typography.Text style={{marginRight: "10px"}}>
              To Date:
            </Typography.Text>
            <DatePicker />
          </Col>
          <Col>
            <Button type="primary">Fetch</Button>
          </Col>
        </Space>
      </Row>

      <Row style={{marginBottom: "10px"}}>
        <Collapse style={{width: "500px"}}>
          <Collapse.Panel header="Graphs">
            <ParoksnaSevaReportGraphs />
          </Collapse.Panel>
        </Collapse>
      </Row>

      <Table
        scroll={{x: true}}
        style={{width: "100%", textTransform: "capitalize"}}
        columns={columns}
        dataSource={dataSource}
      ></Table>
    </>
  );
};

export default withRouter(SevaReports);
