import React, {useState} from "react";
import {withRouter} from "react-router";
import CartService from "../../../Services/Cart.service";
import {
  Alert,
  Button,
  Col,
  Input,
  notification,
  Row,
  // Space,
  Typography,
} from "antd";
import {routeMapMini} from "../../../constants/routeMap";
// import DarshanamTicket from "../../Ticket/DarshanamTicket";
import ScannedTicket from "../../Ticket/ScannedTicket";
import QuaggaClass from "../../Ticket/BarcodeScanner/Quagga";
import "../TicketScan.scss";

const CheckInScan = ({history}) => {
  const [loading, setLoading] = useState(false);
  const [ticketId, setTicketId] = useState();
  const [data, setData] = useState();
  const [valid, setValid] = useState(false);
  const [errMessage, setErrMessage] = useState();
  const [scanning, setScanning] = useState("");
  const [closeFlag, setCloseFlag] = useState(false);
  const Scan = () => {
    setScanning(!scanning);
    if (scanning === false) {
      setCloseFlag(true);
    }
  };

  const OnDetected = (result) => {
    setScanning(false);
    setTicketId(result.codeResult.code);
    setValid(false);
    setErrMessage(undefined);
  };

  const ticketScan = async () => {
    setValid(false);
    setErrMessage(undefined);
    setLoading(true);
    if (!ticketId) {
      notification.error({message: "Enter Ticket Number!"});
    }
    try {
      const data = await CartService.scanTicket(ticketId);
      if (data) {
        setData(data);
        setTicketId("");
      }
      setValid(true);
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        setValid(false);
        setErrMessage(error?.response?.data?.message);
        setTicketId("");
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setLoading(false);
  };

  const onChangeTicketId = (e) => {
    setTicketId(e.target.value);
    setValid(false);
    setErrMessage(undefined);
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      ticketScan();
    }
  };

  return (
    <>
      <Row justify="center">
        <Typography.Title> Check In Scan </Typography.Title>
      </Row>
      <Row justify="center">
        {!scanning ? (
          <>
            <Col
              className="mobile-responsive-width"
              style={{margin: "8px", textAlign: "left"}}
            >
              {" "}
              Ticket No:{" "}
            </Col>
            <Col className="mobile-responsive-width" style={{margin: "8px"}}>
              <Input
                value={ticketId}
                onChange={onChangeTicketId}
                onKeyDown={onKeyDown}
                className="mobile-responsive-width"
              />
            </Col>
            <Col className="mobile-responsive-width" style={{margin: "8px"}}>
              <Button
                className="mobile-responsive-width"
                type="primary"
                onClick={ticketScan}
                loading={loading}
              >
                GO!
              </Button>
            </Col>
          </>
        ) : (
          ""
        )}
        <Col className="mobile-responsive-width">
          <Button
            className="mobile-button-width"
            type="primary"
            onClick={() => {
              Scan();
            }}
            loading={loading}
            style={{margin: "8px"}}
          >
            {scanning ? "Close Scan" : " Barcode Scan"}
          </Button>
          {scanning ? (
            <QuaggaClass
              onDetected={OnDetected}
              closeFlag={closeFlag}
              setCloseFlag={setCloseFlag}
            />
          ) : null}
          {/* <br />
              <ul className="results">
                <p>{results?.codeResult?.code}</p>
              </ul> */}
        </Col>
      </Row>
      <Row justify="center" style={{margin: "20px"}}>
        {valid && data && (
          <Alert
            message={`Ticket Scanned ${data?.ticketId}`}
            description={`Ticket has been scanned successfully.`}
            type="success"
            showIcon
          />
        )}
        {!valid && errMessage && (
          <Alert
            style={{paddingRight: "30px"}}
            message={`Error Scanning Ticket ${data?.ticketId}`}
            description={errMessage}
            type="error"
            showIcon
          />
        )}
      </Row>
      {valid && data && (
        <Row
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xsl={12}
          style={{
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <ScannedTicket data={data} />
        </Row>
      )}
    </>
  );
};

export default withRouter(CheckInScan);
