import { useContext, useEffect } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import './App.css';
import HomeLayout from './Components/Layout/Layout';
import Login from './Components/Login/Login';
import { routeMapMini } from './constants/routeMap';
import { UserContext } from './context/user.context';
import AuthService from './Services/Auth.service';

const App = (props) => {
  const { setUserDetails } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        props.history.push(routeMapMini.Login);
      } else {
        try {
          const user = await AuthService.checkToken();
          setUserDetails(user);
          props.history.push(props.location);
        } catch (error) {
          if (error?.response?.status !== 401) {
            console.error(error);
          }
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          props.history.push(routeMapMini.Login);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history]);

  return (
    <div className='App'>
      <Switch>
        <Route
          exact
          path={routeMapMini.Login}
          component={Login}
          render={() => {
            return <Login />;
          }}
        />
        <Route
          path={'/'}
          render={() => {
            return <HomeLayout />;
          }}
        />
      </Switch>
    </div>
  );
};

export default withRouter(App);
