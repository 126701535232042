import {
  Button,
  Col,
  DatePicker,
  Input,
  notification,
  Row,
  Divider,
  Modal,
} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {withRouter} from "react-router";
import {SADContext} from "../../../context/SAD.context";
import {routeMapMini} from "../../../constants/routeMap";
import {LoadingOutlined, RightOutlined} from "@ant-design/icons";
import {Select} from "antd";
import moment from "moment";
import {FormatNumberINRCurrency} from "../../../utils/currencyFormat";
import "./styles.css";
import StringUtils from "../../../utils/string.utils";
import PrintTicket from "../../Ticket/PrintTicket";
import DonationService from "../../../Services/Donation.service";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";

const {Option} = Select;
const Sd_CounterManagement_Donation = ({history}) => {
  const {selectedDonation, setSelectedDonation} = useContext(SADContext);
  const [sevaList, setSevaList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [gothram, setGothram] = useState("");
  const [payment, setPayment] = useState("CASH");
  const [idProofType, setIdProofType] = useState("");
  const [idProofNumber, setIdProofNumber] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bookingData, setBookingData] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);

  const [masam, setMasam] = useState("Chaithram");
  const [tithi, setTithi] = useState("Prathama");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [paksham, setPaksham] = useState("shukla");
  const [calenderType, setCalenderType] = useState("english");
  const [cashReference, setCashReference] = useState("");
  const [cashRemarks, setCashRemarks] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const labelStyles = {float: "left", fontSize: "17px", marginBottom: "5px"};
  const [devoteeName, setDevoteeName] = useState("");
  const [onBehalfOf, setOnBehalfof] = useState("");

  useEffect(() => {
    getDonationSevaList();
    setDevoteeName("");
  }, []);

  const getDonationSevaList = async () => {
    setLoading(true);
    try {
      let res = await DonationService.getAllOfflineCounterDonationList();
      setSevaList(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        console.log(error);

        notification.error({message: error.response?.data?.message});
      } else {
        console.log(error);
        notification.error({message: error.message});
      }
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setButtonLoading(true);
    if (
      phone === "" ||
      devoteeName === "" ||
      zip === "" ||
      address === "" ||
      city === "" ||
      state === "" ||
      phone === "" ||
      payment === ""
    ) {
      notification.error({message: "Please fill all mandatory fields"});
      setLoading(false);
      setButtonLoading(false);
      return;
    }

    if (phone.length !== 10) {
      notification.error({message: "Phone number must be 10 digits"});
      setLoading(false);
      setButtonLoading(false);
      return;
    }
    if (idProofType !== "") {
      if (idProofType === "pan") {
        let txt = idProofNumber.toUpperCase();
        let regex = /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
        let pan = {
          C: "Company",
          P: "Personal",
          H: "Hindu Undivided Family (HUF)",
          F: "Firm",
          A: "Association of Persons (AOP)",
          T: "AOP (Trust)",
          B: "Body of Individuals (BOI)",
          L: "Local Authority",
          J: "Artificial Juridical Person",
          G: "Govt",
        };
        pan = pan[txt[3]];
        if (regex.test(txt)) {
          if (pan !== "Personal") {
            notification.error({message: "Invalid Pan Card Number."});
            setLoading(false);
            setButtonLoading(false);
            return;
          }
        } else {
          notification.error({message: "Invalid Pan Card Number."});
          setLoading(false);
          setButtonLoading(false);
          return;
        }
      } else if (idProofType === "voter") {
        let regex = /^([a-zA-Z]){3}([0-9]){7}?$/;
        if (!regex.test(idProofNumber.toUpperCase())) {
          notification.error({message: "Invalid Voter Card Number."});
          setLoading(false);
          setButtonLoading(false);
          return;
        }
      } else if (idProofType === "aadhar") {
        let regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
        if (!regex.test(idProofNumber.toUpperCase())) {
          notification.error({message: "Invalid Aadhar Card Number."});
          setLoading(false);
          setButtonLoading(false);
          return;
        }
      }
    }
    if (email !== "") {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(email).toLowerCase())) {
        notification.error({message: "Invalid Email."});
        setLoading(false);
        setButtonLoading(false);
        return;
      }
    }
    if (calenderType === "english") {
      if (date === "") {
        notification.error({message: "Select donation date"});
        setLoading(false);
        setButtonLoading(false);
        return;
      }
    }
    try {
      let body = {
        // "date": "dolor",
        idProofType: idProofType,
        idProofNumber: idProofNumber,
        name: devoteeName,
        contact: `+91${phone}`,
        address: address,
        inTheNameOf: onBehalfOf,
        calendar_type: calenderType,
        pin_code: zip,
        state: state,
        city: city,
        country: country,
        donationId: selectedDonation.sd_donation_id,
        cashType: payment,
        amount: amount,
      };
      if (calenderType === "telugu") {
        body.masam = masam;
        body.paksham = paksham;
        body.tithi = tithi;
      } else {
        body.date = moment(date).format("YYYY-MM-DD");
      }
      if (idProofType !== "") {
        body.idProofType = idProofType;
        body.idProofNumber = idProofNumber;
      }
      if (email !== "") body.email = email;
      if (gothram !== "") body.gothram = gothram;
      if (cashReference !== "") body.cashReference = cashReference;
      if (cashRemarks !== "") body.cashReference = cashRemarks;

      let res = await DonationService.bookDonationTicket(body);
      notification.success({message: "Success"});
      setBookingData(res);
      clearFields();
      setLoading(false);
      setIsModalVisible(true);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
      setLoading(false);
    }
    setButtonLoading(false);
  };
  const clearFields = () => {
    setPhone("");
    setIdProofNumber("");
    setIdProofType("");
    setSelectedDonation("");
    setGothram("");
    setAddress("");
    setCity("");
    setZip("");
    setEmail("");
    setAmount("");
    setPaksham("shukla");
    setMasam("Chaithram");
    setTithi("Prathama");
    setCashReference("");
    setCashRemarks("");
  };

  return (
    <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div
          style={{
            maxWidth: "1200px",
            width: "100%",
            background: "white",
            padding: "30px 50px",
          }}
        >
          <Row
            justify="center"
            style={{fontSize: "22px", marginBottom: "20px"}}
          >
            Donation
          </Row>
          <Divider />
          <Row justify="space-between">
            <Col style={{width: "48%", justifyContent: "start"}}>
              <label style={labelStyles}>
                Select Donation Type
                <span style={{color: "red", marginLeft: "3px"}}>*</span>
              </label>
              <br />
              <Select
                size="medium"
                style={{width: "100%"}}
                showSearch
                placeholder="Select Donation Type"
                optionFilterProp="children"
                value={selectedDonation?.sd_donation?.name}
                onChange={(e) => {
                  let temp = sevaList?.find((a) => a.sd_donation?.name === e);
                  setSelectedDonation(temp);
                }}
              >
                {sevaList?.map((item) => {
                  return (
                    <Option
                      key={item?.sd_donation?.name}
                      value={item?.sd_donation?.name}
                    >
                      {item?.sd_donation?.name} - ( Minimum Amount-Rs.
                      {item?.sd_donation?.min_amount})
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col style={{width: "48%", justifyContent: "start"}}>
              <label style={labelStyles}>
                Enter Amount To be donated
                <span style={{color: "red", marginLeft: "3px"}}>*</span>
              </label>
              <br />
              <Input
                type="number"
                onWheel={(event) => event.currentTarget.blur()}
                size="medium"
                maxLength="6"
                style={{textTransform: "none"}}
                placeholder={`Minimum donation amount Rs. ${
                  selectedDonation?.sd_donation?.min_amount
                    ? selectedDonation?.sd_donation?.min_amount
                    : ""
                }`}
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <Row justify="space-between">
            <Col style={{width: "48%", justifyContent: "start"}}>
              <label style={labelStyles}>
                Devotee Name
                <span style={{color: "red", marginLeft: "3px"}}>*</span>
              </label>
              <br />
              <Input
                type="text"
                placeholder="Enter a Name"
                value={devoteeName}
                onChange={(e) => {
                  setDevoteeName(e.target.value);
                  setOnBehalfof(e.target.value);
                }}
              />
            </Col>
            <Col style={{width: "48%", justifyContent: "start"}}>
              <label style={labelStyles}>
                Booking Date
                <span style={{color: "red", marginLeft: "3px"}}>*</span>
              </label>
              <br />
              <DatePicker
                value={moment(new Date())}
                style={{width: "100%"}}
                format="DD-MM-YYYY"
                defaultValue={moment(new Date())}
                disabled={true}
              />
            </Col>
          </Row>

          <Divider />

          <Row style={{display: "flex", flexDirection: "column"}}>
            <label style={{...labelStyles, fontSize: "24px"}}>
              Devotee Details
            </label>
            <br />
            <Row justify="space-between">
              <Col style={{width: "48%", justifyContent: "start"}}>
                <label style={labelStyles}>
                  On behalf Of
                  <span style={{color: "red", marginLeft: "3px"}}>*</span>
                </label>
                <br />
                <Input
                  type="text"
                  placeholder="Enter a Name"
                  style={{height: "40px"}}
                  value={onBehalfOf}
                  onChange={(e) => {
                    setOnBehalfof(e.target.value);
                  }}
                />
              </Col>
              <Col style={{width: "48%"}}>
                <label style={labelStyles}>
                  Mobile Number
                  <span style={{color: "red", marginLeft: "3px"}}>*</span>
                </label>
                <br />
                <Input
                  size="medium"
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  maxLength={10}
                  addonBefore={"+91"}
                  placeholder="Please Enter Mobile Number"
                  style={{width: "100%"}}
                  value={phone}
                  onChange={(e) => {
                    if (e.target.value.length > 10) {
                      return;
                    }
                    setPhone(`${e.target.value}`);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row justify="space-between">
              <Col style={{width: "48%", justifyContent: "start"}}>
                <label style={labelStyles}>
                  Calendar Type
                  <span style={{color: "red", marginLeft: "3px"}}>*</span>
                </label>
                <br />
                <Select
                  size="medium"
                  style={{width: "100%"}}
                  placeholder="Select Calender Type"
                  optionFilterProp="children"
                  value={calenderType}
                  onChange={(e) => {
                    setCalenderType(e);
                  }}
                >
                  <Option key={"english"} value={"english"}>
                    English Calendar
                  </Option>
                  <Option key={"telugu"} value={"telugu"}>
                    Telugu Calendar
                  </Option>
                </Select>
              </Col>
              {calenderType === "english" ? (
                <Col style={{width: "48%", justifyContent: "start"}}>
                  <label style={labelStyles}>
                    Performance Date
                    <span style={{color: "red", marginLeft: "3px"}}>*</span>
                  </label>
                  <br />
                  <DatePicker
                    value={date}
                    style={{width: "100%"}}
                    format="DD-MM-YYYY"
                    disabledDate={(d) =>
                      !d || d.isBefore(StringUtils.addDays(new Date(), -1))
                    }
                    onChange={(dates) => {
                      setDate(dates);
                    }}
                  />
                </Col>
              ) : (
                <Col style={{width: "48%", justifyContent: "start"}}>
                  <label style={labelStyles}>
                    Select Masam
                    <span style={{color: "red", marginLeft: "3px"}}>*</span>
                  </label>
                  <br />
                  <Select
                    size="medium"
                    style={{width: "100%"}}
                    placeholder="Select Masam"
                    optionFilterProp="children"
                    value={masam}
                    onChange={(e) => {
                      setMasam(e);
                    }}
                  >
                    <Option key={"Chaithram"} value={"Chaithram"}>
                      Chaithram
                    </Option>
                    <Option key={"Vaisaakham"} value={"Vaisaakham"}>
                      Vaisaakham
                    </Option>
                    <Option key={"Jyeshttam"} value={"Jyeshttam"}>
                      Jyeshttam
                    </Option>
                    <Option key={"Ashadam"} value={"Ashadam"}>
                      Ashadam
                    </Option>
                    <Option key={"Sraavanam"} value={"Sraavanam"}>
                      Sraavanam
                    </Option>
                    <Option key={"Bhaadhrapadam"} value={"Bhaadhrapadam"}>
                      Bhaadhrapadam
                    </Option>
                    <Option key={"Aasveeyujam"} value={"Aasveeyujam"}>
                      Aasveeyujam
                    </Option>
                    <Option key={"Kaarthikam"} value={"Kaarthikam"}>
                      Kaarthikam
                    </Option>
                    <Option key={"Maargaseersham"} value={"Maargaseersham"}>
                      Maargaseersham
                    </Option>
                    <Option key={"Pushyam"} value={"Pushyam"}>
                      Pushyam
                    </Option>
                    <Option key={"Maagham"} value={"Maagham"}>
                      Maagham
                    </Option>
                    <Option key={"Phalgunam"} value={"Phalgunam"}>
                      Phalgunam
                    </Option>
                  </Select>
                </Col>
              )}
            </Row>
            {calenderType === "telugu" && (
              <>
                <br />
                <Row justify="space-between">
                  <Col style={{width: "48%", justifyContent: "start"}}>
                    <label style={labelStyles}>
                      Paksham
                      <span style={{color: "red", marginLeft: "3px"}}>*</span>
                    </label>
                    <br />
                    <Select
                      size="medium"
                      style={{width: "100%"}}
                      placeholder="Select a Paksham"
                      optionFilterProp="children"
                      value={paksham}
                      onChange={(e) => {
                        setPaksham(e);
                      }}
                    >
                      <Option key={"Shukla"} value={"Shukla"}>
                        Shukla
                      </Option>
                      <Option key={"Krishna"} value={"Krishna"}>
                        Krishna
                      </Option>
                    </Select>
                  </Col>

                  <Col style={{width: "48%", justifyContent: "start"}}>
                    <label style={labelStyles}>
                      Tithi
                      <span style={{color: "red", marginLeft: "3px"}}>*</span>
                    </label>
                    <br />
                    <Select
                      size="medium"
                      style={{width: "100%"}}
                      placeholder="Select a Tithi"
                      optionFilterProp="children"
                      value={tithi}
                      onChange={(e) => {
                        setTithi(e);
                      }}
                    >
                      <Option key={"Prathama"} value={"Prathama"}>
                        Prathama
                      </Option>
                      <Option key={"Dwitiya"} value={"Dwitiya"}>
                        Dwitiya
                      </Option>
                      <Option key={"Tritiya"} value={"Tritiya"}>
                        Tritiya
                      </Option>
                      <Option key={"Chaturthi"} value={"Chaturthi"}>
                        Chaturthi
                      </Option>
                      <Option key={"Panchami"} value={"Panchami"}>
                        Panchami
                      </Option>
                      <Option key={"Shashthi"} value={"Shashthi"}>
                        Shashthi
                      </Option>
                      <Option key={"Saptami"} value={"Saptami"}>
                        Saptami
                      </Option>
                      <Option key={"Ashtami"} value={"Ashtami"}>
                        Ashtami
                      </Option>
                      <Option key={"Navami"} value={"Navami"}>
                        Navami
                      </Option>
                      <Option key={"Dasami"} value={"Dasami"}>
                        Dasami
                      </Option>
                      <Option key={"Ekadasi"} value={"Ekadasi"}>
                        Ekadasi
                      </Option>
                      <Option key={"Dvadasi"} value={"Dvadasi"}>
                        Dvadasi
                      </Option>
                      <Option key={"Trayodasi"} value={"Trayodasi"}>
                        Trayodasi
                      </Option>
                      <Option key={"Chaturdashi"} value={"Chaturdashi"}>
                        Chaturdashi
                      </Option>
                      <Option
                        key={paksham === "Shukla" ? "Purnima" : "Amavasya"}
                        value={paksham === "Shukla" ? "Purnima" : "Amavasya"}
                      >
                        {paksham === "Shukla" ? "Purnima" : "Amavasya"}
                      </Option>
                    </Select>
                  </Col>
                </Row>
                <br />
              </>
            )}
            <Row justify="space-between">
              <Col style={{width: "48%"}}>
                <label style={labelStyles}>Gothram</label>
                <br />
                <Input
                  type="text"
                  placeholder="Enter a Gothram"
                  value={gothram}
                  onChange={(e) => {
                    setGothram(e.target.value);
                  }}
                />
              </Col>{" "}
              <Col style={{width: "48%"}}>
                <label style={labelStyles}>
                  Enter Address{" "}
                  <span style={{color: "red", marginLeft: "3px"}}>*</span>
                </label>
                <br />
                <Input
                  type="text"
                  placeholder="Enter address"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row justify="space-between">
              <Col style={{width: "48%"}}>
                <label style={labelStyles}>
                  Enter City{" "}
                  <span style={{color: "red", marginLeft: "3px"}}>*</span>
                </label>
                <br />
                <Input
                  type="text"
                  placeholder="Enter address"
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
              </Col>
              <Col style={{width: "48%"}}>
                <label style={labelStyles}>
                  {" "}
                  Select State{" "}
                  <span style={{color: "red", marginLeft: "3px"}}>*</span>
                </label>
                <RegionDropdown
                  className="CountryRegionDropDown"
                  style={{
                    border: "1px solid #d9d9d9",
                    marginBottom: "20px",
                    height: "32px",
                    fontSize: "14px",
                    padding: "0px 8px",
                  }}
                  country={country}
                  value={state}
                  showDefaultOption={true}
                  blankOptionLabel="Select a State"
                  onChange={(val) => {
                    setState(val);
                  }}
                />
              </Col>
            </Row>
            <Row justify="space-between">
              <Col style={{width: "48%"}}>
                <label style={labelStyles}>
                  {" "}
                  Select Country{" "}
                  <span style={{color: "red", marginLeft: "3px"}}>*</span>
                </label>
                <CountryDropdown
                  className="CountryRegionDropDown"
                  style={{
                    border: "1px solid #d9d9d9",
                    marginBottom: "20px",
                    height: "32px",
                    fontSize: "14px",
                    padding: "0px 8px",
                  }}
                  value={country}
                  onChange={(val) => {
                    setCountry(val);
                  }}
                />
              </Col>
              <Col style={{width: "48%"}}>
                <label style={labelStyles}>
                  Enter Pincode{" "}
                  <span style={{color: "red", marginLeft: "3px"}}>*</span>
                </label>{" "}
                <br />
                <Input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Enter Pincode"
                  value={zip}
                  onChange={(e) => {
                    if (e.target.value.length > 6) {
                      return;
                    }
                    setZip(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Divider />
            <Row justify="space-between">
              <Col style={{width: "48%", justifyContent: "start"}}>
                <label style={labelStyles}>Select ID Proof</label>
                <br />
                <Select
                  size="medium"
                  style={{width: "100%"}}
                  placeholder="Select an ID"
                  optionFilterProp="children"
                  value={idProofType}
                  onChange={(e) => {
                    setIdProofType(e);
                  }}
                >
                  <Option key="pan" value="pan">
                    PAN
                  </Option>
                  <Option key="aadhar" value="aadhar">
                    AADHAR
                  </Option>
                  {/* <Option key="driving" value="driving">
                      Driving License
                    </Option> */}

                  <Option key="voter" value="voter">
                    Voter ID
                  </Option>
                </Select>
              </Col>
              <Col style={{width: "48%"}}>
                <label style={labelStyles}>Enter ID Proof</label>
                <br />
                <Input
                  size="medium"
                  type="text"
                  placeholder="Enter ID Proof"
                  style={{width: "100%"}}
                  value={idProofNumber}
                  onChange={(e) => {
                    setIdProofNumber(`${e.target.value?.toUpperCase()}`);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row justify="space-between">
              <Col style={{width: "48%", justifyContent: "start"}}>
                <label style={labelStyles}>
                  Select Payment Type
                  <span style={{color: "red", marginLeft: "3px"}}>*</span>
                </label>
                <br />
                <Select
                  size="medium"
                  style={{width: "100%"}}
                  placeholder="Select payment type"
                  optionFilterProp="children"
                  value={payment}
                  onChange={(e) => {
                    setPayment(e);
                  }}
                >
                  <Option key="CASH" value="CASH">
                    Cash
                  </Option>
                  <Option key="UPI" value="UPI">
                    UPI
                  </Option>
                  <Option key="CARD" value="CARD">
                    Card
                  </Option>
                  <Option key="CHEQUE" value="CHEQUE">
                    Cheque
                  </Option>
                  <Option key="DD" value="DD">
                    DD
                  </Option>
                </Select>
              </Col>
              <Col style={{width: "48%"}}>
                <label style={labelStyles}>
                  Enter Reference (UPI Payment ID, Cheque ID, DD ID)
                </label>
                <br />
                <Input
                  size="medium"
                  type="text"
                  placeholder="Enter Reference ID"
                  style={{width: "100%"}}
                  value={cashReference}
                  onChange={(e) => {
                    setCashReference(`${e.target.value}`);
                  }}
                />
              </Col>
            </Row>
            <Row justify="space-between">
              <Col style={{width: "48%"}}>
                <label style={labelStyles}>Enter Payment Remarks </label>
                <br />
                <Input
                  size="medium"
                  type="text"
                  placeholder="Enter Payment Remarks"
                  style={{width: "100%"}}
                  value={cashRemarks}
                  onChange={(e) => {
                    setCashRemarks(`${e.target.value}`);
                  }}
                />
              </Col>
              <Col style={{width: "48%"}}>
                <label style={labelStyles}>Enter Email </label>
                <br />
                <Input
                  size="medium"
                  type="email"
                  placeholder="Enter Email"
                  style={{width: "100%"}}
                  value={email}
                  onChange={(e) => {
                    setEmail(`${e.target.value}`);
                  }}
                />
              </Col>
            </Row>
            <br /> <br />
            <Row style={{height: "50px"}}>
              <Col
                style={{
                  width: "25%",
                  fontWeight: "bold",
                  fontSize: "23px",
                  textAlign: "left",
                }}
              >
                Total Amount :
              </Col>
              <Col
                style={{
                  width: "75%",
                  textAlign: "right",
                  fontSize: "22px",
                  fontWeight: "600",
                }}
              >
                {FormatNumberINRCurrency(amount)}
              </Col>
            </Row>
            <Row>
              <Col
                style={{
                  textAlign: "right",
                }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    handleSubmit();
                  }}
                  loading={buttonLoading}
                >
                  Submit <RightOutlined />
                </Button>
              </Col>
            </Row>
          </Row>
        </div>
      )}
      <Modal
        visible={isModalVisible}
        width={850}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <PrintTicket
          type="donation"
          from="Counter"
          bookingDate={moment(bookingData?.donationBooking?.booking?.created_at)
            .utcOffset("+5:30")
            .format(`DD-MM-YYYY`)}
          performanceDate={moment(bookingData?.donationBooking?.booking?.date)
            .utcOffset("+5:30")
            .format(`DD-MM-YYYY`)}
          name={bookingData?.donationBooking?.booking?.name ?? devoteeName}
          gothram={bookingData?.donationBooking?.booking?.gothram}
          amountPaid={bookingData?.donationBooking?.booking?.price}
          actualAmount={bookingData?.donationBooking?.booking?.actual_price}
          contact={bookingData?.donationBooking?.booking?.contact}
          state={bookingData?.donationBooking?.booking?.state}
          city={bookingData?.donationBooking?.booking?.city}
          pincode={bookingData?.donationBooking?.booking?.pin_code}
          address={bookingData?.donationBooking?.booking?.address}
          ticketId={bookingData?.ticket}
          setPrintTicketModal={setIsModalVisible}
          sevaName={bookingData?.donationBooking?.donationName}
          calendarType={bookingData?.donationBooking?.booking?.calendar_type}
          masam={bookingData?.donationBooking?.booking?.masam}
          paksham={bookingData?.donationBooking?.booking?.paksham}
          tithi={bookingData?.donationBooking?.booking?.tithi}
          categoryTransactionId={bookingData?.category_transaction_id}
          setData={setBookingData}
          inTheNameOf={bookingData?.donationBooking?.booking?.in_the_name_of}
          serialNumber={bookingData?.daily_category_transaction_id}
        />
      </Modal>
    </div>
  );
};
export default withRouter(Sd_CounterManagement_Donation);
