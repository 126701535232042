import Axios from "../utils/axios";

const TollGateServices = {
  all: async () => {
    const resp = await Axios.get(`toll-gate/all`);
    return resp.data;
  },

  newTollGate: async (tollGateName, price, duration, status) => {
    const body = {
      name: tollGateName,
      price: price,
      durationInHours: duration,
      disabled: status,
    };
    Object.keys(body).forEach((key) => {
      if (body[key] === undefined || !body[key]) {
        delete body[key];
      }
    });
    const resp = await Axios.post(`toll-gate/new`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  delete: async (tollGateId) => {
    const resp = await Axios.delete(`toll-gate/byId/${tollGateId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  editTollGate: async (tollGateId, tollGateName, price, duration, status) => {
    const body = {
      toll_gate_id: tollGateId,
      name: tollGateName,
      price: price,
      durationInHours: duration,
      disabled: status,
    };
    Object.keys(body).forEach((key) => {
      if (body[key] === undefined || !body[key]) {
        delete body[key];
      }
    });
    const resp = await Axios.put(`toll-gate/edit`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  loadTollGateById: async (tollGateId) => {
    const resp = await Axios.get(`/toll-gate/byId/${tollGateId}`);
    return resp.data;
  },

  tollGateCounters: async (tollGateId) => {
    const resp = await Axios.get(
      `toll-gate/counter/byTollGateId/${tollGateId}`
    );
    return resp.data;
  },

  tollGateCounterDelete: async (counterId) => {
    const resp = await Axios.delete(`toll-gate/counter/byId/${counterId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  addCounter: async (counterId, tollGateId) => {
    const resp = await Axios.post(
      `toll-gate/counter/new`,
      { sd_counter_id: counterId, sd_toll_gate_id: tollGateId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  fetchCounterTollGates: async () => {
    const resp = await Axios.get(`toll-gate/counter/counterTollGates`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  bookTollGate: async (
    tollGateId,
    plateNumber,
    cashType,
    cashReference,
    cashRemark
  ) => {
    const body = {
      tollGateId: tollGateId,
      plateNumber: plateNumber,
      cashType: cashType,
      cashReference: cashReference,
      cashRemark: cashRemark,
    };
    Object.keys(body).forEach((key) => {
      if (body[key] === undefined || !body[key]) {
        delete body[key];
      }
    });
    const resp = await Axios.post(`cart-counter/tollGate`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  getTollGateReportCollection: async (fromDate, toDate, txType) => {
    const resp = await Axios.get(
      `/toll-gate-report/collection?fromDate=${fromDate}&toDate=${toDate}&paymentStatus=success&txType=${txType}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
};

export default TollGateServices;
