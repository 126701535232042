import React, {useEffect, useState} from "react";
import {Layout} from "antd";
import {withRouter} from "react-router-dom";

// import jwt_decode from 'jwt-decode';

import Routes from "../../Routes";
import HomeSider from "./Sider";
import HomeHeader from "./Header/Header";
import {routeMapMini} from "../../constants/routeMap";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import ShiftDetailsBreadCrumb from "../BreadCrumb/ShiftDetailsBreadCrumb";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const {Content} = Layout;

const HomeLayout = (props) => {
  const [siderCollapsed, setSiderCollapsed] = useState(true);

  const toggleSider = () => {
    setSiderCollapsed(!siderCollapsed);
  };

  const [collapsedWidth, setCollapsedWidth] = useState(80);

  const screens = useBreakpoint();

  useEffect(() => {
    if (screens?.xl || screens?.xxl || screens?.lg) {
      setCollapsedWidth(80);
    } else {
      setCollapsedWidth(0);
    }
  }, [screens]);

  useEffect(() => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        props.history.push(routeMapMini.Login);
      }
    } catch (error) {
      props.history.push(routeMapMini.Login);
    }
  }, [props.history]);

  return (
    <Layout>
      <HomeSider siderCollapsed={siderCollapsed} toggleSider={toggleSider} />
      <Layout className="site-layout">
        <HomeHeader toggleSider={toggleSider} siderCollapsed={siderCollapsed} />
        <div
          style={{
            marginLeft: siderCollapsed ? collapsedWidth : 200,
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              textAlign: "left",
              padding: "5px",
              paddingLeft: "10px",
              marginTop: "1px",
              // marginBottom: "10px",
              backgroundColor: "#ffffff",
            }}
          >
            <BreadCrumb />
          </div>
          <ShiftDetailsBreadCrumb />
          {/* <div></div> */}
          <Content
            style={{
              margin: "14px 16px",
              padding: 24,
              overflow: "auto",
            }}
          >
            <Routes />
          </Content>
        </div>
      </Layout>
    </Layout>
  );
};

export default withRouter(HomeLayout);
