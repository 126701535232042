import {Col, Row} from "antd";
import React from "react";
import numberToWords from "../../utils/amount-to-words/number-to-words";
import {sd_srisailalam_logo} from "../../assets/images";
import QRCode from "qrcode.react";
const PublicationTicket = ({
  publicationName,
  name,
  bookingDate,
  language,
  subscription,
  amountPaid,
  actualAmount,
  address,
  city,
  state,
  pincode,
  ticketId,
  categoryTransactionId,
  serialNumber,
}) => {
  return (
    <>
      <div
        style={{
          fontWeight: "bold",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          {" "}
          {`TI No : ${categoryTransactionId?.split("-")[2]} -
              ${categoryTransactionId?.split("-")?.pop()}`}
        </div>
        <div>
          {`Sl No : 
            ${serialNumber?.split("-")[3]}`}{" "}
        </div>
      </div>
      <div style={{textAlign: "center"}}>
        <img
          src={sd_srisailalam_logo}
          alt="logo"
          style={{
            width: "20%",
            justifyContent: "center",
            margin: "auto",
            filter: "grayscale(1)",
            textAlign: "center",
          }}
        />
      </div>

      <>
        <div
          style={{
            justifyContent: "center",
            margin: "0",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          <p>
            Sri Bhramaramba Mallikarjuna Swamy Varla Devasthanam - Srisailam
          </p>
        </div>
      </>
      <Row className="tickettitle">
        <p style={{textTransform: "uppercase"}}>{publicationName}</p>
      </Row>
      <div style={{margin: "20px"}}>
        <Row>
          <Col className="ticket_label">Devotee Name :</Col>
          <Col className="ticket_data">{name}</Col>
        </Row>
        <Row>
          <Col className="ticket_label">Booking Date :</Col>
          <Col className="ticket_data">{bookingDate}</Col>
        </Row>
        <Row>
          <Col className="ticket_label">Language :</Col>
          <Col className="ticket_data">{language}</Col>
        </Row>
        {subscription && (
          <Row>
            <Col className="ticket_label">Subscription :</Col>
            <Col className="ticket_data">{subscription}</Col>
          </Row>
        )}
        {amountPaid && (
          <Row>
            <Col className="ticket_label">
              {actualAmount && (
                <>
                  <p className="amount-label" style={{whitespace: "no-wrap"}}>
                    Publication Amount :
                  </p>
                  {amountPaid - actualAmount > 0 && (
                    <p className="amount-label">Handling Charges :</p>
                  )}
                </>
              )}
              <p>Total Amount :</p>
            </Col>
            <Col className="ticket_data">
              {actualAmount && (
                <>
                  <p className="amount-data">Rs. {actualAmount}.00</p>
                  {amountPaid - actualAmount > 0 && (
                    <p className="amount-data">
                      Rs. {amountPaid - actualAmount}.00
                    </p>
                  )}
                </>
              )}
              <p>
                Rs. {amountPaid}.00 <br />({numberToWords(amountPaid)} Rupees)
                <br />
              </p>
            </Col>
          </Row>
        )}
        {address && (
          <Row>
            <Col className="ticket_label">Address :</Col>
            <Col className="ticket_data">{`${address}, ${city}, ${state}, ${pincode}`}</Col>
          </Row>
        )}
      </div>
      {ticketId && (
        <Row
          style={{
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <QRCode
            size="75"
            fontSize="15px"
            value={ticketId ? ticketId : "transactionId=127"}
          />
        </Row>
      )}
    </>
  );
};

export default PublicationTicket;
