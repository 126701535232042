/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import CounterService from "../../Services/Counter.service";
import { notification, Table, Badge, Popconfirm } from "antd";

const CounterRoleTable = ({ counterId, counterRole }) => {
  const [roleDetails, setRoleDetails] = useState();
  const [loading, setLoading] = useState(false);

  const fetchRole = async () => {
    setLoading(true);
    try {
      const roleDetails = await CounterService.getCounterRoles(counterId);
      setRoleDetails(
        roleDetails.map((item) => {
          return {
            key: item?.id,
            role: item?.role,
            userName: item?.sd_user?.display_name,
            counter: item?.sd_counter?.name,
            disable: item?.sd_counter?.disabled,
          };
        })
      );
    } catch (error) {
      if (error.response?.data?.message) {
        notification.error({ message: error.response?.data?.message });
      } else {
        notification.error({ message: error.message });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (counterId) {
      fetchRole();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterId, counterRole]);

  const columns = [
    { title: "Role", dataIndex: "role", key: "role" },
    { title: "Counter", dataIndex: "counter", key: "counter" },
    {
      title: "Status",
      dataIndex: "disabled",
      key: "disabled",
      render: (text, record) => {
        return text ? <Badge color="red" /> : <Badge color="green" />;
      },
    },
    { title: "Added By", dataIndex: "userName", key: "userName" },
    {
      title: "Action",
      fixed: "right",
      responsive: ["md", "lg", "xl", "xsl"],
      render: (record) => {
        return (
          <Popconfirm
            title="Are you sure delete this role?"
            onConfirm={async () => {
              setLoading(true);
              try {
                await CounterService.deleteRoleFromCounter(
                  counterId,
                  record.role
                );
              } catch (error) {
                if (error.response?.data?.message) {
                  notification.error({
                    message: error.response?.data?.message,
                  });
                } else {
                  notification.error({ message: error.message });
                }
              }
              fetchRole();
              setLoading(false);
            }}
            okText="Yes"
            cancelText="No"
          >
            <a>Remove</a>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <>
      <Table
        tableLayout="fixed"
        scroll={{ x: true }}
        loading={loading}
        style={{ width: "100%", textTransform: "capitalize" }}
        columns={columns}
        dataSource={roleDetails}
      ></Table>
    </>
  );
};

export default CounterRoleTable;
