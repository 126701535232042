/* eslint-disable react/jsx-pascal-case */
import {
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  notification,
  Row,
} from "antd";

import Modal from "antd/lib/modal/Modal";
import {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {routeMapMini} from "../../../constants/routeMap";
import SevaSlotTable from "./SevaSlotTable";
import moment from "moment";
import SevaService from "../../../Services/Seva.service";

const SevaSlotMainPage = ({history, location}) => {
  const [loading, setLoading] = useState();
  const [showModal, setShowModal] = useState(false);
  const [list, setList] = useState([]);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [description, setDescription] = useState("");
  const [edit, setEdit] = useState(false);
  const [temp, setTemp] = useState(false);
  const [maxBookings, setMaxBookings] = useState("");
  const [openBeforeHours, setOpenBeforeHours] = useState();
  const [sevaId, setSevaId] = useState("");
  const [sevaDetails, setSevaDetails] = useState(null);
  useEffect(() => {
    getSevaDetails();
    getAllSlots();
  }, []);

  useEffect(() => {
    if (edit === true) {
      setShowModal(true);
      setDescription(temp?.description);
      setFrom(moment(temp?.start_time, "HH:mm:ss"));
      setTo(moment(temp?.end_time, "HH:mm:ss"));
      setMaxBookings(temp?.max_booking_per_day);
      setOpenBeforeHours(temp?.open_before_hours);
    }
  }, [edit]);

  const getSevaDetails = async () => {
    try {
      const resp = await SevaService.getSevaById(
        new URLSearchParams(location.search).get("id")
      );
      setSevaDetails(resp);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
  };
  const getAllSlots = async () => {
    setLoading(true);
    try {
      const id = new URLSearchParams(location.search).get("id");
      setSevaId(id);
      const resp = await SevaService.getSevaAllSlots(
        new URLSearchParams(location.search).get("id")
      );
      setList(resp);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
  };

  const handleOk = async () => {
    if (maxBookings === "") {
      notification.error({message: "Please select max bookings"});
      return;
    }
    if (description === "") {
      notification.error({message: "Please enter a Description"});
      return;
    }
    setLoading(true);
    try {
      console.log(edit);
      if (edit) {
        let body = {
          sevaId: sevaId,
          description: description,
          max_booking_per_day: maxBookings,
          start_time: moment(from).format("hh:mm A"),
          end_time: moment(to).format("hh:mm A"),
          seva_slot_id: temp?.id,
          open_before_hours: openBeforeHours,
        };
        console.log(body);

        await SevaService.editSevaSlot(body);
        notification.success({
          message: `Slot has been succesfully Edited`,
        });
        setEdit(false);
      } else {
        let body = {
          sevaId: sevaId,
          description: description,
          max_booking_per_day: maxBookings,
          start_time: moment(from).format("hh:mm A"),
          end_time: moment(to).format("hh:mm A"),
          open_before_hours: openBeforeHours,
        };
        await SevaService.addSevaSlot(body);
        notification.success({
          message: `Slot has been succesfully created`,
        });
      }

      getAllSlots();
      setShowModal(false);
      clearFields();
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setMaxBookings("");
    setDescription("");
    setFrom("");
    setTo(""); // setblockID(undefined);
  };

  const handleCancel = () => {
    clearFields();
    setShowModal(false);
    setEdit(false);
  };

  return (
    <div style={{width: "100%"}}>
      <Row style={{width: "100%", marginBottom: "15px"}} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setShowModal(true);
            }}
            // disabled={userDetails.role !== "admin"}
          >
            Add New Slot
          </Button>
        </Col>
      </Row>
      <Row
        style={{
          fontSize: "18px",
          fontWeight: "600",
          textTransform: "capitalize",
        }}
      >
        Seva Name: {sevaDetails?.name}- ( {sevaDetails?.type} )
      </Row>{" "}
      <br />
      <Row style={{width: "100%"}}>
        <SevaSlotTable
          list={list}
          loading={loading}
          setLoading={setLoading}
          getAllSlots={getAllSlots}
          edit={edit}
          setEdit={setEdit}
          setTemp={setTemp}
          sevaId={sevaId}
        />
      </Row>
      <Modal
        visible={showModal}
        title={edit ? `Edit a Slot` : `Add a New Slot to ${sevaDetails?.name}`}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row>
          Description :{" "}
          <Input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter Description"
          ></Input>
        </Row>
        <br />
        <Row width="100%">
          Max Bookings :{" "}
          <InputNumber
            defaultValue={maxBookings}
            value={maxBookings}
            min={0}
            style={{width: "100%"}}
            placeholder="Enter Maximum Bookings allowed"
            onChange={(e) => {
              setMaxBookings(e);
            }}
          ></InputNumber>
        </Row>

        <Row style={{width: "100%", padding: "25px 0px 10px 0px"}}>
          Start Time:
          <DatePicker
            allowClear
            picker="time"
            style={{marginLeft: "5px"}}
            defaultValue={from}
            value={from}
            onChange={(e) => {
              setFrom(e);
            }}
          />
          <Row style={{width: "100%", padding: "25px 0px 10px 0px"}}>
            End Time:
            <DatePicker
              allowClear
              style={{marginLeft: "5px"}}
              picker="time"
              defaultValue={to}
              value={to}
              onChange={(e) => {
                setTo(e);
              }}
            />
          </Row>
          <Row width="100%">
          Open Before Hrs :{" "}
          <InputNumber
            defaultValue={openBeforeHours}
            value={openBeforeHours}
            min={0}
            style={{width: "100%"}}
            placeholder="Enter Maximum Bookings allowed"
            onChange={(e) => {
              setOpenBeforeHours(e);
            }}
          ></InputNumber>
        </Row>
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(SevaSlotMainPage);
