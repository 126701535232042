/* eslint-disable jsx-a11y/anchor-is-valid */
import { notification, Popconfirm, Space, Table } from "antd";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { routeMapMini } from "../../../constants/routeMap";
import CounterService from "../../../Services/Counter.service";

const ShiftmangementTable = ({
  list,
  loading,
  setLoading,
  getAllShifts,
  edit,
  setEdit,
  setTemp,
  history,
}) => {
  const columns = [
    { title: "id", dataIndex: "id", key: "id" },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Start Time",
      dataIndex: "start",
      key: "start",
      render: (record) => {
        return moment(record, "HH:mm:ss").format("hh:mm A");
      },
    },
    {
      title: "End Time",
      dataIndex: "end",
      key: "end",
      render: (record) => {
        return moment(record, "HH:mm:ss").format("hh:mm A");
      },
    },

    {
      title: "Action",
      fixed: "right",
      responsive: ["md", "lg", "xl", "xsl"],
      render: (record) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={async () => {
                setLoading(true);
                try {
                  await CounterService.deleteShiftById(record.id);
                  notification.success({
                    message: `Shift has been deleted`,
                  });
                  getAllShifts();
                } catch (error) {
                  if (error?.response?.status === 401) {
                    notification.error({
                      message:
                        "Token Expired, redirecting to login page in 5 seconds",
                    });
                    history?.push(routeMapMini.Login);
                  }
                  if (error.response?.data?.message) {
                    notification.error({
                      message: error.response?.data?.message,
                    });
                  } else {
                    notification.error({ message: error.message });
                  }
                }

                setLoading(false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm>{" "}
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      tableLayout="fixed"
      scroll={{ x: true }}
      loading={loading}
      style={{ width: "100%", textTransform: "capitalize" }}
      columns={columns}
      dataSource={list}
    ></Table>
  );
};

export default withRouter(ShiftmangementTable);
