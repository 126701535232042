/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import ReportService from "../../Services/Report.service";
import {routeMapMini} from "../../constants/routeMap";
import moment from "moment";
import {
  notification,
  Row,
  Col,
  Table,
  Space,
  DatePicker,
  Button,
  Select,
} from "antd";
import {FormatNumberINRCurrency} from "../../utils/currencyFormat";
import fileDownload from "js-file-download";

const GstReport = ({history}) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [reportData, setReportData] = useState([]);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [sortType, setSortType] = useState("DESC");

  const fetchGstReport = async () => {
    setDataLoading(true);
    try {
      const data = await ReportService.gstReport(fromDate, toDate);
      console.log(data);
      setReportData(data?.transactions);
      setTabelTotalSize(data?.transactions?.length);
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setDataLoading(false);
  };

  console.log(reportData);

  useEffect(() => {
    fetchGstReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableCurrentPage, tablePageSize, fromDate, toDate, sortType]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const downloadExcel = async () => {
    notification.info({message: "Downloading file! Please wait."});
    ReportService.gstReportExcel(
      fromDate,
      toDate,
      sortType,
      tableCurrentPage,
      tablePageSize
    )
      .then((data) => {
        fileDownload(
          data,
          `${fromDate}-GSTReport-${moment().format("DD-MM-YYYY")}.xlsx`
        );
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          history?.push(routeMapMini.Login);
        } else if (error?.response?.data?.message) {
          notification.error({message: error?.response?.data?.message});
        } else {
          console.error(error);
          notification.error({message: "something is wrong"});
        }
      });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "guestName",
      key: "guestName",
    },
    {
      title: "Booking Date",
      dataIndex: "bookingDate",
      key: "bookingDate",
    },
    {title: "Room Number", dataIndex: "roomNumber", key: "roomNumber"},
    {title: "Service", dataIndex: "service", key: "service"},
    {
      title: "Service Amount",
      dataIndex: "serviceAmount",
      key: "serviceAmount",
      render: FormatNumberINRCurrency,
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: FormatNumberINRCurrency,
    },
  ];

  return (
    <>
      <Row style={{marginBottom: "20px"}} gutter={[20, 20]}>
        <Col>
          <Row justify="start">Date</Row>
          <Row justify="start">
            <DatePicker.RangePicker
              format="DD-MM-YYYY"
              onChange={(e) => {
                if (e?.[0] && e?.[1]) {
                  setFromDate(moment(e[0]).format(`YYYY-MM-DD`));
                  setToDate(moment(e[1]).format(`YYYY-MM-DD`));
                } else {
                  setFromDate(undefined);
                  setToDate(undefined);
                }
              }}
              ranges={{
                Today: [moment(), moment()],
                "Last 7 Days": [moment().subtract(7, "d"), moment()],
                "Last 15 Days": [moment().subtract(15, "d"), moment()],
              }}
              defaultValue={[moment(fromDate), moment(toDate)]}
              defaultPickerValue={[moment(fromDate), moment(toDate)]}
            />
          </Row>
        </Col>
        <Col>
          <Row justify="start">Sort Type</Row>
          <Row justify="start">
            <Select
              style={{width: "150px"}}
              onChange={(e) => {
                setSortType(e);
              }}
              value={sortType}
            >
              <Select.Option value="DESC">Descending</Select.Option>
              <Select.Option value="ASC">Ascending</Select.Option>
            </Select>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Update</Row>
          <Row justify="start">
            <Button
              type="primary"
              style={{minWidth: "100px"}}
              onClick={() => fetchGstReport()}
            >
              Fetch
            </Button>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Download</Row>
          <Row justify="start">
            <Button
              type="primary"
              style={{minWidth: "100px"}}
              onClick={() => downloadExcel()}
            >
              Excel
            </Button>
          </Row>
        </Col>
      </Row>

      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Total Bookings</span>
        </Space>
      </Row>

      <Table
        style={{width: "100%", textTransform: "capitalize"}}
        columns={columns}
        dataSource={reportData}
        loading={dataLoading}
        size="small"
        scroll={{x: true}}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        onChange={handleTableChange}
      ></Table>
    </>
  );
};

export default withRouter(GstReport);
