import React from "react";
import {Col, Row, Space, Card, Tag} from "antd";
import {FormatNumberINRCurrency} from "../../utils/currencyFormat";
import moment from "moment";
import "./PrintTicket.scss";

const ScannedTicket = ({data}) => {
  return (
    // <div style={{ width: "100%" }}>
    <Card
      hoverable={"true"}
      style={{width: "100%", textAlign: "left"}}
      title="Ticket Details"
    >
      {data?.type === "prasadam_counter" ? (
        <Col>
          {data?.ticketId && (
            <Row style={{marginBottom: "5px"}}>
              <Col className="ticket_label">Ticket ID :</Col>
              <Col className="ticket_data">{data?.ticketId}</Col>
            </Row>
          )}
          {data?.booking_date && (
            <Row style={{marginBottom: "5px"}}>
              <Col className="ticket_label">Booking Date:</Col>
              <Col className="ticket_data">{data?.booking_date}</Col>
            </Row>
          )}

          <Row style={{marginBottom: "5px"}}>
            <Col className="ticket_label">Ticket Scanned :</Col>
            <Col className="ticket_data">
              {(() => {
                if (data?.ticket_used === true) {
                  return <Tag color="success">Used</Tag>;
                } else if (data?.ticket_used === false) {
                  return <Tag color="error">Not Used</Tag>;
                }
              })()}
            </Col>
          </Row>
          {data?.amount_paid && (
            <Row style={{marginBottom: "5px"}}>
              <Col className="ticket_label">Amount Paid :</Col>
              <Col className="ticket_data">
                {FormatNumberINRCurrency(data?.amount_paid)}
              </Col>
            </Row>
          )}
          <br />
          <Row>
            <Col className="ticket_label">Details :</Col>
          </Row>
          <Row>
            <Col className="ticket_label" span="11">
              <p>Item</p>
            </Col>
            <Col className="ticket_label" span="6">
              <p>Quantity</p>
            </Col>
            <Col className="ticket_label" span="7">
              <p>Amount</p>
            </Col>
          </Row>
          {data?.prasadamItems?.map((items) => (
            <Row>
              <Col className="ticket_data" span="11">
                <p>{items?.item_name}</p>
              </Col>
              <Col className="ticket_data" span="6">
                <p>{items?.quantity}</p>
              </Col>
              <Col className="ticket_data" span="7">
                <p>{FormatNumberINRCurrency(items?.price)}</p>
              </Col>
            </Row>
          ))}
        </Col>
      ) : (
        <Col>
          {data?.ticketId && (
            <Row style={{marginBottom: "5px"}}>
              <Col className="ticket_label">Ticket ID :</Col>
              <Col className="ticket_data">{data?.ticketId}</Col>
            </Row>
          )}
          {data?.party_name && (
            <Row style={{marginBottom: "5px"}}>
              <Col className="ticket_label">User Name :</Col>
              <Col className="ticket_data">{data?.party_name}</Col>
            </Row>
          )}
          {data?.devotee_names && (
            <Row style={{marginBottom: "5px"}}>
              <Col className="ticket_label">Devotee Names :</Col>
              <Col className="ticket_data">{data?.devotee_names}</Col>
            </Row>
          )}

          {(() => {
            if (
              data?.type === "pratyakshaSeva" ||
              data?.type === "darshanam" ||
              data?.type === "darshanam_counter" ||
              data?.type === "pratyakshaSeva_counter"
            ) {
              return (
                <>
                  {data?.no_of_tickets && (
                    <Row style={{marginBottom: "5px"}}>
                      <Col className="ticket_label">No of Tickets :</Col>
                      <Col className="ticket_data">{data?.no_of_tickets}</Col>
                    </Row>
                  )}
                </>
              );
            }
          })()}
          {data?.gothram && (
            <Row style={{marginBottom: "5px"}}>
              <Col className="ticket_label">Gothram :</Col>
              <Col className="ticket_data">{data?.gothram}</Col>
            </Row>
          )}
          {data?.mobile_no && (
            <Row style={{marginBottom: "5px"}}>
              <Col className="ticket_label">Contact No :</Col>
              <Col className="ticket_data">{data?.mobile_no}</Col>
            </Row>
          )}
          {data?.seva_name && (
            <Row style={{marginBottom: "5px"}}>
              <Col className="ticket_label">
                {data?.type === "accomodation" ? `Block Name` : `Seva Name`} :
              </Col>
              <Col className="ticket_data">{data?.seva_name}</Col>
            </Row>
          )}
          {data?.booking_date && (
            <Row style={{marginBottom: "5px"}}>
              <Col className="ticket_label">Booking Date :</Col>
              <Col className="ticket_data">{data?.booking_date}</Col>
            </Row>
          )}

          {data?.type !== "accomodation" && (
            <Row style={{marginBottom: "5px"}}>
              <Col className="ticket_label">Time Slot :</Col>
              {data?.time_slot ? (
                <Col className="ticket_data">{data?.time_slot}</Col>
              ) : (
                <Col className="ticket_data">
                  {moment(data?.slot_start_start, "HH:mm:ss").format("hh:mm A")}{" "}
                  - {moment(data?.slot_end_start, "HH:mm:ss").format("hh:mm A")}
                </Col>
              )}
            </Row>
          )}
          {data?.scanned_on !== "Invalid date" && (
            <Row style={{marginBottom: "5px"}}>
              <Col className="ticket_label">Scanned Date :</Col>
              <Col className="ticket_data">{data?.scanned_on}</Col>
            </Row>
          )}
          <Row style={{marginBottom: "5px"}}>
            <Col className="ticket_label">Price :</Col>
            <Col className="ticket_data">
              {FormatNumberINRCurrency(data?.actual_amount)}
            </Col>
          </Row>
          {data?.address && (
            <Row style={{marginBottom: "5px"}}>
              <Col className="ticket_label">Address :</Col>
              <Col className="ticket_data">{data?.address}</Col>
            </Row>
          )}
          <Row style={{marginBottom: "5px"}}>
            <Col className="ticket_label">Ticket Scanned :</Col>
            <Col className="ticket_data">
              {(() => {
                if (data?.ticket_used === true) {
                  return <Tag color="success">Scanned</Tag>;
                } else if (data?.ticket_used === false) {
                  return <Tag color="error">Not Scanned</Tag>;
                }
              })()}
            </Col>
          </Row>
        </Col>
      )}
    </Card>
    // </div>
  );
};

export default ScannedTicket;
