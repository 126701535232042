/* eslint-disable jsx-a11y/anchor-is-valid */
import {Button, Input, notification, Popconfirm, Row, Space, Table} from "antd";
import {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import SevaService from "../../../Services/Seva.service";
import {routeMapMini} from "../../../constants/routeMap";

const SevaDisabledDatesTable = ({history}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);

  useEffect(() => {
    loadSevas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePageSize, tableCurrentPage]);

  const loadSevas = async () => {
    try {
      setLoading(true);
      const sevas = await SevaService.getDisabledDates(
        tableCurrentPage,
        tablePageSize
      );
      setData(
        sevas?.items?.map((item) => {
          return {
            key: item.id,
            sevaname: item.sd_seva?.name,
            start_date: item.start_date,
            end_date: item.end_date,
            type: item.sd_seva?.type,
            description: item.description,
          };
        })
      );
      setTabelTotalSize(sevas?.meta?.totalItems);
      setLoading(false);
    } catch (error) {
      if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
      setLoading(false);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{padding: 8}}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: "block"}}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{width: 90, marginRight: 8}}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{width: 90}}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Seva Name",
      dataIndex: "sevaname",
      key: "sevaname",
      ellipsis: true,
      ...getColumnSearchProps("sevaname"),
      render: (text) => {
        return (
          text && (
            <div style={{width: "200px", whiteSpace: "initial"}}>{text}</div>
          )
        );
      },
    },
    {
      title: "Seva Type",
      dataIndex: "type",
      key: "type",
      filters: [
        {
          text: "Paroksha Seva",
          value: "parokshaSeva",
        },
        {
          text: "Pratyaksha Seva",
          value: "pratyakshaSeva",
        },
      ],
      onFilter: (value, record) => record.type.startsWith(value),
      filterSearch: true,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
      render: (text) => {
        return (
          text && (
            <div style={{width: "300px", whiteSpace: "initial"}}>{text}</div>
          )
        );
      },
    },
    {
      title: "Action",
      fixed: "right",
      render: (record) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={async () => {
                setLoading(true);
                try {
                  await SevaService.deleteDisabledDate(record.key);
                } catch (error) {
                  if (error?.response?.status === 401) {
                    notification.error({
                      message:
                        "Token Expired, redirecting to login page in 5 seconds",
                    });
                    history?.push(routeMapMini.Login);
                  } else if (error.response?.data?.message) {
                    notification.error({
                      message: error.response?.data?.message,
                    });
                  } else {
                    notification.error({message: error.message});
                  }
                }
                loadSevas();
                setLoading(false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  return (
    <>
      <Row style={{marginBottom: "20px"}}>
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              history?.push(routeMapMini.DisableSeva);
            }}
          >
            {" "}
            Disable Date{" "}
          </Button>
        </Space>
      </Row>
      <Table
        tableLayout="fixed"
        scroll={{x: true}}
        loading={loading}
        style={{width: "100%", textTransform: "capitalize"}}
        columns={columns}
        dataSource={data}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        onChange={handleTableChange}
      ></Table>
    </>
  );
};

export default withRouter(SevaDisabledDatesTable);
