import {Button, Input, notification, Row, Select, Space} from "antd";
import Modal from "antd/lib/modal/Modal";
import {useContext, useState} from "react";
import {withRouter} from "react-router-dom";
import {routeMapMini} from "../../constants/routeMap";
import {UserContext} from "../../context/user.context";
import UserService from "../../Services/User.service";
import UsersTable from "./UsersTable";

const ManageUsers = ({history}) => {
  const [showManageUserModal, setShowManageUserModal] = useState(false);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [cUserPhone, setCUserPhone] = useState();
  const [cUserDisplayName, setCUserDisplayName] = useState();
  const [cUserRole, setCUserRole] = useState();
  const [cUserPass, setCUserPass] = useState();
  const [loading, setLoading] = useState();
  const [phone, setPhone] = useState();
  const [role, setRole] = useState();
  const [updateTable, setUpdateTable] = useState();
  const {userDetails} = useContext(UserContext);
  const roles = [
    {name: "Admin", key: "admin"},
    {name: "Manager", key: "manager"},
    {name: "Operator", key: "operator"},
    {name: "User", key: "user"},
    {name: "Dashboard View", key: "dashboardView"},
    {name: "Counter", key: "counter"},

    {name: "Seva counter", key: "seva_counter"},
    {name: "Seva Read", key: "seva_read"},
    {name: "Seva and darshanam counter", key: "seva_and_darshanam_counter"},
    {
      name: "Seva read and performance scan",
      key: "seva_read_and_performance_scan",
    },
    {name: "Donation Counter", key: "donation_counter"},
    {name: "Donation Read", key: "donation_read"},
    {name: "Donation Write", key: "donation_write"},
    {name: "Prasadam Counter", key: "prasadam_counter"},
    {name: "Kailasa Kankanam Counter", key: "kailasakankanam_counter"},

    {name: "Thulabharam Counter", key: "thulabharam_counter"},
    {name: "Publication Read", key: "publication_read"},
    {name: "Publication Counter", key: "publication_counter"},
    {name: "Customer Support", key: "customer_support"},
    {name: "Accommodation counter", key: "accommodation_counter"},
    {name: "Accommodation read", key: "accommodation_read"},

    {name: "Darshanam counter", key: "darshanam_counter"},
    {name: "Toll gate counter", key: "toll_gate_counter"},
    {name: "performance scan", key: "performance_scan"},
    {name: "Kalyanakatta Counter", key: "kalyanakatta_counter"},
  ];

  const handleOk = async (_role, _phone) => {
    setLoading(true);
    try {
      await UserService.manageUserRole(_role, _phone);
    } catch (error) {
      console.log(error.response);
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setLoading(false);
    setShowManageUserModal(false);
    setPhone("");
    setRole("");
    setUpdateTable(Math.random());
  };

  const handleCancel = () => {
    setShowManageUserModal(false);
    setShowCreateUserModal(false);
  };

  const handleCreatUser = async () => {
    setLoading(true);
    try {
      await UserService.adminCreateUser(
        cUserPhone,
        cUserDisplayName,
        cUserRole,
        cUserPass
      );
      setLoading(false);
      setShowCreateUserModal(false);
      setCUserDisplayName("");
      setCUserPass("");
      setCUserPhone("");
      setCUserRole("");
      setUpdateTable(Math.random());
    } catch (error) {
      setLoading(false);
      console.log(error.response);
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
  };

  return (
    <div style={{width: "100%"}}>
      <Row style={{width: "100%", marginBottom: "15px"}}>
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              setShowManageUserModal(true);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Manage User
          </Button>
          <Button
            type="primary"
            onClick={() => {
              history.push(routeMapMini.ManagePermissions);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Manage Permissions
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setShowCreateUserModal(true);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Create User
          </Button>
          <Button
            type="primary"
            onClick={() => {
              history?.push(routeMapMini.userDetails);
            }}
            disabled={userDetails.role !== "admin"}
          >
            User Details
          </Button>
        </Space>
      </Row>
      <Row style={{width: "100%"}}>
        <UsersTable
          updateTable={updateTable}
          setPhone={setPhone}
          setRole={setRole}
          handleOk={handleOk}
          setShowManageUserModal={setShowManageUserModal}
        />
      </Row>
      <Modal
        visible={showManageUserModal}
        title="Manage User"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => {
              handleOk(role, phone);
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify="center">Phone</Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="phone"
            style={{width: "250px"}}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Row>
        <Row justify="center">Role</Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Select
            style={{width: "250px"}}
            onChange={(e) => {
              setRole(e);
            }}
            value={role}
          >
            {roles.map((item) => {
              return (
                <Select.Option value={item.key} key={item.key}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        </Row>
      </Modal>
      <Modal
        visible={showCreateUserModal}
        title="Create User"
        onOk={handleCreatUser}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => {
              handleCreatUser();
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify="center">Phone</Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="phone"
            style={{width: "250px"}}
            value={cUserPhone}
            onChange={(e) => setCUserPhone(e.target.value)}
          />
        </Row>
        <Row justify="center">Display Name</Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Display Name"
            style={{width: "250px"}}
            value={cUserDisplayName}
            onChange={(e) => setCUserDisplayName(e.target.value)}
          />
        </Row>
        <Row justify="center">Role</Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Select
            style={{width: "250px"}}
            onChange={(e) => {
              setCUserRole(e);
            }}
            value={cUserRole}
          >
            {roles.forEach((item) => {
              return (
                <Select.Option value={item.key} key={item.key}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        </Row>
        <Row justify="center">Password</Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="password"
            style={{width: "250px"}}
            value={cUserPass}
            onChange={(e) => setCUserPass(e.target.value)}
          />
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(ManageUsers);
