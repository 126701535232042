import Axios from '../utils/axios';
import { EncryptPhone } from '../utils/Encryption.util';

const AuthService = {
  login: async (phone, password) => {
    phone = '+91' + phone;
    const resp = await Axios.post(`/user/login`, {
      PhoneOrEmail: phone,
      password: EncryptPhone(password),
    });
    return resp.data;
  },

  checkToken: async () => {
    const resp = await Axios.get(`/user/check-token`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return resp.data;
  },

  logout: async () => {
    const resp = await Axios.post(
      '/user/logout',
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('refreshToken')}`,
        },
      }
    );
    return resp.data;
  },
};

export default AuthService;
