import { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router";
import {
  Row,
  Col,
  Form,
  Select,
  Divider,
  notification,
  Button,
  Card,
} from "antd";
import { RightOutlined } from "@ant-design/icons";
import { routeMapMini } from "../../../constants/routeMap";
import "./OfflineAccommodation.css";
import RoomsService from "../../../Services/rooms.service";
import { AccContext } from "../../../context/accommodation.context";
import { FormatNumberINRCurrency } from "../../../utils/currencyFormat";

const OfflineAccommodation = ({ history, location }) => {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const [blockList, setBlockList] = useState([]);

  const {
    country,
    docNumber,
    firstName,
    gender,
    phone,
    email,
    address,
    city,
    state,
    zip,
    docType,
    roomtype,
    setRoomtype,
    blockId,
    persons,
    setPersons,
    setBlockCategoryId,
    setPrice,
    price,
    setTotalAmount,
    setDeposite,
    clearAccContext,
    setBlockName,
  } = useContext(AccContext);

  useEffect(() => {
    setBlockList();
  }, []);

  useEffect(() => {
    const clear = new URLSearchParams(location.search).get("clear");
    if (clear === "true") {
      clearAccContext();
      form.resetFields();
      history?.push(`${routeMapMini.OfflineAccommodation}?clear=false`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, location.search]);

  const onReset = () => {
    clearAccContext();
    form.resetFields();
    setBlockList();
  };

  const [form] = Form.useForm();

  const loadBlockCategoryList = async () => {
    setLoading(true);
    if (!roomtype || roomtype === "") {
      notification.error({ message: "Please select romm type" });

      return;
    }
    if (!persons || persons === "") {
      notification.error({ message: "Please select no of persons" });

      return;
    }
    try {
      const respData = await RoomsService.getBlockCategoryList(
        roomtype,
        persons
      );
      setBlockList(
        respData.map((item) => {
          return { ...item, key: item.id };
        })
      );
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({ message: error.response?.data?.message });
      } else {
        notification.error({ message: error.message });
      }
    }
    setLoading(false);
  };

  const bookBlock = (price, depositeAmount, id, blockName) => {
    setPrice(price);
    setDeposite(depositeAmount);
    setBlockCategoryId(id);
    setTotalAmount(Number(price) + Number(depositeAmount));
    setBlockName(blockName);
    history.push(routeMapMini.userForm);
  };

  return (
    <>
      <Form
        colon={false}
        form={form}
        onFinish={loadBlockCategoryList}
        style={{
          justifyContent: "center",
          margin: "auto",
          width: "55%",
          textAlign: "left",
        }}
        scrollToFirstError
        initialValues={{
          roomType: roomtype,
          persons: persons,
          blockCategory: blockId,
          price: price,
          firstName: firstName,
          gender: gender,
          mobileNumber: phone,
          emailAddress: email,
          address1: address,
          country: country,
          state: state,
          city: city,
          zipcode: zip,
          photoIDProof: docType,
          photoIDNumber: docNumber,
        }}
      >
        <Row>
          <h1
            style={{
              textAlign: "left",
              fontWeight: "bold",
              marginBottom: "0px",
            }}
          >
            Accommodation Booking
          </h1>
          <Divider />
        </Row>
        <Row gutter={[10, 10]}>
          <Col style={{ width: "50%" }}>
            <Form.Item
              name="roomType"
              label="Room Type"
              rules={[{ required: true, message: "Please Select Room Type" }]}
            >
              <Select
                placeholder="Please Select Room Type"
                size="large"
                value={roomtype}
                allowClear
                onChange={(e) => setRoomtype(e)}
              >
                <Select.Option key="AC" value="AC">
                  AC
                </Select.Option>
                <Select.Option key="NONAC" value="NONAC">
                  NON-AC
                </Select.Option>
                <Select.Option key="SUITE" value="SUITE">
                  Suite
                </Select.Option>
                <Select.Option key="COTTAGE" value="COTTAGE">
                  Cottage
                </Select.Option>
                <Select.Option key="DORMITORY" value="DORMITORY">
                  Dormitory
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col style={{ width: "50%" }}>
            <Form.Item
              name="persons"
              label="No. of Persons"
              rules={[
                { required: true, message: "Please Select No. of Persons!" },
              ]}
            >
              <Select
                placeholder="Please Select No. of Persons"
                size="large"
                allowClear
                value={persons}
                onChange={setPersons}
              >
                <Select.Option key="1" value="1">
                  1
                </Select.Option>
                <Select.Option key="2" value="2">
                  2
                </Select.Option>
                <Select.Option key="3" value="3">
                  3
                </Select.Option>
                <Select.Option key="4" value="4">
                  4
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]} style={{ marginTop: "20px" }}>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {blockList && (
        <>
          <Row>
            <h1
              style={{
                textAlign: "left",
                fontWeight: "bold",
                marginBottom: "0px",
              }}
            >
              Search Results
            </h1>
            <Divider />
          </Row>
          <Row gutter={[20, 20]}>
            {blockList?.map((item, index) => {
              return (
                <Col xs={24} sm={12} md={12} lg={8} xl={6} xsl={6}>
                  <Card
                    className="offline-card"
                    style={{
                      borderRadius: "10px",
                      border: "1px solid black",
                    }}
                  >
                    <Row
                      style={{
                        justifyContent: "center",
                        margin: "auto",
                        width: "100%",
                      }}
                    >
                      <div
                        alt="block pic"
                        className="sd-block-image"
                        style={{
                          backgroundImage: `url(${item.image_url})`,
                          // backgroundPosition: "center",
                          backgroundRepeat: "none",
                          maxHeight: "150px",
                          minHeight: "150px",
                        }}
                      >
                        <img
                          src={item.image_url}
                          alt=""
                          style={{
                            visibility: "hidden",
                            maxHeight: "150px",
                            minHeight: "150px",
                          }}
                        />
                      </div>
                    </Row>
                    <Row
                      style={{
                        justifyContent: "center",
                        margin: "auto",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        paddingBottom: "10px",
                        fontSize: "17px",
                        color: "#2f3193",
                      }}
                    >
                      {item?.sd_block_details?.block_name} / {item?.room_type}
                    </Row>
                    <div
                      className="offine-inner-card"
                      style={{
                        backgroundColor: "#e7e8fe",
                        borderRadius: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Row
                        style={{
                          justifyContent: "center",
                          margin: "auto",
                          fontSize: "16px",
                        }}
                      >
                        <p style={{ margin: "5px", fontWeight: "bold" }}>
                          {item?.current_available_rooms}
                        </p>
                        <p style={{ margin: "5px 0px" }}>- Available Rooms</p>
                      </Row>
                      {Number(item?.next_day_online_booking) !== 0 && (
                        <Row
                          style={{
                            justifyContent: "center",
                            margin: "auto",
                            fontSize: "16px",
                          }}
                        >
                          <p style={{ margin: "5px", fontWeight: "bold" }}>
                            {item?.next_day_online_booking}
                          </p>
                          <p style={{ margin: "5px 0px" }}>
                            - Next Day Online Bookings
                          </p>
                        </Row>
                      )}
                      <Row
                        style={{
                          justifyContent: "center",
                          margin: "auto",
                          fontSize: "16px",
                        }}
                      >
                        <p style={{ margin: "5px", fontWeight: "bold" }}>
                          {item?.online_booking_done}
                        </p>
                        <p style={{ margin: "5px 0px" }}>
                          - Online Rooms Alloted
                        </p>
                      </Row>
                      <Row
                        style={{
                          justifyContent: "center",
                          margin: "auto",
                          fontSize: "16px",
                        }}
                      >
                        <p style={{ margin: "5px", fontWeight: "bold" }}>
                          {item?.online_booking_today}
                        </p>
                        <p style={{ margin: "5px 0px" }}>
                          - Online Bookings Today
                        </p>
                      </Row>
                    </div>
                    <Row style={{ justifyContent: "center", margin: "auto" }}>
                      <Col
                        style={{
                          width: "50%",
                          justifyContent: "center",
                          margin: "auto",
                          textAlign: "left",
                        }}
                      >
                        <Row>
                          <p style={{ margin: "0px", fontWeight: "bold" }}>
                            {FormatNumberINRCurrency(item?.price)}
                          </p>
                          <p style={{ margin: "0px" }}>/ Day</p>
                        </Row>
                      </Col>
                      <Col
                        style={{
                          width: "50%",
                          justifyContent: "center",
                          margin: "auto",
                          textAlign: "right",
                        }}
                      >
                        <Button
                          type="primary"
                          onClick={() =>
                            bookBlock(
                              item?.price,
                              item?.deposit_amount,
                              item?.id,
                              item?.sd_block_details?.block_name
                            )
                          }
                        >
                          Book Now <RightOutlined />
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            })}
            {blockList?.length === 0 && "No results Found"}
          </Row>
        </>
      )}
    </>
  );
};

export default withRouter(OfflineAccommodation);
