/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Col,
  DatePicker,
  Input,
  notification,
  Row,
  Select,
  Table,
  Drawer,
} from "antd";
import {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import CounterService from "../../../Services/Counter.service";
import {routeMapMini} from "../../../constants/routeMap";
import fileDownload from "js-file-download";
import ReportService from "../../../Services/Report.service";
import {FormatNumberINRCurrency} from "../../../utils/currencyFormat";
import "./crcss.scss";
const {Option} = Select;
const ConsolidatedReport = ({history}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [FromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [countersList, setCounterList] = useState([]);
  const [selectedCounter, setSelectedCounter] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [selectedShift, setSelectedShift] = useState([]);

  useEffect(() => {
    getCounters();
    getShifts();
  }, []);

  useEffect(() => {
    fetchReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FromDate, toDate, selectedCounter, selectedShift]);

  const fetchReport = async () => {
    setDataLoading(true);
    // console.log(FromDate);
    if (!FromDate || !toDate) {
      notification.error({message: "Please select a valid date Range"});
      setDataLoading(false);
      return;
    }

    try {
      let CounterIds = selectedCounter.map((item) => item.id)?.join(",");
      let ShiftIds = selectedShift.map((item) => item.id)?.join(",");

      // console.log(id);
      const respData = await ReportService.getConsolidatedReport(
        FromDate,
        toDate,
        CounterIds,
        ShiftIds
      );
      setData(respData);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }

    setDataLoading(false);
  };

  const getCounters = async () => {
    try {
      // setLoading(true);
      const counters = await CounterService.getAllCounter();
      setCounterList(counters);
      // setLoading(false);
    } catch (error) {
      if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
      // setLoading(false);
    }
  };

  const getShifts = async () => {
    try {
      const res = await CounterService.getAllShifts();
      setShiftList(res);
    } catch (error) {
      if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
  };

  const downloadFile = async (type, format = "Bookings") => {
    if (!FromDate) {
      return notification.error({
        message: "From Date is not selected",
      });
    }
    if (!toDate) {
      return notification.error({
        message: "To Date is not selected",
      });
    }

    notification.info({message: "Downloading file! Please wait."});
    let CounterIds = selectedCounter.map((item) => item.id)?.join(",");
    let ShiftIds = selectedShift.map((item) => item.id)?.join(",");

    // console.log(id);

    ReportService.getConsolidatedReportExcel(
      FromDate,
      toDate,
      CounterIds,
      ShiftIds
    )
      .then((data) => {
        fileDownload(
          data,
          `${FromDate}-sevaCounterReport-${moment().format(
            "DD-MM-YYYY HH:MM"
          )}.xlsx`
        );
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          notification.error({
            message: "Token Expired, redirecting to login page in 5 seconds",
          });
          history?.push(routeMapMini.Login);
        } else if (error?.response?.data?.message) {
          notification.error({message: error?.response?.data?.message});
        } else {
          console.error(error);
          notification.error({message: "something is wrong"});
        }
      });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleUserDetails = () => {
    // setUserDetails(undefined);
    setShowUserDetails(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{padding: 8}}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: "block"}}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{width: 90, marginRight: 8}}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{width: 90}}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Counter Name",
      dataIndex: "counterName",
      key: "counterName",
      ...getColumnSearchProps("counterName"),
    },
    {
      title: "GST Amount",
      dataIndex: "gstAmount",
      key: "gstAmount",
      render: FormatNumberINRCurrency,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Shift Name",
      dataIndex: "shiftName",
      key: "shiftName",
      ...getColumnSearchProps("shiftName"),
    },
    {
      title: "Total Sevice Amount",
      dataIndex: "totalServiceAmount",
      key: "totalServiceAmount",
      render: FormatNumberINRCurrency,
    },
  ];

  return (
    <>
      <Row style={{marginBottom: "20px"}} justify={"start"} gutter={[20, 20]}>
        <Col>
          <Row justify="start">Date Range</Row>
          <Row justify="start">
            <DatePicker.RangePicker
              onChange={(e) => {
                if (e?.[0] && e?.[1]) {
                  setFromDate(moment(e[0]).format(`YYYY-MM-DD`));
                  setToDate(moment(e[1]).format(`YYYY-MM-DD`));
                } else {
                  setFromDate(undefined);
                  setToDate(undefined);
                }
              }}
              format="DD-MM-YYYY"
              ranges={{
                Today: [moment(), moment()],
                "Last 7 Days": [moment().subtract(7, "d"), moment()],
                "Last 15 Days": [moment().subtract(15, "d"), moment()],
              }}
              defaultValue={[moment(FromDate), moment(toDate)]}
              defaultPickerValue={[moment(FromDate), moment(toDate)]}
            />
          </Row>
        </Col>

        <Col>
          <Row justify="start">Counter</Row>
          <Row justify="start">
            <Select
              mode="multiple"
              maxTagCount="responsive"
              allowClear
              // value={selectedCounter.map((e) => e?.name)}
              className="sd-select"
              dropdownClassName="sd-select-dropdown-1"
              placeholder="Please enter counter"
              onChange={(e) => {
                // console.log(e);
                if (e.find((E) => E === "end")) {
                  setSelectedCounter([]);
                } else {
                  // console.log(e);
                  let parsed = e.map((item) => JSON.parse(item));
                  console.log(parsed);
                  setSelectedCounter(parsed);
                }
              }}
            >
              {countersList.map((item, i) => {
                return (
                  <Option
                    key={item.name + "counter-list" + i}
                    value={JSON.stringify(item)}
                  >
                    {item.name}
                  </Option>
                );
              })}
              <Option value="end" key="end">
                All
              </Option>
            </Select>
          </Row>
        </Col>

        <Col>
          <Row justify="start">Shifts</Row>
          <Row justify="start">
            <Select
              mode="multiple"
              maxTagCount="responsive"
              allowClear
              // value={selectedCounter.map((e) => e?.name)}
              className="sd-select"
              dropdownClassName="sd-select-dropdown-2"
              placeholder="Please enter shift(s)"
              onChange={(e) => {
                // console.log(e);
                if (e.find((E) => E === "end")) {
                  setSelectedShift([]);
                } else {
                  // console.log(e);
                  let parsed = e.map((item) => JSON.parse(item));
                  console.log(parsed);
                  setSelectedShift(parsed);
                }
              }}
            >
              {shiftList.map((item, i) => {
                return (
                  <Option
                    key={item.name + "shift-list" + i}
                    value={JSON.stringify(item)}
                  >
                    {item.name}
                  </Option>
                );
              })}
              <Option value="end" key="end">
                All
              </Option>
            </Select>
          </Row>
        </Col>

        <Col>
          <Row justify="start">Update</Row>
          <Row justify="start">
            <Button
              loading={dataLoading}
              type="primary"
              style={{minWidth: "100px"}}
              onClick={fetchReport}
            >
              Fetch
            </Button>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Download</Row>
          <Row justify="start">
            <Button
              type="primary"
              style={{minWidth: "100px"}}
              onClick={() => downloadFile("excel")}
            >
              Excel
            </Button>
          </Row>
        </Col>
      </Row>

      {/* <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Total Bookings</span>
        </Space>
      </Row> */}

      <Table
        style={{width: "100%", textTransform: "capitalize"}}
        columns={columns}
        dataSource={data}
        loading={dataLoading}
        scroll={{x: true}}
        size="small"
      ></Table>
      <Drawer
        title={`User`}
        placement="right"
        closable={false}
        onClose={handleUserDetails}
        visible={showUserDetails}
        width={500}
        destroyOnClose={true}
      >
        {/* <SevaCounterTable userid={userDetails} /> */}
      </Drawer>
    </>
  );
};

export default withRouter(ConsolidatedReport);
