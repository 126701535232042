import "../CounterDonation/styles.css";
import "../../Ticket/PrintTicket.scss";

import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  Menu,
  Modal,
  Row,
  Switch,
  notification,
} from "antd";
import { LoadingOutlined, RightOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import { FormatNumberINRCurrency } from "../../../utils/currencyFormat";
import PrasadamService from "../../../Services/Prasadam.service";
// import { useReactToPrint } from "react-to-print";
import PrintTicket from "../../Ticket/PrintTicket";
import StringUtils from "../../../utils/string.utils";
import moment from "moment";
import { routeMapMini } from "../../../constants/routeMap";
import { withRouter } from "react-router";

const { Meta } = Card;

const Sd_Counter_Prasadam = ({ history }) => {
  // let refContainer = useRef(null);
  const [prasadamList, setPrasadamList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bookingData, setBookingData] = useState([]);
  const [prasadamCount, setPrasadamCount] = useState([]);
  const [amountpaid, setAmountPaid] = useState(0);
  const labelStyles = { float: "left", fontSize: "17px", marginBottom: "5px" };
  const [buttonLoading, setButtonLoading] = useState(false);
  const [externalPrint, setExternalPrint] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [selectedPrasadamIds, setSelectedPrasadamIds] = useState([]);

  useEffect(() => {
    const localStorageSelectedPrasadamIds = localStorage.getItem(
      "selectedCounterPrasadamIds"
    );

    if (localStorageSelectedPrasadamIds) {
      const ids = localStorageSelectedPrasadamIds.split(",").map(Number);
      setSelectedPrasadamIds(ids);
    } else {
      setSelectedPrasadamIds([]);
    }
  }, []);

  const getPrasadam = async () => {
    setLoading(true);
    try {
      let res1 = await PrasadamService.allByType("prasadam");
      setPrasadamList(res1);
      setPrasadamCount(
        res1.map((item) => {
          return {
            id: item?.sd_prasadam?.id,
            price: item?.sd_prasadam?.price,
            count: 0,
          };
        })
      );
      // let res2 = await PrasadamService.allByType("cover");
      // setCoversList(res2);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        // console.log(error);

        notification.error({ message: error.response?.data?.message });
      } else {
        // console.log(error);
        notification.error({ message: error.message });
      }
    }
  };

  useEffect(() => {
    getPrasadam();
  }, []);

  const handleSubmit = async () => {
    setButtonLoading(true);
    setLoading(true);
    let a = false;
    prasadamCount?.forEach((e) => {
      if (e.count !== 0 && e.count > 0) {
        a = true;
      }
    });
    if (!a) {
      notification.error({ message: "Please select atleast one prasadam" });
      setLoading(false);
      setButtonLoading(false);
      return;
    }
    try {
      let body = {
        prasadamItems: [],
        cashType: "CASH",
      };
      prasadamCount.forEach((item) => {
        if (item.count > 0 && item?.count !== 0) {
          body.prasadamItems.push({
            prasadamId: item?.id,
            quantity: item?.count,
          });
        }
      });
      let res = await PrasadamService.bookPrasadam(body);
      notification.success({ message: "Success" });
      setBookingData(res);
      clearFields();
      setLoading(false);
      setIsModalVisible(true);
      setExternalPrint(true);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({ message: error.response?.data?.message });
      } else {
        notification.error({ message: error.message });
      }
      setLoading(false);
    }
    setButtonLoading(false);
  };

  const getAmount = (type) => {
    let amount = 0;
    prasadamCount.forEach((e) => {
      if (e?.count === 0) {
        return;
      } else {
        amount += e?.count * e?.price;
      }
    });
    return amount;
  };

  const clearFields = () => {
    setStartDate("");
    setPrasadamList([]);
  };

  const toggleSwitch = (id) => {
    let newIds = [];
    if (selectedPrasadamIds?.includes(id)) {
      newIds = selectedPrasadamIds.filter((e) => e !== id);
    } else {
      newIds = [...selectedPrasadamIds, id];
    }
    setSelectedPrasadamIds(newIds);
    localStorage.setItem("selectedCounterPrasadamIds", newIds);
  };

  const menu = (
    <Menu onClick={() => setMenuVisible(true)}>
      {prasadamList.map((item, i) => {
        return (
          <Menu.Item
            key={item?.sd_prasadam?.id}
            onClick={() => {
              toggleSwitch(item?.sd_prasadam?.id);
            }}
          >
            {item?.sd_prasadam.name}
            <Switch
              size="small"
              style={{ float: "right" }}
              checked={selectedPrasadamIds?.includes(item?.sd_prasadam?.id)}
            />
          </Menu.Item>
        );
      })}
    </Menu>
  );
  // const handlePrint = useReactToPrint({
  //   content: () => refContainer.current,
  //   copyStyles: true,
  //   bodyClass: "sd-ticket-container",
  //   onAfterPrint: () => {
  //     setBookingData(undefined);
  //     window.location.reload();
  //   },
  // });
  // const handleFunction = () => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 200);
  //   setTimeout(() => {
  //     handlePrint();
  //   }, 1000);
  // };
  const handleVisibleChange = () => {
    if (menuVisible) {
      setMenuVisible(false);
    } else {
      setMenuVisible(true);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div
          style={{
            maxWidth: "1200px",
            width: "100%",
            background: "white",
            padding: "30px 50px",
          }}
        >
          <Row
            justify="center"
            style={{ fontSize: "22px", marginBottom: "10px" }}
          >
            Prasadam
          </Row>
          <Divider style={{ marginTop: "0px", marginBottom: "10px" }} />
          <Row justify="space-between">
            <Col style={{ width: "48%", justifyContent: "start" }}>
              <label style={labelStyles}>
                Booking Date{" "}
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
              </label>
              <br />{" "}
              <DatePicker
                disabled={true}
                value={startDate}
                style={{ width: "100%" }}
                format="DD-MM-YYYY"
                disabledDate={(d) =>
                  !d || d.isBefore(StringUtils.addDays(new Date(), -1))
                }
                onChange={(date) => {
                  setStartDate(date);
                }}
                size="small"
              />
            </Col>
            <Col style={{ width: "48%", justifyContent: "start" }}>
              <label style={labelStyles}>Prasadam Menu </label>
              <br />{" "}
              <Dropdown
                overlay={menu}
                placement="bottomLeft"
                onVisibleChange={() => handleVisibleChange()}
                visible={menuVisible}
              >
                <Button style={{ width: "100%" }} size="small">
                  Prasadam Menu
                </Button>
              </Dropdown>
            </Col>
          </Row>
          <br />
          <Row
            justify="space-between"
            className="sd-display-cards"
            style={{ overflow: "hidden" }}
          >
            {prasadamList
              .filter((item) =>
                selectedPrasadamIds?.includes(item?.sd_prasadam?.id)
              )
              .map((item, i) => {
                return (
                  <Card
                    hoverable
                    className="sd-display-card"
                    style={{
                      width: "100%",
                      height: "50px",
                      display: "flex",
                    }}
                    actions={[
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          textAlign: "left",
                        }}
                      >
                        {/* <MinusOutlined
                        key="minus"
                        onClick={() => {
                          let data = [...prasadamCount];
                          data.forEach((e) => {
                            if (e?.id === item?.sd_prasadam?.id) {
                              if (e?.count === 0) {
                                return;
                              }
                              e.count--;
                            }
                          });
                          setPrasadamCount(data);
                        }}
                      /> */}
                        <Input
                          style={{ width: "50px", textAlign: "center" }}
                          type="number"
                          min="0"
                          max="99"
                          maxLength="2"
                          key="edit"
                          placeholder="0"
                          autoFocus={i === 0 ? true : false}
                          value={
                            prasadamCount?.find(
                              (e) => e.id === item?.sd_prasadam?.id
                            )?.count || undefined
                          }
                          onInput={(object) => {
                            if (
                              object.target.value.length >
                              object.target.maxLength
                            ) {
                              object.target.value = object.target.value.slice(
                                0,
                                object.target.maxLength
                              );
                            }
                          }}
                          onChange={(event) => {
                            let data = [...prasadamCount];
                            data.forEach((e) => {
                              if (e?.id === item?.sd_prasadam?.id) {
                                e.count = event.target.value;
                              }
                            });
                            setPrasadamCount(data);
                          }}
                        />
                        {/* <PlusOutlined
                        key="plus"
                        onClick={() => {
                          let data = [...prasadamCount];
                          data.forEach((e) => {
                            if (e?.id === item?.sd_prasadam?.id) {
                              e.count++;
                            }
                          });
                          setPrasadamCount(data);
                        }}
                      /> */}
                      </div>,
                      <div>
                        Final Amout:{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          {FormatNumberINRCurrency(
                            prasadamCount?.find(
                              (e) => e.id === item?.sd_prasadam?.id
                            )?.price *
                              prasadamCount?.find(
                                (e) => e.id === item?.sd_prasadam?.id
                              )?.count
                          )}
                        </span>
                      </div>,
                    ]}
                  >
                    <Meta
                      // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                      title={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-beetween",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              textTransform: "capitalize",
                              width: "200px",
                              fontWeight: "400",
                              textAlign: "left",
                            }}
                          >
                            {item?.sd_prasadam.name}
                          </span>
                          <Divider type="vertical" />
                          <span
                            style={{
                              marginLeft: "30px",
                              fontWeight: "400",
                            }}
                          >
                            Price:
                            <span
                              style={{
                                color: "red",
                                fontWeight: "600",
                                marginLeft: "5px",
                              }}
                            >
                              {FormatNumberINRCurrency(
                                item?.sd_prasadam?.price
                              )}
                            </span>
                          </span>
                        </div>
                      }
                    />
                  </Card>
                );
              })}
          </Row>
          <Row style={{ height: "50px", marginTop: "10px" }}>
            <Col
              style={{
                width: "25%",
                fontWeight: "bold",
                fontSize: "23px",
                textAlign: "left",
              }}
            >
              Total Amount :
            </Col>
            <Col
              style={{
                width: "75%",
                textAlign: "right",
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              {FormatNumberINRCurrency(getAmount())}
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col
              style={{
                width: "25%",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              Amount Paid:
            </Col>
            <Col
              style={{
                width: "75%",
                textAlign: "right",
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              <Input
                style={{ width: "200px", textAlign: "right" }}
                type="number"
                onChange={(e) => {
                  setAmountPaid(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col
              style={{
                width: "25%",
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              Amount to be Returned:
            </Col>
            <Col
              style={{
                width: "75%",
                textAlign: "right",
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              {FormatNumberINRCurrency(
                amountpaid - getAmount() > 0 ? amountpaid - getAmount() : 0
              )}
            </Col>
          </Row>
          <br />
          <Row>
            <Col
              style={{
                textAlign: "right",
              }}
            >
              <Button
                type="primary"
                style={{ background: "#f68121", border: "none" }}
                onClick={() => {
                  handleSubmit();
                }}
                loading={buttonLoading}
              >
                Submit <RightOutlined />
              </Button>
            </Col>
          </Row>
        </div>
      )}
      <Row style={{ display: "none" }}>
        <PrintTicket
          type="prasadam"
          from="Counter"
          bookingDate={moment(bookingData?.booking?.booking?.created_at).format(
            "DD-MM-YYYY"
          )}
          ticketId={bookingData?.ticket}
          prasadamItems={bookingData?.booking?.prasadamItems}
          setPrintTicketModal={setIsModalVisible}
          setData={setBookingData}
          categoryTransactionId={
            bookingData?.counterTransactions
              ? bookingData?.counterTransactions[0]?.category_transaction_id
              : ""
          }
          totalAmount={bookingData?.booking?.booking?.total_price ?? 0}
          serialNumber={
            bookingData?.counterTransactions
              ? bookingData?.counterTransactions[0]
                  ?.daily_category_transaction_id
              : ""
          }
          externalPrint={externalPrint}
          setExternalPrint={setExternalPrint}
        />
      </Row>
      {/* <Modal
        visible={isModalVisible}
        width={400}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <PrintTicket
          type="prasadam"
          from="Counter"
          bookingDate={moment(bookingData?.booking?.booking?.created_at).format(
            "DD-MM-YYYY"
          )}
          ticketId={bookingData?.ticket}
          prasadamItems={bookingData?.booking?.prasadamItems}
          setPrintTicketModal={setIsModalVisible}
          setData={setBookingData}
          categoryTransactionId={
            bookingData?.counterTransactions
              ? bookingData?.counterTransactions[0]?.category_transaction_id
              : ""
          }
          totalAmount={bookingData?.booking?.booking?.total_price}
          serialNumber={
            bookingData?.counterTransactions
              ? bookingData?.counterTransactions[0]
                  ?.daily_category_transaction_id
              : ""
          }
        />
      </Modal> */}
    </div>
  );
};
export default withRouter(Sd_Counter_Prasadam);
