import React from 'react';
import { Tabs } from 'antd';
import SevaAudit from './SevaAudit';
import { withRouter } from 'react-router';

const Audit = () => {
  return (
    <Tabs defaultActiveKey='1'>
      <Tabs.TabPane tab='Sevas' key='1'>
        <SevaAudit />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default withRouter(Audit);
