/* eslint-disable jsx-a11y/anchor-is-valid */
import {Button, Input, notification, Popconfirm, Space, Table} from "antd";
import {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {FormatNumberINRCurrency} from "../../utils/currencyFormat";
import PrasadamService from "../../Services/Prasadam.service";
import {routeMapMini} from "../../constants/routeMap";

const PrasadamTable = ({updateTable, setPrasadamId, history}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadSevas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTable]);

  const loadSevas = async () => {
    try {
      setLoading(true);
      const prasadams = await PrasadamService.all();
      setData(
        prasadams?.map((item) => {
          return {
            key: item.id,
            name: item?.name,
            price: item?.price,
            type: item?.type,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
      setLoading(false);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{padding: 8}}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: "block"}}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{width: 90, marginRight: 8}}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{width: 90}}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {title: "Id", dataIndex: "key", key: "key"},
    {
      title: "Prasadam Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      ...getColumnSearchProps("name"),
      render: (text) => {
        return (
          text && (
            <div
              style={{
                width: "200px",
                whiteSpace: "initial",
                textTransform: "capitalize",
              }}
            >
              {text}
            </div>
          )
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: FormatNumberINRCurrency,
      sorter: (a, b) => a.price > b.price,
    },
    {
      title: "type",
      dataIndex: "type",
      key: "type",
    },

    {
      title: "Action",
      fixed: "right",
      responsive: ["md", "lg", "xl", "xsl"],
      render: (record) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                history?.push(
                  `${routeMapMini.PrasadamCounterMain}?id=${record.key}`
                );
              }}
            >
              Manage Counters
            </a>
            <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={async () => {
                setLoading(true);
                try {
                  await PrasadamService.delete(record.key);
                } catch (error) {
                  if (error.response?.data?.message) {
                    notification.error({
                      message: error.response?.data?.message,
                    });
                  } else {
                    notification.error({message: error.message});
                  }
                }
                loadSevas();
                setLoading(false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm>
            <a
              onClick={() => {
                setPrasadamId(record.key);
              }}
            >
              Modify
            </a>
          </Space>
        );
      },
    },
  ];
  return (
    <Table
      tableLayout="fixed"
      scroll={{x: true}}
      loading={loading}
      style={{width: "100%", textTransform: "capitalize"}}
      columns={columns}
      dataSource={data}
    ></Table>
  );
};

export default withRouter(PrasadamTable);
