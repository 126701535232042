import {Button, Checkbox, Col, Input, notification, Row, Space} from "antd";
import Modal from "antd/lib/modal/Modal";
import {useContext, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {routeMapMini} from "../../constants/routeMap";
import {UserContext} from "../../context/user.context";
import TollGateServices from "../../Services/TollGate.service";
import TollGateTable from "./TollGateTable";

const Toll_Gate = ({history}) => {
  const [showNewModal, setShowNewModal] = useState(false);
  const [loading, setLoading] = useState();
  const [tollGateName, setTollGateName] = useState("");
  const [price, setPrice] = useState();
  const [updateTable, setUpdateTable] = useState(false);
  const [tollGateId, setTollGateId] = useState();
  const {userDetails} = useContext(UserContext);
  const [duration, setDuration] = useState();
  const [status, setStatus] = useState(false);
  const [edit, setEdit] = useState(false);

  const loadTollGateById = async (id) => {
    setLoading(true);
    setEdit(true);
    setShowNewModal(true);
    try {
      const loadTollData = await TollGateServices.loadTollGateById(id);
      setTollGateId(loadTollData?.id);
      setTollGateName(loadTollData?.name);
      setPrice(loadTollData?.price);
      setDuration(loadTollData?.duration_in_hours);
      setStatus(loadTollData?.disabled);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
    setLoading(false);
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      if (edit) {
        await TollGateServices.editTollGate(
          tollGateId,
          tollGateName,
          price,
          duration,
          status
        );
      } else {
        await TollGateServices.newTollGate(
          tollGateName,
          price,
          duration,
          status
        );
      }
      setUpdateTable(true);
      setShowNewModal(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setTollGateName(undefined);
    setPrice(undefined);

    setDuration(undefined);
    setStatus(undefined);
  };

  const handleCancel = () => {
    clearFields();
    setShowNewModal(false);
  };

  return (
    <div style={{width: "100%"}}>
      <Row style={{width: "100%", marginBottom: "15px"}} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setShowNewModal(true);
              setEdit(false);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Add New Toll Gate
          </Button>
        </Col>
      </Row>
      <Row style={{width: "100%"}}>
        <TollGateTable
          updateTable={updateTable}
          loadTollGateById={loadTollGateById}
        />
      </Row>
      <Modal
        visible={showNewModal}
        title={edit ? "Edit Toll Gate" : "Add New Toll Gate"}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{alignContent: "center"}}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify="start" style={{width: "100%", padding: "10px 0px"}}>
          <label>Toll Gate Name</label>
          <Input
            placeholder="Toll Gate Name"
            value={tollGateName}
            onChange={(e) => {
              setTollGateName(e.target.value);
            }}
            style={{width: "450px"}}
          />
        </Row>

        <Row justify="start" style={{width: "100%", padding: "10px 0px"}}>
          <label>Duration</label>
          <Input
            placeholder="Duration"
            value={duration}
            onChange={(e) => {
              setDuration(e.target.value);
            }}
            style={{width: "450px"}}
          />
        </Row>

        <Row
          justify="start"
          style={{
            width: "100%",
            padding: "10px 0px",
            flexDirection: "column",
          }}
        >
          <label>Price </label>
          <Input
            placeholder="Price"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
            type="number"
            style={{width: "450px"}}
          />
        </Row>

        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Space size="middle">
            Disable Toll Gate
            <Checkbox
              checked={status}
              onChange={(e) => {
                setStatus(e.target.checked);
              }}
            />
          </Space>
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(Toll_Gate);
