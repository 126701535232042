/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Input, notification, Popconfirm, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import TollGateServices from "../../Services/TollGate.service";

const TollGateCounterTable = ({ updateTable, history, tollGateId }) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadTollGateCounters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTable]);

  const loadTollGateCounters = async () => {
    try {
      setLoading(true);
      const tollgateCounter = await TollGateServices.tollGateCounters(
        tollGateId
      );
      setData(
        tollgateCounter?.map((item) => {
          return {
            key: item?.id,
            counterId: item?.sd_counter_id,
            counterName: item?.sd_counter?.name,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      if (error.response?.data?.message) {
        notification.error({ message: error.response?.data?.message });
      } else {
        notification.error({ message: error.message });
      }
      setLoading(false);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const columns = [
    { title: "Id", dataIndex: "key", key: "key" },
    { title: "Counter ID", dataIndex: "counterId", key: "counterId" },
    {
      title: "Counter Name",
      dataIndex: "counterName",
      key: "counterName",
      ellipsis: true,
      ...getColumnSearchProps("name"),
      render: (text) => {
        return (
          text && (
            <div
              style={{
                whiteSpace: "initial",
                textTransform: "capitalize",
              }}
            >
              {text}
            </div>
          )
        );
      },
    },
    {
      title: "Action",
      fixed: "right",
      responsive: ["md", "lg", "xl", "xsl"],
      render: (record) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={async () => {
                setLoading(true);
                try {
                  await TollGateServices.tollGateCounterDelete(record?.key);
                } catch (error) {
                  if (error.response?.data?.message) {
                    notification.error({
                      message: error.response?.data?.message,
                    });
                  } else {
                    notification.error({ message: error.message });
                  }
                }
                loadTollGateCounters();
                setLoading(false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <Table
      tableLayout="fixed"
      scroll={{ x: true }}
      loading={loading}
      style={{ width: "100%", textTransform: "capitalize" }}
      columns={columns}
      dataSource={data}
    ></Table>
  );
};

export default withRouter(TollGateCounterTable);
