import {PlusSquareOutlined} from "@ant-design/icons";
import {Badge, Button, Col, List, Row} from "antd";
import React from "react";
import {withRouter} from "react-router";
const TempleCalender = ({selectedTimePeriod, setSelectedTimePeriod}) => {
  const data = [
    "Racing car sprays burning fuel into crowd.",
    "Japanese princess to wed commoner.",
    "Australian walks 100km after outback crash.",
    "Man charged over missing wedding girl.",
    "Los Angeles battles huge wildfires.",
  ];
  const Panchangamdata = [
    {name: "Tithi", value: "Racing car sprays burning ."},
    {name: "Nakshatram", value: "Japanese princess ."},
    {name: "Amrutha Kalam", value: "Australian walks 1."},
    {name: "Varjyam", value: "Man charged "},
    {name: "Dur muhurtham", value: "Los Angeles battles"},
    {name: "Rahu Kalam", value: "Racing car sprays burning ."},
    {name: "Yama Gandam", value: "Japanese princess ."},
    {name: "Suryodhayam", value: "Australian walks 1."},
    {name: "Suryasthamayam", value: "Man charged "},
  ];
  return (
    <div className="temple-calender">
      <div className="tc-top">
        <div>
          <Button
            className={selectedTimePeriod === "D" ? "active-button" : ""}
            onClick={(e) => {
              e.preventDefault();
              setSelectedTimePeriod("D");
            }}
          >
            Day
          </Button>
          <Button
            className={selectedTimePeriod === "week" ? "active-button" : ""}
            onClick={(e) => {
              e.preventDefault();
              setSelectedTimePeriod("W");
            }}
          >
            Week
          </Button>
          <Button
            className={selectedTimePeriod === "month" ? "active-button" : ""}
            onClick={(e) => {
              e.preventDefault();
              setSelectedTimePeriod("M");
            }}
          >
            Month
          </Button>
        </div>

        <Button>
          Panchanagam <PlusSquareOutlined />
        </Button>
        <Button>
          Events <PlusSquareOutlined />
        </Button>
      </div>
      <div className="tc-bottom">
        <div className="tc-left w-50">
          <List
            header={<h3>Panchangam</h3>}
            bordered
            dataSource={Panchangamdata}
            renderItem={(item) => (
              <List.Item>
                <Row style={{width: "100%", textAlign: "left"}}>
                  <Col span={10} style={{fontWeight: "600"}}>
                    {item.name}
                  </Col>
                  <Col span={14}>{item.value}</Col>
                </Row>
              </List.Item>
            )}
          />
        </div>
        <div className="tc-right w-50">
          <List
            header={<h3>Events</h3>}
            bordered
            dataSource={data}
            renderItem={(item) => (
              <List.Item style={{textAlign: "left"}}>
                {" "}
                <Badge color="#f50" />
                {item}
              </List.Item>
            )}
          />
        </div>
      </div>
    </div>
  );
};
export default withRouter(TempleCalender);
