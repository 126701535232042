import React, { useState, useEffect } from "react";
import { Row, Col, Button, notification, Modal, Space, Input } from "antd";
import RoomsService from "../../Services/rooms.service";
import { routeMapMini } from "../../constants/routeMap";
import { FormatNumberINRCurrency } from "../../utils/currencyFormat";
import moment from "moment";
import PrintTicket from "./PrintTicket";
import "./PrintTicket.scss";

const Renew = ({
  history,
  ticketId,
  roomNo,
  blockName,
  devoteeName,
  bookingDate,
  checkInTime,
  depositAmount,
  persons,
  phone,
  roomType,
  from,
  setRenewModal,
  getCheckoutTable,
  accDashboard,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ticketVisible, setTicketVisible] = useState(false);
  const [onlineTicketId, setOnlineTicketId] = useState();

  const submit = async (stats) => {
    setLoading(true);
    try {
      const data = await RoomsService.Renew(ticketId, stats);
      setData(data);
      if (!stats) {
        setIsModalVisible(true);
      }
    } catch (error) {
      setIsModalVisible(false);
      setTicketVisible(false);

      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
    setLoading(false);
  };

  const onlineRenewTicket = async () => {
    setLoading(true);
    try {
      const data = await RoomsService.onlineRenewTicket(
        ticketId,
        onlineTicketId
      );
      setData(data);
      setIsModalVisible(true);
    } catch (error) {
      setIsModalVisible(false);
      setTicketVisible(false);
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    submit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomNo]);

  const renewTicket = () => {
    if (onlineTicketId) {
      setTicketVisible(true);
      onlineRenewTicket();
    } else {
      setTicketVisible(true);
      submit(false);
    }
  };

  return (
    <>
      <Col style={{ justifyContent: "center", margin: "auto" }}>
        <Row>
          <Col style={{ width: "50%", textAlign: "left" }}>Room :</Col>
          <Col>
            {roomNo} | {blockName}
          </Col>
        </Row>
        <Row>
          <Col style={{ width: "50%", textAlign: "left" }}>
            Actual Check Out Time :
          </Col>
          <Col>
            {moment(data?.currentCheckOutTime).format(
              "ddd, MMM Do YYYY, h:mm:ss a"
            )}
          </Col>
        </Row>
        <Row>
          <Col style={{ width: "50%", textAlign: "left" }}>
            New Check Out Time :
          </Col>
          <Col>
            {moment(data?.afterRenewCheckOutTime).format(
              "ddd, MMM Do YYYY, h:mm:ss a"
            )}
          </Col>
        </Row>
        <Row>
          <Col style={{ width: "50%", textAlign: "left" }}>Unpaid Days :</Col>
          <Col>{data?.unPaidDays}</Col>
        </Row>
        <Row>
          <Col style={{ width: "50%", textAlign: "left" }}>Room Price :</Col>
          <Col>{FormatNumberINRCurrency(data?.roomPrice)}</Col>
        </Row>
        {data?.roomPrice > 1000 && (
          <Row>
            <Col style={{ width: "50%", textAlign: "left" }}>GST :</Col>
            <Col>{FormatNumberINRCurrency(data?.GST)}</Col>
          </Row>
        )}
        <Row style={{ marginTop: "10px" }}>
          <Col style={{ width: "50%", textAlign: "left" }}>Renew Amount :</Col>
          <Col>{FormatNumberINRCurrency(data?.renewalPrice)}</Col>
        </Row>
        <Row>
          <Col style={{ width: "50%", textAlign: "left" }}>
            Online Ticket ID :
          </Col>
          <Col>
            {" "}
            <Input
              placeholder="Online Ticket Id"
              onChange={(e) => {
                setOnlineTicketId(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Button
            type="primary"
            onClick={() => setIsModalVisible(true)}
            loading={loading}
          >
            Renew
          </Button>
        </Row>
      </Col>
      <Modal
        visible={isModalVisible}
        width={850}
        footer={null}
        closable={false}
        centered
        // onCancel={() => {
        //   setIsModalVisible(false);
        //   setTicketVisible(false);
        // }}
      >
        {!ticketVisible && (
          <>
            <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
              {onlineTicketId
                ? `Are you sure you want to renew room with Online Ticket ID ?`
                : `Are you sure you want to renew room ?`}
            </Row>
            <Row style={{ justifyContent: "center", margin: "auto" }}>
              <Space>
                <Button type="primary" onClick={renewTicket}>
                  Yes
                </Button>
                <Button onClick={() => setIsModalVisible(false)}>No</Button>
              </Space>
            </Row>
          </>
        )}
        {ticketVisible && (
          <PrintTicket
            ticketId={onlineTicketId ? onlineTicketId : ticketId}
            name={devoteeName}
            blockName={blockName}
            roomType={roomType}
            roomNo={roomNo}
            bookingDate={bookingDate}
            checkInTime={checkInTime}
            checkOutTime={data?.afterRenewCheckOutTime}
            roomPrice={data?.roomPrice}
            depositAmount={0}
            extraAmount={data?.GST ?? 0}
            totalAmount={onlineTicketId ? 0 : data?.renewalPrice}
            persons={persons}
            contact={phone}
            setRenewModal={setRenewModal}
            setPrintTicketModal={setIsModalVisible}
            type="accomodation"
            from={from}
            couponDisc={0}
            unPaidDays={Number(data?.unPaidDays + 1)}
            getCheckoutTable={getCheckoutTable}
            accDashboard={accDashboard}
            booking="Renew"
            setData={setData}
            setOnlineTicketId={setOnlineTicketId}
          />
        )}
      </Modal>
    </>
  );
};

export default Renew;
