import React, { useRef, useContext, useState } from "react";
import { Button, Card, Col, Divider, Row, Space, Modal } from "antd";
import { withRouter } from "react-router";
import { useReactToPrint } from "react-to-print";
import "./PrintTicket.scss";
import numberToWords from "../../utils/amount-to-words/number-to-words";
import { routeMapMini } from "../../constants/routeMap";
import Barcode from "react-barcode";

const BondTicket = ({
  history,
  name,
  sevaName,
  bookingDate,
  calendarType,
  performanceDate,
  masam,
  paksham,
  tithi,
  amountPaid,
  actualAmount,
  address,
  city,
  state,
  pincode,
  categoryTransactionId,
  setBondPrintTicket,
  inTheNameOf,
  gothram,
  serialNumber,
  ticketId,
  contact,
}) => {
  const [loading, setLoading] = useState(false);

  let refContainer = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => refContainer.current,
    copyStyles: true,
    bodyClass: "sd-bond-ticket-container",
    onAfterPrint: () => {
      setBondPrintTicket(false);
      history?.push(routeMapMini.Counter);
    },
  });

  const handleFunction = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 200);
    setTimeout(() => {
      handlePrint();
    }, 1000);
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Button
          type="primary"
          style={{ margin: "20px" }}
          onClick={() => {
            handleFunction();
          }}
        >
          Print
        </Button>
        {loading ? (
          "Loading"
        ) : (
          <>
            {" "}
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <div ref={refContainer} className="sd-bond-ticket-container">
                <Card
                  style={{
                    width: "100%",
                    // backgroundColor: "white",
                    color: "black",
                    textAlign: "left",
                  }}
                  className="sd-ticket-card"
                >
                  <Row>
                    <Col className="ticket_label">
                      <p>Donor Id :</p>
                    </Col>
                    <Col
                      className="ticket_data"
                      style={{ textAlign: "center" }}
                    >
                      <p>{categoryTransactionId}</p>
                    </Col>
                    <Row style={{ width: "100%" }}>
                      <Col className="ticket_label">Seva Name :</Col>
                      <Col
                        className="ticket_data"
                        style={{ fontWeight: "bold" }}
                      >
                        {sevaName}
                      </Col>
                    </Row>
                    <Col style={{ width: "50%", float: "left" }}>
                      <Row>
                        <Col className="ticket_label">Sl No :</Col>
                        <Col className="ticket_data">{serialNumber}</Col>
                      </Row>
                      <br />
                      <Row>
                        <Col className="ticket_label">Devotee Name :</Col>
                        <Col className="ticket_data">
                          {name} | {contact}
                        </Col>
                      </Row>
                      <Row style={{ paddingBottom: "100px" }}>
                        <Col className="ticket_label">Address :</Col>
                        <Col className="ticket_data">
                          {address},{city},{state},{pincode}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="ticket_label">Donation Date :</Col>
                        <Col className="ticket_data">
                          {calendarType === "english"
                            ? performanceDate
                            : `${masam} Masam - ${paksham} Paksham - ${tithi} Tithi`}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="ticket_label">in the Name of :</Col>
                        <Col className="ticket_data">{inTheNameOf}</Col>
                      </Row>
                    </Col>
                    <Col style={{ width: "50%", float: "right" }}>
                      <Row style={{ paddingTop: "100px" }}>
                        <Col className="ticket_label">Gothram :</Col>
                        <Col className="ticket_data">{gothram}</Col>
                      </Row>
                      {/* <Row>
                      <Col className="ticket_label">Booking Date :</Col>
                      <Col className="ticket_data">{bookingDate}</Col>
                    </Row> */}
                      {amountPaid && (
                        <Row>
                          <Col className="ticket_label">
                            <p>Total Amount :</p>
                          </Col>
                          <Col className="ticket_data">
                            <p>
                              Rs. {amountPaid}.00 <br />(
                              {numberToWords(amountPaid)} Rupees)
                              <br />
                            </p>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col className="ticket_label">
                          <p>Receipt No :</p>
                        </Col>
                        <Col className="ticket_data">
                          <p>{categoryTransactionId}</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      width: "100%",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Barcode
                      height="25"
                      width="1"
                      fontSize="15px"
                      value={ticketId ? ticketId : "transactionId=127"}
                      style={{
                        justifyContent: "center",
                        margin: "auto",
                      }}
                    />
                  </Row>
                </Card>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default withRouter(BondTicket);
