import Axios from "../utils/axios";

const RolesService = {
  getUsers: async () => {
    const resp = await Axios.get(`/user-permissions/get-users`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  getUsersRoles: async (userId) => {
    const resp = await Axios.get(`/user-permissions/by-admin/${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  getRoles: async () => {
    const resp = await Axios.get(`/user-permissions/get-available-roles`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  updateRoles: async (userRolesData, userIdorPhone, isPhone) => {
    const body = { permissions: userRolesData };
    if (isPhone) {
      body.phone = userIdorPhone;
    } else {
      body.userId = userIdorPhone;
    }
    const resp = await Axios.post(`/user-permissions/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
};

export default RolesService;
