/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useState} from "react";
import {
  Button,
  Col,
  DatePicker,
  Row,
  Select,
  Table,
  Modal,
  notification,
  Card,
  Statistic,
} from "antd";
import moment from "moment";
import ShiftService from "../../../Services/Shift.service";
import CounterService from "../../../Services/Counter.service";
import {routeMapMini} from "../../../constants/routeMap";
import {withRouter} from "react-router-dom";
import SummaryReport from "../../Layout/Header/SummaryReport";
import {UserContext} from "../../../context/user.context";
import "../Report.css";
// import {FormatNumberINRCurrency} from "../../../utils/currencyFormat";

const ShiftReportTable = ({history}) => {
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [userId, setUserId] = useState();
  const [counterId, setCounterId] = useState();
  const [counters, setCounters] = useState();
  const {userDetails} = useContext(UserContext);
  const [data, setData] = useState([]);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [total, setTotal] = useState(0);
  const [reportPopUp, setReportPopUp] = useState(false);
  const [shiftId, setShiftId] = useState(0);

  const getData = async () => {
    try {
      const respData = await ShiftService.allShiftReports(
        userId,
        counterId,
        fromDate,
        toDate,
        tableCurrentPage,
        tablePageSize
      );
      setData(respData.items);
      setTabelTotalSize(respData?.meta?.totalItems);
      setTotal(respData?.meta?.totalAmount);
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
  };

  const getCounters = async () => {
    try {
      const respData = await CounterService.getAllCounter();
      setCounters(respData);
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
  };

  useState(() => {
    userDetails?.role === "admin" && getCounters();
  }, []);

  useEffect(() => {
    getData();
    userDetails?.role === "admin" && getCounters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, tableCurrentPage, tablePageSize, reportPopUp]);

  const viewReport = (id) => {
    setShiftId(id);
    setReportPopUp(true);
  };

  const columns = [
    {title: "ID", dataIndex: "id"},
    {
      title: "Username",
      dataIndex: "user_display_name",
      key: "user_display_name",
      render: (text, record) => {
        return (
          <div>
            <a
              onClick={() => {
                // history?.push(
                //   `${routeMapMini.userDetails}?userId=${record.user_id}`
                // );
                // setUserDetails(record?.user_id);
                // setShowUserDetails(true);
              }}
              style={{textTransform: "capitalize"}}
            >
              {record.sd_user.display_name}
            </a>
          </div>
        );
      },
    },
    {
      title: "Counter",
      dataIndex: "sd_counter",
      key: "id",
      render: (text, record) => record.sd_counter.name,
    },
    {
      title: "Shift",
      dataIndex: "shift",
      key: "shift",
      render: (text, record) => record?.sd_shift?.name,
    },
    {
      title: "Transaction Count",
      dataIndex: "transactions_count",
      key: "id",
    },

    {
      title: "Login",
      dataIndex: "login",
      key: "id",
      render: (text, record) => {
        return record.login
          ? moment(record.login).format("DD-MM-YYYY hh:mm A")
          : "Not yet";
      },
    },
    {
      title: "Logout",
      dataIndex: "logout",
      key: "id",
      render: (text, record) => {
        return record.logout
          ? moment(record.logout).format("DD-MM-YYYY hh:mm A")
          : "Not Yet";
      },
    },
    {
      title: "Shift Amount",
      render: (text, record) => {
        return (
          // Number(record?.previous_deposit) +
          // Number(record?.amount_deposited) +
          Number(record?.darshanam_services_amount) +
          Number(record?.pratyaksha_seva_services_amount) +
          Number(record?.accommodation_services_amount) +
          Number(record?.gst_amount) +
          Number(record?.prasadam_services_amount) +
          Number(record?.donation_services_amount) +
          Number(record?.publication_services_amount) +
          Number(record?.toll_gate_services_amount) +
          Number(record?.kalyanakatta_services_amount)
        );
      },
    },
    {
      title: "Report",
      render: (text, record) => {
        return (
          <>
            {userDetails?.role === "admin" && (
              <a
                onClick={() => {
                  // history.push(`/report/${record.id}`);
                  viewReport(record.id);
                }}
              >
                View Report
              </a>
            )}
            <a
              style={{marginLeft: "10px"}}
              onClick={() => {
                history.push(
                  `${routeMapMini.ShiftTransactions}?shiftID=${record.id}`
                );
              }}
            >
              View Transactions
            </a>
            <a
              style={{marginLeft: "10px"}}
              onClick={() => {
                history.push(
                  `${routeMapMini.ShiftScanLogs}?shiftID=${record.id}`
                );
              }}
            >
              View Scan Logs
            </a>
          </>
        );
      },
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  return (
    <div>
      <Row gutter={[20, 20]}>
        <Col>
          <Row justify="start">Date</Row>
          <Row justify="start">
            <DatePicker.RangePicker
              format="DD-MM-YYYY"
              onChange={(e) => {
                if (e?.[0] && e?.[1]) {
                  setFromDate(moment(e[0]).format(`YYYY-MM-DD`));
                  setToDate(moment(e[1]).format(`YYYY-MM-DD`));
                } else {
                  setFromDate(undefined);
                  setToDate(undefined);
                }
              }}
              ranges={{
                Today: [moment(), moment()],
                "Last 7 Days": [moment().subtract(7, "d"), moment()],
                "Last 15 Days": [moment().subtract(15, "d"), moment()],
              }}
              defaultValue={[moment(fromDate), moment(toDate)]}
              defaultPickerValue={[moment(fromDate), moment(toDate)]}
            />
          </Row>
        </Col>
        {userDetails?.role === "admin" && (
          <Col>
            <Row justify="start">Counter</Row>
            <Row justify="start">
              <Select
                className="sd-select-r"
                dropdownClassName="sd-select-dropdown-r"
                onChange={setCounterId}
                value={counterId}
                defaultValue={counterId}
                placeholder="Select a Counter"
              >
                {counters?.map((item) => {
                  return (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Row>
          </Col>
        )}{" "}
        <Col>
          <Row justify="start">Update</Row>
          <Row justify="start">
            <Button type="primary" onClick={getData}>
              Fetch
            </Button>
          </Row>
        </Col>
        <Col
          style={{
            width: "20%",
            textAlign: "right",
            margin: "auto",
            marginRight: "0",
          }}
        >
          <Card hoverable size="small" style={{fontWeight: "bold"}}>
            <Statistic
              prefix="₹"
              title="Total Collection"
              value={total === null || total === 0 ? 0 : total}
            />
          </Card>
        </Col>
      </Row>
      <Table
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        scroll={{x: true}}
        size="small"
        onChange={handleTableChange}
        columns={columns}
        dataSource={data}
        style={{textTransform: "capitalize"}}
      />
      <Modal
        visible={reportPopUp}
        width={1000}
        footer={null}
        closable={true}
        centered
        onCancel={() => {
          setReportPopUp(false);
        }}
      >
        <SummaryReport
          shiftId={shiftId}
          setReportPopUp={setReportPopUp}
          from="shiftReportTable"
        />
      </Modal>
    </div>
  );
};

export default withRouter(ShiftReportTable);
