import Axios from '../utils/axios';

const DashStatsService = {
  getAllDashStats: async (fromDate, toDate, aggregationType, txType) => {
    const bookingTypes = [
      'seva',
      'donation',
      'darshanam',
      'srisailaPrabha',
      'accomodation',
    ];
    const resp = await Promise.all(
      bookingTypes.map(async (item) => {
        return Axios.get(
          `dash-stats/bookings/date?bookingType=${item}&aggregationType=${aggregationType}&fromDate=${fromDate}&toDate=${toDate}&paymentStatus=success&page=0&limit=0&txType=${txType}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );
      })
    );

    const results = {};
    resp.forEach((item, i) => {
      results[bookingTypes[i]] = item.data;
    });

    return results;
  },

  getAllCollections: async (aggregationType, fromDate, toDate, txType, dateType) => {
    const resp = await Axios.get(
      `dash-stats/bookings/collections/all?fromDate=${fromDate}&toDate=${toDate}&paymentStatus=success&aggregationType=${aggregationType}&txType=${txType}&dateType=${dateType}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );

    return resp.data;
  },
};

export default DashStatsService;
