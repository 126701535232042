import axios from "axios";
import Axios from "../utils/axios";

const RoomsService = {
  getAllBlocks: async () => {
    const resp = await Axios.get(`/accommodation/block/all`);
    return resp.data;
  },

  getBlockById: async (block_id) => {
    const resp = await Axios.get(`/accommodation/block/byId/${block_id}`);
    return resp.data;
  },

  createNewBlock: async (
    block_name,
    description,
    min_days,
    max_days,
    open_for_bookings
  ) => {
    const requestBody = {
      block_name,
      description,
      min_days,
      max_days,
      open_for_bookings,
    };
    Object.keys(requestBody).forEach((key) => {
      if (requestBody[key] === undefined || !requestBody[key]) {
        delete requestBody[key];
      }
    });
    if (!open_for_bookings) {
      requestBody.open_for_bookings = false;
    } else {
      requestBody.open_for_bookings = true;
    }
    const resp = await Axios.post(`/accommodation/block/create`, requestBody, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  editBlock: async (
    block_details_id,
    block_name,
    description,
    min_days,
    max_days,
    open_for_bookings
  ) => {
    const requestBody = {
      block_details_id,
      block_name,
      description,
      min_days,
      max_days,
      open_for_bookings,
    };
    Object.keys(requestBody).forEach((key) => {
      if (requestBody[key] === undefined || !requestBody[key]) {
        delete requestBody[key];
      }
    });
    if (!open_for_bookings) {
      requestBody.open_for_bookings = false;
    } else {
      requestBody.open_for_bookings = true;
    }
    const resp = await Axios.put(`/accommodation/block/edit`, requestBody, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  deleteBlock: async (block_details_id) => {
    const resp = await Axios.delete(
      `/accommodation/block/${block_details_id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  getAllBlockCategories: async () => {
    const resp = await Axios.get(`/accommodation/block-category/all`);
    return resp.data;
  },

  getBlockCategoryById: async (block_category_id) => {
    const resp = await Axios.get(
      `/accommodation/block-category/byId/${block_category_id}`
    );
    return resp.data;
  },

  getBlockCategoryiesByBlock: async (blockId) => {
    const resp = await Axios.get(
      `/accommodation/block-category/byBlock?id=${blockId}`
    );
    return resp.data;
  },

  createNewBlockCategory: async (
    block_details_id,
    price,
    deposit_amount,
    room_type,
    online_available_rooms,
    persons,
    image_url
  ) => {
    const requestBody = {
      block_details_id,
      price,
      deposit_amount,
      room_type,
      online_available_rooms,
      persons,
      image_url,
    };
    Object.keys(requestBody).forEach((key) => {
      if (requestBody[key] === undefined || !requestBody[key]) {
        delete requestBody[key];
      }
    });
    const resp = await Axios.post(
      `/accommodation/block-category/create`,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  editBlockCategory: async (
    block_category_id,
    block_details_id,
    price,
    deposit_amount,
    room_type,
    online_available_rooms,
    persons,
    image_url
  ) => {
    const requestBody = {
      block_category_id,
      block_details_id,
      price,
      deposit_amount,
      room_type: room_type,
      online_available_rooms,
      persons,
      image_url,
    };
    Object.keys(requestBody).forEach((key) => {
      if (requestBody[key] === undefined || !requestBody[key]) {
        delete requestBody[key];
      }
    });
    const resp = await Axios.put(
      `/accommodation/block-category/edit`,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  deleteBlockCategory: async (block_category_id) => {
    const resp = await Axios.delete(
      `/accommodation/block-category/${block_category_id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  // not passing paymentStatus so gets back only success
  bookings: async (
    blockId,
    bookingFromDate,
    bookingToDate,
    dateType,
    counterType,
    page,
    limit
  ) => {
    let query = `paymentStatus=success&page=${page}&limit=${limit}&dateType=${dateType}&`;
    if (blockId) {
      query += `blockId=${blockId}&`;
    }
    if (bookingFromDate) {
      query += `bookingFromDate=${bookingFromDate}&`;
    }
    if (bookingToDate) {
      query += `bookingToDate=${bookingToDate}&`;
    }
    if (counterType) {
      query += `bookingType=${counterType}`
    }
    const resp = await Axios.get(`/accommodation-report/bookings?${query}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  bookingsExcel: async (blockId, bookingFromDate, bookingToDate, dateType, counterType) => {
    const resp = await Axios.get(
      `accommodation-report/bookings/excel?blockId=${blockId}&bookingFromDate=${bookingFromDate}&bookingToDate=${bookingToDate}&dateType=${dateType}&paymentStatus=success&bookingType=${counterType}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        responseType: "blob",
      }
    );
    return resp.data;
  },

  disableDates: async (blockIds, fromDate, toDate, description) => {
    const requestObj = {
      blockIds: blockIds,
      start_date: fromDate,
      end_date: toDate,
      description: description,
    };
    if (!description) {
      delete requestObj.description;
    }
    const resp = await Axios.post(`/accommodation/disable/date`, requestObj, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  getDisabledDates: async (page, limit) => {
    const resp = await Axios.get(
      `/accommodation/disable/date?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  deleteDisabledDate: async (id) => {
    const resp = await Axios.delete(`/accommodation/disable/date?id=${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  getRoomById: async (id) => {
    const resp = await Axios.get(`/accommodation/room/byId/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  getRooms: async (id) => {
    const resp = await Axios.get(
      `/accommodation/room/all?blockCategoryId=${id}`
    );
    return resp.data;
  },
  getRoomsByBlockID: async (id) => {
    const resp = await Axios.get(
      `/accommodation/room/all/by-block?blockId=${id}`
    );
    return resp.data;
  },

  createRoom: async (
    room_no,
    description,
    open_for_bookings,
    block_category_id
  ) => {
    const requestBody = {
      room_no,
      description,
      open_for_bookings,
      block_category_id,
    };
    Object.keys(requestBody).forEach((key) => {
      if (requestBody[key] === undefined || !requestBody[key]) {
        delete requestBody[key];
      }
    });
    const resp = await Axios.post(`accommodation/room/create`, requestBody, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  editRoom: async (
    room_id,
    room_no,
    description,
    open_for_bookings,
    block_category_id
  ) => {
    const requestBody = {
      room_id,
      room_no,
      description,
      open_for_bookings,
      block_category_id,
    };
    Object.keys(requestBody).forEach((key) => {
      if (requestBody[key] === undefined || !requestBody[key]) {
        delete requestBody[key];
      }
    });
    if (!requestBody.room_id) {
      throw new Error(`Room id is required`);
    }
    if (!open_for_bookings) {
      requestBody.open_for_bookings = false;
    }
    const resp = await Axios.put(`accommodation/room/edit`, requestBody, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  deleteRoom: async (room_id) => {
    if (!room_id) {
      throw new Error(`Room id is required`);
    }
    const resp = await Axios.delete(`accommodation/room/${room_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  blockAvailableRooms: async (blockCategoryId, booking_date) => {
    const resp = await Axios.get(
      `accommodation/availableRooms?blockCategoryId=${blockCategoryId}&date=${booking_date}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  counterBlockAvailableRooms: async (blockCategoryId, booking_date) => {
    const resp = await Axios.get(
      `accommodation/counter/availableRooms?blockCategoryId=${blockCategoryId}&date=${booking_date}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  bookRoom: async (ticketId, roomId, depositAmount) => {
    const resp = await Axios.post(
      "accommodation/admin/book",
      {
        rooms_id: roomId,
        ticket_id: ticketId,
        deposit_amount: depositAmount,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    return resp.data;
  },

  printTicket: async (roomBookingId) => {
    const resp = await Axios.get(
      `accommodation/booked-room/byId/${roomBookingId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  roomAllotment: async (
    onlyCheckedIn,
    bookingFromDate,
    bookingToDate,
    page,
    limit,
    selectedBlockId
  ) => {
    const resp = await Axios.get(
      selectedBlockId
        ? `accommodation-report/room-allotment?page=${page}&limit=${limit}&onlyCheckedIn=${onlyCheckedIn}&checkInFromDate=${bookingFromDate}&checkInToDate=${bookingToDate}&blockId=${selectedBlockId}`
        : `accommodation-report/room-allotment?page=${page}&limit=${limit}&onlyCheckedIn=${onlyCheckedIn}&checkInFromDate=${bookingFromDate}&checkInToDate=${bookingToDate}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  checkOut: async (
    roomBookingId,
    depositeReturned,
    outstandingBalance,
    stats
  ) => {
    const resp = await Axios.post(
      `accommodation/booked-room/checkout`,
      {
        room_booking_id: roomBookingId,
        deposit_returned: depositeReturned,
        outstanding_balance: outstandingBalance,
        stats: stats,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  Renew: async (ticketId, stats) => {
    const resp = await Axios.post(
      `accommodation/booked-room/renew?ticketId=${ticketId}&stats=${stats}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  onlineRenewTicket: async (ticketId, onlineTicketId) => {
    const resp = await Axios.post(
      `accommodation/booked-room/renew-with-online-ticket?ticketId=${ticketId}&onlineTicketId=${onlineTicketId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  getCounterBlocks: async (roomtype, persons, blockId) => {
    const resp = await Axios.get(
      `accommodation/counter-query?room_type=${roomtype}&persons=${persons}&blockId=${blockId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  getBlockCategoryList: async (roomType, persons) => {
    const resp = await Axios.get(
      `/block-counter/counter-query?room_type=${roomType}&persons=${persons}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
};

export default RoomsService;
