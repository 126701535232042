import React from "react";
import { Col, Row, Space, Card, Typography } from "antd";
const { Paragraph } = Typography;

const NewUpdateDetails = ({ details }) => {
  const ellipsis = true;

  return (
    <Card
      title="New"
      hoverable={"true"}
      style={{ width: "100%", marginBottom: "10px" }}
    >
      <Col>
        <Row
          style={{
            display: details?.update_type === "DELETE" ? "none" : "block",
          }}
        >
          <Space>
            <p>Name :</p>
            <p>{details?.name}</p>
          </Space>
        </Row>
        <Row
          style={{
            display: details?.update_type === "DELETE" ? "none" : "block",
          }}
        >
          <Space>
            <p>Type :</p>
            <p>{details?.type}</p>
          </Space>
        </Row>
        <Row
          style={{
            display: details?.update_type === "DELETE" ? "none" : "block",
          }}
        >
          <Space>
            <p>Location :</p>
            <p>{details?.location}</p>
          </Space>
        </Row>
        <Row
          style={{
            display: details?.update_type === "DELETE" ? "none" : "block",
          }}
        >
          <Space>
            <p>Start Date :</p>
            <p>{details?.start_date}</p>
          </Space>
        </Row>
        <Row
          style={{
            display: details?.update_type === "DELETE" ? "none" : "block",
          }}
        >
          <Space>
            <p>End Date :</p>
            <p>{details?.end_date}</p>
          </Space>
        </Row>
        <Row
          style={{
            display: details?.update_type === "DELETE" ? "none" : "block",
          }}
        >
          <p>Description :</p>
          <Paragraph
            ellipsis={
              ellipsis ? { rows: 2, expandable: true, symbol: "more" } : false
            }
          >
            {details?.description}
          </Paragraph>
        </Row>
        <Row
          style={{
            display: details?.update_type === "DELETE" ? "none" : "block",
          }}
        >
          <Space>
            <p>Image URL :</p>
            <p>{details?.image_url}</p>
          </Space>
        </Row>
        <Row
          style={{
            display: details?.update_type === "DELETE" ? "none" : "block",
          }}
        >
          <Space>
            <p>Max Booking Per Day :</p>
            <p>{details?.max_booking_per_day}</p>
          </Space>
        </Row>
        <Row
          style={{
            display: details?.update_type === "DELETE" ? "none" : "block",
          }}
        >
          <Space>
            <p>Min Days :</p>
            <p>{details?.min_days}</p>
          </Space>
        </Row>
        <Row
          style={{
            display: details?.update_type === "DELETE" ? "none" : "block",
          }}
        >
          <Space>
            <p>Max Days :</p>
            <p>{details?.max_days}</p>
          </Space>
        </Row>
        <Row
          style={{
            display: details?.update_type === "DELETE" ? "none" : "block",
          }}
        >
          <Space>
            <p>Disabled :</p>
            <p>{details?.disabled}</p>
          </Space>
        </Row>
        <Row
          style={{
            display: details?.update_type === "DELETE" ? "none" : "block",
          }}
        >
          <Space>
            <p>Price :</p>
            <p>{details?.price}</p>
          </Space>
        </Row>
        <Row
          style={{
            display: details?.update_type === "DELETE" ? "none" : "block",
          }}
        >
          <Space>
            <p>Live Telecast :</p>
            <p>{details?.live_telecast}</p>
          </Space>
        </Row>
        <Row>
          <Space>
            <p>Created at :</p>
            <p>{details?.created_at}</p>
          </Space>
        </Row>
        <Row>
          <Space>
            <p>Updated at :</p>
            <p>{details?.updated_at}</p>
          </Space>
        </Row>
        <Row>
          <Space>
            <p>Deleted at :</p>
            <p>{details?.deleted_at}</p>
          </Space>
        </Row>
        <Row>
          <Space>
            <p>Update Reason :</p>
            <p>{details?.update_reason}</p>
          </Space>
        </Row>
        <Row>
          <Space>
            <p>Update Type :</p>
            <p>{details?.update_type}</p>
          </Space>
        </Row>
        <Row
          style={{
            display: details?.update_type === "DELETE" ? "none" : "block",
          }}
        >
          <Space>
            <p>Update Used :</p>
            <p>{details?.update_used}</p>
          </Space>
        </Row>
        <Row>
          <Space>
            <p>Username :</p>
            <p>{details?.userName}</p>
          </Space>
        </Row>
        <Row>
          <Space>
            <p>No of User Reviews :</p>
            <p>{details?.no_of_user_reviews}</p>
          </Space>
        </Row>
      </Col>
    </Card>
  );
};

export default NewUpdateDetails;
