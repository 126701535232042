/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext} from "react";
import {withRouter} from "react-router-dom";
import RoomsService from "../../../Services/rooms.service";
import {routeMapMini} from "../../../constants/routeMap";
import {
  notification,
  Table,
  Row,
  Col,
  DatePicker,
  Button,
  Space,
  Drawer,
  Tag,
  Modal,
  Select,
} from "antd";
import moment from "moment";
import UserDetailsCard from "../../ManageUsers/UserDetailsCard";
import {FormatNumberINRCurrency} from "../../../utils/currencyFormat";
import Checkbox from "antd/lib/checkbox/Checkbox";
import CheckOut from "../../Ticket/CheckOut";
import accommodationReportService from "../../../Services/accommodation-report.service";
import {UserContext} from "../../../context/user.context";

const RoomAllocation = ({history}) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [allotmentdata, setAllotmentData] = useState("");
  const [bookingFromDate, setBookingFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [bookingToDate, setBookingToDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const {userDetails, setUserDetails} = useContext(UserContext);
  const [onlyCheckedIn, setOnlyCheckedIn] = useState(false);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [blocks, setBlocks] = useState([]);
  const [roomId, setRoomId] = useState();
  const [roomNo, setRoomNo] = useState();
  const [blockName, setBlockName] = useState();
  const [checkOutModal, setCheckOutModal] = useState(false);
  const [checkOutData, setCheckOutData] = useState();
  const [selectedBlockId, setSelectedBlockId] = useState("");

  const fetchRoomAllocation = async () => {
    setDataLoading(true);
    try {
      const data = await RoomsService.roomAllotment(
        onlyCheckedIn,
        bookingFromDate,
        bookingToDate,
        tableCurrentPage,
        tablePageSize,
        Number(selectedBlockId)
      );
      setAllotmentData(data?.items);
      setTabelTotalSize(data?.meta?.totalItems);
    } catch (error) {
      if (error?.response?.status === 401) {
        setTimeout(() => {
          history?.push(routeMapMini.Login);
        }, 5000);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setDataLoading(false);
  };
  const getBlocks = async () => {
    try {
      if (!userDetails) {
        return;
      }
      if (userDetails?.role === "admin") {
        let resp = await RoomsService.getAllBlocks();
        resp = resp?.map((item) => {
          return {block_id: item.id, block_block_name: item.block_name};
        });
        setBlocks(resp);
        if (selectedBlockId === "") {
          setSelectedBlockId("all");
        }
      } else {
        const resp = await accommodationReportService.getShiftBlocks();
        setBlocks(resp);
        if (resp[0].block_id) {
          setSelectedBlockId(resp[0].block_id);
        }
      }
    } catch (error) {
      console.error(error);

      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
  };

  const handleCancel = () => {
    setRoomId();
    setCheckOutModal(false);
  };

  useEffect(() => {
    getBlocks();
  }, [userDetails]);

  useEffect(() => {
    if (selectedBlockId === "") {
      return;
    }
    fetchRoomAllocation();
  }, [
    tableCurrentPage,
    tablePageSize,
    bookingFromDate,
    bookingToDate,
    onlyCheckedIn,
    checkOutData,
    selectedBlockId,
  ]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const handleUserDetails = () => {
    setUserDetails(undefined);
    setShowUserDetails(false);
  };
  const checkOut = (record) => {
    setCheckOutModal(true);
    setRoomId(record?.id);
    setRoomNo(record?.sd_rooms?.room_number);
    setBlockName(
      record?.sd_block_category_booking?.sd_block_category_details
        ?.sd_block_details?.block_name
    );
  };

  // const renew = (record) => {
  //   setRenewModal(true);
  //   setTicketId(record?.sd_block_category_booking?.sd_cart_item?.ticket_id);
  //   setRoomNo(record?.sd_rooms?.room_number);
  //   setBlockName(
  //     record?.sd_block_category_booking?.sd_block_category_details
  //       ?.sd_block_details?.block_name
  //   );
  //   setDevoteeName(record?.sd_block_category_booking?.first_name);
  //   setBookingDate(record?.sd_block_category_booking?.booking_date);
  //   setCheckInTime(record?.check_in_time);
  //   setDepositeAmount(record?.deposit_amount);
  //   setPersons(record?.sd_block_category_booking?.no_of_persons);
  //   setPhone(record?.sd_block_category_booking?.contact);
  //   setRoomType(
  //     record?.sd_block_category_booking?.sd_block_category_details?.room_type
  //   );
  // };

  const columns = [
    {
      title: "Booked By",
      dataIndex: "sd_user.display_name",
      key: "sd_user.display_name",
      render: (text, record) => record.sd_user.display_name,
    },
    {
      title: "Booked For",
      dataIndex: "sd_block_category_booking.first_name",
      key: "sd_block_category_booking.first_name",
      render: (text, record) => {
        return (
          <div>
            {record.sd_block_category_booking.first_name}{" "}
            {record.sd_block_category_booking.last_name}
          </div>
        );
      },
    },
    {
      title: "Ticket Id",
      dataIndex: "sd_block_category_booking.sd_cart_item.ticket_id",
      key: "sd_block_category_booking.sd_cart_item.ticket_id",
      render: (text, record) =>
        record.sd_block_category_booking.sd_cart_item.ticket_id,
    },
    {
      title: "Contact",
      dataIndex: "sd_block_category_booking.contact",
      key: "sd_block_category_booking.contact",
      render: (text, record) => record.sd_block_category_booking.contact,
    },
    {
      title: "Block Name",
      dataIndex:
        "sd_block_category_booking.sd_block_category_details.sd_block_details.block_name",
      key: "sd_block_category_booking.sd_block_category_details.sd_block_details.block_name",
      render: (text, record) => {
        return (
          <div>
            {
              record.sd_block_category_booking.sd_block_category_details
                .sd_block_details.block_name
            }
          </div>
        );
      },
    },
    {
      title: "Room Number",
      dataIndex: "sd_rooms.room_number",
      key: "sd_rooms.room_number",
      render: (text, record) => {
        return <div>{record.sd_rooms.room_number}</div>;
      },
    },
    {
      title: "Room Type",
      dataIndex:
        "sd_block_category_booking.sd_block_category_details.room_type",
      key: "sd_block_category_booking.sd_block_category_details.room_type",
      render: (text, record) => {
        if (
          record.sd_block_category_booking.sd_block_category_details
            .room_type === "AC"
        ) {
          return <Tag color="green">A/C</Tag>;
        } else if (
          record.sd_block_category_booking.sd_block_category_details
            .room_type === "NONAC"
        ) {
          return <Tag color="volcano">Non A/C</Tag>;
        } else if (
          record.sd_block_category_booking.sd_block_category_details
            .room_type === "DORMITORY"
        ) {
          return <Tag color="brown">Dormitory</Tag>;
        } else if (
          record.sd_block_category_booking.sd_block_category_details
            .room_type === "COTTAGE"
        ) {
          return <Tag color="red">Cottage</Tag>;
        } else {
          return <Tag color="orange">Suite</Tag>;
        }
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: FormatNumberINRCurrency,
    },
    {
      title: "Deposit",
      dataIndex: "deposit_amount",
      key: "deposit_amount",
      render: FormatNumberINRCurrency,
    },
    {
      title: "Check In Time",
      dataIndex: "check_in_time",
      key: "check_in_time",
      render: (text) =>
        moment(text).utcOffset("+5:30").format(`DD-MM-YYYY - hh:mm A `),
    },
    {
      title: "Check Out Time",
      dataIndex: "check_out_time",
      key: "check_out_time",
      render: (text, record) => {
        if (text) {
          return moment(text)
            .utcOffset("+5:30")
            .format(`DD-MM-YYYY - hh:mm A `);
        } else {
          return (
            <Space size="middle">
              <a onClick={() => checkOut(record)}>Check Out</a>
              {/* <a onClick={() => renew(record)}>Renew</a> */}
            </Space>
          );
        }
      },
    },
  ];

  return (
    <>
      <>
        <Row style={{marginBottom: "20px"}} gutter={[20, 20]}>
          <Col>
            <Row justify="start">Check In Date</Row>
            <Row justify="start">
              <DatePicker.RangePicker
                onChange={(e) => {
                  if (e?.[0] && e?.[1]) {
                    setBookingFromDate(moment(e[0]).format(`YYYY-MM-DD`));
                    setBookingToDate(moment(e[1]).format(`YYYY-MM-DD`));
                  } else {
                    setBookingFromDate(undefined);
                    setBookingToDate(undefined);
                  }
                }}
                ranges={{
                  Today: [moment(), moment()],
                  "Last 7 Days": [moment().subtract(7, "d"), moment()],
                  "Last 15 Days": [moment().subtract(15, "d"), moment()],
                }}
                defaultValue={[moment(bookingFromDate), moment(bookingToDate)]}
                defaultPickerValue={[
                  moment(bookingFromDate),
                  moment(bookingToDate),
                ]}
              />
            </Row>
            <Row justify="start">
              <Col>
                <Checkbox
                  checked={onlyCheckedIn}
                  onChange={(e) => {
                    setOnlyCheckedIn(e.target.checked);
                  }}
                />
              </Col>
              <Col style={{marginLeft: "5px"}}>Only Checked In</Col>
            </Row>
          </Col>
          <Col>
            <Row style={{color: "rgb(222, 222, 222)"}}>.</Row>
            <Row>
              {" "}
              <Select
                style={{width: "200px"}}
                onChange={(e) => {
                  setSelectedBlockId(e);
                }}
                value={selectedBlockId}
              >
                {blocks?.map((block) => (
                  <Select.Option key={block.block_id} value={block.block_id}>
                    {block.block_block_name}
                  </Select.Option>
                ))}
                <Select.Option key={"all"} value={"all"}>
                  All
                </Select.Option>
              </Select>
            </Row>{" "}
          </Col>
          <Col>
            <Row style={{color: "rgb(222, 222, 222)"}}>.</Row>

            <Row justify="start">
              <Button
                type="primary"
                style={{minWidth: "100px"}}
                onClick={() => fetchRoomAllocation()}
              >
                Fetch
              </Button>
            </Row>
          </Col>
        </Row>

        <Row>
          <Space size="small">
            <a>{tableTotalSize}</a>
            <span>Total Bookings</span>
          </Space>
        </Row>

        <Table
          style={{width: "100%", textTransform: "capitalize"}}
          columns={columns}
          dataSource={allotmentdata}
          loading={dataLoading}
          size="small"
          scroll={{x: true}}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: 15,
          }}
          onChange={handleTableChange}
        />
        <Drawer
          title={`User`}
          placement="right"
          closable={false}
          onClose={handleUserDetails}
          visible={showUserDetails}
          width={500}
          destroyOnClose={true}
        >
          <UserDetailsCard userid={userDetails} />
        </Drawer>
        {/* <Modal
        visible={modalVisible}
        title="Return Amount"
        onOk={submit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={submit}
          >
            Submit
          </Button>,
        ]}
      >
        <Row>Amount</Row>
        <Row justify="center" style={{ width: "100%", padding: "10px 0px" }}>
          <Input
            placeholder="Please enter returning amount"
            type={Number}
            value={returnAmount}
            onChange={(e) => SetReturnAmount(e.target.value)}
          ></Input>
        </Row>
      </Modal> */}

        <Modal
          visible={checkOutModal}
          onCancel={handleCancel}
          footer={false}
          centered
        >
          <CheckOut
            roomId={roomId}
            roomNo={roomNo}
            blockName={blockName}
            setModalVisible={setCheckOutModal}
            setCheckOutData={setCheckOutData}
            from="allocationTable"
          />
        </Modal>
        {/* <Modal
          visible={renewModal}
          onCancel={handleCancel}
          footer={false}
          centered
        >
          <Renew
            ticketId={ticketId}
            persons={persons}
            roomNo={roomNo}
            roomId={roomId}
            roomType={roomType}
            blockName={blockName}
            devoteeName={devoteeName}
            bookingDate={bookingDate}
            checkInTime={checkInTime}
            depositAmount={depositAmount}
            phone={phone}
            setRenewModal={setRenewModal}
            from="allocationTable"
          />
        </Modal> */}
      </>
    </>
  );
};

export default withRouter(RoomAllocation);
