/* eslint-disable react/jsx-pascal-case */
import {Button, Col, DatePicker, Input, notification, Row} from "antd";

import Modal from "antd/lib/modal/Modal";
import {useContext, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {routeMapMini} from "../../../constants/routeMap";
// import { UserContext } from '../../../context/user.context';
import ShiftmangementTable from "./ShiftmangementTable";
import moment from "moment";
import CounterService from "../../../Services/Counter.service";
const ShiftMangementMainPage = ({history, location}) => {
  const [loading, setLoading] = useState();
  const [showModal, setShowModal] = useState(false);
  // const { userDetails } = useContext(UserContext);
  const [list, setList] = useState([]);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [description, setDescription] = useState("");
  const [edit, setEdit] = useState(false);
  const [temp, setTemp] = useState(false);
  const [darshanamID, setDarshanamId] = useState("");
  useEffect(() => {
    getAllShifts();
  }, []);

  useEffect(() => {
    if (edit === true) {
      setShowModal(true);
      setDescription(temp?.description);
      setFrom(moment(temp?.start_time, "hh:mm:ss"));
      setTo(moment(temp?.end_time, "hh:mm:ss"));
    }
  }, [edit]);

  const getAllShifts = async (data) => {
    setLoading(true);
    try {
      const id = new URLSearchParams(location.search).get("id");
      setDarshanamId(id);
      const resp = await CounterService.getAllShifts(id);
      setList(resp);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
  };

  const handleOk = async () => {
    if (description === "") {
      notification.error({message: "Please enter a name"});
      return;
    }
    setLoading(true);
    try {
      console.log(edit);
      if (edit) {
        let body = {
          name: description,
          start: moment(from).format("hh:mm A"),
          end: moment(to).format("hh:mm A"),
          darshanam_slot_id: temp?.id,
        };
        console.log(body);

        await CounterService.editSlot(body);
        notification.success({
          message: `Shift has been succesfully Edited`,
        });
        setEdit(false);
      } else {
        let body = {
          name: description,
          start: moment(from).format("hh:mm A"),
          end: moment(to).format("hh:mm A"),
        };
        await CounterService.addShift(body);
        notification.success({
          message: `Shift has been succesfully created`,
        });
      }

      getAllShifts();
      setShowModal(false);
      clearFields();
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setDescription("");
    setFrom("");
    setTo(""); // setblockID(undefined);
  };

  const handleCancel = () => {
    clearFields();
    setShowModal(false);
    setEdit(false);
  };

  return (
    <div style={{width: "100%"}}>
      <Row style={{width: "100%", marginBottom: "15px"}} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setShowModal(true);
            }}
            // disabled={userDetails.role !== "admin"}
          >
            Add New Shift
          </Button>
        </Col>
      </Row>
      <Row style={{width: "100%"}}>
        <ShiftmangementTable
          list={list}
          loading={loading}
          setLoading={setLoading}
          getAllShifts={getAllShifts}
          edit={edit}
          setEdit={setEdit}
          setTemp={setTemp}
        />
      </Row>

      <Modal
        visible={showModal}
        title={edit ? `Edit a Shift` : `Add a New Shift`}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row>
          Description :{" "}
          <Input
            style={{marginTop: "10px"}}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter Name "
          ></Input>
        </Row>

        <Row style={{width: "100%", padding: "25px 0px 10px 0px"}}>
          Start Time:
          <DatePicker
            allowClear
            picker="time"
            style={{marginLeft: "5px"}}
            defaultValue={from}
            value={from}
            onChange={(e) => {
              setFrom(e);
            }}
          />
          <Row style={{width: "100%", padding: "25px 0px 10px 0px"}}>
            End Time:
            <DatePicker
              allowClear
              style={{marginLeft: "5px"}}
              picker="time"
              defaultValue={to}
              value={to}
              onChange={(e) => {
                setTo(e);
              }}
            />
          </Row>
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(ShiftMangementMainPage);
