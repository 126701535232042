/* eslint-disable jsx-a11y/anchor-is-valid */
import { notification, Row, Space, Table, Tag } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactJson from "react-json-view";
import { routeMapMini } from "../../../constants/routeMap";
import UserService from "../../../Services/User.service";

const UserActionLogs = ({ history, userId }) => {
  const [logs, setLogs] = useState();

  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);

  const [dataLoading, setDataLoading] = useState(false);

  const [showJSONModal, setShowJSONModal] = useState(false);
  const [JSONModalData, setJSONModalData] = useState(false);

  const fetchLogs = async () => {
    setDataLoading(true);
    try {
      const respData = await UserService.userActionLogs(
        userId,
        tableCurrentPage,
        tablePageSize
      );
      setLogs(
        respData?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setTabelTotalSize(respData?.meta?.totalItems);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page.",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
    setDataLoading(false);
  };

  useEffect(() => {
    fetchLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, tableCurrentPage, tablePageSize]);

  const columns = [
    {
      title: "url",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "ip_address",
      dataIndex: "ip_address",
      key: "ip_address",
    },
    {
      title: "method",
      dataIndex: "method",
      key: "method",
      render: (text) => {
        if (text === "GET") {
          return <Tag color="green">{text}</Tag>;
        } else if (text === "POST") {
          return <Tag color="orange">{text}</Tag>;
        } else if (text === "DELETE") {
          return <Tag color="RED">{text}</Tag>;
        } else {
          return <Tag color="blue">{text}</Tag>;
        }
      },
    },
    {
      title: "created_at",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) =>
        moment(text).utcOffset("+05:30").format("YYYY-MM-DD hh:mm A"),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  return (
    <>
      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Total Logs</span>
        </Space>
      </Row>
      <Table
        dataSource={logs}
        loading={dataLoading}
        columns={columns}
        onChange={handleTableChange}
        scroll={{ x: true }}
        size="small"
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        style={{ textTransform: "capitalize" }}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <Row>
                <p style={{ fontWeight: "bolder" }}>User Agent:</p>
                {record.user_agent}
              </Row>
              <Row hidden={!record.params || record.params === "{}"}>
                <p style={{ fontWeight: "bolder" }}>Params:</p>
                <a
                  onClick={() => {
                    setJSONModalData(JSON.parse(record.params));
                    setShowJSONModal(true);
                  }}
                >
                  view
                </a>
              </Row>
              <Row hidden={!record.query || record.query === "{}"}>
                <p style={{ fontWeight: "bolder" }}>Query:</p>
                <a
                  onClick={() => {
                    setJSONModalData(JSON.parse(record.query));
                    setShowJSONModal(true);
                  }}
                >
                  view
                </a>
              </Row>
              <Row hidden={!record.body || record.body === "{}"}>
                <p style={{ fontWeight: "bolder" }}>Body:</p>
                <a
                  onClick={() => {
                    setJSONModalData(JSON.parse(record.body));
                    setShowJSONModal(true);
                  }}
                >
                  view
                </a>
              </Row>
            </>
          ),
        }}
      />
      <Modal
        visible={showJSONModal}
        title={`Add New Room`}
        onOk={() => {
          setShowJSONModal(false);
        }}
        onCancel={() => {
          setShowJSONModal(false);
        }}
      >
        <ReactJson
          src={JSONModalData}
          theme="bright:inverted"
          displayDataTypes={false}
          displayObjectSize={false}
          enableClipboard={false}
        />
      </Modal>
    </>
  );
};

export default UserActionLogs;
