/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Col,
  DatePicker,
  Input,
  notification,
  Row,
  Select,
  Space,
  Table,
  Drawer,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import fileDownload from "js-file-download";
import { routeMapMini } from "../../constants/routeMap";
import DonationService from "../../Services/Donation.service";
import { FormatNumberINRCurrency } from "../../utils/currencyFormat";
import moment from "moment";
import UserDetailsCard from "../ManageUsers/UserDetailsCard";
import { UserContext } from "../../context/user.context";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

const DonationBookingDetails = ({ history }) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const { userDetails } = useContext(UserContext);
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  const [DonationNamesAndIds, setDonationNamesAndIds] = useState([]);
  const [donationNamesLoading, setDonationNamesLoading] = useState(false);
  const labelStyles = {
    float: "left",
    fontSize: "14px",
    marginBottom: "5px",
    marginRight: "5px",
  };

  const [selectedDonationId, setSelectedDonationId] = useState(0);
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [showUserDetails, setShowUserDetails] = useState(false);
  const [userDonationDetails, setDonationUserDetails] = useState();
  const [editLoading, setEditLoading] = useState(false);
  const [editDetails, setEditDetails] = useState(null);
  const [editFlag, setEditFlag] = useState(false);

  const getDonationNamesAndIds = async () => {
    setDonationNamesLoading(true);
    try {
      const donationNamesAndIdData = await DonationService.getAll();
      setDonationNamesAndIds(donationNamesAndIdData);
    } catch (error) {
      notification.error({ message: error.message });
    }
    setDonationNamesLoading(false);
  };

  const editDonationDetails = async (id) => {
    setEditLoading(true);
    if (editDetails?.contact?.length !== 10) {
      notification.error({ message: "Phone number must be 10 digits" });
      setEditLoading(false);
      return;
    }
    try {
      let body = {
        donationBookingId: id,
        contact: `+91${editDetails.contact}`,
        address: editDetails?.address,
      };
      await DonationService.editDonationBookingDetails(body);
      setEditLoading(false);
      setEditFlag(false);
      setEditDetails(null);
      fetchBookings();
    } catch (error) {
      setEditLoading(false);
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({ message: error.response?.data?.message });
      } else {
        notification.error({ message: error.message });
      }
    }
  };

  const fetchBookings = async () => {
    setLoading(true);
    try {
      const respData = await DonationService.bookings(
        selectedDonationId,
        fromDate,
        toDate,
        tableCurrentPage,
        tablePageSize
      );
      setData(
        respData?.items.map((item) => {
          return {
            key: item.ticket_id,
            ...item,
          };
        })
      );
      setTabelTotalSize(respData?.meta?.totalItems);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
    setLoading(false);
  };

  const downloadExcel = async () => {
    if (!selectedDonationId) {
      return notification.error({
        message: "Donation is not selected",
      });
    }

    notification.info({ message: "Downloading file! Please wait." });
    DonationService.bookingsExcel(selectedDonationId, fromDate, toDate)
      .then((data) => {
        fileDownload(
          data,
          `Donations-${moment().format("DD-MM-YYYY HH:MM")}.xlsx`
        );
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          notification.error({
            message: "Token Expired, redirecting to login page in 5 seconds",
          });
          history?.push(routeMapMini.Login);
        } else if (error?.response?.data?.message) {
          notification.error({ message: error?.response?.data?.message });
        } else {
          console.error(error);
          notification.error({ message: "something is wrong" });
        }
      });
  };

  useEffect(() => {
    getDonationNamesAndIds();
  }, []);

  useEffect(() => {
    fetchBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDonationId, tableCurrentPage, tablePageSize, fromDate, toDate]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "user",
      dataIndex: "user_display_name",
      key: "user_display_name",
      render: (text, record) => {
        return (
          <div>
            <a
              onClick={() => {
                // history?.push(
                //   `${routeMapMini.userDetails}?userId=${record.user_id}`
                // );
                setDonationUserDetails(record?.user_id);
                setShowUserDetails(true);
              }}
              style={{ textTransform: "capitalize" }}
            >
              {record.user_display_name}
            </a>
          </div>
        );
      },
    },
    {
      title: "Donation Name",
      dataIndex: "donationName",
      key: "donationName",
      ...getColumnSearchProps("donationName"),
    },
    {
      title: "In the name of",
      dataIndex: "inTheNameOf",
      key: "inTheNameOf",
    },
    // {
    //   title: "Ticket ID",
    //   dataIndex: "ticket_id",
    //   key: "ticket_id",
    // },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: FormatNumberINRCurrency,
    },
    {
      title: "Order Date",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const handleUserDetails = () => {
    setDonationUserDetails(undefined);
    setShowUserDetails(false);
  };

  return (
    <>
      <Row style={{ marginBottom: "20px" }} gutter={[20, 20]}>
        <Col>
          <Row justify="start">Donation Name</Row>
          <Row justify="start">
            <Select
              className="sd-select-seva"
              dropdownClassName="sd-select-dropdown-seva"
              loading={donationNamesLoading}
              onChange={setSelectedDonationId}
              defaultValue=""
            >
              {DonationNamesAndIds.map((item) => {
                return (
                  <Select.Option value={item.id}> {item.name} </Select.Option>
                );
              })}
              <Select.Option value="">All</Select.Option>
            </Select>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Order Date</Row>
          <Row justify="start">
            <DatePicker.RangePicker
              format="DD-MM-YYYY"
              onChange={(e) => {
                if (e?.[0] && e?.[1]) {
                  setFromDate(moment(e[0]).format(`YYYY-MM-DD`));
                  setToDate(moment(e[1]).format(`YYYY-MM-DD`));
                } else {
                  setFromDate(undefined);
                  setToDate(undefined);
                }
              }}
              ranges={{
                Today: [moment(), moment()],
                "Last 7 Days": [moment().subtract(7, "d"), moment()],
                "Last 15 Days": [moment().subtract(15, "d"), moment()],
              }}
              defaultValue={[moment(fromDate), moment(toDate)]}
              defaultPickerValue={[moment(fromDate), moment(toDate)]}
            />
          </Row>
        </Col>
        <Col>
          <Row justify="start">Update</Row>
          <Row justify="start">
            <Button type="primary" onClick={fetchBookings}>
              Fetch
            </Button>
          </Row>
        </Col>
        <Col>
          <Row justify="start"> Download </Row>
          <Row justify="start">
            <Button type="primary" onClick={downloadExcel}>
              Excel
            </Button>
          </Row>
        </Col>
      </Row>

      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Total Bookings</span>
        </Space>
      </Row>

      <Table
        scroll={{ x: true }}
        style={{ width: "100%", textTransform: "capitalize" }}
        columns={columns}
        dataSource={data}
        size="small"
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        loading={loading}
        onChange={handleTableChange}
        onExpandedRowsChange={() => {
          setEditFlag(false);
          setEditDetails(null);
        }}
        expandable={{
          expandedRowRender: (record) => (
            <div
              style={{
                display: "flex",
                flexDirection: `${editFlag ? "column" : "row"}`,
              }}
            >
              {editFlag ? (
                <>
                  <Row justify="space-between" style={{ width: "100%" }}>
                    <Col style={{ width: "24%" }}>
                      <label style={labelStyles}>
                        Mobile Number
                        <span style={{ color: "red", marginLeft: "3px" }}>
                          *
                        </span>
                      </label>

                      <Input
                        size="medium"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        maxLength={10}
                        addonBefore={"+91"}
                        placeholder="Please Enter Mobile Number"
                        value={editDetails?.contact}
                        onChange={(e) => {
                          if (e?.target?.value.length > 10) {
                            return;
                          }
                          setEditDetails({
                            address: editDetails?.address,
                            contact: `${e?.target?.value}`,
                          });
                        }}
                      />
                    </Col>
                    <Col style={{ width: "24%" }}>
                      <label style={labelStyles}>
                        Address
                        <span style={{ color: "red", marginLeft: "3px" }}>
                          *
                        </span>
                      </label>

                      <Input
                        type="text"
                        size="medium"
                        placeholder="Enter a Address"
                        value={editDetails?.address}
                        onChange={(e) => {
                          setEditDetails({
                            address: e.target.value,
                            contact: editDetails?.contact,
                          });
                        }}
                      />
                    </Col>
                    <Col style={{ width: "24%" }}>
                      <label style={labelStyles}>
                        Enter City{" "}
                        <span style={{ color: "red", marginLeft: "3px" }}>
                          *
                        </span>
                      </label>

                      <Input
                        type="text"
                        placeholder="Enter address"
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                      />
                    </Col>
                    <Col style={{ width: "24%", textAlign: "right" }}></Col>
                  </Row>
                  <Row justify="space-between" style={{ width: "100%" }}>
                    <Col style={{ width: "24%" }}>
                      <label style={labelStyles}>
                        {" "}
                        Select State{" "}
                        <span style={{ color: "red", marginLeft: "3px" }}>
                          *
                        </span>
                      </label>
                      <RegionDropdown
                        className="CountryRegionDropDown"
                        style={{
                          border: "1px solid #d9d9d9",
                          marginBottom: "20px",
                          height: "32px",
                          fontSize: "14px",
                          padding: "0px 8px",
                        }}
                        country={country}
                        value={state}
                        showDefaultOption={true}
                        blankOptionLabel="Select a State"
                        onChange={(val) => {
                          setState(val);
                        }}
                      />
                    </Col>
                    <Col style={{ width: "24%" }}>
                      <label style={labelStyles}>
                        {" "}
                        Select Country{" "}
                        <span style={{ color: "red", marginLeft: "3px" }}>
                          *
                        </span>
                      </label>
                      <CountryDropdown
                        className="CountryRegionDropDown"
                        style={{
                          border: "1px solid #d9d9d9",
                          marginBottom: "20px",
                          height: "32px",
                          fontSize: "14px",
                          padding: "0px 8px",
                        }}
                        value={country}
                        onChange={(val) => {
                          setCountry(val);
                        }}
                      />
                    </Col>
                    <Col style={{ width: "24%" }}>
                      <label style={labelStyles}>
                        Enter Pincode{" "}
                        <span style={{ color: "red", marginLeft: "3px" }}>
                          *
                        </span>
                      </label>{" "}
                      <br />
                      <Input
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder="Enter Pincode"
                        value={zip}
                        onChange={(e) => {
                          if (e.target.value.length > 6) {
                            return;
                          }
                          setZip(e.target.value);
                        }}
                      />
                    </Col>
                    <Col style={{ width: "24%", textAlign: "center" }}>
                      <label style={labelStyles}></label>
                      <br />
                      <Button
                        style={{ marginTop: "4px", marginRight: "10px" }}
                        type="danger"
                        onClick={() => {
                          setEditFlag(false);
                          setEditDetails(null);
                        }}
                      >
                        Cancel
                      </Button>
                      {userDetails.role === "donation_write" ||
                      userDetails.role === "admin" ? (
                        <>
                          <Button
                            style={{ marginTop: "4px" }}
                            type="submit"
                            loading={editLoading}
                            onClick={() => {
                              if (editFlag) {
                                editDonationDetails(record.donationBookingId);
                              }
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </>
              ) : (
                <Row style={{ width: "80%" }}>
                  <p style={{ fontWeight: "bolder", margin: "0px 10px" }}>
                    Address:
                  </p>
                  {record?.address}
                  <p style={{ fontWeight: "bolder", margin: "0px 10px" }}>
                    Phone:
                  </p>
                  {record.contact}
                </Row>
              )}
              {!editFlag && (
                <Col
                  style={{
                    width: "20%",
                    justifyContent: "end",
                    display: "flex",
                  }}
                >
                  {" "}
                  {userDetails.role === "donation_write" ||
                  userDetails.role === "admin" ? (
                    <Button
                      type="submit"
                      loading={editLoading}
                      onClick={() => {
                        setEditDetails({
                          address: record?.address,
                          contact: record?.contact?.split("+91")[1],
                        });
                        setEditFlag(true);
                      }}
                    >
                      Edit
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
              )}
            </div>
          ),
        }}
      ></Table>
      <Drawer
        title={`User`}
        placement="right"
        closable={false}
        onClose={handleUserDetails}
        visible={showUserDetails}
        width={500}
        destroyOnClose={true}
      >
        <UserDetailsCard userid={userDonationDetails} />
      </Drawer>
    </>
  );
};

export default withRouter(DonationBookingDetails);
