/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  // Badge,
  // Button,
  // Input,
  // notification,
  // Popconfirm,
  Space,
  Table,
} from "antd";
import { useState } from "react";
import { withRouter } from "react-router-dom";
// import { SearchOutlined } from "@ant-design/icons";
// import Highlighter from "react-highlight-words";
// import CounterService from "../../../Services/Counter.service";
// import { routeMapMini } from "../../../constants/routeMap";
import { FormatNumberINRCurrency } from "../../../utils/currencyFormat";

const CoupounTable = ({
  list,
  loading,
  setLoading,
  getCoupons,
  edit,
  setEdit,
  setTemp,
  meta,
  handleTableChange,
  tableTotalSize,
  tableCurrentPage,
  tablePageSize,
  history,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  // const handleReset = (clearFilters) => {
  //   clearFilters();
  //   setSearchText("");
  // };

  // const getColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //   }) => (
  //     <div style={{padding: 8}}>
  //       <Input
  //         // ref={node => {
  //         //   this.searchInput = node;
  //         // }}
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) =>
  //           setSelectedKeys(e.target.value ? [e.target.value] : [])
  //         }
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{width: 188, marginBottom: 8, display: "block"}}
  //       />
  //       <Button
  //         type="primary"
  //         onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         size="small"
  //         style={{width: 90, marginRight: 8}}
  //       >
  //         Search
  //       </Button>
  //       <Button
  //         onClick={() => handleReset(clearFilters)}
  //         size="small"
  //         style={{width: 90}}
  //       >
  //         Reset
  //       </Button>
  //     </div>
  //   ),
  //   filterIcon: (filtered) => (
  //     <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}} />
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
  //   onFilterDropdownVisibleChange: (visible) => {
  //     if (visible) {
  //       // setTimeout(() => this.searchInput.select());
  //     }
  //   },
  //   render: (text) =>
  //     searchedColumn === dataIndex ? (
  //       <Highlighter
  //         highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
  //         searchWords={[searchText]}
  //         autoEscape
  //         textToHighlight={text.toString()}
  //       />
  //     ) : (
  //       text
  //     ),
  // });

  const columns = [
    { title: "id", dataIndex: "id", key: "id" },
    {
      title: "Coupon Code",
      dataIndex: "coupon_code",
      key: "coupon_code",
    },
    {
      title: "Counter Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Discount (%)",
      dataIndex: "max_offer_percentage",
      key: "max_offer_percentage",
      render: (text) => {
        return `${text} %`;
      },
    },
    {
      title: "Max offer amount",
      dataIndex: "max_offer_amount",
      key: "max_offer_amount",
      render: FormatNumberINRCurrency,
    },
    {
      title: "Status",
      dataIndex: "used",
      render: (record) => {
        return <Space size="middle">{!record ? "Available" : "Used"}</Space>;
      },
    },

    {
      title: "Valid from ",
      dataIndex: "valid_from",
      key: "valid_from",
    },

    {
      title: "Valid To",
      dataIndex: "valid_to",
      key: "valid_to",
    },

    // {
    //   title: "Action",
    //   fixed: "right",
    //   responsive: ["md", "lg", "xl", "xsl"],
    //   render: (record) => {
    //     return (
    //       <Space size="middle">
    //         {/* <a
    //           onClick={() => {
    //             setTemp(record);
    //             setEdit(true);
    //           }}
    //         >
    //           Edit
    //         </a> */}
    //         <Popconfirm
    //           title="Are you sure delete this user?"
    //           onConfirm={async () => {
    //             setLoading(true);
    //             try {
    //               await CounterService.deleteCouponById(record.id);
    //               notification.success({
    //                 message: `Coupoun ${record.coupon_code} has been deleted`,
    //               });
    //               getCoupons();
    //             } catch (error) {
    //               if (error?.response?.status === 401) {
    //                 notification.error({
    //                   message:
    //                     "Token Expired, redirecting to login page in 5 seconds",
    //                 });
    //                 history?.push(routeMapMini.Login);
    //               }
    //               if (error.response?.data?.message) {
    //                 notification.error({
    //                   message: error.response?.data?.message,
    //                 });
    //               } else {
    //                 notification.error({ message: error.message });
    //               }
    //             }

    //             setLoading(false);
    //           }}
    //           okText="Yes"
    //           cancelText="No"
    //         >
    //           <a>Remove</a>
    //         </Popconfirm>{" "}
    //       </Space>
    //     );
    //   },
    // },
  ];

  return (
    <Table
      tableLayout="fixed"
      scroll={{ x: true }}
      loading={loading}
      style={{ width: "100%", textTransform: "capitalize" }}
      columns={columns}
      dataSource={list}
      pagination={{
        total: tableTotalSize,
        current: tableCurrentPage,
        pageSize: tablePageSize,
      }}
      tableTotalSize
      onChange={handleTableChange}
    ></Table>
  );
};

export default withRouter(CoupounTable);
