/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import RoomsService from "../../Services/rooms.service";
import {
  Button,
  notification,
  Table,
  Modal,
  Row,
  Col,
  Input,
  Space,
} from "antd";
import moment from "moment";
import { routeMapMini } from "../../constants/routeMap";
import PrintTicket from "./PrintTicket";
import { FormatNumberINRCurrency } from "../../utils/currencyFormat";

const TicketTable = ({ history, data }) => {
  const [blockData, setBlockData] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [roomDetails, setRoomDetails] = useState("");
  const [ticketLoading, setTicketLoading] = useState(false);
  const [ticketDetails, setTicketDetails] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [roomNumber, setRoomNumber] = useState();
  const [roomId, setRoomId] = useState();
  const [ticketVisible, setTicketVisible] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const fetchtable = async () => {
    setTableLoading(true);
    try {
      const respData = await RoomsService.blockAvailableRooms(
        data.block_category_id,
        moment(data.booking_date, "DD-MM-YYYY").format(`YYYY-MM-DD`)
      );
      setBlockData(
        respData.map((item) => {
          return { key: item.id, ...item };
        })
      );
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "Table fetch error" });
      }
    }
    setTableLoading(false);
  };

  useEffect(() => {
    if (data.block_category_id) {
      fetchtable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.block_category_id, data.booking_date]);

  const bookRoom = async () => {
    setTableLoading(true);
    setButtonLoading(true);
    try {
      const roomDetails = await RoomsService.bookRoom(
        data.ticketId,
        roomId,
        data?.actual_amount
      );
      setRoomDetails(roomDetails);
      await fetchDetails(roomDetails);
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
      setTableLoading(false);
    }
    setButtonLoading(false);
  };

  const fetchDetails = async (roomDetail) => {
    setTicketLoading(true);
    try {
      const ticketDetails = await RoomsService.printTicket(roomDetail.id);
      setTicketDetails(ticketDetails);
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        notification.error({ message: "something is wrong" });
      }
    }
    setTableLoading(false);
    setTicketVisible(true);
    setTicketLoading(false);
  };

  const columns = [
    {
      title: "Room Number",
      dataIndex: "room_number",
      key: "room_number",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      fixed: "right",
      responsive: ["md", "lg", "xl", "xsl"],
      render: (record) => {
        return (
          <Button
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              setRoomId(record.key);
              setRoomNumber(record?.room_number);
              setIsModalVisible(true);
            }}
          >
            Book Room
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      <Row style={{ marginBottom: "20px" }}>
        <Space>
          <Col> Deposit Amount : </Col>
          <Col>{FormatNumberINRCurrency(data?.actual_amount)}</Col>
        </Space>
      </Row>
      <Table
        size="small"
        scroll={{ x: true }}
        loading={tableLoading}
        style={{ width: "100%", textTransform: "capitalize" }}
        columns={columns}
        dataSource={blockData}
        pagination={{ pageSize: 10 }}
      ></Table>

      <Modal
        visible={isModalVisible}
        width={850}
        footer={null}
        closable={false}
        centered
        // onCancel={() => setIsModalVisible(false)}
      >
        {!ticketVisible && (
          <>
            <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
              Deposit Amount Received ?
            </Row>
            <Row style={{ justifyContent: "center", margin: "auto" }}>
              <Space>
                <Button
                  type="primary"
                  onClick={bookRoom}
                  loading={buttonLoading}
                >
                  Yes
                </Button>
                <Button onClick={() => setIsModalVisible(false)}>No</Button>
              </Space>
            </Row>
          </>
        )}
        {ticketVisible && (
          <PrintTicket
            ticketId={data?.ticketId}
            name={data?.devotee_names}
            blockName={data?.seva_name}
            roomNo={roomNumber}
            bookingDate={roomDetails?.booking_date}
            checkInTime={roomDetails?.check_in_time}
            checkOutTime={moment(roomDetails?.check_in_time)
              .set("hour", 8)
              .set("minute", 0)
              .add(1, "day")}
            roomPrice={data?.actual_amount}
            depositAmount={roomDetails?.deposit_amount}
            contact={data?.mobile_no}
            type={data?.type}
            couponDisc={0}
            persons={data?.no_of_tickets}
            from="ScanTicket"
            extraAmount={0}
            setPrintTicketModal={setIsModalVisible}
            booking="Online"
            setData={setBlockData}
          />
        )}
      </Modal>
    </div>
  );
};

export default TicketTable;
