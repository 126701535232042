import {
  Button,
  Divider,
  Input,
  notification,
  Row,
  Select,
  Space,
  Upload,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import {useContext, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {routeMapMini} from "../../../constants/routeMap";
import {UserContext} from "../../../context/user.context";
import RoomService from "../../../Services/rooms.service";
import {baseURL} from "../../../utils/axios";
import BlockCategoryTable from "./BlockCategoryTable";
import {UploadOutlined} from "@ant-design/icons";

const BlockCategory = ({history, location}) => {
  const [showNewModal, setShowNewModal] = useState(false);
  const [loading, setLoading] = useState();
  const [price, setPrice] = useState();
  const [depositAmount, setDepositAmount] = useState();
  const [roomType, setRoomType] = useState("standard");
  const [onlineAvailableRooms, setOnlineAvailableRooms] = useState();
  const [persons, setPersons] = useState();
  const [imageUrl, setImageUrl] = useState();

  const [updateTable, setUpdateTable] = useState();

  const [updateBlockCategoryId, setUpdateBlockCategoryId] = useState();

  const [blockId, setBlockId] = useState();

  const [blockName, setBlockName] = useState();

  const {userDetails} = useContext(UserContext);

  useEffect(() => {
    setBlockName(new URLSearchParams(location.search).get("blockName"));
    const id = new URLSearchParams(location.search).get("blockId");
    if (!id) {
      history?.push(routeMapMini.Accommodation);
    }
    setBlockId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, location.search]);

  useEffect(() => {
    (async () => {
      if (updateBlockCategoryId) {
        setShowNewModal(true);
        try {
          const resp = await RoomService.getBlockCategoryById(
            updateBlockCategoryId
          );
          setPrice(resp?.price);
          setDepositAmount(resp?.deposit_amount);
          setRoomType(resp?.room_type);
          setOnlineAvailableRooms(resp?.online_available_rooms);
          setPersons(resp?.persons);
          setShowNewModal(true);
          setImageUrl(resp?.image_url);
        } catch (error) {
          if (error?.response?.status === 401) {
            notification.error({
              message: "Token Expired, redirecting to login page in 5 seconds",
            });
            history?.push(routeMapMini.Login);
          } else if (error.response?.data?.message) {
            notification.error({message: error.response?.data?.message});
          } else {
            notification.error({message: error.message});
          }
        }
      }
    })();
  }, [history, updateBlockCategoryId]);

  const handleOk = async () => {
    if (price < 0) {
      return notification.error({
        message: `price should be positive`,
      });
    }
    setLoading(true);
    try {
      if (!blockId) {
        return history?.push(routeMapMini.Accommodation);
      }
      if (updateBlockCategoryId) {
        await RoomService.editBlockCategory(
          updateBlockCategoryId,
          blockId,
          price,
          depositAmount,
          roomType,
          onlineAvailableRooms,
          persons,
          imageUrl
        );
        notification.success({
          message: `block ${updateBlockCategoryId} updated`,
        });
        clearFields();
        setUpdateTable(updateBlockCategoryId + Math.random());
      } else {
        const block = await RoomService.createNewBlockCategory(
          blockId,
          price,
          depositAmount,
          roomType,
          onlineAvailableRooms,
          persons,
          imageUrl
        );
        notification.success({message: `block ${block.id} created`});
        setUpdateTable(block.id + Math.random());
      }
      setShowNewModal(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setOnlineAvailableRooms(undefined);
    setPrice(undefined);
    setDepositAmount(undefined);
    setUpdateBlockCategoryId(undefined);
    setPersons(undefined);
  };

  const handleCancel = () => {
    clearFields();
    setShowNewModal(false);
  };

  return (
    <div style={{width: "100%"}}>
      <Row>
        <h1
          style={{
            textAlign: "left",
            fontWeight: "bold",
            marginBottom: "0px",
            fontSize: "24px",
          }}
        >
          {blockName}
        </h1>
        <Divider />
      </Row>
      <Row style={{width: "100%", marginBottom: "15px"}}>
        <Space size="middle">
          <Button
            onClick={() => {
              history?.push(routeMapMini.Accommodation);
            }}
          >
            Go Back
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setShowNewModal(true);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Add New Category
          </Button>
        </Space>
      </Row>
      <Row style={{width: "100%"}}>
        <BlockCategoryTable
          updateTable={updateTable}
          setUpdateBlockId={setUpdateBlockCategoryId}
          blockId={blockId}
          blockName={blockName}
        />
      </Row>
      <Modal
        visible={showNewModal}
        title={`Add New Block`}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify="center" style={{width: "100%", paddingTop: "10px"}}>
          Online Available Rooms
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Online Available Rooms"
            value={onlineAvailableRooms}
            onChange={(e) => {
              setOnlineAvailableRooms(e.target.value);
            }}
            type="number"
            onWheel={(event) => event.currentTarget.blur()}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", paddingTop: "10px"}}>
          Persons
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Persons"
            value={persons}
            onChange={(e) => {
              setPersons(e.target.value);
            }}
            type="number"
            onWheel={(event) => event.currentTarget.blur()}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", paddingTop: "10px"}}>
          Price
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Price"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
            type="number"
            onWheel={(event) => event.currentTarget.blur()}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", paddingTop: "10px"}}>
          Deposit Amount
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="deposit amount"
            type="number"
            onWheel={(event) => event.currentTarget.blur()}
            value={depositAmount}
            onChange={(e) => {
              setDepositAmount(e.target.value);
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", paddingTop: "10px"}}>
          Room Type
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Select
            style={{minWidth: "150px", marginRight: "10px"}}
            onChange={(e) => {
              console.log(e);
              setRoomType(e);
            }}
            value={roomType}
          >
            <Select.Option value="AC">AC</Select.Option>
            <Select.Option value="NONAC">NONAC</Select.Option>
            <Select.Option value="SUITE">Suite</Select.Option>
            <Select.Option value="COTTAGE">Cottage</Select.Option>
            <Select.Option value="DORMITORY">Dormitory</Select.Option>
          </Select>
        </Row>
        <Row justify="center">Image</Row>
        <Row justify="center">
          <Upload
            name="file"
            listType="picture"
            action={`${baseURL}fileupload/upload/public-file?fileFor=publication_cover_pic`}
            headers={{
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }}
            accept="image/png, image/gif, image/jpeg"
            maxCount={1}
            onChange={(info) => {
              if (info.file.status !== "uploading") {
                setImageUrl(info.file?.response?.path);
              }
              if (info.file.status === "done") {
                notification.success({
                  message: `${info.file.name} file uploaded successfully`,
                });
              } else if (info.file.status === "error") {
                notification.error({
                  message: `${info.file.name} file upload failed.`,
                });
              }
            }}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(BlockCategory);
