/* eslint-disable react-hooks/exhaustive-deps */
import {useRef, useEffect} from "react";
import {Row, Col, Button, Divider, Card, Space, notification} from "antd";
import srisailamLogo from "../../../assets/srisailam_logo.png";
import "./SummaryReport.scss";
import {useReactToPrint} from "react-to-print";
import {routeMapMini} from "../../../constants/routeMap";
import ShiftService from "../../../Services/Shift.service";
import moment from "moment";
import {UserContext} from "../../../context/user.context";
import {useContext} from "react";
import {useState} from "react";
import {FormatNumberINRCurrency} from "../../../utils/currencyFormat";

const SummaryReport = ({
  history,
  shiftId,
  setReportPopUp,
  setReportVisible,
  from,
}) => {
  const {userDetails} = useContext(UserContext);

  let refContainer = useRef(null);

  let countA = 0;

  let bookingTypeCount = 0;

  const [reportData, setReportData] = useState([]);
  const [thisShiftCollection, setThisShiftCollection] = useState("");
  const [totalSevaCollection, setTotalSevaCollection] = useState(0);
  const [totalAnnaPrasadamCollection, setTotalAnnaPrasadamCollection] =
    useState(0);
  const [loading, setLoading] = useState(false);
  const [totalSevaTickets, setTotalSevaTickets] = useState(0);
  const [totalAnnaPrasadamTicket, setTotalAnnaPrasadamTicket] = useState(0);

  const fetchReport = async () => {
    let totalTickets = 0;
    let sevaCollection = 0;
    let annaPrasadamCollection = 0;
    let annaPrasadamTicket = 0;
    try {
      const repData = await ShiftService.ShiftReport(shiftId);
      repData?.table?.forEach((item) => {
        if (item?.name?.toLowerCase().includes("annaprasadam") === false) {
          totalTickets = totalTickets + Number(item?.total);
          sevaCollection = sevaCollection + Number(item?.collection);
        }
        if (item?.name?.toLowerCase().includes("annaprasadam") === true) {
          annaPrasadamCollection =
            annaPrasadamCollection + Number(item?.collection);
          annaPrasadamTicket = annaPrasadamTicket + Number(item?.total);
        }
      });
      setTotalSevaTickets(totalTickets);
      setTotalSevaCollection(sevaCollection);
      setTotalAnnaPrasadamCollection(annaPrasadamCollection);
      setTotalAnnaPrasadamTicket(annaPrasadamTicket);
      setReportData(repData);
      setThisShiftCollection(
        Number(repData?.shiftReport?.amount_deposited ?? 0) +
          Number(repData?.shiftReport?.accommodation_services_amount ?? 0) +
          Number(repData?.shiftReport?.darshanam_services_amount ?? 0) +
          Number(repData?.shiftReport?.pratyaksha_seva_services_amount ?? 0) +
          Number(repData?.shiftReport?.prasadam_services_amount ?? 0) +
          Number(repData?.shiftReport?.gst_amount ?? 0) +
          Number(repData?.shiftReport?.donation_services_amount ?? 0) +
          Number(repData?.shiftReport?.publication_services_amount ?? 0) +
          Number(repData?.ShiftReport?.toll_gate_services_amount ?? 0) +
          Number(repData?.ShiftReport?.kalyanakatta_services_amount ?? 0)
      );
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        notification.error({message: "something is wrong"});
      }
    }
  };

  const handleFunction = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 200);
    setTimeout(() => {
      handlePrint();
    }, 1000);
  };

  useEffect(() => {
    fetchReport();
  }, [shiftId]);

  const handlePrint = useReactToPrint({
    content: () => refContainer.current,
    copyStyles: true,
    bodyClass: "sd-report-container",
    onAfterPrint: () => {
      if (from === "shiftReportTable") {
        setReportPopUp(false);
      } else {
        setReportPopUp();
        setReportVisible();
        setReportData();
      }
    },
  });

  const reportBody = (donationFlag) => {
    return (
      <Card className="cardDiv" style={{color: "black"}}>
        <Row className="headerrow">
          <Col>
            <img src={srisailamLogo} alt="Logo" style={{width: "35%"}} />
          </Col>
        </Row>
        <Row className="headerrow">
          <h3
            className="boldText contantfontsize"
            style={{textTransform: "uppercase"}}
          >
            Sri Bhramaramba Mallikarjuna Swamy Varla Devasthanam
          </h3>
        </Row>
        {/* <Row className="headerrow">
          <h3>SRISAILAM KURNOOL - 518101 A.P</h3>
        </Row> */}
        <Row className="headerrow contantfontsize">
          <h3>{reportData?.contains} Challan Summary Report</h3>
        </Row>
        <Row className="headerrow contantfontsize">
          <Space>
            <Col>
              <Space>
                <p className="boldText">Employee Name :</p>
                <p style={{textTransform: "capitalize"}}>
                  {reportData?.shift?.sd_user?.display_name}{" "}
                </p>
              </Space>
            </Col>
            <Col>
              <Space>
                <p className="boldText">Counter :</p>
                <p style={{textTransform: "capitalize"}}>
                  {reportData?.shiftReport?.sd_counter?.name}
                </p>
              </Space>
            </Col>
          </Space>
        </Row>
        <Row className="headerrow contantfontsize" style={{width: "100%"}}>
          <Space>
            <p className="boldText">Shift Id :</p>
            <p>{shiftId} </p>
            <p className="boldText"> Date :</p>
            <p>{moment(reportData?.shift?.login).format("DD-MM-YYYY")}</p>
            <p className="boldText" style={{textTransform: "capitalize"}}>
              Shift Name : {reportData?.shiftReport?.sd_shift?.name}- (
              {moment(
                reportData?.shiftReport?.sd_shift?.start,
                "HH:mm:ss"
              ).format("hh:mm A")}
              -
              {moment(
                reportData?.shiftReport?.sd_shift?.end,
                "HH:mm:ss"
              ).format("hh:mm A")}
              )
            </p>
            <p className="boldText">Shift Start Time :</p>
            <p>
              {moment(reportData?.shift?.login)
                .utcOffset("+05:30")
                .format("DD-MM-YYYY hh:mm A")}
            </p>
            <p className="boldText">Shift End Time :</p>
            <p>
              {reportData?.shift?.logout
                ? moment(reportData?.shift?.logout)
                    .utcOffset("+05:30")
                    .format("DD-MM-YYYY hh:mm A")
                : "Shift Running"}
            </p>
          </Space>
        </Row>
        {/* <Row>
          <Divider dashed />
        </Row> */}
        {reportData?.shiftReport?.sd_counter?.name ===
          "online ticket scanning counter" && (
          <Row className="contantfontsize">
            <Space>
              <p className="boldText">Total Scanned Tickets :</p>
              <p>{reportData?.successfulTicketsScanned}</p>
            </Space>
          </Row>
        )}
        <table className="reportTable contantfontsize">
          {(() => {
            if (reportData?.contains?.length !== 1) {
              return (
                <tr>
                  <th>Counter S.no</th>
                  <td style={{whiteSpace: "nowrap"}}>
                    (
                    {reportData?.counterStartAndEnd?.startingTransactionId
                      ? reportData?.counterStartAndEnd?.startingTransactionId?.split(
                          "-"
                        )[1]
                      : "Null"}
                    ) - (
                    {reportData?.counterStartAndEnd?.endingTransactionId
                      ? reportData?.counterStartAndEnd?.endingTransactionId?.split(
                          "-"
                        )[1]
                      : "Null"}
                    )
                  </td>
                </tr>
              );
            } else if (
              reportData?.contains?.length === 1 &&
              reportData?.contains?.[0] !== "accommodation"
            ) {
              return (
                <tr>
                  <th>Counter S.no</th>
                  <td style={{whiteSpace: "nowrap"}}>
                    (
                    {reportData?.counterStartAndEnd?.startingTransactionId
                      ? reportData?.counterStartAndEnd?.startingTransactionId?.split(
                          "-"
                        )[1]
                      : "Null"}
                    ) - (
                    {reportData?.counterStartAndEnd?.endingTransactionId
                      ? reportData?.counterStartAndEnd?.endingTransactionId?.split(
                          "-"
                        )[1]
                      : "Null"}
                    )
                  </td>
                </tr>
              );
            }
          })()}
          {reportData?.contains?.map((items) => {
            if (items === "accommodation" && donationFlag === false) {
              return (
                <>
                  {reportData?.shiftReport?.previous_deposit > 0 && (
                    <tr>
                      <th>Opening Balance</th>
                      <td>
                        {FormatNumberINRCurrency(
                          reportData?.shiftReport?.previous_deposit ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                  <>
                    {thisShiftCollection > 0 && (
                      <tr>
                        <th>This Shift Collection</th>
                        <td>
                          {FormatNumberINRCurrency(thisShiftCollection ?? 0)}
                        </td>
                      </tr>
                    )}
                  </>
                  {reportData?.shiftReport?.previous_deposit +
                    thisShiftCollection >
                    0 && (
                    <tr>
                      <th>Total Amount</th>
                      <td>
                        {FormatNumberINRCurrency(
                          (reportData?.shiftReport?.previous_deposit ?? 0) +
                            (thisShiftCollection ?? 0)
                        )}
                      </td>
                    </tr>
                  )}
                  {reportData?.shiftReport?.amount_returned > 0 && (
                    <tr>
                      <th>Deposit Amount Paid to Devotees</th>
                      <td>
                        {FormatNumberINRCurrency(
                          reportData?.shiftReport?.amount_returned ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                  {reportData?.shiftReport?.previous_deposit +
                    thisShiftCollection -
                    reportData?.shiftReport?.amount_returned >
                    0 && (
                    <tr>
                      <th>Cash Balance</th>
                      <td>
                        {FormatNumberINRCurrency(
                          (reportData?.shiftReport?.previous_deposit ?? 0) +
                            (thisShiftCollection ?? 0) -
                            (reportData?.shiftReport?.amount_returned ?? 0)
                        )}
                      </td>
                    </tr>
                  )}
                  {reportData?.shiftReport?.previous_deposit +
                    thisShiftCollection -
                    reportData?.shiftReport?.amount_returned -
                    reportData?.shiftReport?.accommodation_services_amount -
                    reportData?.shiftReport?.gst_amount >
                    0 && (
                    <tr>
                      <th>Advance Cash Balance</th>
                      <td>
                        {FormatNumberINRCurrency(
                          (reportData?.shiftReport?.previous_deposit ?? 0) +
                            (thisShiftCollection ?? 0) -
                            (reportData?.shiftReport?.amount_returned ?? 0) -
                            (reportData?.shiftReport
                              ?.accommodation_services_amount ?? 0) -
                            (reportData?.shiftReport?.gst_amount ?? 0)
                        )}
                      </td>
                    </tr>
                  )}
                  {reportData?.shiftReport?.accommodation_services_amount >
                    0 && (
                    <tr>
                      <th>Rent Collection Amount ( Excluding GST )</th>
                      <td>
                        {FormatNumberINRCurrency(
                          reportData?.shiftReport
                            ?.accommodation_services_amount ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                  {reportData?.shiftReport?.gst_amount > 0 && (
                    <tr>
                      <th>GST Amount</th>
                      <td>
                        {FormatNumberINRCurrency(
                          reportData?.shiftReport?.gst_amount ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                  {reportData?.shiftReport?.accommodation_services_amount +
                    reportData?.shiftReport?.gst_amount >
                    0 && (
                    <tr>
                      <th>Rent Collection Amount ( Including GST )</th>
                      <td>
                        {FormatNumberINRCurrency(
                          (reportData?.shiftReport
                            ?.accommodation_services_amount ?? 0) +
                            (reportData?.shiftReport?.gst_amount ?? 0)
                        )}
                      </td>
                    </tr>
                  )}
                </>
              );
            }
            if (items === "darshanam" && donationFlag === false) {
              return (
                <>
                  {reportData?.shiftReport?.darshanam_services_amount > 0 && (
                    <tr>
                      <th>Darshanam Collection Amount</th>
                      <td>
                        {FormatNumberINRCurrency(
                          reportData?.shiftReport?.darshanam_services_amount ??
                            0
                        )}
                      </td>
                    </tr>
                  )}
                </>
              );
            }
            if (items === "seva" && donationFlag === false) {
              return (
                <>
                  {reportData?.shiftReport?.pratyaksha_seva_services_amount >
                    0 && (
                    <tr>
                      <th>Pratyaksha Seva Collection Amount</th>
                      <td>
                        {FormatNumberINRCurrency(
                          reportData?.shiftReport
                            ?.pratyaksha_seva_services_amount ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                </>
              );
            }
            if (items === "publication" && donationFlag === false) {
              return (
                <>
                  {reportData?.shiftReport?.publication_services_amount > 0 && (
                    <tr>
                      <th>Publication Collection Amount</th>
                      <td>
                        {FormatNumberINRCurrency(
                          reportData?.shiftReport
                            ?.publication_services_amount ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                </>
              );
            }
            if (items === "donation") {
              return (
                <>
                  {reportData?.shiftReport?.donation_services_amount > 0 && (
                    <tr>
                      <th>Donation Collection Amount</th>
                      <td>
                        {FormatNumberINRCurrency(
                          reportData?.shiftReport?.donation_services_amount ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                </>
              );
            }
            if (items === "prasadam" && donationFlag === false) {
              return (
                <>
                  {reportData?.shiftReport?.prasadam_services_amount > 0 && (
                    <tr>
                      <th>Prasadam Collection Amount</th>
                      <td>
                        {FormatNumberINRCurrency(
                          reportData?.shiftReport?.prasadam_services_amount ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                </>
              );
            }
            if (items === "toll_gate") {
              return (
                <>
                  {reportData?.shiftReport?.toll_gate_services_amount > 0 && (
                    <tr>
                      <th>Tollgate Collection Amount</th>
                      <td>
                        {FormatNumberINRCurrency(
                          reportData?.shiftReport?.toll_gate_services_amount ??
                            0
                        )}
                      </td>
                    </tr>
                  )}
                </>
              );
            }
            if (items === "kalayana_katta") {
              return (
                <>
                  {reportData?.shiftReport?.kalyanakatta_services_amount >
                    0 && (
                    <tr>
                      <th>Kalayanakatta Collection Amount</th>
                      <td>
                        {FormatNumberINRCurrency(
                          reportData?.shiftReport
                            ?.kalyanakatta_services_amount ?? 0
                        )}
                      </td>
                    </tr>
                  )}
                </>
              );
            }
          })}
        </table>
        {reportData?.table?.length !== 0 && (
          <table className="bookingTable contantfontsize">
            <tr>
              <th className="serialNo">S. No.</th>
              <th className="itemName">Name</th>
              <th className="slno">Sl No</th>
              <th className="tableData">
                {reportData?.contains?.find((e) => e === "thulabharam")
                  ? "Total (kg)"
                  : " Total"}
              </th>
              <th className="tableData" style={{textTransform: "none"}}>
                {reportData?.contains?.find((e) => e === "darshanam")
                  ? "Devotees"
                  : " Tickets"}
              </th>
              <th className="tableData">Price</th>
              <th className="collection">Collection</th>
            </tr>
            {reportData?.table?.map((items) => (
              <tr>
                {items?.name?.toLowerCase().includes("annaprasadam") ===
                  donationFlag && (
                  <>
                    <td className="serialNo"></td>
                    <td className="itemName">{items?.name}</td>
                    <td className="tableData">
                      {items?.firstCategoryTransId?.substring(
                        items?.firstCategoryTransId?.lastIndexOf("-") + 1
                      )}{" "}
                      -{" "}
                      {items?.lastCategoryTransId?.substring(
                        items?.lastCategoryTransId?.lastIndexOf("-") + 1
                      )}
                    </td>
                    <td className="tableData">
                      {" "}
                      {items?.total ? items?.total : "NA"}{" "}
                    </td>
                    <td className="tableData">
                      {reportData?.contains?.find((e) => e === "darshanam")
                        ? items?.collection / items?.price > 0
                          ? items?.collection / items?.price
                          : "-"
                        : items?.tickets
                        ? items?.tickets
                        : "NA"}
                    </td>
                    <td className="tableData">
                      {items?.price
                        ? FormatNumberINRCurrency(items?.price)
                        : "-"}
                    </td>
                    <td className="tableData">
                      {FormatNumberINRCurrency(items?.collection)}
                    </td>
                  </>
                )}
              </tr>
            ))}
            <tr>
              <th className="tableData" colSpan="3">
                Total
              </th>
              <td class="tableData">
                {donationFlag === true
                  ? totalAnnaPrasadamTicket
                  : totalSevaTickets}
              </td>
              <td></td>
              <td></td>
              <td className="tableData">
                {donationFlag === true
                  ? FormatNumberINRCurrency(totalAnnaPrasadamCollection)
                  : FormatNumberINRCurrency(totalSevaCollection)}
              </td>
            </tr>
          </table>
        )}
        {reportData?.scannedTable?.length !== 0 && (
          <table className="bookingTable contantfontsize">
            <tr>
              <th className="serialNo">S. No.</th>
              <th className="itemName">Name</th>
              <th className="tableData">Total</th>
              <th className="tableData">Type</th>
            </tr>
            {reportData?.scannedTable?.map((item) => (
              <tr>
                <td className="serialNo"></td>
                <td className="itemName">{item?.name}</td>
                <td className="tableData">{item?.total}</td>
                <td className="tableData">{item?.type}</td>
              </tr>
            ))}
          </table>
        )}
        {/* <Row>
          <Divider dashed />
        </Row> */}
        <div className="contantfontsize">
          <Row className="footerContent">
            <Space>
              <Col>
                <Space>
                  <p className="boldText">CC :</p>
                  <p className="boldText">__________</p>
                </Space>
              </Col>
              <Col>
                <Space>
                  <p className="boldText">CO :</p>
                  <p className="boldText">__________</p>
                </Space>
              </Col>
              <Col>
                <Space>
                  <p className="boldText">CLERK :</p>
                  <p className="boldText">__________</p>
                </Space>
              </Col>
              <Col>
                <Space>
                  <p className="boldText">SUPT :</p>
                  <p className="boldText">__________</p>
                </Space>
              </Col>
              <Col>
                <Space>
                  <p className="boldText">AEO :</p>
                  <p className="boldText">__________</p>
                </Space>
              </Col>
            </Space>
          </Row>
          {reportData?.contains?.map((items) => {
            if (items === "accommodation") {
              return (
                <Row className="footerContent contantfontsize">
                  <Space>
                    <Col>
                      <Space>
                        <p className="boldText">Total Room Check In :</p>
                        <p>{reportData?.shiftReport?.check_in_count}</p>
                      </Space>
                    </Col>
                    <Col>
                      <Space>
                        <p className="boldText">Total Room Check Out :</p>
                        <p>{reportData?.shiftReport?.check_out_count}</p>
                      </Space>
                    </Col>
                    <Col>
                      <Space>
                        <p className="boldText">Total Amount :</p>
                        <p>
                          {FormatNumberINRCurrency(
                            (reportData?.shiftReport?.previous_deposit ?? 0) +
                              (thisShiftCollection ?? 0)
                          )}
                        </p>
                      </Space>
                    </Col>
                  </Space>
                </Row>
              );
            }
          })}
          <Row className="footerContent contantfontsize">
            <Space>
              <Col>
                <Space>
                  <p>Report Generated By :</p>
                  <p className="boldText" style={{textTransform: "capitalize"}}>
                    {userDetails.display_name ?? userDetails.phone}
                  </p>
                </Space>
              </Col>
              <Col>
                <Space>
                  <p>Counter :</p>
                  <p className="boldText" style={{textTransform: "capitalize"}}>
                    {reportData?.shiftReport?.sd_counter?.name}
                  </p>
                </Space>
              </Col>
              <Col>
                <Space>
                  <p>Report Generated On :</p>
                  <p className="boldText">
                    {moment()
                      .utcOffset("+05:30")
                      .format("DD-MM-YYYY hh:mm:ss A")}
                  </p>
                </Space>
              </Col>
            </Space>
          </Row>
        </div>
      </Card>
    );
  };

  const sevaCount = (flag) => {
    if (flag) {
      countA = countA + 1;
    }

    if (countA === 1) {
      return (
        <div className="reportFooter">
          {reportBody(true)}
          {reportBody(true)}
        </div>
      );
    }
  };

  return (
    <div
      className="report"
      style={{
        width: "100%",
        textAlign: "center",
      }}
    >
      <Button type="primary" style={{margin: "20px"}} onClick={handleFunction}>
        Print
      </Button>

      {loading ? (
        "Loading"
      ) : (
        <div ref={refContainer} className="sd-report-container">
          {reportBody(false)}
          {reportBody(false)}
          {reportData?.table?.map((items) =>
            items?.name?.toLowerCase().includes("annaprasadam") === true
              ? sevaCount(true)
              : ""
          )}
        </div>
      )}
    </div>
  );
};

export default SummaryReport;
