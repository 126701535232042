import Axios from "../utils/axios";

const PublicationService = {
  getAllPublications: async () => {
    const resp = await Axios.get("/publications/all");
    return resp.data;
  },

  getPublicationById: async (publicationId) => {
    const resp = await Axios.get(`/publications/byId/${publicationId}`);
    return resp.data;
  },

  createNewPublication: async (name, description, cover_url, disabled) => {
    const requestObj = {
      name,
      description,
      cover_url,
      disabled,
    };
    if (!description) {
      delete requestObj.description;
    }
    if (!disabled) {
      delete requestObj.disabled;
    }
    if (!cover_url) {
      delete requestObj.cover_url;
    }
    const resp = await Axios.post(`/publications/new`, requestObj, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  editPublication: async (
    publication_id,
    name,
    description,
    cover_url,
    disabled
  ) => {
    const requestObj = {
      publication_id,
      name,
      description,
      cover_url,
      disabled,
    };
    if (!description) {
      delete requestObj.description;
    }
    if (!cover_url) {
      delete requestObj.cover_url;
    }
    const resp = await Axios.put(`/publications/edit`, requestObj, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  deletePublication: async (id) => {
    const resp = await Axios.delete(`publications/publication/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  getFilesForPublication: async (publicationId) => {
    const resp = await Axios.get(
      `/publications/allFiles/byId/${publicationId}`
    );
    return resp.data;
  },

  getPublicationFileById: async (publicationFileId) => {
    const resp = await Axios.get(
      `/publications/file/byId/${publicationFileId}`
    );
    return resp.data;
  },

  createPublicationFile: async (
    publication_id,
    year,
    month,
    type,
    language_options,
    name,
    file_url,
    price,
    delivery_price
  ) => {
    const requestObj = {
      publication_id,
      year,
      month,
      type,
      language_options,
      file_url,
      name,
      price,
      delivery_price,
    };
    if (!file_url) {
      delete requestObj.file_url;
    }
    if (!year) {
      delete requestObj.year;
    }
    if (!month) {
      delete requestObj.month;
    }
    if (!name) {
      delete requestObj.name;
    }
    if (!delivery_price) {
      delete requestObj.delivery_price;
    }
    const resp = await Axios.post(`/publications/file/new`, requestObj, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  editPublicationFile: async (
    publication_file_id,
    publication_id,
    year,
    month,
    type,
    language_options,
    name,
    file_url,
    price,
    delivery_price
  ) => {
    const requestObj = {
      publication_file_id,
      publication_id,
      year,
      month,
      type,
      language_options,
      name,
      file_url,
      price,
      delivery_price,
    };
    if (!file_url) {
      delete requestObj.file_url;
    }
    if (!year) {
      delete requestObj.year;
    }
    if (!month) {
      delete requestObj.month;
    }
    if (!name) {
      delete requestObj.name;
    }
    if (!delivery_price) {
      delete requestObj.delivery_price;
    }
    const resp = await Axios.put(`/publications/file/edit`, requestObj, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  deletePublicationFile: async (id) => {
    const resp = await Axios.delete(`publications/file/publication/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  bookings: async (
    publicationId,
    fromDate,
    toDate,
    publicationType,
    page,
    limit
  ) => {
    let query = `paymentStatus=success&page=${page}&limit=${limit}&`;

    if (publicationId) {
      query += `publicationId=${publicationId}&`;
    }
    if (fromDate) {
      query += `fromDate=${fromDate}&`;
    }
    if (toDate) {
      query += `toDate=${toDate}&`;
    }
    if (publicationType) {
      query += `publicationType=${publicationType}&`;
    }

    const resp = await Axios.get(`publication-report/bookings?${query}&`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  PulicationCounterBooking: async (body) => {
    let resp = await Axios.post(`/cart-counter/publication`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  bookingsExcel: async (publicationId, fromDate, toDate, publicationType) => {
    let query = `paymentStatus=success&`;

    if (publicationId) {
      query += `publicationId=${publicationId}&`;
    }
    if (fromDate) {
      query += `fromDate=${fromDate}&`;
    }
    if (toDate) {
      query += `toDate=${toDate}&`;
    }
    if (publicationType) {
      query += `publicationType=${publicationType}&`;
    }
    const resp = await Axios.get(
      `/publication-report/bookings/excel?${query}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        responseType: "blob",
      }
    );
    return resp.data;
  },

  getOfflineCounterPublicationList: async () => {
    const resp = await Axios.get(
      "/publication-counter/counter-publications/list",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  getOfflineCounterPublicationFileList: async (id) => {
    const resp = await Axios.get(
      `publication-counter/counter-publications-files/list?publicationId=${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  getAllPublicationCounterList: async (id) => {
    let resp = await Axios.get(
      `publication-counter/counter/byPublicationId/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  addCounterbypublicationId: async (body) => {
    const resp = await Axios.post(`/publication-counter/new`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });

    return resp.data;
  },

  deleteCounterbyPublicationId: async (id) => {
    let resp = await Axios.delete(`publication-counter/byId/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
};

export default PublicationService;
