import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";

import 'antd/dist/antd.css';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import UserContextProvider from "./context/user.context";
import AccContextProvider from "./context/accommodation.context";
import ShiftContextProvider from "./context/shift.context";
import SADContextProvider from "./context/SAD.context";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
        <UserContextProvider>
          <AccContextProvider>
            <ShiftContextProvider>
              <SADContextProvider>
                <App />
              </SADContextProvider>
            </ShiftContextProvider>
          </AccContextProvider>
        </UserContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
