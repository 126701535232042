/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Col,
  DatePicker,
  notification,
  Row,
  Select,
  Space,
  Table,
  Drawer,
} from "antd";
import {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import moment from "moment";
import fileDownload from "js-file-download";
import {routeMapMini} from "../../constants/routeMap";
import PublicationService from "../../Services/Publication.service";
import {FormatNumberINRCurrency} from "../../utils/currencyFormat";
import UserDetailsCard from "../ManageUsers/UserDetailsCard";

const PublicationBookingDetails = ({history}) => {
  const [publicationId, setPublicationId] = useState(0);
  const [publicationType, setPublicationType] = useState();
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));

  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);

  const [publicationIdsLoading, setPublicationIdsLoading] = useState(false);
  const [publicationIds, setPublicationIds] = useState([]);

  const [showUserDetails, setShowUserDetails] = useState(false);
  const [userDetails, setUserDetails] = useState();

  const getPublicationIds = async () => {
    setPublicationIdsLoading(true);
    try {
      const publicationIdData = await PublicationService.getAllPublications();
      setPublicationIds(publicationIdData);
    } catch (error) {
      notification.error({message: error.message});
    }
    setPublicationIdsLoading(false);
  };

  const fetchBookings = async () => {
    setDataLoading(true);
    try {
      const respData = await PublicationService.bookings(
        publicationId,
        fromDate,
        toDate,
        publicationType,
        tableCurrentPage,
        tablePageSize
      );
      setData(respData?.items);
      setTabelTotalSize(respData?.meta?.totalItems);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setDataLoading(false);
  };

  const downloadExcel = async () => {
    if (!publicationType) {
      return notification.error({
        message: "publicationType is not selected",
      });
    }
    if (!fromDate) {
      return notification.error({
        message: "from date is not selected",
      });
    }

    notification.info({message: "Downloading file! Please wait."});
    PublicationService.bookingsExcel(
      publicationId,
      fromDate,
      toDate,
      publicationType
    )
      .then((data) => {
        fileDownload(
          data,
          `${fromDate}-PublicationsBooked-${moment().format(
            "DD-MM-YYYY HH:MM"
          )}.xlsx`
        );
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          notification.error({
            message: "Token Expired, redirecting to login page in 5 seconds",
          });
          history?.push(routeMapMini.Login);
        } else if (error?.response?.data?.message) {
          notification.error({message: error?.response?.data?.message});
        } else {
          console.error(error);
          notification.error({message: "something is wrong"});
        }
      });
  };

  useEffect(() => {
    getPublicationIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fromDate,
    publicationId,
    publicationType,
    tableCurrentPage,
    tablePageSize,
  ]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const columns = [
    {
      title: "User",
      dataIndex: "user_display_name",
      key: "user_display_name",
      render: (text, record) => {
        return (
          <div>
            <a
              onClick={() => {
                // history?.push(
                //   `${routeMapMini.userDetails}?userId=${record.user_id}`
                // );
                setUserDetails(record?.user_id);
                setShowUserDetails(true);
              }}
              style={{textTransform: "capitalize"}}
            >
              {record.user_display_name}
            </a>
          </div>
        );
      },
    },
    {
      title: "Devotee Name",
      dataIndex: "devoteeName",
      key: "devoteeName",
    },
    {
      title: "Publication File Name",
      dataIndex: "publicationFileName",
      key: "publicationFileName",
    },
    {
      title: "Publication Name",
      dataIndex: "publicationName",
      key: "publicationName",
    },
    {
      title: "Type",
      dataIndex: "publicationFileType",
      key: "publicationFileType",
    },
    /*{
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (text, record) => {
        const addressArr = [];
        if (record.address) {
          addressArr.push(record.address);
        }
        if (record.address2) {
          addressArr.push(record.address2);
        }
        if (record.city) {
          addressArr.push(record.city);
        }
        if (record.state) {
          addressArr.push(record.state);
        }
        if (record.pin_code) {
          addressArr.push(record.pin_code);
        }
        return <div>{addressArr.join(', ')}</div>;
      },
    },*/
    {title: "Ticket ID", dataIndex: "ticket_id", key: "ticket_id"},
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.amount > b.amount,
      render: FormatNumberINRCurrency,
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Order Date",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];

  const handleUserDetails = () => {
    setUserDetails(undefined);
    setShowUserDetails(false);
  };

  return (
    <>
      <Row style={{marginBottom: "20px"}} gutter={[20, 20]}>
        <Col>
          <Row justify="start">Type</Row>
          <Row justify="start">
            <Select
              style={{width: "200px", marginRight: "10px"}}
              onChange={(e) => {
                console.log(e);
                setPublicationType(e);
              }}
              defaultValue=""
            >
              <Select.Option value={undefined}>None</Select.Option>
              <Select.Option value="download">Download</Select.Option>
              <Select.Option value="subscription">Subscription</Select.Option>
              <Select.Option value="purchase">Purchase</Select.Option>
              <Select.Option value="">All</Select.Option>
            </Select>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Publication Name</Row>
          <Row justify="start">
            <Select
              style={{width: "200px"}}
              loading={publicationIdsLoading}
              onChange={setPublicationId}
              defaultValue=""
            >
              <Select.Option value={undefined}>None</Select.Option>
              {publicationIds.map((item) => {
                return (
                  <Select.Option value={item.id}> {item.name} </Select.Option>
                );
              })}
              <Select.Option value="">All</Select.Option>
            </Select>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Order Date</Row>
          <Row justify="start">
            <DatePicker.RangePicker
              format="DD-MM-YYYY"
              onChange={(e) => {
                if (e?.[0] && e?.[1]) {
                  setFromDate(moment(e[0]).format(`YYYY-MM-DD`));
                  setToDate(moment(e[1]).format(`YYYY-MM-DD`));
                } else {
                  setFromDate(undefined);
                  setToDate(undefined);
                }
              }}
              ranges={{
                Today: [moment(), moment()],
                "Last 7 Days": [moment().subtract(7, "d"), moment()],
                "Last 15 Days": [moment().subtract(15, "d"), moment()],
              }}
              defaultValue={[moment(fromDate), moment(toDate)]}
              defaultPickerValue={[moment(fromDate), moment(toDate)]}
            />
          </Row>
        </Col>
        <Col>
          <Row justify="start">Update</Row>
          <Row justify="start">
            <Button type="primary" onClick={fetchBookings}>
              Fetch
            </Button>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Download</Row>
          <Row justify="start">
            <Button type="primary" onClick={downloadExcel}>
              Excel
            </Button>
          </Row>
        </Col>
      </Row>

      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Total Bookings</span>
        </Space>
      </Row>

      <Table
        style={{width: "100%", textTransform: "capitalize"}}
        columns={columns}
        dataSource={data}
        loading={dataLoading}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        scroll={{x: true}}
        size="small"
        onChange={handleTableChange}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <Row>
                <p style={{fontWeight: "bolder"}}>Address:</p>
                {record.address}
              </Row>
            </>
          ),
        }}
      ></Table>
      <Drawer
        title={`User`}
        placement="right"
        closable={false}
        onClose={handleUserDetails}
        visible={showUserDetails}
        width={500}
        destroyOnClose={true}
      >
        <UserDetailsCard userid={userDetails} />
      </Drawer>
    </>
  );
};

export default withRouter(PublicationBookingDetails);
