import { notification, Col } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { withRouter } from "react-router";
import { routeMapMini } from "../../../constants/routeMap";
import UserService from "../../../Services/User.service";
import UserOrderCards from "../graphs/UserOrderCards";
import UserPaymentPieGraph from "../graphs/UserPaymentPieGraph";

const UserOrderStats = ({
  userId,
  history,
  cardsmall,
  cardmid,
  cardlarge,
  graphsmall,
  graphlarge,
  cardheight,
}) => {
  const [userOrderStat, setUserOrderStat] = useState([]);
  const [userPaymentStat, setUserPaymentStat] = useState([]);

  const fetchUserOrderStats = async () => {
    try {
      const data = await UserService.userOrderStats(userId);
      setUserOrderStat(data);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page.",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
  };

  const fetchUserPaymentStats = async () => {
    try {
      const data = await UserService.userPaymentStats(userId);
      setUserPaymentStat(data);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page.",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({ message: error?.response?.data?.message });
      } else {
        console.error(error);
        notification.error({ message: "something is wrong" });
      }
    }
  };
  useEffect(() => {
    if (userId !== undefined) {
      fetchUserOrderStats();
      fetchUserPaymentStats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <div>
      <Col>
        <UserOrderCards
          title={"User Orders"}
          data={userOrderStat}
          cardsmall={cardsmall}
          cardmid={cardmid}
          cardlarge={cardlarge}
          cardheight={cardheight}
        />
        <UserPaymentPieGraph
          title={"User Payments"}
          data={userPaymentStat}
          graphsmall={graphsmall}
          graphlarge={graphlarge}
        />
      </Col>
    </div>
  );
};

export default withRouter(UserOrderStats);
