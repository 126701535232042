/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Col,
  DatePicker,
  Input,
  notification,
  Row,
  Select,
  Space,
  Table,
  Drawer,
} from "antd";
import {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import CounterService from "../../../Services/Counter.service";
import {routeMapMini} from "../../../constants/routeMap";
import fileDownload from "js-file-download";
import ReportService from "../../../Services/Report.service";
import ThulabharamService from "../../../Services/Thulabharam.service";
import {FormatNumberINRCurrency} from "../../../utils/currencyFormat";

const {Option} = Select;
const ThulabharamCounterReport = ({history}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [thulabharamList, setThulabharamList] = useState([]);
  const [sevaNamesLoading, setSevaNamesLoading] = useState(false);
  const [selectedThulabharamId, setSelectedThulabharamId] = useState("");
  const [bookingFromDate, setBookingFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [bookingToDate, setBookingToDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [orderBy, setOrderBy] = useState("ASC");
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [dateType, setDateType] = useState("performanceDate");
  const [loading, setLoading] = useState(false);
  //new
  const [countersList, setCounterList] = useState([]);
  const [selectedCounter, setSelectedCounter] = useState({});

  useEffect(() => {
    getCounters();
    getThulabharamNames();
  }, []);

  useEffect(() => {
    fetchBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedThulabharamId,
    bookingFromDate,
    bookingToDate,
    orderBy,
    tableCurrentPage,
    tablePageSize,
    selectedCounter,
  ]);

  const getThulabharamNames = async () => {
    setSevaNamesLoading(true);
    try {
      const thulabharamData = await ThulabharamService.all();
      setThulabharamList(thulabharamData);
    } catch (error) {
      notification.error({message: error.message});
    }
    setSevaNamesLoading(false);
  };

  const fetchBookings = async () => {
    setDataLoading(true);
    console.log(bookingFromDate);
    if (!bookingFromDate || !bookingToDate) {
      notification.error({message: "Please select a valid date Range"});
      setDataLoading(false);
      return;
    }
    try {
      const respData = await ReportService.getThulabharanCounterReport(
        "view",
        selectedThulabharamId,
        bookingFromDate,
        bookingToDate,
        dateType,
        tableCurrentPage,
        tablePageSize,
        selectedCounter?.id
      );
      setData(respData?.items);
      setTabelTotalSize(respData?.meta?.totalItems);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }

    setDataLoading(false);
  };

  const getCounters = async () => {
    try {
      setLoading(true);
      const counters = await CounterService.getAllCounter();
      setCounterList(counters);
      setLoading(false);
    } catch (error) {
      if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
      setLoading(false);
    }
  };

  const downloadFile = async (type, format = "Bookings") => {
    if (!bookingFromDate) {
      return notification.error({
        message: "From Date is not selected",
      });
    }
    if (!bookingToDate) {
      return notification.error({
        message: "To Date is not selected",
      });
    }

    notification.info({message: "Downloading file! Please wait."});
    ReportService.getThulabharanCounterReport(
      "download",
      selectedThulabharamId,
      bookingFromDate,
      bookingToDate,
      dateType,
      tableCurrentPage,
      tablePageSize,
      selectedCounter?.id
    )

      .then((data) => {
        fileDownload(
          data,
          `${bookingFromDate}-sevaCounterReport-${moment().format(
            "DD-MM-YYYY HH:MM"
          )}.xlsx`
        );
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          notification.error({
            message: "Token Expired, redirecting to login page in 5 seconds",
          });
          history?.push(routeMapMini.Login);
        } else if (error?.response?.data?.message) {
          notification.error({message: error?.response?.data?.message});
        } else {
          console.error(error);
          notification.error({message: "something is wrong"});
        }
      });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleUserDetails = () => {
    // setUserDetails(undefined);
    setShowUserDetails(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{padding: 8}}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: "block"}}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{width: 90, marginRight: 8}}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{width: 90}}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "created_at") {
      if (sorter.order === "ascend") {
        setOrderBy("ASC");
      } else {
        setOrderBy("DESC");
      }
    }
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Thulabharam Name",
      dataIndex: "thulabharamName",
      key: "thulabharamName",
      ...getColumnSearchProps("thulabharamName"),
    },

    {
      title: "Counter Name",
      dataIndex: "counterName",
      key: "counterName",
      ...getColumnSearchProps("counterName"),
    },
    {
      title: "Shift Name",
      dataIndex: "shiftName",
      key: "shiftName",
    },
    {
      title: "Shift Log Id",
      dataIndex: "shiftLogId",
      key: "shiftLogId",
    },
    {title: "Quantity", dataIndex: "quantity", key: "quantity"},

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      defaultSortOrder: "descend",
      render: (text) => FormatNumberINRCurrency(text),
    },
    {
      title: "Order Date",
      dataIndex: "date",
      render: (record) => {
        return moment(record).format("DD-MM-YYYY HH:mm A");
      },
    },
  ];

  return (
    <>
      <Row style={{marginBottom: "20px"}} justify={"start"} gutter={[20, 20]}>
        <Col span={6}>
          <Row justify="start">Thulabharam Type</Row>
          <Row justify="start">
            <Select
              style={{width: "100%"}}
              loading={sevaNamesLoading}
              onChange={setSelectedThulabharamId}
              value={selectedThulabharamId}
              defaultValue=""
            >
              {thulabharamList.map((item) => {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                );
              })}
              <Select.Option value={""} key={""}>
                All
              </Select.Option>
            </Select>
          </Row>
        </Col>
        <Col span={6}>
          <Row justify="start">Seva Date</Row>
          <Row justify="start">
            <DatePicker.RangePicker
              onChange={(e) => {
                if (e?.[0] && e?.[1]) {
                  setBookingFromDate(moment(e[0]).format(`YYYY-MM-DD`));
                  setBookingToDate(moment(e[1]).format(`YYYY-MM-DD`));
                } else {
                  setBookingFromDate(undefined);
                  setBookingToDate(undefined);
                }
              }}
              format="DD-MM-YYYY"
              ranges={{
                Today: [moment(), moment()],
                "Last 7 Days": [moment().subtract(7, "d"), moment()],
                "Last 15 Days": [moment().subtract(15, "d"), moment()],
              }}
              defaultValue={[moment(bookingFromDate), moment(bookingToDate)]}
              defaultPickerValue={[
                moment(bookingFromDate),
                moment(bookingToDate),
              ]}
              style={{width: "100%"}}
            />
          </Row>
        </Col>
        <Col span={6}>
          <Row justify="start">Date Type</Row>
          <Row justify="start">
            <Select
              style={{width: "100%"}}
              onChange={(e) => {
                setDateType(e);
              }}
              value={dateType}
            >
              <Select.Option value="orderDate">Order Date</Select.Option>
              <Select.Option value="performanceDate">
                Performance Date
              </Select.Option>
            </Select>
          </Row>
        </Col>

        <Col span={6}>
          <Row justify="start">Counter</Row>
          <Row justify="start">
            <Select
              style={{width: "100%"}}
              value={selectedCounter.name}
              className="sd-select-seva"
              dropdownClassName="sd-select-dropdown-seva"
              placeholder="Please enter counter"
              onChange={(e) => {
                if (e === "") {
                  setSelectedCounter("");
                } else {
                  let parsed = JSON.parse(e);
                  setSelectedCounter(parsed);
                }
              }}
            >
              {countersList.map((item, i) => {
                return (
                  <Option
                    key={item.name + "counter-list" + i}
                    value={JSON.stringify(item)}
                  >
                    {item.name}
                  </Option>
                );
              })}
              <Option value="" key="">
                All
              </Option>
            </Select>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Update</Row>
          <Row justify="start">
            <Button
              loading={dataLoading}
              type="primary"
              style={{minWidth: "100px"}}
              onClick={fetchBookings}
            >
              Fetch
            </Button>
          </Row>
        </Col>
        <Col>
          <Row justify="start">Download</Row>
          <Row justify="start">
            <Button
              type="primary"
              style={{minWidth: "100px"}}
              onClick={() => downloadFile("excel")}
            >
              Excel
            </Button>
          </Row>
        </Col>
      </Row>

      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Total Bookings</span>
        </Space>
      </Row>

      <Table
        style={{width: "100%", textTransform: "capitalize"}}
        columns={columns}
        dataSource={data}
        loading={dataLoading}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        scroll={{x: true}}
        size="small"
        onChange={handleTableChange}
      ></Table>
      <Drawer
        title={`User`}
        placement="right"
        closable={false}
        onClose={handleUserDetails}
        visible={showUserDetails}
        width={500}
        destroyOnClose={true}
      >
        {/* <SevaCounterTable userid={userDetails} /> */}
      </Drawer>
    </>
  );
};

export default withRouter(ThulabharamCounterReport);
