import {Button, Col, Input, notification, Row, Space} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import TextArea from "antd/lib/input/TextArea";
import Modal from "antd/lib/modal/Modal";
import {useContext, useEffect, useState} from "react";

import {withRouter} from "react-router-dom";
import {routeMapMini} from "../../constants/routeMap";
import {UserContext} from "../../context/user.context";
import DarshanamService from "../../Services/Darshanam.service";
import DarshanamTable from "./DarshanamTable";

const Darshanam = ({history}) => {
  const [showNewModal, setShowNewModal] = useState(false);
  const [loading, setLoading] = useState();
  const [darshanamName, setDarshanamName] = useState("");
  const [price, setPrice] = useState();
  const [bookWithinDays, setBookBeforeDays] = useState();
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [maxBookingsPerDay, setMaxBookingsPerDay] = useState();
  const [disabled, setDisabled] = useState(false);
  const [cutOffTimeHours, setCutOffTimeHours] = useState();

  const [updateTable, setUpdateTable] = useState();

  const [updateDarshanamId, setUpdateDarshanamId] = useState();

  const {userDetails} = useContext(UserContext);

  useEffect(() => {
    (async () => {
      if (updateDarshanamId) {
        setShowNewModal(true);
        try {
          const resp = await DarshanamService.darshanamById(updateDarshanamId);
          setDarshanamName(resp?.name);
          setPrice(resp?.price);
          setDescription(resp?.description);
          setImageUrl(resp?.image_url);
          setMaxBookingsPerDay(resp?.max_booking_per_day);
          setDisabled(resp?.disabled);
          setCutOffTimeHours(resp?.cut_off_time_hours);
          setBookBeforeDays(resp?.book_within_days);
          setShowNewModal(true);
        } catch (error) {
          if (error?.response?.status === 401) {
            notification.error({
              message: "Token Expired, redirecting to login page in 5 seconds",
            });
            history?.push(routeMapMini.Login);
          } else if (error.response?.data?.message) {
            notification.error({message: error.response?.data?.message});
          } else {
            notification.error({message: error.message});
          }
        }
      }
    })();
  }, [history, updateDarshanamId]);

  const handleOk = async () => {
    if (!darshanamName) {
      return notification.error({message: `name is required`});
    } else if (!price || price <= 0) {
      return notification.error({
        message: `price is required and should be positive`,
      });
    }
    setLoading(true);
    try {
      if (updateDarshanamId) {
        await DarshanamService.editDarshanam(
          updateDarshanamId,
          darshanamName,
          description,
          imageUrl,
          Number(maxBookingsPerDay),
          cutOffTimeHours,
          disabled,
          price,
          bookWithinDays
        );
        notification.success({
          message: `Darshanam ${updateDarshanamId} updated`,
        });
        clearFields();
        setUpdateTable(updateDarshanamId + Math.random());
      } else {
        const darshanam = await DarshanamService.newDarshanam(
          darshanamName,
          description,
          imageUrl,
          maxBookingsPerDay,
          cutOffTimeHours,
          disabled,
          price,
          bookWithinDays
        );
        notification.success({message: `darshanam ${darshanam.id} created`});
        setUpdateTable(darshanam.id + Math.random());
      }
      setShowNewModal(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        notification.error({
          message: "Token Expired, redirecting to login page in 5 seconds",
        });
        history?.push(routeMapMini.Login);
      } else if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setDarshanamName(undefined);
    setPrice(undefined);
    setDescription(undefined);
    setImageUrl(undefined);
    setMaxBookingsPerDay(undefined);
    setDisabled(undefined);
    setCutOffTimeHours(undefined);
    setUpdateDarshanamId(undefined);
    setMaxBookingsPerDay(undefined);
  };

  const handleCancel = () => {
    clearFields();
    setShowNewModal(false);
  };

  return (
    <div style={{width: "100%"}}>
      <Row style={{width: "100%", marginBottom: "15px"}} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setShowNewModal(true);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Add New Darshanam
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              history?.push(routeMapMini.DarshanamBookingDetails);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Darshanam Booking Details
          </Button>
        </Col>
        {/* <Col>
          <Button
            type='primary'
            // onClick={() => {
            //   history?.push(routeMapMini.DonationReports);
            // }}
            disabled={userDetails.role !== 'admin'}
          >
            Darshanam Reports
          </Button>
        </Col> */}
        <Col>
          <Button
            type="primary"
            onClick={() => {
              history?.push(routeMapMini.DarshanamDisabledDates);
            }}
            disabled={userDetails.role !== "admin"}
          >
            Disable Darshanam on Date
          </Button>
        </Col>
      </Row>
      <Row style={{width: "100%"}}>
        <DarshanamTable
          updateTable={updateTable}
          setUpdateDarshanamId={setUpdateDarshanamId}
        />
      </Row>
      <Modal
        visible={showNewModal}
        title="Add New Darshanam"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Darshanam Name"
            value={darshanamName}
            onChange={(e) => {
              setDarshanamName(e.target.value);
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Image Url"
            value={imageUrl}
            onChange={(e) => {
              setImageUrl(e.target.value);
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <TextArea
            placeholder="Description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Max Bookings Per Day"
            type="number"
            value={maxBookingsPerDay}
            onChange={(e) => {
              setMaxBookingsPerDay(e.target.value);
            }}
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Cut Off Time Hours"
            value={cutOffTimeHours}
            onChange={(e) => {
              setCutOffTimeHours(e.target.value);
            }}
            type="number"
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Space size="middle">
            Disable Darshanam
            <Checkbox
              checked={disabled}
              onChange={(e) => {
                setDisabled(e.target.checked);
              }}
            />
          </Space>
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Price"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
            type="number"
            style={{width: "400px"}}
          />
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Input
            placeholder="Book Within Days"
            value={bookWithinDays}
            onChange={(e) => {
              setBookBeforeDays(e.target.value);
            }}
            type="number"
            style={{width: "400px"}}
          />
        </Row>
      </Modal>
    </div>
  );
};

export default withRouter(Darshanam);
