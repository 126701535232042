/* eslint-disable react-hooks/exhaustive-deps */
import {Modal, notification, Row, Select, Table} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {routeMapMini} from "../../../constants/routeMap";
import {ShiftContext} from "../../../context/shift.context";
import {UserContext} from "../../../context/user.context";
import accommodationReportService from "../../../Services/accommodation-report.service";
import RoomsService from "../../../Services/rooms.service";
import ShiftService from "../../../Services/Shift.service";
import CheckoutTable from "./CheckoutTable";
import CheckOut from "../../Ticket/CheckOut";
import ShiftDetailsCard from "../../Report/ShiftDetailsCard";
import AccommodationStats from "./AccommadationStats";
import "./accomadation.css";
const AccommodationDashboard = ({history}) => {
  const {showData} = useContext(ShiftContext);
  const {userDetails} = useContext(UserContext);
  const [roomId, setRoomId] = useState();
  const [checkOutModal, setCheckOutModal] = useState(false);
  const [checkOutData, setCheckOutData] = useState();
  const [blocks, setBlocks] = useState([]);
  const [selectedBlockId, setSelectedBlockId] = useState("all");

  const [reportData, setReportData] = useState("");
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [userShiftData, setUserShiftData] = useState("");
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };
  const [availabilityModalFlag, setAvailabilityModalFlag] = useState(false);
  const [availabilityRooomsData, setAvailabilityRoomsData] = useState([]);
  const [availabilityLabel, setAvailabilityLabel] = useState("");

  const getBlocks = async () => {
    try {
      if (
        userDetails.role === "admin" ||
        userDetails.role === "accommodation_read"
      ) {
        let resp = await RoomsService.getAllBlocks();
        resp = resp?.map((item) => {
          return {block_id: item.id, block_block_name: item.block_name};
        });
        setBlocks(resp);
        if (resp[0].block_id) {
          setSelectedBlockId(resp[0].block_id);
        }
      } else {
        const resp = await accommodationReportService.getShiftBlocks();
        setBlocks(resp);
        if (resp[0].block_id) {
          setSelectedBlockId(resp[0].block_id);
        }
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
  };

  const getReportData = async () => {
    if (!selectedBlockId) {
      return;
    }
    try {
      const reportData =
        selectedBlockId === "all"
          ? await accommodationReportService.getBlockAccommodationReportAll()
          : await accommodationReportService.getBlockAccommodationReport(
              selectedBlockId
            );
      setReportData(reportData);
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
  };
  const getLiveAvailableRoomsData = async (roomType = "", status) => {
    try {
      let res = await accommodationReportService.getLiveAccommodationData(
        selectedBlockId,
        roomType === "" ? "AC" : roomType,
        status
      );
      setAvailabilityRoomsData(res);
      setAvailabilityModalFlag(true);
      setAvailabilityLabel(status);
    } catch (e) {
      setAvailabilityModalFlag(false);
      setAvailabilityLabel("");
      setAvailabilityRoomsData([]);
      notification.error({message: e?.message});
    }
  };
  useEffect(() => {
    if (userDetails) {
      getBlocks();
    }
  }, [userDetails]);

  useEffect(() => {
    (async () => {
      if (
        userDetails?.role !== "admin" &&
        userDetails?.role !== "accommodation_read"
      ) {
        if (showData) {
          try {
            let res = await ShiftService.getCurrentShiftDetails();
            setUserShiftData(res);
          } catch (error) {
            if (error?.response?.status === 401) {
              history?.push(routeMapMini.Login);
            } else if (error?.response?.data?.message) {
              notification.error({message: error?.response?.data?.message});
            } else {
              console.error(error);
              notification.error({message: "something is wrong"});
            }
          }
        }
      } else {
        setUserShiftData("");
      }
    })();
  }, [showData]);

  useEffect(() => {
    getReportData();
  }, [selectedBlockId]);

  useEffect(() => {
    selectedBlockId !== "" && getCheckoutTable();
  }, [selectedBlockId]);

  const getCheckoutTable = async () => {
    setLoading(true);
    if (!selectedBlockId) {
      setLoading(false);

      return;
    }
    try {
      const reportData =
        selectedBlockId === "all"
          ? await accommodationReportService.getCheckoutTableDataAll()
          : await accommodationReportService.getCheckoutTableData(
              selectedBlockId
            );
      setList(
        reportData?.map((item) => {
          return {key: item?.id, ...item};
        })
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);

      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
  };
  const handleCancel = () => {
    setRoomId();
    setCheckOutModal(false);
  };

  const checkOutSubmit = (record) => {
    setCheckOutModal(true);
    setRoomId(record?.id);
  };

  return !showData &&
    userDetails?.role !== "admin" &&
    userDetails?.role !== "accommodation_read" ? (
    <div>
      <h1> Login To shift </h1>
    </div>
  ) : (
    <div>
      <h1>Accommodation Dashboard</h1>
      <Row>
        <Select
          style={{width: "200px"}}
          onChange={(e) => {
            setSelectedBlockId(e);
          }}
          value={selectedBlockId}
        >
          {blocks?.map((block) => (
            <Select.Option key={block?.block_id} value={block?.block_id}>
              {block?.block_block_name}
            </Select.Option>
          ))}
          <Select.Option key={"all"} value={"all"}>
            All
          </Select.Option>
        </Select>
      </Row>
      <br />
      <Row
        style={{
          marginTop: "20px",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {userShiftData !== "" &&
          userDetails.role === "admin" &&
          userDetails.role !== "accommodation_read" && (
            <ShiftDetailsCard shiftData={userShiftData} />
          )}
        {reportData !== "" && (
          <AccommodationStats
            shiftData={reportData}
            getLiveAvailableRoomsData={getLiveAvailableRoomsData}
          />
        )}
        {/* {Object.entries(reportData)?.map(([key, value]) => (
         <Card
            title={key}
            style={{
              width: "24%",
              marginBottom: "20px",
            }}
          >
            <Typography.Title style={{fontSize: "24px"}}>
              {value}
            </Typography.Title>
          </Card>
          ))}*/}
      </Row>
      <CheckoutTable
        list={list}
        loading={loading}
        setloading={setLoading}
        handleTableChange={handleTableChange}
        tableCurrentPage={tableCurrentPage}
        tablePageSize={tablePageSize}
        checkOutSubmit={checkOutSubmit}
      />
      <Modal
        visible={checkOutModal}
        onCancel={handleCancel}
        footer={false}
        centered
      >
        <CheckOut
          roomId={roomId}
          setModalVisible={setCheckOutModal}
          setCheckOutData={setCheckOutData}
          from="allocationTable"
          getCheckoutTable={getCheckoutTable}
          accDashboard={true}
        />
      </Modal>
      <Modal
        visible={availabilityModalFlag}
        onCancel={() => {
          setAvailabilityModalFlag(false);
          setAvailabilityRoomsData([]);
        }}
        footer={false}
        centered
        width={"70%"}
        height={"80%"}
      >
        <p style={{fontWeight: "bold"}}>
          Block ID: {selectedBlockId} :
          <span style={{textTransform: "capitalize", margin: "0px 5px"}}>
            {
              blocks?.find((item) => item.block_id === selectedBlockId)
                ?.block_block_name
            }
          </span>
          -
          <span style={{textTransform: "capitalize", marginRight: "5px"}}>
            {availabilityLabel}
          </span>
          Rooms
        </p>
        <Table
          className={"accomadation_table"}
          tableLayout="fixed"
          scroll={{x: true}}
          loading={loading}
          style={{width: "100%", textTransform: "capitalize"}}
          columns={[
            {
              title: "Block Name",
              dataIndex: "blockName",
              key: "blockName",
            },
            {
              title: "Room Type",
              dataIndex: "roomType",
              key: "roomType",
            },
            {
              title: "Room Number",
              dataIndex: "roomNumber",
              key: "roomNumber",
            },
          ]}
          dataSource={availabilityRooomsData}
        ></Table>

        {/* <div
          style={{maxHeight: "350px", overflow: "scroll", overflowX: "hidden"}}
        >
          {availabilityRooomsData?.length > 0 &&
            availabilityRooomsData?.map((item, i) => {
              return (
                <div key={i + "availability"}>
                  {i + 1}. Block Name: {item?.blockName} - ({item?.roomType})-
                  Room No: {item?.roomNumber}
                </div>
              );
            })}
        </div> */}
      </Modal>
    </div>
  );
};

export default withRouter(AccommodationDashboard);
