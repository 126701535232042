/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Grid,
  Layout,
  Button,
  Space,
  notification,
  Modal,
  Select,
  Row,
  Col,
  Tooltip,
} from "antd";
import {withRouter} from "react-router-dom";
import AvatarDropdown from "./AvatarDropDown";
import Notification from "./Notification";
import {useState, useContext} from "react";
import React, {useEffect} from "react";
import {MenuUnfoldOutlined, MenuFoldOutlined} from "@ant-design/icons";
import {routeMapMini} from "../../../constants/routeMap";
import ShiftService from "../../../Services/Shift.service";
import SummaryReport from "./SummaryReport";
import {ShiftContext} from "../../../context/shift.context";
import moment from "moment";

const {Header} = Layout;

const HomeHeader = ({history, toggleSider, siderCollapsed}) => {
  const {useBreakpoint} = Grid;

  const screens = useBreakpoint();

  const [siderHidden, setSiderHidden] = useState(false);

  const [shiftLoggedIn, setShiftLoggedIn] = useState(true);

  const [shiftChangeLoading, setShiftChangeLoading] = useState(false);

  const [counterLoading, setCounterLoading] = useState(false);

  const [reportPopUp, setReportPopUp] = useState(false);

  const [counterPopUp, setCounterPopUp] = useState(false);

  const [shiftId, setShiftId] = useState();

  const [counterShiftId, setCounterShiftId] = useState();

  const [counterDetails, setCounterDetails] = useState();

  const [reportVisible, setReportVisible] = useState(false);

  const {
    setShowData,
    setShiftContextCounterName,
    setshiftData,
    shiftData,
    setShiftShowData,
  } = useContext(ShiftContext);

  const getCounter = async () => {
    setCounterLoading(true);
    try {
      const counterDetails = await ShiftService.GetCounterForUserPreShift();
      setCounterDetails(counterDetails);
      setCounterPopUp(true);
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setCounterLoading(false);
  };

  const shiftIn = async () => {
    setShiftChangeLoading(true);
    try {
      const counterObj = counterDetails?.filter(
        (item) => item.id === counterShiftId
      )[0];

      // console.log(
      //   counterDetails?.filter((item) => item.id === counterShiftId)[0]
      // );

      let data = await ShiftService.shiftLogIn(counterObj.id);
      setshiftData(data);
      setShiftLoggedIn({
        sd_counter: {
          name: counterObj.sd_counter.name,
        },
        sd_shift: {
          start: counterObj.sd_shift.start,
          end: counterObj.sd_shift.end,
        },
      });
      setShiftContextCounterName(counterObj.sd_counter.name);
      setCounterPopUp(false);
      setShiftChangeLoading(false);
      setShowData(true);
    } catch (error) {
      setShiftChangeLoading(false);

      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
  };

  const shiftOut = async () => {
    setShiftChangeLoading(true);
    try {
      const shiftId = await ShiftService.shiftLogOut();
      if (shiftId) {
        setShiftId(shiftId);
        setReportVisible(true);
      }
      setshiftData("");
      setShiftLoggedIn(false);
      setShowData(false);
      setShiftContextCounterName();
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
    setShiftChangeLoading(false);
  };

  const isShiftLoggedIn = async () => {
    try {
      const shiftData = await ShiftService.shiftLoggedIn();
      setshiftData(shiftData);
      setShiftLoggedIn(shiftData);
      setShowData(!!shiftData);
      setShiftContextCounterName(shiftData?.sd_counter?.name);
    } catch (error) {
      if (error?.response?.status === 401) {
        history?.push(routeMapMini.Login);
      } else if (error?.response?.data?.message) {
        notification.error({message: error?.response?.data?.message});
      } else {
        console.error(error);
        notification.error({message: "something is wrong"});
      }
    }
  };

  const shiftOutPopUp = () => {
    setReportPopUp(true);
  };

  useEffect(() => {
    isShiftLoggedIn();
  }, []);

  useEffect(() => {
    if (screens?.xl || screens?.xxl || screens?.lg) {
      setSiderHidden(false);
    } else {
      setSiderHidden(true);
    }
  }, [screens]);

  const RenderName = () => {
    if (screens?.xl || screens?.xxl || screens?.lg || screens?.md) {
      return (
        <div
          style={{
            fontWeight: "bolder",
            fontSize: "20px",
            marginLeft: "25px",
          }}
        >
          Sri Bhramaramba Mallikarjuna Swamy Varla Devasthanam
        </div>
      );
    } else if (screens?.xl || screens?.xxl || screens?.lg) {
      return (
        <div
          style={{
            fontWeight: "bolder",
            float: "left",
            fontSize: "20px",
            display: "flex",
            marginLeft: "25px",
          }}
        >
          Sri Bhramaramba Mallikarjuna Swamy Varla Devasthanam
        </div>
      );
    } else {
      return null;
    }
  };
  useEffect(() => {
    if (shiftData?.id) {
      getShiftDetails(shiftData?.id);
    }
  }, [shiftData]);

  const getShiftDetails = async (id) => {
    console.log("we were here");
    const repData = await ShiftService.ShiftReport(id, true);
    console.log(repData);
    setShiftShowData(repData);
  };

  return (
    <div>
      <Header
        style={{
          padding: 0,
          backgroundColor: "#f68121",
        }}
      >
        {React.createElement(
          siderCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: "trigger",
            onClick: toggleSider,
            style: {
              zIndex: 10,
              top: 25,
              left: siderCollapsed ? (siderHidden ? 20 : 100) : 220,
              display: "flex",
              float: "right",
              marginRight: "25px",
              position: "absolute",
            },
          }
        )}
        <RenderName />
        <div
          style={{
            top: 0,
            right: 0,
            display: "flex",
            float: "right",
            marginRight: "25px",
            position: "absolute",
          }}
        >
          <div style={{margin: "0px 20px", fontWeight: "bold"}}></div>
          <Space>
            <div>
              <Tooltip
                placement="bottom"
                title={
                  !!shiftLoggedIn
                    ? `${moment(
                        shiftLoggedIn.sd_shift?.start,
                        "HH:mm:sss"
                      ).format("hh:mm A")} - ${moment(
                        shiftLoggedIn.sd_shift?.end,
                        "HH:mm:sss"
                      ).format("hh:mm A")}`
                    : "Shift In"
                }
              >
                <Button
                  type="default"
                  loading={shiftChangeLoading}
                  onClick={!!shiftLoggedIn ? shiftOutPopUp : getCounter}
                >
                  {!!shiftLoggedIn
                    ? `Shift Out - ${shiftLoggedIn.sd_counter?.name ?? "-"}`
                    : "Shift In"}
                </Button>
              </Tooltip>
            </div>
          </Space>
          <div style={{margin: "0px 20px", cursor: "pointer"}}>
            <Notification />
          </div>
          <div>
            <AvatarDropdown />
          </div>
        </div>
      </Header>
      <Modal
        visible={reportPopUp}
        width={1000}
        footer={null}
        closable={false}
        centered
        // onCancel={() => {
        //   setReportPopUp(false);
        //   setReportVisible(false);
        // }}
      >
        {!reportVisible && (
          <>
            <Row style={{justifyContent: "center", marginBottom: "10px"}}>
              Are you sure you want to end the shift ?
            </Row>
            <Row style={{justifyContent: "center", margin: "auto"}}>
              <Space>
                <Button type="primary" onClick={shiftOut}>
                  Yes
                </Button>
                <Button onClick={() => setReportPopUp(false)}>No</Button>
              </Space>
            </Row>
          </>
        )}
        {reportVisible && (
          <SummaryReport
            shiftId={shiftId}
            setReportPopUp={setReportPopUp}
            setReportVisible={setReportVisible}
          />
        )}
      </Modal>
      <Modal
        visible={counterPopUp}
        width={400}
        onOk={shiftIn}
        closable={false}
        centered
        onCancel={() => setCounterPopUp(false)}
        footer={[
          <Button key="back" onClick={() => setCounterPopUp(false)}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={shiftChangeLoading}
            onClick={() => shiftIn()}
          >
            Submit
          </Button>,
        ]}
      >
        <Col>
          <Row justify="start">Counter</Row>
          <Row>
            <Select
              style={{width: "400px", marginTop: "10px"}}
              loading={counterLoading}
              onChange={setCounterShiftId}
            >
              {counterDetails?.map((item) => {
                return (
                  <Select.Option value={item.id}>
                    {item?.sd_counter.name} ({" "}
                    {moment(item.sd_shift?.start, "HH:mm:ss").format("hh:mm A")}{" "}
                    - {moment(item.sd_shift?.end, "HH:mm:ss").format("hh:mm A")}{" "}
                    )
                  </Select.Option>
                );
              })}
            </Select>
          </Row>
        </Col>
      </Modal>
    </div>
  );
};

export default withRouter(HomeHeader);
