import {Button, Row, notification, Select} from "antd";
import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import CounterRoleTable from "./CounterRoleTable";
import {routeMapMini} from "../../constants/routeMap";
import Modal from "antd/lib/modal/Modal";
import CounterService from "../../Services/Counter.service";

const CounterRole = ({history, location}) => {
  const [counterId, setCounterId] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [counterRole, setCounterRole] = useState();

  useEffect(() => {
    const id = new URLSearchParams(location.search).get("CounterId");
    if (!id) {
      history?.push(routeMapMini.CounterManagement);
    }
    setCounterId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, location.search]);

  const handleOk = async () => {
    setLoading(true);
    try {
      if (counterRole) {
        await CounterService.addRoleToCounter(counterId, counterRole);
        notification.success({
          message: `block ${counterRole} updated`,
        });
        clearFields();
      }
    } catch (error) {
      if (error.response?.data?.message) {
        notification.error({message: error.response?.data?.message});
      } else {
        notification.error({message: error.message});
      }
    }
    setLoading(false);
  };

  const handleCancel = () => {
    clearFields();
    setModalVisible(false);
  };

  const clearFields = () => {
    setCounterRole();
  };

  return (
    <>
      <Row style={{marginBottom: "20px"}}>
        <Button type="primary" onClick={() => setModalVisible(true)}>
          Add New Role
        </Button>
      </Row>
      <Row>
        <CounterRoleTable counterId={counterId} counterRole={counterRole} />
      </Row>
      <Modal
        visible={modalVisible}
        title="Add New Role"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify="center">
          Counter Name:{" "}
          {new URLSearchParams(location.search).get("counterName")}
        </Row>
        <Row justify="center" style={{width: "100%", padding: "10px 0px"}}>
          <Select
            style={{width: "200px"}}
            onChange={(e) => {
              setCounterRole(e);
            }}
            value={counterRole}
          >
            <Select.Option value={"admin"}>Admin</Select.Option>
            <Select.Option value={"manager"}>Manager</Select.Option>
            <Select.Option value={"operator"}>Operator</Select.Option>
            <Select.Option value={"seva_read"}>Seva Read</Select.Option>
            <Select.Option value={"donation_read"}>Donation Read</Select.Option>
            <Select.Option value={"donation_write"}>
              Donation Write
            </Select.Option>

            <Select.Option value={"seva_and_darshanam_counter"}>
              Sevas and Darshanam Counter
            </Select.Option>
            <Select.Option value={"seva_counter"}>Sevas Counter</Select.Option>
            <Select.Option value={"darshanam_counter"}>
              Darshanam Counter
            </Select.Option>
            <Select.Option value={"prasadam_counter"}>
              Prasadam Counter
            </Select.Option>
            <Select.Option value={"kailasakankanam_counter"}>
              Kailasa Kankanam Counter
            </Select.Option>
            <Select.Option value={"thulabharam_counter"}>
              Thulabharam Counter
            </Select.Option>
            <Select.Option value={"toll_gate_counter"}>
              Toll Gate Counter
            </Select.Option>
            <Select.Option value={"publication_read"}>
              Publication Read
            </Select.Option>
            <Select.Option value={"publication_counter"}>
              Publication Counter
            </Select.Option>
            <Select.Option value={"customer_support"}>
              Customer Support
            </Select.Option>
            <Select.Option value={"user"}>User</Select.Option>
            <Select.Option value={"counter"}>Counter</Select.Option>
            <Select.Option value={"accommodation_counter"}>
              Accommodation Counter
            </Select.Option>
            <Select.Option value={"accommodation_read"}>
              Accommodation Read
            </Select.Option>
            <Select.Option value={"performance_scan"}>
              Performance Scan
            </Select.Option>
            <Select.Option value={"seva_read_and_performance_scan"}>
              Seva Read And Performance Scan
            </Select.Option>
            <Select.Option value={"dashboardView"}>
              Dashboard View
            </Select.Option>
            <Select.Option value={"kalyanakatta_counter"}>
              Kalyanakatta Counter
            </Select.Option>
          </Select>
        </Row>
      </Modal>
    </>
  );
};

export default withRouter(CounterRole);
